import { CatalogueFilterArgs } from "~/redux/slice/catalogueFilter.slice";
import { getKeys } from "~/util/functions/getKeys";

export const getFilterCount = (filter: CatalogueFilterArgs) => {
  let count = 0;
  const filterKeys = getKeys(filter);

  for (let i = 0; i < filterKeys.length; i++) {
    const key = filterKeys[i];
    if (key === "orderBy" || key === "cursor" || key === "take") {
      continue;
    } else if (key === "inStock") {
      if (filter.inStock === true || filter.inStock === false) {
        count++;
      }
    } else if (key === "companiesIds") {
      if (!!filter.companiesIds?.length) {
        count++;
      }
    } else {
      if (!!filter[key]) {
        count++;
      }
    }
  }

  return count;
};
