import { CustomButton } from "~/components/form/CustomButton";
import SVGContainer from "~/components/UI/SVGContainer";
import { colors } from "~/constants/styles";

import styles from "./index.module.scss";

interface Props {
  title: string;
  onClick: (button: 1 | 2 | 3) => void;
  selectedButton: 1 | 2 | 3;
  buttonsText: string[];
}

const CardWithButtons = ({
  title,
  onClick,
  selectedButton,
  buttonsText,
  children,
}: React.PropsWithChildren<Props>) => {
  return (
    <div className={`${styles.card}`}>
      <div className={`${styles.header}`}>
        <div className={`${styles.title}`}>{title}</div>
        <div className={`${styles.buttons}`}>
          <CustomButton
            width="max-content"
            height="25px"
            borderRadius="4px"
            backgroundColor={
              selectedButton === 1 ? colors.$darkGray : colors.$inputGray
            }
            onClick={() => {
              onClick(1);
            }}
            padding="1rem"
          >
            {buttonsText[0]}
          </CustomButton>
          <CustomButton
            width="max-content"
            height="25px"
            borderRadius="4px"
            backgroundColor={
              selectedButton === 2 ? colors.$darkGray : colors.$inputGray
            }
            onClick={() => {
              onClick(2);
            }}
            padding="1rem"
          >
            {buttonsText[1]}
          </CustomButton>
          <CustomButton
            width="max-content"
            height="25px"
            borderRadius="4px"
            backgroundColor={
              selectedButton === 3 ? colors.$darkGray : colors.$inputGray
            }
            onClick={() => {
              onClick(3);
            }}
            padding="1rem"
          >
            {buttonsText[2]}
          </CustomButton>
        </div>
        <div className={`draggable ${styles.icon}`}>
          <SVGContainer
            height="28px"
            width="28px"
            imagePath="/assets/drag-icon.svg"
          />
        </div>
      </div>
      <div className={`${styles.content}`}>{children}</div>
    </div>
  );
};

export default CardWithButtons;
