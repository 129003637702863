export function removeDuplicate<K extends keyof T, T>(
  func: (key: T) => T[K],
  data: T[]
) {
  const newArray: T[] = [];
  data.forEach((elt) => {
    if (newArray.filter((newElt) => func(elt) === func(newElt)).length === 0) {
      newArray.push(elt);
    }
  });
  return newArray;
}
