import { LoginResponse } from "~/types/common/auth.types";
import { AxiosHttpError } from "~/types/common/error.types";
import { LoginInput } from "~/types/input/auth.types";

import { sendRawRequest } from "../clients/axios";
import { removeAuth, storeAuthResult } from "./utils/tokenManagement.util";

export default async function login(input: LoginInput) {
  try {
    const { data } = await sendRawRequest<LoginInput, LoginResponse>(
      "post",
      "/auth/login",
      input
    );

    switch (data.ChallengeName) {
      case undefined:
        storeAuthResult(data);
        return {
          success: true,
          action: "LOGIN",
          result: data.AuthenticationResult,
        };
      case "NEW_PASSWORD_REQUIRED":
        return {
          success: true,
          action: "NEW_PASSWORD_REQUIRED",
          session: data.Session,
        };
    }
  } catch (err) {
    const axiosError = err as AxiosHttpError;
    if (axiosError.response?.data) {
      const { message } = axiosError.response.data;
      return {
        success: false,
        action: typeof message === "string" ? message : message[0],
      };
    }
  }
  return {
    success: false,
    action: "Unkown Error",
  };
}

export function logout() {
  removeAuth();
}
