import React from "react";

import { initMyAccountState } from "~/constants/states";
import { Company } from "~/types/data/Company.types";

export interface CompanyContextData extends Company {
  connected?: boolean;
}

export type CompanyAccountContextContent = {
  companyAccount: CompanyContextData;
  setCompanyAccount: React.Dispatch<React.SetStateAction<CompanyContextData>>;
};

export const CompanyAccountContext =
  React.createContext<CompanyAccountContextContent>({
    companyAccount: { ...initMyAccountState, connected: false },
    setCompanyAccount: () => {},
  });

export const useCompanyAccountContext = () =>
  React.useContext(CompanyAccountContext);
