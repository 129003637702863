import { gql } from "@apollo/client";

import {
  ForeignShopMapping,
  ForeignShopMappingUpdateType,
} from "~/types/data/ForeignShopMapping.types";

export interface UpdateForeignShopMappingsResponse {
  updateForeignShopMappings: ForeignShopMapping[];
}

export interface GetForeignShopMappingsResponse {
  getForeignShopMappings: ForeignShopMapping[];
}
export interface UpdateForeignShopMappingsInput {
  UpdateForeignShopMappingsInput: {
    foreignShopMappings: ForeignShopMappingUpdateType[];
  };
}
export interface GetForeignShopMappingInput {
  GetForeignShopMappingInput: {
    shopId: number;
  };
}

export const updateForeignShopMappings = gql`
  mutation ($UpdateForeignShopMappingsInput: UpdateForeignShopMappingsInput!) {
    updateForeignShopMappings(
      UpdateForeignShopMappingsInput: $UpdateForeignShopMappingsInput
    ) {
      id
    }
  }
`;

export const getForeignShopMappings = gql`
  query ($GetForeignShopMappingInput: GetForeignShopMappingInput!) {
    getForeignShopMappings(
      GetForeignShopMappingInput: $GetForeignShopMappingInput
    ) {
      id
      name
      type
      originalValue
      mappedValue
      children {
        id
        name
        type
        originalValue
        mappedValue
      }
    }
  }
`;
