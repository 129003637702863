import Joi from "joi";

import { FtpImport, ImportProtocolEnum } from "~/types/data/FtpImport.types";

import { ValidationError } from "../validation.error";

const raiseError = (message: string, field: keyof FtpImport) =>
  new ValidationError(message, field);

export const createOrUpdateFtpImportValidationSchema = () =>
  Joi.object<Partial<FtpImport>>({
    active: Joi.boolean().required(),
    protocol: Joi.string()
      .valid(ImportProtocolEnum.FTP, ImportProtocolEnum.SFTP)
      .required()
      .uppercase()
      .error(raiseError("Protocol must be FTP or SFTP ", "protocol")),
    host: Joi.string()
      .required()
      .error(raiseError("Host url must be specified", "host")),
    port: Joi.number()
      .optional()
      .error(raiseError("Invalid port number", "port")),
    username: Joi.string()
      .required()
      .error(raiseError("Username must be specified", "username")),
    password: Joi.string()
      .required()
      .error(raiseError("Password must be specified", "password")),
    filePath: Joi.string()
      .required()
      .error(raiseError("File path must be specified", "filePath")),
  });
