import { ApolloError, useLazyQuery, useQuery } from "@apollo/client";
import { debounce } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { utils, writeFile } from "xlsx";

import {
  getAttribute,
  GetAttributeInput,
  GetAttributeResponse,
} from "~/api/graphql/attribute";
import { GET_COMPANIES, GetCompaniesResponse } from "~/api/graphql/company";
import {
  deleteAllProducts,
  DeleteAllProductsResponse,
  deleteProductsByIds,
  DeleteProductsByIdsInput,
  DeleteProductsByIdsResponse,
  getProductsCount,
  GetProductsCountResponse,
  getProductVariants,
  GetProductVariantsInput,
  GetProductVariantsResponse,
} from "~/api/graphql/product";
import {
  GET_USED_VENDOR_CATEGORIES,
  GetVendorCategoriesInput,
  GetVendorCategoriesResponse,
} from "~/api/graphql/vendorCategory";
import { CustomButton } from "~/components/form/CustomButton";
import { InputWithIcon } from "~/components/form/InputWithIcon";
import { MultipleSelectInput } from "~/components/form/MultipleSelectInput";
import { OptionType } from "~/components/form/SelectInput";
import CustomModal from "~/components/UI/CustomModal";
import { ErrorPopup } from "~/components/UI/ErrorPopup";
import FullPageLoader from "~/components/UI/FullPageLoader";
import { LoadingPopup } from "~/components/UI/LoadingPopup";
import { SideModal } from "~/components/UI/SideModal";
import SVGContainer from "~/components/UI/SVGContainer";
import { colors } from "~/constants/styles";
import { useUserContext } from "~/context/userContext";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import { routePaths } from "~/navigation/routes";
import { useAppDispatch, useAppSelector } from "~/redux/hooks";
import {
  onFilterArgsChange,
  selectCatalogueFilterArgs,
} from "~/redux/slice/catalogueFilter.slice";
import { Product } from "~/types/data/Product.types";
import { RoleEnum } from "~/types/data/User.types";

import { CataloguesFilterForm } from "./CataloguesFilterForm";
import { CataloguesTable } from "./CataloguesTable";
import styles from "./index.module.scss";
import { ProductsActionsModal } from "./ProductsActionsModal";
import { getFilterCount } from "./util/getFilterCount";

const MonCatalogue = () => {
  // states
  const [productVariants, setProductVariants] = useState<Product[]>();
  const [hasMoreData, setHasMoreData] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [titleSearch, setTitleSearch] = useState<string>();
  const [isPreparingFile, setIsPreparingFile] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState("");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterCount, setFilterCount] = useState<number>(0);
  const [productsCount, setProductsCount] = useState<number>(0);
  const [showActionsModal, setShowActionsModal] = useState(false);

  // hooks
  const { userState } = useUserContext();
  const filterArgsState = useAppSelector(selectCatalogueFilterArgs);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const isAdmin = !!userState?.connected && userState?.role === RoleEnum.ADMIN;

  const {
    ajouterUnProduitRoute,
    importerParFichierRoute,
    mesImportationsRoute,
  } = routePaths;

  const navigate = useNavigate();

  const { data: companiesData, error: companiesError } =
    useQuery<GetCompaniesResponse>(GET_COMPANIES, {
      skip: !isAdmin,
    });

  const {
    data: vendorCategoriesData,
    error: vendorCategoriesError,
    refetch: refetchVendorCategories,
    loading: vendorCategoriesLoading,
  } = useQuery<GetVendorCategoriesResponse, GetVendorCategoriesInput>(
    GET_USED_VENDOR_CATEGORIES,
    {
      variables: {
        GetVendorCategoriesInput: !isAdmin
          ? undefined
          : { companiesIds: filterArgsState.companiesIds ?? [] },
      },
      skip: !!isAdmin && !companiesData,
    }
  );

  const {
    data: brandData,
    error: brandError,
    refetch: refetchBrand,
    loading: brandLoading,
  } = useQuery<GetAttributeResponse, GetAttributeInput>(getAttribute, {
    variables: {
      GetAttributeInput: {
        name: "brand",
        ...(isAdmin && { companiesIds: filterArgsState.companiesIds ?? [] }),
      },
    },
    skip: !!isAdmin && !companiesData,
  });

  const {
    data: stateTypeData,
    error: stateTypeError,
    loading: stateTypeLoading,
  } = useQuery<GetAttributeResponse, GetAttributeInput>(getAttribute, {
    variables: {
      GetAttributeInput: {
        name: "stateType",
      },
    },
    skip: !!isAdmin && !companiesData,
  });

  const companiesOptions: OptionType[] =
    companiesData?.getCompanies.map(({ id, storeName }) => ({
      value: `${id}`,
      label: storeName,
    })) || [];

  // handler functions
  const showFilterModalHandler = () => {
    setShowFilterModal(true);
  };

  const hideFilterModalHandler = () => {
    setShowFilterModal(false);
  };

  const openAddProductPageHandler = () => {
    navigate(`/${ajouterUnProduitRoute}`, { replace: false });
  };

  const openFileImportPage = () => {
    navigate(`/${importerParFichierRoute}`, { replace: false });
  };

  const openMyImportspageHandler = () => {
    navigate(`/${mesImportationsRoute}`, { replace: false });
  };

  const openEditProduct = (variantId: number) => {
    navigate(`/${ajouterUnProduitRoute}?variantId=${variantId}`, {
      replace: false,
    });
  };

  const titleSearchChangeHandler = useCallback(
    debounce((value: string) => {
      dispatch(
        onFilterArgsChange({
          filterArg: "titleSearch",
          titleSearch: value,
        })
      );
    }, 500),
    []
  );

  const companiesFilterChangeHandler = (selectedOptions: OptionType[]) => {
    dispatch(
      onFilterArgsChange({
        filterArg: "companiesIds",
        companiesIds: selectedOptions.map(({ value }) => +value),
      })
    );
  };

  const checkboxClickHandler = (productInput: Product) => {
    const selectedCheckboxIndex = selectedProducts.findIndex(
      ({ id }) => productInput.id === id
    );
    if (selectedCheckboxIndex > -1) {
      setIsSelectedAll(false);
      setSelectedProducts((prev) => {
        return prev.filter(({ id }) => productInput.id !== id);
      });
      return;
    }
    setSelectedProducts((prev) => {
      return [...prev, productInput];
    });
  };

  const selectAllChangeHandler = (selected: boolean) => {
    if (selected) {
      setIsSelectedAll(true);
      setSelectedProducts(productVariants || []);
      return;
    }
    setIsSelectedAll(false);
    setSelectedProducts([]);
  };

  const [getProductsCountTrigger, { error: productsCountError }] = useLazyQuery<
    GetProductsCountResponse,
    GetProductVariantsInput
  >(getProductsCount);

  const [
    getProductVariantsTrigger,
    { error: getProductVariantsError, loading: getProductVariantsLoading },
  ] = useLazyQuery<GetProductVariantsResponse, GetProductVariantsInput>(
    getProductVariants
  );

  const exportCatalogue = async () => {
    const productsToExport: Product[] = [];
    if (isSelectedAll) {
      let cursor: number | undefined = undefined;
      do {
        //@ts-ignore
        const { data, error } = await getProductVariantsTrigger({
          variables: {
            ProductVariantsSelectArgs: {
              ...filterArgsState,
              cursor: cursor,
              take: 15000,
            },
          },
        });
        if (data) {
          cursor = data?.getProductVariants?.nextCursor;
          productsToExport.push(...data.getProductVariants.productVariants);
        } else if (error) {
          const { message } = error;
          setErrorModalMessage(message);
          return;
        } else {
          cursor = undefined;
        }
      } while (!!cursor);
    } else {
      productsToExport.push(...selectedProducts);
    }

    const mappedProductsToExport = productsToExport.map(
      ({
        categoryId,
        vendorCategory,
        id,
        parentId,
        createdAt,
        status,
        ...product
      }) => ({
        id,
        parentId,
        createdAt,
        status,
        category: vendorCategory?.name ?? "",
        categoryId,
        ...product,
      })
    );

    // create file using xlsx
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(mappedProductsToExport);

    // make cells wrap text
    utils.book_append_sheet(wb, ws, "Catalogue");
    setIsPreparingFile(false);
    writeFile(wb, "Catalogue.xlsx");
  };

  const exportCatalogueHandler = () => {
    setIsPreparingFile(true);
    setTimeout(() => {
      exportCatalogue();
    }, 500);
  };

  const showActionsModalHandler = () => {
    setShowActionsModal(true);
  };

  const hideActionsModalHandler = () => {
    setShowActionsModal(false);
  };

  const fetchProductVariants = async (init: boolean) => {
    if (getProductVariantsLoading) return;

    if (showActionsModal) hideActionsModalHandler();

    const { companiesIds, ...filtersData } = filterArgsState;

    if (init) {
      setProductVariants(undefined);
      dispatch(
        onFilterArgsChange({
          filterArg: "cursor",
          cursor: undefined,
        })
      );
      setSelectedProducts([]);
      setIsSelectedAll(false);

      const { data: getProductsCountData } = await getProductsCountTrigger({
        variables: {
          ProductVariantsSelectArgs: {
            ...filtersData,
            cursor: undefined,
            ...(isAdmin && { companiesIds }),
          },
        },
      });

      if (getProductsCountData) {
        setProductsCount(getProductsCountData.getProductsCount.count);
      }
    }

    const { data, error } = await getProductVariantsTrigger({
      variables: {
        ProductVariantsSelectArgs: {
          ...filtersData,
          ...(init && { cursor: undefined }),
          ...(isAdmin && { companiesIds }),
        },
      },
    });

    if (data) {
      const { getProductVariants } = data;
      const { hasMoreData, nextCursor, productVariants } = getProductVariants;
      setHasMoreData(hasMoreData);
      dispatch(onFilterArgsChange({ filterArg: "cursor", cursor: nextCursor }));

      if (isSelectedAll) {
        setSelectedProducts((prevSelectedProducts) => [
          ...prevSelectedProducts,
          ...productVariants,
        ]);
      }

      setProductVariants((prevProductVariants) =>
        prevProductVariants
          ? [...prevProductVariants, ...productVariants]
          : [...productVariants]
      );
    } else if (error) {
      const { message } = error;
      setErrorModalMessage(message);
    }
  };

  const deleteProductsSuccessHandler = () => {
    fetchProductVariants(true);
  };

  const deleteProductsErrorHandler = (error: ApolloError) => {
    const { message } = error;
    setErrorModalMessage(message);
  };

  const hideErrorPopupHandler = () => {
    setErrorModalMessage("");
    fetchProductVariants(true);
  };

  const { trigger: deleteProductTrigger } = useMutationWithCallbacks<
    DeleteProductsByIdsResponse,
    DeleteProductsByIdsInput
  >(
    deleteProductsByIds,
    deleteProductsSuccessHandler,
    deleteProductsErrorHandler
  );

  const { trigger: deleteAllProductsTrigger } = useMutationWithCallbacks<
    DeleteAllProductsResponse,
    GetProductVariantsInput
  >(
    deleteAllProducts,
    deleteProductsSuccessHandler,
    deleteProductsErrorHandler
  );

  const deleteProducts = async () => {
    setProductVariants(undefined);
    if (isSelectedAll) {
      await deleteAllProductsTrigger({
        variables: {
          ProductVariantsSelectArgs: {
            ...filterArgsState,
            cursor: undefined,
            take: undefined,
          },
        },
      });
      return;
    }

    await deleteProductTrigger({
      variables: {
        DeleteProductsByIdsInput: {
          ids: selectedProducts.map(({ id }) => id || 0),
        },
      },
    });
  };

  const deleteProductsHandler = () => {
    deleteProducts();
  };

  const clearSelectedProductsHandler = () => {
    setIsSelectedAll(false);
    setSelectedProducts([]);
  };

  const clearAllFiltersHandler = () => {
    dispatch(
      onFilterArgsChange({
        filterArg: "clearAll",
      })
    );
  };

  useEffect(() => {
    if (searchParams.has("inStock"))
      dispatch(
        onFilterArgsChange({
          filterArg: "inStock",
          inStock: true,
        })
      );
    if (searchParams.has("outOfStock"))
      dispatch(
        onFilterArgsChange({
          filterArg: "notInStock",
          notInStock: true,
        })
      );
    if (searchParams.has("published"))
      dispatch(
        onFilterArgsChange({
          filterArg: "published",
          published: true,
        })
      );
    if (searchParams.has("refused"))
      dispatch(
        onFilterArgsChange({
          filterArg: "refused",
          refused: true,
        })
      );
  }, []);

  useEffect(() => {
    if (!vendorCategoriesData || !brandData || !stateTypeData) return;

    const count = getFilterCount(filterArgsState);
    setFilterCount(count);

    fetchProductVariants(true);
  }, [
    filterArgsState.orderBy,
    filterArgsState.brand,
    filterArgsState.categoryId,
    filterArgsState.createdAtFrom,
    filterArgsState.createdAtTo,
    filterArgsState.updatedAtFrom,
    filterArgsState.updatedAtTo,
    filterArgsState.inStock,
    filterArgsState.waitingForValidation,
    filterArgsState.modificationRequested,
    filterArgsState.refused,
    filterArgsState.published,
    filterArgsState.incomplete,
    filterArgsState.imageImportStatus,
    filterArgsState.imagePresent,
    filterArgsState.stateType,
    filterArgsState.forSale,
    filterArgsState.forRent,
    filterArgsState.forSubscription,
    filterArgsState.titleSearch,
    vendorCategoriesData,
    brandData,
    stateTypeData,
  ]);

  useEffect(() => {
    setTitleSearch(filterArgsState.titleSearch);
  }, [filterArgsState.titleSearch]);

  useEffect(() => {
    refetchVendorCategories({
      GetVendorCategoriesInput: !isAdmin
        ? undefined
        : { companiesIds: filterArgsState.companiesIds ?? [] },
    });

    refetchBrand({
      GetAttributeInput: {
        name: "brand",
        ...(isAdmin && { companiesIds: filterArgsState.companiesIds ?? [] }),
      },
    });
  }, [filterArgsState.companiesIds]);

  return (
    <>
      <LoadingPopup show={isPreparingFile} message="Preparing file..." />
      <ErrorPopup
        onCancel={hideErrorPopupHandler}
        show={!!errorModalMessage}
        message={errorModalMessage}
      />
      <SideModal onCancel={hideFilterModalHandler} show={showFilterModal}>
        <CataloguesFilterForm
          brandValues={brandData?.getAttribute?.values || []}
          vendorCategories={vendorCategoriesData?.getVendorCategories || []}
          stateTypeValues={stateTypeData?.getAttribute?.values || []}
        />
      </SideModal>
      <CustomModal onCancel={hideActionsModalHandler} show={showActionsModal}>
        <ProductsActionsModal
          selectedProducts={selectedProducts}
          deleteProductsHandler={deleteProductsHandler}
          exportProductsHandler={exportCatalogueHandler}
          fetchProductVariants={fetchProductVariants}
          isSelectedAll={isSelectedAll}
          productsCount={productsCount}
        />
      </CustomModal>
      {vendorCategoriesError ||
      brandError ||
      stateTypeError ||
      companiesError ||
      getProductVariantsError ||
      productsCountError ? (
        <div className={`${styles.container}`}>
          {vendorCategoriesError && (
            <div>{`Categories Error: ${vendorCategoriesError.message}`}</div>
          )}
          {brandError && <div>{`Brand Error: ${brandError.message}`}</div>}{" "}
          {stateTypeError && (
            <div>{`State Type Error: ${stateTypeError.message}`}</div>
          )}{" "}
          {companiesError && (
            <div>{`Brand Error: ${companiesError.message}`}</div>
          )}
          {getProductVariantsError && (
            <div>{`Products Error: ${getProductVariantsError.message}`}</div>
          )}
          {productsCountError && (
            <div>{`Products count Error: ${productsCountError.message}`}</div>
          )}
        </div>
      ) : !vendorCategoriesData ||
        !brandData ||
        !stateTypeData ||
        !!vendorCategoriesLoading ||
        !!brandLoading ||
        !!stateTypeLoading ? (
        <FullPageLoader />
      ) : (
        <div className={`${styles.container}`}>
          <div className={`${styles.toolbar}`}>
            <div className={`${styles.leftColumn}`}>
              <InputWithIcon
                onChange={(value: string | number) => {
                  setTitleSearch(value.toString());
                  titleSearchChangeHandler(value.toString());
                }}
                value={titleSearch}
                borderRadius="5px"
                noBorder={true}
                backgroundColor="white"
                iconPath="/assets/search-icon.svg"
                placeholder="Chercher un produit..."
              />
              <div className={styles.countContainer}>
                {!!productsCount && !!productVariants?.length && (
                  <p>
                    Fetched {productVariants?.length || 0}/{productsCount}
                  </p>
                )}
              </div>
            </div>

            {!isAdmin && (
              <div className={`${styles.rightColumn}`}>
                <CustomButton
                  backgroundColor={colors.$primary}
                  color="white"
                  width="fit-content"
                  borderRadius="8px"
                  padding="1rem 1.5rem"
                  onClick={openMyImportspageHandler}
                >
                  Mes importations
                </CustomButton>
                <CustomButton
                  backgroundColor={colors.$primary}
                  color="white"
                  width="fit-content"
                  borderRadius="8px"
                  padding="1rem 1.5rem"
                  onClick={openAddProductPageHandler}
                >
                  Ajouter un produit
                </CustomButton>
                <CustomButton
                  backgroundColor={colors.$primary}
                  color="white"
                  width="fit-content"
                  borderRadius="8px"
                  padding="1rem 1.5rem"
                  onClick={openFileImportPage}
                >
                  Importer par fichier
                </CustomButton>
              </div>
            )}
          </div>
          <div className={`${styles.statusBar}`}>
            <div className={styles.productsSelectionSection}>
              <div className={`${styles.selectedCount}`}>
                <p>
                  {isSelectedAll ? productsCount : selectedProducts.length}{" "}
                  produits sélectionné{selectedProducts.length > 1 ? "s" : ""}
                </p>
                {selectedProducts.length > 0 && (
                  <div
                    className={`${styles.clearSelectionButton}`}
                    onClick={() => {
                      clearSelectedProductsHandler();
                    }}
                  >
                    <SVGContainer
                      height="16px"
                      width="17px"
                      imagePath="/assets/delete-icon.svg"
                    />
                    <p>Supprimer la sélection</p>
                  </div>
                )}
              </div>
              {selectedProducts.length > 0 && (
                <div className={`${styles.controlButtonsContainer}`}>
                  {isAdmin ? (
                    <div
                      className={`${styles.exportButton}`}
                      onClick={showActionsModalHandler}
                    >
                      <SVGContainer
                        height="16px"
                        width="17px"
                        imagePath="/assets/action-icon.svg"
                      />
                      <p>Actions</p>
                    </div>
                  ) : (
                    <>
                      <div
                        className={`${styles.exportButton}`}
                        onClick={exportCatalogueHandler}
                      >
                        <SVGContainer
                          height="16px"
                          width="17px"
                          imagePath="/assets/export-icon.svg"
                        />
                        <p>Exporter</p>
                      </div>
                      <div
                        className={`${styles.deleteButton}`}
                        onClick={deleteProductsHandler}
                      >
                        <SVGContainer
                          height="16px"
                          width="17px"
                          imagePath="/assets/delete-icon.svg"
                        />
                        <p>supprimer</p>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
            <div className={`${styles.filtersSelectionContainer}`}>
              {isAdmin && (
                <div className={`${styles.selectContainer}`}>
                  <MultipleSelectInput
                    fontSize="12px"
                    backgroundColor="white"
                    options={companiesOptions}
                    onChange={(options: OptionType[]) => {
                      companiesFilterChangeHandler(options);
                    }}
                    value={companiesOptions.filter((option) =>
                      filterArgsState.companiesIds?.includes(+option.value)
                    )}
                  />
                </div>
              )}
              {!!filterCount && (
                <>
                  <div
                    className={styles.clearFilterButton}
                    onClick={clearAllFiltersHandler}
                  >
                    <p className={`${styles.label}`}>
                      Effacer tous les filtres
                    </p>
                  </div>
                  <div className={styles.filterCount}>
                    <p>{filterCount} filtres sélectionnés</p>
                  </div>
                </>
              )}
              <div
                className={`${styles.filterButtonContainer}`}
                onClick={showFilterModalHandler}
              >
                <SVGContainer
                  height="16px"
                  width="17px"
                  imagePath="/assets/filter-icon.svg"
                />
                <p>Filtrer les produits</p>
              </div>
            </div>
          </div>
          <CataloguesTable
            productVariants={productVariants}
            selectedRows={selectedProducts}
            isSelectedAll={isSelectedAll}
            onCheckboxChange={checkboxClickHandler}
            onSelectAllChange={selectAllChangeHandler}
            fetchMoreData={() => {
              fetchProductVariants(false);
            }}
            hasMoreData={hasMoreData}
            vendorCategories={vendorCategoriesData.getVendorCategories}
            onRowClick={openEditProduct}
            showCompanyColumn={isAdmin}
          />
        </div>
      )}
    </>
  );
};

export default MonCatalogue;
