import Joi from "joi";

import { SendForgotPasswordCodeInput } from "~/types/input/auth.types";

export const sendForgotPasswordCodeValidationSchema =
  Joi.object<SendForgotPasswordCodeInput>({
    username: Joi.string()
      .email({ tlds: false })
      .required()
      .error(new Error("Email invalide.")),
  });
