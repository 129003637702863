import { ApolloError } from "@apollo/client";
import { useState } from "react";

import {
  UPDATE_REEDOO_DELIVERY,
  UpdateReedooDeliveryApiInput,
  UpdateReedooDeliveryApiResponse,
} from "~/api/graphql/company";
import { CustomButton } from "~/components/form/CustomButton";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { initReedooCarrierState } from "~/constants/states";
import { colors } from "~/constants/styles";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import { Carrier } from "~/types/data/Carrier.types";
import { ReedooCarrier } from "~/types/data/Carrier.types";

import { ReedooCarrierSection } from "./ReedooCarrierSection";

interface Props {
  reedooCarriers: ReedooCarrier[];
  carriers: Carrier[];
}

export const ReedooDelivery = ({ reedooCarriers, carriers }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { companyAccount, setCompanyAccount } = useCompanyAccountContext();

  const updateReedooDeliverySuccessHandler = ({
    updateReedooDelivery: reedooCarriers,
  }: UpdateReedooDeliveryApiResponse) => {
    setSuccessMessage("Reedoo delivery section updated successfully.");
    setCompanyAccount((prev) => {
      return {
        ...prev,
        reedooCarriers,
      };
    });
  };

  const updateReedooDeliveryErrorHandler = (error: ApolloError) => {
    setErrorMessage(error.message);
  };

  const { trigger: updateReedooDeliveryTrigger } = useMutationWithCallbacks<
    UpdateReedooDeliveryApiResponse,
    UpdateReedooDeliveryApiInput
  >(
    UPDATE_REEDOO_DELIVERY,
    updateReedooDeliverySuccessHandler,
    updateReedooDeliveryErrorHandler
  );

  const addCarrierSection = () => {
    setCompanyAccount((prev) => {
      return {
        ...prev,
        reedooCarriers: [...prev.reedooCarriers, initReedooCarrierState],
      };
    });
  };

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    // validate
    const isValid = !companyAccount.reedooCarriers.filter(
      ({ carrierId }) => !carrierId
    ).length;

    if (!isValid) {
      setErrorMessage("Invalid carrier input");
      setIsLoading(false);
      return;
    }

    await updateReedooDeliveryTrigger({
      variables: {
        UpdateReedooDeliveryInput: {
          companyId: companyAccount.id,
          reedooCarriers: companyAccount.reedooCarriers,
        },
      },
    });

    setIsLoading(false);
  };

  return (
    <form
      className="myAccountFormSection"
      onSubmit={(e) => {
        submitHandler(e);
      }}
    >
      <h3 className="title">Livraison</h3>
      <div style={{ marginBottom: "1rem" }}>
        {companyAccount.reedooCarriers.map((reedooCarrier, index) => {
          return (
            <ReedooCarrierSection
              index={index}
              key={index}
              reedooCarriers={reedooCarriers}
              carriers={carriers}
            />
          );
        })}
        <CustomButton
          backgroundColor={colors.$primary}
          color="white"
          width="fit-content"
          height="fit-content"
          borderRadius="8px"
          padding="0.5rem 1.5rem"
          onClick={addCarrierSection}
        >
          Ajouter un transporteur
        </CustomButton>
      </div>
      <div className="messageContainer">
        {!!errorMessage && <p className="errorMessage">{errorMessage}</p>}
        {!!successMessage && <p>{successMessage}</p>}
      </div>
      <div className="buttonContainer">
        <CustomButton
          backgroundColor={colors.$primary}
          color="white"
          width="fit-content"
          borderRadius="8px"
          padding="0.5rem 1.5rem"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <SimpleLoader size="size2" /> : "Enregistrer"}
        </CustomButton>
      </div>
    </form>
  );
};
