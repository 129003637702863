import validateEmail from "~/api/validation/email";
import { UserFormState } from "~/types/data/User.types";

export const validateUser = (
  input: UserFormState,
  isCreateMode: boolean,
  isSeller: boolean
) => {
  if (!input.email || !validateEmail(input.email)) {
    return "Invalid Email";
  }

  if (!input.resolveFieldUsername) {
    return "Invalid Username";
  }

  // if isSeller or isCreateMode
  if (isSeller || isCreateMode) {
    if (!input.permissionLevel) {
      return "Permission level is required";
    }
  }

  // if update
  if (!isCreateMode) {
    if (!input.id) {
      return "Id is required";
    }
  }

  // if create
  if (isCreateMode) {
    if (!input.role) {
      return "Role is required";
    }
  }
};
