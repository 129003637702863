import { formatPrice } from "~/util/functions/formatDecimal";

import styles from "./index.module.scss";

interface Props {
  originalAmount: number;
  shippingAmount: number;
  refundAmount: number;
  refundShippingAmount: number;
  commissionAmount: number;
  finalTotalPrice: number;
}

const OrderTotalCard = ({
  commissionAmount,
  originalAmount,
  refundAmount,
  refundShippingAmount,
  shippingAmount,
  finalTotalPrice,
}: Props) => {
  const totalAmount = originalAmount + shippingAmount;
  const totalRefundAmount = refundAmount + refundShippingAmount;
  const finalShippingAmount = shippingAmount - refundShippingAmount;

  const formattedTotalAmount = formatPrice(totalAmount);
  const formattedShippingAmount = formatPrice(shippingAmount);
  const fomrattedTotalRefundAmount = formatPrice(totalRefundAmount);
  const formattedRefundShippingAmount = formatPrice(refundShippingAmount);
  const formattedFinalTotalPrice = formatPrice(finalTotalPrice);
  const formattedFinalShippingAmount = formatPrice(finalShippingAmount);
  const formattedCommissionAmount = formatPrice(commissionAmount);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.leftColumn}`}></div>
      <div className={`${styles.rightColumn}`}>
        <div>
          <p className={`${styles.boldText}`}>Montant total original :</p>
          <p>
            {formattedTotalAmount} (Dont frais de port TTC :{" "}
            {formattedShippingAmount})
          </p>
        </div>
        <div>
          <p className={`${styles.boldText}`}>Montant remboursé :</p>
          <p>
            {fomrattedTotalRefundAmount} (Remboursement d'expédition :{" "}
            {formattedRefundShippingAmount})
          </p>
        </div>
        <div>
          <p className={`${styles.boldText}`}>Montant total :</p>
          <p>
            {formattedFinalTotalPrice} (Dont frais de port TTC :{" "}
            {formattedFinalShippingAmount})
          </p>
        </div>
        <div>
          <p className={`${styles.boldText}`}>Commission totale :</p>
          <p>{formattedCommissionAmount} TTC</p>
        </div>
      </div>
    </div>
  );
};

export default OrderTotalCard;
