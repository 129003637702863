import { useNavigate } from "react-router-dom";

import SVGContainer from "~/components/UI/SVGContainer";
import { routePaths } from "~/navigation/routes";

import styles from "./index.module.scss";

const HeaderLogo = () => {
  const { dashboardRoute } = routePaths;
  const navigate = useNavigate();
  return (
    <div className={`${styles.logo}`}>
      <SVGContainer
        width="200px"
        height="100%"
        imagePath="/assets/logo.svg"
        onClick={() => {
          navigate(`/${dashboardRoute}`, { replace: false });
        }}
      />
    </div>
  );
};

export default HeaderLogo;
