export const getProfileName = (firstName?: string, lastName?: string) => {
  const name: string[] = [];
  if (firstName) name.push(firstName);
  if (lastName) name.push(lastName);

  return name.length ? name.join(" ") : "N/A";
};

export const getBuyerName = (
  firstName?: string,
  lastName?: string,
  shippingAdressName?: string
) => {
  const name =
    firstName || lastName
      ? getProfileName(firstName, lastName)
      : shippingAdressName;

  return name || "N/A";
};
