import StatusLabel from "~/components/UI/StatusLabel";
import SVGContainer from "~/components/UI/SVGContainer";
import {
  ImportedFile,
  ImportedFileStatusEnum,
} from "~/types/data/ImportedFile.type";
import { formatDate, formatHour } from "~/util/functions/formatDate";

import styles from "./index.module.scss";

interface Props {
  importedFile: ImportedFile;
}

export const ImportedFileCard = ({ importedFile }: Props) => {
  const {
    fileName,
    createdAt,
    status,
    successfullProducts = 0,
    totalProducts = 0,
    errorFileFullPath,
    successFileFullPath,
    fileFullPath,
    incompleteProducts,
    incompleteFileFullPath,
  } = importedFile;

  const invalidProducts = totalProducts - successfullProducts;

  const createdAtDate = formatDate(createdAt);
  const createdAtTime = formatHour(createdAt);

  const linkClickHandler = (url?: string) => {
    if (url) {
      // const link = document.createElement("a");
      // link.download = "filename";
      // link.href = url;
      // link.click();
      window.open(url);
    }
  };

  const statusLabelColor =
    status === ImportedFileStatusEnum.DONE
      ? "green"
      : status === ImportedFileStatusEnum.ERROR
      ? "red"
      : status === ImportedFileStatusEnum.ON_HOLD
      ? "#dd8500"
      : "blue";

  const invalidProductsClickable = errorFileFullPath && invalidProducts;
  const incompleteProductsClickable =
    incompleteFileFullPath && incompleteProducts;
  const mappedProductsClickable = successFileFullPath && successfullProducts;
  const originalFileClickable = fileFullPath && totalProducts;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <h3>{fileName}</h3>
          <p>
            {createdAtDate} ({createdAtTime})
          </p>
        </div>
        <div className={styles.statusContainer}>
          <StatusLabel
            backgroundColor={statusLabelColor}
            color="white"
            label={status}
          />
        </div>
      </div>
      {status === ImportedFileStatusEnum.ERROR && (
        <div className={styles.errorMessage}>
          <p>Unexpected Error: Please contact Reedoo for support</p>
        </div>
      )}

      <div className={styles.detailsContainer}>
        <div className={styles.informationContainer}>
          <p>
            <span>Invalid products: </span>
            {invalidProducts}
          </p>

          <p>
            <span>Valid products: </span>
            {successfullProducts}
          </p>

          <p>
            <span>Incomplete products: </span>
            {incompleteProducts !== null
              ? `${incompleteProducts}/${successfullProducts}`
              : "unavailable"}
          </p>

          <p>
            <span>Total products: </span>
            {totalProducts}
          </p>
        </div>
        <div className={styles.filesContainer}>
          <div
            onClick={
              invalidProductsClickable
                ? () => {
                    linkClickHandler(errorFileFullPath);
                  }
                : () => {}
            }
            className={`${styles.linkContainer} ${
              invalidProductsClickable ? styles.clickable : ""
            }`}
          >
            <SVGContainer
              height="14px"
              width="15px"
              imagePath={
                invalidProductsClickable
                  ? "/assets/excel-icon.svg"
                  : "/assets/red-circular-close-icon.svg"
              }
            />
            <p>invalid_products_data.xlsx</p>
          </div>

          <div
            onClick={
              mappedProductsClickable
                ? () => {
                    linkClickHandler(successFileFullPath);
                  }
                : () => {}
            }
            className={`${styles.linkContainer} ${
              mappedProductsClickable ? styles.clickable : ""
            }`}
          >
            <SVGContainer
              height="14px"
              width="15px"
              imagePath={
                mappedProductsClickable
                  ? "/assets/excel-icon.svg"
                  : "/assets/red-circular-close-icon.svg"
              }
            />
            <p>mapped_products_data.xlsx</p>
          </div>
          <div
            onClick={
              incompleteProductsClickable
                ? () => {
                    linkClickHandler(incompleteFileFullPath);
                  }
                : () => {}
            }
            className={`${styles.linkContainer} ${
              incompleteProductsClickable ? styles.clickable : ""
            }`}
          >
            <SVGContainer
              height="14px"
              width="15px"
              imagePath={
                incompleteProductsClickable
                  ? "/assets/excel-icon.svg"
                  : "/assets/red-circular-close-icon.svg"
              }
            />
            <p>incomplete_products_data.xlsx</p>
          </div>
          <div
            onClick={
              originalFileClickable
                ? () => {
                    linkClickHandler(fileFullPath);
                  }
                : () => {}
            }
            className={`${styles.linkContainer} ${
              originalFileClickable ? styles.clickable : ""
            }`}
          >
            <SVGContainer
              height="14px"
              width="15px"
              imagePath={
                originalFileClickable
                  ? "/assets/excel-icon.svg"
                  : "/assets/red-circular-close-icon.svg"
              }
            />
            <p>original_file.xlsx</p>
          </div>
        </div>
      </div>
    </div>
  );
};
