import { SimpleLoader } from "~/components/UI/SimpleLoader";
import SVGContainer from "~/components/UI/SVGContainer";

import styles from "./index.module.scss";

interface Props {
  label: string;
  onChange: (imageFile: File | undefined) => void;
  value: string | undefined | null;
  fontSize?: string;
  borderRadius?: string;
  backgroundColor?: string;
  noBorder?: boolean;
  textColor?: string;
  id: string;
  loading?: boolean;
  disabled?: boolean;
  openLink?: string;
}

export const FilePicker = ({
  label,
  onChange,
  value,
  fontSize,
  borderRadius,
  backgroundColor,
  textColor,
  id,
  loading,
  disabled,
  openLink,
}: Props) => {
  const filePickedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files?.length > 0) {
      onChange(files[0]);
    }
  };

  const filePickerValue = value || "";
  const extension = filePickerValue.split(".").slice(-1);
  const shortValue =
    filePickerValue.length > 30
      ? filePickerValue.substring(0, 15) + "..." + extension
      : value;

  return (
    <div className={`${styles.container}`} style={{ fontSize: fontSize }}>
      {label && (
        <div className={`${styles.label}`}>
          <label>{label}</label>
        </div>
      )}
      <div
        className={`${styles.inputContainer}`}
        style={{
          borderRadius: borderRadius,
          backgroundColor: backgroundColor,
          color: textColor,
        }}
      >
        <div className={`${styles.fileName}`}>
          {openLink ? (
            <a href={openLink} target="_blank" rel="noreferrer">
              {shortValue}
            </a>
          ) : (
            <p>{shortValue}</p>
          )}

          {/* {!!shortValue && (
            <div
              className={`${styles.deleteButton}`}
              style={{ cursor: disabled ? "default" : "pointer" }}
              onClick={disabled ? undefined : removeFileHandler}
            >
              <p>x</p>
            </div>
          )} */}
        </div>
        <div className={`${styles.filePickerLabel}`}>
          {loading ? (
            <SimpleLoader size="size1" fill="black" />
          ) : (
            <label htmlFor={id}>
              <SVGContainer
                height="16px"
                width="16px"
                imagePath="/assets/upload-icon.svg"
                onClick={disabled ? undefined : () => {}}
              />
            </label>
          )}
        </div>
        <input
          type="file"
          id={id}
          onChange={filePickedHandler}
          disabled={disabled}
          onClick={(e) => {
            if (!disabled) e.currentTarget.value = "";
          }}
          hidden
        />
      </div>
    </div>
  );
};
