import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import Backdrop from "../Backdrop";
import SVGContainer from "../SVGContainer";
import styles from "./index.module.scss";

interface Props {
  show?: boolean;
  onCancel: () => void;
  disableScroll?: boolean;
  disableCancel?: boolean;
  removeCloseButton?: boolean;
}

const ModalOverlay = ({
  children,
  onCancel,
  disableCancel,
  removeCloseButton,
}: React.PropsWithChildren<Props>) => {
  const content = (
    <div className={`${styles.modal}`}>
      {!removeCloseButton && (
        <div
          className={`${styles.closeButton}`}
          onClick={disableCancel ? () => {} : onCancel}
        >
          <SVGContainer
            height="16px"
            width="16px"
            imagePath="/assets/close-button.svg"
          />
        </div>
      )}
      {children}
    </div>
  );

  const element = document.getElementById("modal-hook");
  if (element) {
    return ReactDOM.createPortal(content, element);
  } else {
    return content;
  }
};

const CustomModal = ({
  show,
  onCancel,
  children,
  disableScroll,
  disableCancel,
  removeCloseButton,
}: React.PropsWithChildren<Props>) => {
  useEffect(() => {
    if (disableScroll && show) document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show]);
  return (
    <React.Fragment>
      {show && <Backdrop onClick={disableCancel ? () => {} : onCancel} />}
      {show && (
        <ModalOverlay
          onCancel={disableCancel ? () => {} : onCancel}
          removeCloseButton={removeCloseButton}
        >
          {children}
        </ModalOverlay>
      )}
    </React.Fragment>
  );
};

export default CustomModal;
