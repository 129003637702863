import { ApolloError } from "@apollo/client";
import { useState } from "react";
import { SingleValue } from "react-select";

import {
  GENERATE_API_TOKEN,
  GenerateApiTokenResponse,
  UPDATE_COMPANY_IDENTIFICATION,
  UpdateCompanyIdentificationApiInput,
  UpdateCompanyIdentificationApiResponse,
} from "~/api/graphql/company";
import { getMediaUploadPath } from "~/api/graphql/media/getMediaUploadPath";
import { CustomButton } from "~/components/form/CustomButton";
import { CustomInput } from "~/components/form/CustomInput";
import { FilePicker } from "~/components/form/FilePicker";
import { RichTextEditor } from "~/components/form/RichTextEditor";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import SVGContainer from "~/components/UI/SVGContainer";
import { countryList } from "~/constants/countries";
import { colors } from "~/constants/styles";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import {
  Company,
  UpdateCompanyIdentificationInput,
} from "~/types/data/Company.types";
import { updateIdentificationValidationSchema } from "~/util/validation/companyAccount/identification.schema";

import styles from "./index.module.scss";

export const Identification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [generateApiTokenLoading, setGenerateApiTokenLoading] = useState(false);

  const { companyAccount, setCompanyAccount } = useCompanyAccountContext();

  const updateCompanyIdentificationSuccessHandler = ({
    updateCompanyIdentification,
  }: UpdateCompanyIdentificationApiResponse) => {
    setSuccessMessage("Identification updated successfully.");
    setCompanyAccount((prev) => {
      return {
        ...prev,
        logo: updateCompanyIdentification.logo,
        logoFullPath: updateCompanyIdentification.logoFullPath,
      };
    });
  };

  const updateCompanyIdentificationErrorHandler = (error: ApolloError) => {
    setErrorMessage(error.message);
  };

  const { trigger: updateCompanyIdentificationTrigger } =
    useMutationWithCallbacks<
      UpdateCompanyIdentificationApiResponse,
      UpdateCompanyIdentificationApiInput
    >(
      UPDATE_COMPANY_IDENTIFICATION,
      updateCompanyIdentificationSuccessHandler,
      updateCompanyIdentificationErrorHandler
    );

  const generateApiTokenSuccessHandler = (data: GenerateApiTokenResponse) => {
    companyAccount.apiToken = data.generateApiToken.apiToken;
    setGenerateApiTokenLoading(false);
  };

  const generateApiTokenErrorHandler = (error: ApolloError) => {
    console.log("generateApiToken Error: " + error.message);
    setGenerateApiTokenLoading(false);
  };

  const { trigger: generateApiTokenTrigger } =
    useMutationWithCallbacks<GenerateApiTokenResponse>(
      GENERATE_API_TOKEN,
      generateApiTokenSuccessHandler,
      generateApiTokenErrorHandler
    );

  const countryOptions: OptionType[] = countryList.map(({ name, alpha2 }) => {
    return { label: name, value: alpha2.toUpperCase() };
  });

  const inputChangeHandler = <T extends keyof Company>(
    inputName: T,
    changes: Company[T]
  ) => {
    setCompanyAccount((state) => ({ ...state, [inputName]: changes }));
  };

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");
    setIsLoading(true);

    const input: UpdateCompanyIdentificationInput = {
      id: companyAccount.id,
      storeName: companyAccount.storeName,
      companyDescription: companyAccount.companyDescription,
      logo: companyAccount.logo,
      shippingCountry: companyAccount.shippingCountry || "",
      returnPolicyDescription: companyAccount.returnPolicyDescription,
    };

    const logoFile = companyAccount.logoFile;
    if (logoFile) {
      const response = await getMediaUploadPath({
        field: "logo",
        filename: logoFile.name,
        isPublic: true,
      });

      if (response.success) {
        const { path, url } = response.data;
        await fetch(url, { method: "put", body: logoFile });
        input.logo = path;
      } else {
        setErrorMessage("Uploading logo didn't work");
        setIsLoading(false);
        return;
      }
    }

    // validate input
    const result = updateIdentificationValidationSchema.validate(input);

    const { error } = result;
    if (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
      return;
    }

    // send update request
    await updateCompanyIdentificationTrigger({
      variables: { UpdateCompanyIdentificationInput: input },
    });

    setIsLoading(false);
  };

  const copyApiToken = () => {
    navigator.clipboard.writeText(companyAccount.apiToken || "");

    alert("Token copied successfully");
  };

  const generateApiTokenClickHandler = () => {
    setGenerateApiTokenLoading(true);
    generateApiTokenTrigger();
  };

  return (
    <form
      className="myAccountFormSection"
      onSubmit={(e) => {
        submitHandler(e);
      }}
    >
      <h3 className="title">Identification</h3>

      <h4 className="normalWeightText">INFORMATIONS DE LA BOUTIQUE</h4>
      <div className="singleInput">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("storeName", value.toString());
          }}
          value={companyAccount?.storeName}
          label="Nom de la boutique :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
      </div>
      <div className={styles.apiTokenContainer}>
        <div className={styles.inputContainer}>
          <CustomInput
            onChange={() => {}}
            value={companyAccount.apiToken}
            label="API Token :"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            disabled={true}
          />
        </div>
        <div className={styles.generateButtonContainer}>
          <CustomButton
            backgroundColor="#CCCCCC"
            height="40px"
            borderRadius="5px"
            width="6rem"
            onClick={generateApiTokenClickHandler}
          >
            {generateApiTokenLoading ? (
              <SimpleLoader size="size2" fill="black" />
            ) : (
              "Generate"
            )}
          </CustomButton>
        </div>
        <div className={styles.copyButtonContainer}>
          <CustomButton
            backgroundColor="#CCCCCC"
            height="40px"
            borderRadius="5px"
            onClick={copyApiToken}
            disabled={!companyAccount.apiToken}
          >
            <SVGContainer
              height="1.5rem"
              width="1.5rem"
              imagePath="/assets/copy-icon.svg"
            />
          </CustomButton>
        </div>
      </div>

      <div className="singleInput">
        <RichTextEditor
          onChange={(value: string | number) => {
            inputChangeHandler("companyDescription", value.toString());
          }}
          htmlValue={companyAccount?.companyDescription}
          label="Description :"
        />
      </div>
      <div className="doubleInputs">
        <FilePicker
          label="Logo :"
          onChange={(value: File | undefined) => {
            if (!value?.type.includes("image")) return;
            inputChangeHandler("logo", value?.name || "");
            inputChangeHandler("logoFile", value);
          }}
          value={companyAccount?.logo}
          fontSize="14px"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          id="logo"
          openLink={companyAccount?.logoFullPath}
        />
        <SelectInput
          label="Pays d'expedition"
          fontSize="14px"
          backgroundColor={colors.$inputGray}
          noBorder={true}
          options={countryOptions}
          onChange={(option: SingleValue<OptionType>) => {
            inputChangeHandler("shippingCountry", option?.value || "");
          }}
          value={companyAccount?.shippingCountry || ""}
        />
      </div>

      <div className="singleInput">
        <RichTextEditor
          onChange={(value: string) => {
            inputChangeHandler("returnPolicyDescription", value.toString());
          }}
          htmlValue={companyAccount?.returnPolicyDescription}
          label="Modalités de retour :"
        />
      </div>
      <div className="messageContainer">
        {!!errorMessage && <p className="errorMessage">{errorMessage}</p>}
        {!!successMessage && <p>{successMessage}</p>}
      </div>

      <div className="buttonContainer">
        <CustomButton
          backgroundColor={colors.$primary}
          color="white"
          width="fit-content"
          borderRadius="8px"
          padding="1rem 1.5rem"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <SimpleLoader size="size2" /> : "Enregistrer"}
        </CustomButton>
      </div>
    </form>
  );
};
