import { gql } from "@apollo/client";

import { graphqlGetBrandCompanyFields } from "~/constants/graphql";
import {
  BrandCompany,
  UpdateBrandCompanyInputType,
} from "~/types/data/BrandCompany.type";

export interface UpdateBrandCompanyInput {
  UpdateBrandCompanyInput: UpdateBrandCompanyInputType;
}

export interface UpdateBrandCompanyResponse {
  updateBrandCompany: BrandCompany;
}

export interface GetBrandCompaniesResponse {
  getBrandCompanies: BrandCompany[];
}

const brandCompanyFields = graphqlGetBrandCompanyFields.join(" ");

export const UPDATE_BRAND_COMPANY = gql`
  mutation ($UpdateBrandCompanyInput: UpdateBrandCompanyInput!) {
    updateBrandCompany(UpdateBrandCompanyInput: $UpdateBrandCompanyInput) {
        ${brandCompanyFields}
    }
  }
`;

export const GET_BRAND_COMPANIES = gql`
  query {
    getBrandCompanies {
      ${brandCompanyFields}
    }
  }
`;
