import { CustomButton } from "~/components/form/CustomButton";
import { SquareImage } from "~/components/UI/SquareImage";
import StatusLabel from "~/components/UI/StatusLabel";
import { colors } from "~/constants/styles";
import {
  GroupedRentOrderSingleProduct,
  SingleRentProductStatusEnum,
} from "~/types/data/RentOrder.type";
import { formatPrice } from "~/util/functions/formatDecimal";

import styles from "./index.module.scss";

interface Props {
  groupedProduct: GroupedRentOrderSingleProduct;
  showModalHandler?: () => void;
  isAdmin: boolean;
}

export const GroupedProductCard = ({
  groupedProduct,
  showModalHandler,
  isAdmin,
}: Props) => {
  const {
    product,
    quantity,
    pricePerPeriod,
    status,
    deposit,
    totalPaidRecurringAmount,
    totalPurchasedAmount,
    purchasePrice,
    currentPurchasePrice,
  } = groupedProduct;

  const totalPaidAmount = totalPaidRecurringAmount + totalPurchasedAmount;

  const formattedPricePerPeriod = formatPrice(pricePerPeriod);
  const formattedTotalPaidRecurringAmount = formatPrice(
    totalPaidRecurringAmount
  );
  const formattedTotalPaidAmount = formatPrice(totalPaidAmount);
  const formattedDeposit = formatPrice(deposit * quantity);
  const formattedPurchasePrice = formatPrice(purchasePrice);
  const formattedCurrentPurchasePrice = formatPrice(currentPurchasePrice);
  return (
    <div className={styles.container}>
      <div className={styles.summaryContainer}>
        <div className={styles.imageContainer}>
          <SquareImage url={product.image1FullPath || ""} borderRadius="8px" />
        </div>
        <div className={styles.details}>
          <div className={styles.cardHeader}>
            <div className={styles.leftSide}>
              <p className={`${styles.productTitle}`}>{product.title}</p>
              <p>x{quantity}</p>
            </div>
            <div className={styles.statusContainer}>
              {status === SingleRentProductStatusEnum.REVIEW_REQUESTED &&
                !isAdmin && (
                  <CustomButton
                    color={colors.$primaryDark}
                    borderColor={colors.$primaryDark}
                    borderRadius="5px"
                    height="fit-content"
                    padding="0.2rem 0.5rem"
                    width="fit-content"
                    onClick={() => {
                      if (showModalHandler) showModalHandler();
                    }}
                  >
                    Review
                  </CustomButton>
                )}
              <StatusLabel
                backgroundColor="green"
                label={status}
                color="white"
              />
            </div>
          </div>
          <div className={`${styles.attributesContainer}`}>
            <p>
              <span className={`${styles.emphasizedText}`}>SKU Produit : </span>
              {product.sku}
            </p>
            {product.size && (
              <p>
                <span className={`${styles.emphasizedText}`}>Taille : </span>
                {product.size}
              </p>
            )}
            {product.color && (
              <p>
                <span className={`${styles.emphasizedText}`}>Couleur : </span>
                {product.color}
              </p>
            )}
          </div>
          <div className={`${styles.attributesContainer}`}>
            <p>
              <span className={`${styles.emphasizedText}`}>
                Prix par période :{" "}
              </span>
              {formattedPricePerPeriod}
            </p>
          </div>

          <div className={`${styles.attributesContainer}`}>
            <p>
              <span className={`${styles.emphasizedText}`}>
                Prix cumulé loué :{" "}
              </span>
              {formattedTotalPaidRecurringAmount}
            </p>
            <p>
              <span className={`${styles.emphasizedText}`}>Global CA : </span>
              {formattedTotalPaidAmount}
            </p>
            <p>
              <span className={`${styles.emphasizedText}`}>Caution : </span>
              {formattedDeposit}
            </p>
            <p>
              <span className={`${styles.emphasizedText}`}>
                Prix achat départ/Actuel :{" "}
              </span>
              {formattedPurchasePrice}/{formattedCurrentPurchasePrice}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
