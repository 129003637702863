import { sendRequest } from "~/api/clients/axios";
import { AxiosHttpError } from "~/types/common/error.types";
import { ShopifyPermissionUrlResponse } from "~/types/common/rest.types";

export default async function getShopifyPermissionUrl(
  shopName: string
): Promise<ShopifyPermissionUrlResponse> {
  //removing the protocol and the path from shop name
  const finalShopName = shopName.includes("https://")
    ? shopName.replace("https://", "").split("/")[0]
    : shopName.split("/")[0];
  try {
    const { data } = await sendRequest<unknown, { url: string }>(
      "get",
      `shopify/permission?shopName=${finalShopName}`
    );
    return { success: true, data: { url: data.url } };
  } catch (err) {
    const axiosError = err as AxiosHttpError;
    if (axiosError.response?.data) {
      const { message } = axiosError.response.data;
      return {
        success: false,
        error: typeof message === "string" ? message : message[0],
      };
    }
  }
  return { success: false, error: "Unknown Error" };
}
