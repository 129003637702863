import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";

import {
  GET_SUBSCRIPTION_PACKAGE_BY_ID,
  GetSubscriptionPackageByIdInput,
  GetSubscriptionPackageByIdResponse,
} from "~/api/graphql/subscriptionPackage";
import FullPageLoader from "~/components/UI/FullPageLoader";
import SVGContainer from "~/components/UI/SVGContainer";
import { useUserContext } from "~/context/userContext";
import { routePaths } from "~/navigation/routes";
import { PermissionLevelEnum } from "~/types/data/User.types";

import { CategoriesAndProductsSection } from "./CategoriesAndProductsSection";
import { FormulasSection } from "./FormulasSection";
import { ImportFileSection } from "./ImportFileSection";
import styles from "./index.module.scss";
import { Title } from "./Title";

export const SubscriptionDetails = () => {
  const { maMarqueRoute } = routePaths;
  const { userState } = useUserContext();
  const { id } = useParams();

  const subscriptionId = Number(id) || 0;

  const { data, loading } = useQuery<
    GetSubscriptionPackageByIdResponse,
    GetSubscriptionPackageByIdInput
  >(GET_SUBSCRIPTION_PACKAGE_BY_ID, {
    variables: {
      GetSubscriptionPackageByIdInput: {
        subscriptionId,
      },
    },
    skip: !id,
  });

  const disabled =
    userState?.connected &&
    userState.permissionLevel === PermissionLevelEnum.MEMBER;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {loading ? (
        <FullPageLoader />
      ) : !data ? (
        <div className={styles.errorContainer}>
          <p>Subscription not found!</p>
        </div>
      ) : (
        <div className={styles.pageContainer}>
          <div className={styles.backButtonContainer}>
            <NavLink to={`/${maMarqueRoute}`} className={styles.backButton}>
              <SVGContainer
                imagePath="/assets/back-button.svg"
                height="17px"
                width="20px"
              />
              <p>Retourner</p>
            </NavLink>
          </div>
          <Title title={data.getSubscriptionPackageById.name} />
          <FormulasSection
            subscriptionPackage={data.getSubscriptionPackageById}
            disabled={!!disabled}
          />
          <ImportFileSection
            subscriptionPackage={data.getSubscriptionPackageById}
          />
          <CategoriesAndProductsSection
            subscriptionPackage={data.getSubscriptionPackageById}
            disabled={!!disabled}
          />
        </div>
      )}
    </>
  );
};
