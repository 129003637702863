import { MyTransaction } from "~/types/data/Accounting.types";

import TransactionsTableRow from "../TransactionsTableRow";
import styles from "./index.module.scss";

interface Props {
  transactions: MyTransaction[];
  selectedRows: MyTransaction[];
  onCheckboxChange: (transaction: MyTransaction) => void;
}

const TransactionsTableBody = ({
  transactions,
  selectedRows,
  onCheckboxChange,
}: Props) => {
  const isSelected = (transactionId?: number) =>
    selectedRows.findIndex(({ id }) => transactionId === id) > -1;
  return (
    <tbody className={`${styles.tbody}`}>
      {transactions.map((transaction) => {
        const isRowSelected = isSelected(transaction.id);
        return (
          <TransactionsTableRow
            key={transaction.id}
            transaction={transaction}
            isSelected={isRowSelected}
            onCheckboxChange={onCheckboxChange}
          />
        );
      })}
    </tbody>
  );
};

export default TransactionsTableBody;
