import { OptionType } from "~/components/form/SelectInput";
import SVGContainer from "~/components/UI/SVGContainer";
import { Order, RefundPartialOrderInput } from "~/types/data/Order.types";
import { formatPrice } from "~/util/functions/formatDecimal";

import { RefundModalState, RefundMode } from "..";
import { DefaultRefundForm } from "./DefaultRefundForm";
import { DetailedRefundForm } from "./DetailedRefundForm";
import { FullRefundForm } from "./FullRefundForm";
import styles from "./index.module.scss";

interface Props {
  state: RefundModalState;
  onChangeMode: (mode: RefundMode) => void;
  refundReasonOptions: OptionType[];
  refundTotalOrderHandler: (refundReason: string) => void;
  refundPartialOrderHandler: (
    refundPartialOrderInput: RefundPartialOrderInput
  ) => void;
  order: Order;
}

export const RefundForm = ({
  onChangeMode,
  state,
  refundReasonOptions,
  refundTotalOrderHandler,
  refundPartialOrderHandler,
  order,
}: Props) => {
  const { mode, isLoading } = state;

  const formattedTotalPrice = formatPrice(order.totalPrice);
  const formattedShippingPrice = formatPrice(order.shippingPrice);
  const formattedLeftTotalPrice = formatPrice(
    order.totalPrice - order.refundAmount
  );
  const formattedLeftShippingPrice = formatPrice(
    order.shippingPrice - order.refundShippingAmount
  );

  return (
    <div className={`${styles.container}`}>
      {mode !== "default" && (
        <div className={`${styles.backButton}`}>
          <SVGContainer
            imagePath="/assets/back-button.svg"
            height="17px"
            width="20px"
            onClick={isLoading ? () => {} : () => onChangeMode("default")}
          />
        </div>
      )}
      <div className={`${styles.header}`}>
        <h2>Remboursement</h2>
      </div>
      <div className={`${styles.orderSummary}`}>
        <p>Montant total original : {formattedTotalPrice}</p>
        <p>Frais de port : {formattedShippingPrice}</p>
      </div>
      <div className={`${styles.refundSummary}`}>
        <p>Montant après remboursement : {formattedLeftTotalPrice}</p>
        <p>Frais de port après remboursement : {formattedLeftShippingPrice}</p>
      </div>
      {mode === "detailed" ? (
        <DetailedRefundForm
          refundReasonOptions={refundReasonOptions}
          orderProducts={order.orderToProducts}
          orderId={order.id}
          onSubmit={refundPartialOrderHandler}
          state={state}
        />
      ) : mode === "full" ? (
        <FullRefundForm
          refundReasonOptions={refundReasonOptions}
          onSubmit={refundTotalOrderHandler}
          state={state}
        />
      ) : (
        <DefaultRefundForm onModeButtonClick={onChangeMode} />
      )}
    </div>
  );
};
