import { DeliveryMethodEnum } from "./Carrier.types";
import { DeliveryAddress } from "./Order.types";
import { Product } from "./Product.types";
import { Profile } from "./Profile.types";
import { TransportationStatusEnum } from "./Transportation.types";

export enum SingleRentProductStatusEnum {
  DELIVERING = "DELIVERING",
  PENDING = "PENDING",
  IN_USE = "IN_USE",
  RETURN_REQUESTED = "RETURN_REQUESTED",
  RETURN_REQUESTED_AND_SHIPPED = "RETURN_REQUESTED_AND_SHIPPED",
  REVIEW_REQUESTED = "REVIEW_REQUESTED",
  RETURNED = "RETURNED",
  DAMAGED = "DAMAGED",
  DAMAGE_FEE_PAID = "DAMAGE_FEE_PAID",
  PURCHASE_PENDING = "PURCHASE_PENDING",
  PURCHASED = "PURCHASED",
}

export enum RentPaymentFrequencyEnum {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
}

export enum RentOrderStatusEnum {
  OPEN = "OPEN",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  ACTIVE = "ACTIVE",
  ENDED = "ENDED",
  FAILED = "FAILED",
}

export enum CompanyUpdateRentSingleProductStatusEnum {
  "DAMAGED" = "DAMAGED",
  "RETURNED" = "RETURNED",
}

export type GroupedRentOrderSingleProduct = {
  id: string;
  product: Product;
  orderToProductId: number;
  singleProductIds: number[];

  quantity: number;

  deposit: number;
  pricePerPeriod: number;
  purchasePrice: number;
  currentPurchasePrice: number;

  totalPaidRecurringAmount: number;
  totalPurchasedAmount: number;

  damageFees: number;
  status: SingleRentProductStatusEnum;

  pdfPath?: string;
  trackingNumber?: string;
  trackingUrl?: string;
  trackingStatus?: TransportationStatusEnum;

  returnPdfPath?: string;
  returnTrackingNumber?: string;
  returnTrackingUrl?: string;
  returnTrackingStatus?: TransportationStatusEnum;
};

export type SingleRentOrderToProduct = {
  id: number;
  createdAt: string;

  damageFees: number;
  status: SingleRentProductStatusEnum;

  currentPurchasePrice: number;

  totalPaidRecurringAmount: number;
  totalPurchasedAmount: number;

  pdfPath?: string;
  trackingNumber?: string;
  trackingUrl?: string;
  trackingStatus?: TransportationStatusEnum;

  returnPdfPath?: string;
  returnTrackingNumber?: string;
  returnTrackingUrl?: string;
  returnTrackingStatus?: TransportationStatusEnum;

  parentOrderToProductId: number;
};

export type RentOrderToProduct = {
  id: number;
  createdAt: string;
  updatedAt: string;

  originalQuantity: number;

  deposit: number;
  pricePerPeriod: number;
  purchasePrice: number;

  product: Product;
  productId: number;

  singleProducts: SingleRentOrderToProduct[];
};

export type RentOrder = {
  id: number;

  createdAt: string;
  startingDate: string;

  status: RentOrderStatusEnum;

  frequency: RentPaymentFrequencyEnum;
  originalPricePerPeriod: number;
  billingPricePerPeriod: number;

  totalPaidRecurringAmount: number;
  totalPurchasedAmount: number;
  totalPaidAmount: number;

  deliveryOption?: DeliveryMethodEnum;
  shippingAddressId?: number;
  shippingAddress?: DeliveryAddress;
  billingAddressId?: number;
  billingAddress?: DeliveryAddress;

  orderToProducts: RentOrderToProduct[];

  initialDepositAmount: number;
  leftDepositAmount: number;
  nextBillingDate: string;

  buyer?: Profile;
  buyerId?: number;

  storeName?: string;
};
