import { routesBuilder } from "~/navigation/routes";

import styles from "./index.module.scss";
import { NavigationSection } from "./NavigationSection";

export const AdminDataPlatformNavigation = () => {
  const adminPlatformDataToTaxonomieRouteRoute = routesBuilder({
    routes: ["adminPlatformDataRoute", "taxonomieRoute"],
  });

  const adminPlatformDataToVendorCategoriesRouteRoute = routesBuilder({
    routes: ["adminPlatformDataRoute", "vendorCategoriesRoute"],
  });

  const adminPlatformDataToAttributesRouteRoute = routesBuilder({
    routes: ["adminPlatformDataRoute", "attributesRoute"],
  });

  return (
    <div className={`${styles.navigationContainer}`}>
      <NavigationSection
        name="Taxonomie"
        iconName="numbers-dashes-icon.svg"
        url={adminPlatformDataToTaxonomieRouteRoute}
      />
      <NavigationSection
        name="Vendor Categories"
        iconName="numbers-dashes-icon.svg"
        url={adminPlatformDataToVendorCategoriesRouteRoute}
      />
      <NavigationSection
        name="Attributes"
        iconName="numbers-dashes-icon.svg"
        url={adminPlatformDataToAttributesRouteRoute}
      />
    </div>
  );
};
