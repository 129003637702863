import { gql } from "@apollo/client";

import {
  graphqlGetMyPayOutsFields,
  graphqlGetMyTransactionsFields,
} from "~/constants/graphql";
import { OrderBy } from "~/types/common/filter.types";
import { MyPayOut, MyTransaction } from "~/types/data/Accounting.types";

// inputs
export interface GetMyTransactionsInput {
  GetMyTransactionsInput: {
    cursor?: number;
    take?: number;
    orderBy?: OrderBy<MyTransaction>;
  };
}

export interface GetMyPayOutsInput {
  GetMyPayOutsInput: {
    cursor?: number;
    take?: number;
    orderBy?: OrderBy<MyPayOut>;
  };
}

// responses
export interface GetMyTransactionsResponse {
  getMyTransactions: {
    myTransactions: MyTransaction[];
    nextCursor: number;
    hasMoreData: boolean;
  };
}

export interface GetMyPayOutsResponse {
  getMyPayOuts: {
    myPayOuts: MyPayOut[];
    nextCursor: number;
    hasMoreData: boolean;
  };
}

export interface GetMyTransactionsCountResponse {
  getMyTransactionsCount: {
    count: number;
  };
}

export interface GetMyPayOutsCountResponse {
  getMyPayOutsCount: {
    count: number;
  };
}

export interface GetMyAccountingStatsResponse {
  getMyAccountingStats: {
    pendingPaymentAmount: number;
    payablePaymentAmount: number;
    paidPaymentAmount: number;
  };
}

// select fields
const myTransactionFields = graphqlGetMyTransactionsFields.join(" ");
const myPayOutFields = graphqlGetMyPayOutsFields.join(" ");

// queries and mutations
export const GET_MY_TRANSACTIONS = gql`
  query ($GetMyTransactionsInput: GetMyTransactionsInput!) {
    getMyTransactions(GetMyTransactionsInput: $GetMyTransactionsInput) {
      myTransactions {
        ${myTransactionFields}

        products {
          id
          title
          quantity
          sku
        }
      }

      nextCursor
      hasMoreData

    }
  }
`;

export const GET_MY_TRANSACTIONS_COUNT = gql`
  query ($GetMyTransactionsInput: GetMyTransactionsInput!) {
    getMyTransactionsCount(GetMyTransactionsInput: $GetMyTransactionsInput) {
      count
    }
  }
`;

export const GET_MY_PAYOUTS = gql`
  query ($GetMyPayOutsInput: GetMyPayOutsInput!) {
    getMyPayOuts(GetMyPayOutsInput: $GetMyPayOutsInput) {
      myPayOuts {
        ${myPayOutFields}
      }

      nextCursor
      hasMoreData

    }
  }
`;

export const GET_MY_PAYOUTS_COUNT = gql`
  query ($GetMyPayOutsInput: GetMyPayOutsInput!) {
    getMyPayOutsCount(GetMyPayOutsInput: $GetMyPayOutsInput) {
      count
    }
  }
`;

export const GET_MY_ACCOUNTING_STATS = gql`
  query {
    getMyAccountingStats {
      pendingPaymentAmount
      payablePaymentAmount
      paidPaymentAmount
    }
  }
`;
