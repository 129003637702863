import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import { ApolloError, useMutation } from "@apollo/client";
import { useState } from "react";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";

import {
  UPDATE_SUBSCRIPTION_CATEGORIES_ORDER,
  UpdateSubscriptionCategoriesOrderInput,
  UpdateSubscriptionCategoriesOrderResponse,
} from "~/api/graphql/subscriptionPackage";
import { CustomButton } from "~/components/form/CustomButton";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { Product } from "~/types/data/Product.types";
import {
  SubscriptionCategory,
  UpdateSubscriptionCategoriesOrderInputObject,
  UpdateSubscriptionCategoriesOrderPriority,
} from "~/types/data/SubscriptionPackage.type";

import { CategoryCard } from "./CategoryCard";
import styles from "./index.module.scss";

interface Props {
  subscriptionPackageId: number;
  subscriptionCategories: SubscriptionCategory[];
  categoryLevel: number;
  products: Product[];
  handleCategoryClick: (category: SubscriptionCategory) => void;
  updateCategoriesOrderSuccessHandler: (
    categories: SubscriptionCategory[]
  ) => void;
}

interface CategoryGridCardType {
  name: string;
  category: SubscriptionCategory;
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  static?: boolean;
}

const ResponsiveGridLayout = WidthProvider(Responsive);

export const CategoriesList = ({
  subscriptionCategories,
  products,
  handleCategoryClick,
  categoryLevel,
  subscriptionPackageId,
  updateCategoriesOrderSuccessHandler,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [orderUpdated, setOrderUpdated] = useState<boolean>(false);
  const [dragging, setDragging] = useState(false);

  const categoriesCards: CategoryGridCardType[] = subscriptionCategories.map(
    (subscriptionCategory, index) => {
      return {
        name: subscriptionCategory.name,
        category: subscriptionCategory,
        i: subscriptionCategory.categoryId.toString(),
        x: 0,
        y: index,
        w: 1,
        h: 1,
      };
    }
  );

  const [categoriesCardsState, setCategoriesCardsState] =
    useState<CategoryGridCardType[]>(categoriesCards);

  const updateSubscriptionCategoriesOrderErrorHandler = (
    error: ApolloError
  ) => {
    setErrorMessage(error.message);
  };

  const updateSubscriptionCategoriesOrderSuccessHandler = (
    data: UpdateSubscriptionCategoriesOrderResponse
  ) => {
    setOrderUpdated(false);
    updateCategoriesOrderSuccessHandler(data.updateSubscriptionCategoriesOrder);
  };

  const [updateSubscriptionCategoriesOrder] = useMutation<
    UpdateSubscriptionCategoriesOrderResponse,
    UpdateSubscriptionCategoriesOrderInput
  >(UPDATE_SUBSCRIPTION_CATEGORIES_ORDER, {});

  const categoriesCardsWithStatic: CategoryGridCardType[] = [
    ...categoriesCards,
    {
      name: "Autre",
      i: categoriesCards.length.toString(),
      h: 1,
      w: 1,
      x: 0,
      y: categoriesCards.length,
      static: true,
      category: {
        categoryId: 0,
        id: 0,
        name: "Autre",
        priority: categoriesCards.length,
      },
    },
  ];

  const changeLayoutHandler = (layout: Layout[]) => {
    if (!orderUpdated) setOrderUpdated(true);

    const newCategoriesCardsState: (CategoryGridCardType | undefined)[] =
      layout.map((layoutItem) => {
        const categoryCard = categoriesCardsWithStatic.find(
          (categoryCard) => categoryCard.i === layoutItem.i
        );
        if (!categoryCard) return;
        return {
          ...categoryCard,
          y: layoutItem.y,
        };
      });

    const filteredList = newCategoriesCardsState.filter(
      (state) => state !== undefined
    ) as CategoryGridCardType[];

    setCategoriesCardsState(filteredList);
  };

  const changeOrderSubmitHandler = async () => {
    setIsLoading(true);
    setErrorMessage("");

    const priorities: UpdateSubscriptionCategoriesOrderPriority[] =
      categoriesCardsState
        .slice(0, categoriesCardsState.length - 1)
        .map((categoryCard) => {
          return {
            id: categoryCard.category.id,
            priority: categoryCard.y + 1,
            categoryId: categoryCard.category.categoryId,
          };
        });

    const input: UpdateSubscriptionCategoriesOrderInputObject = {
      subscriptionId: subscriptionPackageId,
      priorities,
    };

    await updateSubscriptionCategoriesOrder({
      variables: {
        UpdateSubscriptionCategoriesOrderInput: input,
      },
      onCompleted: updateSubscriptionCategoriesOrderSuccessHandler,
      onError: updateSubscriptionCategoriesOrderErrorHandler,
    });
    setIsLoading(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h3>Categories</h3>
        <CustomButton
          backgroundColor={colors.$primary}
          color="white"
          width="fit-content"
          borderRadius="8px"
          padding="1rem 1.5rem"
          type="submit"
          disabled={isLoading || !orderUpdated}
          height="1rem"
          onClick={() => {
            changeOrderSubmitHandler();
          }}
        >
          {isLoading ? <SimpleLoader size="size2" /> : "Enregistrer"}
        </CustomButton>
      </div>
      {!!errorMessage && (
        <div className={styles.errorContainer}>{errorMessage}</div>
      )}
      <div>
        <ResponsiveGridLayout
          breakpoints={{ lg: 1200 }}
          cols={{ lg: 1 }}
          isBounded={true}
          isResizable={false}
          margin={[10, 10]}
          rowHeight={65}
          containerPadding={[0, 0]}
          compactType="vertical"
          layouts={{ lg: categoriesCards }}
          onDragStop={(l) => {
            setDragging(true);
            setTimeout(() => {
              changeLayoutHandler(l);
              setDragging(false);
            }, 100);
          }}
        >
          {categoriesCardsWithStatic.map((subscriptionCategory, index) => (
            <div
              key={subscriptionCategory.i}
              data-grid={{
                x: subscriptionCategory.x,
                y: subscriptionCategory.y,
                w: subscriptionCategory.w,
                h: subscriptionCategory.h,
                static: subscriptionCategory.static,
              }}
              onClick={() => {
                if (!dragging) {
                  handleCategoryClick(subscriptionCategory.category);
                }
              }}
            >
              <CategoryCard
                subscriptionCategory={subscriptionCategory.category}
                key={index}
                products={products}
                categoryLevel={categoryLevel}
              />
            </div>
          ))}
        </ResponsiveGridLayout>
      </div>
    </div>
  );
};
