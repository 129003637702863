import { useState } from "react";
import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

import FullPageLoader from "~/components/UI/FullPageLoader";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useUserContext } from "~/context/userContext";
import { RoleEnum } from "~/types/data/User.types";

import { routePaths } from "../routes";

export const NotAuthenticatedRoute = () => {
  const { userState } = useUserContext();
  const { companyAccount } = useCompanyAccountContext();
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const isAuthorized =
    userState?.connected &&
    (userState.role === RoleEnum.ADMIN || userState.role === RoleEnum.SELLER);

  const { dashboardRoute } = routePaths;

  useEffect(() => {
    if (userState && companyAccount.connected !== undefined) {
      if (isAuthorized) {
        setIsConnected(userState.connected);
      }
      setIsLoading(false);
    }
  }, [userState?.connected, companyAccount?.connected]);

  return (
    <>
      {isLoading ? (
        <FullPageLoader />
      ) : (
        <>{isConnected ? <Navigate to={`/${dashboardRoute}`} /> : <Outlet />}</>
      )}
    </>
  );
};
