import { useEffect, useRef } from "react";

import { Message, MessageTypeEnum } from "~/types/data/Message.types";
import { formatHour } from "~/util/functions/formatDate";
import { mapDate } from "~/util/functions/mapDate";

import { CustomMessageBody } from "./CustomMessageBody";
import { FileMessageBody } from "./FileMessageBody";
import styles from "./index.module.scss";
import { PictureMessageBody } from "./PictureMessageBody";
import { TextMessageBody } from "./TextMessageBody";

interface Props {
  messages: Message[];
  professionalSellerId: number;
}

export const ConversationBody = ({ messages, professionalSellerId }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  //add an entry for every unique date in the messages array
  const finalMessages = messages.reduce<Record<string, Message[]>>(
    (acc, message) => {
      const date = new Date(message.createdAt).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(message);
      return acc;
    },
    {}
  );

  useEffect(() => {
    containerRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages[messages.length - 1]]);
  return (
    <div className={styles.container}>
      {Object.entries(finalMessages).map(([date, sortedMessages]) => (
        <div key={date} className={styles.dateHeader}>
          <div className={styles.date}>{mapDate(new Date(date))} </div>
          {sortedMessages.map(
            ({
              createdAt,
              senderId,
              type,
              text,
              id,
              customMessage,
              filePath,
              fileUrl = "",
              pictureUrl = "",
              status,
            }) => {
              const isSender = professionalSellerId === senderId;
              const formattedHour = formatHour(createdAt);

              return (
                <div
                  ref={containerRef}
                  className={`${styles.messageContainer} ${
                    isSender ? styles.sender : styles.reciever
                  }`}
                  key={id}
                >
                  {isSender && (
                    <div className={styles.hour}>{formattedHour}</div>
                  )}
                  {type === MessageTypeEnum.CUSTOM && customMessage ? (
                    <CustomMessageBody
                      customMessage={customMessage}
                      date={createdAt}
                    />
                  ) : type === MessageTypeEnum.FILE ? (
                    <FileMessageBody
                      status={status}
                      fileName={filePath || ""}
                      fileUrl={fileUrl}
                    />
                  ) : type === MessageTypeEnum.PICTURE ? (
                    <PictureMessageBody
                      pictureUrl={pictureUrl}
                      status={status}
                    />
                  ) : (
                    <TextMessageBody messageText={text || ""} status={status} />
                  )}
                  {!isSender && (
                    <div className={styles.hour}>{formattedHour}</div>
                  )}
                </div>
              );
            }
          )}
        </div>
      ))}
    </div>
  );
};
