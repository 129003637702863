import { Card, Rating } from "@mui/material";
import debounce from "lodash.debounce";
import { useCallback, useState } from "react";
import { SingleValue } from "react-select";

import { InputWithIcon } from "~/components/form/InputWithIcon";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { colors } from "~/constants/styles";
import { useAppDispatch, useAppSelector } from "~/redux/hooks";
import {
  onFilterArgsChange,
  selectReviewsFilterArgs,
} from "~/redux/slice/reviewsFilter.slice";
import { OrderBy, OrderByEnum } from "~/types/common/filter.types";
import { Review } from "~/types/data/Review.types";
import { formatDecimal } from "~/util/functions/formatDecimal";

import styles from "./index.module.scss";

interface Props {
  averageRating: number;
  totalReviews: number;
}

enum ReviewsOrderByFilterEnum {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  RATING_ASC = "RATING_ASC",
  RATING_DESC = "RATING_DESC",
}
export const ReviewsFilterForm = ({ averageRating, totalReviews }: Props) => {
  const [searchBox, setSearchBox] = useState("");

  const filterArgsState = useAppSelector(selectReviewsFilterArgs);
  const dispatch = useAppDispatch();

  const orderByOptions: OptionType[] = [
    {
      label: "+ récent au + ancien",
      value: ReviewsOrderByFilterEnum.CREATED_AT_DESC,
    },
    {
      label: "+ ancien au + récent",
      value: ReviewsOrderByFilterEnum.CREATED_AT_ASC,
    },
    {
      label: "- note la plus élevée - note la plus basse",
      value: ReviewsOrderByFilterEnum.RATING_DESC,
    },
    {
      label: "- note la plus basse - note la plus élevée",
      value: ReviewsOrderByFilterEnum.RATING_ASC,
    },
  ];

  const ratingOptions: OptionType[] = [
    { label: "1 étoiles", value: "1" },
    { label: "2 étoiles", value: "2" },
    { label: "3 étoiles", value: "3" },
    { label: "4 étoiles", value: "4" },
    { label: "5 étoiles", value: "5" },
  ];
  const formattedAverageRating = formatDecimal(averageRating, 1, ".");
  const orderByValueFilterValue =
    filterArgsState.orderBy.createdAt === OrderByEnum.DESC
      ? ReviewsOrderByFilterEnum.CREATED_AT_DESC
      : filterArgsState.orderBy.createdAt === OrderByEnum.ASC
      ? ReviewsOrderByFilterEnum.CREATED_AT_ASC
      : filterArgsState.orderBy.rating === OrderByEnum.DESC
      ? ReviewsOrderByFilterEnum.RATING_DESC
      : ReviewsOrderByFilterEnum.RATING_ASC;

  const searchBoxChangeHandler = useCallback(
    debounce((value: string) => {
      dispatch(
        onFilterArgsChange({
          filterArg: "searchBox",
          searchBox: value,
        })
      );
    }, 500),
    []
  );
  return (
    <>
      <div className={`${styles.header}`}>
        <h2>Mes évaluations</h2>
        <div className={`${styles.searchBox}`}>
          <InputWithIcon
            onChange={(value: string | number) => {
              setSearchBox(value.toString());
              searchBoxChangeHandler(value.toString());
            }}
            value={searchBox}
            borderRadius="5px"
            noBorder={true}
            backgroundColor="white"
            iconPath="/assets/search-icon.svg"
            placeholder="Nom du client, numéro de commande, ..."
          />
        </div>
      </div>
      <Card>
        <div className={`${styles.banner}`}>
          <div className={`${styles.bannerItem}`}>
            <div className={`${styles.item}`}>
              <p className={`${styles.title}`}>Note globale :</p>
              <div className={`${styles.ratingContainer}`}>
                <Rating
                  readOnly
                  value={averageRating}
                  precision={0.1}
                  size="large"
                  sx={{ fontSize: "35px", color: colors.$primary }}
                />
                <p className={`${styles.content}`}>{formattedAverageRating}</p>
              </div>
            </div>
          </div>
          <div className={`${styles.bannerItem}`}>
            <div className={`${styles.item}`}>
              <p className={`${styles.title}`}>Nombre d’avis </p>
              <p className={`${styles.content}`}>{totalReviews}</p>
            </div>
          </div>
          <div className={`${styles.bannerItem}`}>
            <div className={`${styles.item}`}>
              <p className={`${styles.title}`}>Trier par :</p>
              <SelectInput
                fontSize="12px"
                backgroundColor="white"
                options={orderByOptions}
                onChange={(option: SingleValue<OptionType>) => {
                  const orderBy: OrderBy<Review> =
                    option?.value === ReviewsOrderByFilterEnum.CREATED_AT_DESC
                      ? { createdAt: OrderByEnum.DESC }
                      : option?.value ===
                        ReviewsOrderByFilterEnum.CREATED_AT_ASC
                      ? { createdAt: OrderByEnum.ASC }
                      : option?.value === ReviewsOrderByFilterEnum.RATING_DESC
                      ? { rating: OrderByEnum.DESC }
                      : { rating: OrderByEnum.ASC };
                  dispatch(
                    onFilterArgsChange({
                      filterArg: "orderBy",
                      orderBy,
                    })
                  );
                }}
                value={orderByValueFilterValue}
              />
            </div>
          </div>
          <div className={`${styles.bannerItem}`}>
            <div className={`${styles.item}`}>
              <p className={`${styles.title}`}>Nombre d’étoiles :</p>
              <SelectInput
                fontSize="12px"
                backgroundColor="white"
                options={ratingOptions}
                onChange={(option: SingleValue<OptionType>) => {
                  dispatch(
                    onFilterArgsChange({
                      filterArg: "rating",
                      rating: option?.value ? +option.value : undefined,
                    })
                  );
                }}
                value={filterArgsState.rating?.toString() || ""}
                hasDefault={true}
                defaultLabel="Not selected"
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};
