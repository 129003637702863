import { useState } from "react";
import { SingleValue } from "react-select";

import { CustomButton } from "~/components/form/CustomButton";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";

import { RefundModalState } from "../..";
import styles from "./index.module.scss";

interface Props {
  state: RefundModalState;
  refundReasonOptions: OptionType[];
  onSubmit: (refundReason: string) => void;
}

export const FullRefundForm = ({
  state,
  refundReasonOptions,
  onSubmit,
}: Props) => {
  const [formState, setFormState] = useState<{ reason: string }>({
    reason: "",
  });

  const { isLoading, errorMessage } = state;
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.sectionTitle}`}>
        <p>Remboursement complet</p>
      </div>
      <div className={`${styles.form}`}>
        <SelectInput
          label="Motif de remboursement"
          fontSize="14px"
          backgroundColor={colors.$inputGray}
          noBorder={true}
          options={refundReasonOptions}
          onChange={(option: SingleValue<OptionType>) => {
            setFormState({ reason: option?.value || "" });
          }}
          value={formState.reason}
        />
      </div>
      {errorMessage && (
        <div className={`${styles.errorMessage}`}>{errorMessage}</div>
      )}
      <div className={`${styles.button}`}>
        <CustomButton
          backgroundColor={colors.$primary}
          color="white"
          width="fit-content"
          borderRadius="8px"
          padding="1rem 1.5rem"
          onClick={() => {
            onSubmit(formState.reason);
          }}
        >
          {isLoading ? <SimpleLoader size="size2" /> : "Valider"}
        </CustomButton>
      </div>
    </div>
  );
};
