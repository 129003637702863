import { CustomButton } from "~/components/form/CustomButton";
import { colors } from "~/constants/styles";

import CustomModal from "../CustomModal";
import styles from "./index.module.scss";

interface Props {
  show: boolean;
  message: string;
  onCancel: () => void;
}

export const ErrorPopup = ({ show, message, onCancel }: Props) => {
  return (
    <CustomModal onCancel={onCancel} show={show}>
      <div className={styles.container}>
        <h3>Error</h3>
        <p>{message}</p>
        <div className={styles.buttonContainer}>
          <CustomButton
            onClick={onCancel}
            backgroundColor={colors.$primary}
            color="white"
            width="fit-content"
            borderRadius="8px"
            padding="1rem 1.5rem"
          >
            Close
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  );
};
