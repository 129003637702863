export const countryList: {
  id: number;
  alpha2: string;
  alpha3: string;
  name: string;
}[] = [
  {
    id: 4,
    alpha2: "AF",
    alpha3: "afg",
    name: "Afghanistan",
  },
  {
    id: 710,
    alpha2: "ZA",
    alpha3: "zaf",
    name: "Afrique du Sud",
  },
  {
    id: 248,
    alpha2: "AX",
    alpha3: "ala",
    name: "Îles Åland",
  },
  {
    id: 8,
    alpha2: "AL",
    alpha3: "alb",
    name: "Albanie",
  },
  {
    id: 12,
    alpha2: "DZ",
    alpha3: "dza",
    name: "Algérie",
  },
  {
    id: 276,
    alpha2: "DE",
    alpha3: "deu",
    name: "Allemagne",
  },
  {
    id: 20,
    alpha2: "AD",
    alpha3: "and",
    name: "Andorre",
  },
  {
    id: 24,
    alpha2: "AO",
    alpha3: "ago",
    name: "Angola",
  },
  {
    id: 660,
    alpha2: "AI",
    alpha3: "aia",
    name: "Anguilla",
  },
  {
    id: 10,
    alpha2: "AQ",
    alpha3: "ata",
    name: "Antarctique",
  },
  {
    id: 28,
    alpha2: "AG",
    alpha3: "atg",
    name: "Antigua-et-Barbuda",
  },
  {
    id: 682,
    alpha2: "SA",
    alpha3: "sau",
    name: "Arabie saoudite",
  },
  {
    id: 32,
    alpha2: "AR",
    alpha3: "arg",
    name: "Argentine",
  },
  {
    id: 51,
    alpha2: "AM",
    alpha3: "arm",
    name: "Arménie",
  },
  {
    id: 533,
    alpha2: "AW",
    alpha3: "abw",
    name: "Aruba",
  },
  {
    id: 36,
    alpha2: "AU",
    alpha3: "aus",
    name: "Australie",
  },
  {
    id: 40,
    alpha2: "AT",
    alpha3: "aut",
    name: "Autriche",
  },
  {
    id: 31,
    alpha2: "AZ",
    alpha3: "aze",
    name: "Azerbaïdjan",
  },
  {
    id: 44,
    alpha2: "BS",
    alpha3: "bhs",
    name: "Bahamas",
  },
  {
    id: 48,
    alpha2: "BH",
    alpha3: "bhr",
    name: "Bahreïn",
  },
  {
    id: 50,
    alpha2: "BD",
    alpha3: "bgd",
    name: "Bangladesh",
  },
  {
    id: 52,
    alpha2: "BB",
    alpha3: "brb",
    name: "Barbade",
  },
  {
    id: 112,
    alpha2: "BY",
    alpha3: "blr",
    name: "Biélorussie",
  },
  {
    id: 56,
    alpha2: "BE",
    alpha3: "bel",
    name: "Belgique",
  },
  {
    id: 84,
    alpha2: "BZ",
    alpha3: "blz",
    name: "Belize",
  },
  {
    id: 204,
    alpha2: "BJ",
    alpha3: "ben",
    name: "Bénin",
  },
  {
    id: 60,
    alpha2: "BM",
    alpha3: "bmu",
    name: "Bermudes",
  },
  {
    id: 64,
    alpha2: "BT",
    alpha3: "btn",
    name: "Bhoutan",
  },
  {
    id: 68,
    alpha2: "BO",
    alpha3: "bol",
    name: "Bolivie",
  },
  {
    id: 535,
    alpha2: "BQ",
    alpha3: "bes",
    name: "Pays-Bas caribéens",
  },
  {
    id: 70,
    alpha2: "BA",
    alpha3: "bih",
    name: "Bosnie-Herzégovine",
  },
  {
    id: 72,
    alpha2: "BW",
    alpha3: "bwa",
    name: "Botswana",
  },
  {
    id: 74,
    alpha2: "BV",
    alpha3: "bvt",
    name: "Île Bouvet",
  },
  {
    id: 76,
    alpha2: "BR",
    alpha3: "bra",
    name: "Brésil",
  },
  {
    id: 96,
    alpha2: "BN",
    alpha3: "brn",
    name: "Brunei",
  },
  {
    id: 100,
    alpha2: "BG",
    alpha3: "bgr",
    name: "Bulgarie",
  },
  {
    id: 854,
    alpha2: "BF",
    alpha3: "bfa",
    name: "Burkina Faso",
  },
  {
    id: 108,
    alpha2: "BI",
    alpha3: "bdi",
    name: "Burundi",
  },
  {
    id: 136,
    alpha2: "KY",
    alpha3: "cym",
    name: "Îles Caïmans",
  },
  {
    id: 116,
    alpha2: "KH",
    alpha3: "khm",
    name: "Cambodge",
  },
  {
    id: 120,
    alpha2: "CM",
    alpha3: "cmr",
    name: "Cameroun",
  },
  {
    id: 124,
    alpha2: "CA",
    alpha3: "can",
    name: "Canada",
  },
  {
    id: 132,
    alpha2: "CV",
    alpha3: "cpv",
    name: "Cap-Vert",
  },
  {
    id: 140,
    alpha2: "CF",
    alpha3: "caf",
    name: "République centrafricaine",
  },
  {
    id: 152,
    alpha2: "CL",
    alpha3: "chl",
    name: "Chili",
  },
  {
    id: 156,
    alpha2: "CN",
    alpha3: "chn",
    name: "Chine",
  },
  {
    id: 162,
    alpha2: "CX",
    alpha3: "cxr",
    name: "Île Christmas",
  },
  {
    id: 196,
    alpha2: "CY",
    alpha3: "cyp",
    name: "Chypre",
  },
  {
    id: 166,
    alpha2: "CC",
    alpha3: "cck",
    name: "Îles Cocos",
  },
  {
    id: 170,
    alpha2: "CO",
    alpha3: "col",
    name: "Colombie",
  },
  {
    id: 174,
    alpha2: "KM",
    alpha3: "com",
    name: "Comores",
  },
  {
    id: 178,
    alpha2: "CG",
    alpha3: "cog",
    name: "République du Congo",
  },
  {
    id: 180,
    alpha2: "CD",
    alpha3: "cod",
    name: "République démocratique du Congo",
  },
  {
    id: 184,
    alpha2: "CK",
    alpha3: "cok",
    name: "Îles Cook",
  },
  {
    id: 410,
    alpha2: "KR",
    alpha3: "kor",
    name: "Corée du Sud",
  },
  {
    id: 408,
    alpha2: "KP",
    alpha3: "prk",
    name: "Corée du Nord",
  },
  {
    id: 188,
    alpha2: "CR",
    alpha3: "cri",
    name: "Costa Rica",
  },
  {
    id: 384,
    alpha2: "CI",
    alpha3: "civ",
    name: "Côte d'Ivoire",
  },
  {
    id: 191,
    alpha2: "HR",
    alpha3: "hrv",
    name: "Croatie",
  },
  {
    id: 192,
    alpha2: "CU",
    alpha3: "cub",
    name: "Cuba",
  },
  {
    id: 531,
    alpha2: "CW",
    alpha3: "cuw",
    name: "Curaçao",
  },
  {
    id: 208,
    alpha2: "DK",
    alpha3: "dnk",
    name: "Danemark",
  },
  {
    id: 262,
    alpha2: "DJ",
    alpha3: "dji",
    name: "Djibouti",
  },
  {
    id: 214,
    alpha2: "DO",
    alpha3: "dom",
    name: "République dominicaine",
  },
  {
    id: 212,
    alpha2: "DM",
    alpha3: "dma",
    name: "Dominique",
  },
  {
    id: 818,
    alpha2: "EG",
    alpha3: "egy",
    name: "Égypte",
  },
  {
    id: 222,
    alpha2: "SV",
    alpha3: "slv",
    name: "Salvador",
  },
  {
    id: 784,
    alpha2: "AE",
    alpha3: "are",
    name: "Émirats arabes unis",
  },
  {
    id: 218,
    alpha2: "EC",
    alpha3: "ecu",
    name: "Équateur",
  },
  {
    id: 232,
    alpha2: "ER",
    alpha3: "eri",
    name: "Érythrée",
  },
  {
    id: 724,
    alpha2: "ES",
    alpha3: "esp",
    name: "Espagne",
  },
  {
    id: 233,
    alpha2: "EE",
    alpha3: "est",
    name: "Estonie",
  },
  {
    id: 840,
    alpha2: "US",
    alpha3: "usa",
    name: "États-Unis",
  },
  {
    id: 231,
    alpha2: "ET",
    alpha3: "eth",
    name: "Éthiopie",
  },
  {
    id: 238,
    alpha2: "FK",
    alpha3: "flk",
    name: "Malouines",
  },
  {
    id: 234,
    alpha2: "FO",
    alpha3: "fro",
    name: "Îles Féroé",
  },
  {
    id: 242,
    alpha2: "FJ",
    alpha3: "fji",
    name: "Fidji",
  },
  {
    id: 246,
    alpha2: "FI",
    alpha3: "fin",
    name: "Finlande",
  },
  {
    id: 250,
    alpha2: "FR",
    alpha3: "fra",
    name: "France",
  },
  {
    id: 266,
    alpha2: "GA",
    alpha3: "gab",
    name: "Gabon",
  },
  {
    id: 270,
    alpha2: "GM",
    alpha3: "gmb",
    name: "Gambie",
  },
  {
    id: 268,
    alpha2: "GE",
    alpha3: "geo",
    name: "Géorgie",
  },
  {
    id: 239,
    alpha2: "GS",
    alpha3: "sgs",
    name: "Géorgie du Sud-et-les îles Sandwich du Sud",
  },
  {
    id: 288,
    alpha2: "GH",
    alpha3: "gha",
    name: "Ghana",
  },
  {
    id: 292,
    alpha2: "GI",
    alpha3: "gib",
    name: "Gibraltar",
  },
  {
    id: 300,
    alpha2: "GR",
    alpha3: "grc",
    name: "Grèce",
  },
  {
    id: 308,
    alpha2: "GD",
    alpha3: "grd",
    name: "Grenade",
  },
  {
    id: 304,
    alpha2: "GL",
    alpha3: "grl",
    name: "Groenland",
  },
  {
    id: 312,
    alpha2: "GP",
    alpha3: "glp",
    name: "Guadeloupe",
  },
  {
    id: 316,
    alpha2: "GU",
    alpha3: "gum",
    name: "Guam",
  },
  {
    id: 320,
    alpha2: "GT",
    alpha3: "gtm",
    name: "Guatemala",
  },
  {
    id: 831,
    alpha2: "GG",
    alpha3: "ggy",
    name: "Guernesey",
  },
  {
    id: 324,
    alpha2: "GN",
    alpha3: "gin",
    name: "Guinée",
  },
  {
    id: 624,
    alpha2: "GW",
    alpha3: "gnb",
    name: "Guinée-Bissau",
  },
  {
    id: 226,
    alpha2: "GQ",
    alpha3: "gnq",
    name: "Guinée équatoriale",
  },
  {
    id: 328,
    alpha2: "GY",
    alpha3: "guy",
    name: "Guyana",
  },
  {
    id: 254,
    alpha2: "GF",
    alpha3: "guf",
    name: "Guyane",
  },
  {
    id: 332,
    alpha2: "HT",
    alpha3: "hti",
    name: "Haïti",
  },
  {
    id: 334,
    alpha2: "HM",
    alpha3: "hmd",
    name: "Îles Heard-et-MacDonald",
  },
  {
    id: 340,
    alpha2: "HN",
    alpha3: "hnd",
    name: "Honduras",
  },
  {
    id: 344,
    alpha2: "HK",
    alpha3: "hkg",
    name: "Hong Kong",
  },
  {
    id: 348,
    alpha2: "HU",
    alpha3: "hun",
    name: "Hongrie",
  },
  {
    id: 833,
    alpha2: "IM",
    alpha3: "imn",
    name: "Île de Man",
  },
  {
    id: 581,
    alpha2: "UM",
    alpha3: "umi",
    name: "Îles mineures éloignées des États-Unis",
  },
  {
    id: 92,
    alpha2: "VG",
    alpha3: "vgb",
    name: "Îles Vierges britanniques",
  },
  {
    id: 850,
    alpha2: "VI",
    alpha3: "vir",
    name: "Îles Vierges des États-Unis",
  },
  {
    id: 356,
    alpha2: "IN",
    alpha3: "ind",
    name: "Inde",
  },
  {
    id: 360,
    alpha2: "ID",
    alpha3: "idn",
    name: "Indonésie",
  },
  {
    id: 364,
    alpha2: "IR",
    alpha3: "irn",
    name: "Iran",
  },
  {
    id: 368,
    alpha2: "IQ",
    alpha3: "irq",
    name: "Irak",
  },
  {
    id: 372,
    alpha2: "IE",
    alpha3: "irl",
    name: "Irlande",
  },
  {
    id: 352,
    alpha2: "IS",
    alpha3: "isl",
    name: "Islande",
  },
  {
    id: 376,
    alpha2: "IL",
    alpha3: "isr",
    name: "Israël",
  },
  {
    id: 380,
    alpha2: "IT",
    alpha3: "ita",
    name: "Italie",
  },
  {
    id: 388,
    alpha2: "JM",
    alpha3: "jam",
    name: "Jamaïque",
  },
  {
    id: 392,
    alpha2: "JP",
    alpha3: "jpn",
    name: "Japon",
  },
  {
    id: 832,
    alpha2: "JE",
    alpha3: "jey",
    name: "Jersey",
  },
  {
    id: 400,
    alpha2: "JO",
    alpha3: "jor",
    name: "Jordanie",
  },
  {
    id: 398,
    alpha2: "KZ",
    alpha3: "kaz",
    name: "Kazakhstan",
  },
  {
    id: 404,
    alpha2: "KE",
    alpha3: "ken",
    name: "Kenya",
  },
  {
    id: 417,
    alpha2: "KG",
    alpha3: "kgz",
    name: "Kirghizistan",
  },
  {
    id: 296,
    alpha2: "KI",
    alpha3: "kir",
    name: "Kiribati",
  },
  {
    id: 414,
    alpha2: "KW",
    alpha3: "kwt",
    name: "Koweït",
  },
  {
    id: 418,
    alpha2: "LA",
    alpha3: "lao",
    name: "Laos",
  },
  {
    id: 426,
    alpha2: "LS",
    alpha3: "lso",
    name: "Lesotho",
  },
  {
    id: 428,
    alpha2: "LV",
    alpha3: "lva",
    name: "Lettonie",
  },
  {
    id: 422,
    alpha2: "LB",
    alpha3: "lbn",
    name: "Liban",
  },
  {
    id: 430,
    alpha2: "LR",
    alpha3: "lbr",
    name: "Liberia",
  },
  {
    id: 434,
    alpha2: "LY",
    alpha3: "lby",
    name: "Libye",
  },
  {
    id: 438,
    alpha2: "LI",
    alpha3: "lie",
    name: "Liechtenstein",
  },
  {
    id: 440,
    alpha2: "LT",
    alpha3: "ltu",
    name: "Lituanie",
  },
  {
    id: 442,
    alpha2: "LU",
    alpha3: "lux",
    name: "Luxembourg",
  },
  {
    id: 446,
    alpha2: "MO",
    alpha3: "mac",
    name: "Macao",
  },
  {
    id: 807,
    alpha2: "MK",
    alpha3: "mkd",
    name: "Macédoine du Nord",
  },
  {
    id: 450,
    alpha2: "MG",
    alpha3: "mdg",
    name: "Madagascar",
  },
  {
    id: 458,
    alpha2: "MY",
    alpha3: "mys",
    name: "Malaisie",
  },
  {
    id: 454,
    alpha2: "MW",
    alpha3: "mwi",
    name: "Malawi",
  },
  {
    id: 462,
    alpha2: "MV",
    alpha3: "mdv",
    name: "Maldives",
  },
  {
    id: 466,
    alpha2: "ML",
    alpha3: "mli",
    name: "Mali",
  },
  {
    id: 470,
    alpha2: "MT",
    alpha3: "mlt",
    name: "Malte",
  },
  {
    id: 580,
    alpha2: "MP",
    alpha3: "mnp",
    name: "Îles Mariannes du Nord",
  },
  {
    id: 504,
    alpha2: "MA",
    alpha3: "mar",
    name: "Maroc",
  },
  {
    id: 584,
    alpha2: "MH",
    alpha3: "mhl",
    name: "Îles Marshall",
  },
  {
    id: 474,
    alpha2: "MQ",
    alpha3: "mtq",
    name: "Martinique",
  },
  {
    id: 480,
    alpha2: "MU",
    alpha3: "mus",
    name: "Maurice",
  },
  {
    id: 478,
    alpha2: "MR",
    alpha3: "mrt",
    name: "Mauritanie",
  },
  {
    id: 175,
    alpha2: "YT",
    alpha3: "myt",
    name: "Mayotte",
  },
  {
    id: 484,
    alpha2: "MX",
    alpha3: "mex",
    name: "Mexique",
  },
  {
    id: 583,
    alpha2: "FM",
    alpha3: "fsm",
    name: "États fédérés de Micronésie",
  },
  {
    id: 498,
    alpha2: "MD",
    alpha3: "mda",
    name: "Moldavie",
  },
  {
    id: 492,
    alpha2: "MC",
    alpha3: "mco",
    name: "Monaco",
  },
  {
    id: 496,
    alpha2: "MN",
    alpha3: "mng",
    name: "Mongolie",
  },
  {
    id: 499,
    alpha2: "ME",
    alpha3: "mne",
    name: "Monténégro",
  },
  {
    id: 500,
    alpha2: "MS",
    alpha3: "msr",
    name: "Montserrat",
  },
  {
    id: 508,
    alpha2: "MZ",
    alpha3: "moz",
    name: "Mozambique",
  },
  {
    id: 104,
    alpha2: "MM",
    alpha3: "mmr",
    name: "Birmanie",
  },
  {
    id: 516,
    alpha2: "NA",
    alpha3: "nam",
    name: "Namibie",
  },
  {
    id: 520,
    alpha2: "NR",
    alpha3: "nru",
    name: "Nauru",
  },
  {
    id: 524,
    alpha2: "NP",
    alpha3: "npl",
    name: "Népal",
  },
  {
    id: 558,
    alpha2: "NI",
    alpha3: "nic",
    name: "Nicaragua",
  },
  {
    id: 562,
    alpha2: "NE",
    alpha3: "ner",
    name: "Niger",
  },
  {
    id: 566,
    alpha2: "NG",
    alpha3: "nga",
    name: "Nigeria",
  },
  {
    id: 570,
    alpha2: "NU",
    alpha3: "niu",
    name: "Niue",
  },
  {
    id: 574,
    alpha2: "NF",
    alpha3: "nfk",
    name: "Île Norfolk",
  },
  {
    id: 578,
    alpha2: "NO",
    alpha3: "nor",
    name: "Norvège",
  },
  {
    id: 540,
    alpha2: "NC",
    alpha3: "ncl",
    name: "Nouvelle-Calédonie",
  },
  {
    id: 554,
    alpha2: "NZ",
    alpha3: "nzl",
    name: "Nouvelle-Zélande",
  },
  {
    id: 86,
    alpha2: "IO",
    alpha3: "iot",
    name: "Territoire britannique de l'océan Indien",
  },
  {
    id: 512,
    alpha2: "OM",
    alpha3: "omn",
    name: "Oman",
  },
  {
    id: 800,
    alpha2: "UG",
    alpha3: "uga",
    name: "Ouganda",
  },
  {
    id: 860,
    alpha2: "UZ",
    alpha3: "uzb",
    name: "Ouzbékistan",
  },
  {
    id: 586,
    alpha2: "PK",
    alpha3: "pak",
    name: "Pakistan",
  },
  {
    id: 585,
    alpha2: "PW",
    alpha3: "plw",
    name: "Palaos",
  },
  {
    id: 275,
    alpha2: "PS",
    alpha3: "pse",
    name: "Palestine",
  },
  {
    id: 591,
    alpha2: "PA",
    alpha3: "pan",
    name: "Panama",
  },
  {
    id: 598,
    alpha2: "PG",
    alpha3: "png",
    name: "Papouasie-Nouvelle-Guinée",
  },
  {
    id: 600,
    alpha2: "PY",
    alpha3: "pry",
    name: "Paraguay",
  },
  {
    id: 528,
    alpha2: "NL",
    alpha3: "nld",
    name: "Pays-Bas",
  },
  {
    id: 604,
    alpha2: "PE",
    alpha3: "per",
    name: "Pérou",
  },
  {
    id: 608,
    alpha2: "PH",
    alpha3: "phl",
    name: "Philippines",
  },
  {
    id: 612,
    alpha2: "PN",
    alpha3: "pcn",
    name: "Îles Pitcairn",
  },
  {
    id: 616,
    alpha2: "PL",
    alpha3: "pol",
    name: "Pologne",
  },
  {
    id: 258,
    alpha2: "PF",
    alpha3: "pyf",
    name: "Polynésie française",
  },
  {
    id: 630,
    alpha2: "PR",
    alpha3: "pri",
    name: "Porto Rico",
  },
  {
    id: 620,
    alpha2: "PT",
    alpha3: "prt",
    name: "Portugal",
  },
  {
    id: 634,
    alpha2: "QA",
    alpha3: "qat",
    name: "Qatar",
  },
  {
    id: 638,
    alpha2: "RE",
    alpha3: "reu",
    name: "La Réunion",
  },
  {
    id: 642,
    alpha2: "RO",
    alpha3: "rou",
    name: "Roumanie",
  },
  {
    id: 826,
    alpha2: "GB",
    alpha3: "gbr",
    name: "Royaume-Uni",
  },
  {
    id: 643,
    alpha2: "RU",
    alpha3: "rus",
    name: "Russie",
  },
  {
    id: 646,
    alpha2: "RW",
    alpha3: "rwa",
    name: "Rwanda",
  },
  {
    id: 732,
    alpha2: "EH",
    alpha3: "esh",
    name: "République arabe sahraouie démocratique",
  },
  {
    id: 652,
    alpha2: "BL",
    alpha3: "blm",
    name: "Saint-Barthélemy",
  },
  {
    id: 659,
    alpha2: "KN",
    alpha3: "kna",
    name: "Saint-Christophe-et-Niévès",
  },
  {
    id: 674,
    alpha2: "SM",
    alpha3: "smr",
    name: "Saint-Marin",
  },
  {
    id: 663,
    alpha2: "MF",
    alpha3: "maf",
    name: "Saint-Martin",
  },
  {
    id: 534,
    alpha2: "SX",
    alpha3: "sxm",
    name: "Saint-Martin",
  },
  {
    id: 666,
    alpha2: "PM",
    alpha3: "spm",
    name: "Saint-Pierre-et-Miquelon",
  },
  {
    id: 336,
    alpha2: "VA",
    alpha3: "vat",
    name: "Saint-Siège (État de la Cité du Vatican)",
  },
  {
    id: 670,
    alpha2: "VC",
    alpha3: "vct",
    name: "Saint-Vincent-et-les-Grenadines",
  },
  {
    id: 654,
    alpha2: "SH",
    alpha3: "shn",
    name: "Sainte-Hélène, Ascension et Tristan da Cunha",
  },
  {
    id: 662,
    alpha2: "LC",
    alpha3: "lca",
    name: "Sainte-Lucie",
  },
  {
    id: 90,
    alpha2: "SB",
    alpha3: "slb",
    name: "Îles Salomon",
  },
  {
    id: 882,
    alpha2: "WS",
    alpha3: "wsm",
    name: "Samoa",
  },
  {
    id: 16,
    alpha2: "AS",
    alpha3: "asm",
    name: "Samoa américaines",
  },
  {
    id: 678,
    alpha2: "ST",
    alpha3: "stp",
    name: "Sao Tomé-et-Principe",
  },
  {
    id: 686,
    alpha2: "SN",
    alpha3: "sen",
    name: "Sénégal",
  },
  {
    id: 688,
    alpha2: "RS",
    alpha3: "srb",
    name: "Serbie",
  },
  {
    id: 690,
    alpha2: "SC",
    alpha3: "syc",
    name: "Seychelles",
  },
  {
    id: 694,
    alpha2: "SL",
    alpha3: "sle",
    name: "Sierra Leone",
  },
  {
    id: 702,
    alpha2: "SG",
    alpha3: "sgp",
    name: "Singapour",
  },
  {
    id: 703,
    alpha2: "SK",
    alpha3: "svk",
    name: "Slovaquie",
  },
  {
    id: 705,
    alpha2: "SI",
    alpha3: "svn",
    name: "Slovénie",
  },
  {
    id: 706,
    alpha2: "SO",
    alpha3: "som",
    name: "Somalie",
  },
  {
    id: 729,
    alpha2: "SD",
    alpha3: "sdn",
    name: "Soudan",
  },
  {
    id: 728,
    alpha2: "SS",
    alpha3: "ssd",
    name: "Soudan du Sud",
  },
  {
    id: 144,
    alpha2: "LK",
    alpha3: "lka",
    name: "Sri Lanka",
  },
  {
    id: 752,
    alpha2: "SE",
    alpha3: "swe",
    name: "Suède",
  },
  {
    id: 756,
    alpha2: "CH",
    alpha3: "che",
    name: "Suisse",
  },
  {
    id: 740,
    alpha2: "SR",
    alpha3: "sur",
    name: "Suriname",
  },
  {
    id: 744,
    alpha2: "SJ",
    alpha3: "sjm",
    name: "Svalbard et ile Jan Mayen",
  },
  {
    id: 748,
    alpha2: "SZ",
    alpha3: "swz",
    name: "Eswatini",
  },
  {
    id: 760,
    alpha2: "SY",
    alpha3: "syr",
    name: "Syrie",
  },
  {
    id: 762,
    alpha2: "TJ",
    alpha3: "tjk",
    name: "Tadjikistan",
  },
  {
    id: 158,
    alpha2: "TW",
    alpha3: "twn",
    name: "Taïwan / (République de Chine (Taïwan))",
  },
  {
    id: 834,
    alpha2: "TZ",
    alpha3: "tza",
    name: "Tanzanie",
  },
  {
    id: 148,
    alpha2: "TD",
    alpha3: "tcd",
    name: "Tchad",
  },
  {
    id: 203,
    alpha2: "CZ",
    alpha3: "cze",
    name: "Tchéquie",
  },
  {
    id: 260,
    alpha2: "TF",
    alpha3: "atf",
    name: "Terres australes et antarctiques françaises",
  },
  {
    id: 764,
    alpha2: "TH",
    alpha3: "tha",
    name: "Thaïlande",
  },
  {
    id: 626,
    alpha2: "TL",
    alpha3: "tls",
    name: "Timor oriental",
  },
  {
    id: 768,
    alpha2: "TG",
    alpha3: "tgo",
    name: "Togo",
  },
  {
    id: 772,
    alpha2: "TK",
    alpha3: "tkl",
    name: "Tokelau",
  },
  {
    id: 776,
    alpha2: "TO",
    alpha3: "ton",
    name: "Tonga",
  },
  {
    id: 780,
    alpha2: "TT",
    alpha3: "tto",
    name: "Trinité-et-Tobago",
  },
  {
    id: 788,
    alpha2: "TN",
    alpha3: "tun",
    name: "Tunisie",
  },
  {
    id: 795,
    alpha2: "TM",
    alpha3: "tkm",
    name: "Turkménistan",
  },
  {
    id: 796,
    alpha2: "TC",
    alpha3: "tca",
    name: "Îles Turques-et-Caïques",
  },
  {
    id: 792,
    alpha2: "TR",
    alpha3: "tur",
    name: "Turquie",
  },
  {
    id: 798,
    alpha2: "TV",
    alpha3: "tuv",
    name: "Tuvalu",
  },
  {
    id: 804,
    alpha2: "UA",
    alpha3: "ukr",
    name: "Ukraine",
  },
  {
    id: 858,
    alpha2: "UY",
    alpha3: "ury",
    name: "Uruguay",
  },
  {
    id: 548,
    alpha2: "VU",
    alpha3: "vut",
    name: "Vanuatu",
  },
  {
    id: 862,
    alpha2: "VE",
    alpha3: "ven",
    name: "Venezuela",
  },
  {
    id: 704,
    alpha2: "VN",
    alpha3: "vnm",
    name: "Viêt Nam",
  },
  {
    id: 876,
    alpha2: "WF",
    alpha3: "wlf",
    name: "Wallis-et-Futuna",
  },
  {
    id: 887,
    alpha2: "YE",
    alpha3: "yem",
    name: "Yémen",
  },
  {
    id: 894,
    alpha2: "ZM",
    alpha3: "zmb",
    name: "Zambie",
  },
  {
    id: 716,
    alpha2: "ZW",
    alpha3: "zwe",
    name: "Zimbabwe",
  },
];
