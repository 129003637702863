import arraySort from "array-sort";
import { useEffect, useState } from "react";

import { OrderByEnum } from "~/types/common/filter.types";
import { Order } from "~/types/data/Order.types";
import {
  OrderHisory,
  OrderHistoryFilterState,
} from "~/types/data/OrderHistory.types";

import { getBuyerName } from "../util/getProfileName";
import { FilterBar } from "./FilterBar";
import { HistoryTable } from "./HistoryTable";
import styles from "./index.module.scss";
import { InformationBanner } from "./InformationBanner";

interface Props {
  order: Order;
}

export const Historiques = ({ order }: Props) => {
  const [filteredOrderHistory, setFilteredOrderHistory] = useState<
    OrderHisory[]
  >([]);
  const [fitlerState, setFilterState] = useState<OrderHistoryFilterState>({
    searchBox: "",
  });
  const filterChangeHandler = <T extends keyof OrderHistoryFilterState>(
    inputName: T,
    changes: OrderHistoryFilterState[T]
  ) => {
    setFilterState((prev) => ({ ...prev, [inputName]: changes }));
  };

  const buyerName = getBuyerName(
    order.buyer?.firstName,
    order.buyer?.lastName,
    order.shippingAddress?.name
  );

  useEffect(() => {
    const filteredOrderHistory = order.orderHistory.filter(
      ({ title, description, createdByRole }) => {
        const titleFilter = title
          .toLowerCase()
          .includes(fitlerState.searchBox.toLowerCase());

        const descriptionFilter = description
          .toLowerCase()
          .includes(fitlerState.searchBox.toLowerCase());

        const selectFilter = !fitlerState.role
          ? true
          : fitlerState.role === createdByRole;

        return (titleFilter || descriptionFilter) && selectFilter;
      }
    );
    const sortedOrderHistory = arraySort(filteredOrderHistory, "createdAt", {
      reverse: fitlerState.order === OrderByEnum.DESC,
    });
    setFilteredOrderHistory(sortedOrderHistory);
  }, [fitlerState]);

  return (
    <div className={styles.container}>
      <div className={styles.banner}>
        <InformationBanner
          clientName={buyerName}
          orderId={order.id}
          orderStatus={order.status}
        />
      </div>
      <div className={styles.details}>
        <div className={styles.header}>
          <FilterBar
            filterState={fitlerState}
            filterChangeHandler={filterChangeHandler}
          />
        </div>
        <div className={styles.table}>
          <HistoryTable
            filterState={fitlerState}
            filterChangeHandler={filterChangeHandler}
            historyData={filteredOrderHistory}
          />
        </div>
      </div>
    </div>
  );
};
