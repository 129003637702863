import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
  DELETE_COLLECTION,
  DeleteCollectionInput,
  DeleteCollectionResponse,
  GET_ALL_COLLECTIONS,
  GetAllCollectionsResponse,
} from "~/api/graphql/Collection";
import { CollectionModel } from "~/types/data/Collection.type";

import { CollectionModals } from "./Collections";
import CollectionsAndProducts from "./CollectionsAndProducts";
import { Details } from "./details";
import { ImportFileSection } from "./ImportFileSection";
import styles from "./index.module.scss";

export const SecondHandDetails = () => {
  const [selectedCollection, setSelectedCollection] =
    useState<CollectionModel>();

  const { data: colletionsData } =
    useQuery<GetAllCollectionsResponse>(GET_ALL_COLLECTIONS);
  const [collections, setCollections] = useState<CollectionModel[]>([]);
  const modalInitialState: {
    isOpen: boolean;
    collection?: CollectionModel;
  } = {
    isOpen: false,
  };
  const [modalState, setModalState] = useState(modalInitialState);
  const [deleteCollectionModalState, setDeleteCollectionModalState] =
    useState(modalInitialState);

  const openEditModal = (collection: CollectionModel) => {
    setModalState({
      isOpen: true,
      collection,
    });
  };
  const openDeleteModal = (collection: CollectionModel) => {
    setDeleteCollectionModalState({
      isOpen: true,
      collection,
    });
  };

  const [deleteCollection] = useMutation<
    DeleteCollectionResponse,
    DeleteCollectionInput
  >(DELETE_COLLECTION);

  const deleteCarrierHandler = async () => {
    try {
      const data = await deleteCollection({
        variables: {
          DeleteCollectionInput: {
            id: deleteCollectionModalState?.collection?.id || 0,
          },
        },
      });
      if (data.data?.deleteCollection) {
        setCollections((prevState) => {
          return prevState.filter(
            (collection) =>
              collection.id !== deleteCollectionModalState.collection?.id
          );
        });
      } else {
        toast.error("Failed to delete collection");
      }
    } catch (error) {
      toast.error("Failed to delete collection");
    }
    setDeleteCollectionModalState({ isOpen: false });
  };

  useEffect(() => {
    if (colletionsData) {
      setCollections(colletionsData?.getAllCollections);
    }
  }, [colletionsData]);

  return (
    <div className={styles.container}>
      <div className={styles.sectionsContainer}>
        <Details />
      </div>

      <ImportFileSection selectedCollectionId={selectedCollection?.id} />
      <CollectionModals
        closeDelete={() => setDeleteCollectionModalState({ isOpen: false })}
        closeEdit={() => setModalState({ isOpen: false })}
        deleteCarrier={deleteCarrierHandler}
        isDeleteOpen={deleteCollectionModalState.isOpen}
        isEditOpen={modalState.isOpen}
        openEditModal={() => setModalState({ isOpen: true })}
        setCollections={setCollections}
        editedCollection={modalState.collection}
      />
      <CollectionsAndProducts
        collections={collections}
        selectedCollection={selectedCollection}
        setSelectedCollection={setSelectedCollection}
        openDeleteModal={openDeleteModal}
        openEditModal={openEditModal}
      />
    </div>
  );
};
