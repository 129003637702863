import { useLocation } from "react-router-dom";

interface LocationState {
  state: { html?: string };
}

export const DynamicErrorPage = () => {
  const { state } = useLocation() as LocationState;
  const errorHtml = state?.html ?? "<h1>error</h1>";
  return <div dangerouslySetInnerHTML={{ __html: errorHtml }}></div>;
};
