import { HeadCell } from "~/types/common/headCell.type";

import { TableRowData } from "../..";
import styles from "./index.module.scss";

interface Props {
  headCells: HeadCell[];
  tableRowData: TableRowData;
}

export const SmallTableRow = ({ headCells, tableRowData }: Props) => {
  {
    const text = tableRowData.text;
    const value1 = tableRowData.value1;
    const value2 = tableRowData.value2;

    const isValue1Currency = headCells[1] ? headCells[1].isCurrency : false;
    const isValue2Currency = headCells[2] ? headCells[2].isCurrency : false;

    const isValue1Percentage = headCells[1] ? headCells[1].isPercentage : false;
    const isValue2Percentage = headCells[1] ? headCells[1].isPercentage : false;

    const formattedValue1 = isValue1Currency
      ? new Intl.NumberFormat("fr-FR", {
          style: "currency",
          currency: "EUR",
        }).format(value1)
      : value1.toFixed(2);

    const formattedValue2 =
      isValue2Currency && value2
        ? new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
          }).format(value2)
        : value2?.toFixed(2);

    return (
      <tr className={`${styles.row}`}>
        <td>{text}</td>
        <td>{`${formattedValue1}${isValue1Percentage ? "%" : ""}`}</td>
        {!!formattedValue2 && (
          <td>{`${formattedValue2}${isValue2Percentage ? "%" : ""}`}</td>
        )}
      </tr>
    );
  }
};
