import { OrderHisory } from "~/types/data/OrderHistory.types";

import { HistoryTableRow } from "../HistoryTableRow";
import styles from "./index.module.scss";

interface Props {
  historyData: OrderHisory[];
}

export const HistoryTableBody = ({ historyData }: Props) => {
  return (
    <tbody className={`${styles.tbody}`}>
      {historyData.map((data, index) => {
        return <HistoryTableRow key={index} historyData={data} />;
      })}
    </tbody>
  );
};
