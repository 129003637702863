import { useState } from "react";
import { SingleValue } from "react-select";

import { CustomButton } from "~/components/form/CustomButton";
import { CustomInput } from "~/components/form/CustomInput";
import { InputWithIcon } from "~/components/form/InputWithIcon";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import {
  OrderProductRefundInput,
  OrderToProduct,
  ProductOrderStatusEnum,
  RefundPartialOrderInput,
} from "~/types/data/Order.types";
import { formatPrice } from "~/util/functions/formatDecimal";

import { RefundModalState } from "../..";
import styles from "./index.module.scss";

interface Props {
  refundReasonOptions: OptionType[];
  orderProducts: OrderToProduct[];
  orderId: number;
  onSubmit: (refundPartialOrderInput: RefundPartialOrderInput) => void;

  state: RefundModalState;
}

export const DetailedRefundForm = ({
  refundReasonOptions,
  orderProducts,
  orderId,
  onSubmit,
  state,
}: Props) => {
  const { isLoading, errorMessage } = state;
  const refundProducts: OrderProductRefundInput[] = orderProducts.map(
    ({ id }) => {
      return { id, quantity: 0, reason: "", unitAmount: 0 };
    }
  );
  const [refundPartialOrderInput, setRefundPartialOrderInput] =
    useState<RefundPartialOrderInput>({
      orderId,
      refundProducts,
      shippingAmount: 0,
    });

  const changeHandler = <T extends keyof OrderProductRefundInput>(
    key: T,
    value: OrderProductRefundInput[T],
    index: number
  ) => {
    setRefundPartialOrderInput((prev) => {
      return {
        ...prev,
        refundProducts: prev.refundProducts.map((refundProduct, i) => {
          if (index === i) {
            return { ...refundProduct, [key]: value };
          } else {
            return refundProduct;
          }
        }),
      };
    });
  };

  const refundShippingAmountChangeHandler = (value: number) => {
    setRefundPartialOrderInput((prev) => {
      return { ...prev, shippingAmount: value };
    });
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.sectionTitle}`}>
        <p>Remboursement détaillé</p>
      </div>
      <div className={`${styles.form}`}>
        {orderProducts.map(
          (
            {
              id,
              product,
              quantity,
              totalPrice,
              unitPrice,
              refundAmount,
              productStatus,
            },
            productIndex
          ) => {
            const formattedUnitPrice = formatPrice(unitPrice);
            const formattedLeftTotalPrice = formatPrice(
              totalPrice - refundAmount
            );
            if (productStatus === ProductOrderStatusEnum.ACCEPTED)
              return (
                <div className={`${styles.refundDetailCard}`} key={id}>
                  <p className={`${styles.productTitle}`}>{product.title}</p>
                  <div className={`${styles.attributesContainer}`}>
                    <p>
                      <span className={`${styles.emphasizedText}`}>
                        SKU Produit :{" "}
                      </span>
                      {product.sku}
                    </p>
                    <p>
                      <span className={`${styles.emphasizedText}`}>Qté : </span>
                      {quantity}
                    </p>
                    <p>
                      <span className={`${styles.emphasizedText}`}>
                        Prix :{" "}
                      </span>
                      {formattedUnitPrice}
                    </p>
                    {!!refundAmount && (
                      <p className={`${styles.refundAmount}`}>
                        <span className={`${styles.emphasizedText}`}>
                          Montant après remboursement :{" "}
                        </span>
                        {formattedLeftTotalPrice}
                      </p>
                    )}
                  </div>
                  <div className={`${styles.selectInputContainer}`}>
                    <div className={`${styles.selectInput}`}>
                      <CustomInput
                        label="Quantité"
                        fontSize="14px"
                        backgroundColor={colors.$inputGray}
                        noBorder={true}
                        type="number"
                        onChange={(value: string | number) => {
                          changeHandler(
                            "quantity",
                            value as number,
                            productIndex
                          );
                        }}
                        value={
                          refundPartialOrderInput.refundProducts[productIndex]
                            .quantity
                        }
                      />
                    </div>

                    <div className={`${styles.selectInput}`}>
                      <InputWithIcon
                        iconPath="/assets/euro-icon.svg"
                        label="Montant"
                        fontSize="14px"
                        backgroundColor={colors.$inputGray}
                        noBorder={true}
                        type="number"
                        onChange={(value: string | number) => {
                          changeHandler(
                            "unitAmount",
                            value as number,
                            productIndex
                          );
                        }}
                        value={
                          refundPartialOrderInput.refundProducts[productIndex]
                            .unitAmount
                        }
                      />
                    </div>
                    <div className={`${styles.selectInput}`}>
                      <SelectInput
                        label="Motif de remboursement"
                        fontSize="14px"
                        backgroundColor={colors.$inputGray}
                        noBorder={true}
                        options={refundReasonOptions}
                        onChange={(option: SingleValue<OptionType>) => {
                          changeHandler(
                            "reason",
                            option?.value || "",
                            productIndex
                          );
                        }}
                        value={
                          refundPartialOrderInput.refundProducts[productIndex]
                            .reason
                        }
                        defaultLabel="Not selected"
                        hasDefault={true}
                      />
                    </div>
                  </div>
                </div>
              );
          }
        )}
      </div>
      <div className={`${styles.shippingCostInput}`}>
        <InputWithIcon
          label="Frais de port commande"
          fontSize="14px"
          backgroundColor={colors.$inputGray}
          noBorder={true}
          onChange={(value: string | number) => {
            refundShippingAmountChangeHandler(value as number);
          }}
          type="number"
          value={refundPartialOrderInput.shippingAmount}
          iconPath="/assets/euro-icon.svg"
        />
      </div>
      {errorMessage && (
        <div className={`${styles.errorMessage}`}>{errorMessage}</div>
      )}
      <div className={`${styles.button}`}>
        <CustomButton
          backgroundColor={colors.$primary}
          color="white"
          width="fit-content"
          borderRadius="8px"
          padding="1rem 1.5rem"
          onClick={() => {
            onSubmit(refundPartialOrderInput);
          }}
        >
          {isLoading ? <SimpleLoader size="size2" /> : "Valider"}
        </CustomButton>
      </div>
    </div>
  );
};
