import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { OrderBy } from "~/types/common/filter.types";
import { ImageImportStatusEnum, Product } from "~/types/data/Product.types";

import type { RootState } from "../store";

export interface CatalogueFilterArgs {
  orderBy: OrderBy<Product>;
  cursor?: number;
  take?: number;
  brand?: string;
  categoryId?: number;
  createdAtFrom?: string;
  createdAtTo?: string;
  updatedAtFrom?: string;
  updatedAtTo?: string;
  inStock?: boolean;
  waitingForValidation?: boolean;
  modificationRequested?: boolean;
  refused?: boolean;
  published?: boolean;
  incomplete?: boolean;
  imageImportStatus?: ImageImportStatusEnum;
  imagePresent?: boolean;
  stateType?: string[];
  forSale?: boolean;
  forRent?: boolean;
  forSubscription?: boolean;
  titleSearch?: string;
  companiesIds?: number[];
}

type FilterArgs =
  | "orderBy"
  | "cursor"
  | "brand"
  | "category"
  | "createdAtFrom"
  | "createdAtTo"
  | "updatedAtFrom"
  | "updatedAtTo"
  | "inStock"
  | "notInStock"
  | "waitingForValidation"
  | "modificationRequested"
  | "refused"
  | "published"
  | "incomplete"
  | "imageImportStatus"
  | "imagePresent"
  | "imageNotPresent"
  | "stateType"
  | "forSale"
  | "forRent"
  | "forSubscription"
  | "titleSearch"
  | "companiesIds"
  | "clearAll";

export const catalogueFilterArgsInitialState: CatalogueFilterArgs = {
  orderBy: {},
  take: 30,
};

export const catalogueFilterSlice = createSlice({
  name: "catalogueFilter",
  initialState: catalogueFilterArgsInitialState,
  reducers: {
    onFilterArgsChange(
      state,
      action: PayloadAction<{
        filterArg: FilterArgs;
        orderBy?: OrderBy<Product>;
        cursor?: number;
        brand?: string;
        categoryId?: number;
        createdAtFrom?: string;
        createdAtTo?: string;
        updatedAtFrom?: string;
        updatedAtTo?: string;
        inStock?: boolean;
        notInStock?: boolean;
        waitingForValidation?: boolean;
        modificationRequested?: boolean;
        refused?: boolean;
        published?: boolean;
        incomplete?: boolean;
        imageImportStatus?: ImageImportStatusEnum;
        imagePresent?: boolean;
        imageNotPresent?: boolean;
        stateType?: string[];
        forSale?: boolean;
        forRent?: boolean;
        forSubscription?: boolean;
        titleSearch?: string;
        companiesIds?: number[];
      }>
    ) {
      const {
        filterArg,
        cursor,
        orderBy,
        brand,
        categoryId,
        createdAtFrom,
        createdAtTo,
        updatedAtFrom,
        updatedAtTo,
        inStock,
        notInStock,
        waitingForValidation,
        modificationRequested,
        refused,
        published,
        incomplete,
        imageImportStatus,
        imagePresent,
        imageNotPresent,
        stateType,
        forSale,
        forRent,
        forSubscription,
        titleSearch,
        companiesIds,
      } = action.payload;

      if (filterArg === "cursor") {
        state.cursor = cursor;
      } else if (filterArg === "orderBy" && orderBy) {
        state.orderBy = orderBy;
      } else if (filterArg === "brand") {
        state.brand = brand;
      } else if (filterArg === "category") {
        state.categoryId = categoryId;
      } else if (filterArg === "createdAtFrom") {
        state.createdAtFrom = createdAtFrom;
      } else if (filterArg === "createdAtTo") {
        state.createdAtTo = createdAtTo;
      } else if (filterArg === "updatedAtFrom") {
        state.updatedAtFrom = updatedAtFrom;
      } else if (filterArg === "updatedAtTo") {
        state.updatedAtTo = updatedAtTo;
      } else if (filterArg === "inStock") {
        state.inStock = inStock ? true : undefined;
      } else if (filterArg === "notInStock") {
        state.inStock = notInStock ? false : undefined;
      } else if (filterArg === "waitingForValidation") {
        state.waitingForValidation = waitingForValidation;
      } else if (filterArg === "modificationRequested") {
        state.modificationRequested = modificationRequested;
      } else if (filterArg === "refused") {
        state.refused = refused;
      } else if (filterArg === "published") {
        state.published = published;
      } else if (filterArg === "incomplete") {
        state.incomplete = incomplete;
      } else if (filterArg === "imageImportStatus") {
        state.imageImportStatus = imageImportStatus;
      } else if (filterArg === "imagePresent") {
        state.imagePresent = imagePresent ? true : undefined;
      } else if (filterArg === "imageNotPresent") {
        state.imagePresent = imageNotPresent ? false : undefined;
      } else if (filterArg === "stateType") {
        state.stateType = stateType?.length ? stateType : undefined;
      } else if (filterArg === "forSale") {
        state.forSale = forSale;
      } else if (filterArg === "forRent") {
        state.forRent = forRent;
      } else if (filterArg === "forSubscription") {
        state.forSubscription = forSubscription;
      } else if (filterArg === "titleSearch") {
        state.titleSearch = titleSearch;
      } else if (filterArg === "companiesIds") {
        state.companiesIds = companiesIds;
      } else if (filterArg === "clearAll") {
        return { ...catalogueFilterArgsInitialState };
      }
    },
  },
});

export const { onFilterArgsChange } = catalogueFilterSlice.actions;

export const selectCatalogueFilterArgs = (state: RootState) => {
  return state.catalogueFilter;
};
export default catalogueFilterSlice.reducer;
