import Joi from "joi";

import { Formula } from "~/types/data/Formula.type";
import { UpdateSubscriptionFormulasInputObject } from "~/types/data/SubscriptionPackage.type";

const formula = Joi.object<Formula>().keys({
  id: Joi.required(),
  color: Joi.string().required().error(new Error("Couleur can't be empty.")),
  name: Joi.string().required().error(new Error("Nom can't be empty.")),
  description: Joi.string()
    .required()
    .error(new Error("Description can't be empty.")),
  price: Joi.number().required().error(new Error("Prix can't be empty.")),
  points: Joi.number().required().error(new Error("Points can't be empty.")),
});

export const updateSubscriptionFormulasValidationSchema =
  Joi.object<UpdateSubscriptionFormulasInputObject>({
    subscriptionId: Joi.required(),
    formulas: Joi.array().required().items(formula),
  });
