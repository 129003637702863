import { ApolloError } from "@apollo/client";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";

import {
  ADMIN_CREATE_OR_UPDATE_COMPANY,
  AdminCreateOrUpdateCompanyInput,
  AdminCreateOrUpdateCompanyResponse,
} from "~/api/graphql/company";
import { CustomButton } from "~/components/form/CustomButton";
import { CustomInput } from "~/components/form/CustomInput";
import DatePicker from "~/components/form/DatePicker";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { countryList } from "~/constants/countries";
import { colors } from "~/constants/styles";
import { useUserContext } from "~/context/userContext";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import { AxiosHttpError } from "~/types/common/error.types";
import { CompanyFormState, SocialReasonEnum } from "~/types/data/Company.types";
import { UboStatusEnum } from "~/types/data/UBO.types";

import styles from "./index.module.scss";

const socialReasonOptions: OptionType[] = [
  { label: "SAS", value: SocialReasonEnum.SAS },
  { label: "SARL", value: SocialReasonEnum.SARL },
  { label: "Société Anonyme", value: SocialReasonEnum.SA },
  { label: "SASU", value: SocialReasonEnum.SASU },
  { label: "EURL", value: SocialReasonEnum.EURL },
  { label: "Société en nom collectif", value: SocialReasonEnum.SNC },
  { label: "GIE", value: SocialReasonEnum.GIE },
  { label: "GAEC", value: SocialReasonEnum.GAEC },
  { label: "Auto-Entrepreneur", value: SocialReasonEnum.FREELANCE },
  { label: "EIRL", value: SocialReasonEnum.EIRL },
  { label: "Association", value: SocialReasonEnum.ASSOCIATION },
  { label: "Business", value: SocialReasonEnum.BUSINESS },
  { label: "Soletrader", value: SocialReasonEnum.SOLETRADER },
];

const uboStatusOptions: OptionType[] = [
  { label: UboStatusEnum.CREATED, value: UboStatusEnum.CREATED },
  { label: UboStatusEnum.DRAFT, value: UboStatusEnum.DRAFT },
  { label: UboStatusEnum.INCOMPLETE, value: UboStatusEnum.INCOMPLETE },
  { label: UboStatusEnum.REFUSED, value: UboStatusEnum.REFUSED },
  { label: UboStatusEnum.VALIDATED, value: UboStatusEnum.VALIDATED },
  {
    label: UboStatusEnum.VALIDATION_ASKED,
    value: UboStatusEnum.VALIDATION_ASKED,
  },
];

interface Props {
  company: CompanyFormState;
  setCompanyFormState: React.Dispatch<
    React.SetStateAction<CompanyFormState | undefined>
  >;
}

export const CompanyFormContainer = ({
  company,
  setCompanyFormState,
}: Props) => {
  // states

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const countryOptions: OptionType[] = countryList.map(({ name, alpha2 }) => {
    return { label: name, value: alpha2.toUpperCase() };
  });

  // hooks
  const navigate = useNavigate();
  const { userState } = useUserContext();

  const inputChangeHandler = <T extends keyof CompanyFormState>(
    inputName: T,
    changes: CompanyFormState[T]
  ) => {
    setCompanyFormState((state) => {
      if (state) return { ...state, [inputName]: changes };
    });
  };

  // react-query
  const createOrUpdateCompanyHandler = (
    responseData: AdminCreateOrUpdateCompanyResponse
  ) => {
    const { adminCreateOrUpdateCompany } = responseData;

    const {
      returnPolicyDescription: _returnPolicyDescription,
      companyDescription: _companyDescription,
      apiToken: _apiToken,
      ...data
    } = adminCreateOrUpdateCompany;

    setCompanyFormState(data);

    if (company.id) {
      setSuccessMessage("Company updated successfully");
    } else {
      setSuccessMessage("Company created successfully");
    }
    setIsLoading(false);
  };

  const createOrUpdateCompanyErrorHandler = (error: ApolloError) => {
    const { message } = error;
    setErrorMessage(`Errohr: ${message}`);
    setIsLoading(false);
  };

  const { trigger: createOrUpdateCompanyTrigger } = useMutationWithCallbacks<
    AdminCreateOrUpdateCompanyResponse,
    AdminCreateOrUpdateCompanyInput
  >(
    ADMIN_CREATE_OR_UPDATE_COMPANY,
    createOrUpdateCompanyHandler,
    createOrUpdateCompanyErrorHandler
  );

  const cancelClickHandler = () => {
    navigate(-1);
  };

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    if (!userState?.connected) {
      setErrorMessage("User is not connected");
      setIsLoading(false);
      return;
    }

    if (!company.storeName || !company.companyName) {
      setErrorMessage("Please fill all required fields");
      setIsLoading(false);
      return;
    }

    const {
      logoFile: _logoFile,
      logoFullPath: _logoFullPath,
      ...companyInpu
    } = company;

    try {
      await createOrUpdateCompanyTrigger({
        variables: {
          AdminCreateOrUpdateCompanyInput: companyInpu,
        },
      });
    } catch (e) {
      const error = e as AxiosHttpError;
      setErrorMessage("Error: " + error.message);
      setIsLoading(false);
      return;
    }
  };

  return (
    <>
      <form
        className={`${styles.formContainer}`}
        onSubmit={(e) => {
          submitHandler(e);
        }}
      >
        <div className={`${styles.doubleInputs}`}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("storeName", value.toString());
            }}
            value={company.storeName}
            label="Nom de la boutique"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            required={true}
          />
        </div>
        <div className={`${styles.doubleInputs}`}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("companyName", value.toString());
            }}
            value={company.companyName}
            label="Nom de la société"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            required={true}
          />
        </div>
        <div className={styles.doubleInputs}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("mangopayId", value.toString());
            }}
            value={company.mangopayId}
            label="Mangopay id :"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("mangopayWalletId", value.toString());
            }}
            value={company.mangopayWalletId}
            label="Mangopay wallet id :"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
        </div>
        <div className={styles.doubleInputs}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("companyAddress", value.toString());
            }}
            value={company.companyAddress}
            label="Company address :"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
          <SelectInput
            label="Pays :"
            fontSize="14px"
            backgroundColor={colors.$inputGray}
            noBorder={true}
            onChange={(option: SingleValue<OptionType>) => {
              inputChangeHandler("country", option?.value || "");
            }}
            options={countryOptions}
            value={company.country ?? ""}
          />
        </div>

        <div className={styles.doubleInputs}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("companyZipCode", value.toString());
            }}
            value={company.companyZipCode}
            label="Company zip code :"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("companyCity", value.toString());
            }}
            value={company.companyCity}
            label="Company city :"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
        </div>
        <div className={styles.doubleInputs}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("companyPhoneNumber", value.toString());
            }}
            value={company.companyPhoneNumber}
            label="Company phone number :"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("companyPhoneNumber2", value.toString());
            }}
            value={company.companyPhoneNumber2}
            label="Company phone number N°2 :"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
        </div>
        <div className={styles.doubleInputs}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("website", value.toString());
            }}
            value={company.website}
            label="Website :"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("email", value.toString());
            }}
            value={company.email}
            label="Email :"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
        </div>
        <div className={styles.doubleInputs}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("legalRepLastName", value.toString());
            }}
            value={company.legalRepLastName}
            label="Legal rep last name :"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("legalRepFirstName", value.toString());
            }}
            value={company.legalRepFirstName}
            label="Legal rep first name :"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
        </div>
        <div className={styles.doubleInputs}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("legalRepEmail", value.toString());
            }}
            value={company.legalRepEmail}
            label="Legal rep email :"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("legalRepAddress", value.toString());
            }}
            value={company.legalRepAddress}
            label="Legal rep address :"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
        </div>

        <div className={styles.doubleInputs}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("legalRepZipCode", value.toString());
            }}
            value={company.legalRepZipCode}
            label="Legal rep zip code :"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("legalRepCity", value.toString());
            }}
            value={company.legalRepCity}
            label="Legal rep city :"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
        </div>
        <div className={styles.doubleInputs}>
          <DatePicker
            label="Legal rep birthday :"
            fontSize="14px"
            icon="CalendarMonthOutlined"
            onChange={(value: DateTime | null) => {
              inputChangeHandler("legalRepBirthday", value?.toISO() || "");
            }}
            value={DateTime.fromISO(company.legalRepBirthday ?? "")}
            backgroundColor={colors.$inputGray}
            noBorder={true}
            borderRadius="5px"
          />
          <SelectInput
            label="Legal rep country :"
            fontSize="14px"
            backgroundColor={colors.$inputGray}
            noBorder={true}
            onChange={(option: SingleValue<OptionType>) => {
              inputChangeHandler("legalRepCountry", option?.value || "");
            }}
            options={countryOptions}
            value={company.legalRepCountry ?? ""}
          />
        </div>
        <div className={styles.doubleInputs}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("siret", value.toString());
            }}
            value={company.siret}
            label="Siret :"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("intercomTVA", value.toString());
            }}
            value={company.intercomTVA}
            label="Intercom tva :"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
        </div>
        <div className={styles.doubleInputs}>
          <SelectInput
            label="Social reason :"
            fontSize="14px"
            backgroundColor={colors.$inputGray}
            noBorder={true}
            onChange={(option: SingleValue<OptionType>) => {
              inputChangeHandler(
                "socialReason",
                option?.value as SocialReasonEnum
              );
            }}
            options={socialReasonOptions}
            value={company.socialReason ?? ""}
          />
          <SelectInput
            label="Shipping country :"
            fontSize="14px"
            backgroundColor={colors.$inputGray}
            noBorder={true}
            onChange={(option: SingleValue<OptionType>) => {
              inputChangeHandler("shippingCountry", option?.value || "");
            }}
            options={countryOptions}
            value={company.shippingCountry ?? ""}
          />
        </div>
        <div className={styles.doubleInputs}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("uboDeclarationId", value.toString());
            }}
            value={company.uboDeclarationId}
            label="Ubo declaration id :"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
          <SelectInput
            label="Ubo declaration status :"
            fontSize="14px"
            backgroundColor={colors.$inputGray}
            noBorder={true}
            onChange={(option: SingleValue<OptionType>) => {
              inputChangeHandler(
                "uboDeclarationStatus",
                option?.value as UboStatusEnum
              );
            }}
            options={uboStatusOptions}
            value={company.uboDeclarationStatus ?? ""}
          />
        </div>

        <div className={styles.messageContainer}>
          {!!errorMessage && (
            <p className={styles.errorMessage}>{errorMessage}</p>
          )}
          {!!successMessage && <p>{successMessage}</p>}
        </div>

        <div className={`${styles.formButtonsContainer}`}>
          <CustomButton
            color={colors.$primary}
            borderColor={colors.$primary}
            width="fit-content"
            borderRadius="8px"
            padding="1rem 1.5rem"
            disabled={isLoading}
            onClick={() => {
              cancelClickHandler();
            }}
          >
            Annuler
          </CustomButton>
          <CustomButton
            backgroundColor={colors.$primary}
            color="white"
            width="fit-content"
            borderRadius="8px"
            padding="1rem 1.5rem"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <SimpleLoader size="size2" /> : "Ajouter"}
          </CustomButton>
        </div>
      </form>
    </>
  );
};
