import { ApolloError } from "@apollo/client";
import { useState } from "react";

import {
  UPDATE_COMPANY_BANK_REFERENCE,
  UpdateCompanyBankReferenceApiInput,
  UpdateCompanyBankReferenceApiResponse,
} from "~/api/graphql/bankReference";
import { CustomButton } from "~/components/form/CustomButton";
import { CustomInput } from "~/components/form/CustomInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { initBankReference } from "~/constants/states";
import { colors } from "~/constants/styles";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import { BankReference } from "~/types/data/BankReference.types";
import { UpdateCompanyBankReferenceInput } from "~/types/data/Company.types";
import { updateBankReferenceValidationSchema } from "~/util/validation/companyAccount/bankReference.schema";

export const BankReferences = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { companyAccount, setCompanyAccount } = useCompanyAccountContext();

  const updateCompanyBankAccountSuccessHandler = ({
    updateCompanyBankReference: bankReference,
  }: UpdateCompanyBankReferenceApiResponse) => {
    setSuccessMessage("Références bancaires updated successfully.");
    setCompanyAccount((prev) => {
      return {
        ...prev,
        bankReference,
      };
    });
  };

  const updateCompanyBankAccountErrorHandler = (error: ApolloError) => {
    setErrorMessage(error.message);
  };

  const { trigger: updateCompanyBankAccountTrigger } = useMutationWithCallbacks<
    UpdateCompanyBankReferenceApiResponse,
    UpdateCompanyBankReferenceApiInput
  >(
    UPDATE_COMPANY_BANK_REFERENCE,
    updateCompanyBankAccountSuccessHandler,
    updateCompanyBankAccountErrorHandler
  );

  const inputChangeHandler = <T extends keyof BankReference>(
    inputName: T,
    changes: BankReference[T]
  ) => {
    setCompanyAccount((state) => {
      const bankReferenceState = state.bankReference || initBankReference;
      return {
        ...state,
        bankReference: { ...bankReferenceState, [inputName]: changes },
      };
    });
  };

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");
    setIsLoading(true);

    if (companyAccount.bankReference) {
      const input: UpdateCompanyBankReferenceInput = {
        id: companyAccount.id,
        bankReference: companyAccount.bankReference,
      };

      // validate input
      const result = updateBankReferenceValidationSchema.validate(input);

      const { error } = result;
      if (error) {
        setErrorMessage(error.message);
        return;
      }

      // send update request
      await updateCompanyBankAccountTrigger({
        variables: { UpdateCompanyBankReferenceInput: input },
      });

      setIsLoading(false);
    }
  };

  const disabled = !companyAccount.mangopayId;
  return (
    <form
      className="myAccountFormSection"
      onSubmit={(e) => {
        submitHandler(e);
      }}
    >
      <h3 className="title">Références bancaires</h3>
      <div className="singleInput">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("accountOwner", value.toString());
          }}
          value={companyAccount.bankReference?.accountOwner || ""}
          label="Titulaire du compte :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          disabled={disabled}
        />
      </div>

      <div className="singleInput">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("bankName", value.toString());
          }}
          value={companyAccount.bankReference?.bankName || ""}
          label="Nom de la banque :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          disabled={disabled}
        />
      </div>

      <div className="singleInput">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("address", value.toString());
          }}
          value={companyAccount.bankReference?.address || ""}
          label="Adresse :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          disabled={disabled}
        />
      </div>

      <div className="doubleInputs">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("zipCode", value.toString());
          }}
          value={companyAccount.bankReference?.zipCode || ""}
          label="Code postal :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          disabled={disabled}
        />
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("city", value.toString());
          }}
          value={companyAccount.bankReference?.city || ""}
          label="Ville :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          disabled={disabled}
        />
      </div>
      <div className="singleInput">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("iban", value.toString().replaceAll(" ", ""));
          }}
          value={companyAccount.bankReference?.iban || ""}
          label="IBAN :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          disabled={disabled}
        />
      </div>

      <div className="singleInput">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("bic", value.toString());
          }}
          value={companyAccount.bankReference?.bic || ""}
          label="BIC :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          disabled={disabled}
        />
      </div>
      <div className="messageContainer">
        {!!errorMessage && <p className="errorMessage">{errorMessage}</p>}
        {!!successMessage && <p>{successMessage}</p>}
      </div>
      <div className="buttonContainer">
        <CustomButton
          backgroundColor={colors.$primary}
          color="white"
          width="fit-content"
          borderRadius="8px"
          padding="1rem 1.5rem"
          type="submit"
          disabled={disabled || isLoading}
        >
          {isLoading ? <SimpleLoader size="size2" /> : "Enregistrer"}
        </CustomButton>
      </div>
    </form>
  );
};
