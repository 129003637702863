import { memo } from "react";
import { SingleValue } from "react-select";

import { OptionType } from "~/components/form/SelectInput";
import { ForeignShopMapping } from "~/types/data/ForeignShopMapping.types";
import { SellerMappingTypeEnum } from "~/types/data/SellerMapping.types";

import { NavigationSections } from "../SectionsNavigation";
import styles from "./index.module.scss";
import { MemoizedOptionSelect } from "./OptionSelect";

type Props = {
  optionAttributes: {
    value: string;
    label: string;
    id?: string;
  }[];
  mappedOptions: ForeignShopMapping[];
  sectionType: NavigationSections;
} & (
  | {
      optionMappingSelectHandler: (
        option: SingleValue<OptionType>,
        id: number
      ) => void;
    }
  | {
      optionValueMappingSelectHandler: (
        option: SingleValue<OptionType>,
        id: number,
        parentId: number
      ) => void;
      parentId: number;
    }
);
export const SectionMapping = ({
  mappedOptions,
  optionAttributes,
  sectionType,
  ...props
}: Props) => {
  const findCategoryById = (id?: string) => {
    const emptyCategory = { id: "", name: "", value: "" };
    if (optionAttributes && id)
      return (
        optionAttributes.find((category) => category.id === id) ?? emptyCategory
      );
    return emptyCategory;
  };
  const findAttributeByLabel = (label?: string) => {
    const emptyAttribute = { name: "", value: "" };
    if (optionAttributes && label)
      return (
        optionAttributes.find((attribute) => attribute.label === label) ??
        emptyAttribute
      );
    return emptyAttribute;
  };

  const selectHandler = (option: SingleValue<OptionType>, id: number) => {
    if ("optionMappingSelectHandler" in props)
      props.optionMappingSelectHandler(option, id);
    else props.optionValueMappingSelectHandler(option, id, props.parentId);
  };

  return (
    <div className={`${styles.sectionContent}`}>
      {mappedOptions.map((mapping, index) => {
        const mappedValue =
          sectionType == NavigationSections.CategoriesMapping
            ? findCategoryById(mapping.mappedValue).value
            : findAttributeByLabel(mapping.mappedValue).value;

        return (
          <MemoizedOptionSelect
            type={SellerMappingTypeEnum.ATTRIBUTE_VALUE}
            key={mapping.id}
            index={index}
            originalValue={mapping.originalValue}
            optionAttributes={optionAttributes ?? []}
            onChange={(option: SingleValue<OptionType>) =>
              selectHandler(option, mapping.id)
            }
            mappedValue={mappedValue}
          />
        );
      })}
    </div>
  );
};

export const MemoizedMappingSection = memo(
  SectionMapping,
  (oldProps, newProps) => {
    for (let i = 0; i < oldProps.mappedOptions.length; ++i) {
      const oldMappedValue = oldProps.mappedOptions[i].mappedValue;
      const newMappedValue = newProps.mappedOptions[i].mappedValue;

      if (oldMappedValue !== newMappedValue) {
        return false;
      }
    }

    if (oldProps.sectionType == NavigationSections.AttributeMapping)
      return (
        oldProps.optionAttributes.length === newProps.optionAttributes.length
      );

    return false;
  }
);
