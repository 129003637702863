import { SingleValue } from "react-select";

import { InputWithIcon } from "~/components/form/InputWithIcon";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { MessagesSelectFilterEnum } from "~/types/data/Message.types";

import { MessagesFilterState } from "..";
import styles from "./index.module.scss";

interface Props {
  filterState: MessagesFilterState;
  filterChangeHandler: <T extends keyof MessagesFilterState>(
    inputName: T,
    changes: MessagesFilterState[T]
  ) => void;
}

export const FilterBar = ({ filterState, filterChangeHandler }: Props) => {
  const filterOptions: OptionType[] = [
    { label: "Read", value: MessagesSelectFilterEnum.READ },
    { label: "Unread", value: MessagesSelectFilterEnum.UNREAD },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.selectInputsContainer}>
        <SelectInput
          fontSize="12px"
          backgroundColor="white"
          options={filterOptions}
          onChange={(option: SingleValue<OptionType>) => {
            const filterValue = !option?.value
              ? undefined
              : option.value === MessagesSelectFilterEnum.READ
              ? MessagesSelectFilterEnum.READ
              : MessagesSelectFilterEnum.UNREAD;
            filterChangeHandler("filterBox", filterValue);
          }}
          value={filterState.filterBox || ""}
          hasDefault={true}
          defaultLabel="Not selected"
          width="10rem"
        />
      </div>
      <div className={styles.searchInputContainer}>
        <InputWithIcon
          onChange={(value: string | number) => {
            filterChangeHandler("searchBox", value.toString());
          }}
          borderColor="#ECECEC"
          value={filterState.searchBox}
          borderRadius="5px"
          backgroundColor="white"
          iconPath="/assets/search-icon.svg"
          placeholder="Chercher une commande ou un nom"
        />
      </div>
    </div>
  );
};
