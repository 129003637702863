import { MyPayOut } from "~/types/data/Accounting.types";

import PayOutsTableRow from "../PayOutsTableRow";
import styles from "./index.module.scss";

interface Props {
  payOuts: MyPayOut[];
  selectedRows: MyPayOut[];
  onCheckboxChange: (payOuts: MyPayOut) => void;
}

const PayOutsTableBody = ({
  payOuts,
  selectedRows,
  onCheckboxChange,
}: Props) => {
  const isSelected = (payOutId?: number) =>
    selectedRows.findIndex(({ id }) => payOutId === id) > -1;
  return (
    <tbody className={`${styles.tbody}`}>
      {payOuts.map((payOut) => {
        const isRowSelected = isSelected(payOut.id);
        return (
          <PayOutsTableRow
            key={payOut.id}
            payOut={payOut}
            isSelected={isRowSelected}
            onCheckboxChange={onCheckboxChange}
          />
        );
      })}
    </tbody>
  );
};

export default PayOutsTableBody;
