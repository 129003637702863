import { gql } from "@apollo/client";

import {
  graphqlGetCarriersFields,
  graphqlGetDeliveryPackagesFields,
  graphqlGetReedooCarrierFields,
} from "~/constants/graphql";
import { Carrier, ReedooCarrier } from "~/types/data/Carrier.types";

export interface GetCarriersResponse {
  getCarriers: Carrier[];
}

export interface GetReedooCarriersResponse {
  getReedooCarriers: ReedooCarrier[];
}

const carrierFields = graphqlGetCarriersFields.join(" ");
const reedooCarrierFields = graphqlGetReedooCarrierFields.join(" ");
const deliveryPackageFields = graphqlGetDeliveryPackagesFields.join(" ");

export const getCarriers = gql`
  query {
    getCarriers {
      ${carrierFields}
    }
  }
`;

export const getReedooCarriers = gql`
  query {
    getReedooCarriers{
      ${reedooCarrierFields}

      deliveryPackages {
        ${deliveryPackageFields}
      }

    }
  }
`;
