import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import {
  GET_ADMIN_ATTRIBUTES,
  GetAdminAttributesResponse,
} from "~/api/graphql/attribute";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { Attribute } from "~/types/data/Attribute.types";
import { AttributeValue } from "~/types/data/AttributeValue.types";

import { AttributeCard } from "./AttributeCard";
import styles from "./index.module.scss";
import { ValuesSection } from "./ValuesSection";

export const Attributes = () => {
  const [attributes, setAttributes] = useState<Attribute[]>();
  const [fetchingErrorMessage, setFetchingErrorMessage] = useState("");

  const [selectedAttribute, setSelectedAttribute] = useState<Attribute>();

  const [getAttributesTrigger] = useLazyQuery<GetAdminAttributesResponse>(
    GET_ADMIN_ATTRIBUTES,
    {
      onCompleted: (data) => {
        setAttributes(data.getAdminAttributes);
      },
      onError: (error) => {
        setFetchingErrorMessage(error.message);
      },
    }
  );

  const fetchAttributes = async () => {
    await getAttributesTrigger();
  };

  const createAttributeValueStateHandler = (
    attributeId: number,
    attributeValue: AttributeValue
  ) => {
    setAttributes((prevState) => {
      if (!prevState) return prevState;
      const attributeIndex = prevState?.findIndex(
        (attribute) => attribute.id === attributeId
      );

      const attributes = [...prevState];

      const attributeValues = attributes[attributeIndex].values ?? [];

      attributes[attributeIndex].values = [attributeValue, ...attributeValues];

      return attributes;
    });
  };

  const updateAttributeValueState = (
    attributeId: number,
    attributeValueId: number,
    attributeValue: AttributeValue
  ) => {
    setAttributes((prevState) => {
      if (!prevState) return prevState;
      const attributeIndex = prevState?.findIndex(
        (attribute) => attribute.id === attributeId
      );

      const attributes = [...prevState];

      const attributeValues = attributes[attributeIndex].values ?? [];

      attributes[attributeIndex].values = attributeValues.map((value) => {
        if (value.id === attributeValueId) {
          return attributeValue;
        }
        return value;
      });

      return attributes;
    });
  };

  const deleteAttributeValueState = (
    attributeId: number,
    attributeValueId: number
  ) => {
    setAttributes((prevState) => {
      if (!prevState) return prevState;
      const attributeIndex = prevState?.findIndex(
        (attribute) => attribute.id === attributeId
      );

      const attributes = [...prevState];

      const attributeValues = attributes[attributeIndex].values ?? [];

      attributes[attributeIndex].values = attributeValues.filter(
        (value) => value.id !== attributeValueId
      );

      return attributes;
    });
  };

  useEffect(() => {
    fetchAttributes();
  }, []);

  return (
    <div className={styles.container}>
      {!!fetchingErrorMessage ? (
        <div className={styles.error}>
          <p>{fetchingErrorMessage}</p>
        </div>
      ) : !attributes ? (
        <div className={styles.loadingContainer}>
          <SimpleLoader size="size3" fill={colors.$primary} />
        </div>
      ) : (
        <>
          <h2 className={styles.header}>Attributes</h2>
          <div className={styles.dataContainer}>
            <div className={styles.attributesList}>
              {attributes.map((attribute) => (
                <AttributeCard
                  key={attribute.id}
                  attribute={attribute}
                  clickHandler={(att) => {
                    setSelectedAttribute(att);
                  }}
                />
              ))}
            </div>
            {selectedAttribute && (
              <ValuesSection
                attribute={selectedAttribute}
                createAttributeValueState={createAttributeValueStateHandler}
                updateAttributeValueState={updateAttributeValueState}
                deleteAttributeValueState={deleteAttributeValueState}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
