import { CustomInput } from "~/components/form/CustomInput";
import { colors } from "~/constants/styles";
import {
  DeliveryDetailsState,
  DeliveryPackage,
} from "~/types/data/Carrier.types";

import { DeliveryAreaKey, DeliveryMethodKey } from "..";
import styles from "./index.module.scss";

interface Props {
  stateKey: string;
  codeIndex: number;
  methodKey: DeliveryMethodKey;
  areaKey: DeliveryAreaKey;
  deliveryDetailsState?: DeliveryDetailsState;
  deliveryPackageChangeHandler: (
    stateKey: string,
    codeIndex: number,
    type: DeliveryMethodKey,
    area: DeliveryAreaKey,
    changes: DeliveryDetailsState
  ) => void;
}

export const DeliveryPackageSection = ({
  stateKey,
  codeIndex,
  methodKey,
  areaKey,
  deliveryDetailsState,
  deliveryPackageChangeHandler,
}: Props) => {
  const inputChangeHandler = <T extends keyof DeliveryPackage>(
    inputName: T,
    changes: DeliveryPackage[T]
  ) => {
    if (deliveryDetailsState) {
      const newDeliveryDetailsState = {
        ...deliveryDetailsState,
        [inputName]: changes === "" ? undefined : changes,
      };

      deliveryPackageChangeHandler(
        stateKey,
        codeIndex,
        methodKey,
        areaKey,
        newDeliveryDetailsState
      );
    }
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.inputsContainer}`}>
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("minDays", value as number);
          }}
          value={deliveryDetailsState?.minDays}
          label="Délai min. :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="12px"
          type="number"
        />
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("maxDays", value as number);
          }}
          value={deliveryDetailsState?.maxDays}
          label="Délai max. :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="12px"
          type="number"
        />
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("price", value as number);
          }}
          value={deliveryDetailsState?.price}
          label="Prix :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="12px"
          type="number"
        />
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("freeThreshold", value as number);
          }}
          value={deliveryDetailsState?.freeThreshold ?? ""}
          label="Seuil de gratuité :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="12px"
          type="number"
        />
      </div>
    </div>
  );
};
