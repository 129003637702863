import { ApolloError } from "@apollo/client";
import { useEffect, useState } from "react";

import {
  UPDATE_BRAND_COMPANY,
  UpdateBrandCompanyInput,
  UpdateBrandCompanyResponse,
} from "~/api/graphql/BrandCompany";
import { ColorPickerInput } from "~/components/form/ColorPickerInput";
import { CustomButton } from "~/components/form/CustomButton";
import { CustomInput } from "~/components/form/CustomInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useUserContext } from "~/context/userContext";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import { BrandCompany } from "~/types/data/BrandCompany.type";
import { PermissionLevelEnum } from "~/types/data/User.types";

import { ImageEditorDisplay } from "../../../components/form/ImageEditorDisplay";
import styles from "./index.module.scss";
import { validateAndUploadBrandCompanyMedia } from "./utils/validateAndUploadBrandCompanyMedia.util";
import { validateUpdateBrandCompany } from "./utils/validateUpdateBrandCompany.util";

export const Details = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { userState } = useUserContext();
  const { companyAccount, setCompanyAccount } = useCompanyAccountContext();

  const [brandCompany, setBrandCompany] = useState<BrandCompany>();

  const updateBrandCompanySuccessHandler = (
    data: UpdateBrandCompanyResponse
  ) => {
    setCompanyAccount((state) => ({
      ...state,
      ...(state.brandCompany && {
        brandCompany: { ...state.brandCompany, ...data.updateBrandCompany },
      }),
    }));
  };

  const updateBrandCompanyErrorHandler = (error: ApolloError) => {
    setErrorMessage(error.message);
  };

  const { trigger: updateBrandCompanyTrigger } = useMutationWithCallbacks<
    UpdateBrandCompanyResponse,
    UpdateBrandCompanyInput
  >(
    UPDATE_BRAND_COMPANY,
    updateBrandCompanySuccessHandler,
    updateBrandCompanyErrorHandler
  );

  const inputChangeHandler = <T extends keyof BrandCompany>(
    inputName: T,
    changes: BrandCompany[T]
  ) => {
    setBrandCompany((state) =>
      state ? { ...state, [inputName]: changes } : undefined
    );
  };

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!brandCompany) return;
    setSuccessMessage("");
    setErrorMessage("");
    setIsLoading(true);

    // validate input
    const validationError = validateUpdateBrandCompany(brandCompany);

    if (validationError) {
      setErrorMessage(validationError);
      setIsLoading(false);
      return;
    }

    // validate and upload media
    const { input: validatedInput, error: mediaError } =
      await validateAndUploadBrandCompanyMedia(brandCompany);

    if (!validatedInput) {
      setErrorMessage(mediaError);
      setIsLoading(false);
      return;
    }

    // send update request
    await updateBrandCompanyTrigger({
      variables: { UpdateBrandCompanyInput: validatedInput },
    });

    setIsLoading(false);
  };

  const uploadAuthBannerHandler = (file?: File) => {
    if (!file) return;

    const imageUrl = URL.createObjectURL(file);
    inputChangeHandler("authBanner", imageUrl);
    inputChangeHandler("authBannerFile", file);
  };

  const uploadMobileAuthBannerHandler = (file?: File) => {
    if (!file) return;

    const imageUrl = URL.createObjectURL(file);
    inputChangeHandler("mobileAuthBanner", imageUrl);
    inputChangeHandler("mobileAuthBannerFile", file);
  };

  const updloadLogoHandler = (file?: File) => {
    if (!file) return;

    const imageUrl = URL.createObjectURL(file);
    inputChangeHandler("logo", imageUrl);
    inputChangeHandler("logoFile", file);
  };

  const uploadBannerHandler = (file?: File) => {
    if (!file) return;

    const imageUrl = URL.createObjectURL(file);
    inputChangeHandler("banner", imageUrl);
    inputChangeHandler("bannerFile", file);
  };

  const uploadMobileBannerHandler = (file?: File) => {
    if (!file) return;

    const imageUrl = URL.createObjectURL(file);
    inputChangeHandler("mobileBanner", imageUrl);
    inputChangeHandler("mobileBannerFile", file);
  };

  const removeAuthBannerHandler = () => {
    inputChangeHandler("authBanner", "");
    inputChangeHandler("authBannerFile", undefined);
  };

  const removeMobileAuthBannerHandler = () => {
    inputChangeHandler("mobileAuthBanner", "");
    inputChangeHandler("mobileAuthBannerFile", undefined);
  };

  const removeLogoHandler = () => {
    inputChangeHandler("logo", "");
    inputChangeHandler("logoFile", undefined);
  };

  const removeBannerHandler = () => {
    inputChangeHandler("banner", "");
    inputChangeHandler("bannerFile", undefined);
  };

  const removeMobileBannerHandler = () => {
    inputChangeHandler("mobileBanner", "");
    inputChangeHandler("mobileBannerFile", undefined);
  };

  useEffect(() => {
    if (companyAccount?.brandCompany) {
      setBrandCompany(companyAccount.brandCompany);
    }
  }, []);

  const disabled =
    userState?.connected &&
    userState.permissionLevel === PermissionLevelEnum.MEMBER;

  return (
    <div>
      <h2>Gérer ma marque</h2>
      <div className={styles.formCard}>
        <h3 className="title">Ma Marque</h3>
        <form
          className={styles.formContainer}
          onSubmit={(e) => {
            if (!disabled) submitHandler(e);
          }}
        >
          <div className={styles.formRow}>
            <ImageEditorDisplay
              imageUrl={brandCompany?.logo}
              id="brandCompany-logo"
              onUpload={updloadLogoHandler}
              onRemove={removeLogoHandler}
              height="5rem"
              disabled={disabled}
            />
            <CustomInput
              onChange={(value: string | number) => {
                inputChangeHandler("name", value.toString());
              }}
              value={brandCompany?.name}
              label="Name :"
              borderRadius="5px"
              noBorder={true}
              backgroundColor={colors.$inputGray}
              textColor={colors.$primaryDark}
              fontSize="14px"
              width="100%"
              disabled={disabled}
            />
          </div>
          <div className={styles.formRow}>
            <CustomInput
              onChange={(value: string | number) => {
                inputChangeHandler("websiteUrl", value.toString());
              }}
              value={brandCompany?.websiteUrl}
              label="Website url :"
              borderRadius="5px"
              noBorder={true}
              backgroundColor={colors.$inputGray}
              textColor={colors.$primaryDark}
              fontSize="14px"
              width="100%"
              disabled={disabled}
            />
          </div>
          <div className={styles.imageRow}>
            <div className={styles.leftSide}>
              <ImageEditorDisplay
                imageUrl={brandCompany?.banner}
                id="brandCompany-banner"
                label="Image principale"
                onUpload={uploadBannerHandler}
                onRemove={removeBannerHandler}
                disabled={disabled}
              />
            </div>
            <div className={styles.rightSide}>
              <ImageEditorDisplay
                imageUrl={brandCompany?.mobileBanner}
                id="brandCompany-mobileBanner"
                label="Image principale (version mobile)"
                onUpload={uploadMobileBannerHandler}
                onRemove={removeMobileBannerHandler}
                disabled={disabled}
              />
            </div>
          </div>
          <div className={styles.imageRow}>
            <div className={styles.leftSide}>
              <ImageEditorDisplay
                imageUrl={brandCompany?.authBanner}
                id="brandCompany-authBanner"
                label="Image de connexion"
                onUpload={uploadAuthBannerHandler}
                onRemove={removeAuthBannerHandler}
                disabled={disabled}
              />
            </div>
            <div className={styles.rightSide}>
              <ImageEditorDisplay
                imageUrl={brandCompany?.mobileAuthBanner}
                id="brandCompany-mobileAuthBanner"
                label="Image de connexion (version mobile)"
                onUpload={uploadMobileAuthBannerHandler}
                onRemove={removeMobileAuthBannerHandler}
                disabled={disabled}
              />
            </div>
          </div>

          <div className={styles.formRow}>
            <ColorPickerInput
              onChange={(value) => {
                inputChangeHandler("primaryColor", value);
              }}
              value={brandCompany?.primaryColor ?? ""}
              label="Primary color :"
              borderRadius="5px"
              noBorder={true}
              backgroundColor={colors.$inputGray}
              textColor={colors.$primaryDark}
              fontSize="14px"
              width="100%"
              disabled={disabled}
            />
            <ColorPickerInput
              onChange={(value) => {
                inputChangeHandler("secondaryColor", value);
              }}
              value={brandCompany?.secondaryColor ?? ""}
              label="Secondary color :"
              borderRadius="5px"
              noBorder={true}
              backgroundColor={colors.$inputGray}
              textColor={colors.$primaryDark}
              fontSize="14px"
              width="100%"
              disabled={disabled}
            />
          </div>
          {!disabled && (
            <>
              <div className="messageContainer">
                {!!errorMessage && (
                  <p className="errorMessage">{errorMessage}</p>
                )}
                {!!successMessage && <p>{successMessage}</p>}
              </div>

              <div className="buttonContainer">
                <CustomButton
                  backgroundColor={colors.$primary}
                  color="white"
                  width="fit-content"
                  borderRadius="8px"
                  padding="1rem 1.5rem"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? <SimpleLoader size="size2" /> : "Enregistrer"}
                </CustomButton>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};
