import { gql } from "@apollo/client";

import { graphqlGetSelectReasonFields } from "~/constants/graphql";
import { SelectReason } from "~/types/data/SelectReason.types";

export interface GetSelectReasonsResponse {
  getSelectReasons: SelectReason[];
}

const selectReasonFields = graphqlGetSelectReasonFields.join(" ");

export const getSelectReasons = gql`
  query  {
    getSelectReasons {
      ${selectReasonFields}
    }
  }
`;
