import Backdrop from "../Backdrop";
import FullPageLoader from "../FullPageLoader";
import styles from "./index.module.scss";

interface Props {
  show: boolean;
  message?: string;
}

export const LoadingPopup = ({ show, message }: Props) => {
  return (
    <>
      {show && <Backdrop onClick={() => {}} />}
      {show && (
        <div className={`${styles.modal}`}>
          <h2>{message ? message : "Processing.."}</h2>
          <FullPageLoader />
        </div>
      )}
    </>
  );
};
