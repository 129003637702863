import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import {
  GET_LAST_COLLECTION_IMPORT,
  GetLastCollectionImportResponse,
} from "~/api/graphql/collectionImport";
import CustomModal from "~/components/UI/CustomModal";
import StatusLabel from "~/components/UI/StatusLabel";
import SVGContainer from "~/components/UI/SVGContainer";
import {
  CollectionImport,
  SubscriptionImportStatusEnum,
} from "~/types/data/CollectionImport.type";
import { formatDate } from "~/util/functions/formatDate";

import styles from "./index.module.scss";
import { UploadFileModal } from "./UploadFileModal";

type Props = {
  selectedCollectionId?: number;
};
export const ImportFileSection = ({ selectedCollectionId }: Props) => {
  const [lastImport, setLastImport] = useState<CollectionImport | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [getLastCollectionImportTrigger] =
    useLazyQuery<GetLastCollectionImportResponse>(GET_LAST_COLLECTION_IMPORT);

  const dismissModal = () => {
    setShowModal(false);
  };

  const setLastImportHandler = (value: CollectionImport) => {
    setLastImport(value);
  };

  const setIsLoadingHandler = (value: boolean) => {
    setIsLoading(value);
  };

  const lastImportedDate = lastImport?.createdAt
    ? formatDate(lastImport.createdAt)
    : "N/A";
  const lastImportedStatus = lastImport?.status ?? "N/A";
  const lastImportedStatusColor =
    lastImport?.status === SubscriptionImportStatusEnum.DONE
      ? "green"
      : lastImport?.status === SubscriptionImportStatusEnum.ERROR
      ? "red"
      : lastImport?.status === SubscriptionImportStatusEnum.IN_PROGRESS
      ? "#dd8500"
      : "#818181";

  const getLastCollectionImportHandler = async () => {
    const response = await getLastCollectionImportTrigger();
    setLastImport(response.data?.getLastCollectionImport);
  };

  useEffect(() => {
    getLastCollectionImportHandler();

    const intervalId = setInterval(() => {
      getLastCollectionImportHandler();
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      {selectedCollectionId && (
        <CustomModal
          show={showModal}
          disableCancel={isLoading}
          onCancel={dismissModal}
        >
          <UploadFileModal
            setLastImportHandler={setLastImportHandler}
            collectionId={selectedCollectionId}
            hideModal={dismissModal}
            isLoading={isLoading}
            setIsLoading={setIsLoadingHandler}
          />
        </CustomModal>
      )}
      <div className={styles.container}>
        <h3>Import File</h3>
        <div className={styles.BodyContainer}>
          {
            <>
              <p className={styles.rowContainer}>
                <span className={styles.boldText}>Last imported File:</span>{" "}
                {lastImport ? (
                  <a href={lastImport.filePath}>Download</a>
                ) : (
                  "N/A"
                )}
              </p>
              <p className={styles.rowContainer}>
                <span className={styles.boldText}>Date:</span>{" "}
                {lastImportedDate}
              </p>
              <div className={styles.rowContainer}>
                <p className={styles.boldText}>Status: </p>
                <StatusLabel
                  label={lastImportedStatus}
                  backgroundColor={lastImportedStatusColor}
                  color="white"
                />
              </div>
              {lastImport?.status === SubscriptionImportStatusEnum.ERROR &&
                lastImport?.errorMessage && (
                  <p className={styles.rowContainer}>
                    <span className={styles.boldText}>Error:</span>{" "}
                    {lastImport.errorMessage}
                  </p>
                )}
            </>
          }
          {(!lastImport ||
            lastImport?.status !== SubscriptionImportStatusEnum.IN_PROGRESS) &&
            selectedCollectionId && (
              <div className={styles.buttonContainer}>
                <div
                  className={styles.button}
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <SVGContainer
                    height="16px"
                    width="16px"
                    imagePath="/assets/add-button.svg"
                  />
                  <p>Uplaod File</p>
                </div>
              </div>
            )}
          {!selectedCollectionId && (
            <p className={styles.rowContainer}>
              Please select a collection to upload a file
            </p>
          )}
        </div>
      </div>
    </>
  );
};
