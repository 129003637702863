import { useQuery } from "@apollo/client";
import { useState } from "react";

import {
  GetAllLevel1CategoriesResponse,
  getCategories,
} from "~/api/graphql/category";
import { Category } from "~/types/data/Category.type";

import { CategoryToggle } from "./CategoryToggle";
import styles from "./index.module.scss";

export const Taxonomie = () => {
  const { data: categoriesData, error: _vendorcategoriesError } =
    useQuery<GetAllLevel1CategoriesResponse>(getCategories, {
      fetchPolicy: "cache-first",
    });
  const categories: Category[] = categoriesData?.getAllLevel1Categories || [];
  const [openCategories, setOpenCategories] = useState<
    { id: number; parentId?: number }[]
  >([]);
  const isCatOpen = (id: number, parentId?: number) =>
    !!openCategories.find(
      (cat) => cat.id == id && (!parentId || cat.parentId == parentId)
    );
  const toggleCategory = (id: number, parentId?: number) => {
    if (isCatOpen(id, parentId))
      return setOpenCategories((prev) =>
        prev.filter((cat) =>
          parentId
            ? cat.id !== id || cat.parentId !== parentId
            : cat.id !== id && cat.parentId !== id
        )
      );

    setOpenCategories([...openCategories, { id, parentId }]);
  };

  return (
    <>
      {categories.map(({ children, id, name, commissionPercentage }) => {
        const haveChildren = (children && children?.length > 0) ?? false;
        const isOpen = isCatOpen(id);
        return (
          <>
            <CategoryToggle
              isOpen={isOpen}
              onClick={() => toggleCategory(id)}
              haveChildren={haveChildren}
              discountPercent={commissionPercentage ?? 0}
              name={name}
              key={id}
            />
            {isOpen && haveChildren && (
              <div className={styles.childrenContainer}>
                <div className={styles.childrenContainerLine} />
                <div>
                  {children?.map(
                    ({
                      children: cat2,
                      id: cat1Id,
                      commissionPercentage: cat1Commission,
                      name: cat1Name,
                    }) => {
                      const haveChildren = (cat2 && cat2?.length > 0) ?? false;
                      const isOpen = isCatOpen(cat1Id, id);
                      return (
                        <>
                          <CategoryToggle
                            isOpen={isOpen}
                            onClick={() => toggleCategory(cat1Id, id)}
                            haveChildren={haveChildren}
                            discountPercent={cat1Commission ?? 0}
                            name={cat1Name}
                            key={cat1Id}
                          />
                          {isOpen && haveChildren && (
                            <div className={styles.childrenContainer}>
                              <div className={styles.childrenContainerLine} />
                              <div>
                                {cat2?.map(
                                  ({
                                    children: cat3,
                                    id: cat2Id,
                                    name: cat2Name,
                                    commissionPercentage: cat2Commission,
                                  }) => {
                                    const haveChildren =
                                      (cat3 && cat3?.length > 0) ?? false;
                                    const isOpen = isCatOpen(cat2Id, cat1Id);
                                    return (
                                      <CategoryToggle
                                        onClick={() => toggleCategory(cat2Id)}
                                        isOpen={isOpen}
                                        haveChildren={haveChildren}
                                        discountPercent={cat2Commission ?? 0}
                                        name={cat2Name}
                                        key={cat2Id}
                                      />
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      );
                    }
                  )}
                </div>
              </div>
            )}
          </>
        );
      })}
    </>
  );
};
