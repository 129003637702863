import { CompanyCarrier, ReedooCarrier } from "~/types/data/Carrier.types";

export const getDeliveryPackageFromCarriers = (
  carriers: (ReedooCarrier | CompanyCarrier)[]
) => {
  const codes = carriers
    .flatMap((carrier) => carrier.deliveryPackages)
    .map((deliveryPackage) => deliveryPackage.code);
  return [...new Set(codes)];
};
