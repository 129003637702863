import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { OrderBy } from "~/types/common/filter.types";
import { MyPayOut, MyTransaction } from "~/types/data/Accounting.types";

import type { RootState } from "../store";

export interface AccountingFilterArgs {
  transactionsOrderBy: OrderBy<MyTransaction>;
  payOutsOrderBy: OrderBy<MyPayOut>;
  cursor?: number;
  take?: number;
  searchBox?: string;
}

type FilterArgs =
  | "payOutsOrderBy"
  | "transactionsOrderBy"
  | "cursor"
  | "searchBox"
  | "clearAll";

export const accountingFilterArgsInitialState: AccountingFilterArgs = {
  payOutsOrderBy: {},
  transactionsOrderBy: {},
  take: 20,
};

export const accountingFilterSlice = createSlice({
  name: "accountingFilter",
  initialState: accountingFilterArgsInitialState,
  reducers: {
    onFilterArgsChange(
      state,
      action: PayloadAction<{
        filterArg: FilterArgs;
        payOutsOrderBy?: OrderBy<MyPayOut>;
        transactionsOrderBy?: OrderBy<MyTransaction>;
        cursor?: number;
        searchBox?: string;
      }>
    ) {
      const {
        filterArg,
        transactionsOrderBy,
        payOutsOrderBy,
        cursor,
        searchBox,
      } = action.payload;

      if (filterArg === "cursor") {
        state.cursor = cursor;
      } else if (filterArg === "transactionsOrderBy" && transactionsOrderBy) {
        state.transactionsOrderBy = transactionsOrderBy;
      } else if (filterArg === "payOutsOrderBy" && payOutsOrderBy) {
        state.payOutsOrderBy = payOutsOrderBy;
      } else if (filterArg === "searchBox") {
        state.searchBox = searchBox;
      } else if (filterArg === "clearAll") {
        return { ...accountingFilterArgsInitialState };
      }
    },
  },
});

export const { onFilterArgsChange } = accountingFilterSlice.actions;

export const selectAccountingFilterArgs = (state: RootState) => {
  return state.accountingFilter;
};
export default accountingFilterSlice.reducer;
