import { useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

import SVGContainer from "~/components/UI/SVGContainer";
import { Category } from "~/types/data/Category.type";
import { moveItemInList } from "~/util/functions/moveItemInList";

import { CategoryGridItemType } from "..";
import styles from "./index.module.scss";

type Props = {
  category: Category;
  addButtonClickHandler: (category: Category) => void;
  editButtonClickHandler: (category: Category) => void;
  deleteButtonClickHandler: (
    category: Category,
    level: number,
    parentId?: number
  ) => void;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  categoriesItemsState: CategoryGridItemType[];
  setCategoriesItemsState: React.Dispatch<
    React.SetStateAction<CategoryGridItemType[]>
  >;
  parentCategoryId?: number;
  setUpdateCategoriesButtonDisabled: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  isDropDisabled: boolean;
  parentId?: number;
};
export const CategoryToggle = ({
  category,
  addButtonClickHandler,
  editButtonClickHandler,
  deleteButtonClickHandler,
  dragHandleProps,
  categoriesItemsState,
  setCategoriesItemsState,
  parentCategoryId,
  setUpdateCategoriesButtonDisabled,
  isDropDisabled,
  parentId,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { id, name, commissionPercentage, children, level } = category;

  const toggleCategoryHandler = () => {
    setIsOpen((prevState) => !prevState);
  };

  const src = `/assets/${
    isOpen ? "subtract-icon" : "black-circular-add-icon"
  }.svg`;

  let childCategories: Category[] = [];

  if (level === 1) {
    const ch =
      categoriesItemsState?.find((item) => item.key === category.id.toString())
        ?.category.children ?? [];
    childCategories = ch;
  } else if (level === 2) {
    const parentCategory = categoriesItemsState?.find(
      (item) => item.key === parentCategoryId?.toString()
    )?.category;
    const ch =
      parentCategory?.children?.find((item) => item.id === category.id)
        ?.children ?? [];
    childCategories = ch;
  }

  const categoriesItems: CategoryGridItemType[] =
    childCategories?.map((category, index) => {
      return {
        category: category,
        index: index,
        key: category.id.toString(),
      };
    }) ?? [];

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const sortedCategoriesItems = moveItemInList(
      categoriesItems,
      result.source.index,
      result.destination.index
    );

    if (category.level === 1) {
      setCategoriesItemsState((prevState) => {
        const newState = [...prevState];
        const cat1Index = newState.findIndex(
          (item) => item.key === category.id.toString()
        );
        newState[cat1Index].category.children = sortedCategoriesItems.map(
          (item) => item.category
        );
        return newState;
      });
    } else if (category.level === 2) {
      setCategoriesItemsState((prevState) => {
        const newState = [...prevState];
        const cat1Index = newState.findIndex(
          (item) => item.key === parentCategoryId?.toString()
        );
        const cat2Index = newState[cat1Index].category.children?.findIndex(
          (item) => item.id === category.id
        );

        const cat1Children = newState[cat1Index].category.children;
        if (
          typeof cat1Index === "number" &&
          typeof cat2Index === "number" &&
          cat1Children
        ) {
          cat1Children[cat2Index].children = sortedCategoriesItems.map(
            (item) => item.category
          );
        }

        return newState.map((item) => {
          if (item.key === parentCategoryId?.toString()) {
            return {
              ...item,
              category: {
                ...item.category,
                children: cat1Children,
              },
            };
          } else {
            return item;
          }
        });
      });
    }
    setUpdateCategoriesButtonDisabled(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.categoryContainer}>
        <div className={`${styles.iconContainer}`} {...dragHandleProps}>
          <SVGContainer
            height="14px"
            width="14px"
            imagePath="/assets/drag-icon.svg"
          />
        </div>
        <div className={styles.iconContainer}>
          {!!children && (
            <div onClick={toggleCategoryHandler}>
              <SVGContainer height="14px" width="14px" imagePath={src} />
            </div>
          )}
        </div>
        <div>{name}</div>
        {!!commissionPercentage && (
          <div className={styles.commissionPercentageContainer}>
            {commissionPercentage}%
          </div>
        )}
        {level !== 3 && (
          <SVGContainer
            height="14px"
            width="14px"
            imagePath="/assets/add-button.svg"
            onClick={() => {
              addButtonClickHandler(category);
            }}
          />
        )}
        <SVGContainer
          height="14px"
          width="14px"
          imagePath="/assets/edit-button.svg"
          onClick={() => {
            editButtonClickHandler(category);
          }}
        />
        <SVGContainer
          height="14px"
          width="14px"
          imagePath="/assets/delete-icon.svg"
          onClick={() => {
            deleteButtonClickHandler(category, level, parentId);
          }}
        />
      </div>
      {isOpen && !!children && (
        <div className={styles.childrenContainer}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              droppableId={`droppable${id}`}
              isDropDisabled={isDropDisabled}
            >
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {categoriesItems.map((categoryItem, index) => (
                    <Draggable
                      key={categoryItem.key}
                      draggableId={categoryItem.key}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          key={categoryItem.key}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <CategoryToggle
                            category={categoryItem.category}
                            key={categoryItem.category.id}
                            dragHandleProps={provided.dragHandleProps}
                            addButtonClickHandler={addButtonClickHandler}
                            editButtonClickHandler={editButtonClickHandler}
                            categoriesItemsState={categoriesItemsState}
                            setCategoriesItemsState={setCategoriesItemsState}
                            parentCategoryId={category.id}
                            setUpdateCategoriesButtonDisabled={
                              setUpdateCategoriesButtonDisabled
                            }
                            isDropDisabled={isDropDisabled}
                            deleteButtonClickHandler={deleteButtonClickHandler}
                            parentId={id}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </div>
  );
};
