import { useState } from "react";

import { CustomButton } from "~/components/form/CustomButton";
import SVGContainer from "~/components/UI/SVGContainer";

import { UploadMediaType } from "..";
import styles from "./index.module.scss";

type Props = {
  placeholder?: string;
  disabled?: boolean;
  sendTextMessageHandler: (textMessage: string) => void;
  filePickerHandler: (file: File, type: UploadMediaType) => void;
};

export const MessageBar = ({
  placeholder,
  disabled = false,
  sendTextMessageHandler,
  filePickerHandler,
}: Props) => {
  const [textMessage, setTextMessage] = useState("");

  const onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    sendTextMessageHandler(textMessage);
    setTextMessage("");
  };
  const textChangeHandler = (event: React.FormEvent<HTMLTextAreaElement>) => {
    //check ig event is enter and not shift enter
    setTextMessage(event.currentTarget.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      onSubmit();
    }
  };

  const filePickedHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: UploadMediaType
  ) => {
    const files = e.target.files;
    if (files && files?.length > 0) {
      filePickerHandler(files[0], type);
    }
  };

  return (
    <form className={`${styles.container}`} onSubmit={onSubmit}>
      <input
        type="file"
        id="message-image-picker"
        onChange={(e) => {
          filePickedHandler(e, "picture");
        }}
        disabled={disabled}
        onClick={(e) => {
          if (!disabled) e.currentTarget.value = "";
        }}
        hidden
      />
      <input
        type="file"
        id="message-attachement-picker"
        onChange={(e) => {
          filePickedHandler(e, "file");
        }}
        disabled={disabled}
        onClick={(e) => {
          if (!disabled) e.currentTarget.value = "";
        }}
        hidden
      />

      <label htmlFor="message-image-picker">
        <SVGContainer
          height="16px"
          width="16px"
          imagePath="/assets/camera-icon.svg"
          onClick={() => {}}
        />
      </label>

      <div className={`${styles.inputContainer}`}>
        <textarea
          value={textMessage}
          className={styles.input}
          autoComplete="off"
          placeholder={placeholder || ""}
          onInput={textChangeHandler}
          disabled={disabled}
          onKeyDown={handleKeyDown}
        />
      </div>
      <label htmlFor="message-attachement-picker">
        <SVGContainer
          height="16px"
          width="16px"
          imagePath="/assets/attachement-icon.svg"
          onClick={() => {}}
        />
      </label>
      <CustomButton type="submit" width="fit-content">
        <SVGContainer
          height="16px"
          width="16px"
          imagePath="/assets/message-send-button.svg"
        />
      </CustomButton>
    </form>
  );
};
