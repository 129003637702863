// import { GraphQLGetResponse } from "~/types/common/graphql.types";
import { ApiGetResponse } from "~/types/common/auth.types";
import { User } from "~/types/data/User.types";

import { gql, graphqlQuery } from "../../clients/apollo";

interface ApiGetUserResponse {
  getMe: User | null;
}

export async function getUserInfo(
  fields: (keyof User)[]
): Promise<ApiGetResponse<User>> {
  const graphqlField = fields.join(" ");
  const res = await graphqlQuery<ApiGetUserResponse, never>(
    gql`
      query {
        getMe {
          ${graphqlField}
        }
      }
    `
  );
  if (res?.data && res.data.getMe) {
    return {
      success: true,
      data: res.data.getMe,
    };
  }
  return { success: false };
}
