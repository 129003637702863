import { useCompanyAccountContext } from "~/context/companyAccountContext";
import {
  DeliveryAreaEnum,
  DeliveryMethodEnum,
} from "~/types/data/Carrier.types";

import { DeliveryPackageSection } from "../DeliveryPackageSection";

interface Props {
  area: DeliveryAreaEnum;
  method: DeliveryMethodEnum;
  carrierIndex: number;
}

export const DeliveryAreaSection = ({ area, method, carrierIndex }: Props) => {
  const { companyAccount } = useCompanyAccountContext();

  return (
    <div>
      {companyAccount.reedooCarriers[carrierIndex].deliveryPackages
        .filter((deliveryPackage) => {
          return deliveryPackage.deliveryArea === area;
        })
        .filter((deliveryPackage) => {
          return deliveryPackage.deliveryMethod === method;
        })
        .map((deliveryPackage, index) => {
          return (
            <DeliveryPackageSection
              key={index}
              packageIndex={index}
              deliveryPackage={deliveryPackage}
              carrierIndex={carrierIndex}
            />
          );
        })}
    </div>
  );
};
