import SVGContainer from "~/components/UI/SVGContainer";

import styles from "./index.module.scss";

type Props = {
  iconName: string;
  name: string;
  link: string;
  bottomBorder?: boolean;
};

export const CarrierComponent = ({
  iconName,
  name,
  link,
  bottomBorder = false,
}: Props) => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <SVGContainer
          width="28px"
          height="28px"
          imagePath={`/assets/${iconName}`}
        />
        <h2>{name}</h2>
      </div>
      <p>
        Lien de tracking :
        <a href={link} target="_blank" rel="noreferrer">
          {link}{" "}
        </a>
      </p>
      {bottomBorder && <div className={styles.bottomBorder}></div>}
    </div>
  );
};
