import { useDispatch } from "react-redux";

import CustomCheckbox from "~/components/form/CustomCheckbox";
import { SortingButtons } from "~/components/UI/SortingButtons";
import { useAppSelector } from "~/redux/hooks";
import {
  onFilterArgsChange,
  selectAccountingFilterArgs,
} from "~/redux/slice/accounting.slice";
import { OrderByEnum } from "~/types/common/filter.types";
import { HeadCell } from "~/types/common/headCell.type";
import { MyPayOut } from "~/types/data/Accounting.types";

import styles from "./index.module.scss";

interface Props {
  headCells: HeadCell<MyPayOut>[];
  onSelectAllChange: (selected: boolean) => void;
  allSelected: boolean;
}

const PayOutsTableHead = ({
  headCells,
  allSelected,
  onSelectAllChange,
}: Props) => {
  const selectAllChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onSelectAllChange(event.target.checked);
  };
  const { payOutsOrderBy } = useAppSelector(selectAccountingFilterArgs);

  const dispatch = useDispatch();

  const orderByHandler = (key: keyof MyPayOut, order: OrderByEnum) => {
    dispatch(
      onFilterArgsChange({
        filterArg: "payOutsOrderBy",
        payOutsOrderBy: { [key]: order },
      })
    );
  };

  return (
    <thead>
      <tr>
        {headCells.map((headCell) => {
          const isSortable = headCell.isSortable;
          const isCheckbox = headCell.isCheckbox;
          const label = headCell.label;
          const id = headCell.id;

          if (isCheckbox) {
            return (
              <th key={id}>
                <CustomCheckbox
                  onChange={selectAllChangeHandler}
                  checked={allSelected}
                  padding="0.7rem"
                />
              </th>
            );
          }

          return (
            <th key={id}>
              <div className={styles.sortable}>
                <p>{label}</p>
                {isSortable && (
                  <SortingButtons
                    ascendingActive={
                      !!(
                        headCell.associatedKey &&
                        payOutsOrderBy[headCell.associatedKey] ===
                          OrderByEnum.ASC
                      )
                    }
                    descendingActive={
                      !!(
                        headCell.associatedKey &&
                        payOutsOrderBy[headCell.associatedKey] ===
                          OrderByEnum.DESC
                      )
                    }
                    orderByAscendingingHandler={() => {
                      orderByHandler(
                        headCell.associatedKey || "id",
                        OrderByEnum.ASC
                      );
                    }}
                    orderByDescendingHandler={() => {
                      orderByHandler(
                        headCell.associatedKey || "id",
                        OrderByEnum.DESC
                      );
                    }}
                  />
                )}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default PayOutsTableHead;
