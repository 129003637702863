import { Category } from "./Category.type";
import { VendorCategory } from "./VendorCategory.types";

export enum GenderEnum {
  FEMALE = "FEMALE",
  MALE = "MALE",
  MIXED = "MIXED",
}

export enum SellingTypeEnum {
  NEW = "NEW",
  RENT = "RENT",
  SECOND_HAND = "SECOND_HAND",
}

export enum RentingPeriodEnum {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
}

export enum ProductStatusEnum {
  WAITING_FOR_VALIDATION = "WAITING_FOR_VALIDATION",
  INCOMPLETE = "INCOMPLETE",
  MODIFICATION_REQUESTED = "MODIFICATION_REQUESTED",
  REFUSED = "REFUSED",
  PUBLISHED = "PUBLISHED",
}

export enum ImageImportStatusEnum {
  NOT_STARTED = "NOT_STARTED",
  DONE = "DONE",
  ERROR = "ERROR",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum ProductHighlightEnum {
  IS_REEDOO_FAVORITE = "IS_REEDOO_FAVORITE",
  SPOTLIGHTED = "SPOTLIGHTED",
}

export interface ImageFile {
  file: File | undefined;
  url: string;
  key: string;
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
}
export interface Product {
  id?: number;

  createdAt?: string;
  updatedAt?: string;

  storeName?: string;
  sellerName?: string;
  collectionName?: string;
  parentId?: string;

  title?: string;

  categoryId?: number;
  category2?: Category;
  category3?: Category;

  vendorCategory?: VendorCategory;

  image1?: string;
  originalImage1?: string;
  originalImage2?: string;
  originalImage3?: string;
  originalImage4?: string;
  originalImage5?: string;
  originalImage6?: string;
  originalImage7?: string;
  image2?: string;
  image3?: string;
  image4?: string;
  image5?: string;
  image6?: string;
  image7?: string;
  video?: string | null;
  videoThumbnailUrl?: string;
  videoFile?: File;
  images?: ImageFile[];

  description?: string;
  productCareInstructions?: string;
  status?: ProductStatusEnum;
  ean?: string;
  sku?: string;
  vendorParentId?: string;
  composition?: string;
  compositionLining?: string;
  minimalAge?: number;
  weight?: string;
  depth?: string;
  length?: string;
  height?: string;
  volume?: string;
  diameter?: string;

  gender?: GenderEnum;
  brand?: string;
  sellingType?: SellingTypeEnum[];
  stateType?: string;
  size?: string;
  color?: string;
  mainMaterial?: string;
  liningMaterial?: string;
  logisticClass?: string;
  ecoResponsibleLabel?: string;
  madeIn?: string;

  quantity?: number;
  sendAlertOnQuantity?: number;

  ecoTax?: number;
  vatRate?: number;
  priceTTC?: number;
  discountedPriceTTC?: number;
  startingDiscountDate?: string;
  endingDiscountDate?: string;

  rentingPeriod?: RentingPeriodEnum[];

  dailyRentPrice?: number;
  weeklyRentPrice?: number;
  monthlyRentPrice?: number;
  depositPrice?: number;
  rentSecondHandPrice?: number;

  registredSku?: string;

  image1FullPath?: string;

  points?: number;
  minimumEngagement?: number;
  secondHandPrice?: number;
  discountedSecondHandPrice?: number;
  priority?: number;
  subscriptionPackageConditionsId?: number;

  companyId?: number;
  //this can only be fetched from collection products resolve field
  priorityInCollection?: number;
}

export type VariantsChecklist = {
  [key in keyof Product]: boolean;
};
