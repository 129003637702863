import { Avatar } from "@mui/material";
import clip from "text-clipper";

import { getProfileName } from "~/pages/MesCommandes/DetailsDuneCommande/util/getProfileName";
import { Conversation } from "~/types/data/Conversation.types";
import { MessageTypeEnum } from "~/types/data/Message.types";
import { formatDate, formatHour } from "~/util/functions/formatDate";

import styles from "./index.module.scss";

interface Props {
  conversation: Conversation;
  onClick: () => void;
}

export const ConversationCard = ({ conversation, onClick }: Props) => {
  const sortedMessages = [...conversation.messages].sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );
  const lastMessage = sortedMessages.length
    ? sortedMessages[sortedMessages.length - 1]
    : undefined;
  const formattedDate = formatDate(lastMessage?.createdAt);
  const formattedHour = formatHour(lastMessage?.createdAt);

  const buyerName = getProfileName(
    conversation.order?.buyer?.firstName,
    conversation.order?.buyer?.lastName
  );

  const lastMessagePreview =
    lastMessage?.type === MessageTypeEnum.TEXT
      ? clip(lastMessage.text ?? "", 30)
      : lastMessage?.type === MessageTypeEnum.PICTURE
      ? "Image"
      : lastMessage?.type === MessageTypeEnum.FILE
      ? "File"
      : "Incindent";

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.avatarContainer}>
        <Avatar src={conversation.order?.buyer?.profilePicUrl} />
      </div>
      <div className={styles.messageInfoContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.orderIdContainer}>
            Commande N°{conversation.order?.id ?? "N/A"}
          </div>
          <div
            className={styles.dateContainer}
          >{`${formattedDate} - ${formattedHour}`}</div>
        </div>
        <div className={styles.nameContainer}>{buyerName}</div>
        <div className={styles.messagePreviewContainer}>
          <p>{lastMessagePreview}</p>
          {!!conversation.unreadCount && (
            <div className={styles.unreadContainer}>
              {conversation.unreadCount}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
