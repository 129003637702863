import {
  CustomMessage,
  CustomMessageTypeEnum,
} from "~/types/data/Message.types";
import { formatDate, formatHour } from "~/util/functions/formatDate";

import styles from "./index.module.scss";

interface Props {
  customMessage: CustomMessage;
  date: string;
}

export const CustomMessageBody = ({ customMessage, date }: Props) => {
  const { orderToProducts, reason, type, text } = customMessage;

  const formattedDate = formatDate(date);
  const formattedHour = formatHour(date);
  return (
    <div className={styles.container}>
      {type === CustomMessageTypeEnum.INCIDENT_OPEN ? (
        <>
          <div className={styles.detailsContainer}>
            <p
              className={styles.title}
            >{`Incident déclaré le ${formattedDate} à ${formattedHour}`}</p>
            <p>{reason}</p>
          </div>
          <div className={styles.productsContainer}>
            <p className={styles.title}>Produit(s) concerné(s) :</p>
            <ul>
              {orderToProducts.map(({ product }) => (
                <li key={product.id}>{`${product.title} - ${product.sku}`}</li>
              ))}
            </ul>
          </div>
        </>
      ) : (
        <>
          <p>✔ {text}</p>
        </>
      )}
    </div>
  );
};
