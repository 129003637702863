import { useQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { read, utils, WorkBook, write } from "xlsx";

import { GET_ATTRIBUTES, GetAttributesResponse } from "~/api/graphql/attribute";
import {
  createImportedFile,
  CreateImportedFileInput,
  CreateImportedFileResponse,
} from "~/api/graphql/importedFile";
import { getMediaUploadPath } from "~/api/graphql/media/getMediaUploadPath";
import {
  getSellerMappings,
  GetSellerMappingsResponse,
  updateSellerMappings,
  UpdateSellerMappingsInput,
  UpdateSellerMappingsResponse,
} from "~/api/graphql/sellerMapping";
import {
  GET_ALL_VENDOR_CATEGORIES,
  GetAllVendorCategoriesResponse,
} from "~/api/graphql/vendorCategory";
import CustomModal from "~/components/UI/CustomModal";
import FullPageLoader from "~/components/UI/FullPageLoader";
import { LoadingPopup } from "~/components/UI/LoadingPopup";
import { importRequiredAttributes } from "~/constants/keysof";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import { useAppDispatch, useAppSelector } from "~/redux/hooks";
import {
  initializeImportMappedCategories,
  selectMappedCategoriesState,
} from "~/redux/slice/importMappedCategories.slice";
import {
  initializeMappedAttributes,
  selectMappedAttributesState,
} from "~/redux/slice/mappedAttributes.slice";
import {
  initializeMappedAttributeValues,
  selectMappedAttributeValuesState,
} from "~/redux/slice/mappedAttributesValues.slice";
import { ExcelSheetDataObject } from "~/types/common/generic.types";
import { AttributeTypeEnum } from "~/types/data/Attribute.types";
import {
  AttributeValuesSheetValueType,
  FileData,
} from "~/types/data/FileImport.types";
import { Product } from "~/types/data/Product.types";
import {
  SellerMapping,
  SellerMappingTypeEnum,
} from "~/types/data/SellerMapping.types";
import { getDeliveryPackageFromCarriers } from "~/util/functions/getDeliveryPackageFromCarriers";
import { getKeys } from "~/util/functions/getKeys";
import { removeDuplicate } from "~/util/functions/removeDuplicate";

import { AttributeValuesMappingPage } from "./AttributeValuesMappingPage";
import { MemoizedCategoriesMappingPage } from "./CategoriesMappingPage";
import { FieldsMappingPage } from "./FieldsMappingPage";
import { FinalPageModal } from "./FinalPageModal";
import { ImportPage } from "./ImportPage";
import styles from "./index.module.scss";
import { SectionIndexEnum, SectionsNavigation } from "./SectionsNavigation";
import { jsonToWorkbook } from "./utils/jsonToWorkbook";
import { ValidationPage } from "./ValidationPage";

export enum ImportModeEnum {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
}

export interface ValidationState {
  invalidReasons: { reason: string }[];
  incompleteReasons: { reason: string }[];
  line: number;
}

export const ImporterParFichier = () => {
  // states
  const [importMode, setImportMode] = useState<ImportModeEnum>();
  const [isProcessing, setIsProcessing] = useState(false);
  const [activeSection, setActiveSection] = useState<number>(0);
  const [file, setFile] = useState<File>();
  const [fileData, setFileData] = useState<FileData>([]);
  const [attributesSheetValues, setAttributesSheetValues] = useState<string[]>(
    []
  );
  const [categorySheetValues, setCategorySheetValues] = useState<string[]>([]);
  const [attributeValuesSheetValues, setAttributeValuesSheetValues] =
    useState<AttributeValuesSheetValueType>({});
  const [validationValues, setValidationValues] = useState<ValidationState[]>(
    []
  );

  const [lastStateChanged, setLastStateChanged] = useState<number>(-1);
  const [showFinalPageModal, setShowFinalPageModal] = useState(false);

  const [invalidProductsWorkbook, setInvalidProductsWorkbook] =
    useState<WorkBook>();
  const [incompleteProductsWorkbook, setIncompleteProductsWorkbook] =
    useState<WorkBook>();
  const [mappedProductsWorkbook, setMappedProductsWorkbook] =
    useState<WorkBook>();

  // hooks
  const { companyAccount } = useCompanyAccountContext();
  const { data: vendorCategoriesData, error: vendorCategoriesError } =
    useQuery<GetAllVendorCategoriesResponse>(GET_ALL_VENDOR_CATEGORIES);
  const { data: attributesData, error: attributesError } =
    useQuery<GetAttributesResponse>(GET_ATTRIBUTES);
  const attributesDataFiltered =
    attributesData?.getAttributes.filter(
      (attribute) => attribute.forProducts
    ) ?? [];
  const {
    data: sellerMappings,
    error: sellerMappingsError,
    refetch: refetchSellerMappings,
  } = useQuery<GetSellerMappingsResponse>(getSellerMappings);

  const dispatch = useAppDispatch();

  const mappedAttributes = useAppSelector(selectMappedAttributesState);
  const mappedCategories = useAppSelector(selectMappedCategoriesState);
  const mappedAttributeValues = useAppSelector(
    selectMappedAttributeValuesState
  );

  const { trigger: updateSellerMappingTrigger } = useMutationWithCallbacks<
    UpdateSellerMappingsResponse,
    UpdateSellerMappingsInput
  >(
    updateSellerMappings,
    () => {},
    () => {}
  );

  const stateHasChangedHandler = useCallback((state: number) => {
    setLastStateChanged(state);
  }, []);

  const hideFinalPageModal = () => {
    setShowFinalPageModal(false);
  };

  const sectionChangeHandlerAsync = async (section: number) => {
    const newSellerMappins: SellerMapping[] = [];

    if (
      activeSection === SectionIndexEnum.attributesMapping &&
      lastStateChanged === SectionIndexEnum.attributesMapping
    ) {
      // get attributes mapping
      mappedAttributes.forEach(({ originalName, mappedAttribute }) => {
        newSellerMappins.push({
          name: "attribute",
          type: SellerMappingTypeEnum.ATTRIBUTE,
          mappedValue: mappedAttribute?.id.toString() || "",
          originalValue: originalName,
        });
      });

      const updatedSellerMappings = sellerMappings
        ? [...newSellerMappins, ...sellerMappings.getSellerMappings]
        : [...newSellerMappins];

      const filteredSellerMappings = removeDuplicate(
        ({ type, name, originalValue }) => `${type}-${name}$:${originalValue}`,
        updatedSellerMappings
      );

      // send seller mappings mutation
      await updateSellerMappingTrigger({
        variables: {
          UpdateSellerMappingsInput: {
            sellerMappings: filteredSellerMappings,
          },
        },
      });

      await refetchSellerMappings();
    }

    if (
      activeSection === SectionIndexEnum.categoriesMapping &&
      lastStateChanged === SectionIndexEnum.categoriesMapping
    ) {
      // get category mappings
      categorySheetValues.forEach((sheetValue) => {
        const mappedValue = mappedCategories[sheetValue]?.toString();
        if (mappedValue) {
          newSellerMappins.push({
            name: "categories",
            type: SellerMappingTypeEnum.CATEGORY,
            mappedValue: mappedValue,
            originalValue: sheetValue,
          });
        }
      });

      const updatedSellerMappings = sellerMappings
        ? [...newSellerMappins, ...sellerMappings.getSellerMappings]
        : [...newSellerMappins];

      const filteredSellerMappings = removeDuplicate(
        ({ type, name, originalValue }) => `${type}-${name}$:${originalValue}`,
        updatedSellerMappings
      );

      // send seller mappings mutation
      await updateSellerMappingTrigger({
        variables: {
          UpdateSellerMappingsInput: {
            sellerMappings: filteredSellerMappings,
          },
        },
      });

      await refetchSellerMappings();
    }

    if (
      activeSection === SectionIndexEnum.attributeValuesMapping &&
      lastStateChanged === SectionIndexEnum.attributeValuesMapping
    ) {
      // get attributeValues mappings
      const attributeKeys = getKeys(mappedAttributeValues);

      attributeKeys.forEach((attributeKey) => {
        const mappedValuesList = mappedAttributeValues[attributeKey];
        const mappedValuesListKeys = getKeys(mappedValuesList) as string[];

        if (mappedValuesList) {
          mappedValuesListKeys.forEach((key) => {
            const val = mappedValuesList[key];
            newSellerMappins.push({
              name: attributeKey,
              type: SellerMappingTypeEnum.ATTRIBUTE_VALUE,
              mappedValue: val?.id.toString() || "",
              originalValue: key,
            });
          });
        }
      });

      const updatedSellerMappings = sellerMappings
        ? [...newSellerMappins, ...sellerMappings.getSellerMappings]
        : [...newSellerMappins];

      const filteredSellerMappings = removeDuplicate(
        ({ type, name, originalValue }) => `${type}-${name}$:${originalValue}`,
        updatedSellerMappings
      );

      // send seller mappings mutation
      await updateSellerMappingTrigger({
        variables: {
          UpdateSellerMappingsInput: {
            sellerMappings: filteredSellerMappings,
          },
        },
      });

      await refetchSellerMappings();
    }

    setActiveSection(section);
    setIsProcessing(false);
  };

  const sectionChangeHandler = (section: number) => {
    setIsProcessing(true);

    setTimeout(() => {
      sectionChangeHandlerAsync(section);
    }, 500);
  };

  const fileChangeHandler = (file: File | undefined) => {
    setLastStateChanged(SectionIndexEnum.import);
    setFile(file);
    if (!file) {
      setFileData([]);
      return;
    }
  };

  const importModeChangeHandler = (mode?: ImportModeEnum) => {
    setLastStateChanged(SectionIndexEnum.import);
    setImportMode(mode);
  };

  const fileMappingHandler = () => {
    setIsProcessing(true);
    if (lastStateChanged > SectionIndexEnum.import) {
      sectionChangeHandler(SectionIndexEnum.attributesMapping);
      return;
    }
    setLastStateChanged(SectionIndexEnum.attributesMapping);
    const reader = new FileReader();
    reader.onload = function (e) {
      if (e.target) {
        const data = e.target.result;
        const readData = read(data, { type: "binary" });
        const ws = readData.Sheets[readData.SheetNames[0]];

        /* Convert array to json*/
        const dataParse = utils.sheet_to_json(ws, {
          header: 0,
          defval: "",
          raw: false,
        }) as FileData;

        const attributesSheetValues = dataParse.length
          ? Object.keys(dataParse[0])
          : [];
        setAttributesSheetValues(attributesSheetValues);
        if (attributesData && attributesSheetValues.length > 0) {
          dispatch(
            initializeMappedAttributes({
              attributes: attributesData.getAttributes,
              attributesSheetValues,
              sellerMappings: sellerMappings?.getSellerMappings || [],
            })
          );
          setFileData(dataParse);
        }
      }
      sectionChangeHandler(SectionIndexEnum.attributesMapping);
    };
    reader.readAsBinaryString(file as Blob);
  };

  const getCategorySheetValues = (input: FileData) => {
    const categoryColumnIndex = mappedAttributes.findIndex(
      ({ mappedAttribute }) => {
        return mappedAttribute?.name === "categoryId";
      }
    );

    const values = input.map((fileRow) => {
      const categorySheetValue =
        categoryColumnIndex > -1
          ? fileRow[attributesSheetValues[categoryColumnIndex]]
              .toString()
              .toLowerCase()
          : undefined;

      return categorySheetValue;
    });

    const filteredCategorySheetValues: string[] = [];
    values.forEach((value) => {
      if (
        filteredCategorySheetValues.filter((sheetValue) => sheetValue === value)
          .length === 0 &&
        value
      ) {
        filteredCategorySheetValues.push(value);
      }
    });
    return filteredCategorySheetValues;
  };

  const categoryMappingHandler = () => {
    setIsProcessing(true);
    if (lastStateChanged > SectionIndexEnum.attributesMapping) {
      sectionChangeHandler(SectionIndexEnum.categoriesMapping);

      return;
    }

    setLastStateChanged(SectionIndexEnum.categoriesMapping);
    setTimeout(() => {
      const sheetValues = [...getCategorySheetValues(fileData)];
      setCategorySheetValues(sheetValues);
      if (
        sheetValues.length > 0 &&
        vendorCategoriesData?.getAllVendorCategories
      )
        dispatch(
          initializeImportMappedCategories({
            vendorCategorySheetValues: sheetValues,
            vendorCategories: vendorCategoriesData?.getAllVendorCategories,
            sellerMappings: sellerMappings?.getSellerMappings || [],
          })
        );
      sectionChangeHandler(SectionIndexEnum.categoriesMapping);
    }, 500);
  };

  const getAttributeValuesSheetValues = (input: FileData) => {
    const guessableMappedAttributes = mappedAttributes.filter(
      ({ mappedAttribute }) => mappedAttribute?.isGuessable
    );
    const sheetValues: AttributeValuesSheetValueType = {};
    guessableMappedAttributes.forEach((attr) => {
      const columnIndex = mappedAttributes.findIndex(({ mappedAttribute }) => {
        return mappedAttribute?.name === attr.mappedAttribute?.name;
      });
      const values = input.map((fileRow) => {
        return columnIndex > -1
          ? fileRow[attributesSheetValues[columnIndex]]
          : undefined;
      });

      const filteredValues = [
        ...new Set(
          values
            .filter((value) => value)
            .map((value) => value?.toString().toLowerCase())
        ),
      ];
      const key = attr.mappedAttribute?.name;

      if (key)
        sheetValues[key] = filteredValues.map((value) => {
          return value || "";
        });
    });

    return sheetValues;
  };

  const attributeValuesMappingHandler = () => {
    setIsProcessing(true);
    if (lastStateChanged > 2) {
      sectionChangeHandler(SectionIndexEnum.attributeValuesMapping);
      return;
    }

    setLastStateChanged(SectionIndexEnum.attributeValuesMapping);

    setTimeout(() => {
      const sheetValues = {
        ...getAttributeValuesSheetValues(fileData),
      };
      setAttributeValuesSheetValues(sheetValues);
      if (sheetValues && attributesData) {
        dispatch(
          initializeMappedAttributeValues({
            attributeValuesSheetValues: sheetValues,
            sellerMappings: sellerMappings?.getSellerMappings || [],
            attributes: attributesData.getAttributes,
          })
        );
      }
      sectionChangeHandler(SectionIndexEnum.attributeValuesMapping);
    }, 500);
  };

  const validationHandler = () => {
    setIsProcessing(true);
    if (lastStateChanged > SectionIndexEnum.attributeValuesMapping) {
      sectionChangeHandler(SectionIndexEnum.validation);
      return;
    }

    setLastStateChanged(SectionIndexEnum.validation);
    setTimeout(() => {
      const isUpdateMode = importMode === ImportModeEnum.UPDATE;
      // filter the selected attributes
      const requiredAttributes = isUpdateMode
        ? new Set(["sku"])
        : new Set([...importRequiredAttributes]);
      const selectedRequiredAttributeKeys = new Set(
        mappedAttributes
          .filter(({ mappedAttribute }) => {
            if (mappedAttribute)
              return requiredAttributes.has(mappedAttribute.name);
          })
          .map(({ originalName }) => originalName)
      );

      const products: (Product & { line?: number })[] = [];
      const productsValidation: ValidationState[] = [];
      const categoryColumnIndex = mappedAttributes.findIndex(
        ({ mappedAttribute }) => {
          return mappedAttribute?.name === "categoryId";
        }
      );

      // loop over the file data to create products
      for (let i = 0; i < fileData.length; i++) {
        const fileRow = fileData[i];
        let mappedProduct: Product & { line: number } = { line: i + 2 };
        const productValidation: ValidationState = {
          incompleteReasons: [],
          invalidReasons: [],
          line: i,
        };
        // step 1: check if there are no mapped categories
        const originalCategorySheetValue =
          fileRow[attributesSheetValues[categoryColumnIndex]] || "";

        if (originalCategorySheetValue) {
          const categorySheetValue = originalCategorySheetValue
            .toString()
            .toLowerCase();
          // map categories
          const categoryId = mappedCategories[categorySheetValue];

          if (!categoryId) {
            productValidation.invalidReasons.push({
              reason: "No mapping: Category",
            });
          } else {
            mappedProduct = {
              ...mappedProduct,
              categoryId,
            };
          }
        } else if (!isUpdateMode) {
          productValidation.invalidReasons.push({
            reason: `Empty cell: ${attributesSheetValues[categoryColumnIndex]} mapped to Category`,
          });
        }

        // step 3: check if there are no mapped or empty required attributes
        for (const { originalName, mappedAttribute } of mappedAttributes) {
          if (mappedAttribute) {
            if (mappedAttribute.name === "logisticClass") {
              // check if the if value is in carriers
              const logisticClassCode = fileRow[originalName].toString();

              if (logisticClassCode) {
                const classLogistics: string[] = getDeliveryPackageFromCarriers(
                  [...companyAccount.carriers, ...companyAccount.reedooCarriers]
                );

                const isValid = classLogistics.includes(logisticClassCode);

                if (!isValid) {
                  const classLogisticCodes = classLogistics.join(", ");
                  productValidation.invalidReasons.push({
                    reason: `Invalid class logistic code: ${logisticClassCode} should be from [${classLogisticCodes}]`,
                  });
                } else {
                  mappedProduct = {
                    ...mappedProduct,
                    ["logisticClass"]: logisticClassCode,
                  };
                }
              } else {
                productValidation.invalidReasons.push({
                  reason: `Empty cell: ${originalName} mapped to required ${mappedAttribute.name}`,
                });
              }
            } else if (mappedAttribute.isGuessable) {
              // check if it's guessable and required
              const mappedValues = mappedAttributeValues[mappedAttribute.name];

              if (mappedValues) {
                const attributeValueOriginalName = fileRow[originalName]
                  .toString()
                  .toLowerCase();

                if (attributeValueOriginalName) {
                  const attributeValue =
                    mappedValues[attributeValueOriginalName];

                  if (
                    !attributeValue &&
                    selectedRequiredAttributeKeys.has(originalName)
                  ) {
                    productValidation.invalidReasons.push({
                      reason: `No mapping for ${attributeValueOriginalName} in required attribute ${mappedAttribute.name}`,
                    });
                  } else {
                    mappedProduct = {
                      ...mappedProduct,
                      [mappedAttribute.name]: attributeValue?.value,
                    };
                  }
                } else if (selectedRequiredAttributeKeys.has(originalName)) {
                  productValidation.invalidReasons.push({
                    reason: `Empty cell: ${originalName} mapped to required ${mappedAttribute.name}`,
                  });
                }
              }
            } else if (
              mappedAttribute.name !== "categoryId" &&
              !mappedAttribute.isGuessable
            ) {
              if (
                // check if it's required but empty
                selectedRequiredAttributeKeys.has(originalName) &&
                !fileRow[originalName]
              ) {
                productValidation.invalidReasons.push({
                  reason: `Empty cell: ${originalName} mapped to required ${mappedAttribute.name}`,
                });
              } else if (
                // check if it's not empty but is number
                fileRow[originalName] &&
                mappedAttribute.type === AttributeTypeEnum.NUMBER
              ) {
                const castNumber = +fileRow[originalName];
                if (isNaN(castNumber)) {
                  productValidation.invalidReasons.push({
                    reason: `mapped value type: ${originalName} mapped to ${mappedAttribute.name} value must be a number`,
                  });
                } else {
                  mappedProduct = {
                    ...mappedProduct,
                    [mappedAttribute.name]: castNumber,
                  };
                }
              } else if (
                // check if it's not empty but is a date
                fileRow[originalName] &&
                mappedAttribute.type === AttributeTypeEnum.DATE
              ) {
                const timestamp = Date.parse(fileRow[originalName].toString());

                if (isNaN(timestamp)) {
                  productValidation.invalidReasons.push({
                    reason: `Date input ${originalName} with value ${fileRow[originalName]} is not a valid date input`,
                  });
                } else {
                  const mappedDate = new Date(timestamp);
                  mappedProduct = {
                    ...mappedProduct,
                    [mappedAttribute.name]: mappedDate.toISOString(),
                  };
                }
              } else {
                // no problems and no mapping or casting needed
                mappedProduct = {
                  ...mappedProduct,
                  [mappedAttribute.name]: fileRow[originalName]
                    ? `${fileRow[originalName]}`
                    : null,
                };
              }
            }
          }
        }

        // check associated attributes
        const categorySheetValue = originalCategorySheetValue
          .toString()
          .toLowerCase();
        const categoryId = mappedCategories[categorySheetValue];
        if (categoryId && !isUpdateMode) {
          const mappedVendorCategory =
            vendorCategoriesData?.getAllVendorCategories.find(
              ({ id }) => id === categoryId
            );

          if (mappedVendorCategory) {
            const { attributes } = mappedVendorCategory;
            const filteredAttributes = attributes.filter(
              ({ forProducts }) => forProducts
            );

            for (const { name } of filteredAttributes) {
              if (!mappedProduct[name]) {
                productValidation.incompleteReasons.push({
                  reason: `Attribute ${name} must have a value for category ${mappedVendorCategory.name}`,
                });
              }
            }
          }
        }

        // check if there's no image
        if (
          mappedProduct.originalImage1 === null ||
          (!mappedProduct.originalImage1 && !isUpdateMode)
        ) {
          productValidation.incompleteReasons.push({
            reason: "Missing image",
          });
        }

        // check the price
        if (
          (isUpdateMode &&
            mappedProduct.priceTTC &&
            mappedProduct.priceTTC <= 0) ||
          (!isUpdateMode &&
            (!mappedProduct.priceTTC || mappedProduct.priceTTC <= 0))
        ) {
          productValidation.incompleteReasons.push({
            reason: "Price cannot be less than 0",
          });
        }

        // check and add default values if needed
        mappedProduct = {
          ...mappedProduct,
          ...((!isUpdateMode || mappedProduct.stateType) && {
            stateType: mappedProduct.stateType || "Neuf avec étiquette",
          }),
          ...((!isUpdateMode || mappedProduct.vatRate) && {
            vatRate: mappedProduct.vatRate || 20,
          }),
          ...((!isUpdateMode || mappedProduct.quantity) && {
            quantity: mappedProduct.quantity || 0,
          }),
        };

        productsValidation.push(productValidation);

        // add product only if it has no validation errors
        if (productValidation.invalidReasons.length === 0) {
          products.push(mappedProduct);
        }
      }

      // create and set invalid products workbook
      const invalidProducts = productsValidation
        .filter(({ invalidReasons }) => {
          return invalidReasons.length;
        })
        .map(({ invalidReasons, line }) => {
          const rowData = fileData[line];
          const invalidReasonsObject = invalidReasons.reduce(
            (reasonsObject, reason, index) => {
              return {
                ...reasonsObject,
                [`Reason #${index + 1}`]: reason.reason,
              };
            },
            {}
          );

          return {
            line: line + 2,
            ...rowData,
            ...invalidReasonsObject,
          };
        });

      // create and set incomplete products workbook
      const incompleteProducts = productsValidation
        .filter(({ incompleteReasons, invalidReasons }) => {
          return incompleteReasons.length && !invalidReasons.length;
        })
        .map(({ incompleteReasons, line }) => {
          const rowData = fileData[line];
          const incompleteReasonsObject = incompleteReasons.reduce(
            (reasonsObject, reason, index) => {
              return {
                ...reasonsObject,
                [`Reason #${index + 1}`]: reason.reason,
              };
            },
            {}
          );

          return {
            line: line + 2,
            ...rowData,
            ...incompleteReasonsObject,
          };
        });

      const invalidProductsWB = jsonToWorkbook(
        invalidProducts,
        "invalid_products_data"
      );

      const incompleteProductsWB = jsonToWorkbook(
        incompleteProducts,
        "incomplete_products_data"
      );

      const mappedProductsWB = jsonToWorkbook(
        products as ExcelSheetDataObject[],
        "mapped_products_data"
      );

      setInvalidProductsWorkbook(invalidProductsWB);
      setIncompleteProductsWorkbook(incompleteProductsWB);
      setMappedProductsWorkbook(mappedProductsWB);

      setValidationValues(productsValidation);
      sectionChangeHandler(SectionIndexEnum.validation);
    }, 1000);
  };

  const { trigger: CreateImportedFileTrigger } = useMutationWithCallbacks<
    CreateImportedFileResponse,
    CreateImportedFileInput
  >(
    createImportedFile,
    () => {},
    () => {}
  );

  const submitHandlerAsync = async () => {
    // get invalid data file
    if (
      invalidProductsWorkbook &&
      incompleteProductsWorkbook &&
      mappedProductsWorkbook &&
      file
    ) {
      const invalidProductsBytes = write(invalidProductsWorkbook, {
        type: "array",
        bookType: "xlsx",
      }) as BlobPart;

      const invalidProductsBlob = new Blob([invalidProductsBytes], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
      });

      const invalidProductsFile = new File(
        [invalidProductsBlob],
        "invalid_products_data.xlsx"
      );

      // get incomplete data file
      const incompleteProductsBytes = write(incompleteProductsWorkbook, {
        type: "array",
        bookType: "xlsx",
      }) as BlobPart;

      const incompleteProductsBlob = new Blob([incompleteProductsBytes], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
      });

      const incompleteProductsFile = new File(
        [incompleteProductsBlob],
        "incomplete_products_data.xlsx"
      );

      // get mapped data file
      const mappedProductsBytes = write(mappedProductsWorkbook, {
        type: "array",
        bookType: "xlsx",
      }) as BlobPart;

      const mappedProductsBlob = new Blob([mappedProductsBytes], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
      });

      const mappedProductsFile = new File(
        [mappedProductsBlob],
        "mapped_products_data.xlsx"
      );

      // upload to s3 and get the path
      let filePath = "";
      const originalFileResponse = await getMediaUploadPath({
        entity: "files",
        field: "imports",
        filename: file.name,
      });

      if (originalFileResponse.success) {
        const { url, path } = originalFileResponse.data;
        await fetch(url, { method: "put", body: file });

        filePath = path;
      }

      let errorFilePath = "";
      const invalidProductsResponse = await getMediaUploadPath({
        entity: "files",
        field: "imports",
        filename: invalidProductsFile.name,
      });

      if (invalidProductsResponse.success) {
        const { url, path } = invalidProductsResponse.data;
        await fetch(url, { method: "put", body: invalidProductsFile });

        errorFilePath = path;
      }

      let incompleteFilePath = "";
      const incompleteProductsResponse = await getMediaUploadPath({
        entity: "files",
        field: "imports",
        filename: incompleteProductsFile.name,
      });

      if (incompleteProductsResponse.success) {
        const { url, path } = incompleteProductsResponse.data;
        await fetch(url, { method: "put", body: incompleteProductsFile });

        incompleteFilePath = path;
      }

      let successFilePath = "";
      const mappedProductsResponse = await getMediaUploadPath({
        entity: "files",
        field: "imports",
        filename: mappedProductsFile.name,
      });

      if (mappedProductsResponse.success) {
        const { url, path } = mappedProductsResponse.data;
        await fetch(url, { method: "put", body: mappedProductsFile });

        successFilePath = path;
      }

      // get other data
      const totalProducts = validationValues.length;
      const incompleteProducts = validationValues.filter(
        ({ incompleteReasons, invalidReasons }) => {
          return incompleteReasons.length && !invalidReasons.length;
        }
      ).length;
      const successfullProducts = validationValues.filter(
        ({ invalidReasons }) => !invalidReasons.length
      ).length;

      // send request
      await CreateImportedFileTrigger({
        variables: {
          CreateImportedFileInput: {
            filePath,
            fileName: file ? file.name : "",
            errorFilePath,
            incompleteFilePath,
            successFilePath,
            successfullProducts,
            totalProducts,
            incompleteProducts,
            isUpdateMode: importMode === ImportModeEnum.UPDATE,
          },
        },
      });
      setShowFinalPageModal(true);
      setIsProcessing(false);
    }
  };

  const submitHandler = () => {
    setIsProcessing(true);
    // if (lastStateChanged > 4) {
    //   sectionChangeHandler("finalPage");
    //   return;
    // }
    setLastStateChanged(5);
    setTimeout(() => {
      submitHandlerAsync();
    }, 500);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeSection]);

  return (
    <>
      {attributesError || vendorCategoriesError || sellerMappingsError ? (
        <div className={`${styles.container}`}>
          {attributesError && (
            <div>{`Attributes Error: ${attributesError.message}`}</div>
          )}
          {vendorCategoriesError && (
            <div>{`Categories Error: ${vendorCategoriesError.message}`}</div>
          )}
          {sellerMappingsError && (
            <div>{`Mappings Error: ${sellerMappingsError.message}`}</div>
          )}
        </div>
      ) : (
        <div className={`${styles.container}`}>
          {!sellerMappings || !vendorCategoriesData || !attributesData ? (
            <FullPageLoader />
          ) : (
            <>
              <CustomModal
                onCancel={() => {}}
                disableCancel={true}
                show={showFinalPageModal}
                removeCloseButton={true}
              >
                <FinalPageModal hideFinalPageModal={hideFinalPageModal} />
              </CustomModal>
              <LoadingPopup show={isProcessing} />
              <div className={`${styles.headerContainer}`}>
                <h2>Product Flow Mapping</h2>
              </div>

              <div className={`${styles.pageContainer}`}>
                <div className={`${styles.navigationContainer}`}>
                  <SectionsNavigation
                    onChangeSection={sectionChangeHandler}
                    activeSection={activeSection}
                    lastStateChanged={lastStateChanged}
                  />
                </div>
                <div className={`${styles.sectionContainer}`}>
                  {activeSection === SectionIndexEnum.import ? (
                    <ImportPage
                      onFileChange={fileChangeHandler}
                      file={file}
                      onNext={fileMappingHandler}
                      isProcessing={isProcessing}
                      importMode={importMode}
                      importModeChangeHandler={importModeChangeHandler}
                    />
                  ) : activeSection === SectionIndexEnum.attributesMapping ? (
                    <FieldsMappingPage
                      fileData={fileData}
                      isProcessing={isProcessing}
                      onNext={categoryMappingHandler}
                      onPrevious={sectionChangeHandler}
                      onStateHasChanged={stateHasChangedHandler}
                      attributes={attributesDataFiltered}
                      importMode={importMode}
                    />
                  ) : activeSection === SectionIndexEnum.categoriesMapping ? (
                    <MemoizedCategoriesMappingPage
                      categorySheetValues={categorySheetValues}
                      isProcessing={isProcessing}
                      onNext={attributeValuesMappingHandler}
                      onPrevious={sectionChangeHandler}
                      vendorCategories={
                        vendorCategoriesData?.getAllVendorCategories || []
                      }
                      onStateHasChanged={stateHasChangedHandler}
                    />
                  ) : activeSection ===
                    SectionIndexEnum.attributeValuesMapping ? (
                    <AttributeValuesMappingPage
                      attributeValuesSheetValues={attributeValuesSheetValues}
                      isProcessing={isProcessing}
                      onNext={validationHandler}
                      onPrevious={sectionChangeHandler}
                      attributes={attributesDataFiltered}
                      onStateHasChanged={stateHasChangedHandler}
                    />
                  ) : activeSection === SectionIndexEnum.validation ? (
                    <ValidationPage
                      validationValues={validationValues}
                      invalidProductsWorkbook={invalidProductsWorkbook}
                      incompleteProductsWorkbook={incompleteProductsWorkbook}
                      isProcessing={isProcessing}
                      onNext={submitHandler}
                      onPrevious={sectionChangeHandler}
                    />
                  ) : (
                    <div>Final page supposedly</div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
