export enum MyTransactionStatusEnum {
  PENDING = "PENDING",
  PAYABLE = "PAYABLE",
  PAID = "PAID",
  CANCELLED = "CANCELLED",
}

export enum PayoutStatusEnum {
  CREATED = "CREATED",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
}

export interface TransactionProduct {
  id: number;
  title: string;
  sku: string;
  quantity: number;
}

export interface MyTransaction {
  id: number;
  createdAt: string;
  products: TransactionProduct[];
  originalAmount: number;
  totalAmount: number;
  productsVat: number;
  shippingAmount: number;
  refundAmount: number;
  commission: number;
  commissionVat: number;
  status: MyTransactionStatusEnum;
}

export interface MyPayOut {
  id: number;
  paidOutAt: string;
  amount: number;
  reedooCommissionAmount: number;
  payoutStatus: PayoutStatusEnum;
  invoiceUrl?: string;
}
