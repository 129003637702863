import { useEffect } from "react";

interface Props<T = undefined> {
  ms?: number;
  callback: (input: T) => void;
  active: boolean;
}

export const TimeIntervalWrapper = <T extends Record<string, unknown>>({
  ms = 30000,
  callback,
  children,
  active = true,
}: React.PropsWithChildren<Props<T>>) => {
  useEffect(() => {
    if (!active) return;
    const interval = setInterval(callback, ms);
    return () => clearInterval(interval);
  }, [active]);

  return <>{children}</>;
};
