import SVGContainer from "~/components/UI/SVGContainer";
import { CollectionModel } from "~/types/data/Collection.type";

import styles from "./index.module.scss";

interface Props {
  isSelected: boolean;
  collection: CollectionModel;
  onEdit: (collection: CollectionModel) => void;
  onDelete: (collection: CollectionModel) => void;
}

export const CollectionCard = ({
  collection,
  onEdit,
  onDelete,
  isSelected,
}: Props) => {
  const { name, description, enabled, products } = collection;

  return (
    <div
      className={`${styles.cardContainer} ${isSelected ? styles.active : ""}`}
    >
      <div className={styles.detailsParent}>
        <div className={styles.detailsContainer}>
          <p className={styles.title}>{name}</p>
          <div>
            <p>{description}</p>
          </div>
        </div>
        <div className={styles.editDeleteContainer}>
          <SVGContainer
            height="18px"
            width="18px"
            imagePath="/assets/edit-button.svg"
            onClick={(e) => {
              e.stopPropagation();
              onEdit(collection);
            }}
          />
          <SVGContainer
            height="18px"
            width="18px"
            imagePath="/assets/delete-icon.svg"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(collection);
            }}
          />
          <p className={styles.productCount}>{products.length}</p>
        </div>
      </div>
    </div>
  );
};
