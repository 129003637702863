import { HeadCell } from "~/types/common/headCell.type";

import { TableRowData } from "../..";
import { SmallTableRow } from "../SmallTableRow";
import styles from "./index.module.scss";

interface Props {
  headCells: HeadCell[];
  tableRowsData: TableRowData[];
}

export const SmallTableBody = ({ headCells, tableRowsData }: Props) => {
  return (
    <tbody className={`${styles.tbody}`}>
      {tableRowsData.map((rowData, index) => {
        return (
          <SmallTableRow
            key={index.toString()}
            headCells={headCells}
            tableRowData={rowData}
          />
        );
      })}
    </tbody>
  );
};
