import CustomCheckbox from "~/components/form/CustomCheckbox";
import { User } from "~/types/data/User.types";
import { formatDate } from "~/util/functions/formatDate";

import styles from "./index.module.scss";

interface Props {
  user: User;
  isSelected: boolean;
  onCheckboxChange: (user: User) => void;
  onClick: (variantId: number) => void;
}

export const UsersTableRow = ({
  user,
  isSelected,
  onCheckboxChange,
  onClick,
}: Props) => {
  const {
    createdAt,
    id,
    email,
    resolveFieldUsername,
    companyName,
    role,
    status,
    phoneNumber,
    profile,
  } = user;

  const formattedCreatedAt = formatDate(createdAt);

  const onClickFunction = id
    ? () => {
        onClick(id);
      }
    : () => {};

  const companyNameValue = companyName
    ? companyName
    : profile
    ? "Profile"
    : "N/A";

  return (
    <tr>
      <td className={styles.nonClickable}>
        <CustomCheckbox
          checked={isSelected}
          onChange={() => {
            onCheckboxChange(user);
          }}
          padding="0.7rem"
        />
      </td>
      <td onClick={onClickFunction}>{formattedCreatedAt}</td>
      <td onClick={onClickFunction}>{id}</td>
      <td onClick={onClickFunction}>{email}</td>
      <td onClick={onClickFunction}>{resolveFieldUsername}</td>
      <td onClick={onClickFunction}>{companyNameValue}</td>
      <td onClick={onClickFunction}>{role}</td>
      <td onClick={onClickFunction}>{status}</td>
      <td onClick={onClickFunction}>{phoneNumber}</td>
    </tr>
  );
};
