import { Product } from "~/types/data/Product.types";
import { getKeys } from "~/util/functions/getKeys";

export const getFilledFields = (productState: Product) => {
  if (!productState) return [];
  const filledFields: (keyof Product)[] = [];
  const fields = getKeys(productState);
  fields.forEach((fieldName) => {
    if (
      productState[fieldName] &&
      fieldName !== "id" &&
      fieldName !== "sku" &&
      fieldName !== "status" &&
      fieldName !== "images" &&
      fieldName !== "image2" &&
      fieldName !== "image3" &&
      fieldName !== "image4" &&
      fieldName !== "image5" &&
      fieldName !== "image6" &&
      fieldName !== "image7" &&
      fieldName !== "createdAt" &&
      fieldName !== "updatedAt" &&
      fieldName !== "videoFile" &&
      fieldName !== "videoThumbnailUrl" &&
      fieldName !== "registredSku" &&
      fieldName !== "parentId" &&
      fieldName !== "sellingType"
    ) {
      filledFields.push(fieldName);
    }
  });

  return filledFields;
};
