import { DataPlatformPagesEnum } from "..";
import styles from "./index.module.scss";
import { Section } from "./Section";

type Props = {
  onClick: (section: DataPlatformPagesEnum) => void;
};
export const DataPlatformNavigation = ({ onClick }: Props) => {
  return (
    <div className={`${styles.navigationContainer}`}>
      <Section
        name="Taxonomie"
        iconName="numbers-dashes-icon.svg"
        onClick={onClick}
        sectionType={DataPlatformPagesEnum.taxonomie}
      />
      <Section
        name="Abonnement à la plateforme"
        iconName="calendar-icon.svg"
        onClick={onClick}
        sectionType={DataPlatformPagesEnum.platformSubscription}
      />
      <Section
        name="CGU"
        iconName="note-icon.svg"
        onClick={onClick}
        sectionType={DataPlatformPagesEnum.cGU}
      />
      <Section
        name="Charte éthique"
        iconName="note-icon.svg"
        onClick={onClick}
        sectionType={DataPlatformPagesEnum.codeOfEthics}
      />
      <Section
        name="Guide utilisateur"
        iconName="note-icon.svg"
        onClick={onClick}
        sectionType={DataPlatformPagesEnum.userGuide}
      />
      <Section
        name="Classes logistique"
        iconName="truck-icon.svg"
        onClick={onClick}
        sectionType={DataPlatformPagesEnum.logisticClasses}
      />
      <Section
        name="Informations transports"
        iconName="truck-icon.svg"
        onClick={onClick}
        sectionType={DataPlatformPagesEnum.transportInformation}
      />
    </div>
  );
};
