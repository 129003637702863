import StatusLabel from "~/components/UI/StatusLabel";
import SVGContainer from "~/components/UI/SVGContainer";
import { OrderStatusEnum } from "~/types/data/Order.types";
import {
  getOrderStatusColor,
  getTextFromOrderStatus,
} from "~/util/mapping/orderStatus.mapping";

import styles from "./index.module.scss";

interface Props {
  orderId: number;
  clientName: string;
  orderStatus: OrderStatusEnum;
}

export const InformationBanner = ({
  clientName,
  orderId,
  orderStatus,
}: Props) => {
  const statusText = getTextFromOrderStatus(orderStatus);
  const statusColor = getOrderStatusColor(orderStatus);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.bannerItem}`}>
        <p className={`${styles.title}`}>Client :</p>
        <div className={`${styles.itemContent}`}>
          <p>{clientName}</p>
          <SVGContainer
            height="12px"
            width="12px"
            imagePath="/assets/message-icon.svg"
          />
        </div>
      </div>
      <div className={`${styles.bannerItem}`}>
        <p className={`${styles.title}`}>N° de commande :</p>
        <div className={`${styles.itemContent}`}>
          <p>{orderId}</p>
        </div>
      </div>
      <div className={`${styles.bannerItem}`}>
        <p className={`${styles.title}`}>Statut :</p>
        <StatusLabel
          label={statusText}
          color="white"
          backgroundColor={statusColor}
        />
      </div>
    </div>
  );
};
