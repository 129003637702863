import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { GET_ATTRIBUTES, GetAttributesResponse } from "~/api/graphql/attribute";
import {
  getProductVariantsById,
  GetProductVariantsByIdInput,
  GetProductVariantsByIdResponse,
} from "~/api/graphql/product";
import {
  GET_ALL_VENDOR_CATEGORIES,
  GetAllVendorCategoriesResponse,
} from "~/api/graphql/vendorCategory";
import CustomModal from "~/components/UI/CustomModal";
import FullPageLoader from "~/components/UI/FullPageLoader";
import SVGContainer from "~/components/UI/SVGContainer";
import { initProductFormState } from "~/constants/states";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useUserContext } from "~/context/userContext";
import { routePaths } from "~/navigation/routes";
import { DeliveryPackage } from "~/types/data/Carrier.types";
import { Product, VariantsChecklist } from "~/types/data/Product.types";
import { RoleEnum } from "~/types/data/User.types";
import { generateVideoThumbnail } from "~/util/functions/generateVideoThumbnail";

import styles from "./index.module.scss";
import { ProductForm } from "./ProductForm";
import { createNewVairant } from "./util/createNewVariant";
import { fieldsToImagesMap } from "./util/fieldsToImagesMap";
import { getFilledFields } from "./util/getFilledFields";
import { VariantsNavigation } from "./VariantsNavigation";
import { VariantsOptionsChecklist } from "./VariantsOptionsChecklist";

const AjouterUnProduit = () => {
  // states
  const [showModal, setShowModal] = useState(false);
  const [productFormState, setProductFormState] = useState<{
    variants: Product[];
    index: number;
  }>({ variants: [], index: 0 });

  // hooks
  const [searchParams] = useSearchParams();
  const { userState } = useUserContext();
  const { companyAccount } = useCompanyAccountContext();
  const navigate = useNavigate();
  const {
    data: vendorCategoriesData,
    error: vendorCategoriesError,
    loading: vendorCategoriesLoading,
  } = useQuery<GetAllVendorCategoriesResponse>(GET_ALL_VENDOR_CATEGORIES, {
    fetchPolicy: "cache-first",
  });
  const {
    data: attributesData,
    error: attributesError,
    loading: attributesLoading,
  } = useQuery<GetAttributesResponse>(GET_ATTRIBUTES, {
    fetchPolicy: "cache-first",
  });

  const productState = productFormState.variants[productFormState.index];
  const isAdmin = userState?.connected && userState.role === RoleEnum.ADMIN;

  const filledFields = getFilledFields(productState);

  const classLogistics: DeliveryPackage[] = [
    ...companyAccount.reedooCarriers,
    ...companyAccount.carriers,
  ].reduce((a, b) => a.concat(b.deliveryPackages), [] as DeliveryPackage[]);

  const backButtonClickHandler = () => {
    navigate(-1);
  };

  const navigationChangeHandler = (index: number) => {
    setProductFormState((prev) => ({ ...prev, index }));
  };

  const showModalHandler = () => {
    setShowModal(true);
  };

  const hideModalHandler = () => {
    setShowModal(false);
  };

  const createNewVariantHandler = (variantsChecklist: VariantsChecklist) => {
    const newVariant = createNewVairant(productState, variantsChecklist);

    setProductFormState((prev) => {
      return {
        index: prev.variants.length,
        variants: [...prev.variants, newVariant],
      };
    });
    hideModalHandler();
  };

  // react-query
  const getProductVariantsByIdHandler = (
    responseData: GetProductVariantsByIdResponse
  ) => {
    const { getProductVariantsById } = responseData;

    const mappedProducts = getProductVariantsById.map(async (product) => {
      const mappedImages = await fieldsToImagesMap(product);
      let videoThumbnailUrl = "";
      const videoUrl = product.video;
      if (videoUrl) {
        const fileData = await fetch(videoUrl);
        const fileBlob = await fileData.blob();
        videoThumbnailUrl = (await generateVideoThumbnail(
          fileBlob as File
        )) as string;
      }
      return {
        ...product,
        images: mappedImages,
        videoThumbnailUrl,
        registredSku: product.sku,
      };
    });

    Promise.all(mappedProducts)
      .then((products) => {
        const searchParam = searchParams.get("variantId");
        const variantId = searchParam ? +searchParam : 0;
        const productIndex = products.findIndex(({ id }) => id === variantId);
        setProductFormState(() => {
          return {
            index: productIndex > -1 ? productIndex : 0,
            variants: products,
          };
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [
    getProductVariantsByIdTrigger,
    { error: getProductVariantsByIdError },
  ] = useLazyQuery<GetProductVariantsByIdResponse, GetProductVariantsByIdInput>(
    getProductVariantsById,
    { onCompleted: getProductVariantsByIdHandler, errorPolicy: "all" }
  );

  const initFormState = async (id: number) => {
    await getProductVariantsByIdTrigger({
      variables: { GetProductVariantsByIdInput: { id } },
    });
  };

  useEffect(() => {
    const variantId = searchParams.get("variantId");

    if (isAdmin && !variantId) {
      navigate(`/${routePaths.monCatalogueRoute}`);
    }
    if (variantId) {
      initFormState(+variantId);
    } else {
      setProductFormState({ variants: [initProductFormState], index: 0 });
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [productFormState.variants.length, productFormState.index]);

  return (
    <div>
      {vendorCategoriesError ||
      attributesError ||
      getProductVariantsByIdError ? (
        <div className={`${styles.container}`}>
          {vendorCategoriesError && (
            <div>{`Categories Error: ${vendorCategoriesError.message}`}</div>
          )}
          {attributesError && (
            <div>{`Attribute Error: ${attributesError.message}`}</div>
          )}
          {getProductVariantsByIdError && (
            <div>{`Product Error: ${getProductVariantsByIdError.message}`}</div>
          )}
        </div>
      ) : vendorCategoriesLoading || attributesLoading || !productState ? (
        <FullPageLoader />
      ) : (
        <div className={`${styles.container}`}>
          <VariantsNavigation
            activeIndex={productFormState.index}
            variants={productFormState.variants.map(
              ({ registredSku }) => registredSku || ""
            )}
            onNavigationClick={navigationChangeHandler}
          />
          <div className={`${styles.header}`}>
            <div className={`${styles.backButton}`}>
              <SVGContainer
                imagePath="/assets/back-button.svg"
                height="17px"
                width="20px"
                onClick={backButtonClickHandler}
              />
            </div>
            <h2>Nouveau produit</h2>
          </div>

          <ProductForm
            product={productState}
            setProductFormState={setProductFormState}
            variantIndex={productFormState.index}
            vendorCategories={
              vendorCategoriesData?.getAllVendorCategories || []
            }
            attributes={attributesData?.getAttributes || []}
            showModalHandler={showModalHandler}
            classLogistics={classLogistics}
            isAdmin={isAdmin}
          />

          <CustomModal onCancel={hideModalHandler} show={showModal}>
            <VariantsOptionsChecklist
              createNewVariant={createNewVariantHandler}
              filledFields={filledFields}
            />
          </CustomModal>
        </div>
      )}
    </div>
  );
};

export default AjouterUnProduit;
