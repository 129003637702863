import { useState } from "react";
import { SingleValue } from "react-select";

import { CustomButton } from "~/components/form/CustomButton";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";

import { CloseIncidentModalState } from "..";
import styles from "./index.module.scss";

interface Props {
  onConfirm: (reason: string) => void;
  closeIncidentReasonOptions: OptionType[];
  state: CloseIncidentModalState;
}

enum CloseReasonEnum {
  AGREEMENT = "AGREEMENT",
  REFUNDED = "REFUNDED",
}

export const CloseIncidentForm = ({
  onConfirm,
  closeIncidentReasonOptions,
  state,
}: Props) => {
  const [closeReason, setCloseReason] = useState<string>();

  const { isLoading, errorMessage } = state;

  const onChange = (value: CloseReasonEnum) => {
    setCloseReason(value);
  };

  const confirmClickHandler = () => {
    onConfirm(closeReason ?? "");
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h2>Fermer l’incident</h2>
      </div>
      <div className={styles.detailsContainer}>
        <SelectInput
          onChange={(option: SingleValue<OptionType>) => {
            onChange(option?.value as CloseReasonEnum);
          }}
          options={closeIncidentReasonOptions}
          value={closeReason ?? ""}
          label="Raison de fermeture"
        />
      </div>
      {errorMessage && (
        <div className={`${styles.errorMessage}`}>{errorMessage}</div>
      )}
      <div className={styles.buttonsContainer}>
        <CustomButton
          width="max-content"
          padding="0.7rem 1rem"
          backgroundColor={colors.$primary}
          color="white"
          borderRadius="7px"
          onClick={confirmClickHandler}
        >
          {isLoading ? <SimpleLoader size="size2" /> : "Confirmer"}
        </CustomButton>
      </div>
    </div>
  );
};
