import { ApolloError } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  updateForeignShopMappings,
  UpdateForeignShopMappingsInput,
  UpdateForeignShopMappingsResponse,
} from "~/api/graphql/foreignShopMapping";
import { CustomButton } from "~/components/form/CustomButton";
import CustomModal from "~/components/UI/CustomModal";
import { colors } from "~/constants/styles";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import useDidMountEffect from "~/hooks/useDidMountEffect";
import { useAppDispatch, useAppSelector } from "~/redux/hooks";
import { foreignShopMappingState } from "~/redux/slice/foreignShopMapping.slice";
import { guessMappings } from "~/redux/slice/foreignShopMapping.slice";
import { Attribute } from "~/types/data/Attribute.types";
import { VendorCategory } from "~/types/data/VendorCategory.types";

import { scrollToSection } from "..";
import { NavigationSections } from "../SectionsNavigation";
import styles from "./index.module.scss";
type Props = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  attributes: Attribute[];
  vendorCategories: VendorCategory[];
};
export const ActionsSection = ({
  setLoading,
  attributes,
  vendorCategories,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    mappedCategories,
    mappedOptions,
    categoriesFinished,
    optionValuesFinished,
    optionsFinished,
    isGuessing,
  } = useAppSelector(foreignShopMappingState);
  const [modalState, setModalState] = useState<{
    visible: boolean;
    error?: string;
  }>({ visible: false });
  const onUpdateSuccess = (_response: UpdateForeignShopMappingsResponse) => {
    setModalState({ visible: true, error: undefined });
  };
  const onUpdateFailure = (_error: ApolloError) => {
    setModalState({ visible: true, error: _error.message });
  };
  const scrollOnMissingMapping = () => {
    if (!categoriesFinished) {
      scrollToSection(NavigationSections.CategoriesMapping);
      return false;
    }
    if (!optionsFinished) {
      scrollToSection(NavigationSections.FieldsMapping);
      return false;
    }
    if (!optionValuesFinished) {
      scrollToSection(NavigationSections.AttributeMapping);
      return false;
    }
    return true;
  };
  const guess = () => {
    dispatch(guessMappings({ attributes, vendorCategories }));
  };
  const modalButtonHandler = () => {
    if (!modalState.error) navigate(-1);
    setModalState({ visible: false });
  };
  const { trigger: updateForeignShopMappingsTrigger, loading: updateLoading } =
    useMutationWithCallbacks<
      UpdateForeignShopMappingsResponse,
      UpdateForeignShopMappingsInput
    >(updateForeignShopMappings, onUpdateSuccess, onUpdateFailure);

  const submitUpdatedMappings = () => {
    // code to check for if all the mappings are done
    // const allMapped = scrollOnMissingMapping();
    // if (!allMapped) return;

    const input = [...mappedCategories, ...mappedOptions];
    for (const mapping of mappedOptions) {
      input.push(...mapping.children);
    }
    const foreignShopMappings = input.map((mapping) => {
      const { id, mappedValue } = mapping;
      return { id, mappedValue: mappedValue ?? null };
    });

    updateForeignShopMappingsTrigger({
      variables: {
        UpdateForeignShopMappingsInput: { foreignShopMappings },
      },
    });
  };

  useEffect(() => {
    setLoading(updateLoading);
  }, [updateLoading]);

  useDidMountEffect(() => {
    scrollOnMissingMapping();
  }, [isGuessing]);

  return (
    <div className={`${styles.actionsHolder}`}>
      <CustomModal
        onCancel={() => {
          setModalState({ visible: false, error: "" });
        }}
        show={modalState.visible}
      >
        <div className={styles.modalContainer}>
          <h3>{modalState.error ?? "Attributs mappés avec succès"}</h3>
          <CustomButton
            type="button"
            backgroundColor={colors.$primary}
            color="white"
            width="10rem"
            borderRadius="8px"
            padding="0.8rem 0.8rem"
            onClick={modalButtonHandler}
          >
            {modalState.error ? "fermé" : "Page précédente"}
          </CustomButton>
        </div>
      </CustomModal>
      <CustomButton
        backgroundColor={colors.$primary}
        color="white"
        width="fit-content"
        borderRadius="8px"
        padding="1rem 1.5rem"
        onClick={submitUpdatedMappings}
      >
        Submit
      </CustomButton>
      <CustomButton
        backgroundColor={colors.$primary}
        color="white"
        width="fit-content"
        borderRadius="8px"
        padding="1rem 1.5rem"
        onClick={guess}
      >
        Guess Mappings
      </CustomButton>
    </div>
  );
};
