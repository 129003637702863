import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import getShopifyPermissionUrl from "~/api/rest/getShopifyPermissionUrl";

import styles from "./index.module.scss";

export const ShopifyConnect = () => {
  const [searchParams, _setSearchParams] = useSearchParams();

  const submitShopify = async () => {
    const shopifyShop = searchParams.get("shop");
    if (!shopifyShop) return;
    const result = await getShopifyPermissionUrl(shopifyShop);
    if (result.success) window.location.href = result.data.url;
  };

  useEffect(() => {
    submitShopify();
  }, []);

  return (
    <div className={styles.container}>
      {searchParams.get("shop") && (
        <h1>Redirecting to authentication page please wait...</h1>
      )}
    </div>
  );
};
