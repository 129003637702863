import { gql } from "@apollo/client";

import {
  graphGetCustomMessageFields,
  graphqlGetCarriersFields,
  graphqlGetConversationFields,
  graphqlGetDeliveryAddressFields,
  graphqlGetHistoryFields,
  graphqlGetMessageFields,
  graphqlGetOrderDeliveryPackageFields,
  graphqlGetOrderFields,
  graphqlGetOrderToProductFields,
  graphqlGetPaymentFields,
  graphqlGetProductFields,
  graphqlGetProfileFields,
  graphqlGetTransportationFields,
} from "~/constants/graphql";
import { OrdersFilterArgs } from "~/redux/slice/ordersFilter.slice";
import {
  Order,
  OrderProductRefundInput,
  ProductOrderStatusEnum,
} from "~/types/data/Order.types";

// inputs
export interface GetOrdersInput {
  OrdersFilterArgs: OrdersFilterArgs;
}

export interface GetOrderByIdInput {
  GetOrderByIdInput: { id: number };
}

export interface ConfirmAllOrderProductsInput {
  ConfirmAllOrderProductsInput: {
    orderId: number;
    status: ProductOrderStatusEnum;
  };
}

export interface ConfirmOrderProductInput {
  ConfirmOrderProductInput: {
    orderId: number;
    orderProductId: number;
    status: ProductOrderStatusEnum;
  };
}

export interface RefundTotalOrderInput {
  RefundTotalOrderInput: {
    orderId: number;
    refundReason: string;
  };
}

export interface RefundPartialOrderApiInput {
  RefundPartialOrderInput: {
    orderId: number;
    shippingAmount: number;
    refundProducts: OrderProductRefundInput[];
  };
}

export interface CompanyCloseIncidentInput {
  CompanyCloseIncidentInput: {
    orderId: number;
    reason: string;
  };
}

// responses
export interface GetOrdersResponse {
  getOrders: {
    orders: Order[];
    nextCursor: number;
    hasMoreData: boolean;
  };
}

export interface GetOrderByIdResponse {
  getOrderById: Order;
}

export interface ConfirmAllOrderProductsResponse {
  confirmAllOrderProducts: Order;
}

export interface ConfirmOrderProductResponse {
  confirmOrderProduct: Order;
}

export interface RefundTotalOrderResponse {
  refundTotalOrder: Order;
}

export interface RefundPartialOrderResponse {
  refundPartialOrder: Order;
}

export interface CompanyCloseIncidentResponse {
  companyCloseIncident: Order;
}

export interface GetOrdersCountResponse {
  getOrdersCount: { count: number };
}

// select fields
const orderFields = graphqlGetOrderFields.join(" ");
const orderToProductFields = graphqlGetOrderToProductFields.join(" ");
const productFields = graphqlGetProductFields.join(" ");
const conversationFields = graphqlGetConversationFields.join(" ");
const messageFields = graphqlGetMessageFields.join(" ");
const customMessageFields = graphGetCustomMessageFields.join(" ");
const profileFields = graphqlGetProfileFields.join(" ");
const deliveryAddressFields = graphqlGetDeliveryAddressFields.join(" ");
const orderDelviveryPackageFields =
  graphqlGetOrderDeliveryPackageFields.join(" ");
const paymentFields = graphqlGetPaymentFields.join(" ");
const transportationFields = graphqlGetTransportationFields.join(" ");
const historyFields = graphqlGetHistoryFields.join(" ");
const carrierFields = graphqlGetCarriersFields.join(" ");

export const fullOrderFields = `
  ${orderFields}
  
  commissionAmount

  orderToProducts {
    ${orderToProductFields}
    
    product{
      ${productFields}

      image1FullPath
    }
  }

  transportations {
    ${transportationFields}
    
    carrier {
      ${carrierFields}
    }
  }

  orderHistory {
    ${historyFields}

    createdByProfile {
      ${profileFields}
    }
  }

  shippingAddress {
    ${deliveryAddressFields}
  }

  billingAddress {
    ${deliveryAddressFields}
  }

  deliveryOption {
    ${orderDelviveryPackageFields}

    carrier {
      ${carrierFields}
    }
  }

  payment {
    ${paymentFields}
  }

  conversation {
    ${conversationFields}

    messages { 
      ${messageFields}
          
      pictureUrl
      fileUrl

      customMessage {
        ${customMessageFields}

        orderToProducts {
          ${orderToProductFields}

            product {
              ${productFields}
            }
        }
      }
    }
  }

  buyer{
    ${profileFields}

    profilePicUrl
    phoneNumber
    email
  }
`;

// queries and mutations
export const getOrders = gql`
  query ($OrdersFilterArgs: OrdersFilterArgs!) {
    getOrders(OrdersFilterArgs: $OrdersFilterArgs) {
      orders{
        ${orderFields}

        orderToProducts {
          ${orderToProductFields}

          product{
            ${productFields}
          }
        }

        buyer{
          ${profileFields}
          phoneNumber
          email
        }

        shippingAddress {
          ${deliveryAddressFields}
        }

        conversation {
          id
        }
      }

      nextCursor
      hasMoreData

    }
  }
`;

export const getOrderById = gql`
  query ($GetOrderByIdInput: GetOrderByIdInput!) {
    getOrderById(GetOrderByIdInput: $GetOrderByIdInput) {
      ${fullOrderFields}
    }
  }
`;

export const confirmAllOrderProducts = gql`
  mutation ($ConfirmAllOrderProductsInput: ConfirmAllOrderProductsInput!) {
    confirmAllOrderProducts(ConfirmAllOrderProductsInput: $ConfirmAllOrderProductsInput) {
      ${fullOrderFields}
    }
  }
`;

export const confirmOrderProduct = gql`
  mutation ($ConfirmOrderProductInput: ConfirmOrderProductInput!) {
    confirmOrderProduct(ConfirmOrderProductInput: $ConfirmOrderProductInput) {
      ${fullOrderFields}
    }
  }
`;

export const refundTotalOrder = gql`
  mutation ($RefundTotalOrderInput: RefundTotalOrderInput!) {
    refundTotalOrder(RefundTotalOrderInput: $RefundTotalOrderInput) {
      ${fullOrderFields}
    }
  }
`;

export const refundPartialOrder = gql`
  mutation ($RefundPartialOrderInput: RefundPartialOrderInput!) {
    refundPartialOrder(RefundPartialOrderInput: $RefundPartialOrderInput) {
      ${fullOrderFields}
    }
  }
`;

export const companyCloseIncident = gql`
  mutation ($CompanyCloseIncidentInput: CompanyCloseIncidentInput!) {
    companyCloseIncident(CompanyCloseIncidentInput: $CompanyCloseIncidentInput) {
      ${fullOrderFields}
    }
  }
`;

export const getOrdersCount = gql`
  query ($OrdersFilterArgs: OrdersFilterArgs!) {
    getOrdersCount(OrdersFilterArgs: $OrdersFilterArgs) {
      count
    }
  }
`;
