import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import accountingFilterSlice from "./slice/accounting.slice";
import adminCompaniesFilterSlice from "./slice/adminCompanies.slice";
import adminUsersFilterSlice from "./slice/adminUsers.slice";
import catalogueFilterSlice from "./slice/catalogueFilter.slice";
import conversationsStateSlice from "./slice/conversations.slice";
import foreignShopMappingSlice from "./slice/foreignShopMapping.slice";
import importMappedCategoriesSlice from "./slice/importMappedCategories.slice";
import mappedAttributesSlice from "./slice/mappedAttributes.slice";
import mappedAttributeValuesSlice from "./slice/mappedAttributesValues.slice";
import ordersFilterSlice from "./slice/ordersFilter.slice";
import rentOrdersFilterSlice from "./slice/rentOrdersFilter.slice";
import reviewsFilterSlice from "./slice/reviewsFilter.slice";

const reducers = combineReducers({
  importMappedCategories: importMappedCategoriesSlice,
  mappedAttributes: mappedAttributesSlice,
  mappedAttributeValues: mappedAttributeValuesSlice,
  catalogueFilter: catalogueFilterSlice,
  ordersFilter: ordersFilterSlice,
  rentOrdersFilter: rentOrdersFilterSlice,
  conversationsState: conversationsStateSlice,
  foreignShopMappingSlice: foreignShopMappingSlice,
  reviewsFilter: reviewsFilterSlice,
  accountingFilter: accountingFilterSlice,
  adminUsersFilter: adminUsersFilterSlice,
  adminCompaniesFilter: adminCompaniesFilterSlice,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "importMappedCategories",
    "mappedAttributes",
    "mappedAttributeValues",
    "conversationsState",
    "foreignShopMappingSlice",
    "reviewsFilter",
    "accountingFilter",
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);

// export const purgePersistor = async () => {
//   await persistor.purge();
// };

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const clearResults = () => {
  storage.removeItem("persist:root");
};
