import { CustomButton } from "~/components/form/CustomButton";
import CustomModal from "~/components/UI/CustomModal";
import { colors } from "~/constants/styles";

import styles from "./index.module.scss";
type Props = {
  onCancel: () => void;
  onConfirm: () => void;
  show: boolean;
  text: string;
};
export const ConfirmModal = ({ onCancel, onConfirm, show, text }: Props) => {
  return (
    <CustomModal onCancel={onCancel} show={show}>
      <div className={styles.modalContainer}>
        <h3>{text}</h3>
        <div className={styles.buttonsContainer}>
          <CustomButton
            type="button"
            backgroundColor={colors.$primary}
            color="white"
            width="8rem"
            borderRadius="8px"
            padding="1rem 1rem"
            onClick={onCancel}
          >
            No
          </CustomButton>
          <CustomButton
            type="button"
            backgroundColor={colors.$primary}
            color="white"
            width="8rem"
            borderRadius="8px"
            padding="1rem 1rem"
            onClick={onConfirm}
          >
            Yes
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  );
};
