import { OrderHisory } from "~/types/data/OrderHistory.types";
import { formatDate, formatHour } from "~/util/functions/formatDate";
import { mapOrderHistoryStatus } from "~/util/mapping/orderHistoryMapping";

import styles from "./index.module.scss";

interface Props {
  historyData: OrderHisory;
}

// TODO: make a mapping for the roles and statuses
export const HistoryTableRow = ({ historyData }: Props) => {
  const { createdAt, createdByRole, description, status, title } = historyData;

  const formattedDate = formatDate(createdAt);
  const formattedHour = formatHour(createdAt);
  return (
    <tr className={`${styles.row}`}>
      <td>
        <div>{formattedDate}</div>
        <div>{formattedHour}</div>
      </td>
      <td> {createdByRole}</td>
      <td> {title}</td>
      <td> {description}</td>
      <td> {mapOrderHistoryStatus[status ?? ""]}</td>
    </tr>
  );
};
