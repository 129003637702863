import { getMediaUploadPath } from "~/api/graphql/media/getMediaUploadPath";
import { imageFields } from "~/constants/states";
import { ImageFile, Product } from "~/types/data/Product.types";

export const imagesToFieldsMap = async (productImages: ImageFile[]) => {
  const sortedImages = productImages.sort((a, b) => {
    return a.x - b.x;
  });
  let imageInputs: Partial<Product> = {};
  let i = 0;
  for (const imageFile of sortedImages) {
    if (imageFile.file) {
      const response = await getMediaUploadPath({
        entity: "product",
        field: "image",
        filename: imageFile.file.name,
        isPublic: true,
      });
      if (response.success) {
        const { url, path } = response.data;
        await fetch(url, { method: "put", body: imageFile.file });

        imageFile.key = path;
      } else {
        throw new Error(`Uploading image #${i + 1} didn't work`);
      }
    }
    imageInputs = { ...imageInputs, [imageFields[i]]: imageFile.key };
    i++;
  }

  // empty other fields
  for (let i = sortedImages.length; i < 7; i++) {
    imageInputs = { ...imageInputs, [imageFields[i]]: "" };
  }
  return imageInputs;
};
