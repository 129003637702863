import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { utils, writeFile } from "xlsx";

import {
  GET_LAST_ADMIN_IMPORT,
  GetLastAdminImportInput,
  GetLastAdminImportResponse,
  PROCESS_ADMIN_IMPORT,
  ProcessAdminImportInput,
  ProcessAdminImportResponse,
} from "~/api/graphql/adminImport";
import {
  ADMIN_DELETE_ATTRIBUTE_VALUE,
  AdminDeleteAttributeValueInput,
  AdminDeleteAttributeValueResponse,
} from "~/api/graphql/attributeValue";
import { CustomButton } from "~/components/form/CustomButton";
import { ConfirmModal } from "~/components/UI/ConfirmModal";
import CustomModal from "~/components/UI/CustomModal";
import { LoadingPopup } from "~/components/UI/LoadingPopup";
import StatusLabel from "~/components/UI/StatusLabel";
import SVGContainer from "~/components/UI/SVGContainer";
import { UploadFileModal } from "~/components/UI/UploadFileModal";
import { colors } from "~/constants/styles";
import {
  AdminImport,
  AdminImportTypeEnum,
} from "~/types/data/AdminImport.type";
import { Attribute } from "~/types/data/Attribute.types";
import { AttributeValue } from "~/types/data/AttributeValue.types";
import { ImportedFileStatusEnum } from "~/types/data/ImportedFile.type";
import { formatDate } from "~/util/functions/formatDate";

import styles from "./index.module.scss";
import { FormModal } from "./ValueFormModal";
import { ValueRow } from "./ValueRow";

interface Props {
  attribute: Attribute;
  createAttributeValueState: (
    attributeId: number,
    attributeValue: AttributeValue
  ) => void;
  updateAttributeValueState: (
    attributeId: number,
    attributeValueId: number,
    attributeValue: AttributeValue
  ) => void;
  deleteAttributeValueState: (
    attributeId: number,
    attributeValueId: number
  ) => void;
}

export const ValuesSection = ({
  attribute,
  updateAttributeValueState,
  deleteAttributeValueState,
  createAttributeValueState,
}: Props) => {
  const [modalState, setModalState] = useState<{
    show: boolean;
    type?: "create" | "update" | "delete";
    attributeValue?: AttributeValue;
    isLoading?: boolean;
    errorMessage?: string;
  }>({
    show: false,
  });
  const [showUploadFileModal, setShowUploadFileModal] =
    useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
  const [isPreparingFile, setIsPreparingFile] = useState<boolean>(false);

  const [lastImport, setLastImport] = useState<AdminImport>();

  const lastImportedStatus = lastImport?.status ?? "N/A";
  const lastImportedStatusColor =
    lastImport?.status === ImportedFileStatusEnum.DONE
      ? "green"
      : lastImport?.status === ImportedFileStatusEnum.ERROR
      ? "red"
      : lastImport?.status === ImportedFileStatusEnum.IN_PROGRESS
      ? "#dd8500"
      : "#818181";

  const lastImportedDate = lastImport?.finishedAt
    ? formatDate(lastImport?.finishedAt)
    : lastImport?.createdAt
    ? formatDate(lastImport?.createdAt)
    : "N/A";

  const { id, name, values } = attribute;

  const [getLastAdminImportTrigger] = useLazyQuery<
    GetLastAdminImportResponse,
    GetLastAdminImportInput
  >(GET_LAST_ADMIN_IMPORT);

  const showCreateModalHandler = () => {
    setModalState({
      show: true,
      type: "create",
    });
  };

  const showEditModalHandler = (attributeValue: AttributeValue) => {
    setModalState({
      show: true,
      type: "update",
      attributeValue,
    });
  };

  const showDeleteModalHandler = (attributeValue: AttributeValue) => {
    setModalState({
      show: true,
      type: "delete",
      attributeValue,
    });
  };

  const closeModalHandler = () => {
    setModalState({
      show: false,
    });
  };

  const setLoadingHandler = (isLoading: boolean) => {
    setModalState((prevState) => ({
      ...prevState,
      isLoading,
    }));
  };

  const setErrorMessageHandler = (errorMessage: string) => {
    setModalState((prevState) => ({
      ...prevState,
      errorMessage,
    }));
  };

  const [deleteAttributeValueTrigger] = useMutation<
    AdminDeleteAttributeValueResponse,
    AdminDeleteAttributeValueInput
  >(ADMIN_DELETE_ATTRIBUTE_VALUE);

  const deleteAttributeValueHandler = async () => {
    const modalAttributeValue = modalState.attributeValue;
    if (!modalAttributeValue) return;
    setLoadingHandler(true);
    await deleteAttributeValueTrigger({
      variables: {
        AdminDeleteAttributeValueInput: {
          id: modalAttributeValue.id,
        },
      },
      onCompleted: () => {
        deleteAttributeValueState(id, modalAttributeValue.id);
        closeModalHandler();
      },
      onError: (error) => {
        alert(error.message);
      },
    });
    setLoadingHandler(false);
  };

  const getLastAdminImportHandler = async () => {
    const response = await getLastAdminImportTrigger({
      variables: {
        GetLastAdminImportInput: {
          type: AdminImportTypeEnum.ATTRIBUTE_VALUES,
          associatedId: id,
        },
      },
    });
    setLastImport(response.data?.getLastAdminImport);
  };

  const exportAttibuteValues = () => {
    if (!values) return;
    setIsPreparingFile(true);

    const categoriesToExport = values.map(
      ({ id, value, code, associatedValues, order }) => ({
        id: id,
        value,
        associated_values: associatedValues.join(", "),
        code,
        order,
      })
    );

    // create file using xlsx
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(categoriesToExport);

    // make cells wrap text
    utils.book_append_sheet(wb, ws, name);
    setIsPreparingFile(false);
    writeFile(wb, `attribute-values_${name}.xlsx`);
  };

  useEffect(() => {
    return () => {
      if (!intervalId) return;
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    getLastAdminImportHandler();
    if (intervalId) {
      clearInterval(intervalId);
    }

    const i = setInterval(() => {
      getLastAdminImportHandler();
    }, 10000);

    setIntervalId(i);
  }, [id]);

  return (
    <div className={styles.container}>
      <UploadFileModal<ProcessAdminImportResponse, ProcessAdminImportInput>
        graphqlDocument={PROCESS_ADMIN_IMPORT}
        fileFields={{
          field: `attribute-values_${name}`,
          entity: "admin-imports",
        }}
        graphqlVariables={{
          ProcessAdminImportInput: {
            type: AdminImportTypeEnum.ATTRIBUTE_VALUES,
            filePath: "",
            associatedId: id,
          },
        }}
        hideModalHandler={() => {
          setShowUploadFileModal(false);
        }}
        show={showUploadFileModal}
        successSetStateHandler={(data) => {
          setLastImport(data.processAdminImport);
        }}
        acceptedColumns={[
          {
            column: "id",
            required: false,
          },
          {
            column: "value",
            required: false,
          },
          {
            column: "associated_values",
            required: false,
          },
          {
            column: "code",
            required: false,
          },
          {
            column: "order",
            required: false,
          },
        ]}
      />
      <LoadingPopup show={isPreparingFile} message="Preparing file..." />
      <CustomModal
        show={modalState.show && modalState.type !== "delete"}
        disableCancel={modalState.isLoading}
        onCancel={closeModalHandler}
        disableScroll
      >
        <FormModal
          addAttributeValueStateHandler={(attributeValue) => {
            createAttributeValueState(id, attributeValue);
          }}
          attributeValue={modalState.attributeValue}
          updateAttributeValueState={(attributeValueId, attributeValue) => {
            updateAttributeValueState(id, attributeValueId, attributeValue);
          }}
          isLoading={modalState.isLoading}
          setIsLoading={setLoadingHandler}
          setErrorMessage={setErrorMessageHandler}
          errorMessage={modalState.errorMessage}
          attributeId={id}
          hideModal={closeModalHandler}
          attributeIsColor={name === "color"}
        />
      </CustomModal>
      <ConfirmModal
        show={modalState.show && modalState.type === "delete"}
        isLoading={modalState.isLoading}
        headerText="Confirm Delete"
        onExit={closeModalHandler}
        messageText="Are you sure you want to delete this attribute value?"
        onConfirm={() => {
          deleteAttributeValueHandler();
        }}
        errorText={modalState.errorMessage}
      />
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          <h2 className={styles.headerText}>{name}</h2>
          <SVGContainer
            height="18px"
            width="18px"
            imagePath="/assets/add-button.svg"
            onClick={() => {
              showCreateModalHandler();
            }}
          />
        </div>
        <div className={styles.importButtonsContainer}>
          <CustomButton
            borderColor={colors.$primaryDark}
            borderRadius="5px"
            color={colors.$primaryDark}
            height="2rem"
            padding="0.5rem 1rem"
            onClick={exportAttibuteValues}
          >
            <span className={styles.buttonWithIconContent}>
              <SVGContainer
                height="18px"
                width="18px"
                imagePath="/assets/download-icon.svg"
              />
              <span>Download</span>
            </span>
          </CustomButton>
          <CustomButton
            backgroundColor={colors.$primary}
            borderRadius="5px"
            color="white"
            height="2rem"
            padding="0.5rem 1rem"
            onClick={() => {
              setShowUploadFileModal(true);
            }}
          >
            Upload
          </CustomButton>
        </div>
      </div>
      <div className={styles.lastImportContainer}>
        <p>Last import: {lastImportedDate}</p>{" "}
        <StatusLabel
          label={lastImportedStatus}
          backgroundColor={lastImportedStatusColor}
          color="white"
        />
      </div>
      {values && (
        <div className={styles.valuesList}>
          {values.map((value, index) => (
            <ValueRow
              index={index}
              key={value.id}
              attributeValue={value}
              showEditModalHandler={showEditModalHandler}
              showDeleteModalHandler={showDeleteModalHandler}
            />
          ))}
        </div>
      )}
    </div>
  );
};
