import { BankReference } from "~/types/data/BankReference.types";
import { CompanyCarrier, ReedooCarrier } from "~/types/data/Carrier.types";
import { Company } from "~/types/data/Company.types";
import {
  KYC,
  KYCDocumentNameEnum,
  KycDocumentStatusEnum,
} from "~/types/data/KYC.types";
import { Product, VariantsChecklist } from "~/types/data/Product.types";
import { UBO } from "~/types/data/UBO.types";

export const initUBOState: UBO = {
  firstName: "",
  lastName: "",
  address: "",
  zipCode: "",
  city: "",
  region: "",
  country: "",
  birthDate: "",
  birthPlace: "",
  birthCountry: "",
  nationality: "",
  active: true,
};

export const initKYCSIdentityCardState: KYC = {
  name: KYCDocumentNameEnum.IDENTITY_CARD,
  path: "",
  status: KycDocumentStatusEnum.CREATED,
};

export const initKYCSKBISState: KYC = {
  name: KYCDocumentNameEnum.KBIS,
  path: "",
  status: KycDocumentStatusEnum.CREATED,
};

export const initKYCCompanyStatutesState: KYC = {
  name: KYCDocumentNameEnum.COMPANY_STATUTES,
  path: "",
  status: KycDocumentStatusEnum.CREATED,
};

export const initKYCShareholderStatementState: KYC = {
  name: KYCDocumentNameEnum.SHAREHOLDER_STATEMENT,
  path: "",
  status: KycDocumentStatusEnum.CREATED,
};

export const initBankReference: BankReference = {
  accountOwner: "",
  bankName: "",
  address: "",
  zipCode: "",
  city: "",
  iban: "",
  bic: "",
};

export const initDeliveryPackage = {
  code: "",
  minDays: 0,
  maxDays: 0,
  price: 0,
};

export const initCompanyCarrierState: CompanyCarrier = {
  carrierId: 0,
  deliveryPackages: [],
};

export const initReedooCarrierState: ReedooCarrier = {
  id: 0,
  carrierId: 0,
  deliveryPackages: [],
};

export const initMyAccountState: Company = {
  id: 1,
  mangopayId: "",
  storeName: "",
  companyDescription: "",
  logo: "",
  returnPolicyDescription: "",
  companyName: "",
  socialReason: undefined,
  siret: "",
  intercomTVA: "",
  companyAddress: "",
  companyZipCode: "",
  companyCity: "",
  companyPhoneNumber: "",
  companyPhoneNumber2: "",
  website: "",
  email: "",
  country: "",
  legalRepLastName: "",
  legalRepFirstName: "",
  legalRepEmail: "",
  legalRepAddress: "",
  legalRepZipCode: "",
  legalRepCity: "",
  legalRepBirthday: "",
  legalRepCountry: "",
  ubos: [initUBOState],
  kycDocuments: [],
  bankReference: initBankReference,
  carriers: [initCompanyCarrierState],
  reedooCarriers: [initReedooCarrierState],
  users: [],
  conversations: [],
};

export const initProductFormState: Product = {
  id: 0,

  categoryId: undefined,
  parentId: "",

  image1: "",
  image2: "",
  image3: "",
  image4: "",
  image5: "",
  image6: "",
  image7: "",
  video: "",
  videoThumbnailUrl: "",
  images: [],

  title: "",
  description: "",
  productCareInstructions: "",
  ean: "",
  sku: "",
  vendorParentId: "",
  composition: "",
  compositionLining: "",
  minimalAge: 0,
  weight: "",
  depth: "",
  length: "",
  height: "",
  volume: "",
  diameter: "",

  gender: undefined,
  brand: "",
  stateType: "",
  size: "",
  color: "",
  mainMaterial: "",
  liningMaterial: "",
  logisticClass: "",
  ecoResponsibleLabel: "",
  madeIn: "",

  quantity: 0,
  sendAlertOnQuantity: 0,

  ecoTax: 0,
  vatRate: 20,
  priceTTC: 0,
  discountedPriceTTC: undefined,
  startingDiscountDate: "",
  endingDiscountDate: "",
};

export const initVariantsChecklist: VariantsChecklist = {
  images: true,
  video: true,

  title: true,
  sku: true,
  ean: true,

  gender: true,
  brand: true,
  stateType: true,
  size: true,
  color: true,
  mainMaterial: true,
  liningMaterial: true,
  logisticClass: true,
  ecoResponsibleLabel: true,
  madeIn: true,

  quantity: true,
  sendAlertOnQuantity: true,
};

export const imageFields: (keyof Product)[] = [
  "image1",
  "image2",
  "image3",
  "image4",
  "image5",
  "image6",
  "image7",
];

export const InitialCategoryState = {
  level1: [],
  level2: [],
  level3: [],
};
