import InfiniteScroll from "react-infinite-scroll-component";

import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { HeadCell } from "~/types/common/headCell.type";
import { MyTransaction } from "~/types/data/Accounting.types";

import styles from "./index.module.scss";
import TransactionsTableBody from "./TransactionsTableBody";
import TransactionsTableHead from "./TransactionsTableHead";

interface Props {
  transactions?: MyTransaction[];
  selectedRows: MyTransaction[];
  onCheckboxChange: (product: MyTransaction) => void;
  onSelectAllChange: (selected: boolean) => void;
  isSelectedAll: boolean;
  hasMoreData: boolean;
  fetchMoreData: () => void;
}

const HEADCELLS: HeadCell<MyTransaction>[] = [
  { id: 1, label: "", isCheckbox: true },
  {
    id: 2,
    label: "Date",
    isSortable: true,
    associatedKey: "createdAt",
  },
  { id: 3, label: "N° commande", isSortable: true, associatedKey: "id" },
  {
    id: 4,
    label: "Produits",
  },
  { id: 5, label: "Qté" },
  {
    id: 6,
    label: "Description commande",
  },
  { id: 7, label: "Montant" },
  {
    id: 8,
    label: "Statut",
    associatedKey: "status",
  },
];

const TransactionsTable = ({
  transactions,
  fetchMoreData,
  hasMoreData,
  selectedRows,
  isSelectedAll,
  onCheckboxChange,
  onSelectAllChange,
}: Props) => {
  return (
    <>
      <InfiniteScroll
        dataLength={transactions?.length ?? 0}
        next={fetchMoreData}
        hasMore={!!transactions && hasMoreData}
        loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
      >
        <table className={`${styles.table}`}>
          <TransactionsTableHead
            headCells={HEADCELLS}
            onSelectAllChange={onSelectAllChange}
            allSelected={isSelectedAll}
          />
          <TransactionsTableBody
            transactions={transactions ?? []}
            onCheckboxChange={onCheckboxChange}
            selectedRows={selectedRows}
          />
        </table>
      </InfiniteScroll>
      {!transactions && (
        <div className={styles.loaderContainer}>
          <SimpleLoader size="size3" fill={colors.$primary} />
        </div>
      )}
    </>
  );
};

export default TransactionsTable;
