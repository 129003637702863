import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import {
  GET_SALE_DASHBOARD_DATA,
  GetDashboardDataInput,
  GetSaleDashboardDataResponse,
} from "~/api/graphql/dashboard";
import FullPageLoader from "~/components/UI/FullPageLoader";
import { SaleDashboardData } from "~/types/data/DashboardData.types";

import { SaleDashboardGrid } from "./SaleDashboardGrid";

interface Props {
  from?: string;
  to?: string;
}

export const SalePage = ({ from, to }: Props) => {
  const [dashboardData, setDashboardData] = useState<SaleDashboardData>();
  const [getDashboardDataError, setGetDashboardDataError] =
    useState<string>("");
  const [isLoading, setIsLoading] = useState(true);

  const [trigger, { loading }] = useLazyQuery<
    GetSaleDashboardDataResponse,
    GetDashboardDataInput
  >(GET_SALE_DASHBOARD_DATA);

  const fetchData = async () => {
    if (loading) return;
    setIsLoading(true);
    const { data, error } = await trigger({
      variables: { GetDashboardDataInput: { from, to } },
    });
    if (data) {
      setDashboardData(data.getSaleDashboardData);
    } else if (error) {
      setGetDashboardDataError(error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [from, to]);

  return !!getDashboardDataError ? (
    <div>Error: {getDashboardDataError}</div>
  ) : isLoading || !dashboardData ? (
    <FullPageLoader />
  ) : (
    <SaleDashboardGrid data={dashboardData} to={to} />
  );
};
