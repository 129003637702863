import React from "react";
import ReactDOM from "react-dom";

import Backdrop from "../Backdrop";
import SVGContainer from "../SVGContainer";
import styles from "./index.module.scss";

interface Props {
  show?: boolean;
  onCancel: () => void;
  width?: string;
}

const ModalOverlay = ({
  children,
  onCancel,
  width,
}: React.PropsWithChildren<Props>) => {
  const content = (
    <div className={`${styles.modal}`} style={{ width: width || "28rem" }}>
      <div className={`${styles.closeButton}`} onClick={onCancel}>
        <SVGContainer
          height="16px"
          width="16px"
          imagePath="/assets/close-button.svg"
        />
      </div>
      {children}
    </div>
  );

  const element = document.getElementById("modal-hook");
  if (element) {
    return ReactDOM.createPortal(content, element);
  } else {
    return content;
  }
};

export const SideModal = ({
  show,
  onCancel,
  children,
  width,
}: React.PropsWithChildren<Props>) => {
  return (
    <React.Fragment>
      {show && <Backdrop onClick={onCancel} />}
      {show && (
        <ModalOverlay onCancel={onCancel} width={width}>
          {children}
        </ModalOverlay>
      )}
    </React.Fragment>
  );
};
