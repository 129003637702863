import { Product } from "~/types/data/Product.types";

export const importRequiredAttributes: (keyof Product)[] = [
  "title",
  "categoryId",
  "description",
  "sku",
  "brand",
  "logisticClass",
];
