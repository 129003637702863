import { useSelector } from "react-redux";

import { ConversationContainer } from "~/components/messages/ConversationContainer";
import { selectConversationsState } from "~/redux/slice/conversations.slice";
import { Order } from "~/types/data/Order.types";
import { formatDate } from "~/util/functions/formatDate";

import { getProfileName } from "../util/getProfileName";
import styles from "./index.module.scss";
import { InformationBanner } from "./InformationBanner";
import InformationCard from "./InformationCard";

interface Props {
  order: Order;
  showCloseIncidentModalHandler: () => void;
}

export const Messages = ({ order, showCloseIncidentModalHandler }: Props) => {
  const { conversations } = useSelector(selectConversationsState);
  const conversation = conversations.find(
    ({ order: conversationOrder }) => conversationOrder?.id === order.id
  );

  if (!conversation) {
    return <div>No messages</div>;
  }

  const formattedDate = formatDate(order.createdAt);
  const buyerName = getProfileName(
    order.buyer?.firstName,
    order.buyer?.lastName
  );

  const finalShippingPrice = order.shippingPrice - order.refundShippingAmount;

  return (
    <div className={styles.container}>
      <div className={styles.informationSection}>
        <InformationCard
          date={formattedDate}
          title="Informations"
          email={order.buyer?.email ?? ""}
          phoneNumber={order.buyer?.phoneNumber ?? ""}
          finalTotalPrice={order.finalTotalPrice}
          finalShippingPrice={finalShippingPrice}
          products={order.orderToProducts}
          incidentStatus={order.incidentStatus}
          incidentReason={order.incidentReason}
        />
      </div>
      <div className={styles.messagesSection}>
        <div className={styles.bannerConatiner}>
          <InformationBanner
            clientName={buyerName}
            incidentStatus={order.incidentStatus}
            orderId={order.id}
            status={order.status}
          />
        </div>
        <div className={styles.messagesContainer}>
          <ConversationContainer
            conversation={conversation}
            showCloseIncidentModalHandler={showCloseIncidentModalHandler}
            order={order}
          />
        </div>
      </div>
    </div>
  );
};
