import clip from "text-clipper";

import CustomCheckbox from "~/components/form/CustomCheckbox";
import { RentOrder } from "~/types/data/RentOrder.type";
import { formatDate, formatHour } from "~/util/functions/formatDate";
import { formatPrice } from "~/util/functions/formatDecimal";
import { getFrequencyDisplay } from "~/util/functions/getFrequencyDisplay";

import styles from "./index.module.scss";

interface Props {
  order: RentOrder;
  isSelected: boolean;
  onCheckboxChange: (order: RentOrder) => void;
  onClick: (id: number) => void;
  showCompanyColumn: boolean;
}

export const RentOrdersTableRow = ({
  order,
  isSelected,
  onCheckboxChange,
  onClick,
  showCompanyColumn,
}: Props) => {
  const {
    createdAt,
    id,
    startingDate,
    frequency,
    orderToProducts,
    status,
    totalPaidAmount,
    totalPurchasedAmount,
    totalPaidRecurringAmount,
    billingPricePerPeriod,
    storeName,
  } = order;

  const formattedCreatedAtDate = formatDate(createdAt);
  const formattedCreatedAtHour = formatHour(createdAt);

  const formattedStartingDate = formatDate(startingDate);

  const formattedTotalPaidAmount = formatPrice(totalPaidAmount);
  const formattedTotalPurchasedAmount = formatPrice(totalPurchasedAmount);
  const formattedTotalRecurringBilledAmount = formatPrice(
    totalPaidRecurringAmount
  );

  const formattedBillingPricePerPeriod = formatPrice(billingPricePerPeriod);

  const formattedFrequency = getFrequencyDisplay(frequency);

  const clickHandler = () => {
    onClick(id);
  };

  return (
    <tr className={`${styles.row}`}>
      <td className={styles.nonClickable}>
        <CustomCheckbox
          checked={isSelected}
          onChange={() => {
            onCheckboxChange(order);
          }}
          padding="0.7rem"
        />
      </td>
      <td onClick={clickHandler}>
        <div>{formattedCreatedAtDate}</div>
        <div>{formattedCreatedAtHour}</div>
      </td>
      <td onClick={clickHandler}>{formattedStartingDate}</td>
      <td onClick={clickHandler}>{id} </td>
      <td onClick={clickHandler}>{status}</td>
      <td onClick={clickHandler}>
        <div className={`${styles.detailsContainer}`}>
          {orderToProducts.map(({ product, originalQuantity }, index) => {
            return (
              <div key={index} className={styles.productDetailsContainer}>
                <div className={styles.boldText}>
                  {clip(product.title || "", 20)}
                </div>
                <div>
                  <span className={styles.boldText}>SKU: </span>
                  {product.sku}
                </div>
                <div>
                  <span className={styles.boldText}>QTY: </span>
                  {originalQuantity}
                </div>
              </div>
            );
          })}
        </div>
      </td>
      <td onClick={clickHandler}>
        {orderToProducts.map(({ pricePerPeriod }, index) => {
          const formattedPricePerPeriod = formatPrice(pricePerPeriod);
          return (
            <div key={index} className={styles.productQuantityContainer}>
              {formattedPricePerPeriod}
            </div>
          );
        })}
      </td>
      <td onClick={clickHandler}>
        {formattedBillingPricePerPeriod}/{formattedFrequency}
      </td>
      <td onClick={clickHandler}>{formattedTotalRecurringBilledAmount}</td>
      <td onClick={clickHandler}>{formattedTotalPurchasedAmount}</td>
      <td onClick={clickHandler}>{formattedTotalPaidAmount}</td>{" "}
      {showCompanyColumn && (
        <td className={styles.nonClickable}>{storeName || "N/A"}</td>
      )}
    </tr>
  );
};
