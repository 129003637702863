import CustomCheckbox from "~/components/form/CustomCheckbox";
import SVGContainer from "~/components/UI/SVGContainer";
import { MyPayOut } from "~/types/data/Accounting.types";
import { formatDate, formatHour } from "~/util/functions/formatDate";
import { formatDecimal } from "~/util/functions/formatDecimal";
import { getTextFromPayOutStatus } from "~/util/mapping/orderStatus.mapping";

import styles from "./index.module.scss";

interface Props {
  payOut: MyPayOut;
  isSelected: boolean;
  onCheckboxChange: (payOut: MyPayOut) => void;
}

const PayOutsTableRow = ({ payOut, isSelected, onCheckboxChange }: Props) => {
  const {
    id,
    paidOutAt,
    amount,
    reedooCommissionAmount,
    payoutStatus,
    invoiceUrl,
  } = payOut;

  const formattedPaidOutAtDate = formatDate(paidOutAt);
  const formattedPaidOutAtHour = formatHour(paidOutAt);

  const statusText = getTextFromPayOutStatus(payoutStatus);

  const formattedReedooCommissionAmount = formatDecimal(
    reedooCommissionAmount,
    2
  );
  const formattedAmount = formatDecimal(amount, 2);

  return (
    <tr className={`${styles.row}`}>
      <td>
        <CustomCheckbox
          checked={isSelected}
          onChange={() => {
            onCheckboxChange(payOut);
          }}
          padding="0.7rem"
        />
      </td>
      <td>
        <div>{formattedPaidOutAtDate}</div>
        <div>{formattedPaidOutAtHour}</div>
      </td>
      <td>{formattedReedooCommissionAmount}</td>
      <td>{formattedAmount}</td>
      <td>{statusText}</td>
      <td>{id}</td>
      <td>
        <div className={`${styles.buttonsContainer}`}>
          <a
            href={invoiceUrl}
            download="proposed_file_name"
            target="_blank"
            rel="noreferrer"
          >
            <SVGContainer
              height="26px"
              width="26px"
              imagePath="/assets/download-button.svg"
            />
          </a>
        </div>
      </td>
    </tr>
  );
};

export default PayOutsTableRow;
