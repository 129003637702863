function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export function formatDate(date?: string) {
  const dateObject = date ? new Date(date) : new Date();
  return [
    padTo2Digits(dateObject.getDate()),
    padTo2Digits(dateObject.getMonth() + 1),
    dateObject.getFullYear(),
  ].join("/");
}

export function formatHour(date?: string) {
  const dateObject = date ? new Date(date) : new Date();
  return [
    padTo2Digits(dateObject.getHours()),
    padTo2Digits(dateObject.getMinutes()),
  ].join(":");
}
