import SVGContainer from "~/components/UI/SVGContainer";

import styles from "./index.module.scss";

type Props = {
  placeholder?: string;
  value: string | number | undefined;
  type?: "text" | "password" | "number" | "email";
  onChange: (changes: string | number) => void;
  iconPath: string;
  required?: boolean;
  disabled?: boolean;
  noBorder?: boolean;
  label?: string;
  borderRadius?: string;
  backgroundColor?: string;
  textColor?: string;
  fontSize?: string;
  step?: number;
  borderColor?: string;
  invalid?: boolean;
};

export const InputWithIcon = ({
  placeholder,
  value = "",
  type,
  onChange,
  required = false,
  disabled = false,
  noBorder = false,
  iconPath,
  label,
  borderRadius,
  backgroundColor,
  textColor,
  fontSize,
  borderColor,
  invalid,
}: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;

    if (type === "number" && event.target.value === "e") {
      return;
    }

    onChange(
      type === "number" && event.target.value !== ""
        ? +event.target.value
        : event.target.value
    );
  };

  return (
    <div className={`${styles.container}`} style={{ fontSize }}>
      {label && (
        <label className={`${styles.label}`}>
          {label}
          {required && <span>*</span>}
        </label>
      )}
      <div
        className={`${styles.divContainer} ${
          invalid ? "#870000" : noBorder ? styles.noBorder : ""
        }`}
        style={{
          backgroundColor: backgroundColor,
          border: "1px solid " + borderColor,
          color: textColor,
          borderRadius: borderRadius,
          marginTop: label ? "0.3rem" : "",
          borderColor: invalid ? "#870000" : borderColor,
        }}
      >
        <div className={`${styles.inputContainer}`}>
          <input
            value={value || value === 0 ? value : ""}
            type={type}
            className={`${styles.input} ${noBorder ? styles.noBorder : ""}`}
            autoComplete="off"
            placeholder={placeholder || ""}
            onChange={handleChange}
            disabled={disabled}
            step={0.01}
            required={required}
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
          />
        </div>
        <SVGContainer height="16px" width="16px" imagePath={`${iconPath}`} />
      </div>
    </div>
  );
};
