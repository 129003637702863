import { SingleValue } from "react-select";

import { InputWithIcon } from "~/components/form/InputWithIcon";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import {
  OrderHistoryFilterState,
  OrderHistoryRoleEnum,
} from "~/types/data/OrderHistory.types";

import styles from "./index.module.scss";

interface Props {
  filterState: OrderHistoryFilterState;
  filterChangeHandler: <T extends keyof OrderHistoryFilterState>(
    inputName: T,
    changes: OrderHistoryFilterState[T]
  ) => void;
}

export const FilterBar = ({ filterState, filterChangeHandler }: Props) => {
  const roleOptions: OptionType[] = [
    { label: "Client", value: OrderHistoryRoleEnum.BUYER },
    { label: "Vendeur", value: OrderHistoryRoleEnum.SELLER },
    { label: "Reedoo", value: OrderHistoryRoleEnum.REEDOO },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.searchInput}>
        <InputWithIcon
          onChange={(value: string | number) => {
            filterChangeHandler("searchBox", value.toString());
          }}
          borderColor="#ECECEC"
          value={filterState.searchBox}
          borderRadius="5px"
          backgroundColor="white"
          iconPath="/assets/search-icon.svg"
          placeholder="Chercher un sujet..."
        />
      </div>
      <div className={styles.selectInput}>
        <p>Déclencheur:</p>
        <SelectInput
          fontSize="14px"
          backgroundColor="white"
          options={roleOptions}
          onChange={(option: SingleValue<OptionType>) => {
            const filterValue = !option?.value
              ? undefined
              : option.value === OrderHistoryRoleEnum.BUYER
              ? OrderHistoryRoleEnum.BUYER
              : option.value === OrderHistoryRoleEnum.SELLER
              ? OrderHistoryRoleEnum.SELLER
              : OrderHistoryRoleEnum.REEDOO;
            filterChangeHandler("role", filterValue);
          }}
          value={filterState.role || ""}
          hasDefault={true}
          defaultLabel="Not selected"
          width="15rem"
        />
      </div>
    </div>
  );
};
