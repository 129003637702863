import "./index.scss";

import { useQuery } from "@apollo/client";

import {
  getCarriers,
  GetCarriersResponse,
  getReedooCarriers,
  GetReedooCarriersResponse,
} from "~/api/graphql/carrier";
import FullPageLoader from "~/components/UI/FullPageLoader";

import { BankReferences } from "./BankReferences";
import { CompanyDetails } from "./CompanyDetails";
import { Delivery } from "./Delivery";
import { Identification } from "./Identification";
import styles from "./index.module.scss";
import { KycImports } from "./KycImports";
import { MyAccountNavigation } from "./MyAccountNavigation";
import { ReedooDelivery } from "./ReedooDelivery";
import { UboDeclaration } from "./UboDeclaration";
import { Users } from "./Users";

interface Props {
  page:
    | "identification"
    | "companyDetails"
    | "uboDeclaration"
    | "kycImports"
    | "bankReference"
    | "delivery"
    | "reedooDelivery"
    | "users";
}

const MonCompte = ({ page }: Props) => {
  // useQuery
  const { data: carriersData, error: carriersError } =
    useQuery<GetCarriersResponse>(getCarriers, {
      fetchPolicy: "cache-first",
    });

  const { data: reedooCarriersData, error: reedooCarriersError } =
    useQuery<GetReedooCarriersResponse>(getReedooCarriers, {
      fetchPolicy: "cache-first",
    });
  return (
    <div className={`${styles.container}`}>
      {carriersError || reedooCarriersError ? (
        <>
          {carriersError && (
            <div>{`Carriers Error: ${carriersError.message}`}</div>
          )}
          {reedooCarriersError && (
            <div>{`Reedoo Carriers Error: ${reedooCarriersError.message}`}</div>
          )}
        </>
      ) : !carriersData || !reedooCarriersData ? (
        <FullPageLoader />
      ) : (
        <>
          <MyAccountNavigation />
          <div className={`${styles.formsContainer}`}>
            <h2>Gérer mon compte</h2>
            <div className={`${styles.formCard}`}>
              {page === "identification" ? (
                <Identification />
              ) : page === "companyDetails" ? (
                <CompanyDetails />
              ) : page === "uboDeclaration" ? (
                <UboDeclaration />
              ) : page === "kycImports" ? (
                <KycImports />
              ) : page === "bankReference" ? (
                <BankReferences />
              ) : page === "delivery" ? (
                <Delivery carriers={carriersData.getCarriers} />
              ) : page === "reedooDelivery" ? (
                <ReedooDelivery
                  reedooCarriers={reedooCarriersData.getReedooCarriers}
                  carriers={carriersData.getCarriers}
                />
              ) : (
                <Users />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MonCompte;
