import { numberCheck } from "~/pages/AjouterUnProduit/util/validateProduct";
import {
  CompanyCarrier,
  DeliveryPackage,
  GroupedDeliveryState,
} from "~/types/data/Carrier.types";

export const validateDeliveryPackages = (companyCarriers: CompanyCarrier[]) => {
  const deliveryPackages = companyCarriers.reduce<DeliveryPackage[]>(
    (a, b) => a.concat(b.deliveryPackages),
    []
  );
  for (const deliveryPackage of deliveryPackages) {
    if (
      !numberCheck({
        value: deliveryPackage.minDays,
        required: true,
        isInteger: true,
        moreThan: 0,
      })
    )
      return "Délai min. invalide";

    if (
      !numberCheck({
        value: deliveryPackage.maxDays,
        required: true,
        isInteger: true,
        moreThan: 0,
      })
    )
      return "Délai max. invalide";

    if (
      !numberCheck({
        value: deliveryPackage.price,
        required: true,
        moreThan: 0,
        lessThan: 999999.99,
      })
    )
      return "Prix invalide";

    if (
      !numberCheck({
        value: deliveryPackage.freeThreshold,
        moreThan: 0,
        lessThan: 999999.99,
        moreThanOrEqual: false,
      })
    )
      return "Seuil de gratuité invalide";
  }
};

export const validateGroupedDeliveryState = (
  groupedDeliveryState: GroupedDeliveryState
) => {
  for (const key in groupedDeliveryState) {
    const { carrierId, groupedCarrierState } = groupedDeliveryState[key];
    if (!carrierId) {
      return "Invalid carrier input";
    }

    if (!groupedCarrierState.length) {
      return "Can't create a company carrier with no packages";
    }
    for (const carrierState of groupedCarrierState) {
      if (!carrierState.code) {
        return "Invalid code input";
      }

      if (
        !carrierState.delivery?.france &&
        !carrierState.delivery?.corse &&
        !carrierState.delivery?.monaco &&
        !carrierState.pickup?.france &&
        !carrierState.pickup?.corse &&
        !carrierState.pickup?.monaco
      ) {
        return "Can't create a company carrier with no packages";
      }
    }
  }

  return null;
};
