import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { OrderBy } from "~/types/common/filter.types";
import { Order, OrderStatusEnum } from "~/types/data/Order.types";

import type { RootState } from "../store";

export interface OrdersFilterArgs {
  orderBy: OrderBy<Order>;
  cursor?: number;
  take?: number;
  status?: OrderStatusEnum;
  carrierId?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
  shippingDateFrom?: string;
  shippingDateTo?: string;
  finalTotalPriceFrom?: number;
  finalTotalPriceTo?: number;
  hasIncident?: boolean;
  hasRefund?: boolean;
  hasMessages?: boolean;
  pending?: boolean;
  searchBox?: string;
  companiesIds?: number[];
  managedByReedoo?: boolean;
}

type FilterArgs =
  | "orderBy"
  | "cursor"
  | "status"
  | "carrierId"
  | "createdAtFrom"
  | "createdAtTo"
  | "shippingDateFrom"
  | "shippingDateTo"
  | "finalTotalPriceFrom"
  | "finalTotalPriceTo"
  | "hasIncident"
  | "hasRefund"
  | "hasMessages"
  | "searchBox"
  | "pending"
  | "companiesIds"
  | "managedByReedoo"
  | "clearAll";

export const ordersFilterInitialState: OrdersFilterArgs = {
  orderBy: {},
  take: 30,
};

export const ordersFilterSlice = createSlice({
  name: "ordersFilter",
  initialState: ordersFilterInitialState,
  reducers: {
    onFilterArgsChange(
      state,
      action: PayloadAction<{
        filterArg: FilterArgs;
        orderBy?: OrderBy<Order>;
        cursor?: number;
        take?: number;
        status?: OrderStatusEnum;
        carrierId?: string;
        createdAtFrom?: string;
        createdAtTo?: string;
        shippingDateFrom?: string;
        shippingDateTo?: string;
        finalTotalPriceFrom?: number;
        finalTotalPriceTo?: number;
        hasIncident?: boolean;
        hasRefund?: boolean;
        hasMessages?: boolean;
        searchBox?: string;
        pending?: boolean;
        companiesIds?: number[];
        managedByReedoo?: boolean;
      }>
    ) {
      const {
        filterArg,
        cursor,
        orderBy,
        status,
        carrierId,
        createdAtFrom,
        createdAtTo,
        hasIncident,
        hasMessages,
        hasRefund,
        shippingDateFrom,
        shippingDateTo,
        finalTotalPriceFrom,
        finalTotalPriceTo,
        searchBox,
        pending,
        companiesIds,
        managedByReedoo,
      } = action.payload;
      if (filterArg === "cursor" && cursor) {
        state.cursor = cursor;
      } else if (filterArg === "orderBy" && orderBy) {
        state.orderBy = orderBy;
      } else if (filterArg === "status") {
        state.status = status;
      } else if (filterArg === "carrierId") {
        state.carrierId = carrierId;
      } else if (filterArg === "createdAtFrom") {
        state.createdAtFrom = createdAtFrom;
      } else if (filterArg === "createdAtTo") {
        state.createdAtTo = createdAtTo;
      } else if (filterArg === "shippingDateFrom") {
        state.shippingDateFrom = shippingDateFrom;
      } else if (filterArg === "shippingDateTo") {
        state.shippingDateTo = shippingDateTo;
      } else if (filterArg === "finalTotalPriceFrom") {
        state.finalTotalPriceFrom = finalTotalPriceFrom;
      } else if (filterArg === "finalTotalPriceTo") {
        state.finalTotalPriceTo = finalTotalPriceTo;
      } else if (filterArg === "hasIncident") {
        state.hasIncident = hasIncident;
      } else if (filterArg === "hasRefund") {
        state.hasRefund = hasRefund;
      } else if (filterArg === "hasMessages") {
        state.hasMessages = hasMessages;
      } else if (filterArg === "searchBox") {
        state.searchBox = searchBox;
      } else if (filterArg === "pending") {
        state.pending = pending;
      } else if (filterArg === "companiesIds") {
        state.companiesIds = companiesIds;
      } else if (filterArg === "managedByReedoo") {
        state.managedByReedoo = managedByReedoo;
      } else if (filterArg === "clearAll") {
        return { ...ordersFilterInitialState };
      }
    },
  },
});

export const { onFilterArgsChange } = ordersFilterSlice.actions;

export const selectOrdersFilterArgs = (state: RootState) => {
  return state.ordersFilter;
};
export default ordersFilterSlice.reducer;
