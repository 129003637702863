import SVGContainer from "~/components/UI/SVGContainer";

import { DataPlatformPagesEnum } from "../..";
import styles from "./index.module.scss";
type Props = {
  name: string;
  iconName: string;
  onClick: (section: DataPlatformPagesEnum) => void;
  sectionType: DataPlatformPagesEnum;
};

export const Section = ({ name, iconName, onClick, sectionType }: Props) => {
  return (
    <div onClick={() => onClick(sectionType)}>
      <SVGContainer
        height="10px"
        width="1rem"
        imagePath={`/assets/${iconName}`}
      />
      <h4 className={styles.sectionName}> {name} </h4>
    </div>
  );
};
