import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { utils, writeFile } from "xlsx";

import {
  GET_LAST_ADMIN_IMPORT,
  GetLastAdminImportInput,
  GetLastAdminImportResponse,
  PROCESS_ADMIN_IMPORT,
  ProcessAdminImportInput,
  ProcessAdminImportResponse,
} from "~/api/graphql/adminImport";
import {
  CREATE_OR_UPDATE_CATEGORY,
  CreateOrUpdateCategoryInput,
  CreateOrUpdateCategoryResponse,
  DELETE_CATEGORY,
  DeleteCategoryInput,
  DeleteCategoryResponse,
  GET_ALL_PARENTS_LEVEL_CATEGORIES,
  GetAllLevel1CategoriesResponse,
  GetAllParentLevelCategoriesResponse,
  getCategories,
  UPDATE_CATEGORIES_ORDER,
  UpdateCategoriesOrderInput,
  UpdateCategoriesOrderResponse,
} from "~/api/graphql/category";
import { CustomButton } from "~/components/form/CustomButton";
import { ConfirmModal } from "~/components/UI/ConfirmModal";
import CustomModal from "~/components/UI/CustomModal";
import { LoadingPopup } from "~/components/UI/LoadingPopup";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import StatusLabel from "~/components/UI/StatusLabel";
import SVGContainer from "~/components/UI/SVGContainer";
import { colors } from "~/constants/styles";
import {
  AdminImport,
  AdminImportTypeEnum,
} from "~/types/data/AdminImport.type";
import { Category } from "~/types/data/Category.type";
import { ImportedFileStatusEnum } from "~/types/data/ImportedFile.type";
import {
  getAllNestedCategories,
  mapGridStateToCategoriesOrder,
} from "~/util/functions/category/getAllNestedCategories.util";
import { formatDate } from "~/util/functions/formatDate";
import { moveItemInList } from "~/util/functions/moveItemInList";

import { UploadFileModal } from "../../../components/UI/UploadFileModal";
import { CategoryToggle } from "./CategoryToggle";
import { CreateCategoryModal, FormState } from "./CreateCategoryModal";
import styles from "./index.module.scss";

export interface CategoryGridItemType {
  category: Category;
  key: string;
  index: number;
}

export const Taxonomie = () => {
  const [modalState, setModalState] = useState<{
    show: boolean;
    mode?: "create" | "edit";
    category?: Category;
  }>({
    show: false,
  });
  const [showDeleteModal, setShowDeleteModal] = useState<{
    show: boolean;
    category?: Category;
    level?: number;
    parentId?: number;
  }>({
    show: false,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allParentCategories, setAllParentCategories] = useState<Category[]>();
  const [categoriesLevel1Data, setCategoriesLevel1Data] =
    useState<Category[]>();
  const [showUploadFileModal, setShowUploadFileModal] =
    useState<boolean>(false);

  const [fetchingErrorMessage, setFetchingErrorMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [isPreparingFile, setIsPreparingFile] = useState<boolean>(false);

  const [lastImport, setLastImport] = useState<AdminImport>();

  const [updateCategoriesButtonDisabled, setUpdateCategoriesButtonDisabled] =
    useState<boolean>(true);

  const [updateCategoriesOrderLoading, setUpdateCategoriesOrderLoading] =
    useState<boolean>(false);

  const [getAllParentsLevelCategoriesTrigger] =
    useLazyQuery<GetAllParentLevelCategoriesResponse>(
      GET_ALL_PARENTS_LEVEL_CATEGORIES
    );

  const [getAllCategoryLevel1Trigger] =
    useLazyQuery<GetAllLevel1CategoriesResponse>(getCategories);

  const [createOrUpdateCategoryTrigger] = useMutation<
    CreateOrUpdateCategoryResponse,
    CreateOrUpdateCategoryInput
  >(CREATE_OR_UPDATE_CATEGORY);

  const [deleteCategoryTrigger, { loading: deleteLoading }] = useMutation<
    DeleteCategoryResponse,
    DeleteCategoryInput
  >(DELETE_CATEGORY);

  const categories: Category[] = categoriesLevel1Data || [];

  const categoriesItems: CategoryGridItemType[] = categories.map(
    (category, index) => {
      return {
        category: category,
        index: index,
        key: category.id.toString(),
      };
    }
  );

  const [categoriesItemsState, setCategoriesItemsState] =
    useState<CategoryGridItemType[]>(categoriesItems);

  const [getLastAdminImportTrigger] = useLazyQuery<
    GetLastAdminImportResponse,
    GetLastAdminImportInput
  >(GET_LAST_ADMIN_IMPORT);

  const [updateCategoriesTrigger] = useMutation<
    UpdateCategoriesOrderResponse,
    UpdateCategoriesOrderInput
  >(UPDATE_CATEGORIES_ORDER);

  const lastImportedStatus = lastImport?.status ?? "N/A";
  const lastImportedStatusColor =
    lastImport?.status === ImportedFileStatusEnum.DONE
      ? "green"
      : lastImport?.status === ImportedFileStatusEnum.ERROR
      ? "red"
      : lastImport?.status === ImportedFileStatusEnum.IN_PROGRESS
      ? "#dd8500"
      : "#818181";

  const lastImportedDate = lastImport?.finishedAt
    ? formatDate(lastImport?.finishedAt)
    : lastImport?.createdAt
    ? formatDate(lastImport?.createdAt)
    : "N/A";

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const sortedCategoriesItems = moveItemInList(
      categoriesItemsState,
      result.source.index,
      result.destination.index
    );

    setCategoriesItemsState(sortedCategoriesItems);
    setUpdateCategoriesButtonDisabled(false);
  };

  const showCreateCategoryModalHandler = (category?: Category) => {
    setModalState({
      show: true,
      mode: "create",
      category: category,
    });
  };

  const showEditCategoryModalHandler = (category: Category) => {
    setModalState({
      show: true,
      mode: "edit",
      category: category,
    });
  };

  const showDeleteCategoryHandler = (
    category: Category,
    level: number,
    parentId?: number
  ) => {
    setShowDeleteModal({
      show: true,
      category: category,
      level: level,
      parentId: parentId,
    });
  };

  const hideModalHandler = () => {
    setModalState({
      show: false,
    });
  };

  const fetchCategoriesAndParentCategoriesData = async () => {
    setAllParentCategories(undefined);
    setCategoriesLevel1Data(undefined);
    const level1Response = await getAllCategoryLevel1Trigger();

    if (level1Response.data) {
      setCategoriesLevel1Data(level1Response.data.getAllLevel1Categories);
    } else {
      setFetchingErrorMessage(
        `Error fetching level 1 categories: ${level1Response.error?.message}`
      );
    }

    const allParentsResponse = await getAllParentsLevelCategoriesTrigger();

    if (allParentsResponse.data) {
      setAllParentCategories(
        allParentsResponse.data.getAllParentLevelCategories
      );
    } else if (allParentsResponse.error) {
      setFetchingErrorMessage(
        `Error fetching parents categories: ${allParentsResponse.error.message}`
      );
    }
  };

  const createOrUpdateCategorySusccefulHandler = async () => {
    await fetchCategoriesAndParentCategoriesData();
    hideModalHandler();
  };

  const submitHandler = async (formState: FormState) => {
    setErrorMessage("");

    if (!formState.name) {
      setErrorMessage("Name is required");
      return;
    }

    if (!formState.parentCategoriesIds) {
      setErrorMessage("Parent category is required");
      return;
    }

    if (!formState.level) {
      setErrorMessage("Level is required");
      return;
    }
    setIsLoading(true);

    await createOrUpdateCategoryTrigger({
      variables: {
        CreateOrUpdateCategoryInput: formState,
      },
      onCompleted: () => {
        createOrUpdateCategorySusccefulHandler();
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
    setIsLoading(false);
  };

  const exportCategories = () => {
    if (!categoriesLevel1Data) return;
    setIsPreparingFile(true);
    const allCategories = getAllNestedCategories(categoriesLevel1Data);

    const categoriesToExport = allCategories.map(
      ({ id, level, name, associatedNames, commissionPercentage, order }) => ({
        id: id,
        level: level,
        name: name,
        associated_names: associatedNames.join(", "),
        commission: commissionPercentage,
        order,
      })
    );

    // create file using xlsx
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(categoriesToExport);

    // make cells wrap text
    utils.book_append_sheet(wb, ws, "Categories");
    setIsPreparingFile(false);
    writeFile(wb, "Categories.xlsx");
  };

  const getLastAdminImportHandler = async () => {
    const response = await getLastAdminImportTrigger({
      variables: {
        GetLastAdminImportInput: {
          type: AdminImportTypeEnum.CATEGORIES,
        },
      },
    });
    setLastImport(response.data?.getLastAdminImport);
  };

  const updateCategoriesOrderHandler = () => {
    const mappedCategories = mapGridStateToCategoriesOrder(
      categoriesItemsState.map((item) => item.category)
    );
    setUpdateCategoriesOrderLoading(true);
    updateCategoriesTrigger({
      variables: {
        UpdateCategoriesOrderInput: {
          categories: mappedCategories,
        },
      },
      onCompleted: () => {
        setUpdateCategoriesButtonDisabled(true);
        setUpdateCategoriesOrderLoading(false);
      },
      onError: (error) => {
        alert(error.message);
        setUpdateCategoriesOrderLoading(false);
      },
    });
  };

  useEffect(() => {
    setCategoriesItemsState(categoriesItems);
  }, [categoriesLevel1Data]);

  useEffect(() => {
    getLastAdminImportHandler();
    fetchCategoriesAndParentCategoriesData();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getLastAdminImportHandler();
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const onDeleteConfirm = async () => {
    if (!showDeleteModal.category || !showDeleteModal.level) return;

    await deleteCategoryTrigger({
      variables: {
        DeleteCategoryInput: {
          id: showDeleteModal.category.id,
          level: showDeleteModal.level,
          parentId: showDeleteModal.parentId,
        },
      },
      onCompleted: () => {
        fetchCategoriesAndParentCategoriesData();
        setShowDeleteModal({
          show: false,
        });
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  return (
    <>
      <LoadingPopup show={isPreparingFile} message="Preparing file..." />
      <div className={styles.container}>
        {!!fetchingErrorMessage ? (
          <div className={styles.error}>
            <p>{fetchingErrorMessage}</p>
          </div>
        ) : !categoriesLevel1Data || !allParentCategories ? (
          <div className={styles.loadingContainer}>
            <SimpleLoader size="size3" fill={colors.$primary} />
          </div>
        ) : (
          <>
            <UploadFileModal<
              ProcessAdminImportResponse,
              ProcessAdminImportInput
            >
              graphqlDocument={PROCESS_ADMIN_IMPORT}
              fileFields={{ field: "category", entity: "admin-imports" }}
              graphqlVariables={{
                ProcessAdminImportInput: {
                  type: AdminImportTypeEnum.CATEGORIES,
                  filePath: "",
                },
              }}
              hideModalHandler={() => {
                setShowUploadFileModal(false);
              }}
              show={showUploadFileModal}
              successSetStateHandler={(data) => {
                setLastImport(data.processAdminImport);
              }}
              acceptedColumns={[
                {
                  column: "id",
                  required: true,
                },
                {
                  column: "name",
                  required: false,
                },
                {
                  column: "associated_names",
                  required: false,
                },
                {
                  column: "commission",
                  required: false,
                },
                {
                  column: "order",
                  required: false,
                },
              ]}
            />
            <CustomModal
              onCancel={() => {
                hideModalHandler();
              }}
              show={modalState.show}
            >
              <CreateCategoryModal
                isLoading={isLoading}
                category={modalState.category}
                submitHandler={(formState) => {
                  submitHandler(formState);
                }}
                mode={modalState.mode}
                parentCategories={allParentCategories}
                errorMessage={errorMessage}
              />
            </CustomModal>
            <ConfirmModal
              headerText="Supprimer une catégorie"
              messageText="Êtes-vous sûr de vouloir supprimer cette catégorie ?"
              onConfirm={() => {
                onDeleteConfirm();
              }}
              onExit={() => {
                setShowDeleteModal({
                  show: false,
                });
                setErrorMessage("");
              }}
              isLoading={deleteLoading}
              show={showDeleteModal.show}
              errorText={errorMessage}
            />

            <div className={styles.headerContainer}>
              <div className={styles.headerAndButton}>
                <h2 className={styles.header}>Taxonomie</h2>{" "}
                <SVGContainer
                  height="18px"
                  width="18px"
                  imagePath="/assets/add-button.svg"
                  onClick={() => {
                    showCreateCategoryModalHandler();
                  }}
                />
                <CustomButton
                  backgroundColor={colors.$primary}
                  borderRadius="5px"
                  color="white"
                  height="1.6rem"
                  padding="0.5rem 0.5rem"
                  onClick={() => {
                    updateCategoriesOrderHandler();
                  }}
                  disabled={
                    updateCategoriesButtonDisabled ||
                    updateCategoriesOrderLoading
                  }
                  width="9rem"
                >
                  {updateCategoriesOrderLoading ? (
                    <SimpleLoader size="size1" fill="white" />
                  ) : (
                    "Update order"
                  )}
                </CustomButton>
              </div>
              <div className={styles.importDetails}>
                <div className={styles.dataButtonsContainer}>
                  <CustomButton
                    borderColor={colors.$primaryDark}
                    borderRadius="5px"
                    color={colors.$primaryDark}
                    height="2rem"
                    padding="0.5rem 1rem"
                    onClick={exportCategories}
                  >
                    <span className={styles.buttonWithIconContent}>
                      <SVGContainer
                        height="18px"
                        width="18px"
                        imagePath="/assets/download-icon.svg"
                      />
                      <span>Download</span>
                    </span>
                  </CustomButton>
                  <CustomButton
                    backgroundColor={colors.$primary}
                    borderRadius="5px"
                    color="white"
                    height="2rem"
                    padding="0.5rem 1rem"
                    onClick={() => {
                      setShowUploadFileModal(true);
                    }}
                  >
                    Upload
                  </CustomButton>
                </div>
                <div className={styles.lastImportContainer}>
                  <p>Last import: {lastImportedDate}</p>{" "}
                  <StatusLabel
                    label={lastImportedStatus}
                    backgroundColor={lastImportedStatusColor}
                    color="white"
                  />
                </div>
              </div>
            </div>
            <div className={styles.categoriesContainer}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                  droppableId="droppable"
                  isDropDisabled={updateCategoriesOrderLoading}
                >
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {categoriesItemsState.map((categoryItem, index) => (
                        <Draggable
                          key={categoryItem.key}
                          draggableId={categoryItem.key}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              key={categoryItem.key}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <CategoryToggle
                                category={categoryItem.category}
                                key={categoryItem.category.id}
                                dragHandleProps={provided.dragHandleProps}
                                addButtonClickHandler={
                                  showCreateCategoryModalHandler
                                }
                                editButtonClickHandler={
                                  showEditCategoryModalHandler
                                }
                                categoriesItemsState={categoriesItemsState}
                                setCategoriesItemsState={
                                  setCategoriesItemsState
                                }
                                parentCategoryId={categoryItem.category.id}
                                setUpdateCategoriesButtonDisabled={
                                  setUpdateCategoriesButtonDisabled
                                }
                                isDropDisabled={updateCategoriesOrderLoading}
                                deleteButtonClickHandler={
                                  showDeleteCategoryHandler
                                }
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </>
        )}
      </div>
    </>
  );
};
