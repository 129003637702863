import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { FormGroup } from "@mui/material";
import { useState } from "react";

import { CustomButton } from "~/components/form/CustomButton";
import CustomCheckbox from "~/components/form/CustomCheckbox";
import { colors } from "~/constants/styles";
import { Product, VariantsChecklist } from "~/types/data/Product.types";

import { fieldToNameMap } from "../util/fieldToNameMap";
import styles from "./index.module.scss";

interface Props {
  filledFields: (keyof Product)[];
  createNewVariant: (variantsChecklist: VariantsChecklist) => void;
}

export const VariantsOptionsChecklist = ({
  createNewVariant,
  filledFields,
}: Props) => {
  const [variantsChecklist, setVariantsChecklist] = useState<VariantsChecklist>(
    {}
  );

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const checklistField: keyof VariantsChecklist = event.target
      .id as keyof VariantsChecklist;

    setVariantsChecklist((prev) => {
      return { ...prev, [checklistField]: checked };
    });
  };

  const newVariantSubmitHandler = () => {
    createNewVariant(variantsChecklist);
  };

  const columnsNumber = Math.ceil(filledFields.length / 7);

  const columnElements: ReactJSXElement[] = [];
  for (let i = 0; i < columnsNumber; i++) {
    const formGroupElement: ReactJSXElement[] = [];

    for (let j = 0; j < 7; j++) {
      const field = filledFields[j + 7 * i];
      if (!field) break;
      const name = fieldToNameMap[field];
      const checkboxElement = (
        <CustomCheckbox
          label={name}
          checked={
            variantsChecklist[field] === undefined
              ? true
              : variantsChecklist[field]
          }
          onChange={changeHandler}
          id={field}
          key={field}
        />
      );
      formGroupElement.push(checkboxElement);
    }

    columnElements.push(<FormGroup key={i}>{formGroupElement}</FormGroup>);
  }

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.header}`}>
        <h2>Ajouter une variante</h2>
      </div>
      <div className={`${styles.subheader}`}>
        <p>Décochez les attributs que vous ne souhaitez pas dupliquer :</p>
      </div>
      <div className={`${styles.checklist}`}>{columnElements}</div>
      <div className={`${styles.button}`}>
        <CustomButton
          width="max-content"
          padding="20px"
          backgroundColor={colors.$primary}
          color="white"
          borderRadius="7px"
          onClick={newVariantSubmitHandler}
        >
          Valider
        </CustomButton>
      </div>
    </div>
  );
};
