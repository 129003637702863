import { ApolloError, useLazyQuery, useQuery } from "@apollo/client";
import { debounce } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { utils, writeFile } from "xlsx";

import { GET_COMPANIES, GetCompaniesResponse } from "~/api/graphql/company";
import {
  ADMIN_DELETE_ALL_USERS,
  ADMIN_DELETE_USERS,
  AdminDeleteAllUsersResponse,
  AdminDeleteUsersInput,
  AdminDeleteUsersResponse,
  GET_ADMIN_USERS,
  GET_ADMIN_USERS_COUNT,
  GetAdminUsersCountResponse,
  GetAdminUsersInput,
  GetAdminUsersResponse,
} from "~/api/graphql/user";
import { CustomButton } from "~/components/form/CustomButton";
import { InputWithIcon } from "~/components/form/InputWithIcon";
import { MultipleSelectInput } from "~/components/form/MultipleSelectInput";
import { OptionType } from "~/components/form/SelectInput";
import { ErrorPopup } from "~/components/UI/ErrorPopup";
import FullPageLoader from "~/components/UI/FullPageLoader";
import { LoadingPopup } from "~/components/UI/LoadingPopup";
import { SideModal } from "~/components/UI/SideModal";
import SVGContainer from "~/components/UI/SVGContainer";
import { colors } from "~/constants/styles";
import { useUserContext } from "~/context/userContext";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import { routePaths } from "~/navigation/routes";
import { useAppDispatch, useAppSelector } from "~/redux/hooks";
import {
  onFilterArgsChange,
  selectAdminUsersFilterArgs,
} from "~/redux/slice/adminUsers.slice";
import { RoleEnum, User } from "~/types/data/User.types";

import { ConfirmModal } from "../SynchronizationOfStreams/myConnectors/ConfirmModal";
import styles from "./index.module.scss";
import { UsersTable } from "./UsersTable";
import { getFilterCount } from "./util/getFilterCount";

export const AdminUtilisateurs = () => {
  // states
  const [fetchedUsers, setFetchedUsers] = useState<User[]>();
  const [hasMoreData, setHasMoreData] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [searchBox, setSearchBox] = useState<string>();
  const [isPreparingFile, setIsPreparingFile] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState("");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterCount, setFilterCount] = useState<number>(0);
  const [usersCount, setUsersCount] = useState<number>(0);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  // hooks
  const { userState } = useUserContext();
  const filterArgsState = useAppSelector(selectAdminUsersFilterArgs);
  const dispatch = useAppDispatch();

  const isAdmin = !!userState?.connected && userState?.role === RoleEnum.ADMIN;

  const { userDetailsRoute } = routePaths;

  const navigate = useNavigate();

  const { data: companiesData, error: companiesError } =
    useQuery<GetCompaniesResponse>(GET_COMPANIES, {
      skip: !isAdmin,
    });

  const companiesOptions: OptionType[] =
    companiesData?.getCompanies.map(({ id, storeName }) => ({
      value: `${id}`,
      label: storeName,
    })) || [];

  // handler functions
  const showFilterModalHandler = () => {
    setShowFilterModal(true);
  };

  const hideFilterModalHandler = () => {
    setShowFilterModal(false);
  };

  const openAddUserPageHandler = () => {
    navigate(`/${userDetailsRoute}`, { replace: false });
  };

  const openEditUser = (userId: number) => {
    navigate(`/${userDetailsRoute}/${userId}`, {
      replace: false,
    });
  };

  const searchBoxChangeHandler = useCallback(
    debounce((value: string) => {
      dispatch(
        onFilterArgsChange({
          filterArg: "searchBox",
          searchBox: value,
        })
      );
    }, 500),
    []
  );

  const companiesFilterChangeHandler = (selectedOptions: OptionType[]) => {
    dispatch(
      onFilterArgsChange({
        filterArg: "companiesIds",
        companiesIds: selectedOptions.map(({ value }) => +value),
      })
    );
  };

  const checkboxClickHandler = (inputUser: User) => {
    const selectedCheckboxIndex = selectedUsers.findIndex(
      ({ id }) => inputUser.id === id
    );
    if (selectedCheckboxIndex > -1) {
      setIsSelectedAll(false);
      setSelectedUsers((prev) => {
        return prev.filter(({ id }) => inputUser.id !== id);
      });
      return;
    }
    setSelectedUsers((prev) => {
      return [...prev, inputUser];
    });
  };

  const selectAllChangeHandler = (selected: boolean) => {
    if (selected) {
      setIsSelectedAll(true);
      setSelectedUsers(fetchedUsers || []);
      return;
    }
    setIsSelectedAll(false);
    setSelectedUsers([]);
  };

  const [getAdminUsersCountTrigger] = useLazyQuery<
    GetAdminUsersCountResponse,
    GetAdminUsersInput
  >(GET_ADMIN_USERS_COUNT);

  const [getAdminUsersTrigger, { loading: getAdminUsersLoading }] =
    useLazyQuery<GetAdminUsersResponse, GetAdminUsersInput>(GET_ADMIN_USERS);

  const exportUsers = async () => {
    const usersToExport: User[] = [];
    if (isSelectedAll) {
      const { data, error } = await getAdminUsersTrigger({
        variables: {
          GetAdminUsersInput: {
            ...filterArgsState,
            cursor: undefined,
            take: undefined,
          },
        },
      });
      if (data) {
        usersToExport.push(...data.getAdminUsers.users);
      } else if (error) {
        const { message } = error;
        setErrorModalMessage(message);
        return;
      }
    } else {
      usersToExport.push(...selectedUsers);
    }

    const mappedUsersToExport = usersToExport.map(
      ({
        id,
        createdAt,
        email,
        username,
        preferredUsername,
        companyName,
        companyId,
        role,
        status,
        phoneNumber,
        permissionLevel,
        provider,
      }) => ({
        id,
        createdAt,
        email,
        username,
        preferredUsername,
        companyName,
        companyId,
        role,
        status,
        phoneNumber,
        permissionLevel,
        provider,
      })
    );

    // create file using xlsx
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(mappedUsersToExport);

    // make cells wrap text
    utils.book_append_sheet(wb, ws, "Users");
    setIsPreparingFile(false);
    writeFile(wb, "Users.xlsx");
  };

  const exportUsersHandler = () => {
    setIsPreparingFile(true);
    setTimeout(() => {
      exportUsers();
    }, 500);
  };

  const fetchUsers = async (init: boolean) => {
    if (getAdminUsersLoading) return;

    const { companiesIds, ...filtersData } = filterArgsState;

    if (init) {
      setFetchedUsers(undefined);
      dispatch(
        onFilterArgsChange({
          filterArg: "cursor",
          cursor: undefined,
        })
      );
      setSelectedUsers([]);
      setIsSelectedAll(false);

      const { data: getAdminUsersCountData } = await getAdminUsersCountTrigger({
        variables: {
          GetAdminUsersInput: {
            ...filtersData,
            cursor: undefined,
            ...(isAdmin && { companiesIds }),
          },
        },
      });

      if (getAdminUsersCountData) {
        setUsersCount(getAdminUsersCountData.getAdminUsersCount.count);
      }
    }

    const { data, error } = await getAdminUsersTrigger({
      variables: {
        GetAdminUsersInput: {
          ...filtersData,
          ...(init && { cursor: undefined }),
          ...(isAdmin && { companiesIds }),
        },
      },
    });

    if (data) {
      const { getAdminUsers } = data;
      const { hasMoreData, nextCursor, users } = getAdminUsers;
      setHasMoreData(hasMoreData);
      dispatch(onFilterArgsChange({ filterArg: "cursor", cursor: nextCursor }));

      if (isSelectedAll) {
        setSelectedUsers((prevSelectedUsers) => [
          ...prevSelectedUsers,
          ...users,
        ]);
      }

      setFetchedUsers((prevFetchedUsers) =>
        prevFetchedUsers ? [...prevFetchedUsers, ...users] : [...users]
      );
    } else if (error) {
      const { message } = error;
      setErrorModalMessage(message);
    }
  };

  const deleteUserSuccessHandler = () => {
    fetchUsers(true);
  };

  const deleteUserErrorHandler = (error: ApolloError) => {
    const { message } = error;
    setErrorModalMessage(message);
  };

  const hideErrorPopupHandler = () => {
    setErrorModalMessage("");
    fetchUsers(true);
  };

  const { trigger: deleteUsersTrigger } = useMutationWithCallbacks<
    AdminDeleteUsersResponse,
    AdminDeleteUsersInput
  >(ADMIN_DELETE_USERS, deleteUserSuccessHandler, deleteUserErrorHandler);

  const { trigger: deleteAllUsersTrigger } = useMutationWithCallbacks<
    AdminDeleteAllUsersResponse,
    GetAdminUsersInput
  >(ADMIN_DELETE_ALL_USERS, deleteUserSuccessHandler, deleteUserErrorHandler);

  const deleteProducts = async () => {
    setFetchedUsers(undefined);
    if (isSelectedAll) {
      await deleteAllUsersTrigger({
        variables: {
          GetAdminUsersInput: {
            ...filterArgsState,
            cursor: undefined,
            take: undefined,
          },
        },
      });
      return;
    }

    await deleteUsersTrigger({
      variables: {
        AdminDeleteUsersInput: {
          usersIds: selectedUsers.map(({ id }) => id || 0),
        },
      },
    });
  };

  const deleteProductsHandler = () => {
    deleteProducts();
  };

  const openConfirmDeleteUsersModalHandler = () => {
    setShowConfirmDeleteModal(true);
  };

  const closeConfirmDeleteUsersModalHandler = () => {
    setShowConfirmDeleteModal(false);
  };

  const clearSelectedUsersHandler = () => {
    setIsSelectedAll(false);
    setSelectedUsers([]);
  };

  const clearAllFiltersHandler = () => {
    dispatch(
      onFilterArgsChange({
        filterArg: "clearAll",
      })
    );
  };

  useEffect(() => {
    if (!companiesData) return;

    const count = getFilterCount(filterArgsState);
    setFilterCount(count);

    fetchUsers(true);
  }, [
    filterArgsState.orderBy,
    filterArgsState.createdAtFrom,
    filterArgsState.createdAtTo,
    filterArgsState.updatedAtFrom,
    filterArgsState.updatedAtTo,
    filterArgsState.searchBox,
    filterArgsState.companiesIds,
    companiesData,
  ]);

  useEffect(() => {
    setSearchBox(filterArgsState.searchBox);
  }, [filterArgsState.searchBox]);

  return (
    <>
      <LoadingPopup show={isPreparingFile} message="Preparing file..." />
      <ErrorPopup
        onCancel={hideErrorPopupHandler}
        show={!!errorModalMessage}
        message={errorModalMessage}
      />
      <SideModal onCancel={hideFilterModalHandler} show={showFilterModal}>
        {/* <CataloguesFilterForm
          brandValues={brandData?.getAttribute?.values || []}
          vendorCategories={vendorCategoriesData?.getVendorCategories || []}
        /> */}
      </SideModal>
      <ConfirmModal
        show={showConfirmDeleteModal}
        onCancel={closeConfirmDeleteUsersModalHandler}
        onConfirm={() => {
          closeConfirmDeleteUsersModalHandler();
          deleteProductsHandler();
        }}
        text="Êtes-vous sûr de vouloir supprimer ces utilisateurs?"
      />
      <div className={styles.container}>
        {companiesError ? (
          <div>{`Companies Error: ${companiesError.message}`}</div>
        ) : !companiesData ? (
          <FullPageLoader />
        ) : (
          <>
            <div className={`${styles.upperBar}`}>
              <div className={`${styles.leftColumn}`}>
                <InputWithIcon
                  onChange={(value: string | number) => {
                    setSearchBox(value.toString());
                    searchBoxChangeHandler(value.toString());
                  }}
                  value={searchBox}
                  borderRadius="5px"
                  noBorder={true}
                  backgroundColor="white"
                  iconPath="/assets/search-icon.svg"
                  placeholder="Chercher un utilisateur..."
                />
                <div className={styles.countContainer}>
                  {!!usersCount && !!fetchedUsers?.length && (
                    <p>
                      Fetched {fetchedUsers?.length || 0}/{usersCount}
                    </p>
                  )}
                </div>
              </div>
              <div className={`${styles.rightColumn}`}>
                <CustomButton
                  backgroundColor={colors.$primary}
                  color="white"
                  width="fit-content"
                  borderRadius="8px"
                  padding="1rem 1.5rem"
                  onClick={openAddUserPageHandler}
                >
                  Ajouter un utilisateur
                </CustomButton>
              </div>
            </div>
            <div className={`${styles.toolbar}`}>
              <div className={styles.selectionSection}>
                <div className={`${styles.selectedCount}`}>
                  <p>
                    {isSelectedAll ? usersCount : selectedUsers.length} users
                    sélectionné{selectedUsers.length > 1 ? "s" : ""}
                  </p>
                  {selectedUsers.length > 0 && (
                    <div
                      className={`${styles.clearSelectionButton}`}
                      onClick={() => {
                        clearSelectedUsersHandler();
                      }}
                    >
                      <SVGContainer
                        height="16px"
                        width="17px"
                        imagePath="/assets/delete-icon.svg"
                      />
                      <p>Supprimer la sélection</p>
                    </div>
                  )}
                </div>
                {selectedUsers.length > 0 && (
                  <div className={`${styles.controlButtonsContainer}`}>
                    <>
                      <div
                        className={`${styles.buttonContainer}`}
                        onClick={exportUsersHandler}
                      >
                        <SVGContainer
                          height="16px"
                          width="17px"
                          imagePath="/assets/export-icon.svg"
                        />
                        <p>Exporter</p>
                      </div>
                      <div
                        className={`${styles.buttonContainer}`}
                        onClick={openConfirmDeleteUsersModalHandler}
                      >
                        <SVGContainer
                          height="16px"
                          width="17px"
                          imagePath="/assets/delete-icon.svg"
                        />
                        <p>supprimer</p>
                      </div>
                    </>
                  </div>
                )}
              </div>
              <div className={`${styles.filtersContainer}`}>
                {isAdmin && (
                  <div className={`${styles.selectContainer}`}>
                    <MultipleSelectInput
                      fontSize="12px"
                      backgroundColor="white"
                      options={companiesOptions}
                      onChange={(options: OptionType[]) => {
                        companiesFilterChangeHandler(options);
                      }}
                      value={companiesOptions.filter((option) =>
                        filterArgsState.companiesIds?.includes(+option.value)
                      )}
                    />
                  </div>
                )}
                {!!filterCount && (
                  <>
                    <div
                      className={styles.clearFilterButton}
                      onClick={clearAllFiltersHandler}
                    >
                      <p className={`${styles.label}`}>
                        Effacer tous les filtres
                      </p>
                    </div>
                    <div className={styles.filterCount}>
                      <p>{filterCount} filtres sélectionnés</p>
                    </div>
                  </>
                )}
                <div
                  className={`${styles.filterButtonContainer}`}
                  onClick={showFilterModalHandler}
                >
                  <SVGContainer
                    height="16px"
                    width="17px"
                    imagePath="/assets/filter-icon.svg"
                  />
                  <p>Filtrer les produits</p>
                </div>
              </div>
            </div>
            <UsersTable
              users={fetchedUsers}
              selectedRows={selectedUsers}
              isSelectedAll={isSelectedAll}
              onCheckboxChange={checkboxClickHandler}
              onSelectAllChange={selectAllChangeHandler}
              fetchMoreData={() => {
                fetchUsers(false);
              }}
              hasMoreData={hasMoreData}
              onRowClick={openEditUser}
            />
          </>
        )}
      </div>
    </>
  );
};
