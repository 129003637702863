import { sendRawRequest } from "~/api/clients/axios";
import { ApiLoginResponse } from "~/types/common/auth.types";
import { AxiosHttpError } from "~/types/common/error.types";
import { SetNewPasswordInput } from "~/types/input/auth.types";

export default async function setNewPassword(input: SetNewPasswordInput) {
  try {
    const { data } = await sendRawRequest<
      SetNewPasswordInput,
      ApiLoginResponse
    >("post", "/auth/set-new-password", input);

    if (data.ChallengeName === undefined) {
      return {
        success: true,
        action: "PASSWORD_CHANGED",
        result: data.AuthenticationResult,
      };
    }
  } catch (err) {
    const axiosError = err as AxiosHttpError;
    if (axiosError.response?.data) {
      const { message } = axiosError.response.data;
      return {
        success: false,
        action: typeof message === "string" ? message : message[0],
      };
    }
  }
  return {
    success: false,
    action: "Unkown Error",
  };
}
