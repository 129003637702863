import { ApolloError, DocumentNode, useMutation } from "@apollo/client";
import { useState } from "react";

import { getMediaUploadPath } from "~/api/graphql/media/getMediaUploadPath";
import { CustomButton } from "~/components/form/CustomButton";
import { DragAndDropFilePicker } from "~/components/form/DragAndDropFilePicker";
import CustomModal from "~/components/UI/CustomModal";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";

import styles from "./index.module.scss";

interface Props<R extends object, I = undefined> {
  show: boolean;
  hideModalHandler: () => void;
  graphqlDocument: DocumentNode;
  successSetStateHandler: (data: R) => void;
  graphqlVariables: I;
  fileFields: {
    entity: string;
    field: string;
  };
  acceptedColumns: {
    column: string;
    required: boolean;
  }[];
  associatedId?: number;
}

export const UploadFileModal = <R extends object, I extends object>({
  show,
  hideModalHandler,
  graphqlDocument,
  successSetStateHandler,
  graphqlVariables,
  fileFields,
  acceptedColumns,
  associatedId,
}: Props<R, I>) => {
  const [file, setFile] = useState<File>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [trigger] = useMutation<R, I & { filePath: string }>(graphqlDocument);

  const fileChangeHandler = (file: File | undefined) => {
    setFile(file);
  };

  const onCompleted = (data: R) => {
    successSetStateHandler(data);
    hideModalHandler();
  };

  const onError = (error: ApolloError) => {
    setErrorMessage(error.message);
  };

  const submitHandler = async () => {
    setIsLoading(true);
    setErrorMessage("");
    if (!file) {
      setErrorMessage("Please select a file");
      setIsLoading(true);
      return;
    }
    const fileResponse = await getMediaUploadPath({
      entity: fileFields.entity,
      field: fileFields.field,
      filename: file.name,
    });

    let filePath = "";

    if (fileResponse.success) {
      const { url, path } = fileResponse.data;
      await fetch(url, { method: "put", body: file });
      filePath = path;
    } else {
      setErrorMessage("Uploading didn't work");
      setIsLoading(false);
      return;
    }

    const graphqlVariablesKeys = Object.keys(graphqlVariables);
    const firstKey = graphqlVariablesKeys[0] as keyof I;

    await trigger({
      variables: {
        ...graphqlVariables,
        [firstKey]: { ...graphqlVariables[firstKey], filePath },
      } as unknown as I & { filePath: string },
      onCompleted,
      onError,
    });

    setIsLoading(false);
  };

  const acceptedColumnsString = acceptedColumns.reduce((acc, curr, index) => {
    const columnName = curr.required
      ? curr.column
      : `${curr.column} (optional)`;

    return index === 0 ? acc + columnName : acc + ", " + columnName;
  }, "");

  return (
    <CustomModal
      show={show}
      disableCancel={isLoading}
      onCancel={hideModalHandler}
    >
      <div className={styles.container}>
        <h2>Upload file {associatedId}</h2>
        <ul className={styles.list}>
          <li>
            <span className={styles.boldText}>Accepted file formats: </span>
            CSV or XLSX
          </li>
          <li>
            <span className={styles.boldText}>Accepted columns: </span>
            {acceptedColumnsString}
          </li>
        </ul>

        <div className={styles.filePickerContainer}>
          <DragAndDropFilePicker
            id="file"
            onChange={fileChangeHandler}
            value={file?.name}
            height="14rem"
          />
          <div className={styles.errorMessageContainer}>
            <p>{errorMessage}</p>
          </div>
          <div className={styles.submitButtonContainer}>
            <CustomButton
              type="submit"
              backgroundColor={colors.$primary}
              color="white"
              borderRadius="7px"
              width="fit-content"
              padding="0.5rem 2rem"
              disabled={!file || isLoading}
              onClick={() => {
                submitHandler();
              }}
            >
              {isLoading ? <SimpleLoader size="size2" /> : "Submit"}
            </CustomButton>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};
