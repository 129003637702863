import Joi from "joi";

import { UpdateCompanyIdentificationInput } from "~/types/data/Company.types";

export const updateIdentificationValidationSchema =
  Joi.object<UpdateCompanyIdentificationInput>({
    id: Joi.optional(),
    storeName: Joi.string()
      .required()
      .error(new Error("Store name can't be empty.")),
    companyDescription: Joi.string()
      .required()
      .error(new Error("Company Description can't be empty.")),
    logo: Joi.string().required().error(new Error("Logo can't be empty.")),
    shippingCountry: Joi.string()
      .required()
      .error(new Error("Pays d'expedition can't be empty.")),
    returnPolicyDescription: Joi.string()
      .required()
      .error(new Error("Return policy can't be empty.")),
  });
