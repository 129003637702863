import { OrderByEnum } from "../common/filter.types";
import { Company } from "./Company.types";
import { CustomMessageTypeEnum } from "./Message.types";
import { OrderStatusEnum, ProductOrderStatusEnum } from "./Order.types";
import { Profile } from "./Profile.types";

export enum OrderHistoryRoleEnum {
  REEDOO = "REEDOO",
  SELLER = "SELLER",
  BUYER = "BUYER",
}

export interface OrderHisory {
  id: number;
  createdAt: string;
  title: string;
  description: string;
  status?: string;
  createdByRole: OrderHistoryRoleEnum;
  createdByProfile?: Profile;
  createdByCompany?: Company;
}

export interface OrderHistoryFilterState {
  searchBox: string;
  role?: OrderHistoryRoleEnum;
  order?: OrderByEnum;
}

export type OrderHistoryStatusMapping = Record<
  ProductOrderStatusEnum | OrderStatusEnum | CustomMessageTypeEnum | string,
  string
>;
