import { Cell, Pie, PieChart } from "recharts";

import styles from "./styles.module.scss";
export interface PieChartData {
  name: string;
  value: number;
  color: string;
}

interface Props {
  data: PieChartData[];
}

export const CustomPieChart = ({ data }: Props) => {
  return (
    <div className={styles.pie_chart_container}>
      <PieChart width={200} height={300}>
        <Pie
          data={data}
          cx={100}
          cy={150}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          isAnimationActive={false}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
};
