import { gql } from "@apollo/client";

import {
  graphqlGetDeliveryAddressFields,
  graphqlGetProductFields,
  graphqlGetProfileFields,
  graphqlGetRentOrderFields,
  graphqlGetRentOrderToProductFileds,
  graphqlGetSingleRentOrderToProduct,
} from "~/constants/graphql";
import { RentOrdersFilterArgs } from "~/redux/slice/rentOrdersFilter.slice";
import {
  CompanyUpdateRentSingleProductStatusEnum,
  RentOrder,
} from "~/types/data/RentOrder.type";

// response
export type GetCompanyRentOrdersResponse = {
  getCompanyRentOrders: {
    rentOrders: RentOrder[];
    nextCursor: number;
    hasMoreData: boolean;
  };
};

export interface GetCompanyRentOrdersCountResponse {
  getCompanyRentOrdersCount: { count: number };
}

export type GetCompanyRentOrderByIdResponse = {
  getCompanyRentOrderById: RentOrder;
};

export type CompanyReviewSingleProductsResponse = {
  companyReviewSingleProducts: RentOrder;
};

export type InitiateRecurringPaymentResponse = {
  initiateRecurringPayment: RentOrder;
};

// input
export type GetCompanyRentOrdersInput = {
  GetCompanyRentOrdersInput: RentOrdersFilterArgs;
};

export type GetCompanyRentOrderByIdInput = {
  GetCompanyRentOrderByIdInput: {
    orderId: number;
  };
};

export type CompanyReviewSingleProductsInput = {
  CompanyReviewSingleProductsInput: {
    rentOrderId: number;
    rentOrderToProductId: number;
    quantity?: number;
    status?: CompanyUpdateRentSingleProductStatusEnum;
    damageFees?: number;
  };
};

export type InitiateRecurringPaymentInput = {
  InitiateRecurringPaymentInput: {
    rentOrderId: number;
  };
};

// select fields
const rentOrderFields = graphqlGetRentOrderFields.join(" ");
const orderToProductFields = graphqlGetRentOrderToProductFileds.join(" ");
const singleOrderToProductFields = graphqlGetSingleRentOrderToProduct.join(" ");
const productFields = graphqlGetProductFields.join(" ");
const profileFields = graphqlGetProfileFields.join(" ");
const deliveryAddressFields = graphqlGetDeliveryAddressFields.join(" ");

const allRentOrderFields = `
  ${rentOrderFields}

  initialDepositAmount
  leftDepositAmount
  nextBillingDate

  orderToProducts {
    ${orderToProductFields}

    product{
      ${productFields}

      image1FullPath
    }

    singleProducts {
      ${singleOrderToProductFields}

      currentPurchasePrice
      totalPurchasedAmount
    }
  }

  buyer {
    ${profileFields}
    phoneNumber
    email
  }

  shippingAddress {
    ${deliveryAddressFields}
  }

  billingAddress {
    ${deliveryAddressFields}
  }
`;

// queries and mutations
export const GET_COMPANY_RENT_ORDERS = gql`
  query ($GetCompanyRentOrdersInput: GetCompanyRentOrdersInput!) {
    getCompanyRentOrders(GetCompanyRentOrdersInput: $GetCompanyRentOrdersInput) {
      rentOrders{
        ${rentOrderFields}

        orderToProducts {
          ${orderToProductFields}

          product{
            ${productFields}
          }
        }

        buyer{
          ${profileFields}
          phoneNumber
          email
        }
      }

      nextCursor
      hasMoreData

    }
  }
`;

export const GET_COMPANY_RENT_ORDERS_COUNT = gql`
  query ($GetCompanyRentOrdersInput: GetCompanyRentOrdersInput!) {
    getCompanyRentOrdersCount(
      GetCompanyRentOrdersInput: $GetCompanyRentOrdersInput
    ) {
      count
    }
  }
`;

export const GET_COMPANY_RENT_ORDER_BY_ID = gql`
  query ($GetCompanyRentOrderByIdInput: GetCompanyRentOrderByIdInput!) {
    getCompanyRentOrderById(GetCompanyRentOrderByIdInput: $GetCompanyRentOrderByIdInput) {
      ${rentOrderFields}

        initialDepositAmount
        leftDepositAmount
        nextBillingDate

        orderToProducts {
          ${orderToProductFields}

          product{
            ${productFields}

            image1FullPath
          }

          singleProducts {
            ${singleOrderToProductFields}
            
            currentPurchasePrice
            totalPurchasedAmount
          }
        }

        buyer {
          ${profileFields}
          phoneNumber
          email
        }

        shippingAddress {
          ${deliveryAddressFields}
        }

        billingAddress {
          ${deliveryAddressFields}
        }
    }
  }
`;

export const COMPANY_REVIEW_SINGLE_PRODUCTS = gql`
mutation ($CompanyReviewSingleProductsInput: CompanyReviewSingleProductsInput!) {
    companyReviewSingleProducts(CompanyReviewSingleProductsInput: $CompanyReviewSingleProductsInput) {
      ${allRentOrderFields}
    }
  }
`;

export const INITIATE_RECURRING_PAYMENT = gql`
  mutation ($InitiateRecurringPaymentInput: InitiateRecurringPaymentInput!) {
    initiateRecurringPayment(InitiateRecurringPaymentInput: $InitiateRecurringPaymentInput) {
      ${allRentOrderFields}
    }
  }
`;
