import { Product } from "~/types/data/Product.types";
import { SubscriptionCategory } from "~/types/data/SubscriptionPackage.type";

export const getCategoriesFromProducts = (
  products: Product[],
  subscriptionCategories: SubscriptionCategory[],
  categoryLevel = 2
): SubscriptionCategory[] => {
  const resultCategories: SubscriptionCategory[] = [];

  for (const product of products) {
    const productCategory =
      categoryLevel === 3 ? product.category3 : product.category2;

    if (!productCategory) continue;

    const foundCategoryIndex = resultCategories.findIndex(
      (resultCategory) => resultCategory.categoryId === productCategory.id
    );

    if (foundCategoryIndex === -1) {
      const categoryName = productCategory.name;

      const subscriptionCategory = subscriptionCategories.find(
        (subscriptionCategory) =>
          subscriptionCategory.categoryId === productCategory.id
      );

      const categoryPriority = subscriptionCategory?.priority ?? 1;

      resultCategories.push({
        id: subscriptionCategory?.id,
        categoryId: productCategory.id,
        name: categoryName,
        priority: categoryPriority,
      });
    }
  }

  return resultCategories.sort((a, b) => a.priority - b.priority);
};
