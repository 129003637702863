import { gql } from "@apollo/client";

import { graphqlGetAdminImportFields } from "~/constants/graphql";
import {
  AdminImport,
  AdminImportTypeEnum,
} from "~/types/data/AdminImport.type";

export interface ProcessAdminImportInput {
  ProcessAdminImportInput: {
    filePath: string;
    type: AdminImportTypeEnum;
    associatedId?: number;
  };
}

export interface GetLastAdminImportInput {
  GetLastAdminImportInput: {
    type: AdminImportTypeEnum;
    associatedId?: number;
  };
}

export interface ProcessAdminImportResponse {
  processAdminImport: AdminImport;
}

export interface GetLastAdminImportResponse {
  getLastAdminImport: AdminImport;
}

const getAdminImportSelectFields = graphqlGetAdminImportFields.join(" ");

export const PROCESS_ADMIN_IMPORT = gql`
  mutation ($ProcessAdminImportInput: ProcessAdminImportInput!) {
    processAdminImport(
      ProcessAdminImportInput: $ProcessAdminImportInput
    ) {
      ${getAdminImportSelectFields}
    }
  }
`;

export const GET_LAST_ADMIN_IMPORT = gql`
  query ($GetLastAdminImportInput: GetLastAdminImportInput!) {
    getLastAdminImport(
      GetLastAdminImportInput: $GetLastAdminImportInput
    ) {
      ${getAdminImportSelectFields}
    }
  }
`;
