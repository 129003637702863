import React, { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import changePassword from "~/api/auth/changePassword";
import login from "~/api/auth/login";
import sendForgotPasswordCode from "~/api/auth/sendForgotPasswordCode";
import setNewPassword from "~/api/auth/setNewPassword";
import { getUserInfo } from "~/api/graphql/user/getUserInfo";
import { CustomButton } from "~/components/form/CustomButton";
import { CustomInput } from "~/components/form/CustomInput";
import FormCard from "~/components/UI/FormCard";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import SVGContainer from "~/components/UI/SVGContainer";
import { graphqlGetUserFields } from "~/constants/graphql";
import { colors } from "~/constants/styles";
import { useUserContext } from "~/context/userContext";
import { routePaths } from "~/navigation/routes";
import { changePasswordalidationSchema } from "~/util/validation/authentication/changePassword.schema";
import { loginValidationSchema } from "~/util/validation/authentication/login.schema";
import { sendForgotPasswordCodeValidationSchema } from "~/util/validation/authentication/sendForgotPasswordCode.schema";
import { setNewPasswordValidationSchema } from "~/util/validation/authentication/setNewPassword.schema";

import styles from "./index.module.scss";

interface AuthFormState {
  username: string;
  password: string;
  session: string;
  newPassword: string;
  resetCode: string;
}

const initAuthFormData = {
  username: "",
  password: "",
  session: "",
  newPassword: "",
  resetCode: "",
};

interface Props {
  mode:
    | "login"
    | "set-new-password"
    | "forgot-password"
    | "confirm-forgot-password";
}

const authForm: FunctionComponent<Props> = ({ mode }) => {
  const [authFormState, setAuthFormState] = useState<AuthFormState>({
    ...initAuthFormData,
  });
  const [isLoading, setIsLoading] = useState(false);

  const {
    dashboardRoute,
    loginRoute,
    forgetPasswordRoute,
    setNewPasswordRoute,
    confirmForgotPasswordRoute,
  } = routePaths;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { setUserState } = useUserContext();

  const [errorMessage, setErrorMessage] = useState("");

  const inputChangeHandler = <T extends keyof AuthFormState>(
    inputName: T,
    changes: AuthFormState[T]
  ) => {
    setAuthFormState((state) => ({ ...state, [inputName]: changes }));
  };

  const setUserInfo = async () => {
    const response = await getUserInfo(graphqlGetUserFields);
    if (response.success) setUserState({ ...response.data, connected: true });
  };

  const formSubmitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    if (mode === "login") {
      const result = loginValidationSchema.validate({
        username: authFormState.username,
        password: authFormState.password,
      });

      const { error } = result;
      if (error) {
        setErrorMessage(error.message);
        setIsLoading(false);
        return;
      }

      const input = {
        username: authFormState.username,
        password: authFormState.password,
      };
      const response = await login(input);
      setIsLoading(false);

      if (response.action === "LOGIN") {
        await setUserInfo();
        setAuthFormState({ ...initAuthFormData });
        navigate(`/${dashboardRoute}`, { replace: true });
      } else if (response.action === "NEW_PASSWORD_REQUIRED") {
        setAuthFormState((prevState) => {
          return {
            ...initAuthFormData,
            username: prevState.username,
            session: response.session || "",
            password: "",
          };
        });
        navigate(
          `/${setNewPasswordRoute}?session=${response.session}&username=${authFormState.username}`,
          {
            replace: true,
          }
        );
      } else {
        if (typeof response.action === "string") {
          setErrorMessage(response.action);
        }
      }
    } else if (mode === "set-new-password") {
      const username = authFormState.username.replaceAll(" ", "+");
      const result = setNewPasswordValidationSchema.validate({
        username,
        session: authFormState.session,
        newPassword: authFormState.newPassword,
      });

      const { error } = result;
      if (error) {
        setErrorMessage(error.message);
        setIsLoading(false);
        return;
      }

      const input = {
        username,
        session: authFormState.session,
        newPassword: authFormState.newPassword,
      };
      const response = await setNewPassword(input);
      setIsLoading(false);

      if (response.success) {
        setAuthFormState((prevState) => {
          return {
            ...initAuthFormData,
            username: prevState.username,
          };
        });
        navigate(`/${loginRoute}`, { replace: true });
      } else {
        if (typeof response.action === "string") {
          setErrorMessage(response.action);
        }
      }
    } else if (mode === "forgot-password") {
      const result = sendForgotPasswordCodeValidationSchema.validate({
        username: authFormState.username,
      });

      const { error } = result;
      if (error) {
        setErrorMessage(error.message);
        setIsLoading(false);
        return;
      }

      const input = {
        username: authFormState.username,
      };
      const response = await sendForgotPasswordCode(input);
      setIsLoading(false);

      if (response.success) {
        navigate(
          `/${confirmForgotPasswordRoute}?username=${authFormState.username}`,
          { replace: true }
        );
      } else {
        if (typeof response.action === "string") {
          setErrorMessage(response.action);
        }
      }
    } else if (mode === "confirm-forgot-password") {
      const username = authFormState.username.replaceAll(" ", "+");
      const result = changePasswordalidationSchema.validate({
        username,
        password: authFormState.newPassword,
        code: authFormState.resetCode,
      });

      const { error } = result;
      if (error) {
        setErrorMessage(error.message);
        setIsLoading(false);
        return;
      }

      const input = {
        username,
        code: authFormState.resetCode,
        password: authFormState.newPassword,
      };
      const response = await changePassword(input);
      setIsLoading(false);

      if (response.success) {
        setAuthFormState({ ...authFormState, resetCode: "", newPassword: "" });
        navigate(`/${loginRoute}`, { replace: true });
      } else {
        if (response.action) {
          if (typeof response.action === "string") {
            setErrorMessage(response.action);
          }
        }
      }
    }
  };

  useEffect(() => {
    const searchParamSession = searchParams.get("session");
    const searchParamUsername = searchParams.get("username");
    if (mode === "confirm-forgot-password" && !searchParamUsername) {
      navigate(`/${loginRoute}`, { replace: true });
    }

    if (
      mode === "set-new-password" &&
      !searchParamSession &&
      !searchParamUsername
    ) {
      navigate(`/${loginRoute}`, { replace: true });
    }

    setAuthFormState((prevState) => {
      return {
        ...prevState,
        session: searchParamSession || "",
        username: searchParamUsername || "",
      };
    });
  }, []);

  const buttonLabel = mode === "login" ? "Me connecter" : "Valider";

  return (
    <div className={`${styles.container}`}>
      <FormCard>
        <div className={`${styles.logo}`}>
          <SVGContainer
            height="100%"
            width="100%"
            imagePath="/assets/logo.svg"
          />
        </div>
        <form
          id="authForm"
          className={`${styles.form}`}
          onSubmit={(e) => {
            formSubmitHandler(e);
          }}
        >
          {(mode === "login" || mode === "forgot-password") && (
            <div className={`${styles.inputContainer}`}>
              <CustomInput
                value={authFormState.username}
                onChange={(value: string | number) =>
                  inputChangeHandler("username", value.toString())
                }
                type="text"
                noBorder={true}
                placeholder="E-mail"
                backgroundColor="#f2f2f2"
                borderRadius="5px"
              />
            </div>
          )}

          {mode === "login" && (
            <div className={`${styles.inputContainer}`}>
              <CustomInput
                value={authFormState.password}
                onChange={(value: string | number) =>
                  inputChangeHandler("password", value.toString())
                }
                type="password"
                noBorder={true}
                placeholder="Mot de passe"
                backgroundColor="#f2f2f2"
                borderRadius="5px"
              />
            </div>
          )}

          {mode === "confirm-forgot-password" && (
            <div className={`${styles.inputContainer}`}>
              <CustomInput
                value={authFormState.resetCode}
                onChange={(value: string | number) =>
                  inputChangeHandler("resetCode", value.toString())
                }
                type="text"
                noBorder={true}
                placeholder="Code de vérification"
                backgroundColor="#f2f2f2"
                borderRadius="5px"
              />
            </div>
          )}

          {(mode === "set-new-password" ||
            mode === "confirm-forgot-password") && (
            <div className={`${styles.inputContainer}`}>
              <CustomInput
                value={authFormState.newPassword}
                onChange={(value: string | number) =>
                  inputChangeHandler("newPassword", value.toString())
                }
                type="password"
                noBorder={true}
                placeholder="Nouveau mot de passe"
                backgroundColor="#f2f2f2"
                borderRadius="5px"
              />
            </div>
          )}

          <div className={`${styles.button}`}>
            {!!errorMessage && <p className="text-red">{errorMessage}</p>}
            {isLoading ? (
              <CustomButton
                type="submit"
                backgroundColor={colors.$primary}
                color="white"
                borderRadius="7px"
              >
                <SimpleLoader size="size1" />
              </CustomButton>
            ) : (
              <CustomButton
                type="submit"
                backgroundColor={colors.$primary}
                color="white"
                borderRadius="7px"
              >
                {buttonLabel}
              </CustomButton>
            )}
          </div>
          {mode === "login" && (
            <div
              className={`${styles.forgotPasswordLink}`}
              onClick={() => {
                setAuthFormState({
                  ...initAuthFormData,
                  username: authFormState.username,
                });
                setErrorMessage("");
                navigate(`/${forgetPasswordRoute}`, { replace: false });
              }}
            >
              <span className="cursor-pointer">Mot de passe oublié</span>
            </div>
          )}
        </form>
      </FormCard>
    </div>
  );
};

export default authForm;
