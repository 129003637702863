export interface Carrier {
  id: number;
  name: string;
  title: string;
  description: string;
  logoPath: string;
  websiteUrl: string;
  trackingUrl: string;
}

export enum DeliveryPackageSizeEnum {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export enum DeliveryMethodEnum {
  DELIVERY = "DELIVERY",
  PICKUP = "PICKUP",
}

export enum DeliveryAreaEnum {
  FRANCE = "FRANCE",
  CORSE = "CORSE",
  MONACO = "MONACO",
}

export interface CompanyCarrier {
  id?: number;
  carrierId: number;
  deliveryPackages: DeliveryPackage[];
}

export interface ReedooCarrier {
  id: number;
  carrierId: number;
  deliveryPackages: DeliveryPackage[];
}

export interface DeliveryPackage {
  id?: number;
  code: string;
  minDays: number;
  maxDays: number;
  price: number;
  freeThreshold?: number;
  deliveryMethod: DeliveryMethodEnum;
  deliveryArea: DeliveryAreaEnum;
  carrier?: Carrier;
}

export interface DeliveryDetailsState {
  minDays: number;
  maxDays: number;
  price: number;
  freeThreshold?: number;
}

export interface DeliveryAreaState {
  france?: DeliveryDetailsState;
  monaco?: DeliveryDetailsState;
  corse?: DeliveryDetailsState;
}

export interface GroupedCarrierState {
  code: string;
  pickup?: DeliveryAreaState;
  delivery?: DeliveryAreaState;
}

export interface GroupedDeliveryState {
  [key: string]: {
    groupedCarrierState: GroupedCarrierState[];
    carrierId: number;
  };
}
