import { gql } from "@apollo/client";

import {
  graphqlGetCompanyCarriersFields,
  graphqlGetCompanyFields,
  graphqlGetDeliveryPackagesFields,
  graphqlGetReedooCarrierFields,
} from "~/constants/graphql";
import { AdminCompaniesFilterArgs } from "~/redux/slice/adminCompanies.slice";
import { CompanyCarrier, ReedooCarrier } from "~/types/data/Carrier.types";
import {
  Company,
  UpdateCompanyDetailsInput,
  UpdateCompanyIdentificationInput,
  UpdateReedooDeliveryInput,
} from "~/types/data/Company.types";

// responses

export interface GenerateApiTokenResponse {
  generateApiToken: { apiToken: string };
}

export interface GetCompaniesResponse {
  getCompanies: Company[];
}

export interface UpdateCompanyIdentificationApiResponse {
  updateCompanyIdentification: Company;
}

export interface UpdateCompanyDetailsApiResponse {
  updateCompanyDetails: Company;
}

export interface UpdateCompanyDeliveryApiResponse {
  updateCompanyDelivery: CompanyCarrier[];
}

export interface UpdateReedooDeliveryApiResponse {
  updateReedooDelivery: ReedooCarrier[];
}

export interface AdminGetCompaniesResponse {
  adminGetCompanies: {
    companies: Company[];
    nextCursor: number;
    hasMoreData: boolean;
  };
}

export interface AdminGetCompaniesCountResponse {
  adminGetCompaniesCount: { count: number };
}

export interface AdminGetCompanyByIdResponse {
  adminGetCompanyById: Company;
}

export interface AdminDeleteCompaniesResponse {
  adminDeleteCompanies: { count: number };
}

export interface AdminDeleteAllCompaniesResponse {
  adminDeleteAllCompanies: { count: number };
}

export interface AdminCreateOrUpdateCompanyResponse {
  adminCreateOrUpdateCompany: Company;
}

// inputs
export interface UpdateCompanyIdentificationApiInput {
  UpdateCompanyIdentificationInput: UpdateCompanyIdentificationInput;
}

export interface UpdateCompanyDetailsApiInput {
  UpdateCompanyDetailsInput: UpdateCompanyDetailsInput;
}

export interface UpdateReedooDeliveryApiInput {
  UpdateReedooDeliveryInput: UpdateReedooDeliveryInput;
}

export interface UpdateCompanyDeliveryApiInput {
  UpdateCompanyDeliveryInput: {
    companyId: number;
    companyCarriers: CompanyCarrier[];
  };
}

export interface AdminGetCompaniesInput {
  AdminGetCompaniesInput: AdminCompaniesFilterArgs;
}

export interface AdminGetCompanyByIdInput {
  AdminGetCompanyByIdInput: { id: number };
}

export interface AdminDeleteCompaniesInput {
  AdminDeleteCompaniesInput: { companiesIds: number[] };
}

export interface AdminCreateOrUpdateCompanyInput {
  AdminCreateOrUpdateCompanyInput: {
    id?: number;
  };
}

const companyFields = graphqlGetCompanyFields.join(" ");
const companyCarriersFields = graphqlGetCompanyCarriersFields.join(" ");
const deliveryPackagesFields = graphqlGetDeliveryPackagesFields.join(" ");
const reedooCarrierFields = graphqlGetReedooCarrierFields.join(" ");

// gql
export const GENERATE_API_TOKEN = gql`
  mutation {
    generateApiToken {
      apiToken
    }
  }
`;

export const GET_COMPANIES = gql`
  query {
    getCompanies {
      id
      storeName
    }
  }
`;

export const UPDATE_COMPANY_IDENTIFICATION = gql`
  mutation (
    $UpdateCompanyIdentificationInput: UpdateCompanyIdentificationInput!
  ) {
    updateCompanyIdentification(
      UpdateCompanyIdentificationInput: $UpdateCompanyIdentificationInput
    ) {
      id
      logo

      logoFullPath
    }
  }
`;

export const UPDATE_COMPANY_DETAILS = gql`
  mutation ($UpdateCompanyDetailsInput: UpdateCompanyDetailsInput!) {
    updateCompanyDetails(
      UpdateCompanyDetailsInput: $UpdateCompanyDetailsInput
    ) {
      id
      mangopayId
    }
  }
`;

export const UPDATE_COMPANY_DELIVERY = gql`
  mutation ($UpdateCompanyDeliveryInput: UpdateCompanyDeliveryInput!) {
    updateCompanyDelivery(UpdateCompanyDeliveryInput: $UpdateCompanyDeliveryInput) {
      ${companyCarriersFields}
      deliveryPackages {
        ${deliveryPackagesFields}
      }
    }
  }
`;

export const UPDATE_REEDOO_DELIVERY = gql`
  mutation ($UpdateReedooDeliveryInput: UpdateReedooDeliveryInput!) {
    updateReedooDelivery(UpdateReedooDeliveryInput: $UpdateReedooDeliveryInput) {
      ${reedooCarrierFields}
        deliveryPackages {
          ${deliveryPackagesFields}
        }

      }
    }
`;

// admin
export const ADMIN_GET_COMPANIES = gql`
  query ($AdminGetCompaniesInput: AdminGetCompaniesInput!) {
    adminGetCompanies(AdminGetCompaniesInput: $AdminGetCompaniesInput) {
      companies {
        ${companyFields}
        logoFullPath
        productsCount
        ordersCount
        reviewsAverage
        totalRevenue
      }
      nextCursor
      hasMoreData

    }
  }
`;

export const ADMIN_GET_COMPANY_BY_ID = gql`
  query ($AdminGetCompanyByIdInput: AdminGetCompanyByIdInput!) {
    adminGetCompanyById(AdminGetCompanyByIdInput: $AdminGetCompanyByIdInput) {
      ${companyFields}
      logoFullPath
    }
  }
`;

export const ADMIN_GET_COMPANIES_COUNT = gql`
  query ($AdminGetCompaniesInput: AdminGetCompaniesInput!) {
    adminGetCompaniesCount(AdminGetCompaniesInput: $AdminGetCompaniesInput) {
      count
    }
  }
`;

export const ADMIN_DELETE_COMPANIES = gql`
  mutation ($AdminDeleteCompaniesInput: AdminDeleteCompaniesInput!) {
    adminDeleteCompanies(
      AdminDeleteCompaniesInput: $AdminDeleteCompaniesInput
    ) {
      count
    }
  }
`;

export const ADMIN_DELETE_ALL_COMPANIES = gql`
  mutation ($adminGetCompaniesInput: adminGetCompaniesInput!) {
    adminDeleteAllCompanies(adminGetCompaniesInput: $adminGetCompaniesInput) {
      count
    }
  }
`;

export const ADMIN_CREATE_OR_UPDATE_COMPANY = gql`
  mutation ($AdminCreateOrUpdateCompanyInput: AdminCreateOrUpdateCompanyInput!) {
    adminCreateOrUpdateCompany(AdminCreateOrUpdateCompanyInput: $AdminCreateOrUpdateCompanyInput) {
      ${companyFields}
      logoFullPath
    }
  }
`;
