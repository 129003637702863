import { initProductFormState } from "~/constants/states";
import { Product, VariantsChecklist } from "~/types/data/Product.types";

export const createNewVairant = (
  productState: Product,
  variantsChecklist: VariantsChecklist
) => {
  const productImages = !!productState.images ? productState.images : [];

  const newVariant: Product = {
    categoryId:
      variantsChecklist.categoryId === false
        ? initProductFormState.categoryId
        : productState.categoryId,
    images:
      variantsChecklist.image1 === false
        ? initProductFormState.images
        : [...productImages],
    video:
      variantsChecklist.video === false
        ? initProductFormState.video
        : productState.video,
    videoThumbnailUrl:
      variantsChecklist.video === false
        ? initProductFormState.videoThumbnailUrl
        : productState.videoThumbnailUrl,
    title:
      variantsChecklist.title === false
        ? initProductFormState.title
        : productState.title,
    description:
      variantsChecklist.description === false
        ? initProductFormState.description
        : productState.description,
    productCareInstructions:
      variantsChecklist.productCareInstructions === false
        ? initProductFormState.productCareInstructions
        : productState.productCareInstructions,
    sku: initProductFormState.sku,
    ean:
      variantsChecklist.ean === false
        ? initProductFormState.ean
        : productState.ean,
    vendorParentId:
      variantsChecklist.vendorParentId === false
        ? initProductFormState.vendorParentId
        : productState.vendorParentId,
    composition:
      variantsChecklist.composition === false
        ? initProductFormState.composition
        : productState.composition,
    compositionLining:
      variantsChecklist.compositionLining === false
        ? initProductFormState.compositionLining
        : productState.compositionLining,
    minimalAge:
      variantsChecklist.minimalAge === false
        ? initProductFormState.minimalAge
        : productState.minimalAge,
    weight:
      variantsChecklist.weight === false
        ? initProductFormState.weight
        : productState.weight,
    depth:
      variantsChecklist.depth === false
        ? initProductFormState.depth
        : productState.depth,
    length:
      variantsChecklist.length === false
        ? initProductFormState.length
        : productState.length,
    height:
      variantsChecklist.height === false
        ? initProductFormState.height
        : productState.height,
    volume:
      variantsChecklist.volume === false
        ? initProductFormState.volume
        : productState.volume,
    diameter:
      variantsChecklist.diameter === false
        ? initProductFormState.diameter
        : productState.diameter,
    gender:
      variantsChecklist.gender === false
        ? initProductFormState.gender
        : productState.gender,
    brand:
      variantsChecklist.brand === false
        ? initProductFormState.brand
        : productState.brand,
    color:
      variantsChecklist.color === false
        ? initProductFormState.color
        : productState.color,
    size:
      variantsChecklist.size === false
        ? initProductFormState.size
        : productState.size,
    mainMaterial:
      variantsChecklist.mainMaterial === false
        ? initProductFormState.mainMaterial
        : productState.mainMaterial,
    stateType:
      variantsChecklist.stateType === false
        ? initProductFormState.stateType
        : productState.stateType,
    logisticClass:
      variantsChecklist.logisticClass === false
        ? initProductFormState.logisticClass
        : productState.logisticClass,
    ecoResponsibleLabel:
      variantsChecklist.ecoResponsibleLabel === false
        ? initProductFormState.ecoResponsibleLabel
        : productState.ecoResponsibleLabel,
    madeIn:
      variantsChecklist.madeIn === false
        ? initProductFormState.madeIn
        : productState.madeIn,
    quantity:
      variantsChecklist.quantity === false
        ? initProductFormState.quantity
        : productState.quantity,
    sendAlertOnQuantity:
      variantsChecklist.sendAlertOnQuantity === false
        ? initProductFormState.sendAlertOnQuantity
        : productState.sendAlertOnQuantity,
    priceTTC:
      variantsChecklist.priceTTC === false
        ? initProductFormState.priceTTC
        : productState.priceTTC,
    discountedPriceTTC:
      variantsChecklist.discountedPriceTTC === false
        ? initProductFormState.discountedPriceTTC
        : productState.discountedPriceTTC,
    startingDiscountDate:
      variantsChecklist.startingDiscountDate === false
        ? initProductFormState.startingDiscountDate
        : productState.startingDiscountDate,
    endingDiscountDate:
      variantsChecklist.endingDiscountDate === false
        ? initProductFormState.endingDiscountDate
        : productState.endingDiscountDate,
    ecoTax:
      variantsChecklist.ecoTax === false
        ? initProductFormState.ecoTax
        : productState.ecoTax,
    vatRate:
      variantsChecklist.vatRate === false
        ? initProductFormState.vatRate
        : productState.vatRate,
    liningMaterial:
      variantsChecklist.liningMaterial === false
        ? initProductFormState.liningMaterial
        : productState.liningMaterial,
    parentId:
      variantsChecklist.parentId === false
        ? initProductFormState.parentId
        : productState.parentId,
    status:
      variantsChecklist.status === false
        ? initProductFormState.status
        : productState.status,
    sellingType:
      variantsChecklist.sellingType === false
        ? initProductFormState.sellingType
        : productState.sellingType,
    dailyRentPrice:
      variantsChecklist.dailyRentPrice === false
        ? initProductFormState.dailyRentPrice
        : productState.dailyRentPrice,
    weeklyRentPrice:
      variantsChecklist.weeklyRentPrice === false
        ? initProductFormState.weeklyRentPrice
        : productState.weeklyRentPrice,
    monthlyRentPrice:
      variantsChecklist.monthlyRentPrice === false
        ? initProductFormState.monthlyRentPrice
        : productState.monthlyRentPrice,
    depositPrice:
      variantsChecklist.depositPrice === false
        ? initProductFormState.depositPrice
        : productState.depositPrice,
    rentingPeriod:
      variantsChecklist.rentingPeriod === false
        ? initProductFormState.rentingPeriod
        : productState.rentingPeriod,
    rentSecondHandPrice:
      variantsChecklist.rentSecondHandPrice === false
        ? initProductFormState.rentSecondHandPrice
        : productState.rentSecondHandPrice,
  };

  return newVariant;
};
