import Joi from "joi";

import { CreateUserInput } from "~/types/data/User.types";

export const createSellerValidationSchema = Joi.object<CreateUserInput>({
  companyId: Joi.optional(),
  role: Joi.optional(),
  username: Joi.string()
    .required()
    .error(new Error("Username can't be empty.")),
  email: Joi.string()
    .email({ tlds: false, allowUnicode: true })
    .required()
    .error(new Error("Adresse e-mail invalid.")),
  permissionLevel: Joi.string()
    .required()
    .error(new Error("Niveau de droits can't be empty.")),
});
