import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import clip from "text-clipper";

import CustomCheckbox from "~/components/form/CustomCheckbox";
import SVGContainer from "~/components/UI/SVGContainer";
import { routesBuilder } from "~/navigation/routes";
import { selectConversationsState } from "~/redux/slice/conversations.slice";
import { IncidentStatusEnum, Order } from "~/types/data/Order.types";
import { formatDate, formatHour } from "~/util/functions/formatDate";
import { formatPrice } from "~/util/functions/formatDecimal";
import { getTextFromOrderStatus } from "~/util/mapping/orderStatus.mapping";

import styles from "./index.module.scss";

interface Props {
  order: Order;
  isSelected: boolean;
  onCheckboxChange: (order: Order) => void;
  onClick: (id: number) => void;
  showCompanyColumn: boolean;
}

const OrdersTableRow = ({
  order,
  isSelected,
  onCheckboxChange,
  onClick,
  showCompanyColumn,
}: Props) => {
  const {
    id,
    createdAt,
    incidentStatus,
    orderToProducts,
    shippingDate,
    status,
    rating,
    finalTotalPrice,
    managedByReedoo,
    storeName,
    sellerName,
  } = order;

  const navigate = useNavigate();

  const { conversations } = useSelector(selectConversationsState);

  const conversation = conversations.find(({ order }) => order?.id === id);

  const formattedCreatedAtDate = formatDate(createdAt);
  const formattedCreatedAtHour = formatHour(createdAt);
  const formattedShippingDate = shippingDate
    ? `${formatDate(shippingDate)} (domicile)`
    : "N/A";

  const formattedFintalTotalPrice = formatPrice(finalTotalPrice);
  const statusText = getTextFromOrderStatus(status);

  const clickHandler = () => {
    onClick(id);
  };

  const messageButtonClickHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    const detailsDuneCommandeToIdtoDetails = routesBuilder({
      routes: ["detailsDuneCommandeRoute", "messagesRoute"],
      replace: [{ position: 1, variable: id.toString() }],
    });
    navigate(detailsDuneCommandeToIdtoDetails, {
      replace: false,
    });
  };

  return (
    <tr className={`${styles.row}`}>
      <td className={styles.nonClickable}>
        <CustomCheckbox
          checked={isSelected}
          onChange={() => {
            onCheckboxChange(order);
          }}
          padding="0.7rem"
        />
      </td>
      <td onClick={clickHandler}>
        <div>{formattedCreatedAtDate}</div>
        <div>{formattedCreatedAtHour}</div>
      </td>
      <td onClick={clickHandler}>{id}</td>
      <td onClick={clickHandler}>{order.shippingAddress?.name || "N/A"}</td>
      <td onClick={clickHandler}>{formattedShippingDate} </td>
      <td onClick={clickHandler}>{statusText}</td>
      <td className={`${styles.detailsContainer}`} onClick={clickHandler}>
        {orderToProducts.map(({ product }, index) => {
          return (
            <div key={index} className={styles.productDetailsContainer}>
              <div className={styles.boldText}>
                {clip(product.title || "", 20)}
              </div>
              <div>
                <span className={styles.boldText}>SKU: </span>
                {product.sku}
              </div>
            </div>
          );
        })}
      </td>
      <td onClick={clickHandler}>
        {orderToProducts.map(({ quantity }, index) => {
          return (
            <div key={index} className={styles.productQuantityContainer}>
              {quantity}
            </div>
          );
        })}
      </td>
      <td onClick={clickHandler}>{`${formattedFintalTotalPrice}`}</td>{" "}
      {showCompanyColumn && (
        <td className={styles.nonClickable}>
          {storeName || (!!sellerName ? `Profile: ${sellerName}` : "") || "N/A"}
        </td>
      )}
      <td onClick={clickHandler}>
        <div className={`${styles.buttonContainer}`}>
          {managedByReedoo && (
            <SVGContainer
              height="26px"
              width="26px"
              imagePath="/assets/reedoo-black-icon.svg"
            />
          )}
          {conversation && (
            <SVGContainer
              height="26px"
              width="26px"
              imagePath="/assets/message-button.svg"
              onClick={messageButtonClickHandler}
            />
          )}
          {(incidentStatus === IncidentStatusEnum.OPEN ||
            incidentStatus === IncidentStatusEnum.CLOSED_BY_SELLER) && (
            <SVGContainer
              height="26px"
              width="26px"
              imagePath="/assets/abort-button.svg"
              onClick={() => {}}
            />
          )}

          {!!rating && (
            <SVGContainer
              height="26px"
              width="26px"
              imagePath="/assets/yellow-star-icon.svg"
              onClick={() => {}}
            />
          )}
        </div>
      </td>
    </tr>
  );
};

export default OrdersTableRow;
