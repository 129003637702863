import { HeadCell } from "~/types/common/headCell.type";

import styles from "./index.module.scss";

interface Props {
  headCells: HeadCell[];
}

export const SmallTableHead = ({ headCells }: Props) => {
  return (
    <thead>
      <tr className={`${styles.head}`}>
        {headCells.map((headCell) => {
          const key = headCell.id.toString();
          const label = headCell.label;
          return <th key={key}>{label}</th>;
        })}
      </tr>
    </thead>
  );
};
