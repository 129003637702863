import { colors } from "~/constants/styles";
import {
  MyTransactionStatusEnum,
  PayoutStatusEnum,
} from "~/types/data/Accounting.types";
import {
  IncidentStatusEnum,
  OrderStatusEnum,
  OrderToProductStatusEnum,
} from "~/types/data/Order.types";

export function getTextFromOrderStatus(
  status: OrderStatusEnum | OrderToProductStatusEnum
) {
  switch (status) {
    case OrderStatusEnum.OPEN:
      return "Ouvert";

    case OrderStatusEnum.WAITING_FOR_CONFIRMATION:
      return "En attente d'acceptation";

    case OrderStatusEnum.WAITING_FOR_EXPEDITION:
      return "En attente d'expédition";

    case OrderStatusEnum.WAITING_FOR_RECEPTION:
      return "En attente de réception";

    case OrderStatusEnum.WAITING_FOR_VALIDATION:
      return "En attente de fermeture";

    case OrderStatusEnum.CANCELLED:
      return "Refusé";

    case OrderStatusEnum.CLOSED:
      return "Fermé";

    default:
      return "";
  }
}

export const getOrderStatusColor = (
  status: OrderStatusEnum | OrderToProductStatusEnum
) => {
  return status === OrderStatusEnum.WAITING_FOR_VALIDATION ||
    status === OrderStatusEnum.CLOSED
    ? colors.$primary
    : status === OrderStatusEnum.WAITING_FOR_EXPEDITION
    ? "#354957"
    : "#899CAA";
};

export const getTextFromIncidentStatus = (status: IncidentStatusEnum) =>
  status === IncidentStatusEnum.OPEN
    ? "Incident ouvert"
    : status === IncidentStatusEnum.CLOSED
    ? "Incident fermé"
    : status === IncidentStatusEnum.CLOSED_BY_SELLER
    ? "Incident clôturé par le vendeur"
    : "";

export const getTextFromTransactionStatus = (status: MyTransactionStatusEnum) =>
  status === MyTransactionStatusEnum.PENDING
    ? "En attente"
    : status === MyTransactionStatusEnum.PAYABLE
    ? "Payable"
    : status === MyTransactionStatusEnum.PAID
    ? "Payé"
    : status === MyTransactionStatusEnum.CANCELLED
    ? "Refusé"
    : "";

export const getTextFromPayOutStatus = (status: PayoutStatusEnum) =>
  status === PayoutStatusEnum.CREATED
    ? "CREATED"
    : status === PayoutStatusEnum.FAILED
    ? "FAILED"
    : status === PayoutStatusEnum.SUCCEEDED
    ? "SUCCEEDED"
    : "";
