import { ApolloError } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  UPDATE_COMPANY_DELIVERY,
  UpdateCompanyDeliveryApiInput,
  UpdateCompanyDeliveryApiResponse,
} from "~/api/graphql/company";
import { CustomButton } from "~/components/form/CustomButton";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import {
  Carrier,
  DeliveryDetailsState,
  GroupedDeliveryState,
} from "~/types/data/Carrier.types";

import { CarrierSection } from "./CarrierSection";
import {
  mapToCompanyCarrierState,
  mapToGroupedDeliveryState,
} from "./util/deliveryMapping";
import {
  validateDeliveryPackages,
  validateGroupedDeliveryState,
} from "./util/validateUpdateDelivery";

export type DeliveryMethodKey = "delivery" | "pickup";
export type DeliveryAreaKey = "france" | "monaco" | "corse";

interface Props {
  carriers: Carrier[];
}

export const Delivery = ({ carriers }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { companyAccount, setCompanyAccount } = useCompanyAccountContext();

  const initMappedGroupedDeliveryState = mapToGroupedDeliveryState(
    companyAccount.carriers
  );
  const [groupedDeliveryState, setGroupedDeliveryState] =
    useState<GroupedDeliveryState>(initMappedGroupedDeliveryState);

  const updateDeliveryHandler = (
    responseData: UpdateCompanyDeliveryApiResponse
  ) => {
    const { updateCompanyDelivery } = responseData;

    setSuccessMessage("Delivery section updated successfully.");
    setCompanyAccount((prev) => {
      return {
        ...prev,
        carriers: updateCompanyDelivery,
      };
    });
    setIsLoading(false);
  };

  const updateDeliveryErrorHandler = (error: ApolloError) => {
    const { message } = error;
    setErrorMessage(`Error: ${message}`);
    setIsLoading(false);
  };

  const { trigger: updateCompanyDeliveryInfoTrigger } =
    useMutationWithCallbacks<
      UpdateCompanyDeliveryApiResponse,
      UpdateCompanyDeliveryApiInput
    >(
      UPDATE_COMPANY_DELIVERY,
      updateDeliveryHandler,
      updateDeliveryErrorHandler
    );

  const addCarrierSectionHandler = () => {
    const keyState = uuidv4();
    setGroupedDeliveryState((prev) => ({
      ...prev,
      [keyState]: { carrierId: 0, groupedCarrierState: [] },
    }));
  };

  const deleteCarrierSectionHandler = (stateKey: string) => {
    setGroupedDeliveryState((prev) => {
      const copy = { ...prev };
      delete copy[stateKey];
      return copy;
    });
  };

  const carrierChangedHandler = (stateKey: string, carrierId: number) => {
    setGroupedDeliveryState((prev) => ({
      ...prev,
      [stateKey]: { ...prev[stateKey], carrierId },
    }));
  };

  const groupedCarrierCodeChangeHandler = (
    stateKey: string,
    codeIndex: number,
    newCode: string
  ) => {
    setGroupedDeliveryState((prev) => ({
      ...prev,
      [stateKey]: {
        ...prev[stateKey],
        groupedCarrierState: prev[stateKey].groupedCarrierState.map(
          (element, index) =>
            index === codeIndex ? { ...element, code: newCode } : element
        ),
      },
    }));
  };

  const addGroupedCarrierCodeHandler = (stateKey: string) => {
    setGroupedDeliveryState((prev) => ({
      ...prev,
      [stateKey]: {
        ...prev[stateKey],
        groupedCarrierState: [
          ...prev[stateKey].groupedCarrierState,
          { code: "" },
        ],
      },
    }));
  };

  const deleteGroupedCarrierCodeHandler = (
    stateKey: string,
    codeIndex: number
  ) => {
    setGroupedDeliveryState((prev) => ({
      ...prev,
      [stateKey]: {
        ...prev[stateKey],
        groupedCarrierState: prev[stateKey].groupedCarrierState.filter(
          (_element, index) => index !== codeIndex
        ),
      },
    }));
  };

  const clearDeliveryMethodHandler = (
    stateKey: string,
    codeIndex: number,
    method: DeliveryMethodKey
  ) => {
    setGroupedDeliveryState((prev) => {
      const newState = cloneDeep(prev);
      const delivery = newState[stateKey].groupedCarrierState[codeIndex];
      delivery[method] = undefined;
      return newState;
    });
  };

  const clearDeliveryAreaHandler = (
    stateKey: string,
    codeIndex: number,
    method: DeliveryMethodKey,
    area: DeliveryAreaKey
  ) => {
    setGroupedDeliveryState((prev) => {
      const newState = cloneDeep(prev);
      const delivery = newState[stateKey].groupedCarrierState[codeIndex];
      const groupedState = delivery[method];
      if (groupedState) groupedState[area] = undefined;
      return newState;
    });
  };

  const addDeliveryDetailsStateHandler = (
    stateKey: string,
    codeIndex: number,
    method: DeliveryMethodKey,
    area: DeliveryAreaKey
  ) => {
    setGroupedDeliveryState((prev) => {
      const newState = cloneDeep(prev);
      const delivery = newState[stateKey].groupedCarrierState[codeIndex];
      const groupedState = delivery[method];
      const initState = {
        maxDays: 0,
        minDays: 0,
        price: 0,
      };
      if (groupedState) {
        groupedState[area] = initState;
      } else {
        delivery[method] = {
          [area]: initState,
        };
      }
      return newState;
    });
  };

  const deliveryPackageChangeHandler = (
    stateKey: string,
    codeIndex: number,
    method: DeliveryMethodKey,
    area: DeliveryAreaKey,
    changes: DeliveryDetailsState
  ) => {
    setGroupedDeliveryState((prev) => {
      const newState = cloneDeep(prev);
      const delivery = newState[stateKey].groupedCarrierState[codeIndex];
      const groupedState = delivery[method];
      if (groupedState) groupedState[area] = changes;
      return newState;
    });
  };

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    // validate
    const errorMessage = validateGroupedDeliveryState(groupedDeliveryState);

    if (errorMessage) {
      setErrorMessage(errorMessage);
      setIsLoading(false);
      return;
    }

    const mappedCompanyCarriers =
      mapToCompanyCarrierState(groupedDeliveryState);

    // validate delivery packages values
    const deliveryPackagesValidation = validateDeliveryPackages(
      mappedCompanyCarriers
    );

    if (deliveryPackagesValidation) {
      setErrorMessage(deliveryPackagesValidation);
      setIsLoading(false);
      return;
    }

    await updateCompanyDeliveryInfoTrigger({
      variables: {
        UpdateCompanyDeliveryInput: {
          companyId: companyAccount.id,
          companyCarriers: mappedCompanyCarriers,
        },
      },
    });
  };

  const renderCarrierSections = () => {
    const stateKeys = Object.keys(groupedDeliveryState);
    return stateKeys.map((stateKey, carrierIndex) => (
      <CarrierSection
        key={carrierIndex}
        carrierIndex={carrierIndex}
        stateKey={stateKey}
        carriers={carriers}
        groupedCarriersState={groupedDeliveryState[stateKey]}
        stateKeys={stateKeys}
        carrierChangedHandler={carrierChangedHandler}
        addGroupedCarrierCodeHandler={addGroupedCarrierCodeHandler}
        deleteGroupedCarrierCodeHandler={deleteGroupedCarrierCodeHandler}
        deliveryPackageChangeHandler={deliveryPackageChangeHandler}
        groupedCarrierCodeChangeHandler={groupedCarrierCodeChangeHandler}
        deleteCarrierSectionHandler={deleteCarrierSectionHandler}
        clearDeliveryMethodHandler={clearDeliveryMethodHandler}
        clearDeliveryAreaHandler={clearDeliveryAreaHandler}
        addDeliveryDetailsStateHandler={addDeliveryDetailsStateHandler}
      />
    ));
  };

  return (
    <form
      className="myAccountFormSection"
      onSubmit={(e) => {
        submitHandler(e);
      }}
    >
      <h3 className="title">Livraison</h3>
      <div style={{ marginBottom: "1rem" }}>
        {renderCarrierSections()}
        <CustomButton
          backgroundColor={colors.$primary}
          color="white"
          width="fit-content"
          height="fit-content"
          borderRadius="8px"
          padding="0.5rem 1.5rem"
          onClick={addCarrierSectionHandler}
        >
          Ajouter un transporteur
        </CustomButton>
      </div>
      <div className="messageContainer">
        {!!errorMessage && <p className="errorMessage">{errorMessage}</p>}
        {!!successMessage && <p>{successMessage}</p>}
      </div>
      <div className="buttonContainer">
        <CustomButton
          backgroundColor={colors.$primary}
          color="white"
          width="fit-content"
          borderRadius="8px"
          padding="0.5rem 1.5rem"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <SimpleLoader size="size2" /> : "Enregistrer"}
        </CustomButton>
      </div>
    </form>
  );
};
