import { gql } from "@apollo/client";

import { SecondHandCategory } from "~/types/data/SecondHandCategory.type";

export interface UpdateSecondHandCategoryInput {
  UpdateSecondHandCategoryInput: {
    secondHandCompanyId: number;
    categoryId: number;
    imagePath: string;
  };
}

export interface UpdateSecondHandCategoryResponse {
  updateSecondHandCategory: SecondHandCategory;
}

export const UPDATE_SECOND_HAND_CATEGORY = gql`
  mutation updateSecondHandCategory(
    $UpdateSecondHandCategoryInput: UpdateSecondHandCategoryInput!
  ) {
    updateSecondHandCategory(
      UpdateSecondHandCategoryInput: $UpdateSecondHandCategoryInput
    ) {
      id
      categoryId
      imagePath
    }
  }
`;
