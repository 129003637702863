import { useAppSelector } from "~/redux/hooks";
import { foreignShopMappingState } from "~/redux/slice/foreignShopMapping.slice";

import styles from "./index.module.scss";
import { Section } from "./Section";

export enum NavigationSections {
  CategoriesMapping = 1,
  FieldsMapping = 2,
  AttributeMapping = 3,
  Actions = 4,
}

const sections = [
  "Categories Mapping",
  "Fields Mapping",
  "Attributes Mapping",
  "Actions",
];

interface Props {
  navSections: {
    active: NavigationSections[];
  };
  onClick: (section: NavigationSections) => void;
}

export const SectionsNavigation = ({ navSections, onClick }: Props) => {
  const { categoriesFinished, optionValuesFinished, optionsFinished } =
    useAppSelector(foreignShopMappingState);

  const finishedSections = [
    categoriesFinished && NavigationSections.CategoriesMapping,
    optionsFinished && NavigationSections.FieldsMapping,
    optionValuesFinished && NavigationSections.AttributeMapping,
  ];

  return (
    <div className={`${styles.container}`}>
      <h4 className={`${styles.title}`}>Sections</h4>
      <div className={`${styles.sections}`}>
        {sections.map((value, index) => {
          const sectionNumber = index + 1;
          return (
            <Section
              key={sectionNumber}
              isActive={navSections.active.includes(sectionNumber)}
              isFinished={finishedSections.includes(sectionNumber)}
              text={`${sectionNumber}. ${value}`}
              onClick={() => {
                onClick(sectionNumber);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
