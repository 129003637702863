import CustomCheckbox from "~/components/form/CustomCheckbox";
import { Company } from "~/types/data/Company.types";
import { formatDate } from "~/util/functions/formatDate";
import { formatDecimal, formatPrice } from "~/util/functions/formatDecimal";

import styles from "./index.module.scss";

interface Props {
  company: Company;
  isSelected: boolean;
  onCheckboxChange: (company: Company) => void;
  onClick: (variantId: number) => void;
}

export const CompanyTableRow = ({
  company,
  isSelected,
  onCheckboxChange,
  onClick,
}: Props) => {
  const {
    id,
    email,
    companyName,
    storeName,
    companyPhoneNumber,
    mangopayId,
    reviewsAverage,
    createdAt,
    ordersCount,
    productsCount,
    totalRevenue,
    kycLevel,
  } = company;

  const onClickFunction = id
    ? () => {
        onClick(id);
      }
    : () => {};

  const formattedTotalRevenue = formatPrice(totalRevenue);
  const formattedCreatedAt = createdAt ? formatDate(createdAt) : "N/A";
  const formattedReviewsAverage = reviewsAverage
    ? `${formatDecimal(reviewsAverage)}/5`
    : "N/A";

  return (
    <tr>
      <td className={styles.nonClickable}>
        <CustomCheckbox
          checked={isSelected}
          onChange={() => {
            onCheckboxChange(company);
          }}
          padding="0.7rem"
        />
      </td>
      <td onClick={onClickFunction}>{id}</td>
      <td onClick={onClickFunction}>{storeName || "N/A"}</td>
      <td onClick={onClickFunction}>{companyName || "N/A"}</td>
      <td onClick={onClickFunction} className={styles.emailTd}>
        {email || "N/A"}
      </td>
      <td onClick={onClickFunction}>{companyPhoneNumber || "N/A"}</td>
      <td onClick={onClickFunction}>{mangopayId || "N/A"}</td>
      <td onClick={onClickFunction}>{formattedCreatedAt ?? "N/A"}</td>
      <td onClick={onClickFunction}>{formattedTotalRevenue ?? "N/A"}</td>
      <td onClick={onClickFunction}>{productsCount ?? "N/A"}</td>
      <td onClick={onClickFunction}>{ordersCount ?? "N/A"}</td>
      <td onClick={onClickFunction}>
        {reviewsAverage ? formattedReviewsAverage : "N/A"}
      </td>
      <td onClick={onClickFunction}>{kycLevel}</td>
    </tr>
  );
};
