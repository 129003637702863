import { gql } from "@apollo/client";

import { graphqlGetSubscriptionImportFields } from "~/constants/graphql";
import { SubscriptionImport } from "~/types/data/SubscriptionImport.type";

export interface ProcessSubscriptionImportInput {
  ProcessSubscriptionImportInput: {
    filePath: string;
    subscriptionId: number;
  };
}

export interface GetLastSubscriptionImportInput {
  GetLastSubscriptionImportInput: {
    subscriptionId: number;
  };
}

export interface ProcessSubscriptionImportResponse {
  processSubscriptionImport: SubscriptionImport;
}

export interface GetLastSubscriptionImportResponse {
  getLastSubscriptionImport: SubscriptionImport;
}

const getSubscriptionImportSelectFields =
  graphqlGetSubscriptionImportFields.join(" ");

export const PROCESS_SUBSCRIPTION_IMPORT = gql`
  mutation ($ProcessSubscriptionImportInput: ProcessSubscriptionImportInput!) {
    processSubscriptionImport(
      ProcessSubscriptionImportInput: $ProcessSubscriptionImportInput
    ) {
      ${getSubscriptionImportSelectFields}
    }
  }
`;

export const GET_LAST_SUBSCRIPTION_IMPORT = gql`
  query ($GetLastSubscriptionImportInput: GetLastSubscriptionImportInput!) {
    getLastSubscriptionImport(
      GetLastSubscriptionImportInput: $GetLastSubscriptionImportInput
    ) {
      ${getSubscriptionImportSelectFields}
    }
  }
`;
