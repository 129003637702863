import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { GetCarriersResponse } from "~/api/graphql/carrier";
import {
  GET_COMPANY_RENT_ORDERS,
  GET_COMPANY_RENT_ORDERS_COUNT,
  GetCompanyRentOrdersCountResponse,
  GetCompanyRentOrdersInput,
  GetCompanyRentOrdersResponse,
} from "~/api/graphql/rentOrder";
import { ErrorPopup } from "~/components/UI/ErrorPopup";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { routesBuilder } from "~/navigation/routes";
import { useAppSelector } from "~/redux/hooks";
import {
  onFilterArgsChange,
  selectRentOrdersFilterArgs,
} from "~/redux/slice/rentOrdersFilter.slice";
import { OrderByEnum } from "~/types/common/filter.types";
import { HeadCell } from "~/types/common/headCell.type";
import { RentOrder } from "~/types/data/RentOrder.type";

import { getFilterCount } from "../util/getFilterCount";
import styles from "./index.module.scss";
import { RentOrdersTableBody } from "./RentOrdersTableBody";
import { RentOrdersTableHead } from "./RentOrdersTableHead";

const HEADCELLS: HeadCell<RentOrder>[] = [
  { id: 1, label: "", isCheckbox: true },
  {
    id: 2,
    label: "Date de la commande",
    isSortable: true,
    associatedKey: "createdAt",
  },
  {
    id: 3,
    label: "Date de début",
    isSortable: true,
    associatedKey: "startingDate",
  },
  {
    id: 4,
    label: "ID",
    isSortable: true,
    associatedKey: "id",
  },
  { id: 5, label: "Statut", isSortable: true, associatedKey: "status" },
  {
    id: 6,
    label: "Détail de la commande",
  },
  {
    id: 7,
    label: "Prix",
  },
  {
    id: 7,
    label: "Montant actuel",
  },
  {
    id: 8,
    label: "Montant total loué",
    isSortable: true,
    associatedKey: "totalPaidRecurringAmount",
  },
  {
    id: 9,
    label: "Montant acheté",
    isSortable: true,
    associatedKey: "totalPurchasedAmount",
  },
  {
    id: 10,
    label: "Global CA",
    isSortable: true,
    associatedKey: "totalPaidAmount",
  },
  { id: 11, label: "Company" },
];

interface Props {
  rentOrders?: RentOrder[];
  setRentOrders: React.Dispatch<React.SetStateAction<RentOrder[] | undefined>>;
  setOrdersCountHandler: (count: number) => void;
  isSelectedAll: boolean;
  setIsSelectedAllHandler: (isSelectedAll: boolean) => void;
  selectedOrders: RentOrder[];
  setSelectedOrders: React.Dispatch<React.SetStateAction<RentOrder[]>>;
  carriersData: GetCarriersResponse;
  setFilterCountHandler: (count: number) => void;
  showCompanyColumn: boolean;
}

export const RentOrdersTable = ({
  rentOrders,
  setRentOrders,
  setOrdersCountHandler,
  isSelectedAll,
  setIsSelectedAllHandler,
  selectedOrders,
  setSelectedOrders,
  carriersData,
  setFilterCountHandler,
  showCompanyColumn,
}: Props) => {
  const MODIFIED_HEADCELLS = showCompanyColumn
    ? HEADCELLS
    : HEADCELLS.filter(({ id }) => id !== HEADCELLS.length - 1);

  // states
  const [hasMoreData, setHasMoreData] = useState<boolean>(false);
  const [errorModalMessage, setErrorModalMessage] = useState("");

  // hooks
  const dispatch = useDispatch();
  const filterArgsState = useAppSelector(selectRentOrdersFilterArgs);
  const navigate = useNavigate();

  const [getCompanyRentOrdersCountTrigger] = useLazyQuery<
    GetCompanyRentOrdersCountResponse,
    GetCompanyRentOrdersInput
  >(GET_COMPANY_RENT_ORDERS_COUNT);

  const [getRentOrdersTrigger, { loading: getRentOrdersLoading }] =
    useLazyQuery<GetCompanyRentOrdersResponse, GetCompanyRentOrdersInput>(
      GET_COMPANY_RENT_ORDERS
    );

  // handlers
  const orderByHandler = (key: keyof RentOrder, order: OrderByEnum) => {
    dispatch(
      onFilterArgsChange({ filterArg: "orderBy", orderBy: { [key]: order } })
    );
  };

  const selectAllChangeHandler = (selected: boolean) => {
    if (selected) {
      setIsSelectedAllHandler(true);
      setSelectedOrders(rentOrders || []);
      return;
    }
    setIsSelectedAllHandler(false);
    setSelectedOrders([]);
  };

  const orderClickHandler = (id: number) => {
    const detailsDuneCommandeToIdtoDetails = routesBuilder({
      routes: ["detailsDuneCommandeRoute", "detailsRoute"],
      replace: [{ position: 1, variable: id.toString() }],
    });
    navigate(detailsDuneCommandeToIdtoDetails, {
      replace: false,
    });
  };

  const checkboxClickHandler = (orderInput: RentOrder) => {
    const selectedCheckboxIndex = selectedOrders.findIndex(
      ({ id }) => orderInput.id === id
    );
    if (selectedCheckboxIndex > -1) {
      setIsSelectedAllHandler(false);
      setSelectedOrders((prev) => {
        return prev.filter(({ id }) => orderInput.id !== id);
      });
      return;
    }
    setSelectedOrders((prev) => {
      return [...prev, orderInput];
    });
  };

  const fetchOrders = async (init: boolean) => {
    if (getRentOrdersLoading) return;

    if (init) {
      setRentOrders(undefined);
      dispatch(
        onFilterArgsChange({
          filterArg: "cursor",
          cursor: undefined,
        })
      );
      setSelectedOrders([]);
      setIsSelectedAllHandler(false);

      const { data } = await getCompanyRentOrdersCountTrigger({
        variables: {
          GetCompanyRentOrdersInput: { ...filterArgsState, cursor: undefined },
        },
      });

      if (data) {
        setOrdersCountHandler(data.getCompanyRentOrdersCount.count);
      }
    }

    const { data, error } = await getRentOrdersTrigger({
      variables: {
        GetCompanyRentOrdersInput: {
          ...filterArgsState,
          ...(init && { cursor: undefined }),
        },
      },
    });

    if (data) {
      const { getCompanyRentOrders } = data;
      const { hasMoreData, nextCursor, rentOrders } = getCompanyRentOrders;
      setHasMoreData(hasMoreData);
      dispatch(onFilterArgsChange({ filterArg: "cursor", cursor: nextCursor }));

      if (isSelectedAll) {
        setSelectedOrders((prevSelectedOrders) => [
          ...prevSelectedOrders,
          ...rentOrders,
        ]);
      }

      setRentOrders((prevProductVariants) =>
        prevProductVariants
          ? [...prevProductVariants, ...rentOrders]
          : [...rentOrders]
      );
    } else if (error) {
      const { message } = error;
      setErrorModalMessage(message);
    }
  };

  const hideErrorPopupHandler = () => {
    setErrorModalMessage("");
    fetchOrders(true);
  };

  useEffect(() => {
    if (!carriersData) return;

    const count = getFilterCount(filterArgsState);
    setFilterCountHandler(count);

    fetchOrders(true);
  }, [
    filterArgsState.orderBy,
    filterArgsState.searchBox,
    filterArgsState.companiesIds,
  ]);

  return (
    <>
      <ErrorPopup
        onCancel={hideErrorPopupHandler}
        show={!!errorModalMessage}
        message={errorModalMessage}
      />
      <InfiniteScroll
        dataLength={rentOrders?.length ?? 0}
        next={() => {
          fetchOrders(false);
        }}
        hasMore={!!rentOrders && hasMoreData}
        loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
      >
        <table className={`${styles.table}`}>
          <RentOrdersTableHead
            headCells={MODIFIED_HEADCELLS}
            onSelectAllChange={selectAllChangeHandler}
            allSelected={isSelectedAll}
            orderBy={filterArgsState.orderBy}
            orderByHandler={orderByHandler}
          />
          <RentOrdersTableBody
            orders={rentOrders ?? []}
            onRowClick={orderClickHandler}
            onCheckboxChange={checkboxClickHandler}
            selectedRows={selectedOrders}
            showCompanyColumn={showCompanyColumn}
          />
        </table>
      </InfiniteScroll>
      {!rentOrders && (
        <div className={styles.loaderContainer}>
          <SimpleLoader size="size3" fill={colors.$primary} />
        </div>
      )}
    </>
  );
};
