import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { OrderBy } from "~/types/common/filter.types";
import { User } from "~/types/data/User.types";

import type { RootState } from "../store";

export interface AdminUsersFilterArgs {
  orderBy: OrderBy<User>;
  cursor?: number;
  take?: number;
  createdAtFrom?: string;
  createdAtTo?: string;
  updatedAtFrom?: string;
  updatedAtTo?: string;
  searchBox?: string;
  companiesIds?: number[];
}

type FilterArgs =
  | "orderBy"
  | "cursor"
  | "createdAtFrom"
  | "createdAtTo"
  | "updatedAtFrom"
  | "updatedAtTo"
  | "searchBox"
  | "companiesIds"
  | "clearAll";

export const adminUsersFilterArgsInitialState: AdminUsersFilterArgs = {
  orderBy: {},
  take: 30,
};

export const adminUsersFilterSlice = createSlice({
  name: "adminUsersFilter",
  initialState: adminUsersFilterArgsInitialState,
  reducers: {
    onFilterArgsChange(
      state,
      action: PayloadAction<{
        filterArg: FilterArgs;
        orderBy?: OrderBy<User>;
        cursor?: number;
        take?: number;
        createdAtFrom?: string;
        createdAtTo?: string;
        updatedAtFrom?: string;
        updatedAtTo?: string;
        searchBox?: string;
        companiesIds?: number[];
      }>
    ) {
      const {
        filterArg,
        cursor,
        orderBy,
        createdAtFrom,
        createdAtTo,
        updatedAtFrom,
        updatedAtTo,
        searchBox,
        companiesIds,
      } = action.payload;

      if (filterArg === "cursor") {
        state.cursor = cursor;
      } else if (filterArg === "orderBy" && orderBy) {
        state.orderBy = orderBy;
      } else if (filterArg === "createdAtFrom") {
        state.createdAtFrom = createdAtFrom;
      } else if (filterArg === "createdAtTo") {
        state.createdAtTo = createdAtTo;
      } else if (filterArg === "updatedAtFrom") {
        state.updatedAtFrom = updatedAtFrom;
      } else if (filterArg === "updatedAtTo") {
        state.updatedAtTo = updatedAtTo;
      } else if (filterArg === "searchBox") {
        state.searchBox = searchBox;
      } else if (filterArg === "companiesIds") {
        state.companiesIds = companiesIds;
      } else if (filterArg === "clearAll") {
        return { ...adminUsersFilterArgsInitialState };
      }
    },
  },
});

export const { onFilterArgsChange } = adminUsersFilterSlice.actions;

export const selectAdminUsersFilterArgs = (state: RootState) => {
  return state.adminUsersFilter;
};
export default adminUsersFilterSlice.reducer;
