import SVGContainer from "~/components/UI/SVGContainer";
import { PromoCode } from "~/types/data/PromoCode.type";

import styles from "./index.module.scss";

interface Props {
  promoCode: PromoCode;
  clickHandler: (promoCode: PromoCode) => void;
  setPromoCodesDeleteModalState: (promoCode: PromoCode) => void;
}

export const PromoCodeCard = ({
  promoCode,
  clickHandler,
  setPromoCodesDeleteModalState,
}: Props) => {
  const isActive =
    promoCode.active &&
    promoCode.uses > promoCode.used &&
    new Date(promoCode.validUntil) > new Date();
  return (
    <div
      className={`${styles.container} ${isActive ? styles.active : ""}`}
      onClick={() => {
        clickHandler(promoCode);
      }}
    >
      <div className={styles.subContainer}>
        <p>{promoCode.code}</p>
        {/* write a sentence that says on amount x user gets discount of x */}
        <p>
          Pour un montant de {promoCode.threshold}€, l'utilisateur bénéficie
          d'une réduction de{" "}
          {promoCode.discountType === "PERCENTAGE"
            ? promoCode.amount + "%"
            : promoCode.amount + "€"}
          .
        </p>
        <p>
          {promoCode.used}/{promoCode.uses} utilisations
        </p>
      </div>
      <SVGContainer
        height="18px"
        width="18px"
        imagePath="/assets/delete-button.svg"
        onClick={(e) => {
          e.stopPropagation();
          setPromoCodesDeleteModalState(promoCode);
        }}
      />
    </div>
  );
};
