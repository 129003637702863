import { memo } from "react";

import { OptionType } from "~/components/form/SelectInput";
import { Attribute } from "~/types/data/Attribute.types";
import { Product } from "~/types/data/Product.types";
import { getAttributeValues } from "~/util/functions/getAttributeValues";

import { MemoizedAttributeSelectInput } from "../AttributeSelectInput";

interface Props<K extends keyof Product> {
  attributeKey: K;
  attributeValueKey: string;
  value: Product[K];
  attributes: Attribute[];
  onStateHasChanged: (state: number) => void;
}

const AttributeSelectRow = <K extends keyof Product>({
  attributeKey,
  attributeValueKey,
  value,
  attributes,
  onStateHasChanged,
}: Props<K>) => {
  const attribute = attributes.find((attr) => attr.name === attributeKey);
  const attributeValues = getAttributeValues(attribute);
  const options: OptionType[] =
    attributeValues.map(({ value: attributeValue, associatedValues }) => {
      const value = `${attributeValue as string}||${associatedValues.join(
        "||"
      )}`;
      return { label: attributeValue as string, value };
    }) || [];

  return (
    <>
      <td>
        <MemoizedAttributeSelectInput
          attributeKey={attributeKey}
          attributeValueKey={attributeValueKey}
          options={options}
          value={value}
          attributeValues={attributeValues}
          onStateHasChanged={onStateHasChanged}
        />
      </td>
    </>
  );
};

export const MemoizedAttributeSelectRow = memo(AttributeSelectRow);
