import styles from "./index.module.scss";

const Display = () => {
  return (
    <div className={`${styles.display}`}>
      <img src="/assets/login-screen-banner.png" height="100%" />
    </div>
  );
};

export default Display;
