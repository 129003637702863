import { UpdateCategoryOrderType } from "~/api/graphql/category";
import { Category } from "~/types/data/Category.type";

export const getAllNestedCategories = (categories: Category[]): Category[] => {
  const nestedCategories: Category[] = [];

  for (let i = 0; i < categories.length; i++) {
    const category = categories[i];
    nestedCategories.push(category);

    if (category.children && category.children.length > 0) {
      nestedCategories.push(...getAllNestedCategories(category.children));
    }
  }

  return nestedCategories;
};

export const mapGridStateToCategoriesOrder = (
  categories: Category[]
): UpdateCategoryOrderType[] => {
  const nestedCategories: UpdateCategoryOrderType[] = [];

  for (let i = 0; i < categories.length; i++) {
    const category = categories[i];
    const update = { id: category.id, order: i + 1 };
    nestedCategories.push(update);

    if (category.children && category.children.length > 0) {
      nestedCategories.push(
        ...mapGridStateToCategoriesOrder(category.children)
      );
    }
  }

  return nestedCategories;
};

export const getCategories3FromCategories1 = (categories1: Category[]) => {
  const categories3Temp: Category[] = [];
  for (const category1 of categories1) {
    if (!category1.children) continue;
    for (const category2 of category1.children) {
      if (!category2.children) continue;
      for (const category3 of category2.children) {
        if (categories3Temp.find((c) => c.id === category3.id)) continue;
        categories3Temp.push(category3);
      }
    }
  }
  return categories3Temp;
};
