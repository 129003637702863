import { SortingButtons } from "~/components/UI/SortingButtons";
import { OrderByEnum } from "~/types/common/filter.types";
import { HeadCell } from "~/types/common/headCell.type";
import { OrderHistoryFilterState } from "~/types/data/OrderHistory.types";

import styles from "./index.module.scss";

interface Props {
  headCells: HeadCell[];
  filterState: OrderHistoryFilterState;
  filterChangeHandler: <T extends keyof OrderHistoryFilterState>(
    inputName: T,
    changes: OrderHistoryFilterState[T]
  ) => void;
}

export const HistoryTableHead = ({
  headCells,
  filterState,
  filterChangeHandler,
}: Props) => {
  return (
    <thead>
      <tr>
        {headCells.map((headCell) => {
          const isSortable = headCell.isSortable;
          const label = headCell.label;
          const id = headCell.id;

          return (
            <th key={id}>
              <div className={styles.sortable}>
                <p>{label}</p>
                {isSortable && (
                  <SortingButtons
                    ascendingActive={filterState.order === OrderByEnum.ASC}
                    descendingActive={filterState.order === OrderByEnum.DESC}
                    orderByAscendingingHandler={() => {
                      filterChangeHandler("order", OrderByEnum.ASC);
                    }}
                    orderByDescendingHandler={() => {
                      filterChangeHandler("order", OrderByEnum.DESC);
                    }}
                  />
                )}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};
