import { ApolloError, useMutation } from "@apollo/client";
import { useState } from "react";

import { getMediaUploadPath } from "~/api/graphql/media/getMediaUploadPath";
import {
  PROCESS_SUBSCRIPTION_IMPORT,
  ProcessSubscriptionImportInput,
  ProcessSubscriptionImportResponse,
} from "~/api/graphql/subscriptionImport";
import { CustomButton } from "~/components/form/CustomButton";
import { DragAndDropFilePicker } from "~/components/form/DragAndDropFilePicker";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { SubscriptionImport } from "~/types/data/SubscriptionImport.type";

import styles from "./index.module.scss";

interface Props {
  setLastImportHandler: (value: SubscriptionImport) => void;
  subscriptionId: number;
  hideModal: () => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
}

export const UploadFileModal = ({
  setLastImportHandler,
  subscriptionId,
  hideModal,
  isLoading,
  setIsLoading,
}: Props) => {
  const [file, setFile] = useState<File>();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [processSubscriptionImportTrigger] = useMutation<
    ProcessSubscriptionImportResponse,
    ProcessSubscriptionImportInput
  >(PROCESS_SUBSCRIPTION_IMPORT);

  const fileChangeHandler = (file: File | undefined) => {
    setFile(file);
  };

  const processSubscriptionImportSuccessHandler = (
    data: ProcessSubscriptionImportResponse
  ) => {
    setLastImportHandler(data.processSubscriptionImport);
    hideModal();
  };

  const processSubscriptionImportErrorHandler = (error: ApolloError) => {
    setErrorMessage(error.message);
  };

  const submitHandler = async () => {
    setIsLoading(true);
    setErrorMessage("");
    if (!file) {
      setErrorMessage("Please select a file");
      setIsLoading(true);
      return;
    }
    const fileResponse = await getMediaUploadPath({
      entity: "files",
      field: "subscriptionImport",
      filename: file.name,
    });

    let filePath = "";

    if (fileResponse.success) {
      const { url, path } = fileResponse.data;
      await fetch(url, { method: "put", body: file });
      filePath = path;
    } else {
      setErrorMessage("Uploading didn't work");
      setIsLoading(false);
      return;
    }

    await processSubscriptionImportTrigger({
      variables: {
        ProcessSubscriptionImportInput: {
          filePath,
          subscriptionId,
        },
      },
      onCompleted: processSubscriptionImportSuccessHandler,
      onError: processSubscriptionImportErrorHandler,
    });

    setIsLoading(false);
  };

  return (
    <div className={styles.container}>
      <h2>Upload file</h2>
      <ul className={styles.list}>
        <li>
          <span className={styles.boldText}>Accepted file formats: </span>
          CSV or XLSX
        </li>
        <li>
          <span className={styles.boldText}>Accepted columns: </span>sku,
          minimum_duration, points, purchase_price, priority (optional)
        </li>
      </ul>

      <div className={styles.filePickerContainer}>
        <DragAndDropFilePicker
          id="file"
          onChange={fileChangeHandler}
          value={file?.name}
          height="14rem"
        />
        <div className={styles.errorMessageContainer}>
          <p>{errorMessage}</p>
        </div>
        <div className={styles.submitButtonContainer}>
          <CustomButton
            type="submit"
            backgroundColor={colors.$primary}
            color="white"
            borderRadius="7px"
            width="fit-content"
            padding="0.5rem 2rem"
            disabled={!file || isLoading}
            onClick={() => {
              submitHandler();
            }}
          >
            {isLoading ? <SimpleLoader size="size2" /> : "Submit"}
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
