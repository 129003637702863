import { gql } from "@apollo/client";

import { graphqlGetUserFields } from "~/constants/graphql";
import { AdminUsersFilterArgs } from "~/redux/slice/adminUsers.slice";
import { PermissionLevelEnum, RoleEnum, User } from "~/types/data/User.types";

// inputs
export interface GetAdminUsersInput {
  GetAdminUsersInput: AdminUsersFilterArgs;
}

export interface AdminGetUserByIdInput {
  AdminGetUserByIdInput: { id: number };
}

export interface AdminDeleteUsersInput {
  AdminDeleteUsersInput: { usersIds: number[] };
}

export interface AdminCreateOrUpdateUserInput {
  AdminCreateOrUpdateUserInput: {
    id?: number;
    email?: string;
    phoneNumber?: string;
    username: string;
    preferredUsername?: string;
    permissionLevel?: PermissionLevelEnum;
    role?: RoleEnum;
    companyId?: number;
    deactivated: boolean;
  };
}

// responses
export interface GetAdminUsersResponse {
  getAdminUsers: {
    users: User[];
    nextCursor: number;
    hasMoreData: boolean;
  };
}

export interface GetAdminUsersCountResponse {
  getAdminUsersCount: { count: number };
}

export interface AdminGetUserByIdResponse {
  adminGetUserById: User;
}

export interface AdminDeleteUsersResponse {
  adminDeleteUsers: { count: number };
}

export interface AdminDeleteAllUsersResponse {
  adminDeleteAllUsers: { count: number };
}

export interface AdminCreateOrUpdateUserResponse {
  adminCreateOrUpdateUser: User;
}

// select fields
const userFields = graphqlGetUserFields.join(" ");

// queries and mutations
export const GET_ADMIN_USERS = gql`
  query ($GetAdminUsersInput: GetAdminUsersInput!) {
    getAdminUsers(GetAdminUsersInput: $GetAdminUsersInput) {
      users{
        ${userFields}
        resolveFieldUsername
        companyName
        profile {
          id
        }
      }
      nextCursor
      hasMoreData

    }
  }
`;

export const ADMIN_GET_USER_BY_ID = gql`
  query ($AdminGetUserByIdInput: AdminGetUserByIdInput!) {
    adminGetUserById(AdminGetUserByIdInput: $AdminGetUserByIdInput) {
      ${userFields}
      resolveFieldUsername
      companyName
      profile {
        id
      }
    }
  }
`;

export const GET_ADMIN_USERS_COUNT = gql`
  query ($GetAdminUsersInput: GetAdminUsersInput!) {
    getAdminUsersCount(GetAdminUsersInput: $GetAdminUsersInput) {
      count
    }
  }
`;

export const ADMIN_DELETE_USERS = gql`
  mutation ($AdminDeleteUsersInput: AdminDeleteUsersInput!) {
    adminDeleteUsers(AdminDeleteUsersInput: $AdminDeleteUsersInput) {
      count
    }
  }
`;

export const ADMIN_DELETE_ALL_USERS = gql`
  mutation ($GetAdminUsersInput: GetAdminUsersInput!) {
    adminDeleteAllUsers(GetAdminUsersInput: $GetAdminUsersInput) {
      count
    }
  }
`;

export const ADMIN_CREATE_OR_UPDATE_USER = gql`
  mutation ($AdminCreateOrUpdateUserInput: AdminCreateOrUpdateUserInput!) {
    adminCreateOrUpdateUser(AdminCreateOrUpdateUserInput: $AdminCreateOrUpdateUserInput) {
      ${userFields}
      resolveFieldUsername
      companyName
      profile {
        id
      }
    }
  }
`;
