import { ApolloError, useMutation } from "@apollo/client";
import { useState } from "react";

import {
  UPDATE_SUBSCRIPTION_PRODUCT,
  UpdateSubscriptionProductInput,
} from "~/api/graphql/subscriptionPackage";
import { CustomButton } from "~/components/form/CustomButton";
import { CustomInput } from "~/components/form/CustomInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { SquareImage } from "~/components/UI/SquareImage";
import { colors } from "~/constants/styles";
import { Product } from "~/types/data/Product.types";

import styles from "./index.module.scss";

interface Props {
  product: Product;
  disabled: boolean;
  updateProductsSuccessHandler: (product: Product) => void;
}

export const ProductCard = ({
  product,
  disabled,
  updateProductsSuccessHandler,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formUpdated, setFormUpdated] = useState<boolean>(false);
  const [productState, setProductState] = useState<Product>(product);

  const updateSubscriptionProductErrorHandler = (error: ApolloError) => {
    setErrorMessage(error.message);
  };

  const updateSubscriptionProductSuccessHandler = () => {
    setFormUpdated(false);
    updateProductsSuccessHandler(productState);
  };

  const [updateSubscriptionProduct] = useMutation<
    undefined,
    UpdateSubscriptionProductInput
  >(UPDATE_SUBSCRIPTION_PRODUCT);

  const inputChangeHandler = (
    inputName: keyof Product,
    changes: Product[keyof Product]
  ) => {
    setProductState((prevState) => ({
      ...prevState,
      [inputName]: changes,
    }));
    if (!formUpdated) setFormUpdated(true);
  };

  const updateProductHandler = async () => {
    setIsLoading(true);
    setErrorMessage("");
    await updateSubscriptionProduct({
      variables: {
        UpdateSubscriptionProductInput: {
          minimumEngagement: productState.minimumEngagement ?? 0,
          points: productState.points ?? 0,
          subscriptionPackageConditionsId:
            productState.subscriptionPackageConditionsId ?? 0,
          secondHandPrice: productState.secondHandPrice ?? 0,
        },
      },
      onError: updateSubscriptionProductErrorHandler,
      onCompleted: updateSubscriptionProductSuccessHandler,
    });

    setIsLoading(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <SquareImage url={product.image1FullPath ?? ""} borderRadius="0.5rem" />
      </div>
      <div className={styles.cardContentContainer}>
        <div className={styles.detailsContainer}>
          <h2 className={styles.title}>{product.title}</h2>
        </div>
        <div className={`${styles.inputsSectionsContainer} allow-select`}>
          <div className={styles.inputsContainer}>
            <CustomInput
              onChange={(value: string | number) => {
                inputChangeHandler("points", value);
              }}
              value={productState.points}
              label="Points :"
              borderRadius="5px"
              noBorder={true}
              backgroundColor={colors.$inputGray}
              textColor={colors.$primaryDark}
              fontSize="14px"
              width="25%"
              type="number"
              step={1}
              required={true}
              disabled={disabled}
            />
            <CustomInput
              onChange={(value: string | number) => {
                inputChangeHandler("minimumEngagement", value);
              }}
              value={productState.minimumEngagement}
              label="Minimum Engagement :"
              borderRadius="5px"
              noBorder={true}
              backgroundColor={colors.$inputGray}
              textColor={colors.$primaryDark}
              fontSize="14px"
              width="25%"
              type="number"
              step={1}
              required={true}
              disabled={disabled}
            />
            <CustomInput
              onChange={(value: string | number) => {
                inputChangeHandler("secondHandPrice", value);
              }}
              value={productState.secondHandPrice}
              label="Second hand price :"
              borderRadius="5px"
              noBorder={true}
              backgroundColor={colors.$inputGray}
              textColor={colors.$primaryDark}
              fontSize="14px"
              width="25%"
              type="number"
              step={1}
              required={true}
              disabled={disabled}
            />
            <div>
              <CustomButton
                backgroundColor={colors.$primary}
                color="white"
                width="fit-content"
                borderRadius="8px"
                padding="1rem 1.5rem"
                type="submit"
                disabled={isLoading || !formUpdated}
                height="1rem"
                onClick={() => {
                  updateProductHandler();
                }}
              >
                {isLoading ? <SimpleLoader size="size2" /> : "Enregistrer"}
              </CustomButton>
            </div>
          </div>
          {errorMessage && (
            <div className={styles.errorContainer}>{errorMessage}</div>
          )}
        </div>
      </div>
    </div>
  );
};
