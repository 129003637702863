import { ConfirmModalState } from "~/pages/MesCommandes/RentDetailsDuneCommande/Details";
import { GroupedRentOrderSingleProduct } from "~/types/data/RentOrder.type";

import { GroupedProductCard } from "./GroupedProductCard";
import styles from "./index.module.scss";

interface Props {
  title: string;
  groupedProducts: GroupedRentOrderSingleProduct[];
  onShowModal?: (modalState: ConfirmModalState) => void;
  isAdmin: boolean;
}

export const GroupedProductsList = ({
  groupedProducts,
  title,
  onShowModal,
  isAdmin,
}: Props) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      {groupedProducts.map((groupedProduct) => {
        const showModalHandler = () => {
          if (onShowModal) {
            onShowModal({
              show: true,
              mode: "review",
              isLoading: false,
              groupedProduct,
            });
          }
        };
        return (
          <GroupedProductCard
            key={groupedProduct.id}
            groupedProduct={groupedProduct}
            showModalHandler={showModalHandler}
            isAdmin={isAdmin}
          />
        );
      })}
    </div>
  );
};
