import { Order } from "~/types/data/Order.types";

import OrdersTableRow from "../OrdersTableRow";
import styles from "./index.module.scss";

interface Props {
  orders: Order[];
  selectedRows: Order[];
  onCheckboxChange: (order: Order) => void;
  onRowClick: (id: number) => void;
  showCompanyColumn: boolean;
}

const OrdersTableBody = ({
  orders,
  selectedRows,
  onCheckboxChange,
  onRowClick,
  showCompanyColumn,
}: Props) => {
  const isSelected = (productId?: number) =>
    selectedRows.findIndex(({ id }) => productId === id) > -1;
  return (
    <tbody className={`${styles.tbody}`}>
      {orders.map((order, index) => {
        const isRowSelected = isSelected(order.id);
        return (
          <OrdersTableRow
            key={index.toString()}
            order={order}
            isSelected={isRowSelected}
            onCheckboxChange={onCheckboxChange}
            onClick={onRowClick}
            showCompanyColumn={showCompanyColumn}
          />
        );
      })}
    </tbody>
  );
};

export default OrdersTableBody;
