import { ConfirmModalState } from "~/pages/MesCommandes/RentDetailsDuneCommande/Details";
import { RentOrderToProduct } from "~/types/data/RentOrder.type";
import {
  groupActiveRentOrderSingleProducts,
  groupHistoryRentOrderSingleProducts,
  groupReturningRentOrderSingleProducts,
} from "~/util/functions/groupRentOrderSingleProducts.util";

import { GroupedProductsList } from "./GroupedProductsList";
import styles from "./index.module.scss";

interface Props {
  orderToProducts: RentOrderToProduct[];
  onShowModal: (modalState: ConfirmModalState) => void;
  isAdmin: boolean;
}

export const RentOrderProductsList = ({
  orderToProducts,
  onShowModal,
  isAdmin,
}: Props) => {
  const activeGroupedProducts =
    groupActiveRentOrderSingleProducts(orderToProducts);

  const returningGroupedProducts =
    groupReturningRentOrderSingleProducts(orderToProducts);

  const historyGroupedProducts =
    groupHistoryRentOrderSingleProducts(orderToProducts);
  return (
    <div className={styles.container}>
      <div className={styles.cardsContainer}>
        {!!activeGroupedProducts.length && (
          <GroupedProductsList
            groupedProducts={activeGroupedProducts}
            title="En cours"
            isAdmin={isAdmin}
          />
        )}
        {!!returningGroupedProducts.length && (
          <GroupedProductsList
            groupedProducts={returningGroupedProducts}
            title="Suivre mes retours"
            onShowModal={onShowModal}
            isAdmin={isAdmin}
          />
        )}
        {!!historyGroupedProducts.length && (
          <GroupedProductsList
            groupedProducts={historyGroupedProducts}
            title="Historique"
            isAdmin={isAdmin}
          />
        )}
      </div>
    </div>
  );
};
