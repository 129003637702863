import { memo } from "react";

import { OptionType } from "~/components/form/SelectInput";
import { Category } from "~/types/data/Category.type";
import { VendorCategory } from "~/types/data/VendorCategory.types";

import { DeleteModalProps } from "../..";
import VendorCategoriesMapping from "../VendorCategoriesMapping";
import { useVendorCategories } from "./useVendorCategories";

type Props = {
  vendorCategory: VendorCategory;
  index: number;
  level1Categories: Category[];
  level3Categories: Category[];
  attributes: OptionType[];
  setDeleteModalState: (data: DeleteModalProps) => void;
};
const VendorCategoriesRow = ({
  vendorCategory,
  level1Categories,
  level3Categories,
  attributes,
  setDeleteModalState,
}: Props) => {
  const {
    id,
    name,
    mappings,
    associatedNames,
    attributes: oldAttributes,
  } = vendorCategory;

  const {
    updateVendorCategoryState,
    updateCategoryMappings,
    vendorCategoriesState,
    addNewCategoryMapping,
    deleteCategoryMapping,
    deleteCategory,
    loading,
    saveCategory,
  } = useVendorCategories({
    id,
    name,
    associatedNames,
    attributes: oldAttributes.map((attribute) => attribute.id),
    categoryMappings: mappings?.map((mapping) => ({
      category1Id: mapping.category1Id,
      category2Id: mapping.category2Id,
      category3Id: mapping.category3Id,
    })),
  });

  return (
    <>
      {vendorCategoriesState.categoryMappings?.map((_, index) => {
        return (
          <VendorCategoriesMapping
            key={index}
            index={index}
            level1Categories={level1Categories}
            level3Categories={level3Categories}
            isFirstChild={!index}
            attributes={attributes}
            updateCategoryMappings={updateCategoryMappings}
            updateVendorCategoryState={updateVendorCategoryState}
            vendorCategoryState={vendorCategoriesState}
            addNewCategoryMapping={addNewCategoryMapping}
            deleteCategoryMapping={deleteCategoryMapping}
            deleteCategory={deleteCategory}
            saveCategory={saveCategory}
            loading={loading}
            setDeleteModalState={setDeleteModalState}
          />
        );
      })}
    </>
  );
};

export const MemoizedVendorCategoriesRow = memo(VendorCategoriesRow);
