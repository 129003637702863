import { v4 as uuidv4 } from "uuid";

import {
  CompanyCarrier,
  DeliveryAreaEnum,
  DeliveryAreaState,
  DeliveryDetailsState,
  DeliveryMethodEnum,
  DeliveryPackage,
  GroupedCarrierState,
  GroupedDeliveryState,
} from "~/types/data/Carrier.types";

export const groupDeliveryPackagesMapper = (
  deliveryPackages: DeliveryPackage[],
  deliveryAreaInput: DeliveryAreaEnum,
  deliveryMethodInput: DeliveryMethodEnum
) => {
  const groupedPackages = deliveryPackages.filter(
    ({ deliveryArea, deliveryMethod }) =>
      deliveryArea === deliveryAreaInput &&
      deliveryMethod === deliveryMethodInput
  );

  const packageDetails: DeliveryDetailsState | undefined =
    groupedPackages.length
      ? {
          freeThreshold: groupedPackages[0].freeThreshold,
          maxDays: groupedPackages[0].maxDays,
          minDays: groupedPackages[0].minDays,
          price: groupedPackages[0].price,
        }
      : undefined;

  return packageDetails;
};

export const groupDeliveryPackages = (deliveryPackages: DeliveryPackage[]) => {
  const franceDeliveryPackagesDetails = groupDeliveryPackagesMapper(
    deliveryPackages,
    DeliveryAreaEnum.FRANCE,
    DeliveryMethodEnum.DELIVERY
  );

  const corseDeliveryPackagesDetails = groupDeliveryPackagesMapper(
    deliveryPackages,
    DeliveryAreaEnum.CORSE,
    DeliveryMethodEnum.DELIVERY
  );

  const monacoDeliveryPackagesDetails = groupDeliveryPackagesMapper(
    deliveryPackages,
    DeliveryAreaEnum.MONACO,
    DeliveryMethodEnum.DELIVERY
  );

  const francePickupPackagesDetails = groupDeliveryPackagesMapper(
    deliveryPackages,
    DeliveryAreaEnum.FRANCE,
    DeliveryMethodEnum.PICKUP
  );

  const corsePickupPackagesDetails = groupDeliveryPackagesMapper(
    deliveryPackages,
    DeliveryAreaEnum.CORSE,
    DeliveryMethodEnum.PICKUP
  );

  const monacoPickupPackagesDetails = groupDeliveryPackagesMapper(
    deliveryPackages,
    DeliveryAreaEnum.MONACO,
    DeliveryMethodEnum.PICKUP
  );

  const delivery: DeliveryAreaState = {
    france: franceDeliveryPackagesDetails,
    corse: corseDeliveryPackagesDetails,
    monaco: monacoDeliveryPackagesDetails,
  };

  const pickup: DeliveryAreaState = {
    france: francePickupPackagesDetails,
    corse: corsePickupPackagesDetails,
    monaco: monacoPickupPackagesDetails,
  };

  return { delivery, pickup };
};

export const getDeliveryPackagesByCode = (
  deliveryPackages: DeliveryPackage[]
): GroupedCarrierState[] => {
  const codes = deliveryPackages.reduce<string[]>(
    (a, b) => a.concat(b.code),
    []
  );

  const filteredCodes = Array.from(new Set(codes));

  const groupedDeliveryPackages: Array<Array<DeliveryPackage>> = [];

  for (const deliveryCode of filteredCodes) {
    const packages = deliveryPackages.filter(
      ({ code }) => deliveryCode === code
    );

    groupedDeliveryPackages.push(packages);
  }

  const returnedPackages: GroupedCarrierState[] = groupedDeliveryPackages.map(
    (list) => {
      const { delivery, pickup } = groupDeliveryPackages(list);
      return {
        delivery,
        pickup,
        code: list[0].code,
      };
    }
  );
  return returnedPackages;
};

export const mapToGroupedDeliveryState = (
  companyCarriers: CompanyCarrier[]
): GroupedDeliveryState => {
  const mappedDeliveryState: GroupedDeliveryState = {};

  for (const { carrierId, deliveryPackages } of companyCarriers) {
    const groupedCarrierState = getDeliveryPackagesByCode(deliveryPackages);
    const uuid = uuidv4();
    mappedDeliveryState[uuid] = { groupedCarrierState, carrierId };
  }

  return mappedDeliveryState;
};

export const mapToCompanyCarrierState = (
  groupedDeliveryState: GroupedDeliveryState
): CompanyCarrier[] => {
  const mappedCompanyCarriers: CompanyCarrier[] = [];
  for (const key in groupedDeliveryState) {
    const { carrierId, groupedCarrierState } = groupedDeliveryState[key];
    const mappedDeliveryPackages: DeliveryPackage[] = [];

    for (const groupedCarrier of groupedCarrierState) {
      const deliveryFranceDetails = groupedCarrier.delivery?.france;
      const deliveryFrancePackage: DeliveryPackage | undefined =
        deliveryFranceDetails
          ? {
              code: groupedCarrier.code,
              deliveryMethod: DeliveryMethodEnum.DELIVERY,
              deliveryArea: DeliveryAreaEnum.FRANCE,
              freeThreshold: deliveryFranceDetails.freeThreshold,
              maxDays: deliveryFranceDetails.maxDays,
              minDays: deliveryFranceDetails.minDays,
              price: deliveryFranceDetails.price,
            }
          : undefined;

      const deliveryCorseDetails = groupedCarrier.delivery?.corse;
      const deliveryCorsePackage: DeliveryPackage | undefined =
        deliveryCorseDetails
          ? {
              code: groupedCarrier.code,
              deliveryMethod: DeliveryMethodEnum.DELIVERY,
              deliveryArea: DeliveryAreaEnum.CORSE,
              freeThreshold: deliveryCorseDetails.freeThreshold,
              maxDays: deliveryCorseDetails.maxDays,
              minDays: deliveryCorseDetails.minDays,
              price: deliveryCorseDetails.price,
            }
          : undefined;

      const deliveryMonacoDetails = groupedCarrier.delivery?.monaco;
      const deliveryMonacoPackage: DeliveryPackage | undefined =
        deliveryMonacoDetails
          ? {
              code: groupedCarrier.code,
              deliveryMethod: DeliveryMethodEnum.DELIVERY,
              deliveryArea: DeliveryAreaEnum.MONACO,
              freeThreshold: deliveryMonacoDetails.freeThreshold,
              maxDays: deliveryMonacoDetails.maxDays,
              minDays: deliveryMonacoDetails.minDays,
              price: deliveryMonacoDetails.price,
            }
          : undefined;

      const pickupFranceDetails = groupedCarrier.pickup?.france;
      const pickupFrancePackage: DeliveryPackage | undefined =
        pickupFranceDetails
          ? {
              code: groupedCarrier.code,
              deliveryMethod: DeliveryMethodEnum.PICKUP,
              deliveryArea: DeliveryAreaEnum.FRANCE,
              freeThreshold: pickupFranceDetails.freeThreshold,
              maxDays: pickupFranceDetails.maxDays,
              minDays: pickupFranceDetails.minDays,
              price: pickupFranceDetails.price,
            }
          : undefined;

      const pickupCorseDetails = groupedCarrier.pickup?.corse;
      const pickupCorsePackage: DeliveryPackage | undefined = pickupCorseDetails
        ? {
            code: groupedCarrier.code,
            deliveryMethod: DeliveryMethodEnum.PICKUP,
            deliveryArea: DeliveryAreaEnum.CORSE,
            freeThreshold: pickupCorseDetails.freeThreshold,
            maxDays: pickupCorseDetails.maxDays,
            minDays: pickupCorseDetails.minDays,
            price: pickupCorseDetails.price,
          }
        : undefined;

      const pickupMonacoDetails = groupedCarrier.pickup?.monaco;
      const pickupMonacoPackage: DeliveryPackage | undefined =
        pickupMonacoDetails
          ? {
              code: groupedCarrier.code,
              deliveryMethod: DeliveryMethodEnum.PICKUP,
              deliveryArea: DeliveryAreaEnum.MONACO,
              freeThreshold: pickupMonacoDetails.freeThreshold,
              maxDays: pickupMonacoDetails.maxDays,
              minDays: pickupMonacoDetails.minDays,
              price: pickupMonacoDetails.price,
            }
          : undefined;

      if (deliveryFrancePackage)
        mappedDeliveryPackages.push(deliveryFrancePackage);

      if (deliveryCorsePackage)
        mappedDeliveryPackages.push(deliveryCorsePackage);

      if (deliveryMonacoPackage)
        mappedDeliveryPackages.push(deliveryMonacoPackage);

      if (pickupFrancePackage) mappedDeliveryPackages.push(pickupFrancePackage);

      if (pickupCorsePackage) mappedDeliveryPackages.push(pickupCorsePackage);

      if (pickupMonacoPackage) mappedDeliveryPackages.push(pickupMonacoPackage);
    }

    mappedCompanyCarriers.push({
      carrierId,
      deliveryPackages: mappedDeliveryPackages,
    });
  }
  return mappedCompanyCarriers;
};
