import CustomCheckbox from "~/components/form/CustomCheckbox";
import { SortingButtons } from "~/components/UI/SortingButtons";
import { OrderBy, OrderByEnum } from "~/types/common/filter.types";
import { HeadCell } from "~/types/common/headCell.type";
import { RentOrder } from "~/types/data/RentOrder.type";

import styles from "./index.module.scss";

interface Props {
  headCells: HeadCell<RentOrder>[];
  onSelectAllChange: (selected: boolean) => void;
  allSelected: boolean;
  orderBy: OrderBy<RentOrder>;
  orderByHandler: (key: keyof RentOrder, order: OrderByEnum) => void;
}

export const RentOrdersTableHead = ({
  headCells,
  allSelected,
  onSelectAllChange,
  orderBy,
  orderByHandler,
}: Props) => {
  const selectAllChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onSelectAllChange(event.target.checked);
  };

  return (
    <thead>
      <tr>
        {headCells.map((headCell) => {
          const isSortable = headCell.isSortable;
          const isCheckbox = headCell.isCheckbox;
          const label = headCell.label;
          const id = headCell.id;

          if (isCheckbox) {
            return (
              <th key={id}>
                <CustomCheckbox
                  onChange={selectAllChangeHandler}
                  checked={allSelected}
                  padding="0.7rem"
                />
              </th>
            );
          }

          return (
            <th key={id}>
              <div className={styles.sortable}>
                <p>{label}</p>
                {isSortable && (
                  <SortingButtons
                    ascendingActive={
                      !!(
                        headCell.associatedKey &&
                        orderBy[headCell.associatedKey] === OrderByEnum.ASC
                      )
                    }
                    descendingActive={
                      !!(
                        headCell.associatedKey &&
                        orderBy[headCell.associatedKey] === OrderByEnum.DESC
                      )
                    }
                    orderByAscendingingHandler={() => {
                      orderByHandler(
                        headCell.associatedKey || "id",
                        OrderByEnum.ASC
                      );
                    }}
                    orderByDescendingHandler={() => {
                      orderByHandler(
                        headCell.associatedKey || "id",
                        OrderByEnum.DESC
                      );
                    }}
                  />
                )}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};
