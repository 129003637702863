import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { OrderBy } from "~/types/common/filter.types";
import { Order } from "~/types/data/Order.types";
import { RentOrder } from "~/types/data/RentOrder.type";

import type { RootState } from "../store";

export interface RentOrdersFilterArgs {
  orderBy: OrderBy<RentOrder>;
  cursor?: number;
  take?: number;
  // status?: OrderStatusEnum;
  // carrierId?: string;
  // createdAtFrom?: string;
  // createdAtTo?: string;
  // shippingDateFrom?: string;
  // shippingDateTo?: string;
  // finalTotalPriceFrom?: number;
  // finalTotalPriceTo?: number;
  // hasIncident?: boolean;
  // hasRefund?: boolean;
  // hasMessages?: boolean;
  // pending?: boolean;
  searchBox?: string;
  companiesIds?: number[];
}

type FilterArgs =
  | "orderBy"
  | "cursor"
  // | "status"
  // | "carrierId"
  // | "createdAtFrom"
  // | "createdAtTo"
  // | "shippingDateFrom"
  // | "shippingDateTo"
  // | "finalTotalPriceFrom"
  // | "finalTotalPriceTo"
  // | "hasIncident"
  // | "hasRefund"
  // | "hasMessages"
  // | "pending"
  | "companiesIds"
  | "searchBox"
  | "clearAll";

export const rentOrdersFilterInitialState: RentOrdersFilterArgs = {
  orderBy: {},
  take: 30,
};

export const rentOrdersFilterSlice = createSlice({
  name: "rentOrdersFilter",
  initialState: rentOrdersFilterInitialState,
  reducers: {
    onFilterArgsChange(
      state,
      action: PayloadAction<{
        filterArg: FilterArgs;
        orderBy?: OrderBy<Order>;
        cursor?: number;
        take?: number;
        // status?: OrderStatusEnum;
        // carrierId?: string;
        // createdAtFrom?: string;
        // createdAtTo?: string;
        // shippingDateFrom?: string;
        // shippingDateTo?: string;
        // finalTotalPriceFrom?: number;
        // finalTotalPriceTo?: number;
        // hasIncident?: boolean;
        // hasRefund?: boolean;
        // hasMessages?: boolean;
        // pending?: boolean;
        searchBox?: string;
        companiesIds?: number[];
      }>
    ) {
      const {
        filterArg,
        cursor,
        orderBy,
        // status,
        // carrierId,
        // createdAtFrom,
        // createdAtTo,
        // hasIncident,
        // hasMessages,
        // hasRefund,
        // shippingDateFrom,
        // shippingDateTo,
        // finalTotalPriceFrom,
        // finalTotalPriceTo,
        // pending,
        searchBox,
        companiesIds,
      } = action.payload;
      if (filterArg === "cursor" && cursor) {
        state.cursor = cursor;
      } else if (filterArg === "orderBy" && orderBy) {
        state.orderBy = orderBy;
      } else if (filterArg === "searchBox") {
        state.searchBox = searchBox;
      } else if (filterArg === "companiesIds") {
        state.companiesIds = companiesIds;
      } else if (filterArg === "clearAll") {
        return { ...rentOrdersFilterInitialState };
      }
    },
  },
});

export const { onFilterArgsChange } = rentOrdersFilterSlice.actions;

export const selectRentOrdersFilterArgs = (state: RootState) => {
  return state.rentOrdersFilter;
};
export default rentOrdersFilterSlice.reducer;
