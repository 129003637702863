import { gql } from "@apollo/client";

import { graphqlGetCollectionImportFields } from "~/constants/graphql";
import { CollectionImport } from "~/types/data/CollectionImport.type";

export interface ProcessCollectionImportInput {
  ProcessCollectionImportInput: {
    filePath: string;
    collectionId: number;
  };
}

export interface ProcessCollectionImportResponse {
  processCollectionImport: CollectionImport;
}

export interface GetLastCollectionImportResponse {
  getLastCollectionImport: CollectionImport;
}

const getCollectionImportSelectFields =
  graphqlGetCollectionImportFields.join(" ");

export const PROCESS_COLLECTION_IMPORT = gql`
  mutation ($ProcessCollectionImportInput: ProcessCollectionImportInput!) {
    processCollectionImport(
      ProcessCollectionImportInput: $ProcessCollectionImportInput
    ) {
      ${getCollectionImportSelectFields}
    }
  }
`;

export const GET_LAST_COLLECTION_IMPORT = gql`
  query  {
    getLastCollectionImport {
      ${getCollectionImportSelectFields}
    }
  }
`;
