export enum ImportModeEnum {
  OVERRIDE = "OVERRIDE",
  UPSERT = "UPSERT",
}
export enum ImportProtocolEnum {
  SFTP = "SFTP",
  FTP = "FTP",
}
export interface FtpImport {
  id: number;
  port: number;
  host: string;
  username: string;
  password: string;
  error?: string;
  filePath: string;
  active: boolean;
  protocol: ImportProtocolEnum;
  companyId: number;
}
