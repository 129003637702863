import { NavLink } from "react-router-dom";

import { SquareImage } from "~/components/UI/SquareImage";
import { routesBuilder } from "~/navigation/routes";
import { SubscriptionPackage } from "~/types/data/SubscriptionPackage.type";
import { formatPrice } from "~/util/functions/formatDecimal";

import styles from "./index.module.scss";

interface Props {
  subscriptionPackage: SubscriptionPackage;
}

export const SubscriptionPackageCard = ({ subscriptionPackage }: Props) => {
  const { webPicturePath, name, description, startingPrice } =
    subscriptionPackage;

  const formattedStartingPrice = formatPrice(startingPrice);
  const startingPriceText = `A partir de ${formattedStartingPrice} / mois`;

  const maMarqueToAbonnementToId = routesBuilder({
    routes: ["maMarqueRoute", "abonnementRoute"],
  });

  return (
    <NavLink
      to={`/${maMarqueToAbonnementToId}/${subscriptionPackage.id}`}
      className={styles.navLink}
    >
      <div className={styles.cardContainer}>
        <div className={styles.imageContainer}>
          <SquareImage url={webPicturePath} borderRadius="0.5rem" />
        </div>
        <div className={styles.detailsContainer}>
          <h2 className={styles.title}>{name}</h2>
          <p className={styles.priceContainer}>{startingPriceText}</p>
          <div>
            {description.map((paragraph, index) => {
              return <p key={index}>{paragraph}</p>;
            })}
          </div>
        </div>
      </div>
    </NavLink>
  );
};
