import { ReedooCarrier } from "~/types/data/Carrier.types";

export const deletedReedooCarrier = (
  reedooCarriers: ReedooCarrier[],
  carrierIndex: number
) => {
  const updatedReedooCarriers: ReedooCarrier[] = reedooCarriers.filter(
    (carrier, index) => {
      return index !== carrierIndex;
    }
  );

  return {
    updatedReedooCarriers: updatedReedooCarriers,
  };
};
