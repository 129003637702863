import { Attribute } from "~/types/data/Attribute.types";

import styles from "./index.module.scss";

interface Props {
  attribute: Attribute;
  clickHandler: (attribute: Attribute) => void;
}

export const AttributeCard = ({ attribute, clickHandler }: Props) => {
  return (
    <div className={styles.container} onClick={() => clickHandler(attribute)}>
      <p className={styles.attributeName}>{attribute.name}</p>
    </div>
  );
};
