import { AdminDataPlatformNavigation } from "./AdminDataPlatformNavigation";
import { Attributes } from "./Attributes";
import styles from "./index.module.scss";
import { Taxonomie } from "./Taxonomie";
import { VendorCategories } from "./VendorCategories";

export enum AdminDataPlatformPagesEnum {
  TAXONOMIE = "TAXONOMIE",
  VENDOR_CATEGORIES = "VENDOR_CATEGORIES",
  ATTRIBUTES = "ATTRIBUTES",
}

interface Props {
  page: AdminDataPlatformPagesEnum;
}

export const AdminDataPlatform = ({ page }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.nav}>
        <AdminDataPlatformNavigation />
      </div>
      <div className={styles.contentContainerParent}>
        <h2>Gérer mon compte</h2>
        <div className={`${styles.contentContainer}`}>
          {page === AdminDataPlatformPagesEnum.TAXONOMIE ? (
            <Taxonomie />
          ) : page === AdminDataPlatformPagesEnum.VENDOR_CATEGORIES ? (
            <VendorCategories />
          ) : page === AdminDataPlatformPagesEnum.ATTRIBUTES ? (
            <Attributes />
          ) : (
            <>test2</>
          )}
        </div>
      </div>
    </div>
  );
};
