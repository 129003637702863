import { Product } from "~/types/data/Product.types";
import { SubscriptionCategory } from "~/types/data/SubscriptionPackage.type";

import styles from "./index.module.scss";

interface Props {
  subscriptionCategory: SubscriptionCategory;
  products: Product[];
  categoryLevel: number;
}

export const CategoryCard = ({
  subscriptionCategory,
  products,
  categoryLevel,
}: Props) => {
  const isOthers = subscriptionCategory.categoryId === 0;
  const filteredProducts = products.filter((product) => {
    const category =
      categoryLevel === 3 ? product.category3 : product.category2;
    return isOthers
      ? !category
      : category?.id === subscriptionCategory.categoryId;
  });
  return (
    <div className={styles.cardContainer}>
      <h3>{subscriptionCategory.name}</h3>
      <h3>{filteredProducts.length}</h3>
    </div>
  );
};
