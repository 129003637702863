import { gql } from "@apollo/client";

import {
  ForeignShop,
  ForeignShopWithCount,
} from "~/types/data/ForeignShop.types";

export interface GetForeignShopResponse {
  getForeignShops: ForeignShopWithCount[];
}

export interface UpdateForeignShopResponse {
  updateForeignShop: ForeignShop;
}

export interface UpdateForeignShopInput {
  UpdateForeignShopInput: Omit<
    ForeignShop,
    "newShopId" | "name" | "provider" | "companyId"
  >;
}

export interface DeleteForeignShopResponse {
  deleteForeignShop: ForeignShop;
}

export interface DeleteForeignShopInput {
  DeleteForeignShopInput: {
    id: number;
  };
}

export const getForeignShops = gql`
  query {
    getForeignShops {
      id
      name
      provider
      updateQuantity
      updatePrice
      updateDiscount
      updateOrders
      _count {
        foreignShopMapping
      }
    }
  }
`;

export const updateForeignShop = gql`
  mutation ($UpdateForeignShopInput: UpdateForeignShopInput!) {
    updateForeignShop(UpdateForeignShopInput: $UpdateForeignShopInput) {
      id
    }
  }
`;

export const deleteForeignShop = gql`
  mutation ($DeleteForeignShopInput: DeleteForeignShopInput!) {
    deleteForeignShop(DeleteForeignShopInput: $DeleteForeignShopInput) {
      id
    }
  }
`;
