import { Avatar, Popover } from "@mui/material";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { logout } from "~/api/auth/login";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useUserContext } from "~/context/userContext";
import { routePaths } from "~/navigation/routes";
import { RoleEnum } from "~/types/data/User.types";

import SVGContainer from "../SVGContainer";
import styles from "./index.module.scss";
const ProfileIcon = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { userState, setUserState } = useUserContext();

  const { companyAccount } = useCompanyAccountContext();

  const navigate = useNavigate();

  const {
    loginRoute,
    synchronizationOfStreamsRoute,
    platformDataRoute,
    adminPlatformDataRoute,
  } = routePaths;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutHandler = () => {
    logout();
    setUserState({ connected: false });
    navigate(`/${loginRoute}`, { replace: true });
  };

  const isOpen = !!anchorEl;

  const isAdmin = userState?.connected && userState.role === RoleEnum.ADMIN;

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.avatar}`}>
        <Avatar src={companyAccount.logoFullPath} />
      </div>
      <div className={`${styles.icon}`} onClick={handleClick}>
        <SVGContainer
          height="8px"
          width="14px"
          imagePath="/assets/drop-down-icon.svg"
        />
      </div>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={`${styles.dropdownContent}`}>
          {isAdmin ? (
            <NavLink
              className={styles.navLink}
              to={adminPlatformDataRoute}
              onClick={handleClose}
            >
              Donnée plateforme
            </NavLink>
          ) : (
            <NavLink
              className={styles.navLink}
              to={platformDataRoute}
              onClick={handleClose}
            >
              Donnée plateforme
            </NavLink>
          )}
          <NavLink
            className={styles.navLink}
            to={synchronizationOfStreamsRoute}
            onClick={handleClose}
          >
            Synchronisation des flux
          </NavLink>

          <p onClick={logoutHandler}>Déconnexion</p>
        </div>
      </Popover>
    </div>
  );
};

export default ProfileIcon;
