import Joi from "joi";

import { UpdateCompanyUboDeclarationInput } from "~/types/data/Company.types";
import { UBO } from "~/types/data/UBO.types";

const ubo = Joi.object<UBO>().keys({
  id: Joi.optional(),
  mangopayId: Joi.optional(),
  active: Joi.optional(),
  lastName: Joi.string().required().error(new Error("Nom can't be empty.")),
  firstName: Joi.string().required().error(new Error("Prénom can't be empty.")),
  address: Joi.string().required().error(new Error("Adresse can't be empty.")),
  zipCode: Joi.string()
    .required()
    .error(new Error("Code postal can't be empty.")),
  city: Joi.string().required().error(new Error("Ville can't be empty.")),
  region: Joi.string().required().error(new Error("Région can't be empty.")),
  country: Joi.string().required().error(new Error("Pays can't be empty.")),
  nationality: Joi.string()
    .required()
    .error(new Error("Nationalité can't be empty.")),
  birthDate: Joi.string()
    .required()
    .error(new Error("Date de naissance can't be empty.")),
  birthPlace: Joi.string()
    .required()
    .error(new Error("Ville de naissance can't be empty.")),
  birthCountry: Joi.string()
    .required()
    .error(new Error("Pays de naissance can't be empty.")),
});
export const updateUboDeclarationValidationSchema =
  Joi.object<UpdateCompanyUboDeclarationInput>({
    id: Joi.optional(),
    ubos: Joi.array().items(ubo),
  });
