import { memo, useCallback, useState } from "react";
import { SingleValue } from "react-select";

import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { colors } from "~/constants/styles";
import { useAppDispatch } from "~/redux/hooks";
import { onMappingChange } from "~/redux/slice/mappedAttributesValues.slice";
import { AttributeValue } from "~/types/data/AttributeValue.types";
import { Product } from "~/types/data/Product.types";

interface Props<K extends keyof Product> {
  attributeKey: K;
  value: Product[K];
  attributeValueKey: string;
  options?: OptionType[];
  attributeValues: AttributeValue<Product[K]>[];
  onChangeOptions?: (change: string) => void;
  onStateHasChanged: (state: number) => void;
}

export const AttributeSelectInput = <K extends keyof Product>({
  attributeKey,
  attributeValueKey,
  value,
  attributeValues,
  onChangeOptions,
  options = [],
  onStateHasChanged,
}: Props<K>) => {
  const [currentValue, setCurrentValue] = useState<Product[K]>(value);
  const dispatch = useAppDispatch();
  const onChange = useCallback((change: string) => {
    const splitValues = change.split("||");
    const value = splitValues?.length ? splitValues[0] : undefined;
    const mappedAttributeValue = attributeValues.find(
      ({ value: attributeValue }) => attributeValue === value
    );
    setCurrentValue(change as Product[K]);
    if (onChangeOptions) onChangeOptions(change);

    onStateHasChanged(3);
    dispatch(
      onMappingChange({
        attributeValueName: attributeValueKey,
        attributeKey,
        mappedAttributeValue,
      })
    );
  }, []);

  return (
    <>
      <SelectInput
        onChange={(option: SingleValue<OptionType>) => {
          onChange(option?.value || "");
        }}
        options={options}
        value={currentValue?.toString() || ""}
        backgroundColor={colors.$inputGray}
        fontSize="12px"
        hasDefault={true}
        defaultLabel="Not selected"
        invalidColor="#870000"
      />
    </>
  );
};

export const MemoizedAttributeSelectInput = memo(AttributeSelectInput);
