import SVGContainer from "~/components/UI/SVGContainer";
import { AttributeValue } from "~/types/data/AttributeValue.types";

import styles from "./index.module.scss";

interface Props {
  attributeValue: AttributeValue;
  showEditModalHandler: (attributeValue: AttributeValue) => void;
  showDeleteModalHandler: (attributeValue: AttributeValue) => void;
  index: number;
}

export const ValueRow = ({
  attributeValue,
  showEditModalHandler,
  showDeleteModalHandler,
  index,
}: Props) => {
  return (
    <div className={styles.container}>
      <p>{`${index + 1}. ${attributeValue.value}`}</p>
      <SVGContainer
        height="14px"
        width="14px"
        imagePath="/assets/edit-button.svg"
        onClick={() => {
          showEditModalHandler(attributeValue);
        }}
      />
      <SVGContainer
        height="14px"
        width="14px"
        imagePath="/assets/delete-icon.svg"
        onClick={() => {
          showDeleteModalHandler(attributeValue);
        }}
      />
    </div>
  );
};
