import { useLazyQuery, useQuery } from "@apollo/client";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useState } from "react";
import { utils, writeFile } from "xlsx";

import {
  GET_MY_ACCOUNTING_STATS,
  GET_MY_PAYOUTS,
  GET_MY_PAYOUTS_COUNT,
  GET_MY_TRANSACTIONS,
  GET_MY_TRANSACTIONS_COUNT,
  GetMyAccountingStatsResponse,
  GetMyPayOutsCountResponse,
  GetMyPayOutsInput,
  GetMyPayOutsResponse,
  GetMyTransactionsCountResponse,
  GetMyTransactionsInput,
  GetMyTransactionsResponse,
} from "~/api/graphql/accounting";
import { InputWithIcon } from "~/components/form/InputWithIcon";
import CustomNavButton from "~/components/navigation/CustomNavButton";
import { ErrorPopup } from "~/components/UI/ErrorPopup";
import FullPageLoader from "~/components/UI/FullPageLoader";
import { LoadingPopup } from "~/components/UI/LoadingPopup";
import SVGContainer from "~/components/UI/SVGContainer";
import { colors } from "~/constants/styles";
import { routesBuilder } from "~/navigation/routes";
import { useAppDispatch, useAppSelector } from "~/redux/hooks";
import {
  onFilterArgsChange,
  selectAccountingFilterArgs,
} from "~/redux/slice/accounting.slice";
import { MyPayOut, MyTransaction } from "~/types/data/Accounting.types";
import { formatDecimal } from "~/util/functions/formatDecimal";

import styles from "./index.module.scss";
import { MemoizedPayOutsPage } from "./PayOutsPage";
import { MemoizedTransactionsPage } from "./TransactionsPage";

interface Props {
  mode: "factures" | "transactions";
}

export const MaComptabilite = ({ mode }: Props) => {
  // payouts
  const [myPayOuts, setMyPayOuts] = useState<MyPayOut[]>();
  const [hasMorePayOuts, setHasMorePayOuts] = useState(false);
  const [myPayOutsCount, setMyPayOutsCount] = useState<number>();
  const [selectedPayOuts, setSelectedPayOuts] = useState<MyPayOut[]>([]);
  const [isSelectedAllPayOuts, setIsSelectedAllPayOuts] = useState(false);

  // transactions
  const [myTransactions, setMyTransactions] = useState<MyTransaction[]>();
  const [hasMoreTransactions, setHasMoreTransactions] = useState(false);
  const [myTransactionsCount, setMyTransactionsCount] = useState<number>();
  const [selectedTransactions, setSelectedTransactions] = useState<
    MyTransaction[]
  >([]);
  const [isSelectedAllTransactions, setIsSelectedAllTransactions] =
    useState(false);

  const [searchBox, setSearchBox] = useState("");
  const [isPreparingFile, setIsPreparingFile] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState("");

  const filterArgsState = useAppSelector(selectAccountingFilterArgs);
  const dispatch = useAppDispatch();

  const maComptabiliteToFacturesRoute = routesBuilder({
    routes: ["maComptabiliteRoute", "facturesRoute"],
  });

  const maComptabiliteToTransactionsRoute = routesBuilder({
    routes: ["maComptabiliteRoute", "transactionsRoute"],
  });

  // useQuery
  const [getMyTransactionsCountTrigger] = useLazyQuery<
    GetMyTransactionsCountResponse,
    GetMyTransactionsInput
  >(GET_MY_TRANSACTIONS_COUNT);

  const [getMyTransactionsTrigger, { loading: getMyTransactionsLoading }] =
    useLazyQuery<GetMyTransactionsResponse, GetMyTransactionsInput>(
      GET_MY_TRANSACTIONS
    );

  const [getMyPayOutsCountTrigger] = useLazyQuery<
    GetMyPayOutsCountResponse,
    GetMyPayOutsInput
  >(GET_MY_PAYOUTS_COUNT);

  const [getMyPayOutsTrigger, { loading: getMyPayOutsLoading }] = useLazyQuery<
    GetMyPayOutsResponse,
    GetMyPayOutsInput
  >(GET_MY_PAYOUTS);

  const { data: accountingStatsData } = useQuery<GetMyAccountingStatsResponse>(
    GET_MY_ACCOUNTING_STATS
  );

  // handlers
  const fetchMyTransactions = async (init: boolean) => {
    if (getMyTransactionsLoading) return;
    const {
      transactionsOrderBy,
      payOutsOrderBy: _,
      ...filterArgs
    } = filterArgsState;

    if (init) {
      setMyTransactions(undefined);
      setMyTransactionsCount(undefined);
      dispatch(
        onFilterArgsChange({
          filterArg: "cursor",
          cursor: undefined,
        })
      );
      setIsSelectedAllTransactions(false);
      setSelectedTransactions([]);

      const { data } = await getMyTransactionsCountTrigger({
        variables: {
          GetMyTransactionsInput: {
            ...filterArgs,
            cursor: undefined,
          },
        },
      });

      if (data) {
        setMyTransactionsCount(data.getMyTransactionsCount.count);
      }
    }
    const { data, error } = await getMyTransactionsTrigger({
      variables: {
        GetMyTransactionsInput: {
          ...filterArgs,
          orderBy: transactionsOrderBy,
          ...(init && { cursor: undefined }),
        },
      },
    });

    if (data) {
      const { getMyTransactions } = data;
      const { hasMoreData, nextCursor, myTransactions } = getMyTransactions;
      setHasMoreTransactions(hasMoreData);
      dispatch(onFilterArgsChange({ filterArg: "cursor", cursor: nextCursor }));

      if (isSelectedAllTransactions) {
        setSelectedTransactions((prevSelectedTransactions) => [
          ...prevSelectedTransactions,
          ...myTransactions,
        ]);
      }

      setMyTransactions((prevTransactions) =>
        prevTransactions
          ? [...prevTransactions, ...myTransactions]
          : [...myTransactions]
      );
    } else if (error) {
      const { message } = error;
      setErrorModalMessage(message);
    }
  };

  const fetchMyPayOuts = async (init: boolean) => {
    if (getMyPayOutsLoading) return;
    const {
      payOutsOrderBy,
      transactionsOrderBy: _,
      ...filterArgs
    } = filterArgsState;

    if (init) {
      setMyPayOuts(undefined);
      setMyPayOutsCount(undefined);
      dispatch(
        onFilterArgsChange({
          filterArg: "cursor",
          cursor: undefined,
        })
      );
      setIsSelectedAllPayOuts(false);
      setSelectedPayOuts([]);

      const { data } = await getMyPayOutsCountTrigger({
        variables: {
          GetMyPayOutsInput: {
            ...filterArgs,
            cursor: undefined,
          },
        },
      });

      if (data) {
        setMyPayOutsCount(data.getMyPayOutsCount.count);
      }
    }
    const { data, error } = await getMyPayOutsTrigger({
      variables: {
        GetMyPayOutsInput: {
          ...filterArgs,
          orderBy: payOutsOrderBy,
          ...(init && { cursor: undefined }),
        },
      },
    });

    if (data) {
      const { getMyPayOuts } = data;
      const { hasMoreData, nextCursor, myPayOuts } = getMyPayOuts;
      setHasMorePayOuts(hasMoreData);
      dispatch(onFilterArgsChange({ filterArg: "cursor", cursor: nextCursor }));

      if (isSelectedAllPayOuts) {
        setSelectedPayOuts((prevSelectedPayOuts) => [
          ...prevSelectedPayOuts,
          ...myPayOuts,
        ]);
      }

      setMyPayOuts((prevPayOuts) =>
        prevPayOuts ? [...prevPayOuts, ...myPayOuts] : [...myPayOuts]
      );
    } else if (error) {
      const { message } = error;
      setErrorModalMessage(message);
    }
  };

  const searchBoxChangeHandler = useCallback(
    debounce((value: string) => {
      dispatch(
        onFilterArgsChange({
          filterArg: "searchBox",
          searchBox: value,
        })
      );
    }, 500),
    []
  );

  const exportMyTransactions = async () => {
    const {
      transactionsOrderBy,
      payOutsOrderBy: _,
      ...filterArgs
    } = filterArgsState;

    const transactionsToExport: MyTransaction[] = [];
    if (isSelectedAllTransactions) {
      const { data, error } = await getMyTransactionsTrigger({
        variables: {
          GetMyTransactionsInput: {
            ...filterArgs,
            orderBy: transactionsOrderBy,
            cursor: undefined,
            take: undefined,
          },
        },
      });
      if (data) {
        transactionsToExport.push(...data.getMyTransactions.myTransactions);
      } else if (error) {
        const { message } = error;
        setErrorModalMessage(message);
        return;
      }
    } else {
      transactionsToExport.push(...selectedTransactions);
    }

    const modifiedTransactions = transactionsToExport.map(
      ({
        id,
        createdAt,
        totalAmount,
        originalAmount,
        productsVat,
        shippingAmount,
        refundAmount,
        commission,
        commissionVat,
        status,
        products,
      }) => {
        const totalSum =
          originalAmount +
          shippingAmount -
          refundAmount -
          commission -
          commissionVat;

        const productData: { [key: string]: string } = {};
        for (const [index, product] of products.entries()) {
          productData[`product_${index + 1}_id`] = product.id.toString();
          productData[`product_${index + 1}_sku`] = product.sku || "";
          productData[`product_${index + 1}_quantity`] =
            product.quantity.toString();
        }

        return {
          "N° commande": id,
          Date: createdAt,
          status,
          "Montant Original": originalAmount,
          "Montant HT": totalAmount,
          "Product VAT": productsVat,
          "Frais de port": shippingAmount,
          Remboursement: refundAmount,
          Commission: commission,
          "Commission VAT": commissionVat,
          "Total VAT Excluded": totalSum,
          ...productData,
        };
      }
    );

    // create file using xlsx
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(modifiedTransactions);

    // make cells wrap text
    utils.book_append_sheet(wb, ws, "Transactions");
    setIsPreparingFile(false);
    writeFile(wb, "Transactions.xlsx");
  };

  const exportMyTransactionsHandler = () => {
    setIsPreparingFile(true);
    setTimeout(() => {
      exportMyTransactions();
    }, 500);
  };

  const exportMyPayOuts = async () => {
    const {
      payOutsOrderBy,
      transactionsOrderBy: _,
      ...filterArgs
    } = filterArgsState;

    const payOutsToExport: MyPayOut[] = [];
    if (isSelectedAllPayOuts) {
      const { data, error } = await getMyPayOutsTrigger({
        variables: {
          GetMyPayOutsInput: {
            ...filterArgs,
            orderBy: payOutsOrderBy,
            cursor: undefined,
            take: undefined,
          },
        },
      });
      if (data) {
        payOutsToExport.push(...data.getMyPayOuts.myPayOuts);
      } else if (error) {
        const { message } = error;
        setErrorModalMessage(message);
        return;
      }
    } else {
      payOutsToExport.push(...selectedPayOuts);
    }

    // create file using xlsx
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(payOutsToExport);

    // make cells wrap text
    utils.book_append_sheet(wb, ws, "PayOuts");
    setIsPreparingFile(false);
    writeFile(wb, "PayOuts.xlsx");
  };

  const exportMyPayOutsHandler = () => {
    setIsPreparingFile(true);
    setTimeout(() => {
      exportMyPayOuts();
    }, 500);
  };

  const hideErrorPopupHandler = () => {
    setErrorModalMessage("");
    if (mode === "factures") {
      fetchMyPayOuts(true);
    } else {
      fetchMyTransactions(true);
    }
  };

  useEffect(() => {
    setMyPayOuts(undefined);
    setHasMorePayOuts(false);
    setMyPayOutsCount(undefined);
    setSelectedPayOuts([]);
    setIsSelectedAllPayOuts(false);
    setMyTransactions(undefined);
    setHasMoreTransactions(false);
    setMyTransactionsCount(undefined);
    setSelectedTransactions([]);
    setIsSelectedAllTransactions(false);
    setSearchBox("");
  }, [mode]);

  useEffect(
    () => () => {
      dispatch(
        onFilterArgsChange({
          filterArg: "clearAll",
        })
      );
    },
    []
  );

  return (
    <div className={styles.container}>
      <LoadingPopup show={isPreparingFile} message="Preparing file..." />
      <ErrorPopup
        onCancel={hideErrorPopupHandler}
        show={!!errorModalMessage}
        message={errorModalMessage}
      />
      <div className={styles.header}>
        <div className={styles.headersSub}>
          <h2>Comptabilité</h2>
          <CustomNavButton
            label="Mes factures"
            to={`/${maComptabiliteToFacturesRoute}`}
            padding="0.5rem 1rem"
            backgroundColor="rgba(225, 139, 117, 0)"
            activeBackgroundColor={colors.$primary}
            activeTextColor="white"
            borderRadius="5px"
            borderColor={colors.$primary}
            textColor={colors.$primary}
          />
          <CustomNavButton
            label="Mes transactions"
            to={`/${maComptabiliteToTransactionsRoute}`}
            padding="0.5rem 1rem"
            backgroundColor="rgba(225, 139, 117, 0)"
            activeBackgroundColor={colors.$primary}
            activeTextColor="white"
            borderRadius="5px"
            borderColor={colors.$primary}
            textColor={colors.$primary}
          />
          <div
            className={`${styles.exportButton}`}
            onClick={
              mode === "factures"
                ? exportMyPayOutsHandler
                : exportMyTransactionsHandler
            }
          >
            <SVGContainer
              height="16px"
              width="17px"
              imagePath="/assets/export-icon.svg"
            />
            <p>Exporter</p>
          </div>
        </div>
        <div className={styles.searchBoxContainer}>
          <InputWithIcon
            onChange={(value: string | number) => {
              setSearchBox(value.toString());
              searchBoxChangeHandler(value.toString());
            }}
            value={searchBox}
            borderRadius="5px"
            noBorder={true}
            backgroundColor="white"
            iconPath="/assets/search-icon.svg"
            placeholder="Chercher une commande..."
          />
        </div>
      </div>
      {!accountingStatsData ? (
        <FullPageLoader />
      ) : (
        <>
          <div className={styles.summaryContainer}>
            <p className={styles.boldText}>
              {`Solde en attente : ${formatDecimal(
                accountingStatsData.getMyAccountingStats.pendingPaymentAmount,
                2
              )} €`}
            </p>
            <p className={styles.boldText}>
              {`Solde payable : ${formatDecimal(
                accountingStatsData.getMyAccountingStats.payablePaymentAmount,
                2
              )} €`}
            </p>
            <p className={styles.boldText}>
              {`Solde payé : ${formatDecimal(
                accountingStatsData.getMyAccountingStats.paidPaymentAmount,
                2
              )} €`}
            </p>
            <p>
              {mode === "factures" && myPayOutsCount !== undefined
                ? `Fetched ${myPayOuts?.length ?? 0}/${myPayOutsCount}`
                : mode === "transactions" && myTransactionsCount
                ? `Fetched ${
                    myTransactions?.length ?? 0
                  }/${myTransactionsCount}`
                : "Fetching"}
            </p>
          </div>
          {mode === "factures" ? (
            <MemoizedPayOutsPage
              myPayOuts={myPayOuts}
              fetchMyPayOuts={fetchMyPayOuts}
              hasMorePayOuts={hasMorePayOuts}
              isSelectedAll={isSelectedAllPayOuts}
              setIsSelectedAll={setIsSelectedAllPayOuts}
              selectedPayOuts={selectedPayOuts}
              setSelectedPayOuts={setSelectedPayOuts}
            />
          ) : (
            <MemoizedTransactionsPage
              myTransactions={myTransactions}
              fetchMyTransactions={fetchMyTransactions}
              hasMoreTransactions={hasMoreTransactions}
              isSelectedAll={isSelectedAllTransactions}
              setIsSelectedAll={setIsSelectedAllTransactions}
              selectedTransactions={selectedTransactions}
              setSelectedTransactions={setSelectedTransactions}
            />
          )}
        </>
      )}
    </div>
  );
};
