import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./styles.css";

import { useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";

import DraggableCard from "~/components/UI/DraggableCard";
import { months } from "~/constants/general";
import { HistogramBarData } from "~/types/common/histogram.types";
import { RentDashboardData } from "~/types/data/DashboardData.types";

import CardWithButtons from "../../cards/CardWithButtons";
import HistogramCard from "../../cards/HistogramCard";
import NumberCard from "../../cards/NumberCard";
import NumberListCard from "../../cards/NumberListCard";

interface Props {
  data: RentDashboardData;
  to?: string;
}

const ResponsiveGridLayout = WidthProvider(Responsive);

export const RentDashboardGrid = ({ data, to }: Props) => {
  const {
    orderTotalPaidAmount,
    orderTotalPurchasedAmount,
    orderDailyTotalPaidRecurringAmount,
    orderWeeklyTotalPaidRecurringAmount,
    orderMonthlyTotalPaidRecurringAmount,
    toBeShippedOrdersCount,
    ordersCount,
    productsCount,
    productsInStockCount,
    productsOutOfStockCount,
    publishedProductsCount,
    invalidProductsCounts,
    paymentAmountsByMonth,
    purchaseAmountsByMonth,
    ordersCountByMonth,
  } = data;

  const toDate = to ? new Date(to) : new Date();
  const toMonth = toDate.getMonth();

  const monthsDataArray = new Array(12).fill(0);

  const histogramPaymentAmountsByMonth: HistogramBarData[] =
    monthsDataArray.map((_, index) => {
      const monthIndex =
        toMonth + index + 1 > 11 ? toMonth + index - 11 : toMonth + index + 1;
      return {
        xAxis: months[monthIndex],
        yAxis: paymentAmountsByMonth[monthIndex],
      };
    });

  const histogramPurchaseAmountsByMonth: HistogramBarData[] =
    monthsDataArray.map((_, index) => {
      const monthIndex =
        toMonth + index + 1 > 11 ? toMonth + index - 11 : toMonth + index + 1;
      return {
        xAxis: months[monthIndex],
        yAxis: purchaseAmountsByMonth[monthIndex],
      };
    });

  const histogramOrdersCountCountByMonth: HistogramBarData[] =
    monthsDataArray.map((_, index) => {
      const monthIndex =
        toMonth + index + 1 > 11 ? toMonth + index - 11 : toMonth + index + 1;
      return {
        xAxis: months[monthIndex],
        yAxis: ordersCountByMonth[monthIndex],
      };
    });

  const [histogramData, setHistogramData] = useState<HistogramBarData[]>(
    histogramPaymentAmountsByMonth
  );

  const [selectedHistogramButton, setSelectedHistogramButton] = useState<
    1 | 2 | 3
  >(1);

  const layout = [
    { i: "1", x: 0, y: 0, w: 1, h: 2 },
    { i: "2", x: 1, y: 0, w: 1, h: 2 },
    { i: "3", x: 2, y: 0, w: 1, h: 2 },
    { i: "4", x: 3, y: 0, w: 1, h: 2 },
    { i: "5", x: 0, y: 2, w: 1, h: 2 },
    { i: "6", x: 1, y: 2, w: 1, h: 2 },
    { i: "7", x: 2, y: 2, w: 1, h: 2 },
    { i: "8", x: 3, y: 2, w: 1, h: 3 },
    { i: "9", x: 0, y: 4, w: 3, h: 6 },
    { i: "10", x: 3, y: 5, w: 1, h: 2 },
    { i: "11", x: 3, y: 6, w: 1, h: 2 },
  ];

  const histogramButtonClickHandler = (button: 1 | 2 | 3) => {
    setSelectedHistogramButton(button);
    switch (button) {
      case 1:
        setHistogramData(histogramPaymentAmountsByMonth);
        return;
      case 2:
        setHistogramData(histogramPurchaseAmountsByMonth);
        return;
      case 3:
        setHistogramData(histogramOrdersCountCountByMonth);
        return;
    }
  };

  return (
    <div>
      <ResponsiveGridLayout
        className={"layout"}
        layouts={{ lg: layout }}
        breakpoints={{ lg: 1200 }}
        cols={{ lg: 4 }}
        isBounded={true}
        isResizable={false}
        margin={[20, 20]}
        draggableHandle=".draggable"
        rowHeight={60}
        containerPadding={[0, 0]}
      >
        <div key="1">
          <DraggableCard title="CA global :">
            <NumberCard number={orderTotalPaidAmount} currency={true} />
          </DraggableCard>
        </div>
        <div key="2">
          <DraggableCard title="Montant acheté :">
            <NumberCard number={orderTotalPurchasedAmount} currency={true} />
          </DraggableCard>
        </div>
        <div key="3">
          <DraggableCard title="Nombe de commande :">
            <NumberCard number={ordersCount} currency={false} />
          </DraggableCard>
        </div>
        <div key="4">
          <DraggableCard title="Nombre de produits :">
            <NumberCard number={productsCount} currency={false} />
          </DraggableCard>
        </div>
        <div key="5">
          <DraggableCard title="Produits publiés :">
            <NumberCard number={publishedProductsCount} currency={false} />
          </DraggableCard>
        </div>
        <div key="6">
          <DraggableCard title="Commandes à traiter (Location):">
            <NumberCard number={toBeShippedOrdersCount} currency={false} />
          </DraggableCard>
        </div>
        <div key="7">
          <DraggableCard title="Produits invalides :">
            <NumberCard number={invalidProductsCounts} currency={false} />
          </DraggableCard>
        </div>
        <div key="8">
          <DraggableCard title="Montant paiement récurrent :">
            <NumberListCard
              data={[
                {
                  number: orderDailyTotalPaidRecurringAmount,
                  title: "Quotidien",
                  isCurrency: true,
                },
                {
                  number: orderWeeklyTotalPaidRecurringAmount,
                  title: "Hebdomadaire",
                  isCurrency: true,
                },
                {
                  number: orderMonthlyTotalPaidRecurringAmount,
                  title: "Mensuel",
                  isCurrency: true,
                },
              ]}
            />
          </DraggableCard>
        </div>
        <div key="9">
          <CardWithButtons
            title="Chiffre d'affaire en détail :"
            onClick={histogramButtonClickHandler}
            selectedButton={selectedHistogramButton}
            buttonsText={["Paiement", "Achat", "Commandes"]}
          >
            <HistogramCard data={histogramData} />
          </CardWithButtons>
        </div>
        <div key="10">
          <DraggableCard title="Produits en stock :">
            <NumberCard number={productsInStockCount} currency={false} />
          </DraggableCard>
        </div>
        <div key="11">
          <DraggableCard title="Ruptures de stock :">
            <NumberCard number={productsOutOfStockCount} currency={false} />
          </DraggableCard>
        </div>
      </ResponsiveGridLayout>
    </div>
  );
};
