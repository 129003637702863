import { ApolloError } from "@apollo/client";
import { useState } from "react";
import { SingleValue } from "react-select";

import {
  createReedooTransportation,
  CreateReedooTransportationInput,
  CreateReedooTransportationResponse,
} from "~/api/graphql/transportation";
import { CustomButton } from "~/components/form/CustomButton";
import { CustomInput } from "~/components/form/CustomInput";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { ErrorPopup } from "~/components/UI/ErrorPopup";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import StatusLabel from "~/components/UI/StatusLabel";
import SVGContainer from "~/components/UI/SVGContainer";
import { colors } from "~/constants/styles";
import { useUserContext } from "~/context/userContext";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import { Order, OrderStatusEnum } from "~/types/data/Order.types";
import { Transportation } from "~/types/data/Transportation.types";
import { RoleEnum } from "~/types/data/User.types";
import { formatDate } from "~/util/functions/formatDate";
import { getTextFromTransportationStatus } from "~/util/mapping/transportationStatus.mapping";

import styles from "./index.module.scss";

interface Props {
  index: number;
  order: Order;
  updateOrderHandler: (order: Order) => void;
  transportation: Transportation;
  carriersOptions: OptionType[];
  inputChangeHandler: <T extends keyof Transportation>(
    index: number,
    inputName: T,
    changes: Transportation[T]
  ) => void;
  onDeleteCarrier?: (index: number) => void;
  readOnly: boolean;
}

export const CarrierDetailsCard = ({
  index,
  order,
  updateOrderHandler,
  transportation,
  carriersOptions,
  inputChangeHandler,
  onDeleteCarrier,
  readOnly,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { userState } = useUserContext();

  const isAdmin = userState?.connected && userState.role === RoleEnum.ADMIN;

  const {
    id,
    carrierId,
    carrier,
    updatedAt,
    pdfUrl,
    status,
    trackingUrl,
    trackingNumber,
  } = transportation;

  const createReedooTransportationErrorHandler = (error: ApolloError) => {
    setIsLoading(false);
    setErrorMessage(error.message);
  };

  const createReedooTransportationSuccessHandler = (
    response: CreateReedooTransportationResponse
  ) => {
    updateOrderHandler(response.createReedooTransportation);
    setIsLoading(false);
  };

  const { trigger: createReedooTransportationTrigger } =
    useMutationWithCallbacks<
      CreateReedooTransportationResponse,
      CreateReedooTransportationInput
    >(
      createReedooTransportation,
      createReedooTransportationSuccessHandler,
      createReedooTransportationErrorHandler
    );

  const createReedooTransportationHandler = () => {
    setIsLoading(true);
    createReedooTransportationTrigger({
      variables: {
        CreateReedooTransportationInput: {
          carrierId:
            isAdmin && order.managedByReedoo
              ? 10
              : order.deliveryOption?.carrier?.id || 0,
          depositDate: new Date(),
          weight: 12.2,
          orderId: order.id,
        },
      },
    });
  };

  const hideErrorPopupHandler = () => {
    setErrorMessage("");
  };

  const formattedStatus = getTextFromTransportationStatus(status);
  const formattedUpdatedAt = formatDate(updatedAt);

  return (
    <div className={styles.container}>
      <ErrorPopup
        onCancel={hideErrorPopupHandler}
        show={!!errorMessage}
        message={errorMessage}
      />
      <div className={styles.header}>
        <p>Colis {index + 1}</p>
        <div className={styles.buttonContainer}>
          {!!status && (
            <div>
              <StatusLabel
                backgroundColor="#899CAA"
                color="white"
                label={`${formattedStatus} (${formattedUpdatedAt})`}
              />
            </div>
          )}

          {!id &&
            (order.usedReedooCarrier || (isAdmin && order.managedByReedoo)) &&
            (order.status === OrderStatusEnum.WAITING_FOR_EXPEDITION ||
              order.status === OrderStatusEnum.WAITING_FOR_RECEPTION) &&
            !readOnly && (
              <CustomButton
                color="#354957"
                borderColor="#354957"
                borderRadius="5px"
                padding="0.2rem 0.5rem"
                height="2rem"
                width="12rem"
                onClick={createReedooTransportationHandler}
                disabled={isLoading}
              >
                {isLoading ? (
                  <SimpleLoader size="size1" fill="black" />
                ) : (
                  <span className={styles.bold}>Télécharger l’étiquette</span>
                )}
              </CustomButton>
            )}
          {onDeleteCarrier &&
            (!id || !order.usedReedooCarrier) &&
            !isLoading &&
            !readOnly && (
              <SVGContainer
                height="10px"
                width="10px"
                imagePath="/assets/close-button.svg"
                onClick={
                  onDeleteCarrier
                    ? () => {
                        onDeleteCarrier(index);
                      }
                    : undefined
                }
              />
            )}
        </div>
      </div>
      <div className={styles.inputs}>
        <div className={styles.carrierInput}>
          {order.usedReedooCarrier ? (
            <CustomInput
              label="Transporteur"
              fontSize="14px"
              backgroundColor={colors.$inputGray}
              noBorder={true}
              onChange={() => {}}
              value={carrier?.name}
              borderRadius="5px"
              disabled={true}
            />
          ) : (
            <SelectInput
              label="Transporteur"
              fontSize="14px"
              backgroundColor={colors.$inputGray}
              noBorder={true}
              options={carriersOptions}
              onChange={(option: SingleValue<OptionType>) => {
                if (option)
                  inputChangeHandler(index, "carrierId", +option.value);
              }}
              value={carrierId?.toString() || ""}
              disabled={readOnly}
            />
          )}
        </div>
        <div className={styles.trackingNumberInput}>
          <CustomInput
            label={"Numéro de suivi"}
            fontSize="14px"
            backgroundColor={colors.$inputGray}
            noBorder={true}
            onChange={(value: string | number) => {
              inputChangeHandler(index, "trackingNumber", value.toString());
            }}
            value={trackingNumber}
            borderRadius="5px"
            disabled={!!order.usedReedooCarrier || readOnly}
          />
        </div>
      </div>
      {!!id && trackingUrl && (
        <div className={styles.details}>
          <div className={styles.section}>
            <p>
              Adresse de suivi :{" "}
              <a
                href={trackingUrl}
                className={styles.link}
                target="_blank"
                rel="noreferrer"
              >
                {trackingUrl}
              </a>
            </p>
          </div>
          {(order.usedReedooCarrier || (order.managedByReedoo && isAdmin)) && (
            <div className={styles.section}>
              <p>
                shipping label:{" "}
                <a
                  href={pdfUrl}
                  className={styles.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Télécharger
                </a>
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
