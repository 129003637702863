import { ApolloError } from "@apollo/client";
import { useState } from "react";

import {
  INITIATE_RECURRING_PAYMENT,
  InitiateRecurringPaymentInput,
  InitiateRecurringPaymentResponse,
} from "~/api/graphql/rentOrder";
import { CustomButton } from "~/components/form/CustomButton";
import { ErrorPopup } from "~/components/UI/ErrorPopup";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import StatusLabel from "~/components/UI/StatusLabel";
import { colors } from "~/constants/styles";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import {
  RentOrder,
  SingleRentProductStatusEnum,
} from "~/types/data/RentOrder.type";
import { formatDate } from "~/util/functions/formatDate";
import { formatPrice } from "~/util/functions/formatDecimal";
import {
  mapRentOrderStatus,
  mapRentOrderStatusColor,
} from "~/util/mapping/orderHistoryMapping";

import { getBuyerName } from "../../util/getProfileName";
import styles from "./index.module.scss";

interface Props {
  rentOrder: RentOrder;
  setOrderHandler: (order: RentOrder) => void;
}

export const InformationBanner = ({ rentOrder, setOrderHandler }: Props) => {
  const {
    id,
    status,
    orderToProducts,
    billingPricePerPeriod,
    originalPricePerPeriod,
    buyer,
    totalPurchasedAmount,
    initialDepositAmount,
    leftDepositAmount,
    shippingAddress,
    nextBillingDate,
  } = rentOrder;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const formattedOriginalPricePerPeriod = formatPrice(originalPricePerPeriod);
  const formattedBillingPricePerPeriod = formatPrice(billingPricePerPeriod);

  const formattedTotalPurchaseAmount = formatPrice(totalPurchasedAmount);

  const formattedInitialDepositAmount = formatPrice(initialDepositAmount);
  const formattedLeftDepositAmount = formatPrice(leftDepositAmount);

  const formattedDeliveryPrice = formatPrice(0);

  const formattedNextBillingDate = formatDate(nextBillingDate);

  const numberOfOriginalProducts = orderToProducts?.reduce(
    (acc, { originalQuantity }) => acc + originalQuantity,
    0
  );

  const numberOfActiveProducts = orderToProducts?.reduce(
    (acc, { singleProducts }) => {
      const activeQuantity = singleProducts.filter(
        ({ status }) =>
          status === SingleRentProductStatusEnum.IN_USE ||
          status === SingleRentProductStatusEnum.DELIVERING ||
          status === SingleRentProductStatusEnum.PENDING
      ).length;
      return acc + activeQuantity;
    },
    0
  );

  const numberOfPurchasedProducts = orderToProducts?.reduce(
    (acc, { singleProducts }) => {
      const activeQuantity = singleProducts.filter(
        ({ status }) => status === SingleRentProductStatusEnum.PURCHASED
      ).length;
      return acc + activeQuantity;
    },
    0
  );
  const buyerName = getBuyerName(
    buyer?.firstName,
    buyer?.lastName,
    shippingAddress?.name
  );

  const formattedStatus = mapRentOrderStatus(status);
  const statusColor = mapRentOrderStatusColor(status);

  const initiateRecrurringPaymentSuccessCallback = (
    data: InitiateRecurringPaymentResponse
  ) => {
    const { initiateRecurringPayment } = data;
    setOrderHandler(initiateRecurringPayment);
    setIsLoading(false);
  };

  const initiateRecrurringPaymentErrorCallback = (error: ApolloError) => {
    setIsLoading(false);
    setErrorMessage(error.message);
  };

  const { trigger: initiateRecurringPayment } = useMutationWithCallbacks<
    InitiateRecurringPaymentResponse,
    InitiateRecurringPaymentInput
  >(
    INITIATE_RECURRING_PAYMENT,
    initiateRecrurringPaymentSuccessCallback,
    initiateRecrurringPaymentErrorCallback
  );

  const initiateRecurringPaymentHandler = () => {
    setErrorMessage("");
    setIsLoading(true);
    initiateRecurringPayment({
      variables: {
        InitiateRecurringPaymentInput: {
          rentOrderId: id,
        },
      },
    });
  };

  return (
    <div className={`${styles.container}`}>
      <ErrorPopup
        message={errorMessage}
        onCancel={() => {
          setErrorMessage("");
        }}
        show={!!errorMessage}
      />
      <div className={`${styles.banner}`}>
        <div className={`${styles.bannerItem}`}>
          <p className={`${styles.title}`}>Client :</p>
          <div className={`${styles.itemContent}`}>
            <p>{buyerName}</p>
          </div>
        </div>
        <div className={`${styles.bannerItem}`}>
          <p className={`${styles.title}`}>N° de commande :</p>
          <div className={`${styles.itemContent}`}>
            <p>{id}</p>
          </div>
        </div>
        <div className={`${styles.bannerItem}`}>
          <p className={`${styles.title}`}>Statut :</p>
          <div className={`${styles.statusItemContent}`}>
            <StatusLabel
              label={formattedStatus}
              color="white"
              backgroundColor={statusColor}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.summaryContainer}`}>
        <div className={`${styles.summary}`}>
          <p>
            Montant Départ/En cours :{" "}
            <span className={`${styles.emphasizedText}`}>
              {formattedOriginalPricePerPeriod}/{formattedBillingPricePerPeriod}
            </span>
          </p>
          <p>
            Achats :{" "}
            <span className={`${styles.emphasizedText}`}>
              {formattedTotalPurchaseAmount}
            </span>
          </p>
        </div>
        <div className={`${styles.summary}`}>
          <p>
            Nombre articles en cours :{" "}
            <span className={`${styles.emphasizedText}`}>
              {numberOfActiveProducts}/{numberOfOriginalProducts}
            </span>
          </p>
          <p>
            Nombre articles achetés :{" "}
            <span className={`${styles.emphasizedText}`}>
              {numberOfPurchasedProducts}
            </span>
          </p>
        </div>
        <div className={`${styles.summary}`}>
          <p>
            Frais de port :{" "}
            <span className={`${styles.emphasizedText}`}>
              {formattedDeliveryPrice}
            </span>
          </p>
          <p>
            Prochaine date de facturation :{" "}
            <span className={`${styles.emphasizedText}`}>
              {formattedNextBillingDate}
            </span>
          </p>
          <p>
            Caution début/En cours :{" "}
            <span className={`${styles.emphasizedText}`}>
              {formattedInitialDepositAmount}/{formattedLeftDepositAmount}
            </span>
          </p>
        </div>
        {!!billingPricePerPeriod && (
          <div>
            <CustomButton
              color={colors.$primaryDark}
              borderColor={colors.$primaryDark}
              borderRadius="5px"
              height="2rem"
              padding="0.2rem 0.5rem"
              width="10rem"
              onClick={() => {
                initiateRecurringPaymentHandler();
              }}
            >
              {isLoading ? (
                <SimpleLoader size="size1" fill={colors.$primaryDark} />
              ) : (
                "Manual Billing"
              )}
            </CustomButton>
          </div>
        )}
      </div>
    </div>
  );
};
