import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { GET_COMPANIES, GetCompaniesResponse } from "~/api/graphql/company";
import {
  ADMIN_GET_USER_BY_ID,
  AdminGetUserByIdInput,
  AdminGetUserByIdResponse,
} from "~/api/graphql/user";
import FullPageLoader from "~/components/UI/FullPageLoader";
import SVGContainer from "~/components/UI/SVGContainer";
import { UserFormState } from "~/types/data/User.types";

import styles from "./index.module.scss";
import { UserAddresses } from "./UserAddresses";
import { UserDetails } from "./UserDetails";
import { UserNavigation } from "./UserNavigation";
import { UserProfile } from "./UserProfile";
import { userFormStateInitState } from "./util/userFormStateInitState";

interface Props {
  page: "details" | "addresses" | "profile" | "create";
}

export const UserDetailsPage = ({ page }: Props) => {
  // states
  const [userFormState, setUserFormState] = useState<UserFormState>();

  // hooks
  const params = useParams();
  const navigate = useNavigate();

  const { data: companiesData, error: companiesError } =
    useQuery<GetCompaniesResponse>(GET_COMPANIES, {
      fetchPolicy: "cache-first",
    });

  const backButtonClickHandler = () => {
    navigate(-1);
  };

  // react-query
  const getUserByIdHandler = (responseData: AdminGetUserByIdResponse) => {
    const { adminGetUserById } = responseData;

    setUserFormState({
      ...adminGetUserById,
      profileId: adminGetUserById.profile?.id,
    });
  };

  const [getUserByIdTrigger, { error: getUserByIdError }] = useLazyQuery<
    AdminGetUserByIdResponse,
    AdminGetUserByIdInput
  >(ADMIN_GET_USER_BY_ID, {
    onCompleted: getUserByIdHandler,
    errorPolicy: "all",
  });

  const initFormState = async (id: number) => {
    await getUserByIdTrigger({
      variables: { AdminGetUserByIdInput: { id } },
    });
  };

  useEffect(() => {
    const userId = params.id || "";
    if (userId) {
      initFormState(+userId);
    } else {
      setUserFormState(userFormStateInitState);
    }
  }, []);

  return (
    <div>
      {companiesError || getUserByIdError ? (
        <div className={`${styles.container}`}>
          {companiesError && (
            <div>{`Companies Error: ${companiesError.message}`}</div>
          )}
          {getUserByIdError && (
            <div>{`Product Error: ${getUserByIdError.message}`}</div>
          )}
        </div>
      ) : !companiesData || !userFormState ? (
        <FullPageLoader />
      ) : (
        <div className={`${styles.container}`}>
          <div className={`${styles.header}`}>
            <div className={`${styles.backButton}`}>
              <SVGContainer
                imagePath="/assets/back-button.svg"
                height="17px"
                width="20px"
                onClick={backButtonClickHandler}
              />
            </div>
            <h2>Nouveau User</h2>
          </div>
          {!!userFormState.id && <UserNavigation userId={userFormState.id} />}
          <div className={`${styles.formContainer}`}>
            {page === "create" || page === "details" ? (
              <UserDetails
                companiesData={companiesData.getCompanies}
                setUserFormState={setUserFormState}
                user={userFormState}
              />
            ) : page === "addresses" ? (
              <UserProfile />
            ) : (
              <UserAddresses />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
