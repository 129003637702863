import { HeadCell } from "~/types/common/headCell.type";
import { PermissionLevelEnum, User } from "~/types/data/User.types";

import styles from "./index.module.scss";
import { UsersTableBody } from "./UsersTableBody";
import { UsersTableHead } from "./UsersTableHead";

interface Props {
  users: User[];
  onUserEditClick: (id: number) => void;
  onUserDeleteClick: (id: number) => void;
  deletingId?: number;
}

const HEADCELLS: HeadCell[] = [
  { id: 1, label: "ID", isSortable: true },
  { id: 2, label: "Adresse e-mail", isSortable: true },
  { id: 3, label: "Droits", isSortable: true },
  { id: 4, label: "Création", isSortable: true },
  {
    id: 5,
    label: "Actions",
    isSortable: true,
    permissionLevel: PermissionLevelEnum.ADMIN,
  },
];

export const UsersTable = ({
  users,
  onUserEditClick,
  onUserDeleteClick,
  deletingId,
}: Props) => {
  return (
    <table className={`${styles.table}`}>
      <UsersTableHead headCells={HEADCELLS} />
      <UsersTableBody
        users={users}
        onUserEditClick={onUserEditClick}
        onUserDeleteClick={onUserDeleteClick}
        deletingId={deletingId}
      />
    </table>
  );
};
