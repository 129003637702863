import { memo } from "react";

import { OptionType } from "~/components/form/SelectInput";

import { MemoizedCategorySelectInput } from "../CategorySelectInput";

interface Props {
  options: OptionType[];
  value: string;
  sheetValue: string;
  onStateHasChanged: (state: number) => void;
}

const CategorySelectRow = ({
  value,
  options,
  sheetValue,
  onStateHasChanged,
}: Props) => {
  return (
    <>
      <td>
        <MemoizedCategorySelectInput
          options={options}
          value={value}
          sheetValue={sheetValue}
          onStateHasChanged={onStateHasChanged}
        />
      </td>
    </>
  );
};

export const MemoizedCategorySelectRow = memo(CategorySelectRow);
