import { ApolloError } from "@apollo/client";
import { useState } from "react";

import {
  UPDATE_COMPANY_UBO_DECLARATION,
  UpdateCompanyUboDeclarationApiInput,
  UpdateCompanyUboDeclarationApiResponse,
} from "~/api/graphql/uboDeclaration";
import { CustomButton } from "~/components/form/CustomButton";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import SVGContainer from "~/components/UI/SVGContainer";
import { initUBOState } from "~/constants/states";
import { colors } from "~/constants/styles";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import { UpdateCompanyUboDeclarationInput } from "~/types/data/Company.types";
import { UboStatusEnum } from "~/types/data/UBO.types";
import { updateUboDeclarationValidationSchema } from "~/util/validation/companyAccount/uboDeclaration.schema";

import styles from "./index.module.scss";
import { UboSection } from "./UboSection";

export const UboDeclaration = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { companyAccount, setCompanyAccount } = useCompanyAccountContext();

  const updateCompanyUboDeclarationSuccessHandler = ({
    updateCompanyUboDeclaration,
  }: UpdateCompanyUboDeclarationApiResponse) => {
    setSuccessMessage("Ubo declaration updated successfully.");
    setCompanyAccount((prev) => {
      return {
        ...prev,
        uboDeclarationId: updateCompanyUboDeclaration.uboDeclarationId,
        uboDeclarationStatus: updateCompanyUboDeclaration.uboDeclarationStatus,
        ubos: updateCompanyUboDeclaration.ubos,
      };
    });
  };

  const updateCompanyUboDeclarationErrorHandler = (error: ApolloError) => {
    setErrorMessage(error.message);
  };

  const { trigger: updateCompanyUboDeclarationTrigger } =
    useMutationWithCallbacks<
      UpdateCompanyUboDeclarationApiResponse,
      UpdateCompanyUboDeclarationApiInput
    >(
      UPDATE_COMPANY_UBO_DECLARATION,
      updateCompanyUboDeclarationSuccessHandler,
      updateCompanyUboDeclarationErrorHandler
    );

  const activeUbo = companyAccount.ubos.filter((ubo) => ubo.active);
  const addUboSection = () => {
    setCompanyAccount((prev) => {
      return {
        ...prev,
        ubos: [...prev.ubos, initUBOState],
      };
    });
  };

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");
    setIsLoading(true);

    const input: UpdateCompanyUboDeclarationInput = {
      id: companyAccount.id,
      ubos: companyAccount.ubos,
    };

    // validate input
    const result = updateUboDeclarationValidationSchema.validate({
      ...input,
      ubos: input.ubos.filter((ubo) => ubo.active),
    });

    const { error } = result;
    if (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
      return;
    }

    // send update request
    await updateCompanyUboDeclarationTrigger({
      variables: { UpdateCompanyUboDeclarationInput: input },
    });

    setIsLoading(false);
  };

  const disabled =
    companyAccount.uboDeclarationStatus === UboStatusEnum.VALIDATED ||
    companyAccount.uboDeclarationStatus === UboStatusEnum.VALIDATION_ASKED ||
    !companyAccount.mangopayId;

  return (
    <form
      className="myAccountFormSection"
      onSubmit={(e) => {
        submitHandler(e);
      }}
    >
      {" "}
      <h3 className="title">Informations de la société</h3>
      <h4 className={`normalWeightText ${styles.title}`}>
        DÉCLARATION DES BÉNÉFICIAIRES EFFECTIFS (UBO)
      </h4>
      <p className={`${styles.subtitle}`}>
        Cette section permet de déclarer les personnes possédant 25% ou plus de
        parts ou de droits de vote dans la société (UBO). Tous les champs
        applicables à un bénéficiaire effectif doivent être remplis.
      </p>
      <div style={{ marginBottom: "4rem" }}>
        {companyAccount.uboDeclarationStatus ===
        UboStatusEnum.VALIDATION_ASKED ? (
          <div className={styles.statusContainer}>
            <div className={styles.divContainer}>
              <h5 className={`${styles.validationAskedStatus}`}>
                Pending Validation
              </h5>
              <SVGContainer
                height="15px"
                width="15px"
                imagePath="/assets/clock-icon.svg"
              />
            </div>
          </div>
        ) : companyAccount.uboDeclarationStatus === UboStatusEnum.VALIDATED ? (
          <div className={styles.statusContainer}>
            <h5
              className={`${styles.validatedStatus}`}
            >{`Bénéficiaire n° ${companyAccount.uboDeclarationId}`}</h5>
          </div>
        ) : companyAccount.uboDeclarationStatus === UboStatusEnum.INCOMPLETE ? (
          <div className={styles.statusContainer}>
            <h5 className={`${styles.incompleteStatus}`}>
              Merci de compléter votre UBO
            </h5>
          </div>
        ) : companyAccount.uboDeclarationStatus === UboStatusEnum.REFUSED ? (
          <div className={`${styles.statusContainer}`}>
            <h5 className={`${styles.refusedStatus}`}>
              Ubo refusé. Merci de modifier les informations ou les compléter
            </h5>
          </div>
        ) : companyAccount.uboDeclarationStatus === UboStatusEnum.CREATED ? (
          <div className={`${styles.statusContainer}`}>
            <h5>CREATED</h5>
          </div>
        ) : (
          <></>
        )}
        {companyAccount.ubos.map((uboDeclaration, index) => {
          return (
            uboDeclaration.active && (
              <UboSection index={index} key={index} disabled={disabled} />
            )
          );
        })}
        <CustomButton
          backgroundColor={colors.$primary}
          color="white"
          width="fit-content"
          height="fit-content"
          borderRadius="8px"
          padding="0.5rem 1rem"
          onClick={addUboSection}
          disabled={disabled || activeUbo.length > 3 || isLoading}
        >
          <div className={`${styles.buttonTextContainer}`}>
            <SVGContainer
              height="16px"
              width="16px"
              imagePath="/assets/circular-add-icon.svg"
            />
            <p>Ajouter un nouvel UBO</p>
          </div>
        </CustomButton>
      </div>
      <div className="messageContainer">
        {!!errorMessage && <p className="errorMessage">{errorMessage}</p>}
        {!!successMessage && <p>{successMessage}</p>}
      </div>
      <div className="buttonContainer">
        <CustomButton
          backgroundColor={colors.$primary}
          color="white"
          width="fit-content"
          borderRadius="8px"
          padding="1rem 1.5rem"
          type="submit"
          disabled={disabled || activeUbo.length === 0 || isLoading}
        >
          {isLoading ? <SimpleLoader size="size2" /> : "Enregistrer"}
        </CustomButton>
      </div>
    </form>
  );
};
