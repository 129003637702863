import { useState } from "react";
import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

import FullPageLoader from "~/components/UI/FullPageLoader";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useUserContext } from "~/context/userContext";
import { RoleEnum } from "~/types/data/User.types";

import { routePaths } from "../routes";

export const AuthenticatedRoute = () => {
  const { userState } = useUserContext();
  const { companyAccount } = useCompanyAccountContext();
  const [isConnected, setIsConnected] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const isAuthenticated =
    userState?.connected &&
    (userState.role === RoleEnum.ADMIN || userState.role === RoleEnum.SELLER);

  const { loginRoute, dashboardRoute } = routePaths;

  useEffect(() => {
    if (userState && companyAccount.connected !== undefined) {
      if (isAuthenticated) {
        setIsConnected(userState.connected);
        setIsAdmin(userState.role === RoleEnum.ADMIN);
      }
      setIsLoading(false);
    }
  }, [userState?.connected, companyAccount.connected]);

  return (
    <>
      {isLoading ? (
        <FullPageLoader />
      ) : (
        <>
          {isAdmin ? (
            <Navigate to={`/${dashboardRoute}`} />
          ) : isConnected ? (
            <Outlet />
          ) : (
            <Navigate to={`/${loginRoute}`} />
          )}
        </>
      )}
    </>
  );
};
