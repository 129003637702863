export enum KYCDocumentNameEnum {
  IDENTITY_CARD = "IDENTITY_CARD",
  KBIS = "KBIS",
  COMPANY_STATUTES = "COMPANY_STATUTES",
  SHAREHOLDER_STATEMENT = "SHAREHOLDER_STATEMENT",
}

export enum KycDocumentStatusEnum {
  CREATED = "CREATED",
  VALIDATION_ASKED = "VALIDATION_ASKED",
  VALIDATED = "VALIDATED",
  OUT_OF_DATE = "OUT_OF_DATE",
  REFUSED = "REFUSED",
}

export interface KYC {
  id?: number;
  mangopayId?: string;
  createdAt?: Date;
  name: KYCDocumentNameEnum;
  path: string;
  status?: KycDocumentStatusEnum;
  fullPath?: string;
}

export interface UploadKYCDocumentInput {
  companyId: number;
  path: string;
  name: KYCDocumentNameEnum;
}
