import { Transportation } from "~/types/data/Transportation.types";

export const validateTransportationInput = (
  transportations: Transportation[]
) => {
  for (const [i, transportation] of transportations.entries()) {
    if (!transportation.carrierId) {
      return `Transporteur invalide on colis ${i + 1}`;
    }
  }
};
