import { gql } from "@apollo/client";

import { graphqlGetBankReferenceFields } from "~/constants/graphql";
import { BankReference } from "~/types/data/BankReference.types";
import { UpdateCompanyBankReferenceInput } from "~/types/data/Company.types";

export interface UpdateCompanyBankReferenceApiResponse {
  updateCompanyBankReference: BankReference;
}

export interface UpdateCompanyBankReferenceApiInput {
  UpdateCompanyBankReferenceInput: UpdateCompanyBankReferenceInput;
}
const bankReferenceFields = graphqlGetBankReferenceFields.join(" ");

export const UPDATE_COMPANY_BANK_REFERENCE = gql`
  mutation ($UpdateCompanyBankReferenceInput: UpdateCompanyBankReferenceInput!) {
    updateCompanyBankReference(UpdateCompanyBankReferenceInput: $UpdateCompanyBankReferenceInput) {
      ${bankReferenceFields}
    }
  }
`;
