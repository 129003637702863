import { gql } from "@apollo/client";

import { graphqlGetKYCDocumentFields } from "~/constants/graphql";
import { UpdateCompanyKYCDocumentsInput } from "~/types/data/Company.types";
import { KYC } from "~/types/data/KYC.types";

export interface UpdateCompanyKYCDocumentsApiResponse {
  updateCompanyKYCDocuments: KYC[];
}

export interface UpdateCompanyKYCDocumentsApiInput {
  UpdateCompanyKYCDocumentsInput: UpdateCompanyKYCDocumentsInput;
}

const kycDocumentFields = graphqlGetKYCDocumentFields.join(" ");

export const UPDATE_COMPANY_KYC_DOCUMENTS = gql`
  mutation ($UpdateCompanyKYCDocumentsInput: UpdateCompanyKYCDocumentsInput!) {
    updateCompanyKYCDocuments(UpdateCompanyKYCDocumentsInput: $UpdateCompanyKYCDocumentsInput) {
      fullPath
      ${kycDocumentFields}
          
    }
  }
`;
