import { RentOrder } from "~/types/data/RentOrder.type";

import { RentOrdersTableRow } from "../RentOrdersTableRow";
import styles from "./index.module.scss";

interface Props {
  orders: RentOrder[];
  selectedRows: RentOrder[];
  onCheckboxChange: (order: RentOrder) => void;
  onRowClick: (id: number) => void;
  showCompanyColumn: boolean;
}

export const RentOrdersTableBody = ({
  orders,
  selectedRows,
  onCheckboxChange,
  onRowClick,
  showCompanyColumn,
}: Props) => {
  const isSelected = (orderId?: number) =>
    selectedRows.findIndex(({ id }) => orderId === id) > -1;
  return (
    <tbody className={`${styles.tbody}`}>
      {orders.map((order, index) => {
        const isRowSelected = isSelected(order.id);
        return (
          <RentOrdersTableRow
            key={index.toString()}
            order={order}
            isSelected={isRowSelected}
            onCheckboxChange={onCheckboxChange}
            onClick={onRowClick}
            showCompanyColumn={showCompanyColumn}
          />
        );
      })}
    </tbody>
  );
};
