import SVGContainer from "~/components/UI/SVGContainer";

import styles from "./index.module.scss";

interface SectionProps {
  isActive: boolean;
  isFinished: boolean;
  text: string;
  onClick: () => void;
}

export const Section = ({
  isActive,
  isFinished,
  text,
  onClick,
}: SectionProps) => {
  return (
    <div
      className={`${styles.section} ${isActive ? styles.activeLink : ""} ${
        styles.clickable
      }`}
      onClick={onClick}
    >
      <p className={styles.clickable}>{text}</p>
      {isFinished && (
        <SVGContainer
          height="15px"
          width="15px"
          imagePath="/assets/done-icon.svg"
        />
      )}
    </div>
  );
};
