import { gql } from "@apollo/client";

import {
  graphqlGetFtpImportFields,
  graphqlUpsertFtpImportFields,
} from "~/constants/graphql";
import { Optional } from "~/types/common/optional.types";
import { FtpImport } from "~/types/data/FtpImport.types";

export interface UpsertFtpImportInput {
  UpsertFtpImportInput: Optional<FtpImport, "id" | "port" | "companyId">;
}

export interface UpsertFtpImportResponse {
  upsertFtpImport: FtpImport;
}

export interface GetFtpImport {
  getFtpImport: FtpImport;
}

const upsertFtpImportFields = graphqlUpsertFtpImportFields.join(" ");
const getFtpImportFields = graphqlGetFtpImportFields.join(" ");

export const ftpImportUpsert = gql`
  mutation ($UpsertFtpImportInput: UpsertFtpImportInput!) {
    upsertFtpImport(UpsertFtpImportInput: $UpsertFtpImportInput) {
        ${upsertFtpImportFields}
    }
  }
`;

export const getFtpImport = gql`
  query {
    getFtpImport {
        ${getFtpImportFields}
    }
  }
`;
