import { gql } from "@apollo/client";

import { promoCodesFields } from "~/constants/graphql";
import { DiscountTypeEnum, PromoCode } from "~/types/data/PromoCode.type";

export interface GetSecondHandPromoCodesResponse {
  getSecondHandPromoCodes: PromoCode[];
}

export interface DeletePromoCodeResponse {
  deleteSecondHandPromoCode: boolean;
}

export interface CreatePromoCodeResponse {
  createSecondHandPromoCode: PromoCode;
}

export interface UpdatePromoCodeResponse {
  updateSecondHandPromoCode: PromoCode;
}

export type PromoCodeState = {
  id?: number;
  code?: string | null;
  discountType?: DiscountTypeEnum | null;
  amount?: number | null;
  threshold?: number | null;
  validUntil?: Date | null;
  active?: boolean | null;
  uses?: number | null;
  used?: number;
  maximumUsesPerUser?: number | null;
};

export type CreateSecondHandPromoCodeInput = {
  CreateSecondHandPromoCodeInput: PromoCodeState;
};

export type UpdateSecondHandPromoCodeInput = {
  UpdateSecondHandPromoCodeInput: PromoCodeState;
};

export interface DeleteSecondHandPromoCodeInput {
  id: number;
}

export interface GetSecondHandPromoCodesResponse {
  getSecondHandPromoCodes: PromoCode[];
}

export const GET_PROMO_CODES = gql`
  query {
    getSecondHandPromoCodes {
      ${promoCodesFields}
    }
  }
`;

export const CREATE_PROMO_CODE = gql`
  mutation ($CreateSecondHandPromoCodeInput: CreateSecondHandPromoCodeInput!) {
    createSecondHandPromoCode(CreateSecondHandPromoCodeInput: $CreateSecondHandPromoCodeInput) {
      ${promoCodesFields}
    }
  }
`;

export const UPDATE_PROMO_CODE = gql`
  mutation ($UpdateSecondHandPromoCodeInput: UpdateSecondHandPromoCodeInput!) {
    updateSecondHandPromoCode(UpdateSecondHandPromoCodeInput: $UpdateSecondHandPromoCodeInput) {
      ${promoCodesFields}
    }
  }
`;

export const DELETE_PROMO_CODE = gql`
  mutation ($id: Float!) {
    deleteSecondHandPromoCode(id: $id)
  }
`;
