import { SingleValue } from "react-select";

import SelectInput, { OptionType } from "~/components/form/SelectInput";
import SVGContainer from "~/components/UI/SVGContainer";
import { colors } from "~/constants/styles";
import {
  Carrier,
  DeliveryDetailsState,
  GroupedCarrierState,
} from "~/types/data/Carrier.types";

import { DeliveryAreaKey, DeliveryMethodKey } from "..";
import { DeliveryCodeSection } from "../DeliveryCodeSection";
import styles from "./index.module.scss";

interface Props {
  carrierIndex: number;
  stateKey: string;
  carriers: Carrier[];
  groupedCarriersState: {
    groupedCarrierState: GroupedCarrierState[];
    carrierId: number;
  };
  stateKeys: string[];
  carrierChangedHandler: (stateKey: string, carrierId: number) => void;
  deleteCarrierSectionHandler: (stateKey: string) => void;
  addGroupedCarrierCodeHandler: (stateKey: string) => void;
  deleteGroupedCarrierCodeHandler: (
    stateKey: string,
    codeIndex: number
  ) => void;
  clearDeliveryMethodHandler: (
    stateKey: string,
    codeIndex: number,
    method: DeliveryMethodKey
  ) => void;
  clearDeliveryAreaHandler: (
    stateKey: string,
    codeIndex: number,
    method: DeliveryMethodKey,
    area: DeliveryAreaKey
  ) => void;
  addDeliveryDetailsStateHandler: (
    stateKey: string,
    codeIndex: number,
    method: DeliveryMethodKey,
    area: DeliveryAreaKey
  ) => void;
  deliveryPackageChangeHandler: (
    stateKey: string,
    codeIndex: number,
    type: DeliveryMethodKey,
    area: DeliveryAreaKey,
    changes: DeliveryDetailsState
  ) => void;
  groupedCarrierCodeChangeHandler: (
    stateKey: string,
    codeIndex: number,
    newCode: string
  ) => void;
}

export const CarrierSection = ({
  carrierIndex,
  stateKey,
  carriers,
  groupedCarriersState,
  stateKeys,
  carrierChangedHandler,
  deleteCarrierSectionHandler,
  addGroupedCarrierCodeHandler,
  deleteGroupedCarrierCodeHandler,
  deliveryPackageChangeHandler,
  groupedCarrierCodeChangeHandler,
  clearDeliveryAreaHandler,
  clearDeliveryMethodHandler,
  addDeliveryDetailsStateHandler,
}: Props) => {
  const carriersOptions: OptionType[] = carriers.map(({ id, name }) => ({
    label: name,
    value: id.toString(),
  }));

  const deleteCarrierHandler = () => {
    deleteCarrierSectionHandler(stateKey);
  };

  const addCodeHandler = () => {
    addGroupedCarrierCodeHandler(stateKey);
  };

  return (
    <div className={`${styles.container}`}>
      {carrierIndex !== 0 && <hr />}
      <div>
        <div className={`${styles.carrierInfoSection}`}>
          <div className={`${styles.selectInputContainer}`}>
            <div className="doubleInputs">
              <SelectInput
                label="Choisir un transporteur :"
                fontSize="14px"
                backgroundColor={colors.$inputGray}
                noBorder={true}
                options={carriersOptions}
                onChange={(option: SingleValue<OptionType>) =>
                  carrierChangedHandler(stateKey, option ? +option.value : 0)
                }
                value={groupedCarriersState.carrierId.toString()}
              />
              <div style={{ width: "100%" }} />
            </div>
          </div>
          {stateKeys.length > 1 && (
            <SVGContainer
              height="16px"
              width="16px"
              imagePath="/assets/circular-delete-button.svg"
              onClick={deleteCarrierHandler}
            />
          )}
        </div>
        {groupedCarriersState.groupedCarrierState.map(
          (groupedCarrierState, codeIndex) => {
            return (
              <DeliveryCodeSection
                key={codeIndex}
                stateKey={stateKey}
                codeIndex={codeIndex}
                groupedCarrierState={groupedCarrierState}
                deliveryPackageChangeHandler={deliveryPackageChangeHandler}
                groupedCarrierCodeChangeHandler={
                  groupedCarrierCodeChangeHandler
                }
                clearDeliveryMethodHandler={clearDeliveryMethodHandler}
                clearDeliveryAreaHandler={clearDeliveryAreaHandler}
                addDeliveryDetailsStateHandler={addDeliveryDetailsStateHandler}
                deleteGroupedCarrierCodeHandler={
                  deleteGroupedCarrierCodeHandler
                }
              />
            );
          }
        )}

        <div
          className={`${styles.addButtonContainer}`}
          onClick={addCodeHandler}
        >
          <SVGContainer
            height="12px"
            width="12px"
            imagePath="/assets/rounded-add-button.svg"
          />
          Ajouter une taille de colis
        </div>
      </div>
    </div>
  );
};
