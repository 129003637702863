import SVGContainer from "~/components/UI/SVGContainer";

import { NavigationSectionStateEnum } from "..";
import styles from "./index.module.scss";

interface SectionProps {
  index: number;
  isClickable: boolean;
  clickState: NavigationSectionStateEnum;
  title: string;
  onClick: (section: number) => void;
}

export const Section = ({
  isClickable,
  title,
  onClick,
  clickState,
  index,
}: SectionProps) => {
  return (
    <div
      className={`${styles.section} ${
        clickState === NavigationSectionStateEnum.ACTIVE && styles.activeLink
      } ${isClickable && styles.clickable}`}
      onClick={() => {
        if (isClickable) onClick(index);
      }}
    >
      <p className={`${isClickable && styles.clickable}`}>{`${
        index + 1
      }. ${title}`}</p>
      {clickState === NavigationSectionStateEnum.FINISHED && (
        <SVGContainer
          height="15px"
          width="15px"
          imagePath="/assets/done-icon.svg"
        />
      )}
    </div>
  );
};
