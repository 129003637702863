import { SingleValue } from "react-select";

import { CustomButton } from "~/components/form/CustomButton";
import { DragAndDropFilePicker } from "~/components/form/DragAndDropFilePicker";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { colors } from "~/constants/styles";

import { ImportModeEnum } from "..";
import styles from "./index.module.scss";

interface Props {
  file: File | undefined;
  onFileChange: (value: File | undefined) => void;
  onNext: () => void;
  isProcessing: boolean;
  importMode?: ImportModeEnum;
  importModeChangeHandler: (mode?: ImportModeEnum) => void;
}

export const ImportPage = ({
  onFileChange,
  file,
  onNext,
  isProcessing,
  importMode,
  importModeChangeHandler,
}: Props) => {
  const disabled = isProcessing || !file || !importMode;
  return (
    <div className={`${styles.container}`}>
      <h4>Import your Products File</h4>
      <div className={`${styles.descriptionContainer}`}>
        <p>Welcome to the product import wizard. This wizard helps you to:</p>
        <ul>
          <li>import your product files</li>
          <li>set up your configuration</li>
          <li>add your products to the Marketplace</li>
        </ul>
        <p>
          Import the file containing the products to add to the Marketplace
          first.
        </p>
      </div>
      <div className={`${styles.selectInput}`}>
        <SelectInput
          label="Import Mode"
          fontSize="14px"
          backgroundColor={colors.$inputGray}
          noBorder={true}
          options={[
            { label: "Create mode", value: ImportModeEnum.CREATE },
            { label: "Update mode", value: ImportModeEnum.UPDATE },
          ]}
          onChange={(option: SingleValue<OptionType>) => {
            importModeChangeHandler(
              option?.value ? (option.value as ImportModeEnum) : undefined
            );
          }}
          value={importMode || ""}
          required={true}
        />
      </div>
      <DragAndDropFilePicker
        id="prdouct-file"
        onChange={onFileChange}
        value={file?.name}
        disabled={false}
      />
      <div className={`${styles.buttons}`}>
        <div className={`${styles.button}`}>
          <CustomButton
            backgroundColor={colors.$inputGray}
            borderColor="#DEE3E8"
            borderRadius="5px"
            disabled={disabled}
            onClick={!disabled ? onNext : undefined}
          >
            Next
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
