import { RentPaymentFrequencyEnum } from "~/types/data/RentOrder.type";

export const getFrequencyDisplay = (frequency?: RentPaymentFrequencyEnum) => {
  switch (frequency) {
    case RentPaymentFrequencyEnum.DAILY:
      return "jour";
    case RentPaymentFrequencyEnum.WEEKLY:
      return "semaine";
    case RentPaymentFrequencyEnum.MONTHLY:
      return "mois";
    default:
      return "jour";
  }
};
