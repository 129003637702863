import { Product } from "~/types/data/Product.types";
import { VendorCategory } from "~/types/data/VendorCategory.types";

import { CataloguesTableRow } from "../CataloguesTableRow";
import styles from "./index.module.scss";

interface Props {
  productVariants: Product[];
  selectedRows: Product[];
  onCheckboxChange: (product: Product) => void;
  vendorCategories: VendorCategory[];
  onRowClick: (variantId: number) => void;
  showCompanyColumn: boolean;
}

export const CataloguesTableBody = ({
  productVariants,
  selectedRows,
  onCheckboxChange,
  vendorCategories,
  onRowClick,
  showCompanyColumn,
}: Props) => {
  const isSelected = (productId?: number) =>
    selectedRows.findIndex(({ id }) => productId === id) > -1;

  return (
    <tbody className={`${styles.tbody}`}>
      {productVariants.map((productVariant, index) => {
        const isRowSelected = isSelected(productVariant.id);
        return (
          <CataloguesTableRow
            key={index.toString()}
            productVariant={productVariant}
            isSelected={isRowSelected}
            onCheckboxChange={onCheckboxChange}
            vendorCategories={vendorCategories}
            onClick={onRowClick}
            showCompanyColumn={showCompanyColumn}
          />
        );
      })}
    </tbody>
  );
};
