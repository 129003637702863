import { useMemo } from "react";

import { CustomButton } from "~/components/form/CustomButton";
import { MultipleSelectInput } from "~/components/form/MultipleSelectInput";
import { OptionType } from "~/components/form/SelectInput";
import SVGContainer from "~/components/UI/SVGContainer";
import { colors } from "~/constants/styles";
import { Category } from "~/types/data/Category.type";

import { DeleteModalProps } from "../..";
import { MemoizedCategorySelectInput } from "../CategorySelectInput";
import { VendorCategoryState } from "../VendorCategoriesRow/useVendorCategories";
import styles from "./index.module.scss";
type Props = {
  index: number;
  level1Categories: Category[];
  level3Categories: Category[];
  vendorCategoryState: VendorCategoryState;
  isFirstChild?: boolean;
  attributes: OptionType[];
  updateCategoryMappings: (
    index: number,
    categoryId: number | null,
    categoryLevel: 1 | 2 | 3
  ) => void;
  updateVendorCategoryState: (
    key: keyof VendorCategoryState,
    value: string | string[] | number[] | boolean
  ) => void;
  addNewCategoryMapping: () => void;
  deleteCategoryMapping: (index: number) => void;
  deleteCategory: () => Promise<void>;
  saveCategory: () => Promise<void>;
  loading: boolean;
  setDeleteModalState: (data: DeleteModalProps) => void;
};
const VendorCategoriesMapping = ({
  index,
  level1Categories,
  level3Categories,
  vendorCategoryState,
  isFirstChild,
  attributes,
  updateVendorCategoryState,
  updateCategoryMappings,
  addNewCategoryMapping,
  deleteCategoryMapping,
  saveCategory,
  loading,
  deleteCategory,
  setDeleteModalState,
}: Props) => {
  const mapping = vendorCategoryState.categoryMappings[index];
  const mappingsLength = vendorCategoryState.categoryMappings.length;
  const level2Categories = useMemo(() => {
    if (!mapping.category1Id) return [];
    return (
      level1Categories.find((category) => category.id == mapping?.category1Id)
        ?.children ?? []
    );
  }, [mapping?.category1Id, level1Categories]);

  const level3CategoriesToUse =
    mapping?.category1Id && mapping.category2Id
      ? level2Categories.find((category) => category.id === mapping.category2Id)
          ?.children ?? []
      : mapping?.category1Id && !mapping.category2Id
      ? []
      : level3Categories;

  const level1CategoriesOptions =
    level1Categories.map((category) => ({
      label: category.name,
      value: `${category.id}`,
    })) ?? [];

  const level2CategoriesOptions =
    level2Categories.map((category) => ({
      label: category.name,
      value: `${category.id}`,
    })) ?? [];

  const level3CategoriesOptions =
    level3CategoriesToUse.map((category) => ({
      label: category.name,
      value: `${category.id}`,
    })) ?? [];

  const onValueChange = (value: string | null, level: 1 | 2 | 3) => {
    updateCategoryMappings(index, value ? +value : null, level);
    const newLevel = level + 1;
    if (newLevel == 2 || newLevel == 3) onValueChange(null, newLevel);
  };

  const canSave =
    vendorCategoryState.didChange &&
    vendorCategoryState.categoryMappings.every((mapping) => {
      return (
        (mapping.category1Id && mapping.category2Id && mapping.category3Id) ||
        (!mapping.category1Id && !mapping.category2Id && mapping.category3Id)
      );
    });
  const id =
    typeof vendorCategoryState.id == "number" ? vendorCategoryState.id : "new";
  return (
    <>
      <tr key={index} className={styles.tr}>
        {isFirstChild && (
          <>
            <td rowSpan={mappingsLength} className={styles.idContainer}>
              {id}
            </td>
            <td rowSpan={mappingsLength}>
              {vendorCategoryState.name || "new category"}
            </td>
          </>
        )}
        <td>
          <MemoizedCategorySelectInput
            options={level1CategoriesOptions}
            value={mapping.category1Id}
            onValueChange={(value) => {
              onValueChange(value, 1);
            }}
          />
        </td>
        <td>
          <MemoizedCategorySelectInput
            options={level2CategoriesOptions}
            value={mapping.category2Id}
            onValueChange={(value) => {
              onValueChange(value, 2);
            }}
          />
        </td>
        <td>
          <div className={styles.thirdCategory}>
            <MemoizedCategorySelectInput
              options={level3CategoriesOptions}
              value={mapping.category3Id}
              onValueChange={(value) => {
                onValueChange(value, 3);
              }}
            />
            {mappingsLength > 1 && (
              <SVGContainer
                height="8px"
                width="8px"
                imagePath="/assets/close-button.svg"
                onClick={() => {
                  deleteCategoryMapping(index);
                }}
              />
            )}
          </div>
        </td>

        {index === 0 && (
          <>
            <td rowSpan={mappingsLength}>
              <MultipleSelectInput
                fontSize="14px"
                backgroundColor={colors.$inputGray}
                options={attributes}
                onChange={(options: OptionType[]) => {
                  updateVendorCategoryState(
                    "attributes",
                    options.map((option) => +option.value)
                  );
                }}
                value={attributes.filter((option) =>
                  vendorCategoryState.attributes.includes(+option.value)
                )}
                noBorder
                hideDropdownIndicator
              />
            </td>
            <td rowSpan={mappingsLength}>
              <div className={styles.actions}>
                <CustomButton
                  backgroundColor={colors.$primary}
                  color="white"
                  width="fit-content"
                  borderRadius="8px"
                  height="fit-content"
                  padding="0.5rem"
                  onClick={() => {
                    saveCategory();
                  }}
                  disabled={!canSave || loading}
                >
                  Save
                </CustomButton>
                <SVGContainer
                  width="25px"
                  height="25px"
                  imagePath="/assets/add-button.svg"
                  onClick={addNewCategoryMapping}
                />
                <SVGContainer
                  height="25px"
                  width="25px"
                  imagePath="/assets/delete-icon.svg"
                  onClick={() => {
                    if (typeof id == "number")
                      setDeleteModalState({
                        open: true,
                        deleteCategory: () => deleteCategory(),
                      });
                    else deleteCategory();
                  }}
                />
              </div>
            </td>
          </>
        )}
      </tr>
    </>
  );
};

export default VendorCategoriesMapping;
