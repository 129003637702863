import HeaderLogo from "~/components/navigation/HeaderLogo";
import HeaderNavigation from "~/components/navigation/HeaderNavigation";
import ProfileIcon from "~/components/UI/ProfileIcon";

import styles from "./index.module.scss";

type Props = {
  children: React.ReactNode;
};
const Layout = ({ children }: Props) => {
  return (
    <div className={`${styles.page}`}>
      <header className={`${styles.header}`}>
        <HeaderLogo />
        <HeaderNavigation />
        <ProfileIcon />
      </header>
      <div className={`${styles.content}`}>{children}</div>
    </div>
  );
};

export default Layout;
