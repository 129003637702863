import { ConfirmModal } from "~/components/UI/ConfirmModal";
import CustomModal from "~/components/UI/CustomModal";
import SVGContainer from "~/components/UI/SVGContainer";
import { CollectionModel } from "~/types/data/Collection.type";

import { CollectionModal } from "./CollectionModal";
import styles from "./index.module.scss";
type Props = {
  isDeleteOpen: boolean;
  closeDelete: () => void;
  isEditOpen: boolean;
  closeEdit: () => void;
  openEditModal: () => void;
  deleteCarrier: () => Promise<void>;
  editedCollection?: CollectionModel;
  setCollections: React.Dispatch<React.SetStateAction<CollectionModel[]>>;
};
export const CollectionModals = ({
  isDeleteOpen,
  openEditModal,
  isEditOpen,
  closeDelete,
  closeEdit,
  deleteCarrier,
  editedCollection,
  setCollections,
}: Props) => {
  return (
    <>
      <ConfirmModal
        show={isDeleteOpen}
        headerText="Delete Collection"
        messageText="Are you sure you want to delete this collection?"
        onConfirm={() => {
          deleteCarrier();
        }}
        onExit={closeDelete}
      />
      <CustomModal show={isEditOpen} onCancel={closeEdit} disableScroll>
        <CollectionModal
          oldCollection={editedCollection}
          closeModal={closeEdit}
          onSuccess={(newCollection) => {
            setCollections((prevState) => {
              if (!prevState) return [newCollection];
              const attFound = prevState.find(
                (collection) => collection.id === newCollection.id
              );
              if (!attFound) return [...prevState, newCollection];
              return prevState.map((collection) => {
                if (collection.id === newCollection.id) {
                  return newCollection;
                }
                return collection;
              });
            });
          }}
        />
      </CustomModal>
      <div className={styles.headerContainer}>
        <h2>Collections</h2>{" "}
        <SVGContainer
          height="30px"
          width="30px"
          imagePath="/assets/add-button.svg"
          onClick={openEditModal}
        />
      </div>
    </>
  );
};
