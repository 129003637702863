import { DateTime } from "luxon";
import { SingleValue } from "react-select";

import CustomCheckbox from "~/components/form/CustomCheckbox";
import DatePicker from "~/components/form/DatePicker";
import { MultipleSelectInput } from "~/components/form/MultipleSelectInput";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import SVGContainer from "~/components/UI/SVGContainer";
import { useAppDispatch, useAppSelector } from "~/redux/hooks";
import {
  onFilterArgsChange,
  selectCatalogueFilterArgs,
} from "~/redux/slice/catalogueFilter.slice";
import { AttributeValue } from "~/types/data/AttributeValue.types";
import { ImageImportStatusEnum } from "~/types/data/Product.types";
import { VendorCategory } from "~/types/data/VendorCategory.types";

import styles from "./index.module.scss";

export interface Props {
  brandValues: AttributeValue[];
  vendorCategories: VendorCategory[];
  stateTypeValues: AttributeValue[];
}

export const CataloguesFilterForm = ({
  brandValues,
  vendorCategories,
  stateTypeValues,
}: Props) => {
  const brandOptions: OptionType[] = brandValues.map(({ value }) => ({
    label: value,
    value: value,
  }));

  const stateTypeOptions: OptionType[] = stateTypeValues.map(({ value }) => ({
    label: value,
    value: value,
  }));

  const filterArgsState = useAppSelector(selectCatalogueFilterArgs);

  const dispatch = useAppDispatch();

  const categoriesOptions: OptionType[] = vendorCategories.map(
    ({ id, name }) => {
      return {
        label: name,
        value: id.toString(),
      } as OptionType;
    }
  );

  const imageImportStatusOptions: OptionType[] = [
    {
      label: "Not started",
      value: ImageImportStatusEnum.NOT_STARTED,
    },
    {
      label: "In progress",
      value: ImageImportStatusEnum.IN_PROGRESS,
    },
    {
      label: "Done",
      value: ImageImportStatusEnum.DONE,
    },
    {
      label: "Error",
      value: ImageImportStatusEnum.ERROR,
    },
  ];

  const clearAllFiltersHandler = () => {
    dispatch(
      onFilterArgsChange({
        filterArg: "clearAll",
      })
    );
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.title}`}>
        <SVGContainer
          height="16px"
          width="17px"
          imagePath="/assets/filter-icon.svg"
        />
        <p>Filtrer les produits</p>
      </div>
      <div className={`${styles.formSection}`}>
        <p className={`${styles.label}`}>Catégorie :</p>
        <div className={`${styles.singleInput}`}>
          <SelectInput
            fontSize="12px"
            backgroundColor="white"
            options={categoriesOptions}
            onChange={(option: SingleValue<OptionType>) => {
              dispatch(
                onFilterArgsChange({
                  filterArg: "category",
                  categoryId: +(option?.value || 0) || undefined,
                })
              );
            }}
            value={filterArgsState.categoryId?.toString() || ""}
            hasDefault={true}
            defaultLabel="Not selected"
          />
        </div>
      </div>
      <div className={`${styles.formSection}`}>
        <p className={`${styles.label}`}>Statut du produit :</p>
        <div className={`${styles.inputsContainer}`}>
          <div className={`${styles.column}`}>
            <CustomCheckbox
              label="En attente de validation"
              checked={filterArgsState.waitingForValidation}
              onChange={(e) => {
                dispatch(
                  onFilterArgsChange({
                    filterArg: "waitingForValidation",
                    waitingForValidation: e.target.checked,
                  })
                );
              }}
              id="waitingForValidation"
            />
            <CustomCheckbox
              label="Modification requise"
              checked={filterArgsState.modificationRequested}
              onChange={(e) => {
                dispatch(
                  onFilterArgsChange({
                    filterArg: "modificationRequested",
                    modificationRequested: e.target.checked,
                  })
                );
              }}
              id="modificationRequested"
            />
            <CustomCheckbox
              label="Incomplète"
              checked={filterArgsState.incomplete}
              onChange={(e) => {
                dispatch(
                  onFilterArgsChange({
                    filterArg: "incomplete",
                    incomplete: e.target.checked,
                  })
                );
              }}
              id="incomplete"
            />
          </div>
          <div className={`${styles.column}`}>
            <CustomCheckbox
              label="Refusé"
              checked={filterArgsState.refused}
              onChange={(e) => {
                dispatch(
                  onFilterArgsChange({
                    filterArg: "refused",
                    refused: e.target.checked,
                  })
                );
              }}
              id="refused"
            />
            <CustomCheckbox
              label="Publié"
              checked={filterArgsState.published}
              onChange={(e) => {
                dispatch(
                  onFilterArgsChange({
                    filterArg: "published",
                    published: e.target.checked,
                  })
                );
              }}
              id="published"
            />
          </div>
        </div>
      </div>
      <div className={`${styles.formSection}`}>
        <p className={`${styles.label}`}>Image import status :</p>
        <div className={`${styles.singleInput}`}>
          <SelectInput
            fontSize="12px"
            backgroundColor="white"
            options={imageImportStatusOptions}
            onChange={(option: SingleValue<OptionType>) => {
              dispatch(
                onFilterArgsChange({
                  filterArg: "imageImportStatus",
                  imageImportStatus:
                    (option?.value as ImageImportStatusEnum) || undefined,
                })
              );
            }}
            value={filterArgsState.imageImportStatus?.toString() || ""}
            hasDefault={true}
            defaultLabel="Not selected"
          />
        </div>
        <div className={`${styles.inputsContainer}`}>
          <div className={`${styles.column}`}>
            <CustomCheckbox
              label="Image present"
              checked={filterArgsState.imagePresent}
              onChange={(e) => {
                dispatch(
                  onFilterArgsChange({
                    filterArg: "imagePresent",
                    imagePresent: e.target.checked,
                  })
                );
              }}
              id="imagePresent"
            />
          </div>
          <div className={`${styles.column}`}>
            <CustomCheckbox
              label="Image not present"
              checked={filterArgsState.imagePresent === false ? true : false}
              onChange={(e) => {
                dispatch(
                  onFilterArgsChange({
                    filterArg: "imageNotPresent",
                    imageNotPresent: e.target.checked,
                  })
                );
              }}
              id={"imageNotPresent"}
            />
          </div>
        </div>
      </div>

      <div className={`${styles.formSection}`}>
        <p className={`${styles.label}`}>Offre :</p>
        <div className={`${styles.inputsContainer}`}>
          <div className={`${styles.column}`}>
            <CustomCheckbox
              label="En stock"
              checked={filterArgsState.inStock}
              onChange={(e) => {
                dispatch(
                  onFilterArgsChange({
                    filterArg: "inStock",
                    inStock: e.target.checked,
                  })
                );
              }}
              id="inStock"
            />
          </div>
          <div className={`${styles.column}`}>
            <CustomCheckbox
              label="Pas de stock"
              checked={filterArgsState.inStock === false ? true : false}
              onChange={(e) => {
                dispatch(
                  onFilterArgsChange({
                    filterArg: "notInStock",
                    notInStock: e.target.checked,
                  })
                );
              }}
              id={"notInStock"}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.formSection}`}>
        <p className={`${styles.label}`}>Product state :</p>
        <div className={`${styles.singleInput}`}>
          <MultipleSelectInput
            fontSize="12px"
            backgroundColor="white"
            options={stateTypeOptions}
            onChange={(options: OptionType[]) => {
              dispatch(
                onFilterArgsChange({
                  filterArg: "stateType",
                  stateType: options.map((option) => option.value),
                })
              );
            }}
            value={stateTypeOptions.filter((option) =>
              filterArgsState.stateType?.includes(option.value)
            )}
          />
        </div>
        <div className={`${styles.inputsContainer}`}>
          <div className={`${styles.column}`}>
            <CustomCheckbox
              label="Sale"
              checked={filterArgsState.forSale}
              onChange={(e) => {
                dispatch(
                  onFilterArgsChange({
                    filterArg: "forSale",
                    forSale: e.target.checked,
                  })
                );
              }}
              id="forSale"
            />
          </div>
          <div className={`${styles.column}`}>
            <CustomCheckbox
              label="Rent"
              checked={filterArgsState.forRent}
              onChange={(e) => {
                dispatch(
                  onFilterArgsChange({
                    filterArg: "forRent",
                    forRent: e.target.checked,
                  })
                );
              }}
              id="forRent"
            />
          </div>{" "}
          <div className={`${styles.column}`}>
            <CustomCheckbox
              label="Subscription"
              checked={filterArgsState.forSubscription}
              onChange={(e) => {
                dispatch(
                  onFilterArgsChange({
                    filterArg: "forSubscription",
                    forSubscription: e.target.checked,
                  })
                );
              }}
              id="forSubscription"
            />
          </div>
        </div>
      </div>
      <div className={`${styles.formSection}`}>
        <p className={`${styles.label}`}>Marque :</p>
        <div className={`${styles.inputsContainer}`}>
          <div className={`${styles.column}`}>
            <SelectInput
              fontSize="12px"
              backgroundColor="white"
              options={brandOptions}
              onChange={(option: SingleValue<OptionType>) => {
                dispatch(
                  onFilterArgsChange({
                    filterArg: "brand",
                    brand: option?.value ? option.value : undefined,
                  })
                );
              }}
              value={filterArgsState.brand || ""}
              hasDefault={true}
              defaultLabel="Not selected"
            />
          </div>

          <div style={{ width: "100%" }} />
        </div>
      </div>
      <div className={`${styles.formSection}`}>
        <p className={`${styles.label}`}>Date de création :</p>
        <div className={`${styles.inputsContainer}`}>
          <div className={`${styles.datePickerContainer}`}>
            <p>Du</p>
            <DatePicker
              fontSize="12px"
              icon="keyboardArrowDown"
              onChange={(value: DateTime | null) => {
                if (value?.isValid !== false) {
                  const modifiedDate = value?.set({ hour: 0, minute: 0 });
                  dispatch(
                    onFilterArgsChange({
                      filterArg: "createdAtFrom",
                      createdAtFrom: modifiedDate?.toISO(),
                    })
                  );
                }
              }}
              value={
                filterArgsState.createdAtFrom
                  ? DateTime.fromISO(filterArgsState.createdAtFrom)
                  : null
              }
              backgroundColor="white"
              borderRadius="5px"
            />
          </div>
          <div className={`${styles.datePickerContainer}`}>
            <p>au</p>
            <DatePicker
              fontSize="12px"
              icon="keyboardArrowDown"
              onChange={(value: DateTime | null) => {
                if (value?.isValid !== false) {
                  const modifiedDate = value?.set({ hour: 23, minute: 59 });
                  dispatch(
                    onFilterArgsChange({
                      filterArg: "createdAtTo",
                      createdAtTo: modifiedDate?.toISO(),
                    })
                  );
                }
              }}
              value={
                filterArgsState.createdAtTo
                  ? DateTime.fromISO(filterArgsState.createdAtTo)
                  : null
              }
              backgroundColor="white"
              borderRadius="5px"
            />
          </div>
        </div>
      </div>
      <div className={`${styles.formSection}`}>
        <p className={`${styles.label}`}>Date de mise à jour :</p>
        <div className={`${styles.inputsContainer}`}>
          <div className={`${styles.datePickerContainer}`}>
            <p>Du</p>
            <DatePicker
              fontSize="12px"
              icon="keyboardArrowDown"
              onChange={(value: DateTime | null) => {
                if (value?.isValid !== false) {
                  const modifiedDate = value?.set({ hour: 0, minute: 0 });
                  dispatch(
                    onFilterArgsChange({
                      filterArg: "updatedAtFrom",
                      updatedAtFrom: modifiedDate?.toISO(),
                    })
                  );
                }
              }}
              value={
                filterArgsState.updatedAtFrom
                  ? DateTime.fromISO(filterArgsState.updatedAtFrom)
                  : null
              }
              backgroundColor="white"
              borderRadius="5px"
            />
          </div>
          <div className={`${styles.datePickerContainer}`}>
            <p>au</p>
            <DatePicker
              fontSize="12px"
              icon="keyboardArrowDown"
              onChange={(value: DateTime | null) => {
                if (value?.isValid !== false) {
                  const modifiedDate = value?.set({ hour: 23, minute: 59 });
                  dispatch(
                    onFilterArgsChange({
                      filterArg: "updatedAtTo",
                      updatedAtTo: modifiedDate?.toISO(),
                    })
                  );
                }
              }}
              value={
                filterArgsState.updatedAtTo
                  ? DateTime.fromISO(filterArgsState.updatedAtTo)
                  : null
              }
              backgroundColor="white"
              borderRadius="5px"
            />
          </div>
        </div>
        <div className={`${styles.clearAllButtonContainer}`}>
          <p className={`${styles.label}`} onClick={clearAllFiltersHandler}>
            Effacer tous les filtres
          </p>
        </div>
      </div>
    </div>
  );
};
