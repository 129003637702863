import { Conversation } from "~/types/data/Conversation.types";

import { ConversationCard } from "./ConversationCard";
import styles from "./index.module.scss";

interface Props {
  conversations: Conversation[];
  clickHandler: (index: number) => void;
}

export const ConversationsList = ({ conversations, clickHandler }: Props) => {
  return (
    <div className={styles.container}>
      {conversations.map((conversation) => {
        return (
          <div key={conversation.id} className={styles.cardContainer}>
            <ConversationCard
              conversation={conversation}
              onClick={() => {
                clickHandler(conversation.id);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
