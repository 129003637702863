import Joi from "joi";
import passwordComplexity from "joi-password-complexity";

import { SetNewPasswordInput } from "~/types/input/auth.types";

export const setNewPasswordValidationSchema = Joi.object<SetNewPasswordInput>({
  username: Joi.string()
    .email({ tlds: false, allowUnicode: true })
    .required()
    .error(new Error("Email invalide.")),
  session: Joi.string()
    .required()
    .error(new Error("Le code de session ne peut pas être vide.")),
  newPassword: passwordComplexity()
    .required()
    .error(
      new Error("Au moins une majuscule, un caractère spécial et un chiffre.")
    ),
});
