import { gql } from "@apollo/client";

import { graphqlGetProductFields } from "~/constants/graphql";
import { CatalogueFilterArgs } from "~/redux/slice/catalogueFilter.slice";
import {
  Product,
  ProductHighlightEnum,
  ProductStatusEnum,
} from "~/types/data/Product.types";

// inputs
export interface CreateOrUpdateProductInput {
  CreateOrUpdateProductInput: Product;
}

export interface GetProductVariantsInput {
  ProductVariantsSelectArgs: CatalogueFilterArgs;
}

export interface GetProductVariantsByIdInput {
  GetProductVariantsByIdInput: { id: number };
}

export interface DeleteProductInput {
  DeleteProductInput: { id: number };
}

export interface DeleteProductsByIdsInput {
  DeleteProductsByIdsInput: { ids: number[] };
}

export interface UpdateProductsStatusInput {
  UpdateProductsStatusInput: {
    productsIds: number[];
    status: ProductStatusEnum;
  };
}

export interface UpdateAllProductsStatusInput {
  UpdateAllProductsStatusInput: {
    filterArgs: CatalogueFilterArgs;
    status: ProductStatusEnum;
  };
}

export interface UpdateProductsHighlightInput {
  UpdateProductsHighlightInput: {
    productsIds: number[];
    highlight: ProductHighlightEnum[];
  };
}

export interface UpdateAllProductsHighlightInput {
  UpdateAllProductsHighlightInput: {
    filterArgs: CatalogueFilterArgs;
    highlight: ProductHighlightEnum[];
  };
}

// responses
export interface CreateOrUpdateProductResponse {
  createOrUpdateProduct: Product;
}

export interface GetProductVariantsResponse {
  getProductVariants: {
    productVariants: Product[];
    nextCursor: number;
    hasMoreData: boolean;
  };
}

export interface GetProductVariantsByIdResponse {
  getProductVariantsById: Product[];
}

export interface DeleteProductResponse {
  deleteProduct: Product;
}

export interface DeleteProductsByIdsResponse {
  deleteProductsByIds: { count: number };
}

export interface DeleteAllProductsResponse {
  deleteAllProducts: { count: number };
}

export interface GetProductsCountResponse {
  getProductsCount: { count: number };
}

export interface UpdateProductsStatusResponse {
  updateProductsStatus: { count: number };
}

export interface UpdateAllProductsStatusResponse {
  updateAllProductsStatus: { count: number };
}

export interface UpdateProductsHighlightResponse {
  updateProductsHighlight: { count: number };
}

export interface UpdateAllProductsHighlightResponse {
  updateAllProductsHighlight: { count: number };
}

// select fields
const productFields = graphqlGetProductFields.join(" ");

// queries and mutations
export const createOrUpdateProduct = gql`
  mutation ($CreateOrUpdateProductInput: CreateOrUpdateProductInput!) {
    createOrUpdateProduct(CreateOrUpdateProductInput: $CreateOrUpdateProductInput) {
      ${productFields}

      companyId
      storeName
      sellerName
    }
  }
`;

export const getProductVariants = gql`
  query ($ProductVariantsSelectArgs: ProductVariantsSelectArgs!) {
    getProductVariants(ProductVariantsSelectArgs: $ProductVariantsSelectArgs) {
      productVariants {
        ${productFields}
        image1FullPath
        vendorCategory {
          id
          name
        }
        companyId
        storeName
        sellerName
      }

      nextCursor
      hasMoreData

    }
  }
`;

export const getProductVariantsById = gql`
  query ($GetProductVariantsByIdInput: GetProductVariantsByIdInput!) {
    getProductVariantsById(GetProductVariantsByIdInput: $GetProductVariantsByIdInput) {
      ${productFields}\

      companyId
      storeName
      sellerName

    }
  }
`;

export const getProductsCount = gql`
  query ($ProductVariantsSelectArgs: ProductVariantsSelectArgs!) {
    getProductsCount(ProductVariantsSelectArgs: $ProductVariantsSelectArgs) {
      count
    }
  }
`;

export const deleteProduct = gql`
  mutation ($DeleteProductInput: DeleteProductInput!) {
    deleteProduct(DeleteProductInput: $DeleteProductInput) {
      ${graphqlGetProductFields}
    }
  }
`;

export const deleteProductsByIds = gql`
  mutation ($DeleteProductsByIdsInput: DeleteProductsByIdsInput!) {
    deleteProductsByIds(DeleteProductsByIdsInput: $DeleteProductsByIdsInput) {
      count
    }
  }
`;

export const deleteAllProducts = gql`
  mutation ($ProductVariantsSelectArgs: ProductVariantsSelectArgs!) {
    deleteAllProducts(ProductVariantsSelectArgs: $ProductVariantsSelectArgs) {
      count
    }
  }
`;

export const UPDATE_PRODUCTS_STATUS = gql`
  mutation ($UpdateProductsStatusInput: UpdateProductsStatusInput!) {
    updateProductsStatus(
      UpdateProductsStatusInput: $UpdateProductsStatusInput
    ) {
      count
    }
  }
`;

export const UPDATE_ALL_PRODUCTS_STATUS = gql`
  mutation ($UpdateAllProductsStatusInput: UpdateAllProductsStatusInput!) {
    updateAllProductsStatus(
      UpdateAllProductsStatusInput: $UpdateAllProductsStatusInput
    ) {
      count
    }
  }
`;

export const UPDATE_PRODUCTS_HIGHLIGHT = gql`
  mutation ($UpdateProductsHighlightInput: UpdateProductsHighlightInput!) {
    updateProductsHighlight(
      UpdateProductsHighlightInput: $UpdateProductsHighlightInput
    ) {
      count
    }
  }
`;

export const UPDATE_ALL_PRODUCTS_HIGHLIGHT = gql`
  mutation (
    $UpdateAllProductsHighlightInput: UpdateAllProductsHighlightInput!
  ) {
    updateAllProductsHighlight(
      UpdateAllProductsHighlightInput: $UpdateAllProductsHighlightInput
    ) {
      count
    }
  }
`;
