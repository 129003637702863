import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import styles from "./index.module.scss";

export interface HoverSubLink {
  to: string;
  label: string;
}

interface Props {
  label: string;
  to: string;
  height?: string;
  width?: string;
  padding?: string;
  borderColor?: string;
  textColor?: string;
  backgroundColor?: string;
  activeTextColor?: string;
  activeBackgroundColor?: string;
  borderRadius?: string;
  margin?: string;
  preventClickOnActive?: boolean;
  subLinks: HoverSubLink[];
}

export const CustomHoverNavButton = ({
  label,
  to,
  height,
  width,
  padding,
  borderColor,
  textColor,
  backgroundColor,
  activeTextColor,
  activeBackgroundColor,
  borderRadius,
  margin,
  preventClickOnActive,
  subLinks,
}: Props) => {
  const pathName = useLocation().pathname;
  const isActive = pathName.includes(to);

  const [hover, setHover] = useState(false);

  const clickHandler = (event: React.FormEvent) => {
    if (preventClickOnActive && isActive) {
      event.preventDefault();
    }
  };

  const mouseOverHandler = () => {
    setHover(true);
  };

  const mouseLeaveHandler = () => {
    setHover(false);
  };

  return (
    <div onMouseOver={mouseOverHandler} onMouseLeave={mouseLeaveHandler}>
      <NavLink
        to={to}
        className={`${styles.navLink}`}
        onClick={clickHandler}
        style={{
          height: height,
          padding: padding,
          width: width,
          border: borderColor ? `1px solid ${borderColor}` : "",
          borderRadius: borderRadius,
          backgroundColor:
            (isActive ? activeBackgroundColor : backgroundColor) || "inherit",
          color: (isActive ? activeTextColor : textColor) || "black",
          textDecoration: "none",
          margin: margin || 0,
        }}
      >
        {label}
      </NavLink>
      {hover && subLinks.length > 0 && (
        <div className={`${styles.dropdown}`}>
          <div className={`${styles.dropdownContent}`}>
            {subLinks.map(({ label, to }, index) => (
              <NavLink to={to} onClick={clickHandler} key={index}>
                {label}
              </NavLink>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
