import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ConversationContainer } from "~/components/messages/ConversationContainer";
import { selectConversationsState } from "~/redux/slice/conversations.slice";
import { Conversation } from "~/types/data/Conversation.types";
import { MessagesSelectFilterEnum } from "~/types/data/Message.types";

import { getProfileName } from "../MesCommandes/DetailsDuneCommande/util/getProfileName";
import { ConversationsList } from "./ConversationsList";
import { FilterBar } from "./FilterBar";
import styles from "./index.module.scss";

export interface MessagesFilterState {
  searchBox: string;
  filterBox?: MessagesSelectFilterEnum;
}

export const MaMessagerie = () => {
  const [filteredConversations, setFilteredConversations] = useState<
    Conversation[]
  >([]);
  const [conversationId, setConversationId] = useState(0);
  const [fitlerState, setFilterState] = useState<MessagesFilterState>({
    searchBox: "",
  });

  const { conversations } = useSelector(selectConversationsState);
  const conversation = conversations.find(({ id }) => id === conversationId);

  const changeConversationHandler = (conversationId: number) => {
    setConversationId(conversationId);
  };

  const filterChangeHandler = <T extends keyof MessagesFilterState>(
    inputName: T,
    changes: MessagesFilterState[T]
  ) => {
    setFilterState((prev) => ({ ...prev, [inputName]: changes }));
  };

  useEffect(() => {
    const filteredConversations = conversations.filter(
      ({ order, unreadCount }) => {
        const buyerName = getProfileName(
          order?.buyer?.firstName,
          order?.buyer?.lastName
        );
        const nameFilter = buyerName
          .toLowerCase()
          .includes(fitlerState.searchBox.toLowerCase());

        const selectFilter = !fitlerState.filterBox
          ? true
          : fitlerState.filterBox === MessagesSelectFilterEnum.READ
          ? !unreadCount
          : unreadCount;

        return nameFilter && selectFilter;
      }
    );

    setFilteredConversations(filteredConversations);
  }, [fitlerState, conversations]);

  return (
    <div className={styles.container}>
      <>
        <FilterBar
          filterState={fitlerState}
          filterChangeHandler={filterChangeHandler}
        />
        <div className={styles.conversationsContainer}>
          <div className={styles.messagesListContainer}>
            <ConversationsList
              conversations={filteredConversations ?? []}
              clickHandler={changeConversationHandler}
            />
          </div>
          <div className={styles.conversationContainer}>
            {conversation ? (
              <ConversationContainer
                conversation={conversation}
                order={conversation.order}
              />
            ) : (
              <div>no conversation selected</div>
            )}
          </div>
        </div>
      </>
    </div>
  );
};
