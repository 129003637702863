import { useEffect, useState } from "react";

import { CollectionModel } from "~/types/data/Collection.type";
import { Product } from "~/types/data/Product.types";

import { CollectionCard } from "../Collections/CollectionCard";
import styles from "./index.module.scss";
import { ProductsList } from "./ProductsList";

type Props = {
  collections: CollectionModel[];
  setSelectedCollection: (collection: CollectionModel) => void;
  selectedCollection?: CollectionModel;
  openEditModal: (collection: CollectionModel) => void;
  openDeleteModal: (collection: CollectionModel) => void;
};
const CollectionsAndProducts = ({
  collections,
  selectedCollection,
  setSelectedCollection,
  openEditModal,
  openDeleteModal,
}: Props) => {
  const [productsState, setProductsState] = useState<Product[]>(
    selectedCollection?.products || []
  );

  const sortedProducts = productsState.sort(
    (a, b) => (a.priorityInCollection ?? 0) - (b.priorityInCollection ?? 0)
  );

  const scrollToSection = () => {
    const section = document.getElementById("top");
    if (section) section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleCollectionClick = (collection: CollectionModel) => {
    setSelectedCollection(collection);
    scrollToSection();
  };

  const updateProductsSuccessHandler = (product: Product) => {
    setProductsState((prevState) => {
      const productIndex = prevState.findIndex((p) => p.id === product.id);
      const newState = [...prevState];
      newState[productIndex] = product;
      return newState;
    });
  };

  const updateProductsOrderSuccessHandler = (products: Product[]) => {
    setProductsState(products);
  };

  useEffect(() => {
    setProductsState(selectedCollection?.products || []);
    console.log("selectedCollection", selectedCollection?.products || []);
  }, [selectedCollection]);

  return (
    <div className={styles.container} id="top">
      <div className={styles.listContainer}>
        {collections?.map((collection) => {
          return (
            <div
              key={collection.id}
              onClick={() => {
                handleCollectionClick(collection);
              }}
            >
              <CollectionCard
                isSelected={selectedCollection?.id === collection.id}
                collection={collection}
                key={collection.id}
                onEdit={openEditModal}
                onDelete={openDeleteModal}
              />
            </div>
          );
        })}
      </div>
      {!!sortedProducts.length && (
        <ProductsList
          collection={selectedCollection}
          disabled={!selectedCollection}
          products={sortedProducts}
          updateProductsOrderSuccessHandler={updateProductsOrderSuccessHandler}
          updateProductsSuccessHandler={updateProductsSuccessHandler}
        />
      )}
    </div>
  );
};

export default CollectionsAndProducts;
