import InfiniteScroll from "react-infinite-scroll-component";

import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { HeadCell } from "~/types/common/headCell.type";
import { Company } from "~/types/data/Company.types";

import { CompaniesTableBody } from "./CompaniesTableBody";
import { CompaniesTableHead } from "./CompaniesTableHead";
import styles from "./index.module.scss";

const HEADCELLS: HeadCell<Company>[] = [
  { id: 1, label: "", isCheckbox: true },
  { id: 2, label: "ID", isSortable: true, associatedKey: "id" },
  { id: 3, label: "Shop's name", isSortable: true, associatedKey: "storeName" },
  {
    id: 4,
    label: "Company name",
    isSortable: true,
    associatedKey: "companyName",
  },
  {
    id: 5,
    label: "Email",
    isSortable: true,
    associatedKey: "email",
  },
  {
    id: 6,
    label: "Phone number",
    isSortable: true,
    associatedKey: "companyPhoneNumber",
  },
  {
    id: 7,
    label: "Mangopay ID",
    isSortable: true,
    associatedKey: "mangopayId",
  },
  {
    id: 8,
    label: "Created at",
    isSortable: true,
    associatedKey: "createdAt",
  },
  {
    id: 9,
    label: "Total revenue",
  },
  {
    id: 10,
    label: "Products",
  },
  {
    id: 11,
    label: "Orders",
  },
  {
    id: 12,
    label: "Evaluation",
  },
  {
    id: 13,
    label: "KYC",
  },
];
interface Props {
  companies?: Company[];
  selectedRows: Company[];
  onCheckboxChange: (company: Company) => void;
  onSelectAllChange: (selected: boolean) => void;
  isSelectedAll: boolean;
  fetchMoreData: () => void;
  hasMoreData: boolean;
  onRowClick: (variantId: number) => void;
}

export const CompaniesTable = ({
  companies,
  selectedRows,
  onCheckboxChange,
  onSelectAllChange,
  fetchMoreData,
  hasMoreData,
  onRowClick,
  isSelectedAll,
}: Props) => {
  return (
    <>
      <InfiniteScroll
        dataLength={companies?.length ?? 0}
        next={fetchMoreData}
        hasMore={!!companies && hasMoreData}
        loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
      >
        <table className={`${styles.table}`}>
          <CompaniesTableHead
            headCells={HEADCELLS}
            onSelectAllChange={onSelectAllChange}
            allSelected={isSelectedAll}
          />
          <CompaniesTableBody
            companies={companies || []}
            selectedRows={selectedRows}
            onCheckboxChange={onCheckboxChange}
            onRowClick={onRowClick}
          />
        </table>
      </InfiniteScroll>
      {!companies && (
        <div className={styles.loaderContainer}>
          <SimpleLoader size="size3" fill={colors.$primary} />
        </div>
      )}
    </>
  );
};
