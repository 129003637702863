import { gql } from "@apollo/client";

import { graphqlGetCategoryFields } from "~/constants/graphql";
import { Category } from "~/types/data/Category.type";

export interface GetAllLevel1CategoriesResponse {
  getAllLevel1Categories: Category[];
}

export interface GetAllParentLevelCategoriesResponse {
  getAllParentLevelCategories: Category[];
}

export interface CreateOrUpdateCategoryResponse {
  createOrUpdateCategory: Category;
}

export interface GetAllCategoriesResponse {
  getAllCategories: Category[];
}

export interface GetAllCategoriesResponse {
  getAllLevel1Categories: Category[];
}

export interface DeleteCategoryResponse {
  id: number;
}
export interface UpdateCategoriesOrderResponse {
  updateCategoriesOrder: Category[];
}

export interface CreateOrUpdateCategoryInput {
  CreateOrUpdateCategoryInput: {
    id?: number;
    name: string;
    commissionPercentage?: number;
    parentCategoriesIds: number[];
    level: number;
  };
}

export type UpdateCategoryOrderType = {
  id: number;
  order: number;
};

export interface UpdateCategoriesOrderInput {
  UpdateCategoriesOrderInput: { categories: UpdateCategoryOrderType[] };
}

export interface DeleteCategoryInput {
  DeleteCategoryInput: {
    id: number;
    level: number;
    parentId?: number;
  };
}

const categoriesFields = graphqlGetCategoryFields.join(" ");

export const getCategories = gql`
  query {
    getAllLevel1Categories {
      ${categoriesFields}

      children {
        ${categoriesFields}
        children {
          ${categoriesFields}

          parents {
            ${categoriesFields}
          }
        }

        parents {
          ${categoriesFields}
        }
      }
      
      parents {
        ${categoriesFields}
      }

    }
  }
`;

export const GET_ALL_PARENTS_LEVEL_CATEGORIES = gql`
  query {
    getAllParentLevelCategories {
      ${categoriesFields}
    }
  }
`;

export const CREATE_OR_UPDATE_CATEGORY = gql`
  mutation ($CreateOrUpdateCategoryInput: CreateOrUpdateCategoryInput!) {
    createOrUpdateCategory(CreateOrUpdateCategoryInput: $CreateOrUpdateCategoryInput) {
      ${categoriesFields}
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation ($DeleteCategoryInput: DeleteCategoryInput!) {
    adminDeleteCategory(DeleteCategoryInput: $DeleteCategoryInput) {
      id
    }
  }
`;

export const GET_ALL_CATEGORIES = gql`
  query {
    getAllCategories {
      ${categoriesFields}
    }
  }
`;

export const GET_ALL_LEVEL1_CATEGORIES = gql`
  query {
    getAllLevel1Categories {
      ${categoriesFields}
      children {
        ${categoriesFields}
        
        children {
          ${categoriesFields}
        }
      }

    }
  }
`;

export const UPDATE_CATEGORIES_ORDER = gql`
  mutation ($UpdateCategoriesOrderInput: UpdateCategoriesOrderInput!) {
    updateCategoriesOrder(UpdateCategoriesOrderInput: $UpdateCategoriesOrderInput) {
      ${categoriesFields}
    }
  }
`;
