import { gql } from "@apollo/client";

import {
  graphGetCustomMessageFields,
  graphqlGetConversationFields,
  graphqlGetMessageFields,
  graphqlGetOrderFields,
  graphqlGetOrderToProductFields,
  graphqlGetProductFields,
  graphqlGetProfileFields,
} from "~/constants/graphql";
import { Conversation } from "~/types/data/Conversation.types";
import { MessageTypeEnum } from "~/types/data/Message.types";

export interface CreateMessageInput {
  CreateMessageInput: {
    conversationId: number;
    type: MessageTypeEnum;
    text?: string;
    picturePath?: string;
    filePath?: string;
  };
}

export interface MarkMessagesAsReadInput {
  MarkMessagesAsReadInput: {
    conversationId: number;
  };
}

export interface CreateMessageResponse {
  createMessage: Conversation;
}

export interface GetCompanyConversationsResponse {
  getCompanyConversations: Conversation[];
}

const orderFields = graphqlGetOrderFields.join(" ");
const orderToProductFields = graphqlGetOrderToProductFields.join(" ");
const productFields = graphqlGetProductFields.join(" ");
const conversationFields = graphqlGetConversationFields.join(" ");
const messageFields = graphqlGetMessageFields.join(" ");
const customMessageFields = graphGetCustomMessageFields.join(" ");
const profileFields = graphqlGetProfileFields.join(" ");

export const createMessage = gql`
  mutation ($CreateMessageInput: CreateMessageInput!) {
    createMessage(CreateMessageInput: $CreateMessageInput) {
        ${conversationFields}

        messages { 
          ${messageFields}
          
          pictureUrl
          fileUrl

          customMessage {
            ${customMessageFields}

            orderToProducts {
              ${orderToProductFields}

              product{
                ${productFields}
              }
            }
          }
        }

        order{
          ${orderFields}

          buyer{
            ${profileFields}

            profilePicUrl
            phoneNumber
            email
          }
        }
      
      
    }
  }
`;

export const markMessagesAsRead = gql`
  mutation ($MarkMessagesAsReadInput: MarkMessagesAsReadInput!) {
    markMessagesAsRead(MarkMessagesAsReadInput: $MarkMessagesAsReadInput) {
      id
    }
  }
`;

export const getCompanyConversations = gql`
  query {
    getCompanyConversations {
      ${conversationFields}

        messages { 
          ${messageFields}
          
          pictureUrl
          fileUrl

          customMessage {
            ${customMessageFields}

            orderToProducts {
              ${orderToProductFields}

              product{
                ${productFields}
              }
            }
          }
        }

        order{
          ${orderFields}

          buyer{
            ${profileFields}

            profilePicUrl
            phoneNumber
            email
          }
        }
    }
  }
`;
