import { sendRequest } from "~/api/clients/axios";
import { ApiGetResponse } from "~/types/common/auth.types";
import { AxiosHttpError } from "~/types/common/error.types";
import { DeleteUserInput, User } from "~/types/data/User.types";

export default async function deleteSeller(
  input: DeleteUserInput
): Promise<ApiGetResponse<User>> {
  try {
    const res = await sendRequest<DeleteUserInput, User>(
      "post",
      "/auth/delete-user",
      input
    );
    return { success: true, data: res.data };
  } catch (err) {
    const axiosError = err as AxiosHttpError;
    if (axiosError.response?.data) {
      const { message } = axiosError.response.data;
      return {
        success: false,
        errorMessage: typeof message === "string" ? message : message[0],
      };
    }
  }
  return {
    success: false,
    errorMessage: "Unkown Error",
  };
}
