import { Carrier } from "./Carrier.types";

export enum TransportationStatusEnum {
  NOT_TRACKABLE = "NOT_TRACKABLE",
  NOTIFICATION = "NOTIFICATION",
  PROCESSING = "PROCESSING",
  DISPATCH = "DISPATCH",
  ARRIVAL_ON_SITE = "ARRIVAL_ON_SITE",
  DELIVERY = "DELIVERY",
  DELIVERED = "DELIVERED",
}

export interface Transportation {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  pdfPath?: string;
  pdfUrl?: string;
  trackingNumber?: string;
  trackingUrl?: string;
  status?: TransportationStatusEnum;

  carrierId?: number;

  carrier?: Carrier;
}
