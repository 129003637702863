import { CustomButton } from "~/components/form/CustomButton";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { SquareImage } from "~/components/UI/SquareImage";
import SVGContainer from "~/components/UI/SVGContainer";

import { UploadMediaModalType } from "..";
import styles from "./index.module.scss";

interface Props {
  modalState: UploadMediaModalType;
  submitHandler: () => void;
}

export const ChatPreviewModal = ({ modalState, submitHandler }: Props) => {
  const fileSize = modalState.file?.size
    ? Math.round(modalState.file?.size / 1024)
    : 0;
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.header}`}>
        <h2>Confirm Upload</h2>
      </div>
      <div className={`${styles.preview}`}>
        <div className={`${styles.previewSection}`}>
          <SquareImage
            url={
              modalState.file && modalState.type === "picture"
                ? URL.createObjectURL(modalState.file)
                : "/assets/file-thumbnail.png"
            }
            borderRadius="1rem"
          />
        </div>
        <div className={`${styles.detailsSection}`}>
          <h3>{modalState.file?.name}</h3>
          <h4>{fileSize}kb</h4>
        </div>
      </div>
      <div className={`${styles.errorMessage}`}>{modalState.error}</div>
      <div className={`${styles.buttonContainer}`}>
        <CustomButton
          backgroundColor="#f8f8f8"
          width="fit-content"
          borderRadius="1rem"
          padding="0 0.8rem"
          onClick={submitHandler}
          disabled={modalState.isLoading}
        >
          {modalState.isLoading ? (
            <SimpleLoader size="size2" fill="black" />
          ) : (
            <div className={`${styles.button}`}>
              <p>Send</p>
              <SVGContainer
                height="20px"
                width="20px"
                imagePath="/assets/message-send-button.svg"
              />
            </div>
          )}
        </CustomButton>
      </div>
    </div>
  );
};
