import { NavLink, useLocation } from "react-router-dom";

import styles from "./index.module.scss";

interface Props {
  label: string;
  to: string;
  height?: string;
  width?: string;
  padding?: string;
  borderColor?: string;
  textColor?: string;
  backgroundColor?: string;
  activeTextColor?: string;
  activeBackgroundColor?: string;
  borderRadius?: string;
  margin?: string;
  preventClickOnActive?: boolean;
  count?: number;
}

const CustomNavButton = ({
  label,
  to,
  height,
  width,
  padding,
  borderColor,
  textColor,
  backgroundColor,
  activeTextColor,
  activeBackgroundColor,
  borderRadius,
  margin,
  preventClickOnActive,
  count,
}: Props) => {
  const pathName = useLocation().pathname;
  const isActive = pathName.includes(to);

  const clickHandler = (event: React.FormEvent) => {
    if (preventClickOnActive && isActive) {
      event.preventDefault();
    }
  };

  const countLabel = `${label} ${count ? `(${count})` : ""}`;
  return (
    <NavLink
      to={to}
      className={`${styles.navLink}`}
      onClick={clickHandler}
      style={{
        height: height,
        padding: padding,
        width: width,
        border: borderColor ? `1px solid ${borderColor}` : "",
        borderRadius: borderRadius,
        backgroundColor:
          (isActive ? activeBackgroundColor : backgroundColor) || "inherit",
        color: (isActive ? activeTextColor : textColor) || "black",
        textDecoration: "none",
        margin: margin || 0,
      }}
    >
      {countLabel}
    </NavLink>
  );
};

export default CustomNavButton;
