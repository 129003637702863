import "./index.scss";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CalendarMonthOutlined from "@mui/icons-material/CalendarMonthOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CalendarPickerView, LocalizationProvider } from "@mui/lab";
import AdapterLuxon from "@mui/lab/AdapterLuxon";
import MUIDatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { DateTime } from "luxon";

import styles from "./index.module.scss";

interface Props {
  value: DateTime | null;
  onChange: (date: DateTime | null) => void;
  icon: "keyboardArrowDown" | "CalendarMonth" | "CalendarMonthOutlined";
  label?: string;
  fontSize?: string;
  backgroundColor?: string;
  noBorder?: boolean;
  borderRadius?: string;
  disabled?: boolean;
  required?: boolean;
  changeIndicator?: boolean | string | number;
  invalid?: boolean;
  views?: CalendarPickerView[];
}
export default function DatePicker({
  value,
  onChange,
  icon,
  label,
  fontSize,
  backgroundColor,
  borderRadius,
  noBorder,
  required,
  disabled,
  invalid,
  views,
}: Props) {
  return (
    <div className={`${styles.container}`} style={{ fontSize: fontSize }}>
      {label && (
        <div className={`${styles.label}`}>
          <label>{label}</label>
          {required && <span>*</span>}
        </div>
      )}
      <FormControl sx={{ width: "100%" }}>
        <LocalizationProvider dateAdapter={AdapterLuxon} locale={"fr"}>
          <MUIDatePicker
            // inputFormat="DD-MM-YYYY"
            // label="jj/mm/aaaa"
            value={value?.isValid ? value : null}
            onChange={onChange}
            loading={false}
            disabled={disabled}
            views={views}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                {...params}
                sx={{
                  width: "100%",
                  backgroundColor: backgroundColor || "white",
                  borderRadius: borderRadius,
                  border: invalid
                    ? "1px solid #870000"
                    : noBorder
                    ? ""
                    : "1px solid #CCCCCC",
                }}
                size="small"
                autoComplete="off"
              />
            )}
            components={{
              OpenPickerIcon:
                icon === "keyboardArrowDown"
                  ? KeyboardArrowDownIcon
                  : icon === "CalendarMonth"
                  ? CalendarMonthIcon
                  : CalendarMonthOutlined,
            }}
          />
        </LocalizationProvider>
      </FormControl>
    </div>
  );
}
