import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { OrderBy, OrderByEnum } from "~/types/common/filter.types";
import { Review } from "~/types/data/Review.types";

import type { RootState } from "../store";

export interface ReviewsFilterArgs {
  orderBy: OrderBy<Review>;
  cursor?: number;
  take?: number;
  rating?: number;
  searchBox?: string;
}

type FilterArgs = "orderBy" | "cursor" | "rating" | "searchBox" | "clearAll";

export const reviewFilterArgsInitialState: ReviewsFilterArgs = {
  orderBy: { createdAt: OrderByEnum.DESC },
  take: 20,
};

export const reviewFilterSlice = createSlice({
  name: "reviewFilter",
  initialState: reviewFilterArgsInitialState,
  reducers: {
    onFilterArgsChange(
      state,
      action: PayloadAction<{
        filterArg: FilterArgs;
        orderBy?: OrderBy<Review>;
        cursor?: number;
        rating?: number;
        searchBox?: string;
      }>
    ) {
      const { filterArg, cursor, orderBy, rating, searchBox } = action.payload;

      if (filterArg === "cursor" && cursor) {
        state.cursor = cursor;
      } else if (filterArg === "orderBy" && orderBy) {
        state.orderBy = orderBy;
      } else if (filterArg === "rating") {
        state.rating = rating;
      } else if (filterArg === "searchBox") {
        state.searchBox = searchBox;
      } else if (filterArg === "clearAll") {
        return { ...reviewFilterArgsInitialState };
      }
    },
  },
});

export const { onFilterArgsChange } = reviewFilterSlice.actions;

export const selectReviewsFilterArgs = (state: RootState) => {
  return state.reviewsFilter;
};
export default reviewFilterSlice.reducer;
