import { ShopTypes } from "~/types/data/ForeignShop.types";

import styles from "./index.module.scss";

interface Props {
  path: string;
  selected: boolean;
  disabled: boolean;
  shopType: ShopTypes;
  onClick: (mode: ShopTypes) => void;
}

export const ShopCard = ({
  path,
  selected,
  shopType,
  onClick,
  disabled,
}: Props) => {
  const onClickHandler = () => {
    if (!disabled) onClick(shopType);
  };
  return (
    <div
      className={`${styles.card} ${selected && styles.selectedCard} ${
        disabled && styles.disabledCard
      }`}
      onClick={onClickHandler}
    >
      <img
        src={`${path}`}
        className={`${styles.image} ${disabled && styles.disabledImage} `}
      />
    </div>
  );
};
