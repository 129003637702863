import { useState } from "react";

import CustomModal from "~/components/UI/CustomModal";
import { FullMediaPreview } from "~/components/UI/FullMediaPreview";
import { SquareImage } from "~/components/UI/SquareImage";
import SVGContainer from "~/components/UI/SVGContainer";

import styles from "./index.module.scss";

type Props = {
  id: string;
  label?: string;
  imageUrl?: string;
  onUpload?: (imageFile: File | undefined) => void;
  onRemove?: () => void;
  required?: boolean;
  disabled?: boolean;
  height?: string;
  onCategoryUpload?: (file: File) => void;
};
export const ImageEditorDisplay = ({
  imageUrl,
  id,
  label,
  onUpload,
  onRemove,
  required = false,
  disabled = false,
  height,
  onCategoryUpload,
}: Props) => {
  const [showModal, setShowModal] = useState(false);

  const filePickedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length < 1) return;

    if (onUpload) onUpload(files[0]);
    else onCategoryUpload?.(files[0]);
  };

  const showModalHandler = () => {
    setShowModal(true);
  };

  const hideModalHandler = () => {
    setShowModal(false);
  };

  return (
    <>
      <CustomModal onCancel={hideModalHandler} show={showModal}>
        <FullMediaPreview mediaType={"image"} url={imageUrl ?? ""} />
      </CustomModal>
      <div
        className={styles.inputContainer}
        style={{
          height,
        }}
      >
        {label && (
          <label className={`${styles.label}`}>
            {label}
            {required && <span>*</span>}
          </label>
        )}
        <div className={styles.squareContainer}>
          {!!imageUrl ? (
            <div className={styles.imageContainer}>
              {!disabled && (
                <div className={styles.closeButton}>
                  <SVGContainer
                    imagePath="/assets/circular-close-button.svg"
                    height="1rem"
                    width="1rem"
                    onClick={onRemove}
                  />
                </div>
              )}
              {!disabled && (
                <label htmlFor={id} className={styles.editButton}>
                  <SVGContainer
                    imagePath="/assets/circular-edit-button.svg"
                    height="1rem"
                    width="1rem"
                  />
                </label>
              )}
              <div onClick={showModalHandler} className={styles.image}>
                <SquareImage url={imageUrl} borderRadius="0.5rem" />
              </div>
            </div>
          ) : (
            <label htmlFor={id} className={styles.uploadButtonContainer}>
              <div
                className={`${styles.uploadButton} ${
                  !disabled && styles.clickable
                }`}
              >
                <SVGContainer
                  height="12px"
                  width="12px"
                  imagePath="/assets/black-circular-add-button.svg"
                />
              </div>
            </label>
          )}
        </div>
        <input
          type="file"
          id={id}
          onChange={filePickedHandler}
          disabled={disabled}
          onClick={(e) => {
            if (!disabled) e.currentTarget.value = "";
          }}
          hidden
          accept={["image/jpeg", "image/jpg", "image/png"].join(",")}
        />
      </div>
    </>
  );
};
