import { DeliveryAddress } from "~/types/data/Order.types";

import styles from "./index.module.scss";

interface Props {
  shippingAddress?: DeliveryAddress;
  paymentMethod?: string;
}

export const BillingAddressCard = ({
  shippingAddress,
  paymentMethod,
}: Props) => {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.titleContainer}`}>
        <p>Facturation</p>
      </div>
      <div className={`${styles.sectionContainer}`}>
        <p className={`${styles.sectionTitle}`}>Adresse de facturation :</p>
        {shippingAddress?.name && (
          <p className={`${styles.sectionData}`}>{shippingAddress?.name}</p>
        )}
        <p className={`${styles.sectionData}`}>{shippingAddress?.address1}</p>
        {!!shippingAddress?.address2 && (
          <p className={`${styles.sectionData}`}>{shippingAddress?.address2}</p>
        )}
        <p className={`${styles.sectionData}`}>
          {shippingAddress?.city}, {shippingAddress?.country}
        </p>
        {shippingAddress?.zipCode && (
          <p className={`${styles.sectionData}`}>{shippingAddress?.zipCode}</p>
        )}
      </div>
      <div className={`${styles.sectionContainer}`}>
        <p className={`${styles.sectionTitle}`}>Mode de paiement :</p>
        <p className={`${styles.sectionData}`}>{paymentMethod ?? "N/A"}</p>
      </div>
    </div>
  );
};
