import { CustomButton } from "~/components/form/CustomButton";
import CustomModal from "~/components/UI/CustomModal";
import { customRedButtonParams } from "~/constants/style";
import { colors } from "~/constants/styles";

import styles from "./index.module.scss";
type Props = {
  onExit: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
  headerText: string;
  messageText: string;
  show: boolean;
  errorText?: string;
};
export const ConfirmModal = ({
  headerText,
  messageText,
  onExit,
  onConfirm,
  show,
  isLoading,
  errorText,
}: Props) => {
  return (
    <CustomModal onCancel={onExit} show={show}>
      <div className={styles.modalContainer}>
        <h3>{headerText}</h3>
        <p>{messageText}</p>
        <div className={styles.buttonsContainer}>
          <CustomButton
            type="button"
            {...customRedButtonParams}
            height="3rem"
            width="13rem"
            borderRadius="8px"
            padding="1rem 1rem"
            onClick={onExit}
          >
            {"Annuler"}
          </CustomButton>
          <CustomButton
            type="button"
            backgroundColor={colors.$primary}
            color="white"
            width="13rem"
            borderRadius="8px"
            padding="1rem 1rem"
            onClick={onConfirm}
            disabled={isLoading}
          >
            {"Confirmer"}
          </CustomButton>
        </div>
        <div className={styles.errorClass}>{errorText}</div>
      </div>
    </CustomModal>
  );
};
