import "./styles.scss";

import { ApolloError } from "@apollo/client";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import { useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";

import { getMediaUploadPath } from "~/api/graphql/media/getMediaUploadPath";
import {
  createOrUpdateProduct,
  CreateOrUpdateProductInput,
  CreateOrUpdateProductResponse,
} from "~/api/graphql/product";
import { CustomButton } from "~/components/form/CustomButton";
import { CustomInput } from "~/components/form/CustomInput";
import DatePicker from "~/components/form/DatePicker";
import { InputWithIcon } from "~/components/form/InputWithIcon";
import { MediaUploadButton } from "~/components/form/MediaUploadButton";
import { MultipleSelectInput } from "~/components/form/MultipleSelectInput";
import { RichTextEditor } from "~/components/form/RichTextEditor";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import CustomModal from "~/components/UI/CustomModal";
import { FullMediaPreview } from "~/components/UI/FullMediaPreview";
import { PreviewImage } from "~/components/UI/PreviewImage";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { countryList } from "~/constants/countries";
import { colors } from "~/constants/styles";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useUserContext } from "~/context/userContext";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import { AxiosHttpError } from "~/types/common/error.types";
import { Attribute } from "~/types/data/Attribute.types";
import { DeliveryPackage } from "~/types/data/Carrier.types";
import {
  GenderEnum,
  ImageFile,
  Product,
  RentingPeriodEnum,
  SellingTypeEnum,
} from "~/types/data/Product.types";
import { VendorCategory } from "~/types/data/VendorCategory.types";
import { calculateByteSize } from "~/util/functions/calculateSize";
import { generateVideoThumbnail } from "~/util/functions/generateVideoThumbnail";
import { removeDuplicate } from "~/util/functions/removeDuplicate";

import { attributeValuesToOptionsMap } from "../util/attributeValuesToOptionsMap";
import { hasAttribute } from "../util/hasAttribute";
import { imagesToFieldsMap } from "../util/imagesToFieldsMap";
import { mapRentingPeriod } from "../util/mapRentingPeriod.util";
import {
  getRentingPeriod,
  sellingTypeValueMap,
} from "../util/sellingTypeValueMap";
import { validateProduct, ValidateProductState } from "../util/validateProduct";
import styles from "./index.module.scss";

const ResponsiveGridLayout = WidthProvider(Responsive);

const sellingTypeOptions: OptionType[] = [
  {
    label: "Vente",
    value: SellingTypeEnum.NEW,
  },
  {
    label: "Location",
    value: SellingTypeEnum.RENT,
  },
];

const rentingPeriodOptions: OptionType[] = [
  {
    label: "Par jour",
    value: RentingPeriodEnum.DAILY,
  },
  {
    label: "Par semaine",
    value: RentingPeriodEnum.WEEKLY,
  },
  {
    label: "Par mois",
    value: RentingPeriodEnum.MONTHLY,
  },
];

const genderOptions: OptionType[] = [
  {
    label: "Fille",
    value: GenderEnum.FEMALE,
  },
  {
    label: "Garçon",
    value: GenderEnum.MALE,
  },
  {
    label: "Mixte",
    value: GenderEnum.MIXED,
  },
] as OptionType[];

interface PreviewMediaState {
  show: boolean;
  mediaType: "image" | "video";
  url: string;
}

interface Props {
  product: Product;
  setProductFormState: React.Dispatch<
    React.SetStateAction<{
      variants: Product[];
      index: number;
    }>
  >;
  variantIndex: number;
  vendorCategories: VendorCategory[];
  attributes: Attribute[];
  showModalHandler: () => void;
  classLogistics: DeliveryPackage[];
  isAdmin?: boolean;
}

export const ProductForm = ({
  product,
  setProductFormState,
  variantIndex,
  vendorCategories,
  showModalHandler,
  attributes,
  classLogistics,
  isAdmin,
}: Props) => {
  // states
  const [showImagePreviewModa, setShowImagePreviewModal] =
    useState<PreviewMediaState>({ show: false, url: "", mediaType: "image" });
  const { companyAccount } = useCompanyAccountContext();
  const hasSecondHand = !!companyAccount.secondHandCompany;
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorState, setErrorState] = useState<ValidateProductState>({});
  const [isLoading, setIsLoading] = useState(false);

  const [dragging, setDragging] = useState(false);

  // hooks
  const navigate = useNavigate();
  const { userState } = useUserContext();

  const productImages = product.images || [];

  const productRentingPeriod = getRentingPeriod(product);

  const categoryOptions: OptionType[] = vendorCategories.map(({ name, id }) => {
    return {
      label: name,
      value: id.toString(),
    } as OptionType;
  });

  const countryOptions: OptionType[] = countryList.map(({ name, alpha2 }) => {
    return { label: name, value: alpha2.toUpperCase() };
  });

  const colorOptions = attributeValuesToOptionsMap(attributes, "color");

  const brandOptions = attributeValuesToOptionsMap(attributes, "brand");

  const sizeOptions = attributeValuesToOptionsMap(attributes, "size");

  const liningMaterialOptions = attributeValuesToOptionsMap(
    attributes,
    "liningMaterial"
  );

  const mainMaterialOptions = attributeValuesToOptionsMap(
    attributes,
    "mainMaterial"
  );

  const stateTypeOptions = attributeValuesToOptionsMap(attributes, "stateType");

  const uniqueclassLogistics = removeDuplicate(
    ({ code }) => code,
    classLogistics
  );

  const logisticClassOptions: OptionType[] = uniqueclassLogistics.map(
    ({ code }) => {
      return { label: code, value: code };
    }
  );

  const ecoResponsibleLabelOptions = attributeValuesToOptionsMap(
    attributes,
    "ecoResponsibleLabel"
  );

  const hasMainMaterialAttribute = hasAttribute(
    product.categoryId || 0,
    vendorCategories || [],
    "mainMaterial"
  );

  const hasLiningMaterialAttribute = hasAttribute(
    product.categoryId || 0,
    vendorCategories || [],
    "liningMaterial"
  );

  const hasColorAttribute = hasAttribute(
    product.categoryId || 0,
    vendorCategories || [],
    "color"
  );

  const hasSizeAttribute = hasAttribute(
    product.categoryId || 0,
    vendorCategories || [],
    "size"
  );

  const hasComposition = hasAttribute(
    product.categoryId || 0,
    vendorCategories || [],
    "composition"
  );

  const isC2C = !product.storeName;

  const hasNewSellingType = product.sellingType?.find((sellingType) => {
    return sellingType === SellingTypeEnum.NEW;
  });

  const hasRentSellingType = product.sellingType?.find((sellingType) => {
    return sellingType === SellingTypeEnum.RENT;
  });

  const addImageHandler = (value: File | undefined) => {
    setProductFormState((prev) => {
      const modifiedProductForm = prev.variants.map((product, arrayIndex) => {
        if (arrayIndex === variantIndex) {
          const images = product.images || [];
          if (images.length === 7) return { ...product };
          const lastKey =
            images.length === 0 ? 0 : +images[images.length - 1].i + 1;

          const newImage: ImageFile = {
            file: value,
            url: value ? URL.createObjectURL(value) : "",
            key: "",
            i: `${lastKey}`,
            x: lastKey,
            y: 0,
            w: 1,
            h: 1,
          };
          return { ...product, images: [...images, newImage] };
        } else {
          return { ...product };
        }
      });

      return { ...prev, variants: modifiedProductForm };
    });
  };

  const updateImagesHandler = (layout: Layout[]) => {
    setProductFormState((prev) => {
      const modifiedProductForm = prev.variants.map((product, arrayIndex) => {
        if (arrayIndex === variantIndex) {
          return {
            ...product,
            images: productImages.map((imageFile, index) => {
              return { ...imageFile, ...layout[index] };
            }),
          };
        } else {
          return { ...product };
        }
      });

      return { ...prev, variants: modifiedProductForm };
    });
  };

  const deleteImageHandler = (key: string) => {
    setProductFormState((prev) => {
      const modifiedProductForm = prev.variants.map((product, arrayIndex) => {
        if (arrayIndex === variantIndex) {
          const newImages = productImages.filter((productImage) => {
            return productImage.i !== key;
          });
          return { ...product, images: newImages };
        } else {
          return { ...product };
        }
      });

      return { ...prev, variants: modifiedProductForm };
    });
  };

  const showPreviewModal = ({
    mediaType,
    url,
  }: {
    mediaType: "image" | "video";
    url: string;
  }) => {
    setShowImagePreviewModal({ mediaType, url, show: true });
  };

  const hidePreviewModal = () => {
    setShowImagePreviewModal({ mediaType: "image", url: "", show: false });
  };

  const createImageElement = (image: ImageFile) => {
    const i = image.i;

    return (
      <div
        key={i}
        data-grid={image}
        onClick={() => {
          if (!dragging)
            showPreviewModal({
              mediaType: "image",
              url: image.url,
            });
        }}
        className={styles.imagePreview}
      >
        <PreviewImage
          onDelete={(e) => {
            e.stopPropagation();
            deleteImageHandler(i);
          }}
          url={image.url}
          borderRadius="5px"
        />
      </div>
    );
  };

  const inputChangeHandler = <T extends keyof Product>(
    inputName: T,
    changes: Product[T]
  ) => {
    setProductFormState((state) => {
      const modifiedProductForm = state.variants.map((product, arrayIndex) => {
        if (arrayIndex === variantIndex) {
          return {
            ...product,
            [inputName]: changes === "" ? undefined : changes,
          };
        } else {
          return { ...product };
        }
      });

      return { ...state, variants: modifiedProductForm };
    });
  };

  const sellingTypeChangeHandler = (updatedSellingTypes: SellingTypeEnum[]) => {
    inputChangeHandler("sellingType", updatedSellingTypes);

    if (!updatedSellingTypes.includes(SellingTypeEnum.NEW)) {
      inputChangeHandler("priceTTC", undefined);
      inputChangeHandler("discountedPriceTTC", undefined);
      inputChangeHandler("startingDiscountDate", undefined);
      inputChangeHandler("endingDiscountDate", undefined);
    }

    if (!updatedSellingTypes.includes(SellingTypeEnum.RENT)) {
      inputChangeHandler("dailyRentPrice", undefined);
      inputChangeHandler("weeklyRentPrice", undefined);
      inputChangeHandler("monthlyRentPrice", undefined);
      inputChangeHandler("rentingPeriod", undefined);
      inputChangeHandler("depositPrice", undefined);
      inputChangeHandler("rentSecondHandPrice", undefined);
    }
  };

  const rentingPeriodChangeHandler = (
    updatedRendingPeriod: RentingPeriodEnum[]
  ) => {
    inputChangeHandler("rentingPeriod", updatedRendingPeriod);
    if (!updatedRendingPeriod.includes(RentingPeriodEnum.DAILY)) {
      inputChangeHandler("dailyRentPrice", undefined);
    }

    if (!updatedRendingPeriod.includes(RentingPeriodEnum.WEEKLY)) {
      inputChangeHandler("weeklyRentPrice", undefined);
    }

    if (!updatedRendingPeriod.includes(RentingPeriodEnum.MONTHLY)) {
      inputChangeHandler("monthlyRentPrice", undefined);
    }
  };

  // react-query
  const createOrUpdateProductHandler = (
    responseData: CreateOrUpdateProductResponse
  ) => {
    const { createOrUpdateProduct } = responseData;

    setProductFormState((prev) => {
      const modifiedProducts = prev.variants.map((prevProduct, arrayIndex) => {
        if (arrayIndex === variantIndex) {
          return {
            ...createOrUpdateProduct,
            images: prevProduct.images,
            videoThumbnailUrl: prevProduct.videoThumbnailUrl,
            registredSku: createOrUpdateProduct.sku,
          };
        } else {
          return { ...prevProduct };
        }
      });

      return { ...prev, variants: modifiedProducts };
    });

    if (product.id) {
      setSuccessMessage("Product updated successfully");
    } else {
      setSuccessMessage("Product created successfully");
    }
    setIsLoading(false);
  };

  const createOrUpdateProductErrorHandler = (error: ApolloError) => {
    const { message } = error;
    setErrorMessage(`Error: ${message}`);
    setIsLoading(false);
  };

  const { trigger: createOrUpdateProductTrigger } = useMutationWithCallbacks<
    CreateOrUpdateProductResponse,
    CreateOrUpdateProductInput
  >(
    createOrUpdateProduct,
    createOrUpdateProductHandler,
    createOrUpdateProductErrorHandler
  );

  const cancelClickHandler = () => {
    navigate(-1);
  };

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setSuccessMessage("");
    setErrorMessage("");
    setErrorState({});

    if (!userState?.connected) {
      setErrorMessage("User is not connected");
      setIsLoading(false);
      return;
    }

    const {
      image1: _image1,
      image2: _image2,
      image3: _image3,
      image4: _image4,
      image5: _image5,
      image6: _image6,
      image7: _image7,
      images,
      videoThumbnailUrl: _videoThumbnailUrl,
      registredSku: _registredSku,
      videoFile,
      status: _status,
      rentingPeriod: _rentingPeriod,
      companyId: _companyId,
      storeName: _storeName,
      sellerName: _sellerName,
      ...input
    } = product;

    // validate fields
    const error = validateProduct(
      {
        ...input,
        images,
        rentingPeriod: productRentingPeriod,
      },
      hasColorAttribute,
      hasSizeAttribute,
      hasMainMaterialAttribute,
      hasLiningMaterialAttribute,
      hasComposition,
      isC2C
    );
    const errorFields = Object.keys(error);
    if (errorFields.length) {
      setErrorState(error);
      setErrorMessage("Invalid form input");
      setIsLoading(false);
      return;
    }

    try {
      // map images
      const mappedImageFields = await imagesToFieldsMap(images || []);

      // upload video
      if (videoFile) {
        const response = await getMediaUploadPath({
          entity: "product",
          field: "video",
          filename: videoFile.name,
          isPublic: true,
        });

        if (response.success) {
          const { url, path } = response.data;
          await fetch(url, { method: "put", body: videoFile });
          input.video = path;
        } else {
          setErrorMessage("Uploading video didn't work");
          setIsLoading(false);
          return;
        }
      }

      await createOrUpdateProductTrigger({
        variables: {
          CreateOrUpdateProductInput: {
            ...input,
            ...mappedImageFields,
            color: input.color,
            size: input.size,
            priceTTC: input.priceTTC,
            discountedPriceTTC: input.discountedPriceTTC,
            video: videoFile ? input.video : input.video ? undefined : null,
            dailyRentPrice:
              hasRentSellingType &&
              productRentingPeriod?.includes(RentingPeriodEnum.DAILY)
                ? input.dailyRentPrice
                : undefined,
            weeklyRentPrice:
              hasRentSellingType &&
              productRentingPeriod?.includes(RentingPeriodEnum.WEEKLY)
                ? input.weeklyRentPrice
                : undefined,
            monthlyRentPrice:
              hasRentSellingType &&
              productRentingPeriod?.includes(RentingPeriodEnum.MONTHLY)
                ? input.monthlyRentPrice
                : undefined,
            depositPrice: hasRentSellingType ? input.depositPrice : undefined,
            rentSecondHandPrice: hasRentSellingType
              ? input.rentSecondHandPrice
              : undefined,
          },
        },
        fetchPolicy: "network-only",
      });
    } catch (e) {
      const error = e as AxiosHttpError;
      setErrorMessage("Error: " + error.message);
      setIsLoading(false);
      return;
    }
  };

  useEffect(() => {
    setErrorMessage("");
    setSuccessMessage("");
  }, [variantIndex]);

  const setVideoThumbnail = async (file: File | undefined) => {
    const thumbnailUrl = await generateVideoThumbnail(file);
    inputChangeHandler("videoThumbnailUrl", thumbnailUrl as string);
  };

  const handleVideoFile = (file: File | undefined) => {
    setErrorState(({ video: _, ...data }) => data);
    const size = file?.size;
    const mbSize = calculateByteSize("mb", size);
    if (Math.floor(mbSize) > 8) {
      setErrorState((prev) => ({ ...prev, video: true }));
      return;
    }
    const objectURL = file ? URL.createObjectURL(file) : "";
    inputChangeHandler("videoFile", file);
    inputChangeHandler("video", objectURL);
    setVideoThumbnail(file);
  };

  return (
    <>
      <CustomModal onCancel={hidePreviewModal} show={showImagePreviewModa.show}>
        <FullMediaPreview
          mediaType={showImagePreviewModa.mediaType}
          url={showImagePreviewModa.url}
        />
      </CustomModal>
      <form
        className={`${styles.formContainer}`}
        onSubmit={(e) => {
          submitHandler(e);
        }}
      >
        <h3>Informations du produit :</h3>
        <div className={`${styles.singleInput}`}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("title", value.toString());
            }}
            value={product.title}
            label="Titre"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            required={true}
            invalid={errorState.title}
          />
        </div>
        <div className={`${styles.singleInput}`}>
          <SelectInput
            label="Catégories"
            fontSize="14px"
            backgroundColor={colors.$inputGray}
            noBorder={true}
            options={categoryOptions}
            onChange={(option: SingleValue<OptionType>) => {
              inputChangeHandler(
                "categoryId",
                option?.value ? +option.value : undefined
              );
            }}
            value={product.categoryId?.toString() || ""}
            required={true}
            invalid={errorState.quantity}
          />
        </div>
        {hasSecondHand && (
          <div className={`${styles.singleInput}`}>
            <CustomInput
              onChange={(value: string | number) => {
                inputChangeHandler("collectionName", value.toString());
              }}
              value={product.collectionName}
              label="Nom de la collection"
              borderRadius="5px"
              noBorder={true}
              backgroundColor={colors.$inputGray}
              textColor={colors.$primaryDark}
              fontSize="14px"
              step={0.01}
            />
          </div>
        )}
        <div className={`${styles.mediaSection}`}>
          <div className={`${styles.label}`}>
            <label>Photographies*</label>
          </div>

          <div className={`${styles.mediaUploadRow}`}>
            <div className={`${styles.mediaUploadButton}`}>
              <MediaUploadButton
                onChange={addImageHandler}
                id="images"
                disabled={productImages.length >= 7}
                accept={["image/jpeg", "image/jpg", "image/png"]}
                multiple={true}
                invalid={errorState.images}
              />
            </div>

            <div className={`${styles.imagesGrid}`}>
              <ResponsiveGridLayout
                className={"imagePreview layout"}
                breakpoints={{ lg: 1200 }}
                cols={{ lg: 7 }}
                isBounded={true}
                isResizable={false}
                margin={[2, 2]}
                rowHeight={65}
                containerPadding={[0, 0]}
                compactType="horizontal"
                onLayoutChange={updateImagesHandler}
                layouts={{ lg: productImages }}
                onDragStop={() => {
                  setDragging(true);
                  setTimeout(() => {
                    setDragging(false);
                  }, 100);
                }}
              >
                {productImages.map((image) => {
                  return createImageElement(image);
                })}
              </ResponsiveGridLayout>
            </div>
          </div>
        </div>
        <div className={`${styles.mediaSection}`}>
          <div className={`${styles.label}`}>
            <label>Video</label>
          </div>

          <div className={`${styles.mediaUploadRow}`}>
            <div className={`${styles.mediaUploadButton}`}>
              <MediaUploadButton
                onChange={handleVideoFile}
                id="video"
                disabled={!!product.video}
                accept={["video/wmv", "video/mpeg", "video/avi", "video/mp4"]}
                invalid={errorState.video}
              />
            </div>
            {product.video && product.videoThumbnailUrl && (
              <div
                className={`${styles.imagePreview}`}
                onClick={() => {
                  showPreviewModal({
                    mediaType: "video",
                    url: product.video || "",
                  });
                }}
              >
                <PreviewImage
                  onDelete={(e) => {
                    e.stopPropagation();
                    handleVideoFile(undefined);
                  }}
                  url={product.videoThumbnailUrl || ""}
                  borderRadius="5px"
                />
              </div>
            )}
          </div>
          {errorState.video && (
            <div className={styles.messageContainer}>
              <p className={styles.errorMessage}>
                Videos cannot be larger than 8mb.
              </p>
            </div>
          )}
        </div>
        <div className={`${styles.singleInput}`}>
          <RichTextEditor
            onChange={(value: string) => {
              inputChangeHandler("description", value.toString());
            }}
            htmlValue={product.description || ""}
            label="Description"
            fontSize="14px"
            changeIndex={variantIndex}
            required={true}
            invalid={errorState.description}
          />
        </div>
        <div className={`${styles.singleInput}`}>
          <RichTextEditor
            onChange={(value: string) => {
              inputChangeHandler("productCareInstructions", value.toString());
            }}
            htmlValue={product.productCareInstructions || ""}
            label="Conseil d'entretien"
            fontSize="14px"
            changeIndex={variantIndex}
          />
        </div>
        <div className={`${styles.doubleInputs}`}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("sku", value.toString());
            }}
            value={product.sku}
            label="SKU"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            required={!isC2C}
            invalid={errorState.sku}
          />
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("ean", value.toString());
            }}
            value={product.ean}
            label="EAN"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            invalid={errorState.ean}
          />
        </div>
        <div className={`${styles.doubleInputs}`}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("vendorParentId", value.toString());
            }}
            value={product.vendorParentId}
            label="Références produit"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
          <div style={{ width: "100%" }} />
        </div>
        <div className={`${styles.singleInput}`}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("composition", value.toString());
            }}
            value={product.composition}
            label="Composition"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            invalid={errorState.composition}
            required={!isC2C && hasComposition}
          />
        </div>
        <div className={`${styles.doubleInputs}`}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("compositionLining", value.toString());
            }}
            value={product.compositionLining}
            label="Composition doublure"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("minimalAge", value as number);
            }}
            value={product.minimalAge}
            label="Âge minimal"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            type="number"
            step={1}
            invalid={errorState.minimalAge}
          />
        </div>
        <div className={`${styles.doubleInputs}`}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("weight", value.toString());
            }}
            value={product.weight}
            label="Poids"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            step={0.01}
          />
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("depth", value.toString());
            }}
            value={product.depth}
            label="Profondeur"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            step={0.01}
          />
        </div>
        <div className={`${styles.doubleInputs}`}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("length", value.toString());
            }}
            value={product.length}
            label="Longueur"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            step={0.01}
          />
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("height", value.toString());
            }}
            value={product.height}
            label="Hauteur"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            step={0.01}
          />
        </div>
        <div className={`${styles.doubleInputs}`}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("volume", value.toString());
            }}
            value={product.volume}
            label="Volume"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            step={0.01}
          />
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("diameter", value.toString());
            }}
            value={product.diameter}
            label="Diamètre"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            step={0.01}
          />
        </div>
        <hr className={`${styles.line}`} />
        <h3>Information du variant :</h3>
        <div className={`${styles.doubleInputs}`}>
          <SelectInput
            label="Genre"
            fontSize="14px"
            backgroundColor={colors.$inputGray}
            noBorder={true}
            options={genderOptions}
            onChange={(option: SingleValue<OptionType>) => {
              inputChangeHandler("gender", option?.value as GenderEnum);
            }}
            value={product.gender || ""}
          />
          <SelectInput
            label="Marque"
            fontSize="14px"
            backgroundColor={colors.$inputGray}
            noBorder={true}
            options={brandOptions}
            onChange={(option: SingleValue<OptionType>) => {
              inputChangeHandler("brand", option?.value || "");
            }}
            value={product.brand || ""}
            required={true}
            invalid={errorState.brand}
          />
        </div>
        <div className={`${styles.doubleInputs}`}>
          <SelectInput
            label="Couleur"
            fontSize="14px"
            backgroundColor={colors.$inputGray}
            noBorder={true}
            options={colorOptions}
            onChange={(option: SingleValue<OptionType>) => {
              inputChangeHandler("color", option?.value || "");
            }}
            value={product.color || ""}
            required={hasColorAttribute}
            invalid={errorState.color}
          />
          <SelectInput
            label="Taille"
            fontSize="14px"
            backgroundColor={colors.$inputGray}
            noBorder={true}
            options={sizeOptions}
            onChange={(option: SingleValue<OptionType>) => {
              inputChangeHandler("size", option?.value || "");
            }}
            value={product.size || ""}
            required={hasSizeAttribute}
            invalid={errorState.size}
          />
        </div>
        {(hasMainMaterialAttribute || hasLiningMaterialAttribute) && (
          <div className={`${styles.doubleInputs}`}>
            {hasMainMaterialAttribute && (
              <SelectInput
                label="Matière principale"
                fontSize="14px"
                backgroundColor={colors.$inputGray}
                noBorder={true}
                options={mainMaterialOptions}
                onChange={(option: SingleValue<OptionType>) => {
                  inputChangeHandler("mainMaterial", option?.value || "");
                }}
                value={product.mainMaterial || ""}
                required={true}
                invalid={errorState.mainMaterial}
              />
            )}
            {hasLiningMaterialAttribute && (
              <SelectInput
                label="Matière principale doublure"
                fontSize="14px"
                backgroundColor={colors.$inputGray}
                noBorder={true}
                options={liningMaterialOptions}
                onChange={(option: SingleValue<OptionType>) => {
                  inputChangeHandler("liningMaterial", option?.value || "");
                }}
                value={product.liningMaterial || ""}
                required={true}
                invalid={errorState.liningMaterial}
              />
            )}
            {!hasMainMaterialAttribute && <div style={{ width: "100%" }} />}
            {!hasLiningMaterialAttribute && <div style={{ width: "100%" }} />}
          </div>
        )}
        <div className={`${styles.doubleInputs}`}>
          <SelectInput
            label="Etat du produit"
            fontSize="14px"
            backgroundColor={colors.$inputGray}
            noBorder={true}
            options={stateTypeOptions}
            onChange={(option: SingleValue<OptionType>) => {
              inputChangeHandler("stateType", option?.value || "");
            }}
            value={product.stateType || ""}
            required={true}
            invalid={errorState.stateType}
          />
          {!isAdmin && (
            <SelectInput
              label="Classe logistique"
              fontSize="14px"
              backgroundColor={colors.$inputGray}
              noBorder={true}
              options={logisticClassOptions}
              onChange={(option: SingleValue<OptionType>) => {
                inputChangeHandler("logisticClass", option?.value || "");
              }}
              value={product.logisticClass || ""}
              required={true}
              invalid={errorState.logisticClass}
            />
          )}
        </div>

        <div className={`${styles.doubleInputs}`}>
          <SelectInput
            label="Label eco-responsable"
            fontSize="14px"
            backgroundColor={colors.$inputGray}
            noBorder={true}
            options={ecoResponsibleLabelOptions}
            onChange={(option: SingleValue<OptionType>) => {
              inputChangeHandler("ecoResponsibleLabel", option?.value || "");
            }}
            value={product.ecoResponsibleLabel || ""}
          />
          <SelectInput
            label="Pays de fabrication"
            fontSize="14px"
            backgroundColor={colors.$inputGray}
            noBorder={true}
            options={countryOptions}
            onChange={(option: SingleValue<OptionType>) => {
              inputChangeHandler("madeIn", option?.value || "");
            }}
            value={product.madeIn || ""}
          />
        </div>
        <div className={`${styles.doubleInputs}`}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("quantity", value as number);
            }}
            value={product.quantity}
            label="Quantité en stock"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            type="number"
            step={1}
            required={true}
            invalid={errorState.quantity}
          />
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("sendAlertOnQuantity", value as number);
            }}
            value={product.sendAlertOnQuantity}
            label="Alerte quantité"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            type="number"
            step={1}
            invalid={errorState.sendAlertOnQuantity}
          />
        </div>
        <hr className={`${styles.line}`} />
        <div className={`${styles.doubleInputs}`}>
          <MultipleSelectInput
            label="Type de vente"
            fontSize="14px"
            backgroundColor={colors.$inputGray}
            noBorder={true}
            options={sellingTypeOptions}
            onChange={(options: OptionType[]) => {
              sellingTypeChangeHandler(
                options.map((option) => {
                  return option.value as SellingTypeEnum;
                })
              );
            }}
            value={
              product.sellingType?.map((sellingType) => {
                return {
                  label: sellingTypeValueMap(sellingType),
                  value: sellingType,
                };
              }) || []
            }
            required={true}
            invalid={errorState.sellingType}
          />
        </div>
        {hasNewSellingType && (
          <>
            <h3>Offre de vente :</h3>
            <div className={`${styles.doubleInputs}`}>
              <InputWithIcon
                onChange={(value: string | number) => {
                  inputChangeHandler("priceTTC", value as number);
                }}
                value={product.priceTTC}
                label="Prix de vente (TTC)"
                borderRadius="5px"
                noBorder={true}
                backgroundColor={colors.$inputGray}
                textColor={colors.$primaryDark}
                fontSize="14px"
                iconPath="/assets/euro-icon.svg"
                type="number"
                step={0.01}
                required={true}
                invalid={errorState.priceTTC}
              />
              <InputWithIcon
                onChange={(value: string | number) => {
                  inputChangeHandler("discountedPriceTTC", value as number);
                }}
                value={product.discountedPriceTTC}
                label="Prix remisé"
                borderRadius="5px"
                noBorder={true}
                backgroundColor={colors.$inputGray}
                textColor={colors.$primaryDark}
                fontSize="14px"
                iconPath="/assets/euro-icon.svg"
                type="number"
                step={0.01}
                invalid={errorState.discountedPriceTTC}
              />
            </div>
            <div className={`${styles.doubleInputs}`}>
              <DatePicker
                label="Remisé à partir de"
                fontSize="14px"
                icon="CalendarMonthOutlined"
                onChange={(value: DateTime | null) => {
                  if (value?.isValid !== false) {
                    inputChangeHandler(
                      "startingDiscountDate",
                      value?.toISO() || ""
                    );
                  } else {
                    inputChangeHandler("startingDiscountDate", "invalid");
                  }
                }}
                value={DateTime.fromISO(product.startingDiscountDate || "")}
                backgroundColor={colors.$inputGray}
                noBorder={true}
                borderRadius="5px"
                invalid={errorState.startingDiscountDate}
              />

              <DatePicker
                label="Remisé jusqu'au"
                fontSize="14px"
                icon="CalendarMonthOutlined"
                onChange={(value: DateTime | null) => {
                  if (value?.isValid !== false) {
                    inputChangeHandler(
                      "endingDiscountDate",
                      value?.toISO() || ""
                    );
                  } else {
                    inputChangeHandler("endingDiscountDate", "invalid");
                  }
                }}
                value={DateTime.fromISO(product.endingDiscountDate || "")}
                backgroundColor={colors.$inputGray}
                noBorder={true}
                borderRadius="5px"
                invalid={errorState.startingDiscountDate}
              />
            </div>
          </>
        )}
        {hasRentSellingType && (
          <>
            <h3>Offre de location :</h3>
            <div className={`${styles.doubleInputs}`}>
              <MultipleSelectInput
                label="Fréquence"
                fontSize="14px"
                backgroundColor={colors.$inputGray}
                noBorder={true}
                options={rentingPeriodOptions}
                onChange={(options: OptionType[]) => {
                  rentingPeriodChangeHandler(
                    options.map((option) => {
                      return option.value as RentingPeriodEnum;
                    })
                  );
                }}
                value={
                  productRentingPeriod?.map((rentingPeriod) => {
                    return {
                      label: mapRentingPeriod(rentingPeriod),
                      value: rentingPeriod,
                    };
                  }) || []
                }
                required={true}
                invalid={errorState.rentingPeriod}
              />
            </div>
            <div className={`${styles.doubleInputs}`}>
              <InputWithIcon
                onChange={(value: string | number) => {
                  inputChangeHandler("depositPrice", value as number);
                }}
                value={product.depositPrice}
                label="Montant du dépôt"
                borderRadius="5px"
                noBorder={true}
                backgroundColor={colors.$inputGray}
                textColor={colors.$primaryDark}
                fontSize="14px"
                iconPath="/assets/euro-icon.svg"
                type="number"
                step={0.01}
                required={true}
                invalid={errorState.depositPrice}
              />
            </div>
            <div className={`${styles.doubleInputs}`}>
              <InputWithIcon
                onChange={(value: string | number) => {
                  inputChangeHandler("rentSecondHandPrice", value as number);
                }}
                value={product.rentSecondHandPrice}
                label="Prix d'achat"
                borderRadius="5px"
                noBorder={true}
                backgroundColor={colors.$inputGray}
                textColor={colors.$primaryDark}
                fontSize="14px"
                iconPath="/assets/euro-icon.svg"
                type="number"
                step={0.01}
                required={true}
                invalid={errorState.rentSecondHandPrice}
              />
            </div>
            <div className={`${styles.doubleInputs}`}>
              {productRentingPeriod?.includes(RentingPeriodEnum.DAILY) && (
                <InputWithIcon
                  onChange={(value: string | number) => {
                    inputChangeHandler("dailyRentPrice", value as number);
                  }}
                  value={product.dailyRentPrice}
                  label="Prix de location (Jour)"
                  borderRadius="5px"
                  noBorder={true}
                  backgroundColor={colors.$inputGray}
                  textColor={colors.$primaryDark}
                  fontSize="14px"
                  iconPath="/assets/euro-icon.svg"
                  type="number"
                  step={0.01}
                  required={true}
                  invalid={errorState.dailyRentPrice}
                />
              )}
              {productRentingPeriod?.includes(RentingPeriodEnum.WEEKLY) && (
                <InputWithIcon
                  onChange={(value: string | number) => {
                    inputChangeHandler("weeklyRentPrice", value as number);
                  }}
                  value={product.weeklyRentPrice}
                  label="Prix de location (Semaine)"
                  borderRadius="5px"
                  noBorder={true}
                  backgroundColor={colors.$inputGray}
                  textColor={colors.$primaryDark}
                  fontSize="14px"
                  iconPath="/assets/euro-icon.svg"
                  type="number"
                  step={0.01}
                  required={true}
                  invalid={errorState.weeklyRentPrice}
                />
              )}
              {productRentingPeriod?.includes(RentingPeriodEnum.MONTHLY) && (
                <InputWithIcon
                  onChange={(value: string | number) => {
                    inputChangeHandler("monthlyRentPrice", value as number);
                  }}
                  value={product.monthlyRentPrice}
                  label="Prix de location (Mois)"
                  borderRadius="5px"
                  noBorder={true}
                  backgroundColor={colors.$inputGray}
                  textColor={colors.$primaryDark}
                  fontSize="14px"
                  iconPath="/assets/euro-icon.svg"
                  type="number"
                  step={0.01}
                  required={true}
                  invalid={errorState.monthlyRentPrice}
                />
              )}
            </div>
          </>
        )}

        <div className={`${styles.doubleInputs}`}>
          <InputWithIcon
            onChange={(value: string | number) => {
              inputChangeHandler("vatRate", value as number);
            }}
            value={product.vatRate}
            label="TVA"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            iconPath="/assets/percentage-icon.svg"
            type="number"
            step={0.01}
            required={!isC2C}
            invalid={errorState.vatRate}
          />
          <InputWithIcon
            onChange={(value: string | number) => {
              inputChangeHandler("ecoTax", value as number);
            }}
            value={product.ecoTax}
            label="Eco-Tax"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            iconPath="/assets/euro-icon.svg"
            type="number"
            step={0.01}
            invalid={errorState.ecoTax}
          />
        </div>
        <div className={styles.messageContainer}>
          {!!errorMessage && (
            <p className={styles.errorMessage}>{errorMessage}</p>
          )}
          {!!successMessage && <p>{successMessage}</p>}
        </div>
        {!!product.id && (
          <div className={`${styles.variantButtonContainer}`}>
            <CustomButton
              backgroundColor={colors.$primary}
              color="white"
              width="fit-content"
              borderRadius="8px"
              padding="1rem 1.5rem"
              onClick={showModalHandler}
              disabled={isLoading}
            >
              Ajouter une variante
            </CustomButton>
          </div>
        )}
        <div className={`${styles.formButtonsContainer}`}>
          <CustomButton
            color={colors.$primary}
            borderColor={colors.$primary}
            width="fit-content"
            borderRadius="8px"
            padding="1rem 1.5rem"
            disabled={isLoading}
            onClick={() => {
              cancelClickHandler();
            }}
          >
            Annuler
          </CustomButton>
          <CustomButton
            backgroundColor={colors.$primary}
            color="white"
            width="fit-content"
            borderRadius="8px"
            padding="1rem 1.5rem"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <SimpleLoader size="size2" /> : "Ajouter"}
          </CustomButton>
        </div>
      </form>
    </>
  );
};
