import { ApolloError } from "@apollo/client";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";

import {
  ADMIN_CREATE_OR_UPDATE_USER,
  AdminCreateOrUpdateUserInput,
  AdminCreateOrUpdateUserResponse,
} from "~/api/graphql/user";
import { CustomButton } from "~/components/form/CustomButton";
import CustomCheckbox from "~/components/form/CustomCheckbox";
import { CustomInput } from "~/components/form/CustomInput";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { useUserContext } from "~/context/userContext";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import { routePaths, routesBuilder } from "~/navigation/routes";
import { AxiosHttpError } from "~/types/common/error.types";
import { Company } from "~/types/data/Company.types";
import {
  PermissionLevelEnum,
  RoleEnum,
  UserFormState,
} from "~/types/data/User.types";
import { formatDate } from "~/util/functions/formatDate";

import { validateUser } from "../util/validateUser";
import styles from "./index.module.scss";

const roleOptions: OptionType[] = [
  {
    label: RoleEnum.ADMIN,
    value: RoleEnum.ADMIN,
  },
  {
    label: RoleEnum.SELLER,
    value: RoleEnum.SELLER,
  },
];

const permissionLevelOptions: OptionType[] = [
  {
    label: PermissionLevelEnum.ADMIN,
    value: PermissionLevelEnum.ADMIN,
  },
  {
    label: PermissionLevelEnum.MEMBER,
    value: PermissionLevelEnum.MEMBER,
  },
];

interface Props {
  user: UserFormState;
  setUserFormState: React.Dispatch<
    React.SetStateAction<UserFormState | undefined>
  >;
  companiesData: Company[];
}

export const UserDetails = ({
  user,
  companiesData,
  setUserFormState,
}: Props) => {
  // states

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // hooks
  const navigate = useNavigate();
  const { userState } = useUserContext();

  const updateMode = !!user.id;

  const companiesOptions: OptionType[] = companiesData.map(
    ({ storeName, id }) => {
      return {
        label: storeName,
        value: id.toString(),
      } as OptionType;
    }
  );

  const inputChangeHandler = <T extends keyof UserFormState>(
    inputName: T,
    changes: UserFormState[T]
  ) => {
    setUserFormState((state) => {
      if (state) return { ...state, [inputName]: changes };
    });
  };

  // react-query
  const createOrUpdateUserHandler = (
    responseData: AdminCreateOrUpdateUserResponse
  ) => {
    const { adminCreateOrUpdateUser } = responseData;

    setUserFormState({
      ...adminCreateOrUpdateUser,
      profileId: adminCreateOrUpdateUser.profile?.id,
      resolveFieldUsername: adminCreateOrUpdateUser.resolveFieldUsername,
    });

    if (user.id) {
      setSuccessMessage("User updated successfully");
    } else {
      const userDetailsToDetailsRoute = routesBuilder({
        routes: ["userDetailsRoute", "detailsRoute"],
        replace: [{ position: 1, variable: `${adminCreateOrUpdateUser.id}` }],
      });
      setSuccessMessage("User created successfully");
      navigate(`/${userDetailsToDetailsRoute}`);
    }
    setIsLoading(false);
  };

  const createOrUpdateUserErrorHandler = (error: ApolloError) => {
    const { message } = error;
    setErrorMessage(`Error: ${message}`);
    setIsLoading(false);
  };

  const { trigger: createOrUpdateUserTrigger } = useMutationWithCallbacks<
    AdminCreateOrUpdateUserResponse,
    AdminCreateOrUpdateUserInput
  >(
    ADMIN_CREATE_OR_UPDATE_USER,
    createOrUpdateUserHandler,
    createOrUpdateUserErrorHandler
  );

  const cancelClickHandler = () => {
    navigate(-1);
  };

  const submitHandler = async () => {
    setIsLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    if (!userState?.connected) {
      setErrorMessage("User is not connected");
      setIsLoading(false);
      return;
    }

    // validate fields
    const error = validateUser(
      user,
      !updateMode,
      !!updateMode && user.role !== RoleEnum.CUSTOMER
    );
    if (error) {
      setErrorMessage(error);
      setIsLoading(false);
      return;
    }

    const {
      id,
      deactivated,
      preferredUsername,
      resolveFieldUsername,
      role,
      companyId,
      email,
      permissionLevel,
      phoneNumber,
    } = user;

    try {
      await createOrUpdateUserTrigger({
        variables: {
          AdminCreateOrUpdateUserInput: {
            id,
            email,
            phoneNumber,
            companyId,
            permissionLevel,
            preferredUsername,
            role,
            username: resolveFieldUsername,
            deactivated,
          },
        },
      });
    } catch (e) {
      const error = e as AxiosHttpError;
      setErrorMessage("Error: " + error.message);
      setIsLoading(false);
      return;
    }
  };

  const companyName = companiesOptions.find(
    (o) => +o.value === user.companyId
  )?.label;

  return (
    <div>
      <div className={`${styles.inequalDoubleInputs}`}>
        {!!user.id && (
          <CustomInput
            onChange={() => {}}
            value={user.id}
            label="ID"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            disabled={true}
            width="4rem"
          />
        )}
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("email", value.toString());
          }}
          value={user.email}
          label="Email"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          required={true}
        />
      </div>
      <div className={`${styles.doubleInputs}`}>
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("resolveFieldUsername", value.toString());
          }}
          value={user.resolveFieldUsername}
          label="Username"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          disabled={updateMode}
          required={true}
        />
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("preferredUsername", value.toString());
          }}
          value={user.preferredUsername}
          label="Preferred Username"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
      </div>
      {!!user.id && (
        <div className={`${styles.doubleInputs}`}>
          <CustomInput
            onChange={() => {}}
            value={user.createdAt ? formatDate(user.createdAt) : "N/A"}
            label="Created At"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            disabled
          />
          {!!user.profileId && (
            <CustomInput
              onChange={() => {}}
              value={user.phoneNumber ?? "N/A"}
              label="Phone number"
              borderRadius="5px"
              noBorder={true}
              backgroundColor={colors.$inputGray}
              textColor={colors.$primaryDark}
              fontSize="14px"
            />
          )}
        </div>
      )}
      {!!user.id && (
        <div className={`${styles.doubleInputs}`}>
          <CustomInput
            onChange={() => {}}
            value={user.provider}
            label="Provider"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            disabled
          />
          <CustomInput
            onChange={() => {}}
            value={user.status}
            label="Status"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            disabled
          />
        </div>
      )}
      {!user.profileId && (
        <div className={`${styles.doubleInputs}`}>
          <SelectInput
            label="Permission Level"
            fontSize="14px"
            backgroundColor={colors.$inputGray}
            noBorder={true}
            options={permissionLevelOptions}
            onChange={(option: SingleValue<OptionType>) => {
              inputChangeHandler(
                "permissionLevel",
                option?.value as PermissionLevelEnum
              );
            }}
            value={user.permissionLevel || ""}
            required={true}
          />
          <SelectInput
            label="Role"
            fontSize="14px"
            backgroundColor={colors.$inputGray}
            noBorder={true}
            options={roleOptions}
            onChange={(option: SingleValue<OptionType>) => {
              inputChangeHandler("role", option?.value as RoleEnum);
            }}
            value={user.role || ""}
            required={true}
          />
          <div className={styles.companyInputContainer}>
            <SelectInput
              label="Company"
              fontSize="14px"
              backgroundColor={colors.$inputGray}
              noBorder={true}
              options={companiesOptions}
              onChange={(option: SingleValue<OptionType>) => {
                inputChangeHandler("companyId", +(option?.value ?? ""));
              }}
              value={`${user.companyId ?? 0}`}
              width="100%"
            />
            {companyName && user.companyId && (
              <a
                className={styles.companyName}
                target="_blank"
                href={`/${routePaths.companyFormRoute}?id=${user.companyId}`}
                rel="noreferrer"
              >
                {companyName}
              </a>
            )}
          </div>
        </div>
      )}
      <div className={`${styles.doubleInputs}`}>
        <CustomCheckbox
          label="Deactivated"
          onChange={(e) => {
            inputChangeHandler("deactivated", e.target.checked);
          }}
          checked={user.deactivated}
        />
      </div>
      <div className={styles.messageContainer}>
        {!!errorMessage && (
          <p className={styles.errorMessage}>{errorMessage}</p>
        )}
        {!!successMessage && <p>{successMessage}</p>}
      </div>
      <div className={`${styles.formButtonsContainer}`}>
        <CustomButton
          color={colors.$primary}
          borderColor={colors.$primary}
          width="fit-content"
          borderRadius="8px"
          padding="1rem 1.5rem"
          disabled={isLoading}
          onClick={() => {
            cancelClickHandler();
          }}
        >
          Annuler
        </CustomButton>
        <CustomButton
          backgroundColor={colors.$primary}
          color="white"
          width="fit-content"
          borderRadius="8px"
          padding="1rem 1.5rem"
          disabled={isLoading}
          onClick={() => {
            submitHandler();
          }}
        >
          {isLoading ? <SimpleLoader size="size2" /> : "Ajouter"}
        </CustomButton>
      </div>
    </div>
  );
};
