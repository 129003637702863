import { OptionType } from "~/components/form/SelectInput";
import { Attribute } from "~/types/data/Attribute.types";
import { Product } from "~/types/data/Product.types";

export const attributeValuesToOptionsMap = (
  attributes: Attribute[],
  attributeName: keyof Product
) => {
  const attribute =
    attributes.find((attribute) => {
      return attribute.name === attributeName;
    })?.values || [];

  const options: OptionType[] = attribute.map((attribute) => {
    return { label: attribute.value, value: attribute.value };
  });

  return options;
};
