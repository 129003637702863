import { Details } from "./details";
import styles from "./index.module.scss";
import { SubscriptionPackages } from "./SubscriptionPackages";

export const BrandDetails = () => {
  return (
    <div className={styles.container}>
      <div className={styles.sectionsContainer}>
        <Details /> <SubscriptionPackages />
      </div>
    </div>
  );
};
