import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

import styles from "./index.module.scss";

interface Props {
  variants: string[];
  activeIndex: number;
  onNavigationClick: (index: number) => void;
}

export const VariantsNavigation = ({
  variants,
  activeIndex,
  onNavigationClick,
}: Props) => {
  const variantNavigations: ReactJSXElement[] = [];
  for (let i = 0; i < variants.length; i++) {
    const isActive = i === activeIndex;
    variantNavigations.push(
      <div
        className={`${isActive ? styles.activeLink : ""} ${
          styles.variantContainer
        }`}
        onClick={() => {
          onNavigationClick(i);
        }}
        key={i}
      >
        <p>Variant n°{i + 1}</p>
        <p>{variants[i] || "No SKU"}</p>
      </div>
    );
  }

  return (
    <div className={`${styles.container}`}>
      <h4 className={`${styles.title}`}>Variants</h4>
      <div className={`${styles.variants}`}>{variantNavigations}</div>
    </div>
  );
};
