export enum SubscriptionImportTypeEnum {
  SUBSCRIPTION_PRODUCTS = "SUBSCRIPTION_PRODUCTS",
}

export enum SubscriptionImportStatusEnum {
  IN_PROGRESS = "IN_PROGRESS",
  ERROR = "ERROR",
  DONE = "DONE",
}

export interface SubscriptionImport {
  id: number;
  createdAt: string;
  filePath: string;
  errorFilePath?: string;
  type: SubscriptionImportTypeEnum;
  status: SubscriptionImportStatusEnum;
  errorMessage: string;
  totalEntries: number;
  successfullEntries: number;
}
