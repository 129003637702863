import Joi from "joi";

import { UpdateUserInput } from "~/types/data/User.types";

export const updateSellerValidationSchema = Joi.object<UpdateUserInput>({
  id: Joi.optional(),
  permissionLevel: Joi.string()
    .required()
    .error(new Error("Niveau de droits can't be empty.")),
});
