import { DateTime } from "luxon";
import { SingleValue } from "react-select";

import { CustomInput } from "~/components/form/CustomInput";
import DatePicker from "~/components/form/DatePicker";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import SVGContainer from "~/components/UI/SVGContainer";
import { countryList } from "~/constants/countries";
import { colors } from "~/constants/styles";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { UBO } from "~/types/data/UBO.types";

import styles from "./index.module.scss";

interface Props {
  index: number;
  disabled: boolean;
}

export const UboSection = ({ index, disabled }: Props) => {
  const { companyAccount, setCompanyAccount } = useCompanyAccountContext();

  const inputChangeHandler = <T extends keyof UBO>(
    inputName: T,
    changes: UBO[T],
    inputIndex: number
  ) => {
    setCompanyAccount((state) => {
      const modifiedUbos = [...state.ubos].map((declaration, arrayIndex) => {
        if (arrayIndex === inputIndex) {
          return { ...declaration, [inputName]: changes };
        } else {
          return { ...declaration };
        }
      });

      return { ...state, ubos: modifiedUbos };
    });
  };

  const removeUboDeclarationHandler = () => {
    inputChangeHandler("active", false, index);
  };

  const uboDeclaration = companyAccount.ubos[index];

  const countryOptions: OptionType[] = countryList.map(({ name, alpha2 }) => {
    return { label: name, value: alpha2.toUpperCase() };
  });

  return (
    <div>
      {index !== 0 && <hr />}
      <div className={`${styles.deleteButtonContainer}`}>
        <SVGContainer
          height="12px"
          width="12px"
          imagePath="/assets/close-button.svg"
          onClick={disabled ? undefined : removeUboDeclarationHandler}
        />
      </div>
      <div className="doubleInputs">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("lastName", value.toString(), index);
          }}
          value={uboDeclaration.lastName}
          label="Nom :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          disabled={disabled}
        />
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("firstName", value.toString(), index);
          }}
          value={uboDeclaration.firstName}
          label="Prénom :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          disabled={disabled}
        />
      </div>
      <div className="singleInput">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("address", value.toString(), index);
          }}
          value={uboDeclaration.address}
          label="Adresse :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          disabled={disabled}
        />
      </div>

      <div className="doubleInputs">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("zipCode", value.toString(), index);
          }}
          value={uboDeclaration.zipCode}
          label="Code postal :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          disabled={disabled}
        />
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("city", value.toString(), index);
          }}
          value={uboDeclaration.city}
          label="Ville :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          disabled={disabled}
        />
      </div>
      <div className="doubleInputs">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("region", value.toString(), index);
          }}
          value={uboDeclaration.region}
          label="Région :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          disabled={disabled}
        />
        <SelectInput
          label="Pays :"
          fontSize="14px"
          disabled={disabled}
          backgroundColor={colors.$inputGray}
          noBorder={true}
          onChange={(option: SingleValue<OptionType>) =>
            inputChangeHandler("country", option?.value || "", index)
          }
          options={countryOptions}
          value={uboDeclaration.country}
        />
      </div>
      <div className="doubleInputs">
        <SelectInput
          label="Nationalité :"
          fontSize="14px"
          disabled={disabled}
          backgroundColor={colors.$inputGray}
          noBorder={true}
          onChange={(option: SingleValue<OptionType>) =>
            inputChangeHandler("nationality", option?.value || "", index)
          }
          options={countryOptions}
          value={uboDeclaration.nationality}
        />
        <DatePicker
          label="Date de naissance :"
          fontSize="14px"
          disabled={disabled}
          icon="CalendarMonthOutlined"
          onChange={(value: DateTime | null) => {
            inputChangeHandler("birthDate", value?.toISO() || "", index);
          }}
          value={DateTime.fromISO(uboDeclaration.birthDate)}
          backgroundColor={colors.$inputGray}
          noBorder={true}
          borderRadius="5px"
        />
      </div>
      <div className="doubleInputs">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("birthPlace", value.toString(), index);
          }}
          value={uboDeclaration.birthPlace}
          label="Ville de naissance :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          disabled={disabled}
        />
        <SelectInput
          label="Pays de naissance :"
          fontSize="14px"
          disabled={disabled}
          backgroundColor={colors.$inputGray}
          noBorder={true}
          onChange={(option: SingleValue<OptionType>) =>
            inputChangeHandler("birthCountry", option?.value || "", index)
          }
          options={countryOptions}
          value={uboDeclaration.birthCountry}
        />
      </div>
    </div>
  );
};
