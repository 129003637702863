import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./index.scss";

import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";

import { colors } from "~/constants/styles";

import styles from "./index.module.scss";

interface Props {
  label?: string;
  fontSize?: string;
  readOnly?: boolean;
  htmlValue?: string;
  onChange: (changes: string) => void;
  changeIndex?: number;
  required?: boolean;
  invalid?: boolean;
}

export const RichTextEditor = ({
  label,
  fontSize,
  readOnly,
  htmlValue,
  onChange,
  changeIndex,
  required,
  invalid,
}: Props) => {
  const [editorState, setEditorState] = useState<EditorState>();

  const editorStateChangeHandler = (editorState: EditorState) => {
    setEditorState(editorState);
    const htmlChangeValue = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    onChange(htmlChangeValue);
  };

  useEffect(() => {
    const contentBlock = htmlToDraft(htmlValue || "");
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
  }, [changeIndex]);

  return (
    <div className={`${styles.container}`} style={{ fontSize: fontSize }}>
      {label && (
        <div className={`${styles.label}`}>
          <label>{label}</label>
          {required && <span>*</span>}
        </div>
      )}
      <div
        style={{
          backgroundColor: colors.$inputGray,
          borderRadius: "7px",
          height: "12rem",
        }}
      >
        <Editor
          editorState={editorState}
          wrapperClassName={invalid ? "invalid-wrapper-class" : "wrapper-class"}
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          onEditorStateChange={editorStateChangeHandler}
          readOnly={readOnly}
          handlePastedText={() => false}
          toolbar={{
            options: ["inline"],
            inline: {
              inDropdown: false,
              options: ["bold", "italic", "underline", "strikethrough"],
              bold: { className: "editor-button-class" },
              italic: { className: "editor-button-class" },
              underline: { className: "editor-button-class" },
              strikethrough: { className: "editor-button-class" },
            },
          }}
        />
      </div>
    </div>
  );
};
