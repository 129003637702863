import { useLazyQuery, useQuery } from "@apollo/client";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { utils, writeFile } from "xlsx";

import { getCarriers, GetCarriersResponse } from "~/api/graphql/carrier";
import { GET_COMPANIES, GetCompaniesResponse } from "~/api/graphql/company";
import {
  getOrders,
  GetOrdersInput,
  GetOrdersResponse,
} from "~/api/graphql/order";
import {
  GET_COMPANY_RENT_ORDERS,
  GetCompanyRentOrdersInput,
  GetCompanyRentOrdersResponse,
} from "~/api/graphql/rentOrder";
import CustomCheckbox from "~/components/form/CustomCheckbox";
import { InputWithIcon } from "~/components/form/InputWithIcon";
import { MultipleSelectInput } from "~/components/form/MultipleSelectInput";
import { OptionType } from "~/components/form/SelectInput";
import CustomNavButton from "~/components/navigation/CustomNavButton";
import { ErrorPopup } from "~/components/UI/ErrorPopup";
import FullPageLoader from "~/components/UI/FullPageLoader";
import { LoadingPopup } from "~/components/UI/LoadingPopup";
import { SideModal } from "~/components/UI/SideModal";
import SVGContainer from "~/components/UI/SVGContainer";
import { colors } from "~/constants/styles";
import { useUserContext } from "~/context/userContext";
import { routePaths, routesBuilder } from "~/navigation/routes";
import { useAppDispatch, useAppSelector } from "~/redux/hooks";
import {
  onFilterArgsChange as onOrdersFilterArgsChange,
  selectOrdersFilterArgs,
} from "~/redux/slice/ordersFilter.slice";
import {
  onFilterArgsChange as onRentOrdersFilterArgsChange,
  selectRentOrdersFilterArgs,
} from "~/redux/slice/rentOrdersFilter.slice";
import { Order } from "~/types/data/Order.types";
import { SellingTypeEnum } from "~/types/data/Product.types";
import { RentOrder } from "~/types/data/RentOrder.type";
import { RoleEnum } from "~/types/data/User.types";

import styles from "./index.module.scss";
import { OrdersFilterForm } from "./OrdersFilterForm";
import OrdersTable from "./OrdersTable";
import { RentOrdersTable } from "./RentOrdersTable";

interface Props {
  ordersMode: SellingTypeEnum;
}

const MesCommandes = ({ ordersMode }: Props) => {
  const [orders, setOrders] = useState<Order[]>();
  const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);
  const [rentOrders, setRentOrders] = useState<RentOrder[]>();
  const [selectedRentOrders, setSelectedRentOrders] = useState<RentOrder[]>([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [isPreparingFile, setIsPreparingFile] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [searchBox, setSearchBox] = useState<string>();
  const [filterCount, setFilterCount] = useState<number>(0);
  const [ordersCount, setOrdersCount] = useState<number>(0);
  const [errorModalMessage, setErrorModalMessage] = useState("");

  // hooks
  const { userState } = useUserContext();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const ordersFilterArgsState = useAppSelector(selectOrdersFilterArgs);
  const rentOrdersFilterArgsState = useAppSelector(selectRentOrdersFilterArgs);

  const isAdmin = !!userState?.connected && userState.role === RoleEnum.ADMIN;

  const { mesEvaluationsRoute } = routePaths;
  const navigate = useNavigate();

  const mesCommandesToVenteRoute = routesBuilder({
    routes: ["mesCommandesRoute", "venteRoute"],
  });

  const mesCommandesToLocationRoute = routesBuilder({
    routes: ["mesCommandesRoute", "locationRoute"],
  });

  const { data: carriersData, error: carriersError } =
    useQuery<GetCarriersResponse>(getCarriers);

  const [getOrdersTrigger] = useLazyQuery<GetOrdersResponse, GetOrdersInput>(
    getOrders
  );

  const [getRentOrdersTrigger] = useLazyQuery<
    GetCompanyRentOrdersResponse,
    GetCompanyRentOrdersInput
  >(GET_COMPANY_RENT_ORDERS);

  const { data: companiesData } = useQuery<GetCompaniesResponse>(
    GET_COMPANIES,
    {
      skip: !isAdmin,
    }
  );

  const companiesOptions: OptionType[] =
    companiesData?.getCompanies.map(({ id, storeName }) => ({
      value: `${id}`,
      label: storeName,
    })) || [];

  // handler functions
  const setIsSelectedAllHandler = (isSelectedAll: boolean) => {
    setIsSelectedAll(isSelectedAll);
  };

  const setOrdersCountHandler = (ordersCount: number) => {
    setOrdersCount(ordersCount);
  };

  const showFilterModalHandler = () => {
    setShowFilterModal(true);
  };

  const hideFilterModalHandler = () => {
    setShowFilterModal(false);
  };

  const openEvaluationPageHandler = () => {
    navigate(mesEvaluationsRoute, {
      replace: false,
    });
  };

  const searchBoxChangeHandler = useCallback(
    debounce((value: string) => {
      if (ordersMode === SellingTypeEnum.NEW) {
        dispatch(
          onOrdersFilterArgsChange({
            filterArg: "searchBox",
            searchBox: value,
          })
        );
      } else {
        dispatch(
          onRentOrdersFilterArgsChange({
            filterArg: "searchBox",
            searchBox: value,
          })
        );
      }
    }, 500),
    []
  );

  const managedByReedooChangeHandler = (value: boolean) => {
    if (ordersMode === SellingTypeEnum.NEW) {
      dispatch(
        onOrdersFilterArgsChange({
          filterArg: "managedByReedoo",
          managedByReedoo: value,
        })
      );
    }
  };

  const companiesFilterChangeHandler = (selectedOptions: OptionType[]) => {
    if (ordersMode === SellingTypeEnum.NEW) {
      dispatch(
        onOrdersFilterArgsChange({
          filterArg: "companiesIds",
          companiesIds: selectedOptions.map(({ value }) => +value),
        })
      );
    } else {
      dispatch(
        onRentOrdersFilterArgsChange({
          filterArg: "companiesIds",
          companiesIds: selectedOptions.map(({ value }) => +value),
        })
      );
    }
  };

  const exportOrders = async () => {
    const ordersToExport: Order[] = [];
    if (isSelectedAll) {
      const { data, error } = await getOrdersTrigger({
        variables: {
          OrdersFilterArgs: {
            ...ordersFilterArgsState,
            cursor: undefined,
            take: undefined,
          },
        },
      });
      if (data) {
        ordersToExport.push(...data.getOrders.orders);
      } else if (error) {
        const { message } = error;
        setErrorModalMessage(message);
        return;
      }
    } else {
      ordersToExport.push(...selectedOrders);
    }
    const modifiedOrders = ordersToExport.map(
      ({
        shippingAddress,
        conversation: _conversation,
        orderToProducts,
        buyer,
        ...data
      }) => {
        const returnRes = {
          ...data,
          buyer_name: shippingAddress?.name,
          buyer_address: shippingAddress?.address1,
          buyer_city: shippingAddress?.city,
          buyer_zipCode: shippingAddress?.zipCode,
          buyer_email: buyer?.email,
          buyer_phoneNumber: shippingAddress?.phoneNumber ?? buyer?.phoneNumber,
        };
        const productData: { [key: string]: string } = {};
        for (const [index, product] of orderToProducts.entries()) {
          productData[`product_${index + 1}_id`] = product.id.toString();
          productData[`product_${index + 1}_sku`] = product.product.sku || "";
          productData[`product_${index + 1}_quantity`] =
            product.quantity.toString();
        }

        return { ...returnRes, ...productData };
      }
    );

    // create file using xlsx
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(modifiedOrders);

    // make cells wrap text
    utils.book_append_sheet(wb, ws, "Commandes_vente");
    setIsPreparingFile(false);
    writeFile(wb, "Commandes_vente.xlsx");
  };

  const exportRentOrders = async () => {
    const rentOrdersToExport: RentOrder[] = [];
    if (isSelectedAll) {
      const { data, error } = await getRentOrdersTrigger({
        variables: {
          GetCompanyRentOrdersInput: {
            ...rentOrdersFilterArgsState,
            cursor: undefined,
            take: undefined,
          },
        },
      });
      if (data) {
        rentOrdersToExport.push(...data.getCompanyRentOrders.rentOrders);
      } else if (error) {
        const { message } = error;
        setErrorModalMessage(message);
        return;
      }
    } else {
      rentOrdersToExport.push(...selectedRentOrders);
    }
    const modifiedOrders = rentOrdersToExport.map(
      ({ shippingAddress, orderToProducts, buyer, ...data }) => {
        const returnRes = {
          ...data,
          buyer_name: shippingAddress?.name,
          buyer_address: shippingAddress?.address1,
          buyer_city: shippingAddress?.city,
          buyer_zipCode: shippingAddress?.zipCode,
          buyer_email: buyer?.email,
          buyer_phoneNumber: shippingAddress?.phoneNumber ?? buyer?.phoneNumber,
        };
        const productData: { [key: string]: string } = {};
        for (const [index, product] of orderToProducts.entries()) {
          productData[`product_${index + 1}_id`] = product.id.toString();
          productData[`product_${index + 1}_sku`] = product.product.sku || "";
          productData[`product_${index + 1}_quantity`] =
            product.originalQuantity.toString();
        }

        return { ...returnRes, ...productData };
      }
    );

    // create file using xlsx
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(modifiedOrders);

    // make cells wrap text
    utils.book_append_sheet(wb, ws, "Commandes_location");
    setIsPreparingFile(false);
    writeFile(wb, "Commandes_location.xlsx");
  };

  const exportOrdersHandler = () => {
    setIsPreparingFile(true);
    setTimeout(() => {
      exportOrders();
    }, 500);
  };

  const exportRentOrdersHandler = () => {
    setIsPreparingFile(true);
    setTimeout(() => {
      exportRentOrders();
    });
  };

  const clearSelectedOrdersHandler = () => {
    setIsSelectedAll(false);
    if (ordersMode === SellingTypeEnum.NEW) {
      setSelectedOrders([]);
    } else {
      setSelectedRentOrders([]);
    }
  };

  const clearAllOrderFiltersHandler = () => {
    dispatch(
      onOrdersFilterArgsChange({
        filterArg: "clearAll",
      })
    );
  };

  const clearAllRentOrderFiltersHandler = () => {
    dispatch(
      onRentOrdersFilterArgsChange({
        filterArg: "clearAll",
      })
    );
  };

  const setFilterCountHandler = (filterCount: number) => {
    setFilterCount(filterCount);
  };

  const hideErrorPopupHandler = () => {
    setErrorModalMessage("");
  };

  useEffect(() => {
    if (searchParams.has("pending"))
      dispatch(
        onOrdersFilterArgsChange({
          filterArg: "pending",
          pending: true,
        })
      );
  }, []);

  useEffect(() => {
    setIsSelectedAll(false);
    setOrdersCount(0);
    if (ordersMode === SellingTypeEnum.NEW) {
      setRentOrders(undefined);
      setSelectedRentOrders([]);
    } else {
      setOrders(undefined);
      setSelectedOrders([]);
    }
  }, [ordersMode]);

  useEffect(() => {
    setSearchBox(
      ordersMode === SellingTypeEnum.NEW
        ? ordersFilterArgsState.searchBox
        : rentOrdersFilterArgsState.searchBox
    );
  }, [ordersFilterArgsState.searchBox, rentOrdersFilterArgsState.searchBox]);

  return (
    <>
      <ErrorPopup
        onCancel={hideErrorPopupHandler}
        show={!!errorModalMessage}
        message={errorModalMessage}
      />
      <LoadingPopup show={isPreparingFile} message="Preparing file..." />
      {/* {carriersError || getOrdersError ? ( */}
      {carriersError ? (
        <div className={`${styles.container}`}>
          errorMessage
          {carriersError && <div>Carriers Error: {carriersError?.message}</div>}
        </div>
      ) : !carriersData ? (
        <FullPageLoader />
      ) : (
        <>
          <SideModal
            onCancel={hideFilterModalHandler}
            show={showFilterModal && ordersMode === SellingTypeEnum.NEW}
          >
            <OrdersFilterForm carriers={carriersData.getCarriers} />
          </SideModal>
          <div className={`${styles.container}`} id="scrollableOrdersPage">
            <div className={`${styles.toolbar}`}>
              <div className={`${styles.leftSideContainer}`}>
                <div className={styles.searchBoxContainer}>
                  <InputWithIcon
                    onChange={(value: string | number) => {
                      setSearchBox(value.toString());
                      searchBoxChangeHandler(value.toString());
                    }}
                    value={searchBox}
                    borderRadius="5px"
                    noBorder={true}
                    backgroundColor="white"
                    iconPath="/assets/search-icon.svg"
                    placeholder="Chercher une commande..."
                  />
                </div>

                {ordersMode === SellingTypeEnum.NEW && (
                  <div className={styles.countContainer}>
                    {!!ordersCount && !!orders?.length && (
                      <p>
                        Fetched {orders?.length || 0}/{ordersCount}
                      </p>
                    )}
                  </div>
                )}
                {ordersMode === SellingTypeEnum.RENT && (
                  <div className={styles.countContainer}>
                    {!!ordersCount && !!rentOrders?.length && (
                      <p>
                        Fetched {rentOrders?.length || 0}/{ordersCount}
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div className={`${styles.buttonsContainer}`}>
                {!!filterCount && (
                  <>
                    <div
                      className={styles.clearFilterButton}
                      onClick={
                        ordersMode === SellingTypeEnum.NEW
                          ? clearAllOrderFiltersHandler
                          : clearAllRentOrderFiltersHandler
                      }
                    >
                      <p className={`${styles.label}`}>
                        Effacer tous les filtres
                      </p>
                    </div>
                    <div className={styles.filterCount}>
                      <p>{filterCount} filtres sélectionnés</p>
                    </div>
                  </>
                )}
                {ordersMode === SellingTypeEnum.NEW && (
                  <div onClick={showFilterModalHandler}>
                    <SVGContainer
                      height="16px"
                      width="17px"
                      imagePath="/assets/filter-icon.svg"
                    />
                    <p>Filtrer les commandes</p>
                  </div>
                )}
                <div onClick={openEvaluationPageHandler}>
                  <SVGContainer
                    height="16px"
                    width="17px"
                    imagePath="/assets/empty-star-icon.svg"
                  />
                  <p>Mes évaluations</p>
                </div>
              </div>
            </div>
            <div className={styles.actionsContainer}>
              <div className={`${styles.selectedCount}`}>
                <p>
                  {isSelectedAll
                    ? ordersCount
                    : ordersMode === SellingTypeEnum.NEW
                    ? selectedOrders.length
                    : selectedRentOrders.length}{" "}
                  commandes sélectionnés
                </p>
                {((ordersMode === SellingTypeEnum.NEW &&
                  selectedOrders.length > 0) ||
                  (ordersMode === SellingTypeEnum.RENT &&
                    selectedRentOrders.length > 0)) && (
                  <>
                    <div
                      className={`${styles.actionButtonContainer}`}
                      onClick={() => {
                        clearSelectedOrdersHandler();
                      }}
                    >
                      <SVGContainer
                        height="16px"
                        width="17px"
                        imagePath="/assets/delete-icon.svg"
                      />
                      <p>Supprimer la sélection</p>
                    </div>
                    <div
                      className={`${styles.actionButtonContainer}`}
                      onClick={
                        ordersMode === SellingTypeEnum.NEW
                          ? exportOrdersHandler
                          : exportRentOrdersHandler
                      }
                    >
                      <SVGContainer
                        height="16px"
                        width="17px"
                        imagePath="/assets/export-icon.svg"
                      />
                      <p>Exporter</p>
                    </div>
                  </>
                )}
              </div>
              <div className={styles.modeSelectSection}>
                {isAdmin && (
                  <>
                    <CustomCheckbox
                      label="Managed by Reedoo"
                      checked={ordersFilterArgsState.managedByReedoo}
                      onChange={(e) => {
                        managedByReedooChangeHandler(e.target.checked);
                      }}
                    />
                    <div className={`${styles.selectContainer}`}>
                      <MultipleSelectInput
                        fontSize="12px"
                        backgroundColor="white"
                        options={companiesOptions}
                        onChange={(options: OptionType[]) => {
                          companiesFilterChangeHandler(options);
                        }}
                        value={companiesOptions.filter((option) =>
                          (ordersMode === SellingTypeEnum.NEW
                            ? ordersFilterArgsState.companiesIds
                            : rentOrdersFilterArgsState.companiesIds
                          )?.includes(+option.value)
                        )}
                      />
                    </div>
                  </>
                )}
                <>
                  <CustomNavButton
                    label="Commandes de vente"
                    to={`/${mesCommandesToVenteRoute}`}
                    padding="0.5rem 1rem"
                    backgroundColor="rgba(225, 139, 117, 0)"
                    activeBackgroundColor={colors.$primary}
                    activeTextColor="white"
                    borderRadius="5px"
                    borderColor={colors.$primary}
                    textColor={colors.$primary}
                  />
                  <CustomNavButton
                    label="Commandes de location"
                    to={`/${mesCommandesToLocationRoute}`}
                    padding="0.5rem 1rem"
                    backgroundColor="rgba(225, 139, 117, 0)"
                    activeBackgroundColor={colors.$primary}
                    activeTextColor="white"
                    borderRadius="5px"
                    borderColor={colors.$primary}
                    textColor={colors.$primary}
                  />
                </>
              </div>
            </div>
            {ordersMode === SellingTypeEnum.NEW ? (
              <OrdersTable
                orders={orders}
                setOrders={setOrders}
                setOrdersCountHandler={setOrdersCountHandler}
                setIsSelectedAllHandler={setIsSelectedAllHandler}
                selectedOrders={selectedOrders}
                setSelectedOrders={setSelectedOrders}
                carriersData={carriersData}
                isSelectedAll={isSelectedAll}
                setFilterCountHandler={setFilterCountHandler}
                showCompanyColumn={isAdmin}
              />
            ) : (
              <RentOrdersTable
                rentOrders={rentOrders}
                setRentOrders={setRentOrders}
                setOrdersCountHandler={setOrdersCountHandler}
                setIsSelectedAllHandler={setIsSelectedAllHandler}
                selectedOrders={selectedRentOrders}
                setSelectedOrders={setSelectedRentOrders}
                carriersData={carriersData}
                isSelectedAll={isSelectedAll}
                setFilterCountHandler={setFilterCountHandler}
                showCompanyColumn={isAdmin}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default MesCommandes;
