import styles from "./index.module.scss";

export interface StatusLabelType {
  label: string;
  backgroundColor: string;
  color: string;
}

const StatusLabel = ({ backgroundColor, color, label }: StatusLabelType) => {
  return (
    <div
      className={`${styles.container}`}
      style={{ backgroundColor: backgroundColor, color: color }}
    >
      <p>{label}</p>
    </div>
  );
};

export default StatusLabel;
