import { isValidBIC, isValidIBAN } from "ibantools";
import Joi from "joi";

import { BankReference } from "~/types/data/BankReference.types";
import { UpdateCompanyBankReferenceInput } from "~/types/data/Company.types";

export const updateBankReferenceValidationSchema =
  Joi.object<UpdateCompanyBankReferenceInput>({
    id: Joi.optional(),
    bankReference: Joi.object<BankReference>().keys({
      id: Joi.optional(),
      mangopayId: Joi.optional(),
      accountOwner: Joi.string()
        .required()
        .error(new Error("Titulaire du compte can't be empty.")),
      bankName: Joi.string()
        .required()
        .error(new Error("Nom de la banque can't be empty.")),
      address: Joi.string()
        .required()
        .error(new Error("Adresse can't be empty.")),
      zipCode: Joi.string()
        .required()
        .error(new Error("Code postal can't be empty.")),
      city: Joi.string().required().error(new Error("Ville can't be empty.")),
      iban: Joi.string()
        .required()
        .custom((value, helper) => {
          if (!isValidIBAN(value as string)) {
            return helper.error("any.invalid");
          }

          // Return the value unchanged
          return value;
        }),
      bic: Joi.string()
        .required()
        .custom((value, helper) => {
          if (!isValidBIC(value as string)) {
            return helper.error("any.invalid");
          }

          // Return the value unchanged
          return value;
        }),
    }),
  });
