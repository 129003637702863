import { useState } from "react";
import { SingleValue } from "react-select";
import clip from "text-clipper";

import { CustomButton } from "~/components/form/CustomButton";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { importRequiredAttributes } from "~/constants/keysof";
import { colors } from "~/constants/styles";
import { useAppDispatch, useAppSelector } from "~/redux/hooks";
import {
  onMappingChange,
  selectMappedAttributesState,
} from "~/redux/slice/mappedAttributes.slice";
import { ExcelSheetDataObject } from "~/types/common/generic.types";
import { Attribute } from "~/types/data/Attribute.types";
import { FileData } from "~/types/data/FileImport.types";
import { Product } from "~/types/data/Product.types";

import { ImportModeEnum } from "..";
import { SectionIndexEnum } from "../SectionsNavigation";
import styles from "./index.module.scss";

interface Props {
  fileData: FileData;
  isProcessing: boolean;
  onNext: () => void;
  onPrevious: (section: SectionIndexEnum) => void;
  onStateHasChanged: (state: number) => void;
  attributes: Attribute[];
  importMode?: ImportModeEnum;
}

interface TableRowProps {
  rowData: ExcelSheetDataObject;
  headCells: string[];
  clipIt?: boolean;
}

const TableRow = ({ rowData, headCells, clipIt }: TableRowProps) => {
  return (
    <tr>
      {headCells.map((cellName, index) => {
        const name = clipIt
          ? clip(rowData[cellName].toString(), 30)
          : rowData[cellName];
        return <td key={index}>{name}</td>;
      })}
    </tr>
  );
};

export const FieldsMappingPage = ({
  fileData,
  isProcessing,
  onNext,
  onPrevious,
  onStateHasChanged,
  attributes,
  importMode,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [duplicateAttribute, setDuplicateAttribute] = useState<
    keyof Product | undefined
  >();

  const previousNavigationHandler = () => {
    onPrevious(SectionIndexEnum.import);
  };

  const disabled = isProcessing;

  if (fileData.length === 0) {
    return (
      <div className={`${styles.container}`}>
        <div>Invalid data</div>{" "}
        <div className={`${styles.buttons}`}>
          <div className={`${styles.button}`}>
            <CustomButton
              backgroundColor={colors.$inputGray}
              borderColor="#DEE3E8"
              borderRadius="5px"
              onClick={!disabled ? previousNavigationHandler : undefined}
            >
              Previous
            </CustomButton>
          </div>
        </div>
      </div>
    );
  }

  const selectAttributesOptions: OptionType[] =
    attributes.map(({ name, associatedNames }) => {
      const value = `${name}||${associatedNames.join("||")}`;
      return { label: name, value };
    }) || [];

  const dispatch = useAppDispatch();
  const mappedAttributes = useAppSelector(selectMappedAttributesState);
  const headCells = Object.keys(fileData[0]) as (keyof Product)[];
  const shrinkedRowsData = fileData.slice(
    0,
    fileData.length < 5 ? fileData.length : 5
  );

  const changeHandler = ({
    index,
    mappedAttribute,
  }: {
    index: number;
    mappedAttribute?: Attribute;
  }) => {
    onStateHasChanged(SectionIndexEnum.attributesMapping);
    dispatch(onMappingChange({ index, mappedAttribute }));
  };

  const validateFields = () => {
    setErrorMessage("");

    // filter the selected attributes
    const selectedAttributes = new Set(
      mappedAttributes
        .filter(({ mappedAttribute }) => {
          return mappedAttribute;
        })
        .map(({ mappedAttribute }) => mappedAttribute?.name)
    );

    // check for duplicate values
    const selectedFields = mappedAttributes
      .filter(({ mappedAttribute }) => {
        return mappedAttribute;
      })
      .map(({ mappedAttribute }) => mappedAttribute?.name)
      .sort((a, b) => {
        if (a && b) return a.localeCompare(b);
        return -1;
      });

    const tempAttributesSet: Set<keyof Product | undefined> = new Set();
    for (const attribute of selectedFields) {
      if (tempAttributesSet.has(attribute)) {
        setErrorMessage(`${attribute} is selected more than once`);
        setDuplicateAttribute(attribute);
        return;
      }
      tempAttributesSet.add(attribute);
    }

    // check for required fields
    const requiredAttributes: (keyof Product)[] =
      importMode === ImportModeEnum.UPDATE
        ? ["sku"]
        : [...importRequiredAttributes];

    for (const requiredAttribute of requiredAttributes) {
      const hasAttribute = selectedAttributes.has(requiredAttribute);
      if (!hasAttribute) {
        setErrorMessage(
          `${requiredAttribute} is missing from the mapped attributes`
        );
        return;
      }
    }

    return true;
  };

  const nextNaviagtionHandler = () => {
    const isValid = validateFields();
    if (isValid) {
      onNext();
    }
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.tableContainer}`}>
        <table>
          <thead>
            <tr>
              {mappedAttributes.map((attr, index) => {
                const duplicateError =
                  attr.mappedAttribute?.name &&
                  duplicateAttribute === attr.mappedAttribute.name;
                return (
                  <th
                    key={index}
                    className={`${duplicateError && styles.error}`}
                  >
                    {attr.originalName}
                  </th>
                );
              })}
            </tr>
            <tr>
              {mappedAttributes.map((attr, index) => {
                const value = attr.mappedAttribute
                  ? `${
                      attr.mappedAttribute?.name
                    }||${attr.mappedAttribute?.associatedNames.join("||")}`
                  : "";
                return (
                  <td key={index}>
                    <SelectInput
                      onChange={(option: SingleValue<OptionType>) => {
                        const splitValues = option?.value.split("||");
                        const value = splitValues?.length
                          ? splitValues[0]
                          : undefined;
                        changeHandler({
                          index,
                          mappedAttribute: attributes.find(
                            ({ name }) => name === value
                          ),
                        });
                      }}
                      options={selectAttributesOptions}
                      value={value || ""}
                      fontSize="12px"
                      width="10rem"
                      hasDefault={true}
                      defaultLabel="Not selected"
                      invalidColor="#870000"
                    />
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {shrinkedRowsData.map((rowData, index) => {
              return (
                <TableRow
                  rowData={rowData}
                  key={index}
                  headCells={headCells}
                  clipIt
                />
              );
            })}
          </tbody>
        </table>
      </div>
      {!!errorMessage && (
        <p className={`${styles.errorMessage}`}>{errorMessage}</p>
      )}
      <div className={`${styles.buttons}`}>
        <div className={`${styles.button}`}>
          <CustomButton
            backgroundColor={colors.$inputGray}
            borderColor="#DEE3E8"
            borderRadius="5px"
            onClick={!disabled ? previousNavigationHandler : undefined}
            disabled={disabled}
          >
            Previous
          </CustomButton>
        </div>
        <div className={`${styles.button}`}>
          <CustomButton
            backgroundColor={colors.$inputGray}
            borderColor="#DEE3E8"
            borderRadius="5px"
            onClick={!disabled ? nextNaviagtionHandler : undefined}
            disabled={disabled}
          >
            Next
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
