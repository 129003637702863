import { User } from "~/types/data/User.types";

import { UsersTableRow } from "../UsersTableRow";
import styles from "./index.module.scss";

interface Props {
  users: User[];
  selectedRows: User[];
  onCheckboxChange: (user: User) => void;
  onRowClick: (variantId: number) => void;
}

export const UsersTableBody = ({
  users,
  selectedRows,
  onCheckboxChange,
  onRowClick,
}: Props) => {
  const isSelected = (userId?: number) =>
    selectedRows.findIndex(({ id }) => userId === id) > -1;

  return (
    <tbody className={`${styles.tbody}`}>
      {users.map((user, index) => {
        const isRowSelected = isSelected(user.id);
        return (
          <UsersTableRow
            key={index.toString()}
            user={user}
            isSelected={isRowSelected}
            onCheckboxChange={onCheckboxChange}
            onClick={onRowClick}
          />
        );
      })}
    </tbody>
  );
};
