import { memo, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "~/redux/hooks";
import {
  onFilterArgsChange,
  selectAccountingFilterArgs,
} from "~/redux/slice/accounting.slice";
import { MyTransaction } from "~/types/data/Accounting.types";

import styles from "./index.module.scss";
import TransactionsTable from "./TransactionsTable";

interface Props {
  myTransactions?: MyTransaction[];
  fetchMyTransactions: (init: boolean) => Promise<void>;
  hasMoreTransactions: boolean;
  selectedTransactions: MyTransaction[];
  setSelectedTransactions: React.Dispatch<
    React.SetStateAction<MyTransaction[]>
  >;
  isSelectedAll: boolean;
  setIsSelectedAll: React.Dispatch<React.SetStateAction<boolean>>;
}

const TransactionsPage = ({
  myTransactions,
  fetchMyTransactions,
  hasMoreTransactions,
  selectedTransactions,
  setSelectedTransactions,
  isSelectedAll,
  setIsSelectedAll,
}: Props) => {
  const filterArgsState = useAppSelector(selectAccountingFilterArgs);
  const dispatch = useAppDispatch();

  const checkboxClickHandler = (transactionInput: MyTransaction) => {
    const selectedCheckboxIndex = selectedTransactions.findIndex(
      ({ id }) => transactionInput.id === id
    );
    if (selectedCheckboxIndex > -1) {
      setIsSelectedAll(false);
      setSelectedTransactions((prev) => {
        return prev.filter(({ id }) => transactionInput.id !== id);
      });
      return;
    }
    setSelectedTransactions((prev) => {
      return [...prev, transactionInput];
    });
  };

  const selectAllChangeHandler = (selected: boolean) => {
    if (selected) {
      setIsSelectedAll(true);
      setSelectedTransactions(myTransactions || []);
      return;
    }
    setIsSelectedAll(false);
    setSelectedTransactions([]);
  };

  useEffect(() => {
    fetchMyTransactions(true);
  }, [filterArgsState.transactionsOrderBy, filterArgsState.searchBox]);

  useEffect(
    () => () => {
      dispatch(
        onFilterArgsChange({
          filterArg: "clearAll",
        })
      );
    },
    []
  );

  return (
    <>
      <div className={styles.container}>
        <TransactionsTable
          transactions={myTransactions}
          selectedRows={selectedTransactions}
          isSelectedAll={isSelectedAll}
          onCheckboxChange={checkboxClickHandler}
          onSelectAllChange={selectAllChangeHandler}
          fetchMoreData={() => {
            fetchMyTransactions(false);
          }}
          hasMoreData={hasMoreTransactions}
        />
      </div>
    </>
  );
};

export const MemoizedTransactionsPage = memo(TransactionsPage);
