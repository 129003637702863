import {
  GroupedRentOrderSingleProduct,
  RentOrderToProduct,
  SingleRentOrderToProduct,
  SingleRentProductStatusEnum,
} from "~/types/data/RentOrder.type";

const groupRentOrderSingleProducts = (
  orderProducts: RentOrderToProduct[],
  filterCallback: (singleProduct: SingleRentOrderToProduct) => boolean,
  groupedAttributesConditions: Partial<
    Record<keyof GroupedRentOrderSingleProduct, keyof SingleRentOrderToProduct>
  >
): GroupedRentOrderSingleProduct[] => {
  const groupedSingleProducts = orderProducts.reduce<
    GroupedRentOrderSingleProduct[]
  >((acc, orderProduct) => {
    const {
      id,
      product,
      singleProducts,
      deposit,
      pricePerPeriod,
      purchasePrice,
    } = orderProduct;

    const activeSingleProducts = singleProducts.filter(filterCallback);

    const attributesKeys = Object.keys(groupedAttributesConditions) as Array<
      keyof GroupedRentOrderSingleProduct
    >;

    const groupedSingleProducts = activeSingleProducts.reduce<
      GroupedRentOrderSingleProduct[]
    >((acc, singleProduct) => {
      const groupedSingleProductIndex = acc.findIndex(
        (groupedSingleProduct) => {
          const conditions = attributesKeys.reduce<boolean>(
            (acc, attribute) => {
              const singleProductAttribute =
                groupedAttributesConditions[attribute];
              if (!singleProductAttribute) return acc;
              return (
                acc &&
                groupedSingleProduct[attribute] ===
                  singleProduct[singleProductAttribute]
              );
            },
            true
          );

          return conditions;
        }
      );

      if (groupedSingleProductIndex > -1) {
        acc[groupedSingleProductIndex] = {
          ...acc[groupedSingleProductIndex],
          id: `${acc[groupedSingleProductIndex].id}-${singleProduct.id}`,
          singleProductIds: [
            ...acc[groupedSingleProductIndex].singleProductIds,
            singleProduct.id,
          ],
          quantity: acc[groupedSingleProductIndex].quantity + 1,
          totalPaidRecurringAmount:
            acc[groupedSingleProductIndex].totalPaidRecurringAmount +
            singleProduct.totalPaidRecurringAmount,
          totalPurchasedAmount:
            acc[groupedSingleProductIndex].totalPurchasedAmount +
            singleProduct.totalPurchasedAmount,
        };
      } else {
        if (product) {
          acc.push({
            id: `${id}-${singleProduct.id}`,
            product,
            orderToProductId: id,
            singleProductIds: [singleProduct.id],
            quantity: 1,
            deposit,
            pricePerPeriod,
            purchasePrice,
            damageFees: singleProduct.damageFees,
            status: singleProduct.status,
            pdfPath: singleProduct.pdfPath,
            trackingNumber: singleProduct.trackingNumber,
            trackingUrl: singleProduct.trackingUrl,
            trackingStatus: singleProduct.trackingStatus,
            returnPdfPath: singleProduct.returnPdfPath,
            returnTrackingNumber: singleProduct.returnTrackingNumber,
            returnTrackingUrl: singleProduct.returnTrackingUrl,
            returnTrackingStatus: singleProduct.returnTrackingStatus,
            totalPaidRecurringAmount: singleProduct.totalPaidRecurringAmount,
            totalPurchasedAmount: singleProduct.totalPurchasedAmount,
            currentPurchasePrice: singleProduct.currentPurchasePrice,
          });
        }
      }

      return acc;
    }, []);

    return [...acc, ...groupedSingleProducts];
  }, []);

  return groupedSingleProducts;
};

export const groupActiveRentOrderSingleProducts = (
  orderProducts: RentOrderToProduct[]
): GroupedRentOrderSingleProduct[] => {
  return groupRentOrderSingleProducts(
    orderProducts,
    ({ status }) =>
      status === SingleRentProductStatusEnum.IN_USE ||
      status === SingleRentProductStatusEnum.DELIVERING ||
      status === SingleRentProductStatusEnum.PENDING,
    {
      status: "status",
    }
  );
};

export const groupReturningRentOrderSingleProducts = (
  orderProducts: RentOrderToProduct[]
): GroupedRentOrderSingleProduct[] => {
  return groupRentOrderSingleProducts(
    orderProducts,
    ({ status }) =>
      status === SingleRentProductStatusEnum.RETURN_REQUESTED ||
      status === SingleRentProductStatusEnum.RETURN_REQUESTED_AND_SHIPPED ||
      status === SingleRentProductStatusEnum.REVIEW_REQUESTED,
    {
      status: "status",
      returnTrackingNumber: "returnTrackingNumber",
      orderToProductId: "parentOrderToProductId",
    }
  );
};

export const groupHistoryRentOrderSingleProducts = (
  orderProducts: RentOrderToProduct[]
): GroupedRentOrderSingleProduct[] => {
  return groupRentOrderSingleProducts(
    orderProducts,
    ({ status }) =>
      status === SingleRentProductStatusEnum.DAMAGED ||
      status === SingleRentProductStatusEnum.DAMAGE_FEE_PAID ||
      status === SingleRentProductStatusEnum.PURCHASED ||
      status === SingleRentProductStatusEnum.PURCHASE_PENDING ||
      status === SingleRentProductStatusEnum.RETURNED,
    {
      status: "status",
      damageFees: "damageFees",
      orderToProductId: "parentOrderToProductId",
    }
  );
};

export const groupProductsByReturnTrackingNumber = (
  products: GroupedRentOrderSingleProduct[]
): Record<string, GroupedRentOrderSingleProduct[]> => {
  return products.reduce<Record<string, GroupedRentOrderSingleProduct[]>>(
    (acc, product) => {
      if (product.returnTrackingNumber) {
        if (acc[product.returnTrackingNumber]) {
          acc[product.returnTrackingNumber].push(product);
        } else {
          acc[product.returnTrackingNumber] = [product];
        }
      }
      return acc;
    },
    {}
  );
};
