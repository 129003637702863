import { useEffect, useState } from "react";

import styles from "./index.module.scss";

interface Props {
  url: string;
  borderRadius?: string;
}

const validateAndUpdateImageUrl = (
  url: string,
  stateChangeHandler: (url: string) => void
) => {
  const altImage = "assets/broken-image.png";
  const imgElement = document.createElement("img");

  imgElement.onerror = () => {
    stateChangeHandler(altImage);
  };

  imgElement.onload = () => {
    stateChangeHandler(url);
  };

  imgElement.src = url;
};

export const SquareImage = ({ url, borderRadius }: Props) => {
  const [imageUrl, setImageUrl] = useState<string>(url);

  const changeImageHandler = (url: string) => {
    setImageUrl(url);
  };

  useEffect(() => {
    validateAndUpdateImageUrl(url, changeImageHandler);
  }, [url]);

  return (
    <div
      style={{
        backgroundImage: `url(${imageUrl})`,
        borderRadius: borderRadius,
      }}
      className={`${styles.squareImg}`}
    ></div>
  );
};
