import { memo, useMemo, useState } from "react";

import { CustomButton } from "~/components/form/CustomButton";
import { OptionType } from "~/components/form/SelectInput";
import { colors } from "~/constants/styles";
import { useAppSelector } from "~/redux/hooks";
import { selectMappedCategoriesState } from "~/redux/slice/importMappedCategories.slice";
import { VendorCategory } from "~/types/data/VendorCategory.types";

import { SectionIndexEnum } from "../SectionsNavigation";
import { MemoizedCategorySelectRow } from "./CategorySelectRow";
import styles from "./index.module.scss";

type Props = {
  categorySheetValues: string[];
  isProcessing: boolean;
  onNext: () => void;
  onPrevious: (section: SectionIndexEnum) => void;
  onStateHasChanged: (state: number) => void;
  vendorCategories: VendorCategory[];
};
const CategoriesMappingPage = ({
  categorySheetValues,
  isProcessing,
  onNext,
  onPrevious,
  vendorCategories,
  onStateHasChanged,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState("");

  const vendorCategoriesOptions: OptionType[] = vendorCategories.map(
    ({ name, associatedNames, id }) => {
      const value = `${id}||${associatedNames.join("||")}`;
      const label = name;

      return { value, label };
    }
  );

  const mappedCategories = useAppSelector(selectMappedCategoriesState);

  const disabled = isProcessing;

  const previousNavigationHandler = () => {
    onPrevious(SectionIndexEnum.attributesMapping);
  };

  const nextNaviagtionHandler = () => {
    setErrorMessage("");

    // if no problems call onNext()
    onNext();
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.tableContainer}`}>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Input Category</th>
              <th>Categories Map</th>
            </tr>
          </thead>
          <tbody>
            {categorySheetValues.map((sheetValue, index) => {
              const categoryId = useMemo(
                () => mappedCategories[sheetValue],
                [mappedCategories[sheetValue]]
              );
              const categoryObject = vendorCategories.find(
                ({ id }) => categoryId === id
              );

              const associatedNames =
                categoryObject?.associatedNames.join("||") || "";

              const value = categoryId
                ? `${categoryId}||${associatedNames}`
                : "";
              return (
                <tr key={index}>
                  <td>#{index + 1}</td>
                  <td>{sheetValue}</td>
                  <MemoizedCategorySelectRow
                    options={vendorCategoriesOptions}
                    value={value}
                    sheetValue={sheetValue}
                    onStateHasChanged={onStateHasChanged}
                  />
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {!!errorMessage && (
        <p className={`${styles.errorMessage}`}>{errorMessage}</p>
      )}
      <div className={`${styles.buttons}`}>
        <div className={`${styles.button}`}>
          <CustomButton
            backgroundColor={colors.$inputGray}
            borderColor="#DEE3E8"
            borderRadius="5px"
            onClick={!disabled ? previousNavigationHandler : undefined}
            disabled={disabled}
          >
            Previous
          </CustomButton>
        </div>
        <div className={`${styles.button}`}>
          <CustomButton
            backgroundColor={colors.$inputGray}
            borderColor="#DEE3E8"
            borderRadius="5px"
            onClick={!disabled ? nextNaviagtionHandler : undefined}
            disabled={disabled}
          >
            Next
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export const MemoizedCategoriesMappingPage = memo(CategoriesMappingPage);
