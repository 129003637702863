import { CustomInput } from "~/components/form/CustomInput";
import { colors } from "~/constants/styles";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { DeliveryPackage } from "~/types/data/Carrier.types";

import styles from "./index.module.scss";

interface Props {
  packageIndex: number;
  deliveryPackage: DeliveryPackage;
  carrierIndex: number;
}

export const DeliveryPackageSection = ({
  packageIndex,
  deliveryPackage,
  carrierIndex,
}: Props) => {
  const { companyAccount } = useCompanyAccountContext();

  const deliveryPackageState: DeliveryPackage = companyAccount.reedooCarriers[
    carrierIndex
  ].deliveryPackages
    .filter((arrayDeliveryPackage) => {
      return deliveryPackage.deliveryArea === arrayDeliveryPackage.deliveryArea;
    })
    .filter((arrayDeliveryPackage) => {
      return (
        deliveryPackage.deliveryMethod === arrayDeliveryPackage.deliveryMethod
      );
    })[packageIndex];

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.headerContainer}`}>
        <h4>Classe logistique n°{packageIndex + 1}</h4>
      </div>
      <div className={`${styles.inputsContainer}`}>
        <CustomInput
          onChange={() => {}}
          textColor={colors.$primaryDark}
          value={deliveryPackageState.code}
          noBorder={true}
          backgroundColor={colors.$inputGray}
          label="Code :"
          fontSize="12px"
          disabled
        />
        <div style={{ width: "100%" }}></div>
        <div style={{ width: "100%" }}></div>
        <div style={{ width: "100%" }}></div>
      </div>

      <div className={`${styles.inputsContainer}`}>
        <CustomInput
          onChange={() => {}}
          value={deliveryPackageState.minDays}
          label="Délai min. :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="12px"
          type="number"
          disabled
        />
        <CustomInput
          onChange={() => {}}
          value={deliveryPackageState.maxDays}
          label="Délai max. :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="12px"
          type="number"
          disabled
        />
        <CustomInput
          onChange={() => {}}
          value={deliveryPackageState.price}
          label="Prix :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="12px"
          type="number"
          disabled
        />
        <CustomInput
          onChange={() => {}}
          value={deliveryPackageState.freeThreshold || ""}
          label="Seuil de gratuité :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="12px"
          type="number"
          disabled
        />
      </div>
    </div>
  );
};
