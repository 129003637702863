import { ApiGetResponse } from "~/types/common/auth.types";
import {
  S3SignedURLInput,
  S3SignedURLResult,
} from "~/types/common/media.types";

import { gql, graphqlQuery } from "../../clients/apollo";

interface ApiGetS3SignedURLResponse {
  getS3SignedURL: S3SignedURLResult | null;
}

export async function getS3SignedURL(
  input: S3SignedURLInput
): Promise<ApiGetResponse<S3SignedURLResult>> {
  const res = await graphqlQuery<
    ApiGetS3SignedURLResponse,
    { getS3SignedURLInput: S3SignedURLInput }
  >(
    gql`
      query ($getS3SignedURLInput: GetS3SignedURLInput!) {
        getS3SignedURL(getS3SignedURLInput: $getS3SignedURLInput) {
          s3SignedURL
        }
      }
    `,
    {
      getS3SignedURLInput: input,
    }
  );
  if (res?.data && res.data.getS3SignedURL) {
    return {
      success: true,
      data: res.data.getS3SignedURL,
    };
  }
  return { success: false };
}
