import { ApolloError, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import uuid4 from "uuid4";

import {
  GetForeignShopResponse,
  getForeignShops,
} from "~/api/graphql/foreignShop";
import {
  ftpImportUpsert,
  GetFtpImport,
  getFtpImport,
  UpsertFtpImportInput,
  UpsertFtpImportResponse,
} from "~/api/graphql/ftpImport";
import { CustomButton } from "~/components/form/CustomButton";
import { CustomInput } from "~/components/form/CustomInput";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { ErrorPopup } from "~/components/UI/ErrorPopup";
import FullPageLoader from "~/components/UI/FullPageLoader";
import { LoadingPopup } from "~/components/UI/LoadingPopup";
import { SizedBox } from "~/components/UI/SizedBox";
import { customRedButtonParams } from "~/constants/style";
import { colors } from "~/constants/styles";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import { ForeignShopWithCount } from "~/types/data/ForeignShop.types";
import { ImportProtocolEnum } from "~/types/data/FtpImport.types";
import { createOrUpdateFtpImportValidationSchema } from "~/util/validation/ftpImportValidation/createOrUpdateFtpImport.schema";
import { ValidationError } from "~/util/validation/validation.error";

import { CheckboxContainer } from "./checkboxContainer";
import styles from "./index.module.scss";
import { MyConnectors } from "./myConnectors";

export enum Options1 {
  Active = "active",
}
const protocolOptions: OptionType<ImportProtocolEnum>[] = [
  {
    label: "FTP",
    value: ImportProtocolEnum.FTP,
  },
  {
    label: "SFTP",
    value: ImportProtocolEnum.SFTP,
  },
];
export enum UpdateShopOptions {
  Stocks = "Stocks",
  Prices = "Prices",
  Discounts = "Discounts",
  Orders = "Orders",
}
interface FormState {
  protocol: ImportProtocolEnum;
  host: string;
  username: string;
  password: string;
  port: number;
  filePath: string;
  active: boolean;
  error?: string;
  errorField?: keyof FormState;
}
export const SynchronizationOfStreams = () => {
  const [formState, setFormState] = useState<FormState>({
    active: false,
    protocol: ImportProtocolEnum.FTP,
    host: "",
    port: 21,
    username: "",
    password: "",
    filePath: "",
    error: "",
  });
  const [ftpImportError, setFtpImportError] = useState<string>("");

  const [existingShops, setExistingShops] = useState<ForeignShopWithCount[]>(
    []
  );
  const [newShops, setNewShops] = useState<Partial<ForeignShopWithCount>[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const updateShopCountById = (shopId: number, newCount: number) => {
    const shopToUpdate = existingShops.findIndex((shop) => shop.id === shopId);
    if (existingShops[shopToUpdate]) {
      existingShops[shopToUpdate]._count.foreignShopMapping = newCount;
      setExistingShops([...existingShops]);
    }
  };

  const addNewShop = () => {
    setNewShops((prev) => [
      ...prev,
      { id: prev[prev.length - 1]?.id || 0 + 1, newShopId: uuid4() },
    ]);
  };

  const removeShop = (shopId: string | number, isNew: boolean) => {
    if (isNew)
      setNewShops((prev) => prev.filter((shop) => shop.newShopId !== shopId));
    else setExistingShops((prev) => prev.filter((shop) => shop.id !== shopId));

    setTimeout(() => {
      console.log("newShops", newShops);
      console.log("existingShops", existingShops);
    }, 1000);
  };

  const inputChangeHandler = <T extends keyof FormState>(
    inputName: T,
    changes: FormState[T]
  ) => {
    setFormState((state) => {
      return {
        ...state,
        [inputName]: changes,
      };
    });
  };

  const onUpsertFtpImportSuccess = (_: UpsertFtpImportResponse) => {
    setLoading(false);
  };
  const onUpsertFtpImportError = (error: ApolloError) => {
    setLoading(false);
    setErrorMessage(error.message);
  };
  const { trigger: upsertFtpImport } = useMutationWithCallbacks<
    UpsertFtpImportResponse,
    UpsertFtpImportInput
  >(ftpImportUpsert, onUpsertFtpImportSuccess, onUpsertFtpImportError);

  const {
    data: ftpImport,
    error: _ftpImportError,
    loading: ftpImportLoading,
  } = useQuery<GetFtpImport>(getFtpImport);

  const { data: foreignShops, refetch: refetchShopsRequest } =
    useQuery<GetForeignShopResponse>(getForeignShops);

  const submitFtpImport = () => {
    const validation =
      createOrUpdateFtpImportValidationSchema().validate(formState);

    if (validation.error instanceof ValidationError) {
      const fieldName = validation.error.fieldName as keyof FormState;
      const message = validation.error.message;

      inputChangeHandler("errorField", fieldName);
      inputChangeHandler("error", message);
      return;
    }

    inputChangeHandler("errorField", undefined);
    inputChangeHandler("error", "");
    setFtpImportError("");
    const { error: _error, errorField: _errorField, ...input } = formState;
    setLoading(true);

    upsertFtpImport({ variables: { UpsertFtpImportInput: input } });
  };

  const refetchShops = async () => {
    const response = await refetchShopsRequest();
    setExistingShops(response.data.getForeignShops);
    setNewShops([
      {
        id: 0,
        newShopId: uuid4(),
        name: "",
        _count: { foreignShopMapping: 0 },
      },
    ]);
  };

  useEffect(() => {
    setExistingShops(foreignShops?.getForeignShops ?? []);
    setNewShops([
      {
        id: 0,
        newShopId: uuid4(),
        name: "",
        _count: { foreignShopMapping: 0 },
      },
    ]);
    console.log("I am in");
  }, [foreignShops]);

  useEffect(() => {
    if (ftpImport?.getFtpImport) {
      const { error, ...data } = ftpImport.getFtpImport;
      setFtpImportError(error ?? "");
      setFormState(data);
    }
  }, [ftpImport]);

  if (ftpImportLoading) return <FullPageLoader />;
  return (
    <div className={styles.parent}>
      <LoadingPopup show={loading} message={"loading"} />
      <ErrorPopup
        message={errorMessage}
        onCancel={() => setErrorMessage("")}
        show={!!errorMessage}
      />

      <h2>Synchronisation des flux</h2>
      <div className={styles.content}>
        <h3>Configuration des imports d’offres via FTP ou HTTP</h3>

        <CheckboxContainer
          text="Activé"
          checked={formState.active}
          type={Options1.Active}
          onSwitch={() => inputChangeHandler("active", !formState.active)}
          checkboxProps={{ color: "error" }}
        />
        <SizedBox height={5} />
        <SelectInput
          onChange={(val) =>
            inputChangeHandler("protocol", val?.value ?? ImportProtocolEnum.FTP)
          }
          options={protocolOptions}
          value={formState.protocol}
          backgroundColor={colors.$inputGray}
          fontSize="14px"
          label="Protocol"
        />
        <CustomInput
          className={styles.input}
          onChange={(val) => inputChangeHandler("host", val.toString())}
          errorText={formState.errorField == "host" ? formState.error : ""}
          value={formState.host}
          label="URL de l'hôte"
          placeholder="name.example.com"
          borderRadius="5px"
          required={true}
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
        <CustomInput
          className={styles.input}
          onChange={(val) => inputChangeHandler("port", +val)}
          errorText={formState.errorField == "port" ? formState.error : ""}
          type="number"
          value={formState.port}
          label="Port"
          borderRadius="5px"
          placeholder="21"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
        <CustomInput
          className={styles.input}
          onChange={(val) => inputChangeHandler("username", val.toString())}
          errorText={formState.errorField == "username" ? formState.error : ""}
          value={formState.username}
          label="Nom d'utilisateur"
          borderRadius="5px"
          required={true}
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
        <CustomInput
          className={styles.input}
          onChange={(val) => inputChangeHandler("password", val.toString())}
          errorText={formState.errorField == "password" ? formState.error : ""}
          value={formState.password}
          label="Mot de passe"
          borderRadius="5px"
          required={true}
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
        <CustomInput
          className={styles.input}
          onChange={(val) => inputChangeHandler("filePath", val.toString())}
          errorText={formState.errorField == "filePath" ? formState.error : ""}
          value={formState.filePath}
          label="Chemin du fichier"
          borderRadius="5px"
          required={true}
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
        {ftpImportError && (
          <div className={styles.error}>
            Data provided threw the following error <br /> {ftpImportError}
          </div>
        )}
        <CustomButton
          {...customRedButtonParams}
          onClick={() => submitFtpImport()}
        >
          Sauvegarder
        </CustomButton>
      </div>
      <br />
      <br />
      <div className={styles.content}>
        <h3>My connectors</h3>
        <br />

        {/* TODO refactor into 1 array */}
        {existingShops.map((shop) => (
          <MyConnectors
            removeShop={removeShop}
            updateShopCount={updateShopCountById}
            shop={shop}
            key={`#${shop.id}`}
            refetchShops={() => {
              refetchShops();
            }}
            setLoading={setLoading}
          />
        ))}
        {newShops.map((shop) => (
          <MyConnectors
            updateShopCount={updateShopCountById}
            increaseCounter={addNewShop}
            removeShop={removeShop}
            newShopId={shop.newShopId}
            key={shop.newShopId}
            refetchShops={() => {
              refetchShops();
            }}
            setLoading={setLoading}
          />
        ))}
      </div>
    </div>
  );
};
