import { memo } from "react";
import { SingleValue } from "react-select";

import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { colors } from "~/constants/styles";
import { SellerMappingTypeEnum } from "~/types/data/SellerMapping.types";

import styles from "./index.module.scss";

type Props = {
  index: number;
  originalValue: string;
  optionAttributes: {
    value: string;
    label: string;
  }[];
  onChange: (option: SingleValue<OptionType>) => void;
  mappedValue: string;
  type: SellerMappingTypeEnum;
};
export const OptionSelect = ({
  index,
  originalValue,
  optionAttributes,
  onChange,
  mappedValue,
}: Props) => {
  return (
    <div className={`${styles.sectionRow}`}>
      <div className={`${styles.sectionSmallItem}`}> #{index + 1}</div>
      <div className={`${styles.sectionItem}`}>{originalValue}</div>
      <div className={`${styles.sectionBigItem}`}>
        <SelectInput
          onChange={onChange}
          options={optionAttributes ?? []}
          value={mappedValue}
          backgroundColor={colors.$inputGray}
          fontSize="12px"
          hasDefault={true}
          defaultLabel="Not selected"
          invalidColor="#870000"
        />
      </div>
    </div>
  );
};

export const MemoizedOptionSelect = memo(OptionSelect, (oldProps, newProps) => {
  if (oldProps.mappedValue !== newProps.mappedValue) {
    return false;
  }
  if (
    oldProps.type == SellerMappingTypeEnum.ATTRIBUTE ||
    oldProps.type == SellerMappingTypeEnum.CATEGORY
  )
    return true;
  else {
    const oldArray = oldProps.optionAttributes;
    const newArray = newProps.optionAttributes;

    if (oldArray.length !== newArray.length) return false;
    for (let i = 0; i < oldArray.length; ++i) {
      if (oldArray[i].label !== newArray[i].label) return false;
    }
    return true;
  }
});
