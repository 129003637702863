import { Avatar } from "@mui/material";

import styles from "./index.module.scss";

interface Props {
  imageLink: string;
  name: string;
}

export const NameCard = ({ imageLink, name }: Props) => {
  return (
    <div className={`${styles.card}`}>
      <Avatar src={imageLink} alt={name} />
      <p>{name}</p>
    </div>
  );
};
