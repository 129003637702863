import { Checkbox, FormControlLabel, Typography } from "@mui/material";

import { colors } from "~/constants/styles";

import styles from "./index.module.scss";

interface Props {
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  id?: string;
  disabled?: boolean;
  padding?: string;
}

const CustomCheckbox = ({
  checked = false,
  onChange,
  label,
  id,
  disabled,
  padding,
}: Props) => {
  const checkbox = (
    <Checkbox
      id={id}
      checked={checked}
      onChange={onChange}
      onClick={(e) => e.stopPropagation()}
      disabled={disabled}
      sx={{
        "&.Mui-checked": {
          color: colors.$primary,
        },
        padding: padding,
      }}
      size="small"
    />
  );
  return !!label ? (
    <FormControlLabel
      control={checkbox}
      label={
        !!label && (
          <Typography className={`${styles.label}`}>{label}</Typography>
        )
      }
    />
  ) : (
    checkbox
  );
};

export default CustomCheckbox;
