import StatusLabel from "~/components/UI/StatusLabel";
import SVGContainer from "~/components/UI/SVGContainer";
import { IncidentStatusEnum, OrderStatusEnum } from "~/types/data/Order.types";
import { formatPrice } from "~/util/functions/formatDecimal";
import {
  getOrderStatusColor,
  getTextFromIncidentStatus,
  getTextFromOrderStatus,
} from "~/util/mapping/orderStatus.mapping";

import styles from "./index.module.scss";

interface Props {
  orderId: number;
  clientName: string;
  orderStatus: OrderStatusEnum;
  finalPrice: number;
  numberOfItems: number;
  finalShippingPrice: number;
  incidentStatus: IncidentStatusEnum;
}

export const InformationBanner = ({
  clientName,
  numberOfItems,
  orderId,
  finalShippingPrice,
  orderStatus,
  finalPrice,
  incidentStatus,
}: Props) => {
  const formattedFinalPrice = formatPrice(finalPrice);
  const formattedFinalShippingPrice = formatPrice(finalShippingPrice);

  const statusText = getTextFromOrderStatus(orderStatus);
  const statusColor = getOrderStatusColor(orderStatus);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.banner}`}>
        <div className={`${styles.bannerItem}`}>
          <p className={`${styles.title}`}>Client :</p>
          <div className={`${styles.itemContent}`}>
            <p>{clientName}</p>
            <SVGContainer
              height="12px"
              width="12px"
              imagePath="/assets/message-icon.svg"
            />
          </div>
        </div>
        <div className={`${styles.bannerItem}`}>
          <p className={`${styles.title}`}>N° de commande :</p>
          <div className={`${styles.itemContent}`}>
            <p>{orderId}</p>
          </div>
        </div>
        <div className={`${styles.bannerItem}`}>
          <p className={`${styles.title}`}>Statut :</p>
          <div className={`${styles.statusItemContent}`}>
            <StatusLabel
              label={statusText}
              color="white"
              backgroundColor={statusColor}
            />
            {(incidentStatus === IncidentStatusEnum.OPEN ||
              incidentStatus === IncidentStatusEnum.CLOSED_BY_SELLER ||
              incidentStatus === IncidentStatusEnum.CLOSED) && (
              <StatusLabel
                label={getTextFromIncidentStatus(incidentStatus)}
                color="white"
                backgroundColor="#C22A23"
              />
            )}
          </div>
        </div>
      </div>
      <div className={`${styles.summaryContainer}`}>
        <div className={`${styles.summary}`}>
          <p>
            Montant global commande:{" "}
            <span className={`${styles.emphasizedText}`}>
              {formattedFinalPrice}
            </span>
          </p>
          <p>
            Nombre total article:{" "}
            <span className={`${styles.emphasizedText}`}>{numberOfItems}</span>
          </p>
          <p>
            Frais de port:{" "}
            <span className={`${styles.emphasizedText}`}>
              {formattedFinalShippingPrice}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
