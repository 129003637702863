import { ApolloError } from "@apollo/client";
import { useState } from "react";

import {
  confirmAllOrderProducts,
  ConfirmAllOrderProductsInput,
  ConfirmAllOrderProductsResponse,
  confirmOrderProduct,
  ConfirmOrderProductInput,
  ConfirmOrderProductResponse,
} from "~/api/graphql/order";
import { CustomButton } from "~/components/form/CustomButton";
import Card from "~/components/UI/Card";
import CustomModal from "~/components/UI/CustomModal";
import StatusLabel from "~/components/UI/StatusLabel";
import SVGContainer from "~/components/UI/SVGContainer";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import {
  IncidentStatusEnum,
  Order,
  OrderStatusEnum,
  ProductOrderStatusEnum,
} from "~/types/data/Order.types";

import { BillingAddressCard } from "../components/BillingAddressCard";
import { ShippingAddressCard } from "../components/ShippingAddressCard";
import { getBuyerName } from "../util/getProfileName";
import { ConfirmOrderModal } from "./ConfirmOrderModal";
import styles from "./index.module.scss";
import { InformationBanner } from "./InformationBanner";
import OrderDetailsCard from "./OrderDetailsCard";
import OrderTotalCard from "./OrderTotalCard";

export interface ConfirmModalState {
  orderProductId?: number;
  mode?: "accept" | "refuse" | "acceptAll" | "refuseAll";
  errorMessage?: string;
  isLoading: boolean;
  show: boolean;
}

interface Props {
  order: Order;
  updateOrderHandler: (order: Order) => void;
  showCloseIncidentModalHandler: () => void;
  readOnly: boolean;
}

const Details = ({
  order,
  updateOrderHandler,
  showCloseIncidentModalHandler,
  readOnly,
}: Props) => {
  const {
    id,
    status,
    incidentStatus,
    orderToProducts,
    shippingPrice,
    totalPrice,
    refundAmount,
    refundShippingAmount,
    finalTotalPrice,
  } = order;

  const numberOfItems = orderToProducts.reduce((a, b) => {
    return a + b.quantity;
  }, 0);

  const finalShippingPrice = shippingPrice - refundShippingAmount;

  const [confirmModalState, setConfirmModalState] = useState<ConfirmModalState>(
    { isLoading: false, show: false }
  );

  const showConfirmOrderModalHandler = (modalState: ConfirmModalState) => {
    setConfirmModalState(modalState);
  };

  const hideConfirmOrderModalHandler = () => {
    setConfirmModalState({ isLoading: false, show: false });
  };

  // hooks
  const confirmAllOrderProductsSuccessHandler = (
    responseData: ConfirmAllOrderProductsResponse
  ) => {
    updateOrderHandler(responseData.confirmAllOrderProducts);
    setConfirmModalState((prev) => {
      return { ...prev, isLoading: false, show: false };
    });
  };

  const confirmAllOrderProductsErrorHandler = (error: ApolloError) => {
    const { message } = error;
    setConfirmModalState((prev) => {
      return { ...prev, errorMessage: message, isLoading: false };
    });
  };

  const { trigger: confirmAllOrderProductsTrigger } = useMutationWithCallbacks<
    ConfirmAllOrderProductsResponse,
    ConfirmAllOrderProductsInput
  >(
    confirmAllOrderProducts,
    confirmAllOrderProductsSuccessHandler,
    confirmAllOrderProductsErrorHandler
  );

  const confirmAllOrderProductsHandler = async () => {
    setConfirmModalState((prev) => {
      return { ...prev, isLoading: true, errorMessage: "" };
    });
    const status =
      confirmModalState.mode === "acceptAll"
        ? ProductOrderStatusEnum.ACCEPTED
        : confirmModalState.mode === "refuseAll"
        ? ProductOrderStatusEnum.REFUSED
        : ProductOrderStatusEnum.ON_HOLD;
    await confirmAllOrderProductsTrigger({
      variables: { ConfirmAllOrderProductsInput: { orderId: id, status } },
    });
  };

  const confirmOrderProductSuccessHandler = (
    responseData: ConfirmOrderProductResponse
  ) => {
    updateOrderHandler(responseData.confirmOrderProduct);
    setConfirmModalState((prev) => {
      return { ...prev, isLoading: false, show: false };
    });
  };

  const confirmOrderProductErrorHandler = (error: ApolloError) => {
    const { message } = error;
    setConfirmModalState((prev) => {
      return { ...prev, errorMessage: message, isLoading: false };
    });
  };

  const { trigger: confirmOrderProductTrigger } = useMutationWithCallbacks<
    ConfirmOrderProductResponse,
    ConfirmOrderProductInput
  >(
    confirmOrderProduct,
    confirmOrderProductSuccessHandler,
    confirmOrderProductErrorHandler
  );

  const confirmOrderProductHandler = async () => {
    setConfirmModalState((prev) => {
      return { ...prev, isLoading: true, errorMessage: "" };
    });
    const status =
      confirmModalState.mode === "accept"
        ? ProductOrderStatusEnum.ACCEPTED
        : confirmModalState.mode === "refuse"
        ? ProductOrderStatusEnum.REFUSED
        : ProductOrderStatusEnum.ON_HOLD;
    await confirmOrderProductTrigger({
      variables: {
        ConfirmOrderProductInput: {
          orderId: id,
          status,
          orderProductId: confirmModalState.orderProductId || 0,
        },
      },
    });
  };

  const buyerName = getBuyerName(
    order.buyer?.firstName,
    order.buyer?.lastName,
    order.shippingAddress?.name
  );

  const commissionAmount = order.commissionAmount;

  return (
    <div className={`${styles.container}`}>
      {!readOnly && (
        <>
          <CustomModal
            onCancel={
              !confirmModalState.isLoading
                ? hideConfirmOrderModalHandler
                : () => {}
            }
            show={confirmModalState.show && !readOnly}
            disableCancel={confirmModalState.isLoading}
          >
            <ConfirmOrderModal
              state={confirmModalState}
              order={order}
              onConfirmAllProducts={() => {
                confirmAllOrderProductsHandler();
              }}
              onConfirmProduct={() => {
                confirmOrderProductHandler();
              }}
            />
          </CustomModal>
        </>
      )}
      <div className={`${styles.orderInformationRow}`}>
        <div className={`${styles.bannerContainer}`}>
          <InformationBanner
            clientName={buyerName}
            numberOfItems={numberOfItems}
            orderId={id}
            finalShippingPrice={finalShippingPrice}
            orderStatus={status}
            finalPrice={finalTotalPrice}
            incidentStatus={incidentStatus}
          />
        </div>
        <div className={`${styles.additionalInformationContainer}`}>
          <div className={`${styles.additionalInformationCard}`}>
            <p className={`${styles.title}`}>Informations additionnelles</p>
          </div>
        </div>
      </div>
      {order.incidentStatus === IncidentStatusEnum.OPEN && !readOnly && (
        <div className={`${styles.statusAlertRow}`}>
          <div className={`${styles.statusContainer}`}>
            <StatusLabel
              backgroundColor="#C22A23"
              color="white"
              label="Incident"
            />
            <p>{order.incidentReason}</p>
          </div>
          <div className={`${styles.buttonContainer}`}>
            <CustomButton
              backgroundColor="#C22A23"
              height="100%"
              width="100%"
              padding="0.5rem"
              borderRadius="8px"
              color="white"
              onClick={showCloseIncidentModalHandler}
            >
              <div className={`${styles.button}`}>
                <SVGContainer
                  height="12px"
                  width="12px"
                  imagePath="/assets/white-checkmark-icon.svg"
                />
                <p>Fermer l'incident</p>
              </div>
            </CustomButton>
          </div>
        </div>
      )}

      <div className={`${styles.detailsRow}`}>
        <div className={`${styles.orderSection}`}>
          <div className={`${styles.products}`}>
            {orderToProducts.map((orderProduct, index) => (
              <div className={`${styles.product}`} key={index}>
                <OrderDetailsCard
                  onShowModal={showConfirmOrderModalHandler}
                  orderProduct={orderProduct}
                  readOnly={readOnly}
                />
              </div>
            ))}
            {status === OrderStatusEnum.WAITING_FOR_CONFIRMATION && !readOnly && (
              <div className={`${styles.buttons}`}>
                <p>Pour l’ensemble des produits :</p>

                <>
                  <CustomButton
                    color="white"
                    backgroundColor="#899CAA"
                    borderRadius="5px"
                    height="fit-content"
                    padding="0.6rem 1rem"
                    width="fit-content"
                    onClick={() => {
                      showConfirmOrderModalHandler({
                        mode: "refuseAll",
                        isLoading: false,
                        show: true,
                      });
                    }}
                  >
                    Refuser
                  </CustomButton>
                  <CustomButton
                    color="white"
                    backgroundColor="#354957"
                    borderRadius="5px"
                    height="fit-content"
                    padding="0.6rem 1rem"
                    width="fit-content"
                    onClick={() => {
                      showConfirmOrderModalHandler({
                        mode: "acceptAll",
                        isLoading: false,
                        show: true,
                      });
                    }}
                  >
                    Accepter
                  </CustomButton>
                </>
              </div>
            )}
          </div>

          <div className={`${styles.orderTotal}`}>
            <Card>
              <OrderTotalCard
                commissionAmount={commissionAmount}
                originalAmount={totalPrice}
                refundAmount={refundAmount}
                refundShippingAmount={refundShippingAmount}
                shippingAmount={shippingPrice}
                finalTotalPrice={finalTotalPrice}
              />
            </Card>
          </div>
        </div>
        <div className={`${styles.deliverySection}`}>
          <div className={`${styles.orderDelivery}`}>
            <ShippingAddressCard
              shippingAddress={order.shippingAddress}
              deliveryOption={order.deliveryOption}
              shippingDate={order.shippingDate}
              usedReedooCarrier={order.usedReedooCarrier}
              buyer={order.buyer}
              carrier={order.deliveryOption?.carrier}
            />
          </div>
          <div className={`${styles.orderBilling}`}>
            <BillingAddressCard
              shippingAddress={order.billingAddress}
              paymentMethod={order.payment?.paymentMethod}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
