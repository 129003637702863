import { useCompanyAccountContext } from "~/context/companyAccountContext";

import styles from "./index.module.scss";
import { SubscriptionPackageCard } from "./SubscriptionPackageCard";

export const SubscriptionPackages = () => {
  const { companyAccount } = useCompanyAccountContext();
  const subscriptionPackages =
    companyAccount?.brandCompany?.subscriptions ?? [];
  return (
    <div>
      <h2>Mes abonnements</h2>
      <div className={styles.listContainer}>
        {subscriptionPackages.map((subscriptionPackage) => {
          return (
            <SubscriptionPackageCard
              subscriptionPackage={subscriptionPackage}
              key={subscriptionPackage.id}
            />
          );
        })}
      </div>
    </div>
  );
};
