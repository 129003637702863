import clip from "text-clipper";

import CustomCheckbox from "~/components/form/CustomCheckbox";
import { MyTransaction } from "~/types/data/Accounting.types";
import { formatDate, formatHour } from "~/util/functions/formatDate";
import { formatDecimal } from "~/util/functions/formatDecimal";
import { getTextFromTransactionStatus } from "~/util/mapping/orderStatus.mapping";

import styles from "./index.module.scss";

interface Props {
  transaction: MyTransaction;
  isSelected: boolean;
  onCheckboxChange: (transaction: MyTransaction) => void;
}

const TransactionsTableRow = ({
  transaction,
  isSelected,
  onCheckboxChange,
}: Props) => {
  const {
    id,
    createdAt,
    products,
    totalAmount,
    originalAmount,
    shippingAmount,
    refundAmount,
    commission,
    status,
    productsVat,
    commissionVat,
  } = transaction;

  const formattedCreatedAtDate = formatDate(createdAt);
  const formattedCreatedAtHour = formatHour(createdAt);

  const statusText = getTextFromTransactionStatus(status);

  const totalSum =
    originalAmount + shippingAmount - refundAmount - commission - commissionVat;

  const formattedOriginalAmount = formatDecimal(originalAmount, 2);
  const formattedTotalAmount = formatDecimal(totalAmount, 2);
  const formattedProductsVat = formatDecimal(productsVat, 2);
  const formattedShippingAmount = formatDecimal(shippingAmount, 2);
  const formattedRefundAmount = formatDecimal(refundAmount, 2);
  const formattedCommission = formatDecimal(commission, 2);
  const formattedCommissionVat = formatDecimal(commissionVat, 2);

  const formattedTotalSum = formatDecimal(Math.abs(totalSum), 2);

  return (
    <tr className={`${styles.row}`}>
      <td>
        <CustomCheckbox
          checked={isSelected}
          onChange={() => {
            onCheckboxChange(transaction);
          }}
          padding="0.7rem"
        />
      </td>
      <td>
        <div>{formattedCreatedAtDate}</div>
        <div>{formattedCreatedAtHour}</div>
      </td>
      <td>{id}</td>
      <td>
        {products.map(({ id, sku, title }) => {
          return (
            <div key={id} className={styles.subRowContainer}>
              <div className={styles.boldText}>{clip(title || "", 20)}</div>
              <div>
                <span className={styles.boldText}>SKU: </span>
                {sku}
              </div>
            </div>
          );
        })}
      </td>
      <td>
        {products.map(({ id, quantity }) => {
          return (
            <div key={id} className={styles.subRowContainer}>
              {quantity}
            </div>
          );
        })}
      </td>
      <td>
        <div className={styles.detailsContainer}>
          <div>Montant Original</div>
          <div>Montant HT</div>
          <div>Product VAT</div>
          <div>Frais de port</div>
          {!!refundAmount && <div>Remboursement</div>}
          <div>Commission</div>
          <div>Commission VAT</div>
          <div>Total</div>
        </div>
      </td>
      <td>
        <div>
          <span className={styles.positive}>+</span>
          {formattedOriginalAmount}
        </div>
        <div>
          <span className={styles.positive}>+</span>
          {formattedTotalAmount}
        </div>
        <div>
          <span className={styles.positive}>+</span>
          {formattedProductsVat}
        </div>
        <div>
          <span className={styles.positive}>+</span>
          {formattedShippingAmount}
        </div>
        {!!refundAmount && (
          <div>
            <span className={styles.negative}>-</span>
            {formattedRefundAmount}
          </div>
        )}
        <div>
          <span className={styles.negative}>-</span>
          {formattedCommission}
        </div>
        <div>
          <span className={styles.negative}>-</span>
          {formattedCommissionVat}
        </div>
        <div>
          {totalSum > 0 ? (
            <span className={styles.positive}>+</span>
          ) : (
            <span className={styles.negative}>-</span>
          )}
          {formattedTotalSum}
        </div>
      </td>
      <td>{statusText}</td>
    </tr>
  );
};

export default TransactionsTableRow;
