import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";

import { GetCarriersResponse } from "~/api/graphql/carrier";
import {
  getOrders,
  getOrdersCount,
  GetOrdersCountResponse,
  GetOrdersInput,
  GetOrdersResponse,
} from "~/api/graphql/order";
import { ErrorPopup } from "~/components/UI/ErrorPopup";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { routesBuilder } from "~/navigation/routes";
import { useAppDispatch, useAppSelector } from "~/redux/hooks";
import {
  onFilterArgsChange,
  selectOrdersFilterArgs,
} from "~/redux/slice/ordersFilter.slice";
import { HeadCell } from "~/types/common/headCell.type";
import { Order } from "~/types/data/Order.types";

import { getFilterCount } from "../util/getFilterCount";
import styles from "./index.module.scss";
import OrdersTableBody from "./OrdersTableBody";
import OrdersTableHead from "./OrdersTableHead";

const HEADCELLS: HeadCell<Order>[] = [
  { id: 1, label: "", isCheckbox: true },
  {
    id: 2,
    label: "Date de la commande",
    isSortable: true,
    associatedKey: "createdAt",
  },
  { id: 3, label: "ID commande", isSortable: true, associatedKey: "id" },
  {
    id: 4,
    label: "Nom de l'acheteur",
    isSortable: false,
    associatedKey: "shippingAddress",
  },
  {
    id: 5,
    label: "Limite d'expédition",
    isSortable: true,
    associatedKey: "shippingDate",
  },
  { id: 6, label: "Statut", isSortable: true, associatedKey: "status" },
  {
    id: 7,
    label: "Détail de la commande",
  },
  { id: 8, label: "Qtité" },
  {
    id: 9,
    label: "Montant total",
    isSortable: true,
    associatedKey: "finalTotalPrice",
  },
  { id: 10, label: "Company" },
  { id: 11, label: "" },
];

interface Props {
  orders?: Order[];
  setOrders: React.Dispatch<React.SetStateAction<Order[] | undefined>>;
  setOrdersCountHandler: (count: number) => void;
  isSelectedAll: boolean;
  setIsSelectedAllHandler: (isSelectedAll: boolean) => void;
  selectedOrders: Order[];
  setSelectedOrders: React.Dispatch<React.SetStateAction<Order[]>>;
  carriersData: GetCarriersResponse;
  setFilterCountHandler: (count: number) => void;
  showCompanyColumn: boolean;
}

const OrdersTable = ({
  orders,
  setOrders,
  setOrdersCountHandler,
  isSelectedAll,
  setIsSelectedAllHandler,
  selectedOrders,
  setSelectedOrders,
  carriersData,
  setFilterCountHandler,
  showCompanyColumn,
}: Props) => {
  const MODIFIED_HEADCELLS = showCompanyColumn
    ? HEADCELLS
    : HEADCELLS.filter(({ id }) => id !== HEADCELLS.length - 1);

  // states
  const [hasMoreData, setHasMoreData] = useState<boolean>(false);
  const [errorModalMessage, setErrorModalMessage] = useState("");

  // hooks
  const dispatch = useAppDispatch();
  const filterArgsState = useAppSelector(selectOrdersFilterArgs);
  const navigate = useNavigate();

  const [getOrdersCountTrigger] = useLazyQuery<
    GetOrdersCountResponse,
    GetOrdersInput
  >(getOrdersCount);

  const [getOrdersTrigger, { loading: getOrdersLoading }] = useLazyQuery<
    GetOrdersResponse,
    GetOrdersInput
  >(getOrders);

  // handlers
  const orderClickHandler = (id: number) => {
    const detailsDuneCommandeToIdtoDetails = routesBuilder({
      routes: ["detailsDuneCommandeRoute", "detailsRoute"],
      replace: [{ position: 1, variable: id.toString() }],
    });
    navigate(detailsDuneCommandeToIdtoDetails, {
      replace: false,
    });
  };

  const checkboxClickHandler = (orderInput: Order) => {
    const selectedCheckboxIndex = selectedOrders.findIndex(
      ({ id }) => orderInput.id === id
    );
    if (selectedCheckboxIndex > -1) {
      setIsSelectedAllHandler(false);
      setSelectedOrders((prev) => {
        return prev.filter(({ id }) => orderInput.id !== id);
      });
      return;
    }
    setSelectedOrders((prev) => {
      return [...prev, orderInput];
    });
  };

  const fetchOrders = async (init: boolean) => {
    if (getOrdersLoading) return;

    if (init) {
      setOrders(undefined);
      dispatch(
        onFilterArgsChange({
          filterArg: "cursor",
          cursor: undefined,
        })
      );
      setSelectedOrders([]);
      setIsSelectedAllHandler(false);

      const { data } = await getOrdersCountTrigger({
        variables: {
          OrdersFilterArgs: { ...filterArgsState, cursor: undefined },
        },
      });

      if (data) {
        setOrdersCountHandler(data.getOrdersCount.count);
      }
    }

    const { data, error } = await getOrdersTrigger({
      variables: {
        OrdersFilterArgs: {
          ...filterArgsState,
          ...(init && { cursor: undefined }),
        },
      },
    });

    if (data) {
      const { getOrders } = data;
      const { hasMoreData, nextCursor, orders } = getOrders;
      setHasMoreData(hasMoreData);
      dispatch(onFilterArgsChange({ filterArg: "cursor", cursor: nextCursor }));

      if (isSelectedAll) {
        setSelectedOrders((prevSelectedOrders) => [
          ...prevSelectedOrders,
          ...orders,
        ]);
      }

      setOrders((prevProductVariants) =>
        prevProductVariants ? [...prevProductVariants, ...orders] : [...orders]
      );
    } else if (error) {
      const { message } = error;
      setErrorModalMessage(message);
    }
  };

  const selectAllChangeHandler = (selected: boolean) => {
    if (selected) {
      setIsSelectedAllHandler(true);
      setSelectedOrders(orders || []);
      return;
    }
    setIsSelectedAllHandler(false);
    setSelectedOrders([]);
  };

  const hideErrorPopupHandler = () => {
    setErrorModalMessage("");
    fetchOrders(true);
  };
  useEffect(() => {
    if (!carriersData) return;

    const count = getFilterCount(filterArgsState);
    setFilterCountHandler(count);

    fetchOrders(true);
  }, [
    filterArgsState.orderBy,
    filterArgsState.status,
    filterArgsState.carrierId,
    filterArgsState.createdAtFrom,
    filterArgsState.createdAtTo,
    filterArgsState.shippingDateFrom,
    filterArgsState.shippingDateTo,
    filterArgsState.finalTotalPriceFrom,
    filterArgsState.finalTotalPriceTo,
    filterArgsState.hasIncident,
    filterArgsState.hasRefund,
    filterArgsState.hasMessages,
    filterArgsState.searchBox,
    filterArgsState.companiesIds,
    filterArgsState.managedByReedoo,
    carriersData,
  ]);

  return (
    <>
      <ErrorPopup
        onCancel={hideErrorPopupHandler}
        show={!!errorModalMessage}
        message={errorModalMessage}
      />
      <InfiniteScroll
        dataLength={orders?.length ?? 0}
        next={() => {
          fetchOrders(false);
        }}
        hasMore={!!orders && hasMoreData}
        loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
      >
        <table className={`${styles.table}`}>
          <OrdersTableHead
            headCells={MODIFIED_HEADCELLS}
            onSelectAllChange={selectAllChangeHandler}
            allSelected={isSelectedAll}
          />
          <OrdersTableBody
            orders={orders ?? []}
            onRowClick={orderClickHandler}
            onCheckboxChange={checkboxClickHandler}
            selectedRows={selectedOrders}
            showCompanyColumn={showCompanyColumn}
          />
        </table>
      </InfiniteScroll>
      {!orders && (
        <div className={styles.loaderContainer}>
          <SimpleLoader size="size3" fill={colors.$primary} />
        </div>
      )}
    </>
  );
};

export default OrdersTable;
