import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  ADMIN_GET_COMPANY_BY_ID,
  AdminGetCompanyByIdInput,
  AdminGetCompanyByIdResponse,
} from "~/api/graphql/company";
import FullPageLoader from "~/components/UI/FullPageLoader";
import SVGContainer from "~/components/UI/SVGContainer";
import { CompanyFormState } from "~/types/data/Company.types";

import { CompanyFormContainer } from "./CompanyFormContainer";
import styles from "./index.module.scss";
import { companyFormStateInitState } from "./util/companyFormStateInitState";

export const CompanyForm = () => {
  // states
  const [companyFormState, setCompanyFormState] = useState<CompanyFormState>();

  // hooks
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const backButtonClickHandler = () => {
    navigate(-1);
  };

  // react-query
  const getCompanyByIdHandler = (responseData: AdminGetCompanyByIdResponse) => {
    const { adminGetCompanyById } = responseData;

    const {
      returnPolicyDescription: _returnPolicyDescription,
      companyDescription: _companyDescription,
      apiToken: _apiToken,
      ...data
    } = adminGetCompanyById;

    setCompanyFormState(data);
  };

  const [getCompanyByIdTrigger, { error: getCompanyByIdError }] = useLazyQuery<
    AdminGetCompanyByIdResponse,
    AdminGetCompanyByIdInput
  >(ADMIN_GET_COMPANY_BY_ID, {
    onCompleted: getCompanyByIdHandler,
    errorPolicy: "all",
  });

  const initFormState = async (id: number) => {
    await getCompanyByIdTrigger({
      variables: { AdminGetCompanyByIdInput: { id } },
    });
  };

  useEffect(() => {
    const variantId = searchParams.get("id");
    if (variantId) {
      initFormState(+variantId);
    } else {
      setCompanyFormState(companyFormStateInitState);
    }
  }, []);

  return (
    <div>
      {getCompanyByIdError ? (
        <div className={`${styles.container}`}>
          {getCompanyByIdError && (
            <div>{`Product Error: ${getCompanyByIdError.message}`}</div>
          )}
        </div>
      ) : !companyFormState ? (
        <FullPageLoader />
      ) : (
        <div className={`${styles.container}`}>
          <div className={`${styles.header}`}>
            <div className={`${styles.backButton}`}>
              <SVGContainer
                imagePath="/assets/back-button.svg"
                height="17px"
                width="20px"
                onClick={backButtonClickHandler}
              />
            </div>
            <h2>Nouveau Company</h2>
          </div>

          <CompanyFormContainer
            setCompanyFormState={setCompanyFormState}
            company={companyFormState}
          />
        </div>
      )}
    </div>
  );
};
