import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./styles.css";

import { useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";

import DraggableCard from "~/components/UI/DraggableCard";
import { months } from "~/constants/general";
import { routePaths } from "~/navigation/routes";
import CardWithButtons from "~/pages/Dashboard/cards/CardWithButtons";
import HistogramCard from "~/pages/Dashboard/cards/HistogramCard";
import NumberCard from "~/pages/Dashboard/cards/NumberCard";
import NumberListCard from "~/pages/Dashboard/cards/NumberListCard";
import { useAppSelector } from "~/redux/hooks";
import { selectConversationsState } from "~/redux/slice/conversations.slice";
import { HistogramBarData } from "~/types/common/histogram.types";
import { SaleDashboardData } from "~/types/data/DashboardData.types";

import { TableCard } from "../../cards/TableCard";

interface Props {
  data: SaleDashboardData;
  to?: string;
}

const ResponsiveGridLayout = WidthProvider(Responsive);

export const SaleDashboardGrid = ({ data, to }: Props) => {
  const {
    totalSales,
    pendingOrdersCount,
    acceptedProductsRate,
    incidentProductsRate,
    refundedProductsRate,
    productsCount,
    ordersCount,
    productsInStockCount,
    productsOutOfStockCount,
    publishedProductsCount,
    invalidProductsCounts,
    totalSalesByMonth,
    ordersCountByMonth,
    productSoldCountByMonth,
    incidentsCount,
    openIncidentsCount,
    closedBySellerIncidentsCount,
    pendingPaymentAmount,
    payablePaymentAmount,
    paidPaymentAmount,
  } = data;

  const toDate = to ? new Date(to) : new Date();
  const toMonth = toDate.getMonth();

  const monthsDataArray = new Array(12).fill(0);

  const histogramTotalSalesByMonth: HistogramBarData[] = monthsDataArray.map(
    (_, index) => {
      const monthIndex =
        toMonth + index + 1 > 11 ? toMonth + index - 11 : toMonth + index + 1;
      return {
        xAxis: months[monthIndex],
        yAxis: totalSalesByMonth ? totalSalesByMonth[monthIndex] : 0,
      };
    }
  );
  const histogramOrdersCountByMonth: HistogramBarData[] = monthsDataArray.map(
    (_, index) => {
      const monthIndex =
        toMonth + index + 1 > 11 ? toMonth + index - 11 : toMonth + index + 1;
      return {
        xAxis: months[monthIndex],
        yAxis: ordersCountByMonth[monthIndex],
      };
    }
  );

  const histogramProductSoldCountByMonth: HistogramBarData[] =
    monthsDataArray.map((_, index) => {
      const monthIndex =
        toMonth + index + 1 > 11 ? toMonth + index - 11 : toMonth + index + 1;
      return {
        xAxis: months[monthIndex],
        yAxis: productSoldCountByMonth[monthIndex],
      };
    });

  const [histogramData, setHistogramData] = useState<HistogramBarData[]>(
    histogramTotalSalesByMonth
  );

  const [selectedHistogramButton, setSelectedHistogramButton] = useState<
    1 | 2 | 3
  >(1);

  const { unreadConversationsCount } = useAppSelector(selectConversationsState);
  const layout = [
    { i: "1", x: 0, y: 0, w: 1, h: 2 },
    { i: "2", x: 1, y: 0, w: 1, h: 2 },
    { i: "3", x: 2, y: 0, w: 1, h: 2 },
    { i: "4", x: 3, y: 0, w: 1, h: 2 },
    { i: "5", x: 0, y: 2, w: 2, h: 3 },
    { i: "6", x: 2, y: 2, w: 1, h: 3 },
    { i: "7", x: 3, y: 2, w: 1, h: 3 },
    { i: "8", x: 0, y: 5, w: 1, h: 2 },
    { i: "9", x: 1, y: 5, w: 1, h: 2 },
    { i: "10", x: 2, y: 5, w: 1, h: 2 },
    { i: "11", x: 3, y: 5, w: 1, h: 2 },
    { i: "12", x: 0, y: 7, w: 3, h: 6 },
    { i: "13", x: 3, y: 7, w: 1, h: 2 },
  ];

  const {
    mesCommandesRoute,
    venteRoute,
    monCatalogueRoute,
    maMessagerieRoute,
  } = routePaths;

  const histogramButtonClickHandler = (button: 1 | 2 | 3) => {
    setSelectedHistogramButton(button);
    switch (button) {
      case 1:
        setHistogramData(histogramTotalSalesByMonth);
        return;
      case 2:
        setHistogramData(histogramOrdersCountByMonth);
        return;
      case 3:
        setHistogramData(histogramProductSoldCountByMonth);
        return;
    }
  };

  return (
    <div>
      <ResponsiveGridLayout
        className={"layout"}
        layouts={{ lg: layout }}
        breakpoints={{ lg: 1200 }}
        cols={{ lg: 4 }}
        isBounded={true}
        isResizable={false}
        margin={[20, 20]}
        draggableHandle=".draggable"
        rowHeight={60}
        containerPadding={[0, 0]}
      >
        <div key="1">
          <DraggableCard title="Chiffrage d’affaire total :">
            <NumberCard number={totalSales} currency={true} />
          </DraggableCard>
        </div>
        <div key="2">
          <DraggableCard title="Messages non lus :">
            <NumberCard
              footerText="Tous mes messages"
              number={unreadConversationsCount}
              currency={false}
              route={`/${maMessagerieRoute}`}
            />
          </DraggableCard>
        </div>
        <div key="3">
          <DraggableCard title="Commandes à traiter :">
            <NumberCard
              footerText="Toutes mes commandes à traiter"
              number={pendingOrdersCount}
              currency={false}
              route={`/${mesCommandesRoute}/${venteRoute}?pending`}
            />
          </DraggableCard>
        </div>
        <div key="4">
          <DraggableCard title="Nombe de commande :">
            <NumberCard
              footerText="Toutes mes commandes"
              number={ordersCount}
              currency={false}
              route={`/${mesCommandesRoute}/${venteRoute}`}
            />
          </DraggableCard>
        </div>
        <div key="5">
          <DraggableCard title="Statistiques Boutique :">
            <TableCard
              table1Data={{
                data: [
                  { text: "Taux d’acceptation", value1: acceptedProductsRate },
                  { text: "Taux d’incidents", value1: incidentProductsRate },
                  {
                    text: "Taux de remboursements",
                    value1: refundedProductsRate,
                  },
                ],
                headCells: [
                  { id: 0, label: "Métrique" },
                  { id: 1, label: "Valeur", isPercentage: true },
                ],
              }}
            />
          </DraggableCard>
        </div>
        <div key="6">
          <DraggableCard title="Incidents :">
            <NumberListCard
              data={[
                {
                  number: incidentsCount,
                  title: "Incidents en cours",
                  subtext: "Détail",
                  url: "",
                },
                {
                  number: openIncidentsCount,
                  title: "Incidents à traiter",
                  subtext: "Détail",
                  url: "",
                },
                {
                  number: closedBySellerIncidentsCount,
                  title: "Incidents soumis à l'arbitrage",
                  subtext: "Détail",
                  url: "",
                },
              ]}
            />
          </DraggableCard>
        </div>
        <div key="7">
          <DraggableCard title="Paiement :">
            <NumberListCard
              data={[
                {
                  number: pendingPaymentAmount,
                  title: "Solde en attente",
                  isCurrency: true,
                },
                {
                  number: payablePaymentAmount,
                  title: "Balance payable",
                  isCurrency: true,
                },
                {
                  number: paidPaymentAmount,
                  title: "Balance paid",
                  isCurrency: true,
                },
              ]}
            />
          </DraggableCard>
        </div>
        <div key="8">
          <DraggableCard title="Nombre de produits :">
            <NumberCard
              footerText="Tous mes produits"
              number={productsCount}
              currency={false}
              route={`/${monCatalogueRoute}`}
            />
          </DraggableCard>
        </div>

        <div key="9">
          <DraggableCard title="Produits en stock :">
            <NumberCard
              footerText="Tous mes produits en stock"
              number={productsInStockCount}
              currency={false}
              route={`/${monCatalogueRoute}?inStock`}
            />
          </DraggableCard>
        </div>
        <div key="10">
          <DraggableCard title="Ruptures de stock :">
            <NumberCard
              footerText="Tous mes produits en rupture"
              number={productsOutOfStockCount}
              currency={false}
              route={`/${monCatalogueRoute}?outOfStock`}
            />
          </DraggableCard>
        </div>
        <div key="11">
          <DraggableCard title="Produits publiés :">
            <NumberCard
              footerText="Tous mes produits publiés"
              number={publishedProductsCount}
              currency={false}
              route={`/${monCatalogueRoute}?published`}
            />
          </DraggableCard>
        </div>
        <div key="12">
          <CardWithButtons
            title="Chiffre d'affaire en détail :"
            onClick={histogramButtonClickHandler}
            selectedButton={selectedHistogramButton}
            buttonsText={[
              "Total en €",
              "Nombre de commandes",
              "Nombre de produits vendus",
            ]}
          >
            <HistogramCard data={histogramData} />
          </CardWithButtons>
        </div>
        <div key="13">
          <DraggableCard title="Produits invalides :">
            <NumberCard
              footerText="Tous mes produits invalides"
              number={invalidProductsCounts}
              currency={false}
              route={`/${monCatalogueRoute}?refused`}
            />
          </DraggableCard>
        </div>
      </ResponsiveGridLayout>
    </div>
  );
};
