import { memo, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "~/redux/hooks";
import {
  onFilterArgsChange,
  selectAccountingFilterArgs,
} from "~/redux/slice/accounting.slice";
import { MyPayOut } from "~/types/data/Accounting.types";

import styles from "./index.module.scss";
import PayOutsTable from "./PayOutsTable";

interface Props {
  myPayOuts?: MyPayOut[];
  fetchMyPayOuts: (init: boolean) => Promise<void>;
  hasMorePayOuts: boolean;
  selectedPayOuts: MyPayOut[];
  setSelectedPayOuts: React.Dispatch<React.SetStateAction<MyPayOut[]>>;
  isSelectedAll: boolean;
  setIsSelectedAll: React.Dispatch<React.SetStateAction<boolean>>;
}

const PayOutsPage = ({
  myPayOuts,
  fetchMyPayOuts,
  hasMorePayOuts,
  selectedPayOuts,
  setSelectedPayOuts,
  isSelectedAll,
  setIsSelectedAll,
}: Props) => {
  const filterArgsState = useAppSelector(selectAccountingFilterArgs);
  const dispatch = useAppDispatch();

  const checkboxClickHandler = (payOutInput: MyPayOut) => {
    const selectedCheckboxIndex = selectedPayOuts.findIndex(
      ({ id }) => payOutInput.id === id
    );
    if (selectedCheckboxIndex > -1) {
      setIsSelectedAll(false);
      setSelectedPayOuts((prev) => {
        return prev.filter(({ id }) => payOutInput.id !== id);
      });
      return;
    }
    setSelectedPayOuts((prev) => {
      return [...prev, payOutInput];
    });
  };

  const selectAllChangeHandler = (selected: boolean) => {
    if (selected) {
      setIsSelectedAll(true);
      setSelectedPayOuts(myPayOuts || []);
      return;
    }
    setIsSelectedAll(false);
    setSelectedPayOuts([]);
  };

  useEffect(() => {
    fetchMyPayOuts(true);
  }, [filterArgsState.payOutsOrderBy, filterArgsState.searchBox]);

  useEffect(
    () => () => {
      dispatch(
        onFilterArgsChange({
          filterArg: "clearAll",
        })
      );
    },
    []
  );

  return (
    <>
      <div className={styles.container}>
        <PayOutsTable
          payOuts={myPayOuts}
          selectedRows={selectedPayOuts}
          isSelectedAll={isSelectedAll}
          onCheckboxChange={checkboxClickHandler}
          onSelectAllChange={selectAllChangeHandler}
          fetchMoreData={() => {
            fetchMyPayOuts(false);
          }}
          hasMoreData={hasMorePayOuts}
        />
      </div>
    </>
  );
};

export const MemoizedPayOutsPage = memo(PayOutsPage);
