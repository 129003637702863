import { sendRequest } from "~/api/clients/axios";
import { AxiosHttpError } from "~/types/common/error.types";
import {
  ShopifyFetchNewProductsRequest,
  ShopifyFetchNewProductsResponse,
} from "~/types/data/ShopifyFetchNewProducts.types";

export default async function fetchShopifyProductsRequest(
  shopId: number
): Promise<{
  count?: number;
  error?: string;
}> {
  try {
    const response = await sendRequest<
      ShopifyFetchNewProductsRequest,
      ShopifyFetchNewProductsResponse
    >("post", "shopify/addNewProducts/", {
      shopId,
    });
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosHttpError;
    if (axiosError.response?.data) {
      const { message } = axiosError.response.data;
      return {
        error: typeof message === "string" ? message : message[0],
      };
    }
    return {
      error: "Something went wrong",
    };
  }
}
