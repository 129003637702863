import { Order, RefundPartialOrderInput } from "~/types/data/Order.types";

export const validatePartialRefund = (
  refundPartialOrderInput: RefundPartialOrderInput,
  order?: Order
) => {
  // validate refund shipping price
  const orderShippingPrice = order?.shippingPrice || 0;
  const refundShippingAmount = order?.refundShippingAmount || 0;
  const leftShippingAmount = orderShippingPrice - refundShippingAmount;

  if (
    refundPartialOrderInput.shippingAmount < 0 ||
    refundPartialOrderInput.shippingAmount > leftShippingAmount
  ) {
    return "Invalid frais de port commande";
  }

  for (let i = 0; i < refundPartialOrderInput.refundProducts.length; i++) {
    const refundOrderProduct = refundPartialOrderInput.refundProducts[i];
    const orderProductQuantity = order?.orderToProducts[i].quantity || 0;
    const orderProductUnitPrice = order?.orderToProducts[i].unitPrice || 0;
    const orderProductTotalPrice = order?.orderToProducts[i].totalPrice || 0;
    const orderProductRefundAmount =
      order?.orderToProducts[i].refundAmount || 0;
    const orderProductLeftAmount =
      orderProductTotalPrice - orderProductRefundAmount;

    if (
      !Number.isInteger(refundOrderProduct.quantity) ||
      refundOrderProduct.quantity < 0 ||
      refundOrderProduct.quantity > orderProductQuantity
    ) {
      return `Product #${i + 1} has invalid quantité`;
    }

    if (
      refundOrderProduct.unitAmount < 0 ||
      refundOrderProduct.unitAmount > orderProductUnitPrice
    ) {
      return `Product #${i + 1} has invalid Montant`;
    }

    if (
      refundOrderProduct.quantity * refundOrderProduct.unitAmount >
      orderProductLeftAmount
    ) {
      return `Product #${i + 1} refund exceeds the remaining amount`;
    }
  }

  return "";
};
