import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";

import {
  DELETE_PROMO_CODE,
  DeletePromoCodeResponse,
  DeleteSecondHandPromoCodeInput,
  GET_PROMO_CODES,
  GetSecondHandPromoCodesResponse,
} from "~/api/graphql/promoCode";
import { ConfirmModal } from "~/components/UI/ConfirmModal";
import CustomModal from "~/components/UI/CustomModal";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import SVGContainer from "~/components/UI/SVGContainer";
import { colors } from "~/constants/styles";
import { PromoCode } from "~/types/data/PromoCode.type";

import { AddPromoCode } from "./AddPromoCode";
import styles from "./index.module.scss";
import { PromoCodeCard } from "./PromoCodesCard";

export type PromoCodeModalState = {
  promoCode?: PromoCode;
  isOpen: boolean;
};

export const SecondHandPromoCodeManagment = () => {
  const [promoCodes, setPromoCodes] = useState<PromoCode[]>();
  const [fetchingErrorMessage, setFetchingErrorMessage] = useState("");

  const [promoCodesModalState, setPromoCodesModalState] =
    useState<PromoCodeModalState>({
      isOpen: false,
    });

  const [promoCodesDeleteModalState, setPromoCodesDeleteModalState] =
    useState<PromoCodeModalState>({
      isOpen: false,
    });

  const [deletePromoCode] = useMutation<
    DeletePromoCodeResponse,
    DeleteSecondHandPromoCodeInput
  >(DELETE_PROMO_CODE);

  const [getPromoCodesTrigger] = useLazyQuery<GetSecondHandPromoCodesResponse>(
    GET_PROMO_CODES,
    {
      onCompleted: (data) => {
        setPromoCodes(data.getSecondHandPromoCodes);
      },
      onError: (error) => {
        setFetchingErrorMessage(error.message);
      },
    }
  );

  const fetchPromoCodes = async () => {
    await getPromoCodesTrigger();
  };

  useEffect(() => {
    fetchPromoCodes();
  }, []);

  const deletePromoCodeSuccess = () => {
    deletePromoCode({
      variables: {
        id: promoCodesDeleteModalState.promoCode?.id ?? 0,
      },
    }).then(() => {
      setPromoCodes((prevState) => {
        if (!prevState) return prevState;
        return prevState.filter(
          (promoCode) =>
            promoCode.id !== promoCodesDeleteModalState.promoCode?.id
        );
      });
      setPromoCodesDeleteModalState({ isOpen: false });
    });
  };

  return (
    <>
      <ConfirmModal
        show={promoCodesDeleteModalState.isOpen}
        headerText="Delete PromoCode"
        messageText="Are you sure you want to delete this promoCode?"
        onConfirm={() => {
          deletePromoCodeSuccess();
        }}
        onExit={() => {
          setPromoCodesDeleteModalState({ isOpen: false });
        }}
      />

      <CustomModal
        show={promoCodesModalState.isOpen}
        onCancel={() => {
          setPromoCodesModalState({ isOpen: false });
        }}
        disableScroll
      >
        <AddPromoCode
          oldPromoCode={promoCodesModalState.promoCode}
          closeModal={() => setPromoCodesModalState({ isOpen: false })}
          onSuccess={(newPromoCode) => {
            setPromoCodes((prevState) => {
              if (!prevState) return [newPromoCode];
              const attFound = prevState.find(
                (promoCode) => promoCode.id === newPromoCode.id
              );
              if (!attFound) return [...prevState, newPromoCode];
              return prevState.map((promoCode) => {
                if (promoCode.id === newPromoCode.id) {
                  return newPromoCode;
                }
                return promoCode;
              });
            });
          }}
        />
      </CustomModal>
      <div className={styles.container}>
        <div className={styles.secondContainer}>
          {!!fetchingErrorMessage ? (
            <div className={styles.error}>
              <p>{fetchingErrorMessage}</p>
            </div>
          ) : !promoCodes ? (
            <div className={styles.loadingContainer}>
              <SimpleLoader size="size3" fill={colors.$primary} />
            </div>
          ) : (
            <>
              <div className={styles.headerContainer}>
                <h2 className={styles.header}>Promo Codes</h2>
                <SVGContainer
                  onClick={() => {
                    setPromoCodesModalState({
                      isOpen: true,
                    });
                  }}
                  imagePath="/assets/add-button.svg"
                  width="32px"
                  height="32px"
                />
              </div>
              <div className={styles.dataContainer}>
                {promoCodes.map((promoCode) => (
                  <PromoCodeCard
                    key={promoCode.id}
                    promoCode={promoCode}
                    clickHandler={(att) => {
                      setPromoCodesModalState({
                        isOpen: true,
                        promoCode: att,
                      });
                    }}
                    setPromoCodesDeleteModalState={(att) => {
                      setPromoCodesDeleteModalState({
                        isOpen: true,
                        promoCode: att,
                      });
                    }}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
