import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";

import {
  ADMIN_CREATE_ATTRIBUTE_VALUE,
  ADMIN_UPDATE_ATTRIBUTE_VALUE,
  AdminCreateAttributeValueInput,
  AdminCreateAttributeValueResponse,
  AdminUpdateAttributeValueInput,
  AdminUpdateAttributeValueResponse,
} from "~/api/graphql/attributeValue";
import { ColorPickerInput } from "~/components/form/ColorPickerInput";
import { CustomButton } from "~/components/form/CustomButton";
import { CustomInput } from "~/components/form/CustomInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { AttributeValue } from "~/types/data/AttributeValue.types";

import styles from "./index.module.scss";

interface Props {
  attributeId: number;
  attributeIsColor: boolean;
  attributeValue?: AttributeValue;
  addAttributeValueStateHandler: (attributeValue: AttributeValue) => void;
  updateAttributeValueState: (
    attributeValueId: number,
    attributeValue: AttributeValue
  ) => void;
  isLoading?: boolean;
  setIsLoading: (isLoading: boolean) => void;
  errorMessage?: string;
  setErrorMessage: (errorMessage: string) => void;
  hideModal: () => void;
}

interface AttrubuteValueState {
  id: number;
  value: string;
  associatedValues: string;
  code?: string;
}
export const FormModal = ({
  addAttributeValueStateHandler,
  updateAttributeValueState,
  attributeValue,
  isLoading,
  setIsLoading,
  errorMessage,
  setErrorMessage,
  attributeId,
  hideModal,
  attributeIsColor,
}: Props) => {
  const [attributeValueState, setAttributeValueState] =
    useState<AttrubuteValueState>({
      id: attributeValue?.id ?? 0,
      code: attributeValue?.code,
      value: attributeValue?.value ?? "",
      associatedValues: attributeValue?.associatedValues.join(", ") ?? "",
    });

  const [createAttributeValueTrigger] = useMutation<
    AdminCreateAttributeValueResponse,
    AdminCreateAttributeValueInput
  >(ADMIN_CREATE_ATTRIBUTE_VALUE);

  const [updateAttributeValueTrigger] = useMutation<
    AdminUpdateAttributeValueResponse,
    AdminUpdateAttributeValueInput
  >(ADMIN_UPDATE_ATTRIBUTE_VALUE);

  const inputChangeHandler = <T extends keyof AttrubuteValueState>(
    inputName: T,
    changes: AttrubuteValueState[T]
  ) => {
    setAttributeValueState((prevState) => ({
      ...prevState,
      [inputName]: changes,
    }));
  };

  const createAttributeValueHandler = async () => {
    setIsLoading(true);
    await createAttributeValueTrigger({
      variables: {
        AdminCreateAttributeValueInput: {
          value: attributeValueState.value,
          attributeId,
          associatedValues:
            attributeValueState.associatedValues
              ?.split(",")
              .map((value) => value.trim()) ?? [],
        },
      },
      onCompleted: (data) => {
        addAttributeValueStateHandler(data.adminCreateAttributeValue);
        hideModal();
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
    setIsLoading(false);
  };

  const updateAttributeValueHandler = async () => {
    setIsLoading(true);
    await updateAttributeValueTrigger({
      variables: {
        AdminUpdateAttributeValueInput: {
          id: attributeValueState.id,
          value: attributeValueState.value,
          associatedValues: attributeValueState.associatedValues
            .split(",")
            .map((value) => value.trim()),
          code: attributeValueState.code,
        },
      },
      onCompleted: (data) => {
        updateAttributeValueState(
          attributeValue?.id ?? 0,
          data.adminUpdateAttributeValue
        );
        hideModal();
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
    setIsLoading(false);
  };

  useEffect(() => {
    setAttributeValueState({
      id: attributeValue?.id ?? 0,
      code: attributeValue?.code,
      value: attributeValue?.value ?? "",
      associatedValues: attributeValue?.associatedValues.join(", ") ?? "",
    });
  }, [attributeValue]);

  return (
    <div className={styles.container}>
      <div>
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("value", value.toString());
          }}
          value={attributeValueState.value}
          label="Value"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          required
        />
        {attributeIsColor && (
          <ColorPickerInput
            onChange={(value: string | number) => {
              inputChangeHandler("code", value.toString());
            }}
            value={attributeValueState.code ?? ""}
            label="Color code"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
          />
        )}
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("associatedValues", value.toString());
          }}
          value={attributeValueState.associatedValues}
          label="Alternative names"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
      </div>
      {errorMessage && (
        <div className={styles.error}>
          <p>{errorMessage}</p>
        </div>
      )}
      <div className={styles.buttonsContainer}>
        <CustomButton
          width="9rem"
          backgroundColor={colors.$primary}
          height="2rem"
          borderRadius="0.5rem"
          color="white"
          disabled={isLoading || isLoading}
          onClick={() => {
            if (attributeValueState.id) {
              updateAttributeValueHandler();
            } else {
              createAttributeValueHandler();
            }
          }}
        >
          {isLoading ? (
            <SimpleLoader size="size1" />
          ) : attributeValueState.id ? (
            "Update"
          ) : (
            "Create"
          )}
        </CustomButton>
      </div>
    </div>
  );
};
