import React from "react";

import styles from "./index.module.scss";

interface Props {
  backgroundColor?: string;
  color?: string;
  borderRadius?: string | number;
  type?: "button" | "submit";
  borderColor?: string;
  width?: string;
  disabled?: boolean;
  onClick?: () => void;
  padding?: string;
  height?: string;
  active?: boolean;
  activeBackgroundColor?: string;
  activeColor?: string;
  margin?: string;
  fontSize?: string;
}

export const CustomButton = ({
  type,
  backgroundColor,
  borderColor,
  borderRadius,
  children,
  color,
  disabled,
  onClick,
  width,
  padding,
  height,
  margin,
  fontSize,
}: React.PropsWithChildren<Props>) => {
  return (
    <button
      type={type || "button"}
      className={`${styles.button}`}
      style={{
        backgroundColor:
          (disabled ? `${backgroundColor}88` : backgroundColor) || "inherit",
        opacity: disabled ? 0.7 : 1,
        width: width || "100%",
        border: borderColor ? "2px solid " + borderColor : "none",
        borderRadius: borderRadius,
        color: color,
        padding: padding || "",
        height: height || "50px",
        margin: margin,
        fontSize: fontSize,
      }}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
