import { memo, useCallback, useState } from "react";
import { SingleValue } from "react-select";

import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { colors } from "~/constants/styles";
import { useAppDispatch } from "~/redux/hooks";
import { onMappingChange } from "~/redux/slice/importMappedCategories.slice";
type Props = {
  value: string;
  sheetValue: string;
  options?: OptionType[];
  onStateHasChanged: (state: number) => void;
};
const CategorySelectInput = ({
  value,
  options = [],
  sheetValue,
  onStateHasChanged,
}: Props) => {
  const [currentValue, setCurrentValue] = useState<string>(value);
  const dispatch = useAppDispatch();
  const onChange = useCallback((change: string) => {
    if (change) {
      setCurrentValue(change);
    } else {
      setCurrentValue("");
    }
    onStateHasChanged(2);
    dispatch(onMappingChange({ key: sheetValue, value: change }));
  }, []);
  return (
    <SelectInput
      onChange={(option: SingleValue<OptionType>) => {
        onChange(option?.value || "");
      }}
      options={options}
      value={currentValue}
      backgroundColor={colors.$inputGray}
      fontSize="12px"
      hasDefault={true}
      defaultLabel="Not selected"
      invalidColor="#870000"
    />
  );
};
export const MemoizedCategorySelectInput = memo(CategorySelectInput);
