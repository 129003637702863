import { ApolloError, useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";

import {
  GET_FILTERED_REVIEWS_BY_ID,
  GET_REVIEWS_AGGREGATE_DATA,
  GetFilteredReviewsByIdInput,
  GetFilteredReviewsByIdResponse,
  GetReivewsAggregateDataInput,
  GetReviewsAggregateDataResponse,
  UPDATE_REVIEWS_RESPONSE,
  UpdateReviewResponseApiResponse,
  UpdateReviewResponseInput,
} from "~/api/graphql/review";
import CustomModal from "~/components/UI/CustomModal";
import FullPageLoader from "~/components/UI/FullPageLoader";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import { useAppDispatch } from "~/redux/hooks";
import {
  onFilterArgsChange,
  selectReviewsFilterArgs,
} from "~/redux/slice/reviewsFilter.slice";
import { Review } from "~/types/data/Review.types";

import styles from "./index.module.scss";
import { ReviewCard } from "./ReviewCard";
import { ReviewResponseForm } from "./ReviewResponseForm";
import { ReviewsFilterForm } from "./ReviewsFilterBar";

export const MyReviews = () => {
  const [reviews, setReviews] = useState<Review[]>();
  const [hasMoreReviews, setHasMoreReviews] = useState(false);

  const reviewsFilterArgs = useSelector(selectReviewsFilterArgs);

  const [modalState, setModalState] = useState<{
    reivewIndex: number;
    isLoading: boolean;
    errorMessage: string;
    successMessage: string;
  }>({
    reivewIndex: -1,
    isLoading: false,
    errorMessage: "",
    successMessage: "",
  });

  const { companyAccount } = useCompanyAccountContext();

  const dispatch = useAppDispatch();

  const {
    data: reviewsAggregateData,
    error: getReviewsAggregateDataError,
    loading: getReviewsAggregateDataLoading,
  } = useQuery<GetReviewsAggregateDataResponse, GetReivewsAggregateDataInput>(
    GET_REVIEWS_AGGREGATE_DATA,
    {
      variables: {
        GetReivewsAggregateDataInput: { companyId: companyAccount.id },
      },
    }
  );

  const [getFilteredReviewsTrigger, { error: getFilteredReviewsError }] =
    useLazyQuery<GetFilteredReviewsByIdResponse, GetFilteredReviewsByIdInput>(
      GET_FILTERED_REVIEWS_BY_ID
    );

  const updateReviewsResponseSuccessHandler = (
    data: UpdateReviewResponseApiResponse
  ) => {
    setReviews((prev) =>
      prev?.map((prevReview) =>
        prevReview.id === data.updateReviewResponse.id
          ? data.updateReviewResponse
          : prevReview
      )
    );
    setModalState((prev) => ({
      ...prev,
      isLoading: false,
      successMessage: "Response updated successfully",
    }));
  };

  const updateReviewsResponseErrorHandler = (error: ApolloError) => {
    setModalState((prev) => ({
      ...prev,
      isLoading: false,
      errorMessage: error.message,
    }));
  };

  const { trigger: updateReviewsResponseTrigger } = useMutationWithCallbacks<
    UpdateReviewResponseApiResponse,
    UpdateReviewResponseInput
  >(
    UPDATE_REVIEWS_RESPONSE,
    updateReviewsResponseSuccessHandler,
    updateReviewsResponseErrorHandler
  );

  const showResponseModalHandler = (index: number) => {
    setModalState({
      errorMessage: "",
      isLoading: false,
      reivewIndex: index,
      successMessage: "",
    });
  };

  const hideResponseModalHandler = () => {
    setModalState({
      errorMessage: "",
      isLoading: false,
      reivewIndex: -1,
      successMessage: "",
    });
  };

  const submitResponseHandler = async (reviewId: number, response: string) => {
    setModalState((prev) => ({ ...prev, isLoading: true }));
    await updateReviewsResponseTrigger({
      variables: { UpdateReviewResponseInput: { id: reviewId, response } },
    });
  };

  const fetchReviews = async () => {
    const { data } = await getFilteredReviewsTrigger({
      variables: {
        GetFilteredReviewsByIdInput: {
          reviewedCompanyId: companyAccount.id,
          cursor: reviewsFilterArgs.cursor,
          take: reviewsFilterArgs.take,
          orderBy: reviewsFilterArgs.orderBy,
          rating: reviewsFilterArgs.rating,
          searchBox: reviewsFilterArgs.searchBox,
        },
      },
    });

    if (data) {
      const { hasMoreData, nextCursor, reviews } = data.getFilteredReviewsById;
      setReviews((prev) => (prev ? [...prev, ...reviews] : reviews));
      setHasMoreReviews(hasMoreData);
      dispatch(onFilterArgsChange({ filterArg: "cursor", cursor: nextCursor }));
    }
  };

  useEffect(() => {
    setReviews(undefined);
    fetchReviews();
  }, [
    reviewsFilterArgs.orderBy,
    reviewsFilterArgs.rating,
    reviewsFilterArgs.searchBox,
  ]);

  return (
    <>
      <div className={`${styles.container}`}>
        <CustomModal
          show={modalState.reivewIndex > -1}
          onCancel={hideResponseModalHandler}
          disableCancel={modalState.isLoading}
        >
          {modalState.reivewIndex > -1 && reviews && (
            <ReviewResponseForm
              review={reviews[modalState.reivewIndex]}
              isLoading={modalState.isLoading}
              onSubmit={(reviewId, response) => {
                submitResponseHandler(reviewId, response);
              }}
              errorMessage={modalState.errorMessage}
              successMessage={modalState.successMessage}
            />
          )}
        </CustomModal>
        {getReviewsAggregateDataError ? (
          <div className={`${styles.container}`}>
            {getReviewsAggregateDataError && (
              <div>{`Reviews aggregate data Error: ${getReviewsAggregateDataError.message}`}</div>
            )}
          </div>
        ) : getReviewsAggregateDataLoading ? (
          <FullPageLoader />
        ) : (
          <>
            <ReviewsFilterForm
              averageRating={
                reviewsAggregateData?.getReviewsAggregateData.averageRating || 0
              }
              totalReviews={
                reviewsAggregateData?.getReviewsAggregateData.totalReviews || 0
              }
            />
            {getFilteredReviewsError ? (
              <div className={`${styles.container}`}>
                {getFilteredReviewsError && (
                  <div>{`Reviews Error: ${getFilteredReviewsError.message}`}</div>
                )}
              </div>
            ) : !reviews ? (
              <div className={styles.loaderContainer}>
                <SimpleLoader size="size3" fill={colors.$primary} />
              </div>
            ) : (
              <InfiniteScroll
                dataLength={reviews.length}
                next={() => {
                  fetchReviews();
                }}
                hasMore={hasMoreReviews}
                loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
              >
                <div>
                  {reviews.map((review, index) => {
                    return (
                      <ReviewCard
                        review={review}
                        key={index}
                        index={index}
                        onOpenResponseModal={showResponseModalHandler}
                      />
                    );
                  })}
                </div>
              </InfiniteScroll>
            )}
          </>
        )}
      </div>
    </>
  );
};
