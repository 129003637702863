import {
  Carrier,
  DeliveryMethodEnum,
  DeliveryPackage,
} from "~/types/data/Carrier.types";
import { DeliveryAddress } from "~/types/data/Order.types";
import { Profile } from "~/types/data/Profile.types";
import { formatDate } from "~/util/functions/formatDate";

import styles from "./index.module.scss";

interface Props {
  shippingAddress?: DeliveryAddress;
  deliveryOption?: DeliveryPackage;
  buyer?: Profile;
  shippingDate?: string;
  usedReedooCarrier: boolean;
  carrier?: Carrier;
}

export const ShippingAddressCard = ({
  shippingAddress,
  deliveryOption,
  buyer,
  shippingDate,
  usedReedooCarrier,
  carrier,
}: Props) => {
  const formattedShippingDate = shippingDate ? formatDate(shippingDate) : "N/A";
  const phoneNumber = shippingAddress?.phoneNumber || buyer?.phoneNumber || "";
  const email = shippingAddress?.email || buyer?.email || "";

  const deliverySection = (
    <div className={`${styles.sectionContainer}`}>
      <p className={`${styles.sectionTitle}`}>Adresse de livraison :</p>
      <p className={`${styles.sectionData}`}>{shippingAddress?.address1}</p>
      {!!shippingAddress?.address2 && (
        <p className={`${styles.sectionData}`}>{shippingAddress?.address2}</p>
      )}
      <p className={`${styles.sectionData}`}>
        {shippingAddress?.city}, {shippingAddress?.country}
      </p>
      {shippingAddress?.zipCode && (
        <p className={`${styles.sectionData}`}>{shippingAddress?.zipCode}</p>
      )}
    </div>
  );

  const pickupSection = (
    <div className={`${styles.sectionContainer}`}>
      <p className={`${styles.sectionTitle}`}>Point Relais :</p>
      {carrier && <p className={`${styles.sectionData}`}>{carrier.name}</p>}
      {shippingAddress?.pickupId && (
        <p className={`${styles.sectionData}`}>
          {shippingAddress.pickupId ?? ""}
        </p>
      )}
      {shippingAddress?.pickupName && (
        <p className={`${styles.sectionData}`}>{shippingAddress.pickupName}</p>
      )}
      <p className={`${styles.sectionData}`}>{shippingAddress?.address1}</p>
      {!!shippingAddress?.address2 && (
        <p className={`${styles.sectionData}`}>{shippingAddress?.address2}</p>
      )}
      <p className={`${styles.sectionData}`}>
        {shippingAddress?.city}, {shippingAddress?.country}
      </p>
      {shippingAddress?.zipCode && (
        <p className={`${styles.sectionData}`}>{shippingAddress?.zipCode}</p>
      )}
    </div>
  );

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.titleContainer}`}>
        <p>Livraison</p>
      </div>
      {!!usedReedooCarrier && (
        <div className={`${styles.sectionContainer}`}>
          <p className={`${styles.sectionTitle}`}>Transport Reedoo</p>
        </div>
      )}
      <div className={`${styles.sectionContainer}`}>
        <p className={`${styles.sectionTitle}`}>Informations client :</p>
        {shippingAddress?.name && (
          <p className={`${styles.sectionData}`}>{shippingAddress?.name}</p>
        )}
        {phoneNumber && (
          <p className={`${styles.sectionData}`}>{phoneNumber}</p>
        )}
        {email && <p className={`${styles.sectionData}`}>{email}</p>}
      </div>
      {deliveryOption?.deliveryMethod === DeliveryMethodEnum.DELIVERY
        ? deliverySection
        : pickupSection}

      <div className={`${styles.sectionContainer}`}>
        <p className={`${styles.sectionTitle}`}>Zone de livraison :</p>
        <p className={`${styles.sectionData}`}>
          {deliveryOption?.deliveryArea ?? "N/A"}
        </p>
      </div>
      <div className={`${styles.sectionContainer}`}>
        <p className={`${styles.sectionTitle}`}>Mode de livraison :</p>
        <p className={`${styles.sectionData}`}>
          {deliveryOption?.deliveryMethod ?? "N/A"}
        </p>
      </div>
      <div className={`${styles.sectionContainer}`}>
        <p className={`${styles.sectionTitle}`}>Limite d’expédition : </p>
        <p className={`${styles.sectionData}`}>{formattedShippingDate}</p>
      </div>
    </div>
  );
};
