import { SimpleLoader } from "~/components/UI/SimpleLoader";
import SVGContainer from "~/components/UI/SVGContainer";
import { useUserContext } from "~/context/userContext";
import { PermissionLevelEnum, User } from "~/types/data/User.types";
import { isotoString } from "~/util/mapping/dateMapping";
import { userPermissionLevelToString } from "~/util/mapping/enumMapping";

import styles from "./index.module.scss";

interface Props {
  user: User;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  deletingId?: number;
}

export const UsersTableRow = ({
  user,
  onEdit,
  onDelete,
  deletingId,
}: Props) => {
  const { userState } = useUserContext();

  const { id, email, permissionLevel, createdAt } = user;

  return (
    <tr>
      <td>{id}</td>
      <td>{email}</td>
      <td>{userPermissionLevelToString(permissionLevel)}</td>
      <td>{isotoString(createdAt)}</td>
      {userState?.connected &&
        userState.permissionLevel === PermissionLevelEnum.ADMIN && (
          <td>
            {deletingId === id ? (
              <SimpleLoader size="size1" fill="black" noMargin={true} />
            ) : (
              userState.id !== id && (
                <div className={`${styles.buttonsContainer}`}>
                  <SVGContainer
                    height="12px"
                    width="12px"
                    imagePath="/assets/edit-button.svg"
                    onClick={() => {
                      onEdit(id);
                    }}
                  />
                  <SVGContainer
                    height="12px"
                    width="12px"
                    imagePath="/assets/delete-button.svg"
                    onClick={() => {
                      onDelete(id);
                    }}
                  />
                </div>
              )
            )}
          </td>
        )}
    </tr>
  );
};
