import { Histogram } from "~/components/UI/Histogram";
import { HistogramBarData } from "~/types/common/histogram.types";

import styles from "./index.module.scss";

interface Props {
  data: HistogramBarData[];
}

const HistogramCard = ({ data }: Props) => {
  return (
    <div className={`${styles.container}`}>
      <Histogram data={data} />
    </div>
  );
};
export default HistogramCard;
