import React, { Dispatch, useState } from "react";

import addShopifyStoreRequest from "~/api/rest/addShopifyStore";
import { CustomButton } from "~/components/form/CustomButton";
import { CustomInput } from "~/components/form/CustomInput";
import { MessageModal } from "~/components/UI/MessageModal/MappingModal";
import { customRedButtonParams } from "~/constants/style";
import { colors } from "~/constants/styles";
import { ShopifyFormState } from "~/types/data/ShopifyAddStore.types";
import { onInputChange } from "~/util/functions/onInputChange";

import { UpdateShopOptions } from "../..";
import styles from "./index.module.scss";

type Props = {
  setLoading: Dispatch<React.SetStateAction<boolean>>;
  refetchShops: () => void;
  updateData: Set<UpdateShopOptions>;
};

export const ShopifyConnect = ({
  setLoading,
  refetchShops,
  updateData,
}: Props) => {
  const [formState, setFormState] = useState<ShopifyFormState>({});
  const [formErrors, setFormErrors] = useState<ShopifyFormState>({});

  const initMessages = {
    message: "",
    error: "",
  };
  const [modalMessage, setModalMessage] = useState<{
    message?: string;
    error?: string;
  }>(initMessages);
  const [showModal, setShowModal] = useState(false);

  const validateInput = (name: keyof ShopifyFormState, value?: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: value ? "" : "Ce champ est requis",
    }));
  };

  const submit = async () => {
    if (!formState.shop || !formState.token || !formState.secretKey) return;
    setLoading(true);
    const result = await addShopifyStoreRequest({
      shop: formState.shop.trim(),
      token: formState.token.trim(),
      secretKey: formState.secretKey.trim(),
      updateDiscount: updateData.has(UpdateShopOptions.Discounts),
      updateOrders: updateData.has(UpdateShopOptions.Orders),
      updatePrice: updateData.has(UpdateShopOptions.Prices),
      updateQuantity: updateData.has(UpdateShopOptions.Stocks),
    });
    setModalMessage(result);
    setShowModal(true);
    setLoading(false);
  };

  const hideModal = () => {
    setShowModal(false);
    setModalMessage(initMessages);
    if (modalMessage.message) {
      refetchShops();
    }
  };

  return (
    <>
      <MessageModal
        headerText="Ajouter une boutique Shopify"
        messageText={modalMessage.message || modalMessage.error || ""}
        show={showModal}
        onExit={hideModal}
      />
      <div className={styles.container}>
        <CustomInput
          value={formState.shop}
          onChange={(e) => {
            onInputChange<ShopifyFormState>("shop", e.toString(), setFormState);
            validateInput("shop", e.toString());
          }}
          className={styles.input}
          label="Shopify store URL"
          borderRadius="5px"
          borderColor={formErrors.shop ? "#ff3333" : "black"}
          noBorder={!formErrors.shop}
          required={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
        <br />
        <CustomInput
          value={formState.token}
          onChange={(e) => {
            onInputChange<ShopifyFormState>(
              "token",
              e.toString(),
              setFormState
            );
            validateInput("token", e.toString());
          }}
          className={styles.input}
          label="API token (Retrieve from the admin API access token)"
          borderRadius="5px"
          borderColor={formErrors.token ? "#ff3333" : "black"}
          noBorder={!formErrors.token}
          required={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
        <br />
        <CustomInput
          value={formState.secretKey}
          onChange={(e) => {
            onInputChange<ShopifyFormState>(
              "secretKey",
              e.toString(),
              setFormState
            );
            validateInput("secretKey", e.toString());
          }}
          className={styles.input}
          label="Secret key (To be retrieved from your shopify app)"
          borderRadius="5px"
          borderColor={formErrors.secretKey ? "#ff3333" : "black"}
          noBorder={!formErrors.secretKey}
          required={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
        <br />
        <CustomButton
          {...customRedButtonParams}
          onClick={() => {
            submit();
          }}
          disabled={!formState.shop || !formState.token || !formState.secretKey}
        >
          <p className={styles.gap}>Connect your shop</p>
        </CustomButton>
      </div>
    </>
  );
};
