import { ApiGetResponse } from "~/types/common/auth.types";
import {
  MediaUploadInput,
  MediaUploadResult,
} from "~/types/common/media.types";

import { gql, graphqlQuery } from "../../clients/apollo";

interface ApiGetMediaUploadPathResponse {
  getMediaUploadPath: MediaUploadResult | null;
}

export async function getMediaUploadPath(
  input: MediaUploadInput
): Promise<ApiGetResponse<MediaUploadResult>> {
  const res = await graphqlQuery<
    ApiGetMediaUploadPathResponse,
    { getMediaUploadPathInput: MediaUploadInput }
  >(
    gql`
      query ($getMediaUploadPathInput: GetMediaUploadPathInput!) {
        getMediaUploadPath(getMediaUploadPathInput: $getMediaUploadPathInput) {
          url
          path
        }
      }
    `,
    {
      getMediaUploadPathInput: input,
    }
  );
  if (res?.data && res.data.getMediaUploadPath) {
    return {
      success: true,
      data: res.data.getMediaUploadPath,
    };
  }
  return { success: false };
}
