import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import {
  GET_LAST_SUBSCRIPTION_IMPORT,
  GetLastSubscriptionImportInput,
  GetLastSubscriptionImportResponse,
} from "~/api/graphql/subscriptionImport";
import CustomModal from "~/components/UI/CustomModal";
import StatusLabel from "~/components/UI/StatusLabel";
import SVGContainer from "~/components/UI/SVGContainer";
import {
  SubscriptionImport,
  SubscriptionImportStatusEnum,
} from "~/types/data/SubscriptionImport.type";
import { SubscriptionPackage } from "~/types/data/SubscriptionPackage.type";
import { formatDate } from "~/util/functions/formatDate";

import styles from "./index.module.scss";
import { UploadFileModal } from "./UploadFileModal";

interface Props {
  subscriptionPackage: SubscriptionPackage;
}

export const ImportFileSection = ({ subscriptionPackage }: Props) => {
  const [lastImport, setLastImport] = useState<SubscriptionImport | undefined>(
    subscriptionPackage.lastSubscriptionImport
  );
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [getLastSubscriptionImportTrigger] = useLazyQuery<
    GetLastSubscriptionImportResponse,
    GetLastSubscriptionImportInput
  >(GET_LAST_SUBSCRIPTION_IMPORT);

  const dismissModal = () => {
    setShowModal(false);
  };

  const setLastImportHandler = (value: SubscriptionImport) => {
    setLastImport(value);
  };

  const setIsLoadingHandler = (value: boolean) => {
    setIsLoading(value);
  };

  const lastImportedDate = lastImport?.createdAt
    ? formatDate(lastImport.createdAt)
    : "N/A";
  const lastImportedStatus = lastImport?.status ?? "N/A";
  const lastImportedStatusColor =
    lastImport?.status === SubscriptionImportStatusEnum.DONE
      ? "green"
      : lastImport?.status === SubscriptionImportStatusEnum.ERROR
      ? "red"
      : lastImport?.status === SubscriptionImportStatusEnum.IN_PROGRESS
      ? "#dd8500"
      : "#818181";

  const getLastSubscriptionImportHandler = async () => {
    const response = await getLastSubscriptionImportTrigger({
      variables: {
        GetLastSubscriptionImportInput: {
          subscriptionId: subscriptionPackage.id,
        },
      },
    });
    setLastImport(response.data?.getLastSubscriptionImport);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getLastSubscriptionImportHandler();
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <CustomModal
        show={showModal}
        disableCancel={isLoading}
        onCancel={dismissModal}
      >
        <UploadFileModal
          setLastImportHandler={setLastImportHandler}
          subscriptionId={subscriptionPackage.id}
          hideModal={dismissModal}
          isLoading={isLoading}
          setIsLoading={setIsLoadingHandler}
        />
      </CustomModal>{" "}
      <div className={styles.container}>
        <h3>Import File</h3>
        <div className={styles.BodyContainer}>
          {
            <>
              <p className={styles.rowContainer}>
                <span className={styles.boldText}>Last imported File:</span>{" "}
                {lastImport ? (
                  <a href={lastImport.filePath}>Download</a>
                ) : (
                  "N/A"
                )}
              </p>
              {lastImport?.errorFilePath && (
                <p className={styles.rowContainer}>
                  <span className={styles.boldText}>Error File: </span>{" "}
                  <a href={lastImport?.errorFilePath}>Download</a>
                </p>
              )}
              <p className={styles.rowContainer}>
                <span className={styles.boldText}>Date:</span>{" "}
                {lastImportedDate}
              </p>
              <div className={styles.rowContainer}>
                <p className={styles.boldText}>Status: </p>
                <StatusLabel
                  label={lastImportedStatus}
                  backgroundColor={lastImportedStatusColor}
                  color="white"
                />
              </div>
              {"Successfull Entries: " +
                lastImport?.successfullEntries +
                "/" +
                lastImport?.totalEntries}
              {lastImport?.status === SubscriptionImportStatusEnum.ERROR &&
                lastImport?.errorMessage && (
                  <p className={styles.rowContainer}>
                    <span className={styles.boldText}>Error:</span>{" "}
                    {lastImport.errorMessage}
                  </p>
                )}
            </>
          }
          {(!lastImport ||
            lastImport?.status !==
              SubscriptionImportStatusEnum.IN_PROGRESS) && (
            <div className={styles.buttonContainer}>
              <div
                className={styles.button}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <SVGContainer
                  height="16px"
                  width="16px"
                  imagePath="/assets/add-button.svg"
                />
                <p>Uplaod File</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
