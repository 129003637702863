import styles from "./index.module.scss";
import { Section } from "./Section";

const sections = [
  "Import Product File",
  "Fields Mapping",
  "Categories Mapping",
  "Attributes Mapping",
  "Confirm Mapping",
];

export enum SectionIndexEnum {
  import = 0,
  attributesMapping = 1,
  categoriesMapping = 2,
  attributeValuesMapping = 3,
  validation = 4,
}

export enum NavigationSectionStateEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  FINISHED = "FINISHED",
}
interface Props {
  onChangeSection: (section: number) => void;
  activeSection: number;
  lastStateChanged: number;
}

export const SectionsNavigation = ({
  onChangeSection,
  activeSection,
  lastStateChanged,
}: Props) => {
  return (
    <div className={`${styles.container}`}>
      <h4 className={`${styles.title}`}>Sections</h4>
      <div className={`${styles.sections}`}>
        {sections.map((value, index) => {
          const clickState =
            activeSection === index
              ? NavigationSectionStateEnum.ACTIVE
              : activeSection > index
              ? NavigationSectionStateEnum.FINISHED
              : NavigationSectionStateEnum.INACTIVE;

          const isClickable = lastStateChanged >= index;
          return (
            <Section
              key={index}
              index={index}
              clickState={clickState}
              isClickable={isClickable}
              title={value}
              onClick={onChangeSection}
            />
          );
        })}
      </div>
    </div>
  );
};
