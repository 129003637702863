import { CustomButton } from "~/components/form/CustomButton";
import CustomModal from "~/components/UI/CustomModal";
import { colors } from "~/constants/styles";

import styles from "./index.module.scss";
type Props = {
  onExit: () => void;
  onConfirm?: () => void;
  headerText: string;
  messageText: string;
  buttonText?: string;
  show: boolean;
};
export const MessageModal = ({
  headerText,
  messageText,
  buttonText = "fermé",
  onExit,
  onConfirm,
  show,
}: Props) => {
  return (
    <CustomModal onCancel={onExit} show={show}>
      <div className={styles.modalContainer}>
        <h3>{headerText}</h3>
        <p>{messageText}</p>
        <CustomButton
          type="button"
          backgroundColor={colors.$primary}
          color="white"
          width="13rem"
          borderRadius="8px"
          padding="1rem 1rem"
          onClick={onConfirm ?? onExit}
        >
          {buttonText}
        </CustomButton>
      </div>
    </CustomModal>
  );
};
