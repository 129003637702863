import { SquareImage } from "../SquareImage";
import SVGContainer from "../SVGContainer";
import styles from "./index.module.scss";

interface Props {
  url: string;
  borderRadius: string;
  onDelete: (e: React.MouseEvent) => void;
}

export const PreviewImage = ({ url, borderRadius, onDelete }: Props) => {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.closeButton}`} onClick={onDelete}>
        <SVGContainer
          height="16px"
          width="16px"
          imagePath="/assets/circular-close-button.svg"
        />
      </div>

      <div className={`${styles.imageContainer}`}>
        <SquareImage url={url} borderRadius={borderRadius} />
      </div>
    </div>
  );
};
