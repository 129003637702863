import styles from "./index.module.scss";

interface Props {
  data: {
    title: string;
    number: number;
    subtext?: string;
    url?: string;
    isCurrency?: boolean;
  }[];
}

const NumberListCard = ({ data }: Props) => {
  return (
    <div className={`${styles.container}`}>
      {data.map(({ number, title, isCurrency }, index) => {
        const formattedNumber = isCurrency
          ? new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(number)
          : number;
        return (
          <div className={`${styles.row}`} key={index}>
            <p className={`${styles.title}`}>{title}</p>
            <p className={`${styles.number}`}>{formattedNumber}</p>
          </div>
        );
      })}
    </div>
  );
};

export default NumberListCard;
