import { TransportationStatusEnum } from "~/types/data/Transportation.types";

export function getTextFromTransportationStatus(
  status?: TransportationStatusEnum
) {
  switch (status) {
    case TransportationStatusEnum.NOT_TRACKABLE:
      return "Livraison confirmée";

    case TransportationStatusEnum.NOTIFICATION:
      return "Notification";

    case TransportationStatusEnum.PROCESSING:
      return "En traitement";

    case TransportationStatusEnum.DISPATCH:
      return "Expédition";

    case TransportationStatusEnum.ARRIVAL_ON_SITE:
      return "Arrivée sur place";

    case TransportationStatusEnum.DELIVERY:
      return "Livraison";

    case TransportationStatusEnum.DELIVERED:
      return "Livré";

    default:
      return "";
  }
}
