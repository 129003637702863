import { Popover } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import { SketchPicker } from "react-color";

import styles from "./index.module.scss";

interface Props {
  placeholder?: string;
  value: string;
  onChange: (changes: string) => void;
  required?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  noBorder?: boolean;
  label?: string;
  backgroundColor?: string;
  textColor?: string;
  borderRadius?: string;
  fontSize?: string;
  width?: string;
  borderColor?: string;
  errorText?: string;
}

export const ColorPickerInput: FunctionComponent<Props> = ({
  placeholder,
  value = "",
  onChange,
  required = false,
  disabled = false,
  noBorder = false,
  invalid = false,
  label,
  backgroundColor,
  textColor,
  borderRadius,
  fontSize,
  width,
  borderColor,
  errorText,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;

    onChange(event.target.value);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = !!anchorEl;

  return (
    <div className={styles.container} style={{ fontSize: fontSize, width }}>
      <Popover
        open={!disabled && isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <SketchPicker
          color={value}
          disableAlpha
          onChange={({ hex }) => {
            onChange(hex);
          }}
        />
      </Popover>
      {label && (
        <label className={`${styles.label}`}>
          {label}
          {required && <span>*</span>}
        </label>
      )}
      <div
        onClick={handleClick}
        className={`${styles.divContainer} ${
          invalid ? "#870000" : noBorder ? styles.noBorder : ""
        }`}
        style={{
          backgroundColor: backgroundColor,
          border: "1px solid " + borderColor,
          color: textColor,
          borderRadius: borderRadius,
          marginTop: label ? "0.3rem" : "",
          borderColor: invalid ? "#870000" : borderColor,
          width: "100%",
        }}
      >
        <div
          style={{
            backgroundColor: value,
            height: "100%",
            width: "2rem",
            borderRadius: "0.5rem",
          }}
        />
        <div className={`${styles.inputContainer}`}>
          <input
            value={value}
            type="text"
            className={`${styles.input} ${noBorder ? styles.noBorder : ""}`}
            autoComplete="off"
            placeholder={placeholder || ""}
            onChange={handleChange}
            readOnly
            required={required}
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            onClick={handleClick}
            style={{
              backgroundColor,
            }}
          />
        </div>
      </div>
      {errorText && <span className={styles.error}>{errorText}</span>}
    </div>
  );
};
