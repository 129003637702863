import { AdminUsersFilterArgs } from "~/redux/slice/adminUsers.slice";
import { getKeys } from "~/util/functions/getKeys";

export const getFilterCount = (filter: AdminUsersFilterArgs) => {
  let count = 0;
  const filterKeys = getKeys(filter);

  for (let i = 0; i < filterKeys.length; i++) {
    const key = filterKeys[i];
    if (key === "cursor" || key === "take") {
      continue;
    } else if (key === "orderBy") {
      const orderByKeys = getKeys(filter.orderBy);
      if (orderByKeys.length) {
        count++;
      }
    } else if (key === "companiesIds") {
      if (!!filter.companiesIds?.length) {
        count++;
      }
    } else {
      if (!!filter[key]) {
        count++;
      }
    }
  }

  return count;
};
