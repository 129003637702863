import { gql } from "@apollo/client";

import { graphqlGetSecondHandCompanyFields } from "~/constants/graphql";
import {
  SecondHandCompany,
  UpdateSecondHandCompanyInputType,
} from "~/types/data/SecondHandCompany.type";

export interface UpdateSecondHandCompanyInput {
  UpdateSecondHandCompanyInput: UpdateSecondHandCompanyInputType;
}

export interface UpdateSecondHandCompanyResponse {
  updateSecondHandCompany: SecondHandCompany;
}

export interface GetBrandCompaniesResponse {
  getBrandCompanies: SecondHandCompany[];
}

const secondHandCompanyFields = graphqlGetSecondHandCompanyFields.join(" ");

export const UPDATE_SECOND_HAND_COMPANY = gql`
  mutation ($UpdateSecondHandCompanyInput: UpdateSecondHandCompanyInput!) {
    updateSecondHandCompany(UpdateSecondHandCompanyInput: $UpdateSecondHandCompanyInput) {
        ${secondHandCompanyFields}
    }
  }
`;

export const GET_SECOND_HAND_COMPANIES = gql`
  query {
    getBrandCompanies {
      ${secondHandCompanyFields}
    }
  }
`;
