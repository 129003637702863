export type PromoCode = {
  id: number;
  code: string;
  discountType: DiscountTypeEnum;
  amount: number;
  threshold: number;
  validUntil: Date;
  active: boolean;
  uses: number;
  used: number;
  maximumUsesPerUser?: number;
};

export enum DiscountTypeEnum {
  FIXED = "FIXED",
  PERCENTAGE = "PERCENTAGE",
}
