import { ApolloError } from "@apollo/client";
import { useState } from "react";

import {
  UPDATE_COMPANY_KYC_DOCUMENTS,
  UpdateCompanyKYCDocumentsApiInput,
  UpdateCompanyKYCDocumentsApiResponse,
} from "~/api/graphql/kyc";
import { getMediaUploadPath } from "~/api/graphql/media/getMediaUploadPath";
import { CustomButton } from "~/components/form/CustomButton";
import { FilePicker } from "~/components/form/FilePicker";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import SVGContainer from "~/components/UI/SVGContainer";
import { colors } from "~/constants/styles";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import { UpdateCompanyKYCDocumentsInput } from "~/types/data/Company.types";
import {
  KYCDocumentNameEnum,
  KycDocumentStatusEnum,
} from "~/types/data/KYC.types";

import styles from "./index.module.scss";

interface DocumentStatusProps {
  status?: KycDocumentStatusEnum;
}

const DocumentStatus = ({ status }: DocumentStatusProps) => {
  return status === KycDocumentStatusEnum.VALIDATION_ASKED ? (
    <div
      className={`${styles.statusContainer} ${styles.validationAskedStatus}`}
    >
      <h5>Pending Validation</h5>
      <SVGContainer
        height="15px"
        width="15px"
        imagePath="/assets/clock-icon.svg"
      />
    </div>
  ) : status === KycDocumentStatusEnum.VALIDATED ? (
    <div className={`${styles.statusContainer} ${styles.validatedStatus}`}>
      <h5>Validated</h5>
    </div>
  ) : status === KycDocumentStatusEnum.OUT_OF_DATE ? (
    <div className={`${styles.statusContainer} ${styles.incompleteStatus}`}>
      <h5>Out of date</h5>
    </div>
  ) : status === KycDocumentStatusEnum.REFUSED ? (
    <div className={`${styles.statusContainer} ${styles.refusedStatus}`}>
      <h5>KYC refusé.</h5>
    </div>
  ) : (
    <div className={`${styles.statusContainer} ${styles.createdStatus}`}>
      <h5>En attente de soumission</h5>
    </div>
  );
};

export const KycImports = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNationalIdentityCard, setIsLoadingNationalIdentityCard] =
    useState(false);
  const [isLoadingKBIS, setIsLoadingKBIS] = useState(false);
  const [isLoadingStatutes, setIsLoadingStatutes] = useState(false);
  const [
    isLoadingShareholderStatement,
    setIsLoadingisLoadingShareholderStatement,
  ] = useState(false);
  const [
    nationalIdentityCardFieldChanged,
    setNationalIdentityCardFieldChanged,
  ] = useState(false);
  const [kbisfieldChanged, setKbisFieldChanged] = useState(false);
  const [statutesFieldChanged, setStatutesFieldChanged] = useState(false);
  const [
    shareholderStatementFieldChanged,
    setShareholderStatementFieldChanged,
  ] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { companyAccount, setCompanyAccount } = useCompanyAccountContext();

  const updateCompanyKYCDocumentsSuccessHandler = ({
    updateCompanyKYCDocuments,
  }: UpdateCompanyKYCDocumentsApiResponse) => {
    setSuccessMessage("KYC updated successfully.");
    setNationalIdentityCardFieldChanged(false);
    setKbisFieldChanged(false);
    setStatutesFieldChanged(false);
    setShareholderStatementFieldChanged(false);
    setCompanyAccount((prev) => {
      return {
        ...prev,
        kycDocuments: [...updateCompanyKYCDocuments, ...prev.kycDocuments],
      };
    });
  };

  const updateCompanyKYCDocumentsErrorHandler = (error: ApolloError) => {
    setErrorMessage(error.message);
  };

  const { trigger: updateCompanyKYCDocumentsTrigger } =
    useMutationWithCallbacks<
      UpdateCompanyKYCDocumentsApiResponse,
      UpdateCompanyKYCDocumentsApiInput
    >(
      UPDATE_COMPANY_KYC_DOCUMENTS,
      updateCompanyKYCDocumentsSuccessHandler,
      updateCompanyKYCDocumentsErrorHandler
    );

  const nationalIdentityCardDocument = companyAccount.kycDocuments.filter(
    (kycDocument) => kycDocument.name === KYCDocumentNameEnum.IDENTITY_CARD
  )[0];

  const kbisDocument = companyAccount.kycDocuments.filter(
    (kycDocument) => kycDocument.name === KYCDocumentNameEnum.KBIS
  )[0];

  const statutesDocument = companyAccount.kycDocuments.filter(
    (kycDocument) => kycDocument.name === KYCDocumentNameEnum.COMPANY_STATUTES
  )[0];

  const shareholderStatementDocument = companyAccount.kycDocuments.filter(
    (kycDocument) =>
      kycDocument.name === KYCDocumentNameEnum.SHAREHOLDER_STATEMENT
  )[0];

  const nationalIdentityCardDisabled =
    nationalIdentityCardDocument?.status === KycDocumentStatusEnum.VALIDATED ||
    nationalIdentityCardDocument?.status ===
      KycDocumentStatusEnum.VALIDATION_ASKED;
  const kbisDisabled =
    kbisDocument?.status === KycDocumentStatusEnum.VALIDATED ||
    kbisDocument?.status === KycDocumentStatusEnum.VALIDATION_ASKED;
  const statutesDisabled =
    statutesDocument?.status === KycDocumentStatusEnum.VALIDATED ||
    statutesDocument?.status === KycDocumentStatusEnum.VALIDATION_ASKED;
  const shareholderStatementDisabled =
    shareholderStatementDocument?.status === KycDocumentStatusEnum.VALIDATED ||
    shareholderStatementDocument?.status ===
      KycDocumentStatusEnum.VALIDATION_ASKED;

  const disabledButton =
    (nationalIdentityCardDisabled &&
      kbisDisabled &&
      statutesDisabled &&
      shareholderStatementDisabled) ||
    (!nationalIdentityCardFieldChanged &&
      !kbisfieldChanged &&
      !statutesFieldChanged &&
      !shareholderStatementFieldChanged);

  const disabledForm = !companyAccount.mangopayId;

  const fileUploadHandler = async (
    value: File | undefined,
    inputName:
      | "nationalIdentityCard"
      | "kbis"
      | "statutes"
      | "shareholderStatement",
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (value) {
      setSuccessMessage("");
      setErrorMessage("");
      setLoading(true);
      if (inputName === "nationalIdentityCard" && value.size < 32768) {
        setErrorMessage("File should be larger than 32KB");
        setLoading(false);
        return;
      }

      if (value.size < 1024) {
        setErrorMessage("File should be larger than 1KB");
        setLoading(false);
        return;
      }
      if (value.size > 7864320) {
        setErrorMessage("File should be smaller than 7.5MB");
        setLoading(false);
        return;
      }

      if (
        value.type.split("/")[1] !== "jpeg" &&
        value.type.split("/")[1] !== "jpg" &&
        value.type.split("/")[1] !== "png" &&
        value.type.split("/")[1] !== "pdf"
      ) {
        setErrorMessage("File format should be .pdf, .jpeg, .jpg or .png");
        setLoading(false);
        return;
      }
      const response = await getMediaUploadPath({
        field: inputName,
        filename: value.name,
      });
      if (response.success) {
        const { path, url } = response.data;
        await fetch(url, { method: "put", body: value });

        setCompanyAccount((prev) => {
          const mappedKYCDocumentName: KYCDocumentNameEnum =
            inputName === "nationalIdentityCard"
              ? KYCDocumentNameEnum.IDENTITY_CARD
              : inputName === "kbis"
              ? KYCDocumentNameEnum.KBIS
              : inputName === "statutes"
              ? KYCDocumentNameEnum.COMPANY_STATUTES
              : KYCDocumentNameEnum.SHAREHOLDER_STATEMENT;

          const updatedArray = [
            {
              name: mappedKYCDocumentName,
              path: path,
            },
            ...prev.kycDocuments,
          ];
          switch (inputName) {
            case "nationalIdentityCard":
              setNationalIdentityCardFieldChanged(true);
              break;
            case "kbis":
              setKbisFieldChanged(true);
              break;
            case "statutes":
              setStatutesFieldChanged(true);
              break;
            case "shareholderStatement":
              setShareholderStatementFieldChanged(true);
              break;
          }
          return { ...prev, kycDocuments: updatedArray };
        });
      }
      setLoading(false);
    }
  };

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");
    setIsLoading(true);

    const input: UpdateCompanyKYCDocumentsInput = {
      id: companyAccount.id,
      kycDocuments: [],
    };
    if (nationalIdentityCardFieldChanged) {
      input.kycDocuments.push(nationalIdentityCardDocument);
    }
    if (kbisfieldChanged) {
      input.kycDocuments.push(kbisDocument);
    }
    if (statutesFieldChanged) {
      input.kycDocuments.push(statutesDocument);
    }
    if (shareholderStatementFieldChanged) {
      input.kycDocuments.push(shareholderStatementDocument);
    }
    if (input.kycDocuments.length === 0) {
      setErrorMessage("Your changes are not valid");
      setIsLoading(false);
      return;
    }
    await updateCompanyKYCDocumentsTrigger({
      variables: { UpdateCompanyKYCDocumentsInput: input },
    });

    setIsLoading(false);
  };

  return (
    <form
      className="myAccountFormSection"
      onSubmit={(e) => {
        submitHandler(e);
      }}
    >
      <h3 className="title">Import des KYC</h3>

      <div className="doubleInputs">
        <div className={styles.filePickerContainer}>
          <FilePicker
            label="Carte Nationale d’Identité :"
            onChange={(value: File | undefined) => {
              fileUploadHandler(
                value,
                "nationalIdentityCard",
                setIsLoadingNationalIdentityCard
              );
            }}
            value={nationalIdentityCardDocument?.path}
            fontSize="14px"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            id="nationalIdentityCard"
            loading={isLoadingNationalIdentityCard}
            disabled={nationalIdentityCardDisabled || disabledForm}
            openLink={nationalIdentityCardDocument?.fullPath}
          />
          <DocumentStatus status={nationalIdentityCardDocument?.status} />
        </div>
        <div className={styles.filePickerContainer}>
          <FilePicker
            label="KBIS :"
            onChange={(value: File | undefined) => {
              fileUploadHandler(value, "kbis", setIsLoadingKBIS);
            }}
            value={kbisDocument?.path}
            fontSize="14px"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            id="kbis"
            loading={isLoadingKBIS}
            disabled={kbisDisabled || disabledForm}
            openLink={kbisDocument?.fullPath}
          />
          <DocumentStatus status={kbisDocument?.status} />
        </div>
      </div>
      <div className="doubleInputs">
        <div className={styles.filePickerContainer}>
          <FilePicker
            label="Statuts :"
            onChange={(value: File | undefined) => {
              fileUploadHandler(value, "statutes", setIsLoadingStatutes);
            }}
            value={statutesDocument?.path}
            fontSize="14px"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            id="statutes"
            loading={isLoadingStatutes}
            disabled={statutesDisabled || disabledForm}
            openLink={statutesDocument?.fullPath}
          />
          <DocumentStatus status={statutesDocument?.status} />
        </div>

        <div className={styles.filePickerContainer}>
          <FilePicker
            label="Shareholder déclaration :"
            onChange={(value: File | undefined) => {
              fileUploadHandler(
                value,
                "shareholderStatement",
                setIsLoadingisLoadingShareholderStatement
              );
            }}
            value={shareholderStatementDocument?.path}
            fontSize="14px"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            id="shareholderStatement"
            loading={isLoadingShareholderStatement}
            disabled={shareholderStatementDisabled || disabledForm}
            openLink={shareholderStatementDocument?.fullPath}
          />
          <DocumentStatus status={shareholderStatementDocument?.status} />
        </div>
      </div>
      <div className="messageContainer">
        {!!errorMessage && <p className="errorMessage">{errorMessage}</p>}
        {!!successMessage && <p>{successMessage}</p>}
      </div>
      <div className="buttonContainer">
        <CustomButton
          backgroundColor={colors.$primary}
          color="white"
          width="fit-content"
          borderRadius="8px"
          padding="1rem 1.5rem"
          type="submit"
          disabled={isLoading || disabledButton || disabledForm}
        >
          {isLoading ? <SimpleLoader size="size2" /> : "Enregistrer"}
        </CustomButton>
      </div>
    </form>
  );
};
