import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Attribute } from "~/types/data/Attribute.types";
import {
  SellerMapping,
  SellerMappingTypeEnum,
} from "~/types/data/SellerMapping.types";
import { getBestMatch } from "~/util/functions/matching";

import type { RootState } from "../store";

export interface MappedAttribute {
  originalName: string;
  mappedAttribute?: Attribute;
}

export type MappedAttributesState = MappedAttribute[];
const initialState: MappedAttributesState = [];

export const mappedAttributesSlice = createSlice({
  name: "mappedAttributes",
  initialState,
  reducers: {
    initializeMappedAttributes(
      state,
      action: PayloadAction<{
        attributesSheetValues: string[];
        sellerMappings: SellerMapping[];
        attributes: Attribute[];
      }>
    ) {
      const { attributes, attributesSheetValues, sellerMappings } =
        action.payload;
      const attributesNames = attributes
        .map(({ associatedNames }) => associatedNames)
        .reduce((a, b) => a.concat(b), []);

      const mappedAttributes: MappedAttributesState = [];

      for (const originalName of attributesSheetValues) {
        // find if there's an already mapped value
        const sellerMapping = sellerMappings.find(
          ({ originalValue, type }) =>
            type === SellerMappingTypeEnum.ATTRIBUTE &&
            originalValue === originalName
        );

        const attr = attributes.find(
          ({ id }) => id.toString() === sellerMapping?.mappedValue
        );
        if (sellerMapping) {
          mappedAttributes.push({
            originalName,
            mappedAttribute: attr,
          });
          continue;
        }
        const bestMatch = getBestMatch(originalName, attributesNames);
        if (bestMatch) {
          const mappedAttribute = attributes.find(({ associatedNames }) =>
            associatedNames.includes(bestMatch)
          );
          mappedAttributes.push({
            originalName,
            mappedAttribute,
          });
        } else {
          mappedAttributes.push({
            originalName,
            mappedAttribute: undefined,
          });
        }
      }
      return mappedAttributes;
    },
    onMappingChange(
      state,
      action: PayloadAction<{
        index: number;
        mappedAttribute?: Attribute;
      }>
    ) {
      const { index, mappedAttribute } = action.payload;
      state[index] = { ...state[index], mappedAttribute };
    },
  },
});

export const { initializeMappedAttributes, onMappingChange } =
  mappedAttributesSlice.actions;

export const selectMappedAttributesState = (state: RootState) => {
  return state.mappedAttributes;
};
export default mappedAttributesSlice.reducer;
