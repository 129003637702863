import { ApolloError, useMutation } from "@apollo/client";
import { useState } from "react";

import {
  UPDATE_SUBSCRIPTION_FORMULAS,
  UpdateSubscriptionFormulasInput,
} from "~/api/graphql/subscriptionPackage";
import { CustomButton } from "~/components/form/CustomButton";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { Formula } from "~/types/data/Formula.type";
import { SubscriptionPackage } from "~/types/data/SubscriptionPackage.type";
import { updateSubscriptionFormulasValidationSchema } from "~/util/validation/brandCompany/subscriptionFormula.schema";

import { FormulaCard } from "./FormulaCard";
import styles from "./index.module.scss";

interface Props {
  subscriptionPackage: SubscriptionPackage;
  disabled: boolean;
}

export const FormulasSection = ({ subscriptionPackage, disabled }: Props) => {
  const [formulas, setFormulas] = useState<Formula[]>(
    subscriptionPackage.formulas
  );

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [formUpdated, setFormUpdated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { setCompanyAccount } = useCompanyAccountContext();

  const updateSubscriptionFormulasErrorHandler = (error: ApolloError) => {
    setErrorMessage(error.message);
  };

  const updateSubscriptionFormulasSuccessHandler = () => {
    setFormUpdated(false);
    setCompanyAccount((prev) => {
      return {
        ...prev,
        ...(prev.brandCompany && {
          brandCompany: {
            ...prev.brandCompany,
            subscriptions: prev.brandCompany.subscriptions.map(
              (subscription) => {
                if (subscription.id === subscriptionPackage.id) {
                  const startingPrice = formulas.reduce((acc, formula) => {
                    if (formula.price < acc) {
                      return formula.price;
                    }
                    return acc;
                  }, formulas[0].price);

                  return {
                    ...subscription,
                    startingPrice,
                  };
                }

                return subscription;
              }
            ),
          },
        }),
      };
    });
  };

  const [updateSubscriptionFormulas] = useMutation<
    undefined,
    UpdateSubscriptionFormulasInput
  >(UPDATE_SUBSCRIPTION_FORMULAS);

  const inputChangeHandler = <T extends keyof Formula>(
    inputName: T,
    changes: Formula[T],
    index: number
  ) => {
    if (!formUpdated) setFormUpdated(true);
    if (inputName === "popular" && changes === true) {
      const newFormulas = [...formulas].map((formula, fomrulaIndex) => {
        if (fomrulaIndex === index) {
          return { ...formula, popular: true };
        }
        return { ...formula, popular: false };
      });

      setFormulas(newFormulas);
      return;
    }
    const newFormulas = [...formulas];
    newFormulas[index] = { ...newFormulas[index], [inputName]: changes };
    setFormulas(newFormulas);
  };

  const submitHandler = async () => {
    setIsLoading(true);
    setErrorMessage("");
    const result = updateSubscriptionFormulasValidationSchema.validate({
      formulas: formulas.map(({ popular: _, ...data }) => {
        return data;
      }),
      subscriptionId: subscriptionPackage.id,
    });

    const { error } = result;
    if (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
      return;
    }

    await updateSubscriptionFormulas({
      variables: {
        UpdateSubscriptionFormulasInput: {
          formulas,
          subscriptionId: subscriptionPackage.id,
        },
      },
      onCompleted: updateSubscriptionFormulasSuccessHandler,
      onError: updateSubscriptionFormulasErrorHandler,
    });
    setIsLoading(false);
  };

  return (
    <div className={styles.container}>
      <h3>Formulas</h3>
      <div className={styles.cardsContainer}>
        {formulas.map((formula, index) => {
          return (
            <FormulaCard
              formula={formula}
              key={index}
              inputChangeHandler={inputChangeHandler}
              index={index}
              disabled={disabled}
            />
          );
        })}
      </div>
      {!disabled && (
        <>
          {errorMessage && (
            <div className={styles.errorMessage}>
              <p>{errorMessage}</p>
            </div>
          )}
          <div className={styles.buttonContainer}>
            <CustomButton
              backgroundColor={colors.$primary}
              color="white"
              width="fit-content"
              borderRadius="8px"
              padding="1rem 1.5rem"
              type="submit"
              disabled={isLoading || !formUpdated}
              onClick={() => {
                submitHandler();
              }}
            >
              {isLoading ? <SimpleLoader size="size2" /> : "Enregistrer"}
            </CustomButton>
          </div>
        </>
      )}
    </div>
  );
};
