import { useMutation } from "@apollo/client";
import { useState } from "react";
import { SingleValue } from "react-select";

import {
  UPDATE_ALL_PRODUCTS_HIGHLIGHT,
  UPDATE_ALL_PRODUCTS_STATUS,
  UPDATE_PRODUCTS_HIGHLIGHT,
  UPDATE_PRODUCTS_STATUS,
  UpdateAllProductsHighlightInput,
  UpdateAllProductsHighlightResponse,
  UpdateAllProductsStatusInput,
  UpdateAllProductsStatusResponse,
  UpdateProductsHighlightInput,
  UpdateProductsHighlightResponse,
  UpdateProductsStatusInput,
  UpdateProductsStatusResponse,
} from "~/api/graphql/product";
import { CustomButton } from "~/components/form/CustomButton";
import { MultipleSelectInput } from "~/components/form/MultipleSelectInput";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import SVGContainer from "~/components/UI/SVGContainer";
import { colors } from "~/constants/styles";
import { useAppSelector } from "~/redux/hooks";
import { selectCatalogueFilterArgs } from "~/redux/slice/catalogueFilter.slice";
import {
  Product,
  ProductHighlightEnum,
  ProductStatusEnum,
} from "~/types/data/Product.types";

import styles from "./index.module.scss";

const statusModes: ProductStatusEnum[] = [
  ProductStatusEnum.PUBLISHED,
  ProductStatusEnum.INCOMPLETE,
  ProductStatusEnum.MODIFICATION_REQUESTED,
  ProductStatusEnum.REFUSED,
  ProductStatusEnum.WAITING_FOR_VALIDATION,
];

interface Props {
  selectedProducts: Product[];
  exportProductsHandler: () => void;
  deleteProductsHandler: () => void;
  fetchProductVariants: (init: boolean) => Promise<void>;
  isSelectedAll: boolean;
  productsCount: number;
}

export const ProductsActionsModal = ({
  selectedProducts,
  deleteProductsHandler,
  exportProductsHandler,
  fetchProductVariants,
  isSelectedAll,
  productsCount,
}: Props) => {
  const [_, setErrorMessage] = useState<string>("");
  const [isChangeStatusLoading, setIsChangeStatusLoading] = useState(false);
  const [isChangeHighlightLoading, setIsChangeHighlightLoading] =
    useState(false);
  const [selectedStatusMode, setSelectedStatusMode] =
    useState<ProductStatusEnum>();
  const [selectedHighlightMode, setSelectedHighlightMode] = useState<
    ProductHighlightEnum[]
  >([]);

  const filterArgsState = useAppSelector(selectCatalogueFilterArgs);

  const chooseStatusModeHandler = (statusMode: ProductStatusEnum) => {
    setSelectedStatusMode(statusMode);
  };

  const statusModesOptions: OptionType[] = statusModes.map((statusMode) => {
    return {
      label: statusMode,
      value: statusMode,
    };
  });

  const productHighlightOptions: OptionType[] = [
    {
      label: "Reedoo favorite",
      value: ProductHighlightEnum.IS_REEDOO_FAVORITE,
    },
    {
      label: "Spotlighted",
      value: ProductHighlightEnum.SPOTLIGHTED,
    },
  ];

  const [updateProductsStatusTrigger] = useMutation<
    UpdateProductsStatusResponse,
    UpdateProductsStatusInput
  >(UPDATE_PRODUCTS_STATUS);

  const [updateAllProductsStatusTrigger] = useMutation<
    UpdateAllProductsStatusResponse,
    UpdateAllProductsStatusInput
  >(UPDATE_ALL_PRODUCTS_STATUS);

  const [updateProductsHighlightTrigger] = useMutation<
    UpdateProductsHighlightResponse,
    UpdateProductsHighlightInput
  >(UPDATE_PRODUCTS_HIGHLIGHT);

  const [updateAllProductsHighlightTrigger] = useMutation<
    UpdateAllProductsHighlightResponse,
    UpdateAllProductsHighlightInput
  >(UPDATE_ALL_PRODUCTS_HIGHLIGHT);

  const applyChangeStatusHandler = async () => {
    setIsChangeStatusLoading(true);
    setErrorMessage("");
    if (!selectedStatusMode) {
      setErrorMessage("Please choose a status mode");
      setIsChangeStatusLoading(false);
      return;
    }

    if (isSelectedAll) {
      await updateAllProductsStatusTrigger({
        variables: {
          UpdateAllProductsStatusInput: {
            status: selectedStatusMode,
            filterArgs: filterArgsState,
          },
        },
        onCompleted: () => {
          fetchProductVariants(true);
          setIsChangeStatusLoading(false);
        },
        onError: (error) => {
          setErrorMessage(error.message);
        },
      });
      return;
    }
    await updateProductsStatusTrigger({
      variables: {
        UpdateProductsStatusInput: {
          productsIds: selectedProducts.map((product) => product.id ?? 0),
          status: selectedStatusMode,
        },
      },
      onCompleted: () => {
        fetchProductVariants(true);
        setIsChangeStatusLoading(false);
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
    setIsChangeStatusLoading(false);
  };

  const applyChangeHighlightHandler = async () => {
    setIsChangeHighlightLoading(true);
    setErrorMessage("");

    if (isSelectedAll) {
      await updateAllProductsHighlightTrigger({
        variables: {
          UpdateAllProductsHighlightInput: {
            highlight: selectedHighlightMode,
            filterArgs: filterArgsState,
          },
        },
        onCompleted: () => {
          fetchProductVariants(true);
          setIsChangeHighlightLoading(false);
        },
        onError: (error) => {
          setErrorMessage(error.message);
        },
      });
      return;
    }
    await updateProductsHighlightTrigger({
      variables: {
        UpdateProductsHighlightInput: {
          productsIds: selectedProducts.map((product) => product.id ?? 0),
          highlight: selectedHighlightMode,
        },
      },
      onCompleted: () => {
        fetchProductVariants(true);
        setIsChangeHighlightLoading(false);
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
    setIsChangeHighlightLoading(false);
  };

  return (
    <div className={styles.container}>
      <h3>Actions</h3>
      <div className={styles.detailsContainer}>
        <h4>
          Actions for {isSelectedAll ? productsCount : selectedProducts.length}{" "}
          Products
        </h4>
        <div className={styles.actionsContainer}>
          <div
            className={`${styles.actionButton}`}
            onClick={exportProductsHandler}
          >
            <SVGContainer
              height="16px"
              width="17px"
              imagePath="/assets/export-icon.svg"
            />
            <p>Exporter</p>
          </div>
          <div
            className={`${styles.actionButton}`}
            onClick={() => {
              deleteProductsHandler();
            }}
          >
            <SVGContainer
              height="16px"
              width="17px"
              imagePath="/assets/delete-icon.svg"
            />
            <p>supprimer</p>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <SelectInput
            fontSize="12px"
            backgroundColor="white"
            options={statusModesOptions}
            onChange={(option: SingleValue<OptionType>) => {
              chooseStatusModeHandler(option?.value as ProductStatusEnum);
            }}
            value={selectedStatusMode || ""}
            hasDefault={true}
            defaultLabel="Not selected"
            label="Change status to"
          />
          <CustomButton
            backgroundColor={colors.$primary}
            color="white"
            borderRadius="7px"
            height="2.3rem"
            width="6.5rem"
            onClick={() => {
              applyChangeStatusHandler();
            }}
          >
            {isChangeStatusLoading ? <SimpleLoader size="size1" /> : "Apply"}
          </CustomButton>
        </div>
        <div className={styles.inputContainer}>
          <MultipleSelectInput
            fontSize="12px"
            backgroundColor="white"
            options={productHighlightOptions}
            onChange={(options: OptionType[]) => {
              setSelectedHighlightMode(
                options.map((option) => option.value as ProductHighlightEnum)
              );
            }}
            value={productHighlightOptions.filter((option) =>
              selectedHighlightMode.includes(
                option.value as ProductHighlightEnum
              )
            )}
            label="Product highlight"
          />
          <CustomButton
            backgroundColor={colors.$primary}
            color="white"
            borderRadius="7px"
            height="2.3rem"
            width="6.5rem"
            onClick={() => {
              applyChangeHighlightHandler();
            }}
          >
            {isChangeHighlightLoading ? <SimpleLoader size="size1" /> : "Apply"}
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
