import { useMutation } from "@apollo/client";
import { useState } from "react";

import {
  CollectionState,
  UPSERT_COLLECTION,
  UpsertCollectionInput,
  UpsertCollectionResponse,
} from "~/api/graphql/Collection";
import { CustomButton } from "~/components/form/CustomButton";
import CustomCheckbox from "~/components/form/CustomCheckbox";
import { CustomInput } from "~/components/form/CustomInput";
import { OptionType } from "~/components/form/SelectInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { countryList } from "~/constants/countries";
import { colors } from "~/constants/styles";
import { CollectionModel } from "~/types/data/Collection.type";

import styles from "./index.module.scss";

interface Props {
  oldCollection?: CollectionModel;
  closeModal: () => void;
  onSuccess: (collection: CollectionModel) => void;
}

export const CollectionModal = ({
  closeModal,
  oldCollection,
  onSuccess,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [collectionState, setCollectionState] = useState<CollectionState>({
    id: oldCollection?.id,
    name: oldCollection?.name,
    enabled: oldCollection?.enabled,
    position: oldCollection?.position,
    description: oldCollection?.description,
  });

  const [upsertCollection] = useMutation<
    UpsertCollectionResponse,
    UpsertCollectionInput
  >(UPSERT_COLLECTION);

  const inputChangeHandler = <T extends keyof CollectionState>(
    inputName: T,
    changes: CollectionState[T]
  ) => {
    setCollectionState((prevState) => ({
      ...prevState,
      [inputName]: changes,
    }));
  };

  const upsertCollectionHandler = async () => {
    setIsLoading(true);
    await upsertCollection({
      variables: {
        UpsertCollectionInput: collectionState,
      },
      onCompleted: (data) => {
        onSuccess(data.upsertCollection);
        closeModal();
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
    setIsLoading(false);
  };

  const countryOptions: OptionType[] = countryList.map(({ name, alpha2 }) => {
    return { label: name, value: alpha2.toUpperCase() };
  });

  return (
    <div className={styles.container}>
      <div className={`${styles.doubleInputs}`}>
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("name", value.toString());
          }}
          value={collectionState.name}
          label="Name"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="12px"
          required
        />
      </div>

      <div className={`${styles.doubleInputs}`}>
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("description", value.toString());
          }}
          value={collectionState.description}
          label="Description"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="12px"
        />
      </div>
      <div className={`${styles.doubleInputs}`}>
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("position", +value);
          }}
          value={collectionState.position}
          label="Position"
          type="number"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="12px"
        />
        <CustomCheckbox
          onChange={(e) => {
            inputChangeHandler("enabled", e.target.checked);
          }}
          checked={collectionState.enabled}
          label="Enabled"
        />
      </div>

      {errorMessage && (
        <div className={styles.error}>
          <p>{errorMessage}</p>
        </div>
      )}
      <div className={styles.buttonsContainer}>
        <CustomButton
          width="9rem"
          backgroundColor={colors.$primary}
          height="2rem"
          borderRadius="0.5rem"
          color="white"
          disabled={isLoading || isLoading}
          onClick={() => {
            upsertCollectionHandler();
          }}
        >
          {isLoading ? (
            <SimpleLoader size="size1" />
          ) : collectionState.id ? (
            "Update"
          ) : (
            "Create"
          )}
        </CustomButton>
      </div>
    </div>
  );
};
