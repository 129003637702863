import React from "react";
import { NavLink } from "react-router-dom";

import SVGContainer from "~/components/UI/SVGContainer";

import styles from "./index.module.scss";

interface Props {
  number: number;
  footerText?: string;
  currency: boolean;
  route?: string;
}

const NumberCard = ({
  number,
  footerText,
  currency,
  route,
}: React.PropsWithChildren<Props>) => {
  const formattedNumber = currency
    ? new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
      }).format(number)
    : number;
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.content}`}>
        <p>{formattedNumber}</p>
      </div>
      {!!footerText && (
        <div className={`${styles.footer}`}>
          <NavLink to={route ?? ""}>
            <SVGContainer
              width="6px"
              height="10px"
              imagePath="/assets/right-angle-bracket.svg"
            />
            {footerText}
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default NumberCard;
