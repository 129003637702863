import styles from "./index.module.scss";

interface Props {
  title: string;
}

export const Title = ({ title }: Props) => {
  return (
    <div className={styles.titleContainer}>
      <h1>{title}</h1>
    </div>
  );
};
