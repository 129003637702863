import { gql } from "@apollo/client";

import {
  graphqlGetDashboardDataFields,
  graphqlGetRentDashboardDataFields,
  graphqlGetSaleDashboardDataFields,
} from "~/constants/graphql";
import {
  DashboardData,
  RentDashboardData,
  SaleDashboardData,
} from "~/types/data/DashboardData.types";

export interface GetDashboardDataResponse {
  getDashboardData: DashboardData;
}

export interface GetSaleDashboardDataResponse {
  getSaleDashboardData: SaleDashboardData;
}

export interface GetRentDashboardDataResponse {
  getRentDashboardData: RentDashboardData;
}

export interface GetDashboardDataInput {
  GetDashboardDataInput: { from?: string; to?: string };
}

const dashboardDataFields = graphqlGetDashboardDataFields.join(" ");
const saleDashboardDataFields = graphqlGetSaleDashboardDataFields.join(" ");
const rentDashboardDataFields = graphqlGetRentDashboardDataFields.join(" ");

export const GET_DASHBOARD_DATA = gql`
  query ($GetDashboardDataInput: GetDashboardDataInput!) {
    getDashboardData(GetDashboardDataInput: $GetDashboardDataInput) {
      ${dashboardDataFields}
    }
  }
`;

export const GET_SALE_DASHBOARD_DATA = gql`
  query ($GetDashboardDataInput: GetDashboardDataInput!) {
    getSaleDashboardData(GetDashboardDataInput: $GetDashboardDataInput) {
      ${saleDashboardDataFields}
    }
  }
`;

export const GET_RENT_DASHBOARD_DATA = gql`
  query ($GetDashboardDataInput: GetDashboardDataInput!) {
    getRentDashboardData(GetDashboardDataInput: $GetDashboardDataInput) {
      ${rentDashboardDataFields}
    }
  }
`;
