import { useState } from "react";

import CustomCheckbox from "~/components/form/CustomCheckbox";
import {
  DeliveryAreaState,
  DeliveryDetailsState,
} from "~/types/data/Carrier.types";

import { DeliveryAreaKey, DeliveryMethodKey } from "..";
import { DeliveryAreaSection } from "../DeliveryAreaSection";
import styles from "./index.module.scss";

interface Props {
  stateKey: string;
  codeIndex: number;
  methodKey: DeliveryMethodKey;
  deliveryAreaState: DeliveryAreaState;
  clearDeliveryAreaHandler: (
    stateKey: string,
    codeIndex: number,
    method: DeliveryMethodKey,
    area: DeliveryAreaKey
  ) => void;
  addDeliveryDetailsStateHandler: (
    stateKey: string,
    codeIndex: number,
    method: DeliveryMethodKey,
    area: DeliveryAreaKey
  ) => void;
  deliveryPackageChangeHandler: (
    stateKey: string,
    codeIndex: number,
    type: DeliveryMethodKey,
    area: DeliveryAreaKey,
    changes: DeliveryDetailsState
  ) => void;
}
interface DeliveryAreasChecklist {
  france: boolean;
  corse: boolean;
  monaco: boolean;
}

export const DeliveryMethodSection = ({
  stateKey,
  codeIndex,
  methodKey,
  deliveryAreaState,
  clearDeliveryAreaHandler,
  deliveryPackageChangeHandler,
  addDeliveryDetailsStateHandler,
}: Props) => {
  const initDeliveryAreasChecklist: DeliveryAreasChecklist = {
    france: !!deliveryAreaState.france,
    corse: !!deliveryAreaState.corse,
    monaco: !!deliveryAreaState.monaco,
  };

  const [deliveryAreasChecklist, setDeliveryAreasChecklist] =
    useState<DeliveryAreasChecklist>(initDeliveryAreasChecklist);

  const checkboxClickHandler = (
    deliveryArea: "france" | "corse" | "monaco"
  ) => {
    if (deliveryAreasChecklist.france && deliveryArea === "france") {
      clearDeliveryAreaHandler(stateKey, codeIndex, methodKey, "france");
    }
    if (deliveryAreasChecklist.corse && deliveryArea === "corse") {
      clearDeliveryAreaHandler(stateKey, codeIndex, methodKey, "corse");
    }
    if (deliveryAreasChecklist.monaco && deliveryArea === "monaco") {
      clearDeliveryAreaHandler(stateKey, codeIndex, methodKey, "monaco");
    }

    if (!deliveryAreasChecklist.france && deliveryArea === "france") {
      addDeliveryDetailsStateHandler(stateKey, codeIndex, methodKey, "france");
    }
    if (!deliveryAreasChecklist.corse && deliveryArea === "corse") {
      addDeliveryDetailsStateHandler(stateKey, codeIndex, methodKey, "corse");
    }
    if (!deliveryAreasChecklist.monaco && deliveryArea === "monaco") {
      addDeliveryDetailsStateHandler(stateKey, codeIndex, methodKey, "monaco");
    }

    setDeliveryAreasChecklist((prev) => {
      return { ...prev, [deliveryArea]: !prev[deliveryArea] };
    });
  };

  return (
    <div className={`${styles.deliveryAreasContainer}`}>
      <div>
        <CustomCheckbox
          label="France"
          checked={deliveryAreasChecklist.france}
          onChange={() => {
            checkboxClickHandler("france");
          }}
        />
      </div>
      {deliveryAreasChecklist.france && (
        <DeliveryAreaSection
          stateKey={stateKey}
          codeIndex={codeIndex}
          methodKey={methodKey}
          areaKey="france"
          deliveryDetailsState={deliveryAreaState.france}
          deliveryPackageChangeHandler={deliveryPackageChangeHandler}
        />
      )}
      <div>
        <CustomCheckbox
          label="Corse"
          checked={deliveryAreasChecklist.corse}
          onChange={() => {
            checkboxClickHandler("corse");
          }}
        />
      </div>

      {deliveryAreasChecklist.corse && (
        <DeliveryAreaSection
          stateKey={stateKey}
          codeIndex={codeIndex}
          methodKey={methodKey}
          areaKey="corse"
          deliveryDetailsState={deliveryAreaState.corse}
          deliveryPackageChangeHandler={deliveryPackageChangeHandler}
        />
      )}

      <div>
        <CustomCheckbox
          label="Monaco"
          checked={deliveryAreasChecklist.monaco}
          onChange={() => {
            checkboxClickHandler("monaco");
          }}
        />
      </div>
      {deliveryAreasChecklist.monaco && (
        <DeliveryAreaSection
          stateKey={stateKey}
          codeIndex={codeIndex}
          methodKey={methodKey}
          areaKey="monaco"
          deliveryDetailsState={deliveryAreaState.monaco}
          deliveryPackageChangeHandler={deliveryPackageChangeHandler}
        />
      )}
    </div>
  );
};
