import { gql } from "@apollo/client";

import { graphqlGetStatisticsReportFields } from "~/constants/graphql";
import {
  StatisticsReport,
  StatisticsReportTypeEnum,
} from "~/types/data/StatisticsReport.type";

export interface GenerateStatisticsReportResponse {
  generateStatisticsReport: StatisticsReport;
}

export interface GenerateStatisticsReportInput {
  GenerateStatisticsReportInput: {
    brandId: number;
    type: StatisticsReportTypeEnum;
    monthReportMonth?: number;
    monthReportYear?: number;
    yearReportYear?: number;
    startDate?: string;
    endDate?: string;
  };
}

const statisticsReportFields = graphqlGetStatisticsReportFields.join(" ");

export const GENERATE_STATISTICS_REPORT = gql`
  mutation ($GenerateStatisticsReportInput: GenerateStatisticsReportInput!){
    generateStatisticsReport (GenerateStatisticsReportInput: $GenerateStatisticsReportInput) {
      ${statisticsReportFields}

      reportFullPath
    }
  }
`;
