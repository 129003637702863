import {
  Product,
  RentingPeriodEnum,
  SellingTypeEnum,
} from "~/types/data/Product.types";

export const sellingTypeValueMap = (value: SellingTypeEnum) => {
  if (value === SellingTypeEnum.NEW) {
    return "Vente";
  } else if (value === SellingTypeEnum.RENT) {
    return "Location";
  } else if (value === SellingTypeEnum.SECOND_HAND) {
    return "Second hand";
  } else {
    return "";
  }
};

export const getRentingPeriod = (product: Product) => {
  if (product.rentingPeriod) return product.rentingPeriod;
  const rentingPeriod: RentingPeriodEnum[] = [];
  if (product.dailyRentPrice) rentingPeriod.push(RentingPeriodEnum.DAILY);
  if (product.weeklyRentPrice) rentingPeriod.push(RentingPeriodEnum.WEEKLY);
  if (product.monthlyRentPrice) rentingPeriod.push(RentingPeriodEnum.MONTHLY);
  return rentingPeriod;
};
