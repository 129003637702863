import { PermissionLevelEnum } from "~/types/data/User.types";

export const userPermissionLevelToString = (
  permissionLevel: PermissionLevelEnum
) => {
  switch (permissionLevel) {
    case PermissionLevelEnum.ADMIN:
      return "Administration";
    case PermissionLevelEnum.MEMBER:
      return "Contribution";
  }
};
