import { sendRequest } from "~/api/clients/axios";
import { AxiosHttpError } from "~/types/common/error.types";
import {
  ShopifyAddStoreRequest,
  ShopifyAddStoreResponse,
} from "~/types/data/ShopifyAddStore.types";

export default async function addShopifyStoreRequest(
  input: ShopifyAddStoreRequest
): Promise<{
  message?: string;
  error?: string;
}> {
  const { shop, ...data } = input;
  //removing the protocol and the path from shop name
  const finalShopName = shop.includes("https://")
    ? shop.replace("https://", "").split("/")[0]
    : shop.split("/")[0];
  try {
    const response = await sendRequest<
      ShopifyAddStoreRequest,
      ShopifyAddStoreResponse
    >("post", "shopify/addShop/", {
      shop: finalShopName,
      ...data,
    });
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosHttpError;
    if (axiosError.response?.data) {
      const { message } = axiosError.response.data;
      return {
        error: typeof message === "string" ? message : message[0],
      };
    }
    return {
      error: "Something went wrong",
    };
  }
}
