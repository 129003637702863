import { sendRequest } from "~/api/clients/axios";
import { AxiosHttpError } from "~/types/common/error.types";
import { Product } from "~/types/data/Product.types";
import {
  SynchronizeShopifyMappingsRequest,
  SynchronizeShopifyMappingsResponse,
} from "~/types/data/SynchronizeShopifyMappings.types";

export default async function updateShopifyProductsRequest(
  shopId: number
): Promise<{
  updatedProducts?: Product[];
  error?: string;
}> {
  try {
    const response = await sendRequest<
      SynchronizeShopifyMappingsRequest,
      SynchronizeShopifyMappingsResponse
    >("post", "shopify/updateProducts/", {
      shopId,
    });
    return { updatedProducts: response.data };
  } catch (err) {
    const axiosError = err as AxiosHttpError;
    if (axiosError.response?.data) {
      const { message } = axiosError.response.data;
      return {
        error: typeof message === "string" ? message : message[0],
      };
    }
    return {
      error: "Something went wrong",
    };
  }
}
