import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { Attribute } from "~/types/data/Attribute.types";
import { ForeignShopMapping } from "~/types/data/ForeignShopMapping.types";
import { SellerMappingTypeEnum } from "~/types/data/SellerMapping.types";
import { VendorCategory } from "~/types/data/VendorCategory.types";
import { getBestCategoryMatch } from "~/util/functions/getCategoriesMatch";
import { getBestMatch } from "~/util/functions/matching";

import { RootState } from "../store";

interface ShopMappedAttributesState {
  mappedCategories: ForeignShopMapping[];
  mappedOptions: ForeignShopMapping[];
  categoriesFinished: boolean;
  optionsFinished: boolean;
  optionValuesFinished: boolean;
  isGuessing: boolean;
}

const initialState = {
  mappedCategories: [],
  mappedOptions: [],
  categoriesFinished: false,
  optionsFinished: false,
  optionValuesFinished: false,
  isGuessing: false,
} as ShopMappedAttributesState;

const isFinished = (
  shopMappings: ForeignShopMapping[],
  type?: SellerMappingTypeEnum
) => {
  for (const mapping of shopMappings) {
    if (type === SellerMappingTypeEnum.ATTRIBUTE_VALUE) {
      for (const child of mapping.children)
        if (!child.mappedValue) return false;
    } else if (!mapping.mappedValue) return false;
  }
  return true;
};

const foreignShopMappingSlice = createSlice({
  name: "shopMappedAttributesState",
  initialState,
  reducers: {
    initialize(
      state,
      action: PayloadAction<{
        mappedCategories: ForeignShopMapping[];
        mappedOptions: ForeignShopMapping[];
      }>
    ) {
      state.mappedCategories = [...action.payload.mappedCategories];
      state.mappedOptions = [...action.payload.mappedOptions];
      state.categoriesFinished = isFinished(state.mappedCategories);
      state.optionsFinished = isFinished(state.mappedOptions);
      state.optionValuesFinished = isFinished(
        state.mappedOptions,
        SellerMappingTypeEnum.ATTRIBUTE_VALUE
      );
    },
    updateOne(
      state,
      action: PayloadAction<{
        id: number;
        mappedValue: string;
        type: SellerMappingTypeEnum;
      }>
    ) {
      const { id, mappedValue, type } = action.payload;
      if (type === SellerMappingTypeEnum.CATEGORY) {
        const index = state.mappedCategories.findIndex(
          (mappedAttribute) => mappedAttribute.id === id
        );
        state.mappedCategories[index].mappedValue = mappedValue;
        state.categoriesFinished = isFinished(state.mappedCategories);
      }
      if (type === SellerMappingTypeEnum.ATTRIBUTE) {
        const index = state.mappedOptions.findIndex(
          (mappedAttribute) => mappedAttribute.id === id
        );
        state.mappedOptions[index].mappedValue =
          mappedValue == "Not selected" ? "" : mappedValue;

        for (const child of state.mappedOptions[index].children) {
          child.mappedValue = undefined;
        }

        state.optionsFinished = isFinished(state.mappedOptions);
        state.optionValuesFinished = isFinished(
          state.mappedOptions,
          SellerMappingTypeEnum.ATTRIBUTE_VALUE
        );
      }
    },
    updateChild(
      state,
      action: PayloadAction<{
        id: number;
        parentId: number;
        mappedValue: string;
      }>
    ) {
      const { id, mappedValue, parentId } = action.payload;
      const index = state.mappedOptions.findIndex((mappedAttribute) => {
        return mappedAttribute.id === parentId;
      });
      if (index == -1) return;
      const childIndex = state.mappedOptions[index].children.findIndex(
        (mappedAttribute) => mappedAttribute.id === id
      );
      state.mappedOptions[index].children[childIndex].mappedValue =
        mappedValue == "Not selected" ? "" : mappedValue;
      state.optionValuesFinished = isFinished(
        state.mappedOptions,
        SellerMappingTypeEnum.ATTRIBUTE_VALUE
      );
    },
    guessMappings(
      state,
      action: PayloadAction<{
        attributes: Attribute[];
        vendorCategories: VendorCategory[];
      }>
    ) {
      const { attributes, vendorCategories } = action.payload;
      //categories mapping
      for (const category of state.mappedCategories) {
        const bestCategoryMatch = getBestCategoryMatch(
          vendorCategories,
          category.originalValue
        );
        category.mappedValue = bestCategoryMatch?.id.toString();
      }
      state.categoriesFinished = isFinished(state.mappedCategories);

      //options and optionValues mapping

      //extract associated names from all attributes
      const attributesNames = attributes
        .map(({ associatedNames }) => associatedNames)
        .reduce((a, b) => a.concat(b), []);

      for (const attribute of attributes) {
        attributesNames.concat(attribute.associatedNames);
      }

      for (const option of state.mappedOptions) {
        const bestMatch = getBestMatch(option.originalValue, attributesNames);

        if (!bestMatch) {
          option.mappedValue = undefined;
          for (const child of option.children) {
            child.mappedValue = undefined;
          }
        }
        if (bestMatch) {
          //getting mappedAttribute as its values needed for further guessing
          const mappedAttribute = attributes.find(({ associatedNames }) =>
            associatedNames.includes(bestMatch)
          );
          option.mappedValue = mappedAttribute?.name;

          //extract associated names from all attribute values
          const attributeValueNames: string[] = (mappedAttribute?.values ?? [])
            .map(({ associatedValues }) => associatedValues)
            .reduce((a, b) => a.concat(b), []);
          //guess each value and update it with guessed value
          for (const optionValue of option.children) {
            const bestValueMatch = getBestMatch(
              optionValue.originalValue,
              attributeValueNames
            );
            if (!bestValueMatch) {
              optionValue.mappedValue = undefined;
              continue;
            }
            const mappedAttributeValue = mappedAttribute?.values?.find((v) =>
              v.associatedValues.includes(bestValueMatch)
            );
            optionValue.mappedValue = mappedAttributeValue?.value;
          }
        }
      }
      state.optionsFinished = isFinished(state.mappedOptions);
      state.optionValuesFinished = isFinished(
        state.mappedOptions,
        SellerMappingTypeEnum.ATTRIBUTE_VALUE
      );
      state.isGuessing = !state.isGuessing;
    },
  },
});

export const { initialize, updateOne, updateChild, guessMappings } =
  foreignShopMappingSlice.actions;

export const foreignShopMappingState = (state: RootState) => {
  return state.foreignShopMappingSlice;
};

export default foreignShopMappingSlice.reducer;
