import {
  Product,
  RentingPeriodEnum,
  SellingTypeEnum,
} from "~/types/data/Product.types";

export type ValidateProductState = {
  [key in keyof Product]: boolean;
};

export const numberCheck = (input: {
  value?: number | null;
  required?: boolean;
  isInteger?: boolean;
  lessThan?: number;
  moreThan?: number;
  lessThanOrEqual?: boolean;
  moreThanOrEqual?: boolean;
}) => {
  const {
    isInteger,
    lessThan,
    moreThan,
    required,
    lessThanOrEqual = true,
    moreThanOrEqual = true,
    value,
  } = input;

  if (required && !value && value !== 0) return false;

  if (value === undefined || value === null) return true;

  if (isNaN(value)) return false;

  if (isInteger && !Number.isInteger(value)) return false;

  if (
    typeof lessThan === "number" &&
    !(lessThanOrEqual ? value <= lessThan : value < lessThan)
  )
    return false;

  if (
    typeof moreThan === "number" &&
    !(moreThanOrEqual ? value >= moreThan : value > moreThan)
  )
    return false;

  return true;
};

export const validateProduct = (
  input: Product,
  hasColorAttribute: boolean,
  hasSizeAttribute: boolean,
  hasMainMaterialAttribute: boolean,
  hasLiningMaterialAttribute: boolean,
  hasComposition: boolean,
  isC2C: boolean
) => {
  const productValidation: ValidateProductState = {};

  if (!input.title?.trim()) {
    productValidation.title = true;
  }

  if (!input.categoryId) {
    productValidation.categoryId = true;
  }

  if (!input.images?.length) {
    productValidation.images = true;
  }

  if (!input.description?.trim() || input.description === "<p></p>\n") {
    productValidation.description = true;
  }

  if (!isC2C && !input.sku?.trim()) {
    productValidation.sku = true;
  }

  if (hasComposition && !isC2C && !input.composition?.trim()) {
    productValidation.composition = true;
  }

  if (
    !numberCheck({
      value: input.minimalAge,
      lessThan: 999999,
      moreThan: 0,
      isInteger: true,
    })
  ) {
    productValidation.minimalAge = true;
  }

  if (!input.brand?.trim()) {
    productValidation.brand = true;
  }

  if (hasColorAttribute && !input.color?.trim()) {
    productValidation.color = true;
  }

  if (hasSizeAttribute && !input.size?.trim()) {
    productValidation.size = true;
  }

  if (hasMainMaterialAttribute && !input.mainMaterial?.trim()) {
    productValidation.mainMaterial = true;
  }

  if (hasLiningMaterialAttribute && !input.liningMaterial?.trim()) {
    productValidation.liningMaterial = true;
  }

  if (!input.stateType?.trim()) {
    productValidation.stateType = true;
  }

  if (!input.logisticClass?.trim()) {
    productValidation.logisticClass = true;
  }

  if (
    !numberCheck({
      value: input.quantity,
      lessThan: 999999,
      moreThan: 0,
      isInteger: true,
      required: true,
    })
  ) {
    productValidation.quantity = true;
  }

  if (
    !numberCheck({
      value: input.sendAlertOnQuantity,
      lessThan: 999999,
      moreThan: 0,
      isInteger: true,
    })
  ) {
    productValidation.sendAlertOnQuantity = true;
  }
  if (
    input.sellingType?.includes(SellingTypeEnum.NEW) &&
    !numberCheck({
      value: input.priceTTC,
      lessThan: 999999.99,
      moreThan: 0,
      required: true,
      moreThanOrEqual: false,
    })
  ) {
    productValidation.priceTTC = true;
  }

  if (
    !numberCheck({
      value: input.discountedPriceTTC,
      lessThan: 999999.99,
      moreThan: 0,
      moreThanOrEqual: false,
    })
  ) {
    productValidation.discountedPriceTTC = true;
  }

  if (input.startingDiscountDate === "invalid") {
    productValidation.startingDiscountDate = true;
  }

  if (input.endingDiscountDate === "invalid") {
    productValidation.endingDiscountDate = true;
  }

  if (input.startingDiscountDate && input.endingDiscountDate) {
    if (
      new Date(input.startingDiscountDate) > new Date(input.endingDiscountDate)
    ) {
      productValidation.startingDiscountDate = true;
      productValidation.endingDiscountDate = true;
    }
  }

  if (
    input.priceTTC &&
    input.discountedPriceTTC &&
    +input.priceTTC < +input.discountedPriceTTC
  ) {
    productValidation.priceTTC = true;
    productValidation.discountedPriceTTC = true;
  }

  if (
    input.sellingType?.includes(SellingTypeEnum.RENT) &&
    !input.rentingPeriod?.length
  ) {
    productValidation.rentingPeriod = true;
  }

  if (
    input.sellingType?.includes(SellingTypeEnum.RENT) &&
    !input.depositPrice
  ) {
    productValidation.depositPrice = true;
  }

  if (
    input.sellingType?.includes(SellingTypeEnum.RENT) &&
    !input.rentSecondHandPrice
  ) {
    productValidation.rentSecondHandPrice = true;
  }

  if (
    input.sellingType?.includes(SellingTypeEnum.RENT) &&
    input.rentingPeriod?.includes(RentingPeriodEnum.DAILY) &&
    !numberCheck({
      value: input.dailyRentPrice,
      lessThan: 999999.99,
      moreThan: 0,
      required: true,
      moreThanOrEqual: false,
    })
  ) {
    productValidation.dailyRentPrice = true;
  }

  if (
    input.sellingType?.includes(SellingTypeEnum.RENT) &&
    input.rentingPeriod?.includes(RentingPeriodEnum.WEEKLY) &&
    !numberCheck({
      value: input.weeklyRentPrice,
      lessThan: 999999.99,
      moreThan: 0,
      required: true,
      moreThanOrEqual: false,
    })
  ) {
    productValidation.weeklyRentPrice = true;
  }

  if (
    input.sellingType?.includes(SellingTypeEnum.RENT) &&
    input.rentingPeriod?.includes(RentingPeriodEnum.MONTHLY) &&
    !numberCheck({
      value: input.monthlyRentPrice,
      lessThan: 999999.99,
      moreThan: 0,
      required: true,
      moreThanOrEqual: false,
    })
  ) {
    productValidation.monthlyRentPrice = true;
  }

  if (
    !numberCheck({
      value: input.vatRate,
      lessThan: 100,
      moreThan: 0,
      required: !isC2C,
      moreThanOrEqual: false,
    })
  ) {
    productValidation.vatRate = true;
  }

  if (
    !numberCheck({
      value: input.ecoTax,
      lessThan: 999999.99,
      moreThan: 0,
    })
  ) {
    productValidation.ecoTax = true;
  }

  return productValidation;
};
