import { Company } from "~/types/data/Company.types";

import { CompanyTableRow } from "../CompaniesTableRow";
import styles from "./index.module.scss";

interface Props {
  companies: Company[];
  selectedRows: Company[];
  onCheckboxChange: (company: Company) => void;
  onRowClick: (variantId: number) => void;
}

export const CompaniesTableBody = ({
  companies,
  selectedRows,
  onCheckboxChange,
  onRowClick,
}: Props) => {
  const isSelected = (companyId?: number) =>
    selectedRows.findIndex(({ id }) => companyId === id) > -1;

  return (
    <tbody className={`${styles.tbody}`}>
      {companies.map((company, index) => {
        const isRowSelected = isSelected(company.id);
        return (
          <CompanyTableRow
            key={index.toString()}
            company={company}
            isSelected={isRowSelected}
            onCheckboxChange={onCheckboxChange}
            onClick={onRowClick}
          />
        );
      })}
    </tbody>
  );
};
