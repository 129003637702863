import { useEffect, useRef } from "react";

import useOnScreen from "~/hooks/componentOnScreen";

import { NavigationSections } from "../SectionsNavigation";
import styles from "./index.module.scss";

type Props = {
  children: React.ReactNode;
  onVisibilityChange: (
    sectionType: NavigationSections,
    isVisible: boolean
  ) => void;
  sectionType: NavigationSections;
  sectionTitle: string;
};
export const VisibilityWrapper = ({
  children,
  onVisibilityChange,
  sectionType,
  sectionTitle,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const optionsVisible = useOnScreen(ref);

  useEffect(() => {
    onVisibilityChange(sectionType, optionsVisible);
  }, [optionsVisible]);
  return (
    <div
      className={`${styles.sectionChildren}`}
      ref={ref}
      id={`section${sectionType.valueOf()}`}
    >
      <h2 className={`${styles.sectionTitle}`}>{sectionTitle}</h2>
      {children}
    </div>
  );
};
