import { Avatar } from "@mui/material";

import { CustomButton } from "~/components/form/CustomButton";
import SVGContainer from "~/components/UI/SVGContainer";
import { IncidentStatusEnum } from "~/types/data/Order.types";

import styles from "./index.module.scss";

interface Props {
  refetchHandler?: () => void;
  refetching?: boolean;
  orderId: number;
  buyerName: string;
  incidentStatus: IncidentStatusEnum;
  showCloseIncidentModalHandler?: () => void;
  profilePicUrl: string;
}

export const ConversationHeader = ({
  refetchHandler,
  refetching,
  buyerName,
  incidentStatus,
  orderId,
  showCloseIncidentModalHandler,
  profilePicUrl,
}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.identificationSection}>
        <Avatar src={profilePicUrl} />
        <p className={styles.orderId}>Commande N°{orderId}</p>
        <p className={styles.nameContainer}>{buyerName}</p>
        {refetchHandler && (
          <CustomButton
            backgroundColor="#ABABAB"
            height="100%"
            width="fit-content"
            padding="0.5rem"
            borderRadius="8px"
            onClick={refetchHandler}
            disabled={refetching}
          >
            <SVGContainer
              height="12px"
              width="12px"
              imagePath="/assets/white-refresh-icon.svg"
            />
          </CustomButton>
        )}
      </div>

      <div className={styles.actionsSection}>
        {incidentStatus === IncidentStatusEnum.OPEN &&
          showCloseIncidentModalHandler && (
            <CustomButton
              backgroundColor="#C22A23"
              height="100%"
              width="100%"
              padding="0.5rem"
              borderRadius="8px"
              color="white"
              onClick={showCloseIncidentModalHandler}
            >
              <div className={`${styles.button}`}>
                <SVGContainer
                  height="12px"
                  width="12px"
                  imagePath="/assets/white-checkmark-icon.svg"
                />
                <p>Fermer l'incident</p>
              </div>
            </CustomButton>
          )}
      </div>
    </div>
  );
};
