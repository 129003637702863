import { ApolloError } from "@apollo/client";
import { DateTime } from "luxon";
import { useState } from "react";
import { SingleValue } from "react-select";

import {
  UPDATE_COMPANY_DETAILS,
  UpdateCompanyDetailsApiInput,
  UpdateCompanyDetailsApiResponse,
} from "~/api/graphql/company";
import { CustomButton } from "~/components/form/CustomButton";
import { CustomInput } from "~/components/form/CustomInput";
import DatePicker from "~/components/form/DatePicker";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { countryList } from "~/constants/countries";
import { colors } from "~/constants/styles";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import {
  Company,
  SocialReasonEnum,
  UpdateCompanyDetailsInput,
} from "~/types/data/Company.types";
import { updateDetailsValidationSchema } from "~/util/validation/companyAccount/details.schema";

const socialReasonOptions: OptionType[] = [
  { label: "SAS", value: SocialReasonEnum.SAS },
  { label: "SARL", value: SocialReasonEnum.SARL },
  { label: "Société Anonyme", value: SocialReasonEnum.SA },
  { label: "SASU", value: SocialReasonEnum.SASU },
  { label: "EURL", value: SocialReasonEnum.EURL },
  { label: "Société en nom collectif", value: SocialReasonEnum.SNC },
  { label: "GIE", value: SocialReasonEnum.GIE },
  { label: "GAEC", value: SocialReasonEnum.GAEC },
  { label: "Auto-Entrepreneur", value: SocialReasonEnum.FREELANCE },
  { label: "EIRL", value: SocialReasonEnum.EIRL },
  { label: "Association", value: SocialReasonEnum.ASSOCIATION },
  { label: "Business", value: SocialReasonEnum.BUSINESS },
  { label: "Soletrader", value: SocialReasonEnum.SOLETRADER },
];

export const CompanyDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { companyAccount, setCompanyAccount } = useCompanyAccountContext();

  const updateCompanyDetailsSuccessHandler = ({
    updateCompanyDetails,
  }: UpdateCompanyDetailsApiResponse) => {
    setSuccessMessage("Coordonnées société updated successfully.");
    setCompanyAccount((prev) => {
      return {
        ...prev,
        mangopayId: updateCompanyDetails.mangopayId,
      };
    });
  };

  const updateCompanyDetailsErrorHandler = (error: ApolloError) => {
    setErrorMessage(error.message);
  };

  const { trigger: updateCompanyDetailsTrigger } = useMutationWithCallbacks<
    UpdateCompanyDetailsApiResponse,
    UpdateCompanyDetailsApiInput
  >(
    UPDATE_COMPANY_DETAILS,
    updateCompanyDetailsSuccessHandler,
    updateCompanyDetailsErrorHandler
  );

  const inputChangeHandler = <T extends keyof Company>(
    inputName: T,
    changes: Company[T]
  ) => {
    const removeSpaces = inputName === "siret" && typeof changes === "string";
    setCompanyAccount((state) => ({
      ...state,
      [inputName]: removeSpaces ? changes.replace(/\s/g, "") : changes,
    }));
  };

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");
    setIsLoading(true);

    const input: UpdateCompanyDetailsInput = {
      id: companyAccount.id,
      companyName: companyAccount.companyName,
      mangopayId: companyAccount.mangopayId || "",
      uboDeclarationId: companyAccount.uboDeclarationId || "",
      socialReason: companyAccount.socialReason,
      siret: companyAccount.siret,
      intercomTVA: companyAccount.intercomTVA,
      companyAddress: companyAccount.companyAddress,
      companyZipCode: companyAccount.companyZipCode,
      companyCity: companyAccount.companyCity,
      companyPhoneNumber: companyAccount.companyPhoneNumber,
      companyPhoneNumber2: companyAccount.companyPhoneNumber2,
      website: companyAccount.website,
      country: companyAccount.country,
      email: companyAccount.email,
      legalRepLastName: companyAccount.legalRepLastName,
      legalRepFirstName: companyAccount.legalRepFirstName,
      legalRepEmail: companyAccount.legalRepEmail,
      legalRepAddress: companyAccount.legalRepAddress,
      legalRepZipCode: companyAccount.legalRepZipCode,
      legalRepCity: companyAccount.legalRepCity,
      legalRepCountry: companyAccount.legalRepCountry,
      legalRepBirthday: companyAccount.legalRepBirthday,
    };

    // validate input
    const result = updateDetailsValidationSchema.validate(input);
    const { error } = result;
    if (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
      return;
    }

    // send update request
    await updateCompanyDetailsTrigger({
      variables: { UpdateCompanyDetailsInput: input },
    });

    setIsLoading(false);
  };

  const countryOptions: OptionType[] = countryList.map(({ name, alpha2 }) => {
    return { label: name, value: alpha2.toUpperCase() };
  });

  return (
    <form
      className="myAccountFormSection"
      onSubmit={(e) => {
        submitHandler(e);
      }}
    >
      <h3 className="title">Informations de la société</h3>

      <h4 className="normalWeightText">COORDONNÉES DE LA SOCIÉTÉ</h4>
      <div className="doubleInputs">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("companyName", value.toString());
          }}
          value={companyAccount.companyName}
          label="Nom :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
        <SelectInput
          label="Raison social :"
          fontSize="14px"
          backgroundColor={colors.$inputGray}
          noBorder={true}
          options={socialReasonOptions}
          onChange={(option: SingleValue<OptionType>) =>
            inputChangeHandler(
              "socialReason",
              option?.value as SocialReasonEnum
            )
          }
          value={companyAccount.socialReason || ""}
        />
      </div>
      <div className="doubleInputs">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("siret", value.toString());
          }}
          value={companyAccount.siret}
          label="SIRET :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("intercomTVA", value.toString());
          }}
          value={companyAccount.intercomTVA}
          label="TVA intracommunautaire :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
      </div>
      <div className="doubleInputs">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("companyAddress", value.toString());
          }}
          value={companyAccount.companyAddress}
          label="Adresse :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
        <SelectInput
          label="Pays :"
          fontSize="14px"
          backgroundColor={colors.$inputGray}
          noBorder={true}
          onChange={(option: SingleValue<OptionType>) => {
            inputChangeHandler("country", option?.value || "");
          }}
          options={countryOptions}
          value={companyAccount.country}
        />
      </div>

      <div className="doubleInputs">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("companyZipCode", value.toString());
          }}
          value={companyAccount.companyZipCode}
          label="Code postal :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("companyCity", value.toString());
          }}
          value={companyAccount.companyCity}
          label="Ville :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
      </div>
      <div className="doubleInputs">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("companyPhoneNumber", value.toString());
          }}
          value={companyAccount.companyPhoneNumber}
          label="Téléphone :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("companyPhoneNumber2", value.toString());
          }}
          value={companyAccount.companyPhoneNumber2}
          label="Téléphone N°2 (optionnel) :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
      </div>
      <div className="doubleInputs">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("website", value.toString());
          }}
          value={companyAccount.website}
          label="Site internet :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("email", value.toString());
          }}
          value={companyAccount.email}
          label="Adresse e-mail :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
      </div>

      <h4 className="normalWeightText">REPRÉSENTANT LÉGAL</h4>
      <div className="doubleInputs">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("legalRepLastName", value.toString());
          }}
          value={companyAccount.legalRepLastName}
          label="Nom :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("legalRepFirstName", value.toString());
          }}
          value={companyAccount.legalRepFirstName}
          label="Prénom :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
      </div>
      <div className="doubleInputs">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("legalRepEmail", value.toString());
          }}
          value={companyAccount.legalRepEmail}
          label="Adresse e-mail :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
        <div style={{ width: "100%" }} />
      </div>
      <div className="singleInput">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("legalRepAddress", value.toString());
          }}
          value={companyAccount.legalRepAddress}
          label="Adresse :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
      </div>

      <div className="doubleInputs">
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("legalRepZipCode", value.toString());
          }}
          value={companyAccount.legalRepZipCode}
          label="Code postal :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("legalRepCity", value.toString());
          }}
          value={companyAccount.legalRepCity}
          label="Ville :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
        />
      </div>
      <div className="doubleInputs">
        <DatePicker
          label="Date de naissance :"
          fontSize="14px"
          icon="CalendarMonthOutlined"
          onChange={(value: DateTime | null) => {
            inputChangeHandler("legalRepBirthday", value?.toISO() || "");
          }}
          value={DateTime.fromISO(companyAccount.legalRepBirthday)}
          backgroundColor={colors.$inputGray}
          noBorder={true}
          borderRadius="5px"
        />
        <SelectInput
          label="Pays :"
          fontSize="14px"
          backgroundColor={colors.$inputGray}
          noBorder={true}
          onChange={(option: SingleValue<OptionType>) => {
            inputChangeHandler("legalRepCountry", option?.value || "");
          }}
          options={countryOptions}
          value={companyAccount.legalRepCountry}
        />
      </div>
      <div className="messageContainer">
        {!!errorMessage && <p className="errorMessage">{errorMessage}</p>}
        {!!successMessage && <p>{successMessage}</p>}
      </div>
      <div className="buttonContainer">
        <CustomButton
          backgroundColor={colors.$primary}
          color="white"
          width="fit-content"
          borderRadius="8px"
          padding="1rem 1.5rem"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <SimpleLoader size="size2" /> : "Enregistrer"}
        </CustomButton>
      </div>
    </form>
  );
};
