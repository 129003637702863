export enum SellerMappingTypeEnum {
  CATEGORY = "CATEGORY",
  ATTRIBUTE = "ATTRIBUTE",
  ATTRIBUTE_VALUE = "ATTRIBUTE_VALUE",
}

export interface SellerMapping {
  id?: number;
  type: SellerMappingTypeEnum;
  name: string;
  originalValue: string;
  mappedValue: string;
  companyId?: number;
}
