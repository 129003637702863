import Select, { SingleValue, StylesConfig } from "react-select";

import styles from "./index.module.scss";

export type OptionType<T = string> = {
  value: T;
  label: string;
};

interface Props<T> {
  placeholder?: string;
  label?: string;
  required?: boolean;
  fontSize?: string;
  backgroundColor?: string;
  noBorder?: boolean;
  onChange: (option: SingleValue<OptionType<T>>) => void;
  value: string;
  multiple?: boolean;
  options: OptionType<T>[];
  disabled?: boolean;
  disabledBackground?: string;
  hidden?: boolean;
  height?: number;
  invalidColor?: string;
  borderWidth?: string;
  width?: string;
  hasDefault?: boolean;
  defaultLabel?: string;
  invalid?: boolean;
  menuShouldBlockScroll?: boolean;
}

const SelectInput = <T extends string>({
  placeholder,
  label,
  fontSize,
  backgroundColor,
  noBorder,
  value,
  options,
  onChange,
  disabled,
  disabledBackground,
  hidden,
  height,
  required,
  invalidColor,
  borderWidth = "1px",
  width,
  hasDefault,
  defaultLabel,
  invalid,
  menuShouldBlockScroll,
}: Props<T>) => {
  const isError = (!!invalidColor && !value) || invalid;

  const customStyles: StylesConfig = {
    control: (base, state) => ({
      ...base,
      height: height || 40,
      minHeight: height || 40,
      backgroundColor:
        disabled && disabledBackground ? disabledBackground : backgroundColor,
      fontWeight: isError ? "bolder" : "normal",
      border: isError
        ? `${borderWidth} solid ${invalidColor || "#870000"}`
        : state.isFocused
        ? `${borderWidth} solid black`
        : noBorder
        ? "none"
        : "",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 999,
    }),
  };

  const optionsWithDefault = hasDefault
    ? [{ label: defaultLabel || "Not selected", value: "" }, ...options]
    : options;

  return (
    <div
      className={`${styles.container}`}
      style={{
        fontSize: fontSize,
        visibility: hidden ? "hidden" : "visible",
        minWidth: width,
      }}
    >
      {label && (
        <div className={`${styles.label}`}>
          <label>{label}</label>
          {required && <span>*</span>}
        </div>
      )}
      <Select
        // windowThreshold={50}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
        styles={customStyles}
        value={
          optionsWithDefault.find((option) => option.value === value) || ""
        }
        options={optionsWithDefault}
        onChange={(option) => {
          onChange(option as OptionType<T>);
        }}
        isDisabled={disabled}
        menuPosition="fixed"
        menuPortalTarget={document.querySelector("body")}
        menuShouldBlockScroll={menuShouldBlockScroll ?? true}
      />
    </div>
  );
};

export default SelectInput;
