import { ApolloError, useLazyQuery } from "@apollo/client";
import { debounce } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { utils, writeFile } from "xlsx";

import {
  ADMIN_DELETE_ALL_COMPANIES,
  ADMIN_DELETE_COMPANIES,
  ADMIN_GET_COMPANIES,
  ADMIN_GET_COMPANIES_COUNT,
  AdminDeleteAllCompaniesResponse,
  AdminDeleteCompaniesInput,
  AdminDeleteCompaniesResponse,
  AdminGetCompaniesCountResponse,
  AdminGetCompaniesInput,
  AdminGetCompaniesResponse,
} from "~/api/graphql/company";
import { CustomButton } from "~/components/form/CustomButton";
import { InputWithIcon } from "~/components/form/InputWithIcon";
import { ErrorPopup } from "~/components/UI/ErrorPopup";
import { LoadingPopup } from "~/components/UI/LoadingPopup";
import { SideModal } from "~/components/UI/SideModal";
import SVGContainer from "~/components/UI/SVGContainer";
import { colors } from "~/constants/styles";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import { routePaths } from "~/navigation/routes";
import { useAppDispatch, useAppSelector } from "~/redux/hooks";
import {
  onFilterArgsChange,
  selectAdminCompaniesFilterArgs,
} from "~/redux/slice/adminCompanies.slice";
import { Company } from "~/types/data/Company.types";

import { ConfirmModal } from "../SynchronizationOfStreams/myConnectors/ConfirmModal";
import { CompaniesFilterForm } from "./CompaniesFilterForm";
import { CompaniesTable } from "./CompaniesTable";
import styles from "./index.module.scss";
import { getFilterCount } from "./util/getFilterCount";

export const AdminCompanies = () => {
  // states
  const [fetchedCompanies, setFetchedCompanies] = useState<Company[]>();
  const [hasMoreData, setHasMoreData] = useState<boolean>(false);
  const [selectedCompanies, setSelectedCompanies] = useState<Company[]>([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [searchBox, setSearchBox] = useState<string>();
  const [isPreparingFile, setIsPreparingFile] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState("");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterCount, setFilterCount] = useState<number>(0);
  const [companiesCount, setCompaniesCount] = useState<number>(0);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  // hooks
  const filterArgsState = useAppSelector(selectAdminCompaniesFilterArgs);
  const dispatch = useAppDispatch();

  const { companyFormRoute } = routePaths;

  const navigate = useNavigate();

  // handler functions
  const showFilterModalHandler = () => {
    setShowFilterModal(true);
  };

  const hideFilterModalHandler = () => {
    setShowFilterModal(false);
  };

  const openAddCompanyPageHandler = () => {
    navigate(`/${companyFormRoute}`, { replace: false });
  };

  const openEditCompany = (companyId: number) => {
    navigate(`/${companyFormRoute}?id=${companyId}`, {
      replace: false,
    });
  };

  const searchBoxChangeHandler = useCallback(
    debounce((value: string) => {
      dispatch(
        onFilterArgsChange({
          filterArg: "searchBox",
          searchBox: value,
        })
      );
    }, 500),
    []
  );

  const checkboxClickHandler = (inputCompany: Company) => {
    const selectedCheckboxIndex = selectedCompanies.findIndex(
      ({ id }) => inputCompany.id === id
    );
    if (selectedCheckboxIndex > -1) {
      setIsSelectedAll(false);
      setSelectedCompanies((prev) => {
        return prev.filter(({ id }) => inputCompany.id !== id);
      });
      return;
    }
    setSelectedCompanies((prev) => {
      return [...prev, inputCompany];
    });
  };

  const selectAllChangeHandler = (selected: boolean) => {
    if (selected) {
      setIsSelectedAll(true);
      setSelectedCompanies(fetchedCompanies || []);
      return;
    }
    setIsSelectedAll(false);
    setSelectedCompanies([]);
  };

  const [adminGetCompaniesCountTrigger] = useLazyQuery<
    AdminGetCompaniesCountResponse,
    AdminGetCompaniesInput
  >(ADMIN_GET_COMPANIES_COUNT);

  const [adminGetCompaniesTrigger, { loading: adminGetCompaniesLoading }] =
    useLazyQuery<AdminGetCompaniesResponse, AdminGetCompaniesInput>(
      ADMIN_GET_COMPANIES
    );

  const exportCompanies = async () => {
    const companiesToExport: Company[] = [];
    if (isSelectedAll) {
      const { data, error } = await adminGetCompaniesTrigger({
        variables: {
          AdminGetCompaniesInput: {
            ...filterArgsState,
            cursor: undefined,
            take: undefined,
          },
        },
      });
      if (data) {
        companiesToExport.push(...data.adminGetCompanies.companies);
      } else if (error) {
        const { message } = error;
        setErrorModalMessage(message);
        return;
      }
    } else {
      companiesToExport.push(...selectedCompanies);
    }

    const mappedCompaniesToExport = companiesToExport.map(
      ({
        id,
        email,
        storeName,
        companyDescription,
        logoFullPath,
        companyName,
      }) => ({
        id,
        email,
        storeName,
        companyDescription,
        logoFullPath,
        companyName,
      })
    );

    // create file using xlsx
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(mappedCompaniesToExport);

    // make cells wrap text
    utils.book_append_sheet(wb, ws, "Companies");
    setIsPreparingFile(false);
    writeFile(wb, "Companies.xlsx");
  };

  const exportCompaniesHandler = () => {
    setIsPreparingFile(true);
    setTimeout(() => {
      exportCompanies();
    }, 500);
  };

  const fetchCompanies = async (init: boolean) => {
    if (adminGetCompaniesLoading) return;

    const { ...filtersData } = filterArgsState;

    if (init) {
      setFetchedCompanies(undefined);
      dispatch(
        onFilterArgsChange({
          filterArg: "cursor",
          cursor: undefined,
        })
      );
      setSelectedCompanies([]);
      setIsSelectedAll(false);

      const { data: adminGetCompaniesCountData } =
        await adminGetCompaniesCountTrigger({
          variables: {
            AdminGetCompaniesInput: {
              ...filtersData,
              cursor: undefined,
            },
          },
        });

      if (adminGetCompaniesCountData) {
        setCompaniesCount(
          adminGetCompaniesCountData.adminGetCompaniesCount.count
        );
      }
    }

    const { data, error } = await adminGetCompaniesTrigger({
      variables: {
        AdminGetCompaniesInput: {
          ...filtersData,
          ...(init && { cursor: undefined }),
        },
      },
    });

    if (data) {
      const { adminGetCompanies } = data;
      const { hasMoreData, nextCursor, companies } = adminGetCompanies;
      setHasMoreData(hasMoreData);
      dispatch(onFilterArgsChange({ filterArg: "cursor", cursor: nextCursor }));

      if (isSelectedAll) {
        setSelectedCompanies((prevSelectedCompanies) => [
          ...prevSelectedCompanies,
          ...companies,
        ]);
      }

      setFetchedCompanies((prevFetchedCompanies) =>
        prevFetchedCompanies
          ? [...prevFetchedCompanies, ...companies]
          : [...companies]
      );
    } else if (error) {
      const { message } = error;
      setErrorModalMessage(message);
    }
  };

  const deleteCompaniesSuccessHandler = () => {
    fetchCompanies(true);
  };

  const deleteCompaniesErrorHandler = (error: ApolloError) => {
    const { message } = error;
    setErrorModalMessage(message);
  };

  const hideErrorPopupHandler = () => {
    setErrorModalMessage("");
    fetchCompanies(true);
  };

  const { trigger: deleteCompaniesTrigger } = useMutationWithCallbacks<
    AdminDeleteCompaniesResponse,
    AdminDeleteCompaniesInput
  >(
    ADMIN_DELETE_COMPANIES,
    deleteCompaniesSuccessHandler,
    deleteCompaniesErrorHandler
  );

  const { trigger: deleteAllCompaniesTrigger } = useMutationWithCallbacks<
    AdminDeleteAllCompaniesResponse,
    AdminGetCompaniesInput
  >(
    ADMIN_DELETE_ALL_COMPANIES,
    deleteCompaniesSuccessHandler,
    deleteCompaniesErrorHandler
  );

  const deleteProducts = async () => {
    setFetchedCompanies(undefined);
    if (isSelectedAll) {
      await deleteAllCompaniesTrigger({
        variables: {
          AdminGetCompaniesInput: {
            ...filterArgsState,
            cursor: undefined,
            take: undefined,
          },
        },
      });
      return;
    }

    await deleteCompaniesTrigger({
      variables: {
        AdminDeleteCompaniesInput: {
          companiesIds: selectedCompanies.map(({ id }) => id || 0),
        },
      },
    });
  };

  const deleteProductsHandler = () => {
    deleteProducts();
  };

  const openConfirmDeleteCompaniesModalHandler = () => {
    setShowConfirmDeleteModal(true);
  };

  const closeConfirmDeleteCompaniesModalHandler = () => {
    setShowConfirmDeleteModal(false);
  };

  const clearSelectedCompaniesHandler = () => {
    setIsSelectedAll(false);
    setSelectedCompanies([]);
  };

  const clearAllFiltersHandler = () => {
    dispatch(
      onFilterArgsChange({
        filterArg: "clearAll",
      })
    );
  };

  useEffect(() => {
    const count = getFilterCount(filterArgsState);
    setFilterCount(count);

    fetchCompanies(true);
  }, [
    filterArgsState.orderBy,
    filterArgsState.createdAtFrom,
    filterArgsState.createdAtTo,
    filterArgsState.kycLevel,
    filterArgsState.searchBox,
  ]);

  useEffect(() => {
    setSearchBox(filterArgsState.searchBox);
  }, [filterArgsState.searchBox]);

  return (
    <>
      <LoadingPopup show={isPreparingFile} message="Preparing file..." />
      <ErrorPopup
        onCancel={hideErrorPopupHandler}
        show={!!errorModalMessage}
        message={errorModalMessage}
      />
      <SideModal onCancel={hideFilterModalHandler} show={showFilterModal}>
        <CompaniesFilterForm />
      </SideModal>
      <ConfirmModal
        show={showConfirmDeleteModal}
        onCancel={closeConfirmDeleteCompaniesModalHandler}
        onConfirm={() => {
          closeConfirmDeleteCompaniesModalHandler();
          deleteProductsHandler();
        }}
        text="Êtes-vous sûr de vouloir supprimer ces companies?"
      />
      <div className={styles.container}>
        <>
          <div className={`${styles.upperBar}`}>
            <div className={`${styles.leftColumn}`}>
              <InputWithIcon
                onChange={(value: string | number) => {
                  setSearchBox(value.toString());
                  searchBoxChangeHandler(value.toString());
                }}
                value={searchBox}
                borderRadius="5px"
                noBorder={true}
                backgroundColor="white"
                iconPath="/assets/search-icon.svg"
                placeholder="Chercher un companies..."
              />
              <div className={styles.countContainer}>
                {!!companiesCount && !!fetchedCompanies?.length && (
                  <p>
                    Fetched {fetchedCompanies?.length || 0}/{companiesCount}
                  </p>
                )}
              </div>
            </div>
            <div className={`${styles.rightColumn}`}>
              <CustomButton
                backgroundColor={colors.$primary}
                color="white"
                width="fit-content"
                borderRadius="8px"
                padding="1rem 1.5rem"
                onClick={openAddCompanyPageHandler}
              >
                Ajouter un company
              </CustomButton>
            </div>
          </div>
          <div className={`${styles.toolbar}`}>
            <div className={styles.selectionSection}>
              <div className={`${styles.selectedCount}`}>
                <p>
                  {isSelectedAll ? companiesCount : selectedCompanies.length}{" "}
                  companies sélectionné
                  {selectedCompanies.length > 1 ? "s" : ""}
                </p>
                {selectedCompanies.length > 0 && (
                  <div
                    className={`${styles.clearSelectionButton}`}
                    onClick={() => {
                      clearSelectedCompaniesHandler();
                    }}
                  >
                    <SVGContainer
                      height="16px"
                      width="17px"
                      imagePath="/assets/delete-icon.svg"
                    />
                    <p>Supprimer la sélection</p>
                  </div>
                )}
              </div>
              {selectedCompanies.length > 0 && (
                <div className={`${styles.controlButtonsContainer}`}>
                  <>
                    <div
                      className={`${styles.buttonContainer}`}
                      onClick={exportCompaniesHandler}
                    >
                      <SVGContainer
                        height="16px"
                        width="17px"
                        imagePath="/assets/export-icon.svg"
                      />
                      <p>Exporter</p>
                    </div>
                    <div
                      className={`${styles.buttonContainer}`}
                      onClick={openConfirmDeleteCompaniesModalHandler}
                    >
                      <SVGContainer
                        height="16px"
                        width="17px"
                        imagePath="/assets/delete-icon.svg"
                      />
                      <p>supprimer</p>
                    </div>
                  </>
                </div>
              )}
            </div>
            <div className={`${styles.filtersContainer}`}>
              {!!filterCount && (
                <>
                  <div
                    className={styles.clearFilterButton}
                    onClick={clearAllFiltersHandler}
                  >
                    <p className={`${styles.label}`}>
                      Effacer tous les filtres
                    </p>
                  </div>
                  <div className={styles.filterCount}>
                    <p>{filterCount} filtres sélectionnés</p>
                  </div>
                </>
              )}
              <div
                className={`${styles.filterButtonContainer}`}
                onClick={showFilterModalHandler}
              >
                <SVGContainer
                  height="16px"
                  width="17px"
                  imagePath="/assets/filter-icon.svg"
                />
                <p>Filtrer les companies</p>
              </div>
            </div>
          </div>
          <CompaniesTable
            companies={fetchedCompanies}
            selectedRows={selectedCompanies}
            isSelectedAll={isSelectedAll}
            onCheckboxChange={checkboxClickHandler}
            onSelectAllChange={selectAllChangeHandler}
            fetchMoreData={() => {
              fetchCompanies(false);
            }}
            hasMoreData={hasMoreData}
            onRowClick={openEditCompany}
          />
        </>
      </div>
    </>
  );
};
