import { Checkbox, CheckboxProps } from "@mui/material";
import { useState } from "react";

import SVGContainer from "~/components/UI/SVGContainer";

import styles from "./index.module.scss";

interface Props<T> {
  checkboxProps?: CheckboxProps;
  checked?: boolean;
  haveIcon?: boolean;
  iconText?: string;
  text: string;
  type: T;
  onSwitch: (type: T) => void;
}

export const CheckboxContainer = <T extends string>({
  type,
  checkboxProps,
  checked = false,
  haveIcon = false,
  iconText = "",
  text,
  onSwitch,
}: Props<T>) => {
  const [showIconText, setShowIconText] = useState(false);

  const onClickHandler = () => {
    onSwitch(type);
  };
  const onMouseEnterHandler = () => {
    setShowIconText(true);
  };
  const onMouseLeaveHandler = () => {
    setShowIconText(false);
  };
  return (
    <div className={styles.checkBoxWrapper}>
      <Checkbox {...checkboxProps} onClick={onClickHandler} checked={checked} />
      <span className={styles.checkboxText} onClick={onClickHandler}>
        {text}
      </span>
      <div className={styles.icon}>
        {showIconText && iconText && (
          <span
            className={styles.checkBoxIconText}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
          >
            {iconText}
          </span>
        )}
        {haveIcon && (
          <span
            className={styles.checkboxIcon}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
          >
            <SVGContainer
              imagePath="assets/info.svg"
              height="15px"
              width="15px"
            />
          </span>
        )}
      </div>
    </div>
  );
};
