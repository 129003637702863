import { MyPayOut, MyTransaction } from "~/types/data/Accounting.types";
import { AdminImport } from "~/types/data/AdminImport.type";
import { Attribute } from "~/types/data/Attribute.types";
import { AttributeValue } from "~/types/data/AttributeValue.types";
import { BankReference } from "~/types/data/BankReference.types";
import { BrandCompany } from "~/types/data/BrandCompany.type";
import { Carrier } from "~/types/data/Carrier.types";
import {
  CompanyCarrier,
  DeliveryPackage,
  ReedooCarrier,
} from "~/types/data/Carrier.types";
import { Category } from "~/types/data/Category.type";
import { CollectionModel } from "~/types/data/Collection.type";
import { CollectionImport } from "~/types/data/CollectionImport.type";
import { Company } from "~/types/data/Company.types";
import { Conversation } from "~/types/data/Conversation.types";
import {
  DashboardData,
  RentDashboardData,
  SaleDashboardData,
} from "~/types/data/DashboardData.types";
import { Formula } from "~/types/data/Formula.type";
import { FtpImport } from "~/types/data/FtpImport.types";
import { ImportedFile } from "~/types/data/ImportedFile.type";
import { KYC } from "~/types/data/KYC.types";
import { CustomMessage, Message } from "~/types/data/Message.types";
import {
  DeliveryAddress,
  Order,
  OrderToProduct,
  Payment,
} from "~/types/data/Order.types";
import { OrderHisory } from "~/types/data/OrderHistory.types";
import { Product } from "~/types/data/Product.types";
import { Profile } from "~/types/data/Profile.types";
import { PromoCode } from "~/types/data/PromoCode.type";
import {
  RentOrder,
  RentOrderToProduct,
  SingleRentOrderToProduct,
} from "~/types/data/RentOrder.type";
import { Review } from "~/types/data/Review.types";
import { SecondHandCompany } from "~/types/data/SecondHandCompany.type";
import { SelectReason } from "~/types/data/SelectReason.types";
import { SellerMapping } from "~/types/data/SellerMapping.types";
import { StatisticsReport } from "~/types/data/StatisticsReport.type";
import { SubscriptionImport } from "~/types/data/SubscriptionImport.type";
import { SubscriptionPackage } from "~/types/data/SubscriptionPackage.type";
import { Tag } from "~/types/data/Tag.type";
import { Transportation } from "~/types/data/Transportation.types";
import { UBO } from "~/types/data/UBO.types";
import { User } from "~/types/data/User.types";
import { VendorCategory } from "~/types/data/VendorCategory.types";

export const graphqlGetUserFields: (keyof User)[] = [
  "createdAt",
  "email",
  "id",
  "phoneNumber",
  "provider",
  "role",
  "status",
  "sub",
  "updatedAt",
  "username",
  "permissionLevel",
  "companyId",
  "preferredUsername",
  "deactivated",
];

export const graphqlGetCarriersFields: (keyof Carrier)[] = [
  "id",
  "name",
  "title",
  "description",
  "logoPath",
  "websiteUrl",
  "trackingUrl",
];

export const graphqlGetReedooCarrierFields: (keyof ReedooCarrier)[] = [
  "id",
  "carrierId",
];

export const graphqlGetCompanyCarriersFields: (keyof CompanyCarrier)[] = [
  "id",
  "carrierId",
];

export const graphqlGetDeliveryPackagesFields: (keyof DeliveryPackage)[] = [
  "id",
  "deliveryMethod",
  "deliveryArea",
  "code",
  "minDays",
  "maxDays",
  "price",
  "freeThreshold",
];

export const graphqlGetCompanyFields: (keyof Company)[] = [
  "id",
  "createdAt",
  "mangopayId",
  "uboDeclarationId",
  "uboDeclarationStatus",
  "storeName",
  "companyDescription",
  "logo",
  "shippingCountry",
  "returnPolicyDescription",
  "companyName",
  "socialReason",
  "siret",
  "intercomTVA",
  "companyAddress",
  "companyZipCode",
  "companyCity",
  "companyPhoneNumber",
  "companyPhoneNumber2",
  "website",
  "email",
  "country",
  "legalRepLastName",
  "legalRepFirstName",
  "legalRepEmail",
  "legalRepAddress",
  "legalRepZipCode",
  "legalRepCity",
  "legalRepCountry",
  "legalRepBirthday",
  "apiToken",
  "mangopayWalletId",
  "uboDeclarationId",
  "kycLevel",
];

export const graphqlGetBrandCompanyFields: (keyof BrandCompany)[] = [
  "id",
  "identifier",
  "name",
  "banner",
  "mobileBanner",
  "logo",
  "authBanner",
  "mobileAuthBanner",
  "primaryColor",
  "secondaryColor",
  "websiteUrl",
];

export const graphqlGetSecondHandCompanyFields: (keyof SecondHandCompany)[] = [
  "id",
  "identifier",
  "name",
  "banner",
  "mobileBanner",
  "logo",
  "authBanner",
  "mobileAuthBanner",
  "primaryColor",
  "secondaryColor",
  "websiteUrl",
];
export const graphqlGetCollectionFields: (keyof CollectionModel)[] = [
  "id",
  "name",
  "position",
  "enabled",
  "description",
];

export const graphqlGetSubscriptionFields: (keyof SubscriptionPackage)[] = [
  "id",
  "name",
  "description",
  "webPicturePath",
  "startingPrice",
  "swiperCategoryLevel",
];

export const graphqlGetTagsFields: (keyof Tag)[] = [
  "id",
  "name",
  "picturePath",
];

export const graphqlGetFormulaFields: (keyof Formula)[] = [
  "id",
  "name",
  "description",
  "color",
  "points",
  "popular",
  "price",
];

export const graphqlGetSubscriptionImportFields: (keyof SubscriptionImport)[] =
  [
    "id",
    "createdAt",
    "errorMessage",
    "filePath",
    "errorFilePath",
    "status",
    "successfullEntries",
    "totalEntries",
    "type",
  ];

export const graphqlGetCollectionImportFields: (keyof CollectionImport)[] = [
  "id",
  "createdAt",
  "errorMessage",
  "filePath",
  "status",
  "successfullEntries",
  "totalEntries",
  "type",
];

export const graphqlGetAdminImportFields: (keyof AdminImport)[] = [
  "id",
  "createdAt",
  "finishedAt",
  "filePath",
  "type",
  "status",
  "errorMessage",
  "successfullEntries",
  "totalEntries",
  "associatedId",
];

export const graphqlGetBankReferenceFields: (keyof BankReference)[] = [
  "id",
  "mangopayId",
  "accountOwner",
  "address",
  "bankName",
  "bic",
  "city",
  "iban",
  "zipCode",
];

export const graphqlGetUBOFields: (keyof UBO)[] = [
  "id",
  "mangopayId",
  "active",
  "address",
  "birthCountry",
  "birthDate",
  "birthPlace",
  "city",
  "country",
  "firstName",
  "lastName",
  "nationality",
  "zipCode",
  "region",
];

export const graphqlGetKYCDocumentFields: (keyof KYC)[] = [
  "id",
  "mangopayId",
  "createdAt",
  "name",
  "path",
  "status",
];

export const graphqlGetCategoryFields: (keyof Category)[] = [
  "id",
  "name",
  "level",
  "associatedNames",
  "commissionPercentage",
  "order",
];

export const graphqlGetVendorCategoryFields: (keyof VendorCategory)[] = [
  "id",
  "name",
  "associatedNames",
];

export const graphqlGetProductFields: (keyof Product)[] = [
  "id",
  "collectionName",
  "parentId",

  "createdAt",
  "updatedAt",

  "categoryId",
  "status",

  "image1",
  "image2",
  "image3",
  "image4",
  "image5",
  "image6",
  "image7",
  "video",

  "title",
  "description",
  "productCareInstructions",
  "ean",
  "sku",
  "vendorParentId",
  "composition",
  "compositionLining",
  "minimalAge",
  "weight",
  "depth",
  "length",
  "height",
  "volume",
  "diameter",

  "gender",
  "brand",
  "sellingType",
  "stateType",
  "size",
  "color",
  "mainMaterial",
  "liningMaterial",
  "logisticClass",
  "ecoResponsibleLabel",
  "madeIn",

  "quantity",
  "sendAlertOnQuantity",

  "ecoTax",
  "vatRate",
  "priceTTC",
  "discountedPriceTTC",
  "startingDiscountDate",
  "endingDiscountDate",
  "discountedSecondHandPrice",
  "secondHandPrice",
  "dailyRentPrice",
  "weeklyRentPrice",
  "monthlyRentPrice",
  "depositPrice",
  "rentSecondHandPrice",
];

export const graphqlGetAttributeFields: (keyof Attribute)[] = [
  "id",
  "name",
  "associatedNames",
  "isGuessable",
  "forProducts",
  "type",
];

export const graphqlGetAttributeValuesFields: (keyof AttributeValue)[] = [
  "id",
  "value",
  "associatedValues",
  "forC2C",
  "code",
  "order",
];

export const graphqlGetImportedFileFields: (keyof ImportedFile)[] = [
  "id",
  "createdAt",
  "fileName",
  "filePath",
  "errorFilePath",
  "errorMessage",
  "successFilePath",
  "status",
  "totalProducts",
  "incompleteProducts",
  "successfullProducts",
  "companyId",
  "isFTP",
  "isUpdateMode",
];

export const graphqlGetSellerMappingFields: (keyof SellerMapping)[] = [
  "id",
  "name",
  "type",
  "originalValue",
  "mappedValue",
  "companyId",
];

export const graphqlGetOrderFields: (keyof Order)[] = [
  "id",
  "createdAt",
  "shippingDate",
  "status",
  "incidentStatus",
  "incidentReason",
  "totalPrice",
  "finalTotalPrice",
  "shippingPrice",
  "refundStatus",
  "refundAmount",
  "refundReason",
  "refundShippingAmount",
  "usedReedooCarrier",
  "managedByReedoo",
  "professionalSellerId",
  "storeName",
  "sellerName",
];

export const graphqlGetRentOrderFields: (keyof RentOrder)[] = [
  "id",
  "createdAt",
  "startingDate",
  "status",
  "frequency",
  "originalPricePerPeriod",
  "billingPricePerPeriod",
  "totalPaidRecurringAmount",
  "totalPurchasedAmount",
  "totalPaidAmount",
  "storeName",
];

export const graphqlGetRentOrderToProductFileds: (keyof RentOrderToProduct)[] =
  [
    "id",
    "createdAt",
    "updatedAt",
    "originalQuantity",
    "deposit",
    "pricePerPeriod",
    "purchasePrice",
  ];

export const graphqlGetSingleRentOrderToProduct: (keyof SingleRentOrderToProduct)[] =
  [
    "id",
    "createdAt",
    "damageFees",
    "status",
    "pdfPath",
    "trackingNumber",
    "trackingUrl",
    "trackingStatus",
    "returnPdfPath",
    "returnTrackingNumber",
    "returnTrackingUrl",
    "returnTrackingStatus",
    "parentOrderToProductId",
    "totalPaidRecurringAmount",
  ];

export const graphqlGetTransportationFields: (keyof Transportation)[] = [
  "id",
  "createdAt",
  "updatedAt",
  "pdfPath",
  "trackingNumber",
  "trackingUrl",
  "status",
  "carrierId",
  "pdfUrl",
];

export const graphqlGetHistoryFields: (keyof OrderHisory)[] = [
  "id",
  "createdAt",
  "title",
  "createdByRole",
  "status",
  "description",
];

export const graphqlGetOrderToProductFields: (keyof OrderToProduct)[] = [
  "id",
  "unitPrice",
  "totalPrice",
  "quantity",
  "productStatus",
  "status",
  "refundAmount",
  "refundReason",
  "commissionPercentage",
];

export const graphqlGetSelectReasonFields: (keyof SelectReason)[] = [
  "id",
  "reason",
  "type",
];

export const graphqlGetConversationFields: (keyof Conversation)[] = [
  "id",
  "buyerId",
  "professionalSellerId",
  "updatedAt",
];

export const graphqlGetMessageFields: (keyof Message)[] = [
  "id",
  "createdAt",
  "senderId",
  "recipientId",
  "filePath",
  "read",
  "type",
  "text",
];

export const graphGetCustomMessageFields: (keyof CustomMessage)[] = [
  "id",
  "type",
  "reason",
  "text",
];

export const graphqlGetProfileFields: (keyof Profile)[] = [
  "id",
  "firstName",
  "lastName",
  "description",
  "profilePicPath",
];

export const graphqlGetDeliveryAddressFields: (keyof DeliveryAddress)[] = [
  "id",
  "address1",
  "address2",
  "city",
  "name",
  "title",
  "zipCode",
  "pickupId",
  "pickupName",
  "country",
  "phoneNumber",
  "email",
];

export const graphqlGetOrderDeliveryPackageFields: (keyof DeliveryPackage)[] = [
  "id",
  "deliveryMethod",
  "deliveryArea",
  "minDays",
  "maxDays",
];

export const graphqlGetPaymentFields: (keyof Payment)[] = [
  "id",
  "paymentMethod",
];

export const graphqlUpsertFtpImportFields: (keyof FtpImport)[] = [
  "filePath",
  "active",
  "host",
  "username",
  "password",
  "protocol",
  "port",
];

export const graphqlGetFtpImportFields: (keyof FtpImport)[] = [
  ...graphqlUpsertFtpImportFields,
  "error",
];

export const graphqlGetReviewFields: (keyof Review)[] = [
  "id",
  "createdAt",
  "rating",
  "description",
  "response",
  "responseDate",
  "reviewedOrderId",
];

export const graphqlGetDashboardDataFields: (keyof DashboardData)[] = [
  "totalSales",
  "pendingOrdersCount",
  "averageReviews",
  "reviewsCount",
  "acceptedProductsRate",
  "incidentProductsRate",
  "refundedProductsRate",
  "productsCount",
  "ordersCount",
  "productsInStockCount",
  "productsOutOfStockCount",
  "totalSalesByMonth",
  "ordersCountByMonth",
  "productSoldCountByMonth",
  "publishedProductsCount",
  "invalidProductsCounts",
  "incidentsCount",
  "openIncidentsCount",
  "closedBySellerIncidentsCount",
  "pendingPaymentAmount",
  "payablePaymentAmount",
  "paidPaymentAmount",
  "rentOrderTotalPurchasedAmount",
  "rentOrderTotalPaidAmount",
  "rentOrderDailyTotalPaidRecurringAmount",
  "rentOrderWeeklyTotalPaidRecurringAmount",
  "rentOrderMonthlyTotalPaidRecurringAmount",
  "toBeShippedRentOrdersCount",
];

export const graphqlGetSaleDashboardDataFields: (keyof SaleDashboardData)[] = [
  "totalSales",
  "pendingOrdersCount",
  "acceptedProductsRate",
  "incidentProductsRate",
  "refundedProductsRate",
  "productsCount",
  "ordersCount",
  "productsInStockCount",
  "productsOutOfStockCount",
  "totalSalesByMonth",
  "ordersCountByMonth",
  "productSoldCountByMonth",
  "publishedProductsCount",
  "invalidProductsCounts",
  "incidentsCount",
  "openIncidentsCount",
  "closedBySellerIncidentsCount",
  "pendingPaymentAmount",
  "payablePaymentAmount",
  "paidPaymentAmount",
];

export const graphqlGetRentDashboardDataFields: (keyof RentDashboardData)[] = [
  "orderTotalPaidAmount",
  "orderTotalPurchasedAmount",
  "orderDailyTotalPaidRecurringAmount",
  "orderWeeklyTotalPaidRecurringAmount",
  "orderMonthlyTotalPaidRecurringAmount",
  "toBeShippedOrdersCount",
  "ordersCount",
  "productsCount",
  "productsInStockCount",
  "productsOutOfStockCount",
  "publishedProductsCount",
  "invalidProductsCounts",
  "paymentAmountsByMonth",
  "purchaseAmountsByMonth",
  "ordersCountByMonth",
];
export const graphqlGetMyTransactionsFields: (keyof MyTransaction)[] = [
  "id",
  "createdAt",
  "originalAmount",
  "totalAmount",
  "productsVat",
  "shippingAmount",
  "refundAmount",
  "commission",
  "commissionVat",
  "status",
];

export const graphqlGetMyPayOutsFields: (keyof MyPayOut)[] = [
  "id",
  "paidOutAt",
  "reedooCommissionAmount",
  "amount",
  "payoutStatus",
  "invoiceUrl",
];

export const graphqlGetStatisticsReportFields: (keyof StatisticsReport)[] = [
  "id",
  "createdAt",
  "reportPath",
  "type",
];

export const promoCodesFields: (keyof PromoCode)[] = [
  "id",
  "active",
  "code",
  "discountType",
  "amount",
  "threshold",
  "validUntil",
  "uses",
  "used",
  "maximumUsesPerUser",
];
