import { gql } from "@apollo/client";

import { graphqlGetAttributeValuesFields } from "~/constants/graphql";
import { AttributeValue } from "~/types/data/AttributeValue.types";

export interface AdminCreateAttributeValueResponse {
  adminCreateAttributeValue: AttributeValue;
}

export interface AdminUpdateAttributeValueResponse {
  adminUpdateAttributeValue: AttributeValue;
}

export interface AdminDeleteAttributeValueResponse {
  adminDeleteAttributeValue: AttributeValue;
}

export interface AdminCreateAttributeValueInput {
  AdminCreateAttributeValueInput: {
    value: string;
    associatedValues: string[];
    code?: string;
    attributeId: number;
  };
}

export interface AdminUpdateAttributeValueInput {
  AdminUpdateAttributeValueInput: {
    id: number;
    value: string;
    associatedValues: string[];
    code?: string;
  };
}

export interface AdminDeleteAttributeValueInput {
  AdminDeleteAttributeValueInput: {
    id: number;
  };
}

const attributeValuesFields = graphqlGetAttributeValuesFields.join(" ");

export const ADMIN_CREATE_ATTRIBUTE_VALUE = gql`
  mutation ($AdminCreateAttributeValueInput: AdminCreateAttributeValueInput!) {
    adminCreateAttributeValue(
      AdminCreateAttributeValueInput: $AdminCreateAttributeValueInput
    ) {
      ${attributeValuesFields}
    }
  }
`;

export const ADMIN_UPDATE_ATTRIBUTE_VALUE = gql`
  mutation ($AdminUpdateAttributeValueInput: AdminUpdateAttributeValueInput!) {
    adminUpdateAttributeValue(
      AdminUpdateAttributeValueInput: $AdminUpdateAttributeValueInput
    ) {
      ${attributeValuesFields}
    }
  }
`;

export const ADMIN_DELETE_ATTRIBUTE_VALUE = gql`
  mutation ($AdminDeleteAttributeValueInput: AdminDeleteAttributeValueInput!) {
    adminDeleteAttributeValue(
      AdminDeleteAttributeValueInput: $AdminDeleteAttributeValueInput
    ) {
      ${attributeValuesFields}
    }
  }
`;
