import CustomCheckbox from "~/components/form/CustomCheckbox";
import { SquareImage } from "~/components/UI/SquareImage";
import SVGContainer from "~/components/UI/SVGContainer";
import { Product } from "~/types/data/Product.types";
import { VendorCategory } from "~/types/data/VendorCategory.types";
import { formatDate } from "~/util/functions/formatDate";
import { formatPrice } from "~/util/functions/formatDecimal";

import styles from "./index.module.scss";

interface Props {
  productVariant: Product;
  isSelected: boolean;
  onCheckboxChange: (product: Product) => void;
  vendorCategories: VendorCategory[];
  onClick: (variantId: number) => void;
  showCompanyColumn: boolean;
}

export const CataloguesTableRow = ({
  productVariant,
  isSelected,
  onCheckboxChange,
  vendorCategories,
  onClick,
  showCompanyColumn,
}: Props) => {
  const {
    id = 0,
    categoryId,
    title,
    image1FullPath,
    brand,
    sku,
    vendorParentId,
    ean,
    quantity = 0,
    priceTTC,
    stateType,
    createdAt,
    updatedAt,
    status,
    storeName,
    sellerName,
  } = productVariant;

  const formattedPriceTTC = formatPrice(priceTTC);

  const categoryName = vendorCategories.find(
    ({ id }) => id === categoryId
  )?.name;

  const viewButtonClickHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    alert("View " + id);
  };

  const onClickFunction = id
    ? () => {
        onClick(id);
      }
    : () => {};

  return (
    <tr>
      <td className={styles.nonClickable}>
        <CustomCheckbox
          checked={isSelected}
          onChange={() => {
            onCheckboxChange(productVariant);
          }}
          padding="0.7rem"
        />
      </td>
      <td onClick={onClickFunction}>
        <div className={`${styles.imageContainer}`}>
          <SquareImage url={image1FullPath || ""} />
        </div>
      </td>
      <td onClick={onClickFunction}>
        <div>{title || "N/A"}</div>
        <div>{brand || "N/A"}</div>
        <div>{categoryName}</div>
      </td>
      <td onClick={onClickFunction}>
        <div>SKU: {sku || "N/A"}</div>
        <div>Code Variant: {vendorParentId || "N/A"}</div>
        <div>EAN: {ean || "N/A"}</div>
      </td>

      <td onClick={onClickFunction}>{quantity}</td>
      <td onClick={onClickFunction} className={styles.priceContainer}>
        {(!!priceTTC || priceTTC === 0) && (
          <p>{`Vente: ${formattedPriceTTC}`}</p>
        )}
      </td>
      <td onClick={onClickFunction}>{formatDate(createdAt)}</td>
      <td onClick={onClickFunction}>{formatDate(updatedAt)}</td>
      <td onClick={onClickFunction}>{status}</td>
      <td onClick={onClickFunction}>{stateType || "N/A"}</td>
      {showCompanyColumn && (
        <td className={styles.nonClickable}>
          {storeName || (!!sellerName ? `Profile: ${sellerName}` : "") || "N/A"}
        </td>
      )}

      <td className={styles.nonClickable}>
        <div className={`${styles.buttonsContainer}`}>
          <SVGContainer
            height="26px"
            width="26px"
            imagePath="assets/catalogue-view-button.svg"
            onClick={viewButtonClickHandler}
          />
        </div>
      </td>
    </tr>
  );
};
