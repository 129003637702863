import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { OrderBy } from "~/types/common/filter.types";
import { Company, KycLevelEnum } from "~/types/data/Company.types";

import type { RootState } from "../store";

export interface AdminCompaniesFilterArgs {
  orderBy: OrderBy<Company>;
  cursor?: number;
  take?: number;
  createdAtFrom?: string;
  createdAtTo?: string;
  kycLevel?: KycLevelEnum;
  searchBox?: string;
}

type FilterArgs =
  | "orderBy"
  | "cursor"
  | "createdAtFrom"
  | "createdAtTo"
  | "kycLevel"
  | "searchBox"
  | "clearAll";

export const adminCompaniesFilterArgsInitialState: AdminCompaniesFilterArgs = {
  orderBy: {},
  take: 30,
};

export const adminCompaniesFilterSlice = createSlice({
  name: "adminCompaniesFilter",
  initialState: adminCompaniesFilterArgsInitialState,
  reducers: {
    onFilterArgsChange(
      state,
      action: PayloadAction<{
        filterArg: FilterArgs;
        orderBy?: OrderBy<Company>;
        cursor?: number;
        createdAtFrom?: string;
        createdAtTo?: string;
        kycLevel?: KycLevelEnum;
        searchBox?: string;
      }>
    ) {
      const {
        filterArg,
        cursor,
        orderBy,
        createdAtFrom,
        createdAtTo,
        kycLevel,
        searchBox,
      } = action.payload;

      if (filterArg === "cursor") {
        state.cursor = cursor;
      } else if (filterArg === "orderBy" && orderBy) {
        state.orderBy = orderBy;
      } else if (filterArg === "createdAtFrom") {
        state.createdAtFrom = createdAtFrom;
      } else if (filterArg === "createdAtTo") {
        state.createdAtTo = createdAtTo;
      } else if (filterArg === "kycLevel") {
        state.kycLevel = kycLevel;
      } else if (filterArg === "searchBox") {
        state.searchBox = searchBox;
      } else if (filterArg === "clearAll") {
        return { ...adminCompaniesFilterArgsInitialState };
      }
    },
  },
});

export const { onFilterArgsChange } = adminCompaniesFilterSlice.actions;

export const selectAdminCompaniesFilterArgs = (state: RootState) => {
  return state.adminCompaniesFilter;
};
export default adminCompaniesFilterSlice.reducer;
