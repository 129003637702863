import CustomNavButton from "~/components/navigation/CustomNavButton";
import { colors } from "~/constants/styles";
import { routesBuilder } from "~/navigation/routes";

import styles from "./index.module.scss";

interface Props {
  orderId: string;
  hasMessages: boolean;
  unreadMessagesCount: number;
}

const OrderPageNavigation = ({
  orderId,
  hasMessages,
  unreadMessagesCount,
}: Props) => {
  const detailsRoute = routesBuilder({
    routes: [
      "mesCommandesRoute",
      "venteRoute",
      "detailsDuneCommandeRoute",
      "detailsRoute",
    ],
    replace: [{ position: 3, variable: orderId }],
  });

  const expeditionRoute = routesBuilder({
    routes: [
      "mesCommandesRoute",
      "venteRoute",
      "detailsDuneCommandeRoute",
      "expeditionRoute",
    ],
    replace: [{ position: 3, variable: orderId }],
  });

  const messagesRoute = routesBuilder({
    routes: [
      "mesCommandesRoute",
      "venteRoute",
      "detailsDuneCommandeRoute",
      "messagesRoute",
    ],
    replace: [{ position: 3, variable: orderId }],
  });

  const historiquesRoute = routesBuilder({
    routes: [
      "mesCommandesRoute",
      "venteRoute",
      "detailsDuneCommandeRoute",
      "historiquesRoute",
    ],
    replace: [{ position: 3, variable: orderId }],
  });

  return (
    <div className={styles.container}>
      <CustomNavButton
        label="Détails"
        to={`/${detailsRoute}`}
        borderColor={colors.$primary}
        height="fit-content"
        padding="0.2rem 0.5rem"
        borderRadius="5px"
        textColor={colors.$primary}
        activeBackgroundColor={colors.$primary}
        activeTextColor="white"
        width="max-content"
      />
      <CustomNavButton
        label="Expédition"
        to={`/${expeditionRoute}`}
        borderColor={colors.$primary}
        height="fit-content"
        padding="0.2rem 0.5rem"
        borderRadius="5px"
        textColor={colors.$primary}
        activeBackgroundColor={colors.$primary}
        activeTextColor="white"
        width="max-content"
      />
      {hasMessages && (
        <CustomNavButton
          label="Messages"
          to={`/${messagesRoute}`}
          borderColor={colors.$primary}
          height="fit-content"
          padding="0.2rem 0.5rem"
          borderRadius="5px"
          textColor={colors.$primary}
          activeBackgroundColor={colors.$primary}
          activeTextColor="white"
          count={unreadMessagesCount}
          width="max-content"
        />
      )}
      <CustomNavButton
        label="Historiques"
        to={`/${historiquesRoute}`}
        borderColor={colors.$primary}
        height="fit-content"
        padding="0.2rem 0.5rem"
        borderRadius="5px"
        textColor={colors.$primary}
        activeBackgroundColor={colors.$primary}
        activeTextColor="white"
        width="max-content"
      />
    </div>
  );
};

export default OrderPageNavigation;
