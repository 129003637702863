import { useMutation } from "@apollo/client";
import { GraphQLErrors } from "@apollo/client/errors";
import { useState } from "react";
import { toast } from "react-toastify";

import {
  ADMIN_CREATE_VENDOR_CATEGORIES,
  ADMIN_DELETE_VENDOR_CATEGORIES,
  ADMIN_UPDATE_VENDOR_CATEGORIES,
  CreateVendorCategoriesResponse,
  CreateVendorCategoryInput,
  DeleteVendorCategoryInput,
  DeleteVendorCategoryResponse,
  UpdateVendorCategoriesResponse,
  UpdateVendorCategoryInput,
} from "~/api/graphql/vendorCategory";
import { VendorCategory } from "~/types/data/VendorCategory.types";

export type CategoryMappingState = {
  category1Id: number | null;
  category2Id: number | null;
  category3Id: number | null;
};

export type VendorCategoryState = {
  id?: number | string;
  name: string;
  associatedNames: string[];
  categoryMappings: CategoryMappingState[];
  attributes: number[];
  didChange: boolean;
};

export const useVendorCategories = ({
  associatedNames,
  attributes,
  categoryMappings,
  name,
  id,
}: Partial<VendorCategoryState>) => {
  const [createVendorCategory, { loading: l1 }] = useMutation<
    CreateVendorCategoriesResponse,
    CreateVendorCategoryInput
  >(ADMIN_CREATE_VENDOR_CATEGORIES);

  const [updateVendorCategory, { loading: l2 }] = useMutation<
    UpdateVendorCategoriesResponse,
    UpdateVendorCategoryInput
  >(ADMIN_UPDATE_VENDOR_CATEGORIES);

  const [deleteVendorCategory, { loading: l3 }] = useMutation<
    DeleteVendorCategoryResponse,
    DeleteVendorCategoryInput
  >(ADMIN_DELETE_VENDOR_CATEGORIES);

  const [vendorCategoriesState, setVendorCategoriesState] =
    useState<VendorCategoryState>({
      id: id,
      name: name ?? "",
      associatedNames: associatedNames ?? [],
      categoryMappings: categoryMappings ?? [],
      attributes: attributes ?? [],
      didChange: false,
    });

  const updateCategoryMappings = (
    index: number,
    categoryId: number | null,
    categoryLevel: 1 | 2 | 3
  ) => {
    const updatedCategoryMapping = [...vendorCategoriesState.categoryMappings];
    updatedCategoryMapping[index][`category${categoryLevel}Id`] = categoryId;
    setVendorCategoriesState({
      ...vendorCategoriesState,
      categoryMappings: updatedCategoryMapping,
      didChange: true,
    });
  };

  const updateVendorCategoryState = (
    key: keyof VendorCategoryState,
    value: string | string[] | number[] | boolean
  ) => {
    setVendorCategoriesState({
      ...vendorCategoriesState,
      [key]: value,
      didChange: true,
    });
  };

  const addNewCategoryMapping = () => {
    setVendorCategoriesState({
      ...vendorCategoriesState,
      categoryMappings: [
        ...vendorCategoriesState.categoryMappings,
        {
          category1Id: null,
          category2Id: null,
          category3Id: null,
        },
      ],
      didChange: true,
    });
  };

  const deleteCategoryMapping = (index: number) => {
    const updatedCategoryMappings = [
      ...vendorCategoriesState.categoryMappings,
    ].filter((_, mappingIndex) => mappingIndex !== index);

    setVendorCategoriesState({
      ...vendorCategoriesState,
      categoryMappings: updatedCategoryMappings,
      didChange: true,
    });
  };

  const saveCategory = async () => {
    try {
      if (!vendorCategoriesState.didChange) return;

      const { id, associatedNames, attributes, categoryMappings } =
        vendorCategoriesState;
      let data: VendorCategory | undefined;
      if (typeof id == "number") {
        const response = await updateVendorCategory({
          variables: {
            UpdateVendorCategoryInput: {
              id,
              associatedNames,
              attributes,
              categoryMappings,
            },
          },
        });
        data = response.data?.updateVendorCategory;
        if (response.errors) {
          toast.error((response.errors as GraphQLErrors)[0].message);
        }
      } else {
        const response = await createVendorCategory({
          variables: {
            CreateVendorCategoryInput: {
              associatedNames,
              attributes,
              categoryMappings,
            },
          },
        });

        data = response.data?.createVendorCategory;
        if (response.errors) {
          toast.error((response.errors as GraphQLErrors)[0].message);
        }
      }
      if (!data) return;

      setVendorCategoriesState({
        ...vendorCategoriesState,
        id: data.id,
        name: data.name,
        didChange: false,
      });
    } catch (e: unknown) {
      const error = e as { errors: GraphQLErrors; message: string | string[] };

      toast.error(
        error.message.toString() ??
          error?.errors?.[0]?.message ??
          "Error saving vendor category"
      );
    }
  };

  const deleteCategory = async () => {
    try {
      if (typeof vendorCategoriesState.id !== "number")
        return updateVendorCategoryState("categoryMappings", []);

      const response = await deleteVendorCategory({
        variables: {
          DeleteVendorCategoryInput: {
            id: vendorCategoriesState.id,
          },
        },
      });

      if (response.errors) {
        toast.error(
          response.errors?.[0]?.message ?? "Error deleting vendor category"
        );
        return;
      }
      if (response.data) {
        updateVendorCategoryState("categoryMappings", []);
      }
    } catch (e: unknown) {
      const error = e as { errors: GraphQLErrors; message: string | string[] };

      toast.error(
        error.message.toString() ??
          error?.errors?.[0]?.message ??
          "Error deleting vendor category"
      );
    }
  };

  return {
    vendorCategoriesState,
    setVendorCategoriesState,
    updateCategoryMappings,
    updateVendorCategoryState,
    addNewCategoryMapping,
    deleteCategoryMapping,
    deleteCategory,
    saveCategory,
    loading: l1 || l2 || l3,
  };
};
