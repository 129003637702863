import { SizedBox } from "../../components/UI/SizedBox";
import { ScrollToWrapper } from "../../components/UI/Wrapper";
import { AbonnementPlateforme } from "./AbonnementPlateforme";
import { Cgu } from "./Cgu";
import { CharteEthique } from "./CharteEthique";
import { GuidesUtilisateur } from "./GuidesUtilisateur";
import styles from "./index.module.scss";
import { ListeTransporteur } from "./ListeTransporteur";
import { DataPlatformNavigation } from "./NavigationSection";
import { Taxonomie } from "./Taxonomie";

export enum DataPlatformPagesEnum {
  taxonomie = "Taxonomie",
  platformSubscription = "Abonnement à la plateforme",
  cGU = "CGU",
  codeOfEthics = "Charte éthique",
  userGuide = "Guides utilisateur",
  logisticClasses = "Liste transporteur",
  transportInformation = "transportInformation",
}

export const DataPlatform = () => {
  const scrollToSection = (sectionName: DataPlatformPagesEnum) => {
    const section = document.getElementById(sectionName);

    if (section) section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const emptySpace = "2rem";
  return (
    <>
      <div className={styles.mainContainer}>
        <DataPlatformNavigation onClick={scrollToSection} />
        <h2>Données plateforme</h2>
        <ScrollToWrapper name={DataPlatformPagesEnum.taxonomie}>
          <Taxonomie />
        </ScrollToWrapper>
        <SizedBox height={emptySpace} />
        <ScrollToWrapper name={DataPlatformPagesEnum.platformSubscription}>
          <AbonnementPlateforme />
        </ScrollToWrapper>
        <SizedBox height={emptySpace} />
        <ScrollToWrapper name={DataPlatformPagesEnum.cGU}>
          <Cgu />
        </ScrollToWrapper>
        <SizedBox height={emptySpace} />
        <ScrollToWrapper name={DataPlatformPagesEnum.codeOfEthics}>
          <CharteEthique />
        </ScrollToWrapper>
        <SizedBox height={emptySpace} />
        <ScrollToWrapper name={DataPlatformPagesEnum.userGuide}>
          <GuidesUtilisateur />
        </ScrollToWrapper>
        <SizedBox height={emptySpace} />
        <ScrollToWrapper name={DataPlatformPagesEnum.logisticClasses}>
          <ListeTransporteur />
        </ScrollToWrapper>
      </div>
    </>
  );
};
