import styles from "./index.module.scss";

interface Props {
  mediaType: "image" | "video";
  url: string;
}

export const FullMediaPreview = ({ mediaType, url }: Props) => {
  return (
    <div className={`${styles.container}`}>
      {mediaType === "image" ? (
        <img src={url} width="100%" height="100%" />
      ) : (
        <video src={url} width="100%" height="100%" controls />
      )}
    </div>
  );
};
