import { useDispatch } from "react-redux";

import CustomCheckbox from "~/components/form/CustomCheckbox";
import { SortingButtons } from "~/components/UI/SortingButtons";
import { useAppSelector } from "~/redux/hooks";
import {
  onFilterArgsChange,
  selectCatalogueFilterArgs,
} from "~/redux/slice/catalogueFilter.slice";
import { OrderByEnum } from "~/types/common/filter.types";
import { HeadCell } from "~/types/common/headCell.type";
import { Product } from "~/types/data/Product.types";

import styles from "./index.module.scss";

interface Props {
  headCells: HeadCell<Product>[];
  onSelectAllChange: (selected: boolean) => void;
  allSelected: boolean;
}

export const CataloguesTableHead = ({
  onSelectAllChange,
  headCells,
  allSelected,
}: Props) => {
  const selectAllChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onSelectAllChange(event.target.checked);
  };

  const { orderBy } = useAppSelector(selectCatalogueFilterArgs);

  const dispatch = useDispatch();

  const orderByHandler = (key: keyof Product, order: OrderByEnum) => {
    dispatch(
      onFilterArgsChange({ filterArg: "orderBy", orderBy: { [key]: order } })
    );
  };
  return (
    <thead>
      <tr>
        {headCells.map((headCell) => {
          const isSortable = headCell.isSortable;
          const isCheckbox = headCell.isCheckbox;
          const label = headCell.label;
          const id = headCell.id;

          if (isCheckbox) {
            return (
              <th key={id}>
                <CustomCheckbox
                  onChange={selectAllChangeHandler}
                  checked={allSelected}
                  padding="0.7rem"
                />
              </th>
            );
          }
          return (
            <th key={id}>
              <div className={styles.headCell}>
                <p>{label}</p>
                {isSortable && (
                  <SortingButtons
                    ascendingActive={
                      !!(
                        headCell.associatedKey &&
                        orderBy[headCell.associatedKey] === OrderByEnum.ASC
                      )
                    }
                    descendingActive={
                      !!(
                        headCell.associatedKey &&
                        orderBy[headCell.associatedKey] === OrderByEnum.DESC
                      )
                    }
                    orderByAscendingingHandler={() => {
                      orderByHandler(
                        headCell.associatedKey || "id",
                        OrderByEnum.ASC
                      );
                    }}
                    orderByDescendingHandler={() => {
                      orderByHandler(
                        headCell.associatedKey || "id",
                        OrderByEnum.DESC
                      );
                    }}
                  />
                )}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};
