import Joi from "joi";

import { UpdateCompanyDetailsInput } from "~/types/data/Company.types";

export const updateDetailsValidationSchema =
  Joi.object<UpdateCompanyDetailsInput>({
    id: Joi.optional(),
    uboDeclarationId: Joi.optional(),
    mangopayId: Joi.optional(),
    companyName: Joi.string()
      .required()
      .error(new Error("Nom can't be empty.")),
    socialReason: Joi.string()
      .required()
      .error(new Error("Raison social can't be empty.")),
    siret: Joi.string().required().error(new Error("SIRET can't be empty.")),
    intercomTVA: Joi.string()
      .required()
      .error(new Error("TVA intracommunautaire can't be empty.")),
    companyAddress: Joi.string()
      .required()
      .error(new Error("Company adresse can't be empty.")),
    companyZipCode: Joi.string()
      .required()
      .error(new Error("Company code postal can't be empty.")),
    companyCity: Joi.string()
      .required()
      .error(new Error("Company ville can't be empty.")),
    companyPhoneNumber: Joi.string()
      .required()
      .error(new Error("Téléphone can't be empty.")),
    companyPhoneNumber2: Joi.optional(),
    website: Joi.string()
      .required()
      .error(new Error("Site internet can't be empty.")),
    email: Joi.string()
      .email({ tlds: false, allowUnicode: true })
      .required()
      .error(new Error("Adresse e-mail invalid.")),
    country: Joi.string().required().error(new Error("Pays can't be empty.")),
    legalRepLastName: Joi.string()
      .required()
      .error(new Error("Legal representative nom can't be empty.")),
    legalRepFirstName: Joi.string()
      .required()
      .error(new Error("Legal representative prénom can't be empty.")),
    legalRepEmail: Joi.string()
      .email({ tlds: false, allowUnicode: true })
      .required()
      .error(new Error("Legal representative adresse e-mail invalid.")),
    legalRepAddress: Joi.string()
      .required()
      .error(new Error("Legal representative adresse can't be empty.")),
    legalRepZipCode: Joi.string()
      .required()
      .error(new Error("Legal representative code postal can't be empty.")),
    legalRepCity: Joi.string()
      .required()
      .error(new Error("Legal representative ville can't be empty.")),
    legalRepCountry: Joi.string()
      .required()
      .error(new Error("Legal representative pays can't be empty.")),
    legalRepBirthday: Joi.string()
      .isoDate()
      .required()
      .error(new Error("Legal representative date de naissance invalid.")),
  });
