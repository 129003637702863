import React, { FunctionComponent } from "react";

import styles from "./index.module.scss";

interface Props {
  placeholder?: string;
  value: string | undefined;
  onChange: (changes: string) => void;
  required?: boolean;
  disabled?: boolean;
  style?: object;
  readonly?: boolean;
  noBorder?: boolean;
  label?: string;
  backgroundColor?: string;
  textColor?: string;
  borderRadius?: string;
  fontSize?: string;
  rows?: number;
}

export const CustomTextArea: FunctionComponent<Props> = ({
  placeholder,
  value = "",
  onChange,
  required = false,
  disabled = false,
  noBorder = false,
  label,
  backgroundColor,
  textColor,
  borderRadius,
  fontSize,
  rows,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!onChange) return;
    onChange(event.target.value);
  };

  return (
    <div className={`${styles.container}`} style={{ fontSize: fontSize }}>
      {label && (
        <label className={`${styles.label}`}>
          {label}
          {required && <span>*</span>}
        </label>
      )}
      <textarea
        value={value}
        className={`${styles.input} ${noBorder ? styles.noBorder : ""}`}
        autoComplete="on"
        placeholder={placeholder || ""}
        onChange={handleChange}
        disabled={disabled}
        style={{
          backgroundColor: backgroundColor,
          color: textColor,
          borderRadius: borderRadius,
          marginTop: label ? "0.3rem" : "",
        }}
        rows={rows || 5}
      />
    </div>
  );
};
