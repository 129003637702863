import styles from "./index.module.scss";

type Props = {
  children: React.ReactNode;
};

const FormCard = ({ children }: Props) => {
  return <div className={`${styles.card}`}>{children}</div>;
};

export default FormCard;
