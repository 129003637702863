import { useMutation } from "@apollo/client";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

import {
  GENERATE_STATISTICS_REPORT,
  GenerateStatisticsReportInput,
  GenerateStatisticsReportResponse,
} from "~/api/graphql/statisticsReport";
import { CustomButton } from "~/components/form/CustomButton";
import { CustomInput } from "~/components/form/CustomInput";
import DatePicker from "~/components/form/DatePicker";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { BrandCompany } from "~/types/data/BrandCompany.type";
import { StatisticsReportTypeEnum } from "~/types/data/StatisticsReport.type";

import styles from "./index.module.scss";

interface Props {
  brandCompanies?: BrandCompany[];
  fetchBrandCompaniesError?: string;
  hideModalTrigger: () => void;
}

export const GenerateStatistiscsReportModal = ({
  hideModalTrigger,
  brandCompanies,
  fetchBrandCompaniesError,
}: Props) => {
  const [statisticsReportUrl, setStatisticsReportUrl] = useState<string>("");
  const [generateReportLoading, setGenerateReportLoading] =
    useState<boolean>(false);
  const [generateReportError, setGenerateReportError] = useState<string>("");
  const [selectedBrandCompany, setSelectedBrandCompany] = useState<number>();
  const [reportType, setReportType] = useState<StatisticsReportTypeEnum>(
    StatisticsReportTypeEnum.SUBSCRIPTIONS
  );

  const [yearReportYear, setYearReportYear] = useState<number>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [monthReportDate, setMonthReportDate] = useState<string>();

  const brandCompanyOptions: OptionType[] =
    brandCompanies?.map((bc) => ({
      label: bc.name,
      value: `${bc.id}`,
    })) || [];

  const reportTypeOptions: OptionType[] = [
    {
      label: "Subscriptions",
      value: StatisticsReportTypeEnum.SUBSCRIPTIONS,
    },
  ];

  const [generateStatisticsReportTrigger] = useMutation<
    GenerateStatisticsReportResponse,
    GenerateStatisticsReportInput
  >(GENERATE_STATISTICS_REPORT);

  const generateSubscriptionsReportHandler = async () => {
    if (!selectedBrandCompany) {
      setGenerateReportError("Please select a brand company");
      return;
    }
    setGenerateReportLoading(true);
    setGenerateReportError("");
    const monthReportMonth = DateTime.fromISO(monthReportDate || "").month;
    const monthReportYear = DateTime.fromISO(monthReportDate || "").year;
    await generateStatisticsReportTrigger({
      variables: {
        GenerateStatisticsReportInput: {
          brandId: selectedBrandCompany,
          type: reportType,
          monthReportMonth,
          monthReportYear,
          yearReportYear,
          startDate,
          endDate,
        },
      },
      onError: (err) => {
        setGenerateReportLoading(false);
        setGenerateReportError(err.message);
      },
      onCompleted: (data) => {
        setStatisticsReportUrl(data.generateStatisticsReport.reportFullPath);
        setGenerateReportLoading(false);
      },
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (statisticsReportUrl) {
        const link = document.getElementById("statistics-report-download-link");
        // modify href
        link?.setAttribute("href", statisticsReportUrl);
        // trigger download
        link?.click();
        hideModalTrigger();
      }
    }, 2000);
  }, [statisticsReportUrl]);

  return (
    <>
      {fetchBrandCompaniesError ? (
        `Error: ${fetchBrandCompaniesError}`
      ) : !brandCompanies ? (
        <SimpleLoader size="size3" fill={colors.$primary} />
      ) : (
        <>
          <a
            href={statisticsReportUrl}
            className={styles.hiddenLink}
            id="statistics-report-download-link"
            target="_blank"
            rel="noreferrer"
          ></a>
          <div className={styles.container}>
            <h2>Generate Statistics Report</h2>
            <div className={styles.formContainer}>
              <div className={styles.formRow}>
                <SelectInput
                  onChange={(value) => {
                    setSelectedBrandCompany(+(value?.value ?? 0));
                  }}
                  options={brandCompanyOptions}
                  value={selectedBrandCompany ? `${selectedBrandCompany}` : ""}
                  label="Brand Company"
                  backgroundColor="#f2f2f2"
                  noBorder
                  required
                />
                <SelectInput
                  onChange={(value) => {
                    setReportType(value?.value as StatisticsReportTypeEnum);
                  }}
                  options={reportTypeOptions}
                  value={reportType}
                  label="Report type"
                  backgroundColor="#f2f2f2"
                  noBorder
                  required
                />
              </div>
              <div>
                <label>Rapport mensuel</label>
                <div className={styles.formRow}>
                  <DatePicker
                    views={["year", "month"]}
                    label="Month"
                    fontSize="14px"
                    icon="CalendarMonthOutlined"
                    onChange={(value: DateTime | null) => {
                      if (value?.isValid !== false) {
                        setMonthReportDate(value?.toISO() || "");
                      } else {
                        setMonthReportDate("invalid");
                      }
                    }}
                    value={DateTime.fromISO(monthReportDate || "")}
                    backgroundColor={colors.$inputGray}
                    noBorder={true}
                    borderRadius="5px"
                  />
                  {/* <SelectInput
                    onChange={(value) => {
                      if (value) setMonthReportMonth(+value.value);
                    }}
                    options={monthOptions}
                    value={monthReportMonth ? `${monthReportMonth}` : ""}
                    label="Month"
                    backgroundColor="#f2f2f2"
                    noBorder
                  /> */}
                  {/* <CustomInput
                    value={monthReportYear}
                    onChange={(value: string | number) => {
                      setMonthReportYear(value ? +value : undefined);
                    }}
                    type="number"
                    backgroundColor="#f2f2f2"
                    noBorder
                    borderRadius="5px"
                    label="Year"
                  /> */}
                </div>
              </div>
              <div>
                <label>Rapport annuel</label>
                <CustomInput
                  value={yearReportYear}
                  onChange={(value: string | number) => {
                    setYearReportYear(value ? +value : undefined);
                  }}
                  type="number"
                  backgroundColor="#f2f2f2"
                  noBorder
                  borderRadius="5px"
                  label="Year"
                />
              </div>
              <div>
                <label>Rapport périodique</label>
                <div className={styles.formRow}>
                  <DatePicker
                    label="Start Date"
                    fontSize="14px"
                    icon="CalendarMonthOutlined"
                    onChange={(value: DateTime | null) => {
                      if (value?.isValid !== false) {
                        setStartDate(value?.toISO() || "");
                      } else {
                        setStartDate("invalid");
                      }
                    }}
                    value={DateTime.fromISO(startDate || "")}
                    backgroundColor={colors.$inputGray}
                    noBorder={true}
                    borderRadius="5px"
                  />

                  <DatePicker
                    label="End Date"
                    fontSize="14px"
                    icon="CalendarMonthOutlined"
                    onChange={(value: DateTime | null) => {
                      if (value?.isValid !== false) {
                        setEndDate(value?.toISO() || "");
                      } else {
                        setEndDate("invalid");
                      }
                    }}
                    value={DateTime.fromISO(endDate || "")}
                    backgroundColor={colors.$inputGray}
                    noBorder={true}
                    borderRadius="5px"
                  />
                </div>
              </div>
              <div className={styles.buttonContainer}>
                {generateReportError && <p>{generateReportError}</p>}
                <CustomButton
                  onClick={() => {
                    generateSubscriptionsReportHandler();
                  }}
                  backgroundColor={colors.$primary}
                  color="white"
                  borderRadius="7px"
                  width="8rem"
                  height="2.5rem"
                >
                  {generateReportLoading ? (
                    <SimpleLoader size="size2" />
                  ) : (
                    <span>Generate</span>
                  )}
                </CustomButton>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
