import { Product } from "~/types/data/Product.types";
import { VendorCategory } from "~/types/data/VendorCategory.types";

export const hasAttribute = (
  categoryId: number,
  vendorCategories: VendorCategory[],
  attributeName: keyof Product
) => {
  const attribute = vendorCategories
    .find((category) => {
      return category.id === categoryId;
    })
    ?.attributes.find((attribute) => {
      return attribute.name === attributeName;
    });

  return !!attribute;
};
