export enum StatisticsReportTypeEnum {
  SUBSCRIPTIONS = "SUBSCRIPTIONS",
}

export interface StatisticsReport {
  id: number;
  createdAt: string;
  reportPath: string;
  reportFullPath: string;
  type: StatisticsReportTypeEnum;
}
