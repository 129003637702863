import Select, { StylesConfig } from "react-select";

import { OptionType } from "../SelectInput";
import styles from "./index.module.scss";

interface Props {
  placeholder?: string;
  label?: string;
  fontSize?: string;
  backgroundColor?: string;
  noBorder?: boolean;
  onChange: (options: OptionType[]) => void;
  borderWidth?: string;
  value: OptionType[];
  options: OptionType[];
  disabled?: boolean;
  required?: boolean;
  invalidColor?: string;
  invalid?: boolean;
  hideClearIndicator?: boolean;
  hideDropdownIndicator?: boolean;
}

export const MultipleSelectInput = ({
  placeholder,
  label,
  fontSize,
  backgroundColor,
  noBorder,
  value,
  required,
  options,
  onChange,
  disabled,
  borderWidth = "1px",
  invalidColor,
  invalid,
  hideClearIndicator,
  hideDropdownIndicator,
}: Props) => {
  const isError = (!!invalidColor && !value) || invalid;
  const customStyles: StylesConfig = {
    control: (base, state) => ({
      ...base,
      height: 40,
      minHeight: 40,
      backgroundColor: backgroundColor,
      border: isError
        ? `${borderWidth} solid ${invalidColor || "#870000"}`
        : state.isFocused
        ? `${borderWidth} solid black`
        : noBorder
        ? "none"
        : "",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      height: "90%",
      overflowY: "auto",
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 999,
    }),
  };

  return (
    <div className={`${styles.container}`} style={{ fontSize: fontSize }}>
      {label && (
        <div className={`${styles.label}`}>
          <label>{label}</label>
          {required && <span>*</span>}
        </div>
      )}
      <Select
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
          ...(hideClearIndicator && {
            ClearIndicator: () => null,
          }),
          ...(hideDropdownIndicator && {
            DropdownIndicator: () => null,
          }),
        }}
        styles={customStyles}
        isMulti={true}
        value={value}
        options={options}
        onChange={(option) => onChange(option as OptionType[])}
        isDisabled={disabled}
        menuPosition="fixed"
        menuShouldBlockScroll={true}
        menuPortalTarget={document.querySelector("body")}
      />
    </div>
  );
};
