import SVGContainer from "~/components/UI/SVGContainer";

import styles from "./index.module.scss";

type Props = {
  name: string;
  discountPercent: number;
  isOpen: boolean;
  haveChildren: boolean;
  onClick: () => void;
};
export const CategoryToggle = ({
  name,
  discountPercent,
  isOpen,
  haveChildren,
  onClick,
}: Props) => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.iconContainer}>
        {haveChildren && (
          <div onClick={onClick}>
            {isOpen ? (
              <SVGContainer
                height="14px"
                width="14px"
                imagePath="/assets/subtract-icon.svg"
              />
            ) : (
              <SVGContainer
                height="14px"
                width="14px"
                imagePath="/assets/black-circular-add-icon.svg"
              />
            )}
          </div>
        )}
      </div>
      <div>{name}</div>
      {!!discountPercent && (
        <div className={styles.discountContainer}>{discountPercent}%</div>
      )}
    </div>
  );
};
