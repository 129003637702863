import { DataPlatformPagesEnum } from "../../../pages/DataPlatform";
import styles from "./index.module.scss";
type Props = {
  children: React.ReactNode;
  name: DataPlatformPagesEnum;
};

export const ScrollToWrapper = ({ children, name }: Props) => {
  return (
    <div className={styles.contentContainer} id={name}>
      <h3 className={styles.contentHeader}>{name}</h3>
      {children}
    </div>
  );
};
