import { useState } from "react";
import { SingleValue } from "react-select";

import createUser from "~/api/auth/createUser";
import updateUser from "~/api/auth/updateUser";
import { CustomButton } from "~/components/form/CustomButton";
import { CustomInput } from "~/components/form/CustomInput";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import {
  CreateUserInput,
  PermissionLevelEnum,
  RoleEnum,
  UpdateUserInput,
  User,
} from "~/types/data/User.types";
import { createSellerValidationSchema } from "~/util/validation/companyAccount/createSeller.schema";
import { updateSellerValidationSchema } from "~/util/validation/companyAccount/updateSeller.schema";

import styles from "./index.module.scss";

interface Props {
  user?: User;
}

interface FormState {
  email: string;
  permissionLevel: PermissionLevelEnum;
  username: string;
}

const permisionLevelOptions: OptionType[] = [
  { label: "Contributer", value: PermissionLevelEnum.MEMBER },
  { label: "Administration", value: PermissionLevelEnum.ADMIN },
];

export const UserForm = ({ user }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userId, setUserId] = useState<number | undefined>(user?.id);

  const [formState, setFormState] = useState<FormState>({
    email: user?.email || "",
    permissionLevel: user?.permissionLevel || PermissionLevelEnum.MEMBER,
    username: user?.username || "",
  });

  const { companyAccount, setCompanyAccount } = useCompanyAccountContext();

  const inputChangeHandler = <T extends keyof FormState>(
    inputName: T,
    changes: FormState[T]
  ) => {
    setFormState((state) => ({ ...state, [inputName]: changes }));
  };

  const formSubmitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");
    // do validation and checking here
    setIsLoading(true);
    // if the user exists update
    if (userId) {
      const input: UpdateUserInput = {
        id: userId,
        permissionLevel: formState.permissionLevel,
      };

      // validate input
      const result = updateSellerValidationSchema.validate(input);
      const { error } = result;
      if (error) {
        setErrorMessage(error.message);
        setIsLoading(false);
        return;
      }
      const res = await updateUser(input);
      if (res.success) {
        setSuccessMessage("User updated successfully.");

        setCompanyAccount((prev) => {
          const updatedUsersArray = prev.users.map((user) => {
            return user.id === input.id ? res.data : user;
          });
          return { ...prev, users: updatedUsersArray };
        });
      } else {
        setErrorMessage(res.errorMessage || "Unknown error");
      }
    } else {
      const input: CreateUserInput = {
        email: formState.email,
        username: formState.username,
        permissionLevel: formState.permissionLevel,
        companyId: companyAccount.id,
        role: RoleEnum.SELLER,
      };

      // validate input
      const result = createSellerValidationSchema.validate(input);
      const { error } = result;
      if (error) {
        setErrorMessage(error.message);
        setIsLoading(false);
        return;
      }
      const res = await createUser(input);
      if (res.success) {
        setSuccessMessage("User created successfully.");
        setUserId(res.data.id);
        setCompanyAccount((prev) => {
          return { ...prev, users: [...prev.users, res.data] };
        });
      } else {
        setErrorMessage(res.errorMessage || "Unknown error");
      }
    }

    setIsLoading(false);
  };

  return (
    <form
      className={`${styles.container} myAccountFormSection`}
      onSubmit={(e) => {
        formSubmitHandler(e);
      }}
    >
      <div className={`${styles.header}`}>
        <h2>Éditer un utilisateur</h2>
      </div>
      <div className={`${styles.subheader}`}>
        <p>Renseignez les informations ci-dessous :</p>
      </div>
      <div className={`${styles.formContainer}`}>
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("username", value.toString());
          }}
          value={formState.username}
          label="Username"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          disabled={!!userId}
        />
      </div>
      <div className={`${styles.formContainer}`}>
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("email", value.toString());
          }}
          value={formState.email}
          label="Adresse e-mail"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          disabled={!!userId}
        />
        <SelectInput
          label="Niveau de droits"
          fontSize="14px"
          backgroundColor={colors.$inputGray}
          noBorder={true}
          options={permisionLevelOptions}
          onChange={(option: SingleValue<OptionType>) => {
            inputChangeHandler(
              "permissionLevel",
              option?.value as PermissionLevelEnum
            );
          }}
          value={formState.permissionLevel}
        />
      </div>
      <div className="messageContainer">
        {!!errorMessage && <p className="errorMessage">{errorMessage}</p>}
        {!!successMessage && <p>{successMessage}</p>}
      </div>
      <div className={`${styles.buttonContainer}`}>
        <CustomButton
          backgroundColor={colors.$primary}
          color="white"
          width="fit-content"
          borderRadius="8px"
          padding="1rem 1.5rem"
          type="submit"
        >
          {isLoading ? <SimpleLoader size="size2" /> : "Valider"}
        </CustomButton>
      </div>
    </form>
  );
};
