import { OrderHistoryStatusMapping } from "~/types/data/OrderHistory.types";
import { RentOrderStatusEnum } from "~/types/data/RentOrder.type";

export const mapOrderHistoryStatus: OrderHistoryStatusMapping = {
  ON_HOLD: "En attente",
  REFUSED: "Refusé",
  ACCEPTED: "Accepté",
  OPEN: "Ouvert",
  WAITING_FOR_CONFIRMATION: "En attente d'acceptation",
  WAITING_FOR_EXPEDITION: "En attente d'expédition",
  WAITING_FOR_RECEPTION: "En attente de réception",
  WAITING_FOR_VALIDATION: "En attente de fermeture",
  CANCELLED: "Refusé",
  CLOSED: "Fermé",
  INCIDENT_CLOSED_BY_SELLER: "Incident fermé par le vendeur",
  INCIDENT_CLOSED: "Incident clos",
  INCIDENT_OPEN: "Incident ouvert",
  INCIDENT_REOPENED: "Incident réouvert",
};

export const mapRentOrderStatus = (status: RentOrderStatusEnum) => {
  switch (status) {
    case RentOrderStatusEnum.ACTIVE:
      return "En cours";
    case RentOrderStatusEnum.ENDED:
      return "Terminé";
    default:
      return "En attente";
  }
};

export const mapRentOrderStatusColor = (status: RentOrderStatusEnum) => {
  switch (status) {
    case RentOrderStatusEnum.ACTIVE:
      return "green";
    case RentOrderStatusEnum.ENDED:
      return "red";
    default:
      return "orange";
  }
};
