import { DateTime } from "luxon";
import { SingleValue } from "react-select";

import CustomCheckbox from "~/components/form/CustomCheckbox";
import DatePicker from "~/components/form/DatePicker";
import { InputWithIcon } from "~/components/form/InputWithIcon";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import SVGContainer from "~/components/UI/SVGContainer";
import { colors } from "~/constants/styles";
import { useAppDispatch, useAppSelector } from "~/redux/hooks";
import {
  onFilterArgsChange,
  selectOrdersFilterArgs,
} from "~/redux/slice/ordersFilter.slice";
import { Carrier } from "~/types/data/Carrier.types";
import { OrderStatusEnum } from "~/types/data/Order.types";

import styles from "./index.module.scss";

const statusOptions: OptionType[] = [
  {
    label: "Waiting for confirmation",
    value: OrderStatusEnum.WAITING_FOR_CONFIRMATION,
  },
  {
    label: "Waiting for expedition",
    value: OrderStatusEnum.WAITING_FOR_EXPEDITION,
  },
  {
    label: "Waiting for reception",
    value: OrderStatusEnum.WAITING_FOR_RECEPTION,
  },
  {
    label: "Waiting for validation",
    value: OrderStatusEnum.WAITING_FOR_VALIDATION,
  },
  { label: "Closed", value: OrderStatusEnum.CLOSED },
];

interface Props {
  carriers: Carrier[];
}

export const OrdersFilterForm = ({ carriers }: Props) => {
  const filterArgsState = useAppSelector(selectOrdersFilterArgs);

  const carriersOptions: OptionType[] = carriers.map(({ id, name }) => {
    return { label: name, value: id.toString() };
  });

  const dispatch = useAppDispatch();

  const clearAllFiltersHandler = () => {
    dispatch(
      onFilterArgsChange({
        filterArg: "clearAll",
      })
    );
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.title}`}>
        <SVGContainer
          height="16px"
          width="17px"
          imagePath="/assets/filter-icon.svg"
        />
        <p>Filtrer les commandes</p>
      </div>

      <div className={`${styles.formSection}`}>
        <div className={`${styles.inputsContainer}`}>
          <div className={`${styles.inputWithLabel}`}>
            <p>Statut :</p>
            <SelectInput
              fontSize="12px"
              backgroundColor="white"
              options={statusOptions}
              onChange={(option: SingleValue<OptionType>) => {
                dispatch(
                  onFilterArgsChange({
                    filterArg: "status",
                    status: option?.value
                      ? (option.value as OrderStatusEnum)
                      : undefined,
                  })
                );
              }}
              value={filterArgsState.status || ""}
              hasDefault={true}
              defaultLabel="Not selected"
            />
          </div>
          <div className={`${styles.inputWithLabel}`}>
            <p>Transporteur :</p>
            <SelectInput
              fontSize="12px"
              backgroundColor="white"
              options={carriersOptions}
              onChange={(option: SingleValue<OptionType>) => {
                dispatch(
                  onFilterArgsChange({
                    filterArg: "carrierId",
                    carrierId: option?.value ? option.value : undefined,
                  })
                );
              }}
              value={filterArgsState.carrierId ?? ""}
              hasDefault={true}
              defaultLabel="Not selected"
            />
          </div>
        </div>
      </div>
      <div className={`${styles.formSection}`}>
        <p className={`${styles.sectionLabel}`}>Période de commande :</p>
        <div className={`${styles.inputsContainer}`}>
          <div className={`${styles.rangeInput}`}>
            <p>Du</p>
            <DatePicker
              fontSize="12px"
              icon="keyboardArrowDown"
              onChange={(value: DateTime | null) => {
                if (value?.isValid !== false) {
                  const modifiedDate = value?.set({ hour: 0, minute: 0 });
                  dispatch(
                    onFilterArgsChange({
                      filterArg: "createdAtFrom",
                      createdAtFrom: modifiedDate?.toISO(),
                    })
                  );
                }
              }}
              value={
                filterArgsState.createdAtFrom
                  ? DateTime.fromISO(filterArgsState.createdAtFrom)
                  : null
              }
              backgroundColor="white"
              borderRadius="5px"
            />
          </div>
          <div className={`${styles.rangeInput}`}>
            <p>au</p>
            <DatePicker
              fontSize="12px"
              icon="keyboardArrowDown"
              onChange={(value: DateTime | null) => {
                if (value?.isValid !== false) {
                  const modifiedDate = value?.set({ hour: 23, minute: 59 });
                  dispatch(
                    onFilterArgsChange({
                      filterArg: "createdAtTo",
                      createdAtTo: modifiedDate?.toISO(),
                    })
                  );
                }
              }}
              value={
                filterArgsState.createdAtTo
                  ? DateTime.fromISO(filterArgsState.createdAtTo)
                  : null
              }
              backgroundColor="white"
              borderRadius="5px"
            />
          </div>
        </div>
      </div>
      <div className={`${styles.formSection}`}>
        <p className={`${styles.sectionLabel}`}>Période d’expédition :</p>
        <div className={`${styles.inputsContainer}`}>
          <div className={`${styles.rangeInput}`}>
            <p>Du</p>
            <DatePicker
              fontSize="12px"
              icon="keyboardArrowDown"
              onChange={(value: DateTime | null) => {
                if (value?.isValid !== false) {
                  const modifiedDate = value?.set({ hour: 0, minute: 0 });
                  dispatch(
                    onFilterArgsChange({
                      filterArg: "shippingDateFrom",
                      shippingDateFrom: modifiedDate?.toISO(),
                    })
                  );
                }
              }}
              value={
                filterArgsState.shippingDateFrom
                  ? DateTime.fromISO(filterArgsState.shippingDateFrom)
                  : null
              }
              backgroundColor="white"
              borderRadius="5px"
            />
          </div>
          <div className={`${styles.rangeInput}`}>
            <p>au</p>
            <DatePicker
              fontSize="12px"
              icon="keyboardArrowDown"
              onChange={(value: DateTime | null) => {
                if (value?.isValid !== false) {
                  const modifiedDate = value?.set({ hour: 23, minute: 59 });
                  dispatch(
                    onFilterArgsChange({
                      filterArg: "shippingDateTo",
                      shippingDateTo: modifiedDate?.toISO(),
                    })
                  );
                }
              }}
              value={
                filterArgsState.shippingDateTo
                  ? DateTime.fromISO(filterArgsState.shippingDateTo)
                  : null
              }
              backgroundColor="white"
              borderRadius="5px"
            />
          </div>
        </div>
      </div>
      <div className={`${styles.formSection}`}>
        <p className={`${styles.sectionLabel}`}>Montant :</p>
        <div className={`${styles.inputsContainer}`}>
          <div className={`${styles.rangeInput}`}>
            <p>Du</p>
            <InputWithIcon
              onChange={(value: string | number) => {
                dispatch(
                  onFilterArgsChange({
                    filterArg: "finalTotalPriceFrom",
                    finalTotalPriceFrom: value === "" ? undefined : +value,
                  })
                );
              }}
              value={filterArgsState.finalTotalPriceFrom}
              borderRadius="5px"
              noBorder={true}
              backgroundColor={colors.$inputGray}
              iconPath="/assets/euro-icon.svg"
              type="number"
              step={0.01}
            />
          </div>
          <div className={`${styles.rangeInput}`}>
            <p>au</p>
            <InputWithIcon
              onChange={(value: string | number) => {
                dispatch(
                  onFilterArgsChange({
                    filterArg: "finalTotalPriceTo",
                    finalTotalPriceTo: value === "" ? undefined : +value,
                  })
                );
              }}
              value={filterArgsState.finalTotalPriceTo}
              borderRadius="5px"
              noBorder={true}
              backgroundColor={colors.$inputGray}
              iconPath="/assets/euro-icon.svg"
              type="number"
              step={0.01}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.formSection}`}>
        <p className={`${styles.sectionLabel}`}>Montant :</p>
        <div className={`${styles.inputsContainer}`}>
          <CustomCheckbox
            label="Avec incident"
            checked={filterArgsState.hasIncident}
            onChange={(e) => {
              dispatch(
                onFilterArgsChange({
                  filterArg: "hasIncident",
                  hasIncident: e.target.checked,
                })
              );
            }}
            id="hasIncident"
          />
          <CustomCheckbox
            label="Avec remboursement"
            checked={filterArgsState.hasRefund}
            onChange={(e) => {
              dispatch(
                onFilterArgsChange({
                  filterArg: "hasRefund",
                  hasRefund: e.target.checked,
                })
              );
            }}
            id="hasRefund"
          />
          <CustomCheckbox
            label="Avec message"
            checked={filterArgsState.hasMessages}
            onChange={(e) => {
              dispatch(
                onFilterArgsChange({
                  filterArg: "hasMessages",
                  hasMessages: e.target.checked,
                })
              );
            }}
            id="hasMessages"
          />
        </div>
      </div>
      <div className={`${styles.clearAllButtonContainer}`}>
        <p className={`${styles.label}`} onClick={clearAllFiltersHandler}>
          Effacer tous les filtres
        </p>
      </div>
    </div>
  );
};
