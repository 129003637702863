import { useState } from "react";

import * as CustomButton from "~/components/form/CustomButton";
import { CustomInput } from "~/components/form/CustomInput";
import { MultipleSelectInput } from "~/components/form/MultipleSelectInput";
import { OptionType } from "~/components/form/SelectInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { Category } from "~/types/data/Category.type";

import styles from "./index.module.scss";

export interface FormState {
  id?: number;
  name: string;
  commissionPercentage?: number;
  parentCategoriesIds: number[];
  level: number;
}

interface Props {
  parentCategories: Category[];
  isLoading: boolean;
  submitHandler: (formState: FormState) => void;
  category?: Category;
  mode?: "create" | "edit";
  errorMessage: string;
}

export const CreateCategoryModal = ({
  parentCategories,
  isLoading,
  submitHandler,
  mode,
  category,
  errorMessage,
}: Props) => {
  const [formState, setFormState] = useState<FormState>({
    id: mode === "edit" ? category?.id : undefined,
    name: mode === "create" ? "" : category?.name || "",
    commissionPercentage:
      mode === "edit" ? category?.commissionPercentage : undefined,
    parentCategoriesIds:
      mode === "edit"
        ? category?.parents?.map((parentCategory) => parentCategory.id) ?? []
        : category
        ? [category.id]
        : [],
    level:
      mode === "edit"
        ? category?.level ?? 1
        : category
        ? category.level + 1
        : 1,
  });

  const parentCategoryLevel =
    mode === "edit" && category ? category.level - 1 : category?.level ?? 0;

  const parentCategoriesOptions: OptionType[] = parentCategories
    .filter(({ level }) => level === parentCategoryLevel)
    .map((parentCategory) => {
      return {
        label: `${parentCategory.name}.${parentCategory.level}`,
        value: parentCategory.id.toString(),
      };
    });

  const inputChangeHandler = <T extends keyof FormState>(
    inputName: T,
    changes: FormState[T]
  ) => {
    setFormState((state) => ({ ...state, [inputName]: changes }));
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Create Category</h3>
      <div className={styles.formContainer}>
        <div className={styles.inputsContainer}>
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("name", value.toString());
            }}
            value={formState.name}
            label="Name"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            width="80%"
          />
          <CustomInput
            onChange={(value: string | number) => {
              inputChangeHandler("commissionPercentage", +value);
            }}
            value={formState.commissionPercentage}
            label="Commission"
            borderRadius="5px"
            noBorder={true}
            backgroundColor={colors.$inputGray}
            textColor={colors.$primaryDark}
            fontSize="14px"
            type="number"
            width="20%"
          />
        </div>
        <div className={styles.inputsContainer}>
          <MultipleSelectInput
            label="Parent Categories"
            fontSize="14px"
            backgroundColor={colors.$inputGray}
            options={parentCategoriesOptions}
            onChange={(options: OptionType[]) => {
              inputChangeHandler(
                "parentCategoriesIds",
                options.map((option) => +option.value)
              );
            }}
            value={parentCategoriesOptions.filter((option) =>
              formState.parentCategoriesIds?.includes(+option.value)
            )}
            noBorder
          />
        </div>
        <div className={styles.error}>
          <p>{errorMessage}</p>
        </div>
        <CustomButton.CustomButton
          backgroundColor={colors.$primary}
          color="white"
          width="fit-content"
          borderRadius="8px"
          padding="1rem 1.5rem"
          disabled={isLoading}
          onClick={() => {
            submitHandler(formState);
          }}
        >
          {isLoading ? <SimpleLoader size="size2" /> : "Create"}
        </CustomButton.CustomButton>
      </div>
    </div>
  );
};
