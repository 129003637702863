import { gql } from "@apollo/client";

import { graphqlGetSellerMappingFields } from "~/constants/graphql";
import { SellerMapping } from "~/types/data/SellerMapping.types";

export interface UpdateSellerMappingsResponse {
  updateSellerMappings: { count: number };
}

export interface GetSellerMappingsResponse {
  getSellerMappings: SellerMapping[];
}
export interface UpdateSellerMappingsInput {
  UpdateSellerMappingsInput: { sellerMappings: SellerMapping[] };
}

const sellerMappingFields = graphqlGetSellerMappingFields.join(" ");

export const updateSellerMappings = gql`
  mutation ($UpdateSellerMappingsInput: UpdateSellerMappingsInput!) {
    updateSellerMappings(
      UpdateSellerMappingsInput: $UpdateSellerMappingsInput
    ) {
      count
    }
  }
`;

export const getSellerMappings = gql`
  query {
    getSellerMappings {
      ${sellerMappingFields}
    }
  }
`;
