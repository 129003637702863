import { useState } from "react";

import { CustomButton } from "~/components/form/CustomButton";
import { CustomTextArea } from "~/components/form/CustomTextArea";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { Review } from "~/types/data/Review.types";

import styles from "./index.module.scss";

interface Props {
  onSubmit: (reviewId: number, response: string) => void;
  review: Review;
  isLoading: boolean;
  errorMessage: string;
  successMessage: string;
}

export const ReviewResponseForm = ({
  review,
  onSubmit,
  isLoading,
  errorMessage,
  successMessage,
}: Props) => {
  const [response, setResponse] = useState<string>(review.response);
  const submitHandler = () => {
    onSubmit(review.id, response);
  };
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.title}`}>
        <h3>Réponse à l’avis N°{review.reviewedOrderId}</h3>
      </div>
      <div className={`${styles.review}`}>
        <p>{review.description}</p>
      </div>
      <div className={`${styles.responseForm}`}>
        <h4>Votre réponse</h4>
        <CustomTextArea
          onChange={(value) => {
            setResponse(value);
          }}
          value={response}
          noBorder={true}
          backgroundColor={colors.$inputGray}
        />
      </div>
      <div className={styles.responseMessageContainer}>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        {successMessage && <p>{successMessage}</p>}
      </div>
      <div className={`${styles.button}`}>
        <CustomButton
          backgroundColor={colors.$primary}
          borderRadius="8px"
          width="8rem"
          color="white"
          onClick={submitHandler}
          disabled={isLoading}
        >
          {isLoading ? <SimpleLoader size="size1" /> : "Valider"}
        </CustomButton>
      </div>
    </div>
  );
};
