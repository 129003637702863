import { useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

import { colors } from "~/constants/styles";
import { HistogramBarData } from "~/types/common/histogram.types";

interface Props {
  data: HistogramBarData[];
}

export const Histogram = ({ data }: Props) => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const handleMouseOver = (data: React.MouseEvent, index: number) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(-1);
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{
          top: 30,
          right: 30,
          bottom: 5,
        }}
        barSize={8}
      >
        <CartesianGrid horizontal={true} vertical={false} />
        <XAxis dataKey="xAxis" />
        <YAxis axisLine={false} />
        <Bar
          dataKey="yAxis"
          radius={[10, 10, 0, 0]}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
          {data.map((entry, index) => (
            <Cell
              fill={index === activeIndex ? "#f5a590" : colors.$primary}
              key={`cell-${index}`}
            />
          ))}
          <LabelList dataKey="yAxis" position="top" fill={colors.$primary} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
