import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  GET_COMPANY_RENT_ORDER_BY_ID,
  GetCompanyRentOrderByIdInput,
  GetCompanyRentOrderByIdResponse,
} from "~/api/graphql/rentOrder";
import FullPageLoader from "~/components/UI/FullPageLoader";
import { RentOrder } from "~/types/data/RentOrder.type";
import { formatDate, formatHour } from "~/util/functions/formatDate";

import Details from "./Details";
import styles from "./index.module.scss";

interface Props {
  page: "details" | "delivery" | "history";
}

export const RentDetailsDuneCommande = ({ page }: Props) => {
  const [rentOrder, SetRentOrder] = useState<RentOrder>();

  // hooks
  const params = useParams();
  const rentOrderId = params.id || "";

  const {
    data: rentOrderData,
    error: rentOrderError,
    loading: rentOrderDataLoading,
  } = useQuery<GetCompanyRentOrderByIdResponse, GetCompanyRentOrderByIdInput>(
    GET_COMPANY_RENT_ORDER_BY_ID,
    {
      variables: { GetCompanyRentOrderByIdInput: { orderId: +rentOrderId } },
    }
  );

  const dataNotFound =
    !rentOrderDataLoading && !rentOrderData?.getCompanyRentOrderById;

  const updateOrderHandler = (rentOrder: RentOrder) => {
    SetRentOrder(rentOrder);
  };

  useEffect(() => {
    if (rentOrderData) {
      SetRentOrder(rentOrderData.getCompanyRentOrderById);
    }
  }, [rentOrderData]);

  return (
    <div className={`${styles.container}`}>
      {dataNotFound ? (
        <div>Order of ID {rentOrderId} does not exist in the database</div>
      ) : rentOrderError ? (
        <div>Order Error: ${rentOrderError.message}</div>
      ) : !rentOrder ? (
        <FullPageLoader />
      ) : (
        <>
          <div className={`${styles.header}`}>
            <div className={`${styles.leftColumn}`}>
              <h2 className={styles.title}>Commande N°{rentOrderId}</h2>
              <p>{`${formatDate(rentOrder.createdAt)} à ${formatHour(
                rentOrder.createdAt
              )}`}</p>
            </div>
          </div>
          {page === "details" ? (
            <>
              <Details
                rentOrder={rentOrder}
                updateOrderHandler={updateOrderHandler}
              />
            </>
          ) : page === "delivery" ? (
            <>
              {/* <Expidition
                  order={order}
                  carriers={carriersData.getCarriers}
                  updateOrderHandler={updateOrderHandler}
                  readOnly={readOnly} 
                />*/}
            </>
          ) : (
            <>{/* <Historiques order={order} /> */}</>
          )}
        </>
      )}
    </div>
  );
};
