import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SingleValue } from "react-select";

import { GET_ATTRIBUTES, GetAttributesResponse } from "~/api/graphql/attribute";
import {
  GetForeignShopMappingInput,
  getForeignShopMappings,
  GetForeignShopMappingsResponse,
} from "~/api/graphql/foreignShopMapping";
import {
  GET_ALL_VENDOR_CATEGORIES,
  GetAllVendorCategoriesResponse,
} from "~/api/graphql/vendorCategory";
import { OptionType } from "~/components/form/SelectInput";
import FullPageLoader from "~/components/UI/FullPageLoader";
import { LoadingPopup } from "~/components/UI/LoadingPopup";
import { useAppDispatch, useAppSelector } from "~/redux/hooks";
import {
  foreignShopMappingState,
  initialize as initForeignShopMappedAttributes,
  updateChild as updateChildForeignShopMapping,
  updateOne as updateOneForeignShopMapping,
} from "~/redux/slice/foreignShopMapping.slice";
import { Attribute } from "~/types/data/Attribute.types";
import { ForeignShopMapping } from "~/types/data/ForeignShopMapping.types";
import { SellerMappingTypeEnum } from "~/types/data/SellerMapping.types";

import { ActionsSection } from "./ActionsSection";
import styles from "./index.module.scss";
import { MemoizedMappingSection } from "./OptionsMappingSection";
import { NavigationSections, SectionsNavigation } from "./SectionsNavigation";
import { VisibilityWrapper } from "./VisibilityWrapper";
export const scrollToSection = (sectionNumber: number) => {
  const section = document.getElementById(`section${sectionNumber}`);
  if (section) section.scrollIntoView({ behavior: "smooth", block: "start" });
};

export const ImporterParBoutique = () => {
  const dispatch = useAppDispatch();
  const { shopId } = useParams<{ shopId: string }>();
  const [activeSections, setActiveSections] = useState<NavigationSections[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);

  const { data: foreignShopMappings, loading: shopMappingLoading } = useQuery<
    GetForeignShopMappingsResponse,
    GetForeignShopMappingInput
  >(getForeignShopMappings, {
    variables: {
      GetForeignShopMappingInput: {
        shopId: parseInt(shopId ?? "0"),
      },
    },
  });
  const { data: vendorCategories, loading: categoriesLoading } =
    useQuery<GetAllVendorCategoriesResponse>(GET_ALL_VENDOR_CATEGORIES, {
      fetchPolicy: "cache-first",
    });
  const { data: attributesData, loading: attributesLoading } =
    useQuery<GetAttributesResponse>(GET_ATTRIBUTES, {
      fetchPolicy: "cache-first",
    });

  const attributes =
    attributesData?.getAttributes.filter((attr) => {
      return attr.isGuessable && attr.forProducts;
    }) ?? [];

  const searchableOptions = (attributes: Attribute[]) => {
    return attributes?.map((attribute) => ({
      value: attribute.name + "||" + attribute.associatedNames.join("||"),
      label: attribute.name,
    }));
  };

  const optionAttributes = searchableOptions(attributes);
  const vendorCategoriesOptions =
    vendorCategories?.getAllVendorCategories.map((category) => ({
      value: category.name + "||" + category.associatedNames.join("||"),
      label: category.name,
      id: category.id.toString(),
    })) ?? [];
  const findAttributeValuesOptionByName = (name: string) => {
    return (
      attributes
        ?.find((attribute) => attribute.name === name)
        ?.values?.map((attributeValue) => ({
          value:
            attributeValue.value +
            "||" +
            attributeValue.associatedValues.join("||"),
          label: attributeValue.value,
        })) ?? []
    );
  };

  const categoriesMappingSelectHandler = (
    option: SingleValue<OptionType>,
    id: number
  ) => {
    if (option && id) {
      const categoriesId =
        vendorCategories?.getAllVendorCategories.find(
          (category) => category.name === option.label
        )?.id ?? "";
      dispatch(
        updateOneForeignShopMapping({
          type: SellerMappingTypeEnum.CATEGORY,
          id,
          mappedValue: categoriesId.toString(),
        })
      );
    }
  };
  const optionValueMappingSelectHandler = (
    option: SingleValue<OptionType>,
    id: number | undefined,
    parentId: number
  ) => {
    if (option && id && parentId) {
      dispatch(
        updateChildForeignShopMapping({
          parentId: parentId,
          id,
          mappedValue: option.label,
        })
      );
    }
  };
  const optionMappingSelectHandler = (
    option: SingleValue<OptionType>,
    id: number
  ) => {
    if (option && id)
      dispatch(
        updateOneForeignShopMapping({
          type: SellerMappingTypeEnum.ATTRIBUTE,
          id,
          mappedValue: option.label,
        })
      );
  };

  const onVisibleChange = (
    sectionType: NavigationSections,
    visible: boolean
  ) => {
    setActiveSections((prev) => {
      if (visible && !prev.includes(sectionType)) return [...prev, sectionType];
      if (!visible && prev.includes(sectionType))
        return prev.filter((section) => section !== sectionType);
      return prev;
    });
  };

  const { mappedCategories, mappedOptions } = useAppSelector(
    foreignShopMappingState
  );

  useEffect(() => {
    if (foreignShopMappings) {
      const mappedCategories: ForeignShopMapping[] = [];
      const mappedOptions: ForeignShopMapping[] = [];
      foreignShopMappings.getForeignShopMappings.forEach((mapping) => {
        if (mapping.type === SellerMappingTypeEnum.CATEGORY) {
          mappedCategories.push(mapping);
        } else if (mapping.type === SellerMappingTypeEnum.ATTRIBUTE) {
          mappedOptions.push(mapping);
        }
      });
      dispatch(
        initForeignShopMappedAttributes({ mappedCategories, mappedOptions })
      );
    }
  }, [foreignShopMappings]);
  return (
    <div className={`${styles.container}`}>
      {shopMappingLoading || categoriesLoading || attributesLoading ? (
        <FullPageLoader />
      ) : (
        <>
          <LoadingPopup show={isLoading} />
          <div className={`${styles.headerContainer}`}>
            <h2>Product Flow Mapping</h2>
          </div>
          <div className={`${styles.pageContainer}`}>
            <div className={`${styles.navigationContainer}`}>
              <SectionsNavigation
                navSections={{ active: activeSections }}
                onClick={scrollToSection}
              />
            </div>
            <div className={`${styles.sectionContainer}`}>
              <VisibilityWrapper
                sectionTitle="Categories"
                onVisibilityChange={onVisibleChange}
                sectionType={NavigationSections.CategoriesMapping}
              >
                <MemoizedMappingSection
                  sectionType={NavigationSections.CategoriesMapping}
                  mappedOptions={mappedCategories}
                  optionMappingSelectHandler={categoriesMappingSelectHandler}
                  optionAttributes={vendorCategoriesOptions}
                />
              </VisibilityWrapper>
              <VisibilityWrapper
                sectionType={NavigationSections.FieldsMapping}
                sectionTitle="Fields Mapping"
                onVisibilityChange={onVisibleChange}
              >
                {
                  <MemoizedMappingSection
                    sectionType={NavigationSections.FieldsMapping}
                    mappedOptions={mappedOptions}
                    optionMappingSelectHandler={optionMappingSelectHandler}
                    optionAttributes={optionAttributes}
                  />
                }
              </VisibilityWrapper>

              <VisibilityWrapper
                sectionType={NavigationSections.AttributeMapping}
                sectionTitle="Attributes Mapping"
                onVisibilityChange={onVisibleChange}
              >
                <div className={`${styles.sectionContent}`}>
                  {mappedOptions.map((mapping) => {
                    return (
                      <>
                        <h2 className={`${styles.sectionSubTitle}`}>
                          {mapping.originalValue}
                        </h2>
                        {
                          <MemoizedMappingSection
                            sectionType={NavigationSections.AttributeMapping}
                            mappedOptions={mapping.children}
                            optionValueMappingSelectHandler={
                              optionValueMappingSelectHandler
                            }
                            parentId={mapping.id}
                            optionAttributes={findAttributeValuesOptionByName(
                              mapping.mappedValue || ""
                            )}
                          />
                        }
                      </>
                    );
                  })}
                </div>
              </VisibilityWrapper>
              <VisibilityWrapper
                sectionTitle="Actions"
                onVisibilityChange={onVisibleChange}
                sectionType={NavigationSections.Actions}
              >
                <ActionsSection
                  setLoading={setIsLoading}
                  attributes={attributes}
                  vendorCategories={
                    vendorCategories?.getAllVendorCategories ?? []
                  }
                />
              </VisibilityWrapper>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
