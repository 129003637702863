import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";

import SVGContainer from "~/components/UI/SVGContainer";

import styles from "./index.module.scss";

type Props = {
  header?: string;
  headerSpace?: boolean;
  width?: CSSProperties["width"];
  text: string;
  filePath: string;
};

export const DownloadInput = ({
  header,
  headerSpace,
  width,
  text,
  filePath,
}: Props) => {
  return (
    <div className={styles.mainContainer} style={{ width }}>
      {(header || headerSpace) && <h3 className={styles.header}>{header}</h3>}
      <Link
        to={filePath}
        target="_blank"
        download
        className={styles.inputContainer}
      >
        <div>{text}</div>
        <div>
          {
            <SVGContainer
              width="16px"
              height="16px"
              imagePath="/assets/download-icon.svg"
            />
          }
        </div>
      </Link>
    </div>
  );
};
