import { useState } from "react";

import { Product } from "~/types/data/Product.types";
import {
  SubscriptionCategory,
  SubscriptionPackage,
} from "~/types/data/SubscriptionPackage.type";
import { getCategoriesFromProducts } from "~/util/functions/category.util";

import { CategoriesList } from "./CategoriesList";
import styles from "./index.module.scss";
import { ProductsList } from "./ProductsList";

interface Props {
  subscriptionPackage: SubscriptionPackage;
  disabled: boolean;
}

export const CategoriesAndProductsSection = ({
  disabled,
  subscriptionPackage,
}: Props) => {
  const [selectedCategory, setSelectedCategory] =
    useState<SubscriptionCategory>();
  const [productsState, setProductsState] = useState<Product[]>(
    subscriptionPackage.products
  );
  const [subscriptionCategories, setSubscriptionCategories] = useState<
    SubscriptionCategory[]
  >(subscriptionPackage.subscriptionCategories);

  const subscriptionProductsCategories = getCategoriesFromProducts(
    subscriptionPackage.products,
    subscriptionCategories,
    subscriptionPackage.swiperCategoryLevel
  );

  const sortedProducts = productsState.sort(
    (a, b) => (a.priority ?? 0) - (b.priority ?? 0)
  );

  const scrollToSection = () => {
    const section = document.getElementById("categories-and-products-section");
    if (section) section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleCategoryClick = (category: SubscriptionCategory) => {
    setSelectedCategory(category);
    scrollToSection();
  };

  const updateProductsSuccessHandler = (product: Product) => {
    setProductsState((prevState) => {
      const productIndex = prevState.findIndex((p) => p.id === product.id);
      const newState = [...prevState];
      newState[productIndex] = product;
      return newState;
    });
  };

  const updateCategoriesOrderSuccessHandler = (
    subscriptionCategories: SubscriptionCategory[]
  ) => {
    setSubscriptionCategories(subscriptionCategories);
  };

  const updateProductsOrderSuccessHandler = (products: Product[]) => {
    setProductsState(products);
  };

  return (
    <div className={styles.container} id="categories-and-products-section">
      <CategoriesList
        subscriptionCategories={subscriptionProductsCategories}
        handleCategoryClick={handleCategoryClick}
        products={subscriptionPackage.products}
        categoryLevel={subscriptionPackage.swiperCategoryLevel}
        subscriptionPackageId={subscriptionPackage.id}
        updateCategoriesOrderSuccessHandler={
          updateCategoriesOrderSuccessHandler
        }
      />
      {!!selectedCategory && (
        <ProductsList
          subscriptionCategory={selectedCategory}
          products={sortedProducts}
          categoryLevel={subscriptionPackage.swiperCategoryLevel}
          disabled={disabled}
          updateProductsSuccessHandler={updateProductsSuccessHandler}
          subscriptionPackageId={subscriptionPackage.id}
          updateProductsOrderSuccessHandler={updateProductsOrderSuccessHandler}
        />
      )}
    </div>
  );
};
