import { colors } from "./styles";

export const customRedButtonParams = {
  backgroundColor: "#FFFFFF",
  color: colors.$primary,
  borderColor: colors.$primary,
  width: "fit-content",
  height: "2.5rem",
  borderRadius: "8px",
  padding: "0.5rem 0.7rem",
};
