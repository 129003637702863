export enum ImportedFileStatusEnum {
  ON_HOLD = "ON_HOLD",
  IN_PROGRESS = "IN_PROGRESS",
  ERROR = "ERROR",
  DONE = "DONE",
}

export interface ImportedFile {
  id: number;
  createdAt: string;
  fileName: string;
  filePath: string;
  errorFilePath?: string;
  incompleteFilePath?: string;
  successFilePath?: string;
  status: ImportedFileStatusEnum;
  totalProducts?: number;
  incompleteProducts?: number;
  successfullProducts?: number;
  companyId: number;
  fileFullPath?: string;
  errorFileFullPath?: string;
  incompleteFileFullPath?: string;
  successFileFullPath?: string;
  errorMessage?: string;
  isFTP: boolean;
  isUpdateMode: boolean;
}
