import { ColorPickerInput } from "~/components/form/ColorPickerInput";
import CustomCheckbox from "~/components/form/CustomCheckbox";
import { CustomInput } from "~/components/form/CustomInput";
import { colors } from "~/constants/styles";
import { Formula } from "~/types/data/Formula.type";

import styles from "./index.module.scss";

interface Props {
  formula: Formula;
  index: number;
  disabled: boolean;
  inputChangeHandler: (
    inputName: keyof Formula,
    changes: Formula[keyof Formula],
    index: number
  ) => void;
}

export const FormulaCard = ({
  formula,
  inputChangeHandler,
  index,
  disabled,
}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.formRow}>
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("name", value, index);
          }}
          value={formula.name}
          label="Name : "
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          required={true}
          disabled={disabled}
        />
        <CustomCheckbox
          checked={formula.popular}
          label="Popular"
          onChange={(e) => {
            const value = e.target.checked;
            inputChangeHandler("popular", value, index);
          }}
          disabled={disabled}
        />
      </div>
      <CustomInput
        onChange={(value: string | number) => {
          inputChangeHandler("description", value, index);
        }}
        value={formula.description}
        label="Description : "
        borderRadius="5px"
        noBorder={true}
        backgroundColor={colors.$inputGray}
        textColor={colors.$primaryDark}
        fontSize="14px"
        required={true}
        disabled={disabled}
      />

      <div className={styles.formRow}>
        <ColorPickerInput
          onChange={(value: string) => {
            inputChangeHandler("color", value, index);
          }}
          value={formula.color ?? "#000000"}
          label="Color : "
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          width="40"
          required={true}
          disabled={disabled}
        />
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("price", value, index);
          }}
          value={formula.price}
          label="Price :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          width="30%"
          type="number"
          step={1}
          required={true}
          disabled={disabled}
        />
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("points", value, index);
          }}
          value={formula.points}
          label="Points :"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="14px"
          width="30%"
          type="number"
          step={1}
          required={true}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
