import { ApolloError } from "@apollo/client";
import { useState } from "react";
import { SingleValue } from "react-select";

import {
  COMPANY_REVIEW_SINGLE_PRODUCTS,
  CompanyReviewSingleProductsInput,
  CompanyReviewSingleProductsResponse,
} from "~/api/graphql/rentOrder";
import { CustomButton } from "~/components/form/CustomButton";
import { CustomInput } from "~/components/form/CustomInput";
import { InputWithIcon } from "~/components/form/InputWithIcon";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import {
  CompanyUpdateRentSingleProductStatusEnum,
  RentOrder,
} from "~/types/data/RentOrder.type";
import { formatPrice } from "~/util/functions/formatDecimal";

import { ConfirmModalState } from "..";
import styles from "./index.module.scss";

interface Props {
  state: ConfirmModalState;
  rentOrder: RentOrder;
  setModalState: (modalState: ConfirmModalState) => void;
  updateOrderHandler: (order: RentOrder) => void;
}

export const ConfirmOrderModal = ({
  state,
  rentOrder,
  setModalState,
  updateOrderHandler,
}: Props) => {
  const { mode, groupedProduct, isLoading, errorMessage } = state;

  const [quantity, setQuantity] = useState(groupedProduct?.quantity);
  const [status, setStatus] =
    useState<CompanyUpdateRentSingleProductStatusEnum>();
  const [damageFees, setDamageFees] = useState(0);

  const title =
    mode === "review" && groupedProduct
      ? `Review ${groupedProduct.product.title}`
      : mode === "updatePending" && groupedProduct
      ? `Confirm ${groupedProduct.product.title}`
      : "";

  const formattedDeposit = formatPrice(groupedProduct?.deposit);

  const statusOptions: OptionType[] = [
    {
      label: "Returned",
      value: CompanyUpdateRentSingleProductStatusEnum.RETURNED,
    },
    {
      label: "Damaged",
      value: CompanyUpdateRentSingleProductStatusEnum.DAMAGED,
    },
  ];

  const companyReviewSingleProductsSuccessHandler = (
    data: CompanyReviewSingleProductsResponse
  ) => {
    updateOrderHandler(data.companyReviewSingleProducts);
    setModalState({ isLoading: false, show: false });
  };

  const companyReviewSingleProductsErrorHandler = (error: ApolloError) => {
    setModalState({
      ...state,
      isLoading: false,
      errorMessage: error.message,
    });
  };

  const { trigger: companyReviewSingleProducts } = useMutationWithCallbacks<
    CompanyReviewSingleProductsResponse,
    CompanyReviewSingleProductsInput
  >(
    COMPANY_REVIEW_SINGLE_PRODUCTS,
    companyReviewSingleProductsSuccessHandler,
    companyReviewSingleProductsErrorHandler
  );

  const companyReviewSingleProductsHandler = async () => {
    if (groupedProduct) {
      if (!status) {
        setModalState({
          ...state,
          errorMessage: "Please select a status",
        });
        return;
      }

      if (!quantity || quantity < 1 || quantity > groupedProduct.quantity) {
        setModalState({
          ...state,
          errorMessage: "Please select a valid quantity",
        });
        return;
      }

      if (
        status === CompanyUpdateRentSingleProductStatusEnum.DAMAGED &&
        (!damageFees || damageFees < 0)
      ) {
        setModalState({
          ...state,
          errorMessage: "Please enter damage fees",
        });
        return;
      }

      setModalState({ ...state, isLoading: true, errorMessage: "" });
      const input: CompanyReviewSingleProductsInput = {
        CompanyReviewSingleProductsInput: {
          rentOrderId: rentOrder.id,
          rentOrderToProductId: groupedProduct.orderToProductId,
          quantity,
          status,
          ...(status === CompanyUpdateRentSingleProductStatusEnum.DAMAGED && {
            damageFees,
          }),
        },
      };

      await companyReviewSingleProducts({ variables: input });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h2>{title}</h2>
      </div>
      {mode === "review" && groupedProduct ? (
        <div className={styles.bodyContainer}>
          <div className={styles.detailsContainer}>
            <div className={styles.informationContainer}>
              <p className={styles.boldText}>Available Quantity: </p>
              <p>{groupedProduct.quantity}</p>
            </div>
            <div className={styles.informationContainer}>
              <p className={styles.boldText}>Deposit: </p>
              <p>{formattedDeposit}</p>
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.doubleInputs}>
              <CustomInput
                onChange={(value: string | number) => {
                  setQuantity(+value);
                }}
                value={quantity}
                label="Quantity"
                borderRadius="5px"
                noBorder={true}
                backgroundColor={colors.$inputGray}
                textColor={colors.$primaryDark}
                fontSize="14px"
                type="number"
              />
              <SelectInput
                label="Status"
                fontSize="14px"
                backgroundColor={colors.$inputGray}
                noBorder={true}
                options={statusOptions}
                onChange={(option: SingleValue<OptionType>) => {
                  setStatus(
                    option?.value as CompanyUpdateRentSingleProductStatusEnum
                  );
                }}
                value={status ?? ""}
                menuShouldBlockScroll={false}
                width="70%"
              />
            </div>
            {status === CompanyUpdateRentSingleProductStatusEnum.DAMAGED && (
              <InputWithIcon
                iconPath="/assets/euro-icon.svg"
                onChange={(value: string | number) => {
                  setDamageFees(+value);
                }}
                value={damageFees}
                label="Damage fees"
                borderRadius="5px"
                noBorder={true}
                backgroundColor={colors.$inputGray}
                textColor={colors.$primaryDark}
                fontSize="14px"
                type="number"
              />
            )}
          </div>
        </div>
      ) : mode === "updatePending" && groupedProduct ? (
        <div className={styles.detailsContainer}>
          <div className={styles.informationContainer}>
            <p className={styles.boldText}>Total items: </p>
            {/* <p>{totalQuantity}</p> */}
          </div>
          <div className={styles.informationContainer}>
            <p className={styles.boldText}>Products total price: </p>
            {/* <p>{formattedTotalPrice}</p> */}
          </div>
          <div className={styles.informationContainer}>
            <p className={styles.boldText}>Shipping price: </p>
            {/* <p>{formattedShippingPrice}</p> */}
          </div>
          <div className={styles.informationContainer}>
            <p className={styles.boldText}>Montant total: </p>
            {/* <p>{formattedTotalAmountPrice}</p> */}
          </div>
        </div>
      ) : (
        <></>
      )}
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}

      <div className={styles.buttonsContainer}>
        <CustomButton
          width="max-content"
          padding="0.7rem 1rem"
          backgroundColor={colors.$primary}
          color="white"
          borderRadius="7px"
          disabled={isLoading}
          onClick={() => {
            if (mode === "review") {
              companyReviewSingleProductsHandler();
            }
          }}
        >
          {isLoading ? <SimpleLoader size="size2" /> : "confirmer"}
        </CustomButton>
      </div>
    </div>
  );
};
