import SVGContainer from "~/components/UI/SVGContainer";

import styles from "./index.module.scss";

type Accept =
  | "image/jpeg"
  | "image/png"
  | "image/jpg"
  | "video/wmv"
  | "video/mpeg"
  | "video/mp4"
  | "video/avi";

interface Props {
  onChange: (mageFile: File | undefined) => void;
  disabled?: boolean;
  id: string;
  accept: Accept[];
  multiple?: boolean;
  invalid?: boolean;
}

export const MediaUploadButton = ({
  onChange,
  disabled,
  id,
  accept,
  multiple,
  invalid,
}: Props) => {
  const filePickedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length < 1) return;

    if (multiple) {
      for (let i = 0; i < files.length; i++) {
        onChange(files[i]);
      }
    } else {
      onChange(files[0]);
    }
  };
  return (
    <>
      <label htmlFor={id}>
        <div
          className={`${styles.container} ${!disabled && styles.clickable} ${
            invalid && styles.invalid
          }`}
        >
          <SVGContainer
            height="12px"
            width="12px"
            imagePath="/assets/black-circular-add-button.svg"
          />
        </div>
      </label>
      <input
        type="file"
        id={id}
        onChange={filePickedHandler}
        disabled={disabled}
        onClick={(e) => {
          if (!disabled) e.currentTarget.value = "";
        }}
        hidden
        accept={accept.join(",")}
        multiple={multiple}
      />
    </>
  );
};
