import { gql } from "@apollo/client";

import { graphqlGetImportedFileFields } from "~/constants/graphql";
import { ImportedFile } from "~/types/data/ImportedFile.type";

export interface CreateImportedFileResponse {
  createImportedFile: ImportedFile;
}

export interface GetImportedFilesResponse {
  getImportedFiles: ImportedFile[];
}

export interface GetPaginatedImportedFilesResponse {
  getPaginatedImportedFiles: {
    importedFiles: ImportedFile[];
    nextCursor: number;
    hasMoreData: boolean;
  };
}

export interface CreateImportedFileInput {
  CreateImportedFileInput: {
    fileName: string;
    filePath: string;
    errorFilePath?: string;
    incompleteFilePath?: string;
    successFilePath?: string;
    totalProducts?: number;
    successfullProducts?: number;
    incompleteProducts?: number;
    isUpdateMode: boolean;
  };
}

export interface GetPaginatedImportedFilesInput {
  GetPaginatedImportedFilesInput: {
    take?: number;
    cursor?: number;
  };
}

const importedFileFields = graphqlGetImportedFileFields.join(" ");

export const createImportedFile = gql`
  mutation ($CreateImportedFileInput: CreateImportedFileInput!) {
    createImportedFile(CreateImportedFileInput: $CreateImportedFileInput) {
      ${importedFileFields}
    }
  }
`;

export const getImportedFiles = gql`
  query {
    getImportedFiles {
      ${importedFileFields}

      fileFullPath
      errorFileFullPath
      incompleteFileFullPath
      successFileFullPath
    }
  }
`;

export const GET_PAGINATED_IMPORTED_FILES = gql`
  query ($GetPaginatedImportedFilesInput: GetPaginatedImportedFilesInput!) {
    getPaginatedImportedFiles(GetPaginatedImportedFilesInput: $GetPaginatedImportedFilesInput) {
      importedFiles {
        ${importedFileFields}

        
        fileFullPath
        errorFileFullPath
        incompleteFileFullPath
        successFileFullPath
      }

      nextCursor
      hasMoreData
      
    }
  }
`;
