import { getMediaUploadPath } from "~/api/graphql/media/getMediaUploadPath";
import {
  BrandCompany,
  UpdateBrandCompanyInputType,
} from "~/types/data/BrandCompany.type";

export const validateAndUploadBrandCompanyMedia = async (
  brandCompany: BrandCompany
) => {
  const input: UpdateBrandCompanyInputType = {
    id: brandCompany.id,
    name: brandCompany.name,
    primaryColor: brandCompany.primaryColor,
    secondaryColor: brandCompany.secondaryColor,
    websiteUrl: brandCompany.websiteUrl,
    ...(!brandCompany.authBanner && { authBanner: "" }),
    ...(!brandCompany.mobileAuthBanner && { mobileAuthBanner: "" }),
    ...(!brandCompany.banner && { banner: "" }),
    ...(!brandCompany.mobileBanner && { mobileBanner: "" }),
    ...(!brandCompany.logo && { logo: "" }),
  };

  const logoFile = brandCompany.logoFile;
  if (logoFile) {
    const response = await getMediaUploadPath({
      field: "logo",
      entity: "brandCompany/pictures",
      filename: logoFile.name,
      isPublic: true,
    });

    if (response.success) {
      const { path, url } = response.data;
      await fetch(url, { method: "put", body: logoFile });
      input.logo = path;
    } else {
      return { error: "Uploading logo didn't work" };
    }
  }

  const authBannerFile = brandCompany.authBannerFile;
  if (authBannerFile) {
    const response = await getMediaUploadPath({
      field: "authBanner",
      entity: "brandCompany/pictures",
      filename: authBannerFile.name,
      isPublic: true,
    });

    if (response.success) {
      const { path, url } = response.data;
      await fetch(url, { method: "put", body: authBannerFile });
      input.authBanner = path;
    } else {
      return {
        error: "Uploading auth banner didn't work",
      };
    }
  }

  const mobileAuthBannerFile = brandCompany.mobileAuthBannerFile;

  if (mobileAuthBannerFile) {
    const response = await getMediaUploadPath({
      field: "mobileAuthBanner",
      entity: "brandCompany/pictures",
      filename: mobileAuthBannerFile.name,
      isPublic: true,
    });

    if (response.success) {
      const { path, url } = response.data;
      await fetch(url, { method: "put", body: mobileAuthBannerFile });
      input.mobileAuthBanner = path;
    } else {
      return { error: "Uploading mobile auth banner didn't work" };
    }
  }

  const bannerFile = brandCompany.bannerFile;
  if (bannerFile) {
    const response = await getMediaUploadPath({
      field: "banner",
      entity: "brandCompany/pictures",
      filename: bannerFile.name,
      isPublic: true,
    });

    if (response.success) {
      const { path, url } = response.data;
      await fetch(url, { method: "put", body: bannerFile });
      input.banner = path;
    } else {
      return { error: "Uploading banner didn't work" };
    }
  }

  const mobileBannerFile = brandCompany.mobileBannerFile;
  if (mobileBannerFile) {
    const response = await getMediaUploadPath({
      field: "mobileBanner",
      entity: "brandCompany/pictures",
      filename: mobileBannerFile.name,
      isPublic: true,
    });

    if (response.success) {
      const { path, url } = response.data;
      await fetch(url, { method: "put", body: mobileBannerFile });
      input.mobileBanner = path;
    } else {
      return {
        error: "Uploading mobile banner didn't work",
      };
    }
  }

  return { input };
};
