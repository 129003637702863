import { useState } from "react";
import { WorkBook, writeFile } from "xlsx";

import { CustomButton } from "~/components/form/CustomButton";
import { CustomPieChart, PieChartData } from "~/components/UI/CustomPieChart";
import { LoadingPopup } from "~/components/UI/LoadingPopup";
import { colors } from "~/constants/styles";

import { ValidationState } from "..";
import { SectionIndexEnum } from "../SectionsNavigation";
import styles from "./index.module.scss";

interface Props {
  validationValues: ValidationState[];
  invalidProductsWorkbook?: WorkBook;
  incompleteProductsWorkbook?: WorkBook;
  isProcessing: boolean;
  onNext: () => void;
  onPrevious: (section: SectionIndexEnum) => void;
}

export const ValidationPage = ({
  validationValues,
  isProcessing,
  onNext,
  onPrevious,
  invalidProductsWorkbook,
  incompleteProductsWorkbook,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isPreparingFile, setIsPreparingFile] = useState(false);
  const totalNumber = validationValues.length;
  const validNumber = validationValues.filter(
    ({ invalidReasons }) => !invalidReasons.length
  ).length;
  const invalidNumber = validationValues.filter(
    ({ invalidReasons }) => invalidReasons.length
  ).length;
  const incompleteNumber = validationValues.filter(
    ({ invalidReasons, incompleteReasons }) =>
      incompleteReasons.length && !invalidReasons.length
  ).length;
  const disabled = isProcessing || isPreparingFile;

  const previousNavigationHandler = () => {
    onPrevious(SectionIndexEnum.attributeValuesMapping);
  };

  const nextNaviagtionHandler = () => {
    setErrorMessage("");
    onNext();
  };

  const pieChartData: PieChartData[] = [
    {
      name: "valid",
      value: validNumber - invalidNumber,
      color: colors.$primary,
    },
    { name: "invalid", value: invalidNumber, color: "#666666" },
    { name: "incomplete", value: incompleteNumber, color: "#FF33BE" },
  ];

  const downloadInvalidFileHandler = () => {
    setIsPreparingFile(true);

    setTimeout(() => {
      // create file using xlsx
      if (invalidProductsWorkbook) {
        writeFile(invalidProductsWorkbook, "invalid_products_data.xlsx");
      }
      setIsPreparingFile(false);
    }, 500);
  };

  const downloadIncompleteFileHandler = () => {
    setIsPreparingFile(true);

    setTimeout(() => {
      // create file using xlsx
      if (incompleteProductsWorkbook) {
        writeFile(incompleteProductsWorkbook, "incomplete_products_data.xlsx");
      }
      setIsPreparingFile(false);
    }, 500);
  };

  return (
    <div className={`${styles.container}`}>
      <LoadingPopup show={isPreparingFile} message="Preparing file..." />
      <div className={`${styles.validationContainer}`}>
        <div className={`${styles.progressSection}`}>
          <CustomPieChart data={pieChartData} />
        </div>

        <div className={`${styles.validationSummarySection}`}>
          <div className={`${styles.summaryItem}`}>
            <span>Valid Products: </span>
            <span>{validNumber}</span>
          </div>
          <div className={`${styles.summaryItem} ${styles.errorItem}`}>
            <span>Invalid Products: </span>
            <span>{invalidNumber}</span>
          </div>
          <div className={`${styles.summaryItem} ${styles.errorItem}`}>
            <span>Incomplete Products: </span>
            <span>{incompleteNumber}</span>
          </div>
          <div className={`${styles.summaryItem}`}>
            <span>Total Products: </span>
            <span>{totalNumber}</span>
          </div>
        </div>
        {!!errorMessage && (
          <p className={`${styles.errorMessage}`}>{errorMessage}</p>
        )}
      </div>
      <div className={`${styles.buttons}`}>
        <div className={`${styles.downloadButton}`}>
          {!!incompleteNumber && (
            <CustomButton
              backgroundColor={colors.$inputGray}
              borderColor="#DEE3E8"
              borderRadius="5px"
              onClick={!disabled ? downloadIncompleteFileHandler : undefined}
              disabled={disabled}
            >
              Download incomplete data
            </CustomButton>
          )}
          {!!invalidNumber && (
            <CustomButton
              backgroundColor={colors.$inputGray}
              borderColor="#DEE3E8"
              borderRadius="5px"
              onClick={!disabled ? downloadInvalidFileHandler : undefined}
              disabled={disabled}
            >
              Download invalid data
            </CustomButton>
          )}
        </div>
        <div className={`${styles.navigationButtons}`}>
          <div className={`${styles.button}`}>
            <CustomButton
              backgroundColor={colors.$inputGray}
              borderColor="#DEE3E8"
              borderRadius="5px"
              onClick={!disabled ? previousNavigationHandler : undefined}
              disabled={disabled}
            >
              Previous
            </CustomButton>
          </div>
          <div className={`${styles.button}`}>
            <CustomButton
              backgroundColor={colors.$inputGray}
              borderColor="#DEE3E8"
              borderRadius="5px"
              onClick={!disabled ? nextNaviagtionHandler : undefined}
              disabled={disabled}
            >
              Submit
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};
