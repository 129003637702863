export enum SelectReasonTypeEnum {
  REFUND = "REFUND",
  CLOSE_INCIDENT = "CLOSE_INCIDENT",
  REFUSE_PRODUCT = "REFUSE_PRODUCT",
}

export interface SelectReason {
  id: number;
  reason: string;
  type: SelectReasonTypeEnum;
}
