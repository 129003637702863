import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

import {
  getCompanyConversations,
  GetCompanyConversationsResponse,
} from "~/api/graphql/conversation";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useAppDispatch } from "~/redux/hooks";
import {
  initializeConversationState,
  updateConversationsState,
} from "~/redux/slice/conversations.slice";

import FullPageLoader from "../FullPageLoader";
import { TimeIntervalWrapper } from "../TimeIntervalWrapper";

type Props = {
  children: React.ReactNode;
};
export const ConversationsWrapper = ({ children }: Props) => {
  const { companyAccount } = useCompanyAccountContext();

  const dispatch = useAppDispatch();

  const [
    getCompanyConversationsTrigger,
    { refetch: getCompanyConversationsRefetch },
  ] = useLazyQuery<GetCompanyConversationsResponse>(getCompanyConversations);

  const refetchConversationsHandler = async () => {
    const { data } = await getCompanyConversationsRefetch();

    dispatch(
      updateConversationsState({ conversations: data.getCompanyConversations })
    );
  };

  const initConversations = async () => {
    const { data } = await getCompanyConversationsTrigger();
    dispatch(
      initializeConversationState({
        conversations: data?.getCompanyConversations || [],
      })
    );
  };

  useEffect(() => {
    if (companyAccount.connected) initConversations();
  }, [companyAccount]);

  return (
    <>
      {!companyAccount ? (
        <FullPageLoader />
      ) : (
        <TimeIntervalWrapper
          callback={() => {
            refetchConversationsHandler();
          }}
          active={!!companyAccount.connected}
        >
          {children}
        </TimeIntervalWrapper>
      )}
    </>
  );
};
