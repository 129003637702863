import { Conversation } from "~/types/data/Conversation.types";

export const getUnreadConversationsCount = (conversations: Conversation[]) => {
  return conversations.filter(({ unreadCount }) => unreadCount).length;
};

export const mapMessagesWithUnreadCount = (conversations: Conversation[]) => {
  return conversations.map((conversation) => {
    const unreadCount = conversation?.messages.filter(
      ({ read, recipientId }) =>
        !read && conversation?.professionalSellerId === recipientId
    ).length;
    return { ...conversation, unreadCount };
  });
};
