import { useMutation } from "@apollo/client";
import { DateTime } from "luxon";
import { useState } from "react";

import {
  CREATE_PROMO_CODE,
  CreatePromoCodeResponse,
  CreateSecondHandPromoCodeInput,
  PromoCodeState,
  UPDATE_PROMO_CODE,
  UpdatePromoCodeResponse,
  UpdateSecondHandPromoCodeInput,
} from "~/api/graphql/promoCode";
import { CustomButton } from "~/components/form/CustomButton";
import CustomCheckbox from "~/components/form/CustomCheckbox";
import { CustomInput } from "~/components/form/CustomInput";
import DatePicker from "~/components/form/DatePicker";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { DiscountTypeEnum, PromoCode } from "~/types/data/PromoCode.type";
import {
  promoCodeFieldsMapping,
  requiredPromoCodeFields,
} from "~/util/mapping/fieldToTextMapping.mapping";

import styles from "./index.module.scss";

interface Props {
  oldPromoCode?: PromoCode;
  closeModal: () => void;
  onSuccess: (promoCode: PromoCode) => void;
}

export const AddPromoCode = ({
  closeModal,
  oldPromoCode,
  onSuccess,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [promoCodeState, setPromoCodeState] = useState<PromoCodeState>({
    id: oldPromoCode?.id,
    code: oldPromoCode?.code ?? "",
    active: oldPromoCode?.active ?? false,
    amount: oldPromoCode?.amount ?? 0,
    discountType: oldPromoCode?.discountType ?? DiscountTypeEnum.PERCENTAGE,
    threshold: oldPromoCode?.threshold ?? 0,
    uses: oldPromoCode?.uses ?? 10,
    validUntil: oldPromoCode?.validUntil ?? new Date(),
    maximumUsesPerUser: oldPromoCode?.maximumUsesPerUser ?? 1,
  });

  const [createPromoCode] = useMutation<
    CreatePromoCodeResponse,
    CreateSecondHandPromoCodeInput
  >(CREATE_PROMO_CODE);

  const [updatePromoCode] = useMutation<
    UpdatePromoCodeResponse,
    UpdateSecondHandPromoCodeInput
  >(UPDATE_PROMO_CODE);

  const inputChangeHandler = <T extends keyof PromoCodeState>(
    inputName: T,
    changes: PromoCodeState[T]
  ) => {
    setPromoCodeState((prevState) => ({
      ...prevState,
      [inputName]: changes,
    }));
  };

  const upsertPromoCodeHandler = async () => {
    for (const key in promoCodeState) {
      const typedKey = key as keyof PromoCodeState;
      const hasValue =
        !!promoCodeState[typedKey] || promoCodeState[typedKey] === 0;
      if (requiredPromoCodeFields[typedKey] && !hasValue) {
        return setErrorMessage(
          `Le champ ${promoCodeFieldsMapping[typedKey]} est requis`
        );
      }
    }
    if (promoCodeState?.amount && promoCodeState?.amount <= 0) {
      return setErrorMessage("Le montant doit être supérieur à 0");
    }
    if (
      promoCodeState.discountType === DiscountTypeEnum.PERCENTAGE &&
      promoCodeState?.amount &&
      promoCodeState?.amount > 100
    ) {
      return setErrorMessage("Le montant doit être inférieur ou égal à 100");
    }

    setIsLoading(true);
    if (promoCodeState.id) {
      await updatePromoCode({
        variables: {
          UpdateSecondHandPromoCodeInput: promoCodeState,
        },
        onCompleted: (data) => {
          onSuccess(data.updateSecondHandPromoCode);
          closeModal();
        },
        onError: (error) => {
          setErrorMessage(error.message);
        },
      });
    } else {
      await createPromoCode({
        variables: {
          CreateSecondHandPromoCodeInput: promoCodeState,
        },
        onCompleted: (data) => {
          onSuccess(data.createSecondHandPromoCode);
          closeModal();
        },
        onError: (error) => {
          setErrorMessage(error.message);
        },
      });
    }

    setIsLoading(false);
  };
  const options = Object.values(DiscountTypeEnum).map((type) => ({
    value: type,
    label: type,
  })) as OptionType[];
  return (
    <div className={styles.container}>
      <div className={`${styles.doubleInputs}`}>
        <SelectInput
          onChange={(value) => {
            inputChangeHandler(
              "discountType",
              value?.value as unknown as DiscountTypeEnum
            );
          }}
          options={options}
          value={promoCodeState.discountType as unknown as string}
          label="Type"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          fontSize="12px"
          required
        />
        <div className={`${styles.checkboxes}`}>
          <CustomCheckbox
            label="Active"
            checked={promoCodeState.active || false}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              inputChangeHandler("active", event.target.checked);
            }}
          />
        </div>
      </div>
      <div className={`${styles.doubleInputs}`}>
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("code", value.toString());
          }}
          value={promoCodeState.code || ""}
          label="Code"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="12px"
          required
        />
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("amount", +value);
          }}
          value={promoCodeState.amount || undefined}
          label="Amount"
          type="number"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="12px"
          required
        />
      </div>
      <div className={`${styles.doubleInputs}`}>
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("threshold", +value);
          }}
          value={promoCodeState.threshold || undefined}
          label="Seuil Minimum Requis"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="12px"
          required
        />
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("uses", +value);
          }}
          value={promoCodeState.uses || undefined}
          label="Nombre maximum d'utilisation"
          type="number"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="12px"
          required
        />
      </div>
      <div className={`${styles.doubleInputs}`}>
        <CustomInput
          onChange={(value: string | number) => {
            inputChangeHandler("maximumUsesPerUser", +value);
          }}
          value={promoCodeState.maximumUsesPerUser || undefined}
          label="Nombre maximum d'utilisation par utilisateur"
          type="number"
          borderRadius="5px"
          noBorder={true}
          backgroundColor={colors.$inputGray}
          textColor={colors.$primaryDark}
          fontSize="12px"
        />
        <DatePicker
          label="Valable jusqu’au"
          fontSize="14px"
          icon="CalendarMonthOutlined"
          onChange={(value: DateTime | null) => {
            if (value?.isValid !== false) {
              inputChangeHandler("validUntil", value as unknown as Date);
            }
          }}
          value={DateTime.fromISO(
            promoCodeState.validUntil as unknown as string
          )}
          backgroundColor={colors.$inputGray}
          noBorder={true}
          borderRadius="5px"
        />
      </div>

      {errorMessage && (
        <div className={styles.error}>
          <p>{errorMessage}</p>
        </div>
      )}
      <div className={styles.buttonsContainer}>
        <CustomButton
          width="9rem"
          backgroundColor={colors.$primary}
          height="2rem"
          borderRadius="0.5rem"
          color="white"
          disabled={isLoading || isLoading}
          onClick={() => {
            upsertPromoCodeHandler();
          }}
        >
          {isLoading ? (
            <SimpleLoader size="size1" />
          ) : promoCodeState.id ? (
            "Update"
          ) : (
            "Create"
          )}
        </CustomButton>
      </div>
    </div>
  );
};
