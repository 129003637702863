import { useState } from "react";

import CustomCheckbox from "~/components/form/CustomCheckbox";
import { CustomInput } from "~/components/form/CustomInput";
import SVGContainer from "~/components/UI/SVGContainer";
import { colors } from "~/constants/styles";
import {
  DeliveryDetailsState,
  GroupedCarrierState,
} from "~/types/data/Carrier.types";

import { DeliveryAreaKey, DeliveryMethodKey } from "..";
import { DeliveryMethodSection } from "../DeliveryMethodSection";
import styles from "./index.module.scss";

interface DeliveryMethodsChecklist {
  delivery: boolean;
  pickup: boolean;
}

interface Props {
  stateKey: string;
  codeIndex: number;
  groupedCarrierState: GroupedCarrierState;
  deleteGroupedCarrierCodeHandler: (
    stateKey: string,
    codeIndex: number
  ) => void;
  clearDeliveryMethodHandler: (
    stateKey: string,
    codeIndex: number,
    method: DeliveryMethodKey
  ) => void;
  clearDeliveryAreaHandler: (
    stateKey: string,
    codeIndex: number,
    method: DeliveryMethodKey,
    area: DeliveryAreaKey
  ) => void;
  addDeliveryDetailsStateHandler: (
    stateKey: string,
    codeIndex: number,
    method: DeliveryMethodKey,
    area: DeliveryAreaKey
  ) => void;
  deliveryPackageChangeHandler: (
    stateKey: string,
    codeIndex: number,
    type: DeliveryMethodKey,
    area: DeliveryAreaKey,
    changes: DeliveryDetailsState
  ) => void;
  groupedCarrierCodeChangeHandler: (
    stateKey: string,
    codeIndex: number,
    newCode: string
  ) => void;
}

export const DeliveryCodeSection = ({
  stateKey,
  codeIndex,
  groupedCarrierState,
  deliveryPackageChangeHandler,
  groupedCarrierCodeChangeHandler,
  clearDeliveryMethodHandler,
  clearDeliveryAreaHandler,
  addDeliveryDetailsStateHandler,
  deleteGroupedCarrierCodeHandler,
}: Props) => {
  const initDeliveryMethodsChecklist: DeliveryMethodsChecklist = {
    delivery: !!(
      groupedCarrierState.delivery?.france ||
      groupedCarrierState.delivery?.corse ||
      groupedCarrierState.delivery?.monaco
    ),
    pickup: !!(
      groupedCarrierState.pickup?.france ||
      groupedCarrierState.pickup?.corse ||
      groupedCarrierState.pickup?.monaco
    ),
  };

  const [deliveryMethodsChecklist, setDeliveryMethodsChecklist] =
    useState<DeliveryMethodsChecklist>(initDeliveryMethodsChecklist);

  const checkboxClickHandler = (method: DeliveryMethodKey) => {
    if (deliveryMethodsChecklist.delivery && method === "delivery") {
      clearDeliveryMethodHandler(stateKey, codeIndex, "delivery");
    }
    if (deliveryMethodsChecklist.pickup && method === "pickup") {
      clearDeliveryMethodHandler(stateKey, codeIndex, "pickup");
    }
    setDeliveryMethodsChecklist((prev) => {
      return { ...prev, [method]: !prev[method] };
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.sectionHead}>
        <div className={`${styles.inputsContainer}`}>
          <CustomInput
            onChange={(value: string | number) => {
              groupedCarrierCodeChangeHandler(
                stateKey,
                codeIndex,
                value.toString()
              );
            }}
            textColor={colors.$primaryDark}
            value={groupedCarrierState.code}
            noBorder={true}
            backgroundColor={colors.$inputGray}
            label="Code :"
            fontSize="12px"
          />
          <div style={{ width: "100%" }}></div>
          <div style={{ width: "100%" }}></div>
          <div style={{ width: "100%" }}></div>
        </div>
        <SVGContainer
          height="10px"
          width="10px"
          imagePath="/assets/close-button.svg"
          onClick={() => {
            deleteGroupedCarrierCodeHandler(stateKey, codeIndex);
          }}
        />
      </div>

      <div className={`${styles.deliveryMethodsContainer}`}>
        <div>
          <CustomCheckbox
            label="Livraison à domicile"
            checked={deliveryMethodsChecklist.delivery}
            onChange={() => {
              checkboxClickHandler("delivery");
            }}
          />
        </div>

        {deliveryMethodsChecklist.delivery && (
          <DeliveryMethodSection
            stateKey={stateKey}
            codeIndex={codeIndex}
            methodKey="delivery"
            deliveryAreaState={groupedCarrierState.delivery || {}}
            deliveryPackageChangeHandler={deliveryPackageChangeHandler}
            clearDeliveryAreaHandler={clearDeliveryAreaHandler}
            addDeliveryDetailsStateHandler={addDeliveryDetailsStateHandler}
          />
        )}
        <div>
          <CustomCheckbox
            label="Point retrait"
            checked={deliveryMethodsChecklist.pickup}
            onChange={() => {
              checkboxClickHandler("pickup");
            }}
          />
        </div>
        {deliveryMethodsChecklist.pickup && (
          <DeliveryMethodSection
            stateKey={stateKey}
            codeIndex={codeIndex}
            methodKey="pickup"
            deliveryAreaState={groupedCarrierState.pickup || {}}
            deliveryPackageChangeHandler={deliveryPackageChangeHandler}
            clearDeliveryAreaHandler={clearDeliveryAreaHandler}
            addDeliveryDetailsStateHandler={addDeliveryDetailsStateHandler}
          />
        )}
      </div>
    </div>
  );
};
