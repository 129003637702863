import React, { FunctionComponent } from "react";

import styles from "./index.module.scss";

interface Props {
  placeholder?: string;
  value: string | number | undefined;
  type?: "text" | "password" | "number" | "email" | "tel";
  onChange: (changes: string | number) => void;
  required?: boolean;
  disabled?: boolean;
  style?: object;
  invalid?: boolean;
  readonly?: boolean;
  noBorder?: boolean;
  label?: string;
  backgroundColor?: string;
  textColor?: string;
  borderRadius?: string;
  fontSize?: string;
  step?: number;
  width?: string;
  height?: string;
  borderColor?: string;
  className?: string;
  errorText?: string;
}

export const CustomInput: FunctionComponent<Props> = ({
  className,
  placeholder,
  value = "",
  type,
  onChange,
  required = false,
  disabled = false,
  noBorder = false,
  invalid = false,
  label,
  backgroundColor,
  textColor,
  borderRadius,
  fontSize,
  height,
  width,
  borderColor,
  errorText,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;

    if (type === "number" && event.target.value === "e") {
      return;
    }

    onChange(
      type === "number" && event.target.value !== ""
        ? +event.target.value
        : event.target.value
    );
  };

  return (
    <div
      className={`${styles.container} ${className || ""}`}
      style={{ fontSize: fontSize, width: width || "100%" }}
    >
      {label && (
        <label className={`${styles.label}`}>
          {label}
          {required && <span>*</span>}
        </label>
      )}
      <input
        value={value || value === 0 ? value : ""}
        type={type}
        className={`${styles.input} ${
          invalid || errorText ? "#870000" : noBorder ? styles.noBorder : ""
        }`}
        autoComplete="on"
        placeholder={placeholder || ""}
        onChange={handleChange}
        disabled={disabled}
        required={required}
        step={0.01}
        style={{
          backgroundColor: backgroundColor,
          color: textColor,
          borderRadius: borderRadius,
          marginTop: label ? "0.3rem" : "",
          height: height || "40px",
          borderColor: invalid || errorText ? "#870000" : borderColor,
        }}
        onWheel={(e) => {
          e.currentTarget.blur();
        }}
        onFocus={(e) => {
          if (value === 0) e.target.select();
        }}
      />
      {errorText && <span className={styles.error}>{errorText}</span>}
    </div>
  );
};
