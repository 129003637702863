import { DeliveryDetailsState } from "~/types/data/Carrier.types";

import { DeliveryAreaKey, DeliveryMethodKey } from "..";
import { DeliveryPackageSection } from "../DeliveryPackageSection";

interface Props {
  stateKey: string;
  codeIndex: number;
  methodKey: DeliveryMethodKey;
  areaKey: DeliveryAreaKey;
  deliveryDetailsState?: DeliveryDetailsState;
  deliveryPackageChangeHandler: (
    stateKey: string,
    codeIndex: number,
    type: DeliveryMethodKey,
    area: DeliveryAreaKey,
    changes: DeliveryDetailsState
  ) => void;
}

export const DeliveryAreaSection = ({
  stateKey,
  codeIndex,
  methodKey,
  areaKey,
  deliveryDetailsState,
  deliveryPackageChangeHandler,
}: Props) => {
  return (
    <div>
      <DeliveryPackageSection
        stateKey={stateKey}
        codeIndex={codeIndex}
        methodKey={methodKey}
        areaKey={areaKey}
        deliveryDetailsState={deliveryDetailsState}
        deliveryPackageChangeHandler={deliveryPackageChangeHandler}
      />
    </div>
  );
};
