import { getMediaUploadPath } from "~/api/graphql/media/getMediaUploadPath";
import {
  SecondHandCompany,
  UpdateSecondHandCompanyInputType,
} from "~/types/data/SecondHandCompany.type";

export const validateAndUploadSecondHandCompanyMedia = async (
  secondHandCompany: SecondHandCompany
) => {
  const input: UpdateSecondHandCompanyInputType = {
    id: secondHandCompany.id,
    name: secondHandCompany.name,
    primaryColor: secondHandCompany.primaryColor,
    secondaryColor: secondHandCompany.secondaryColor,
    websiteUrl: secondHandCompany.websiteUrl,
    ...(!secondHandCompany.authBanner && { authBanner: "" }),
    ...(!secondHandCompany.mobileAuthBanner && { mobileAuthBanner: "" }),
    ...(!secondHandCompany.banner && { banner: "" }),
    ...(!secondHandCompany.mobileBanner && { mobileBanner: "" }),
    ...(!secondHandCompany.logo && { logo: "" }),
  };

  const logoFile = secondHandCompany.logoFile;
  if (logoFile) {
    const response = await getMediaUploadPath({
      field: "logo",
      entity: "secondHandCompany/pictures",
      filename: logoFile.name,
      isPublic: true,
    });

    if (response.success) {
      const { path, url } = response.data;
      await fetch(url, { method: "put", body: logoFile });
      input.logo = path;
    } else {
      return { error: "Uploading logo didn't work" };
    }
  }

  const authBannerFile = secondHandCompany.authBannerFile;
  if (authBannerFile) {
    const response = await getMediaUploadPath({
      field: "authBanner",
      entity: "secondHandCompany/pictures",
      filename: authBannerFile.name,
      isPublic: true,
    });

    if (response.success) {
      const { path, url } = response.data;
      await fetch(url, { method: "put", body: authBannerFile });
      input.authBanner = path;
    } else {
      return {
        error: "Uploading auth banner didn't work",
      };
    }
  }

  const mobileAuthBannerFile = secondHandCompany.mobileAuthBannerFile;

  if (mobileAuthBannerFile) {
    const response = await getMediaUploadPath({
      field: "mobileAuthBanner",
      entity: "secondHandCompany/pictures",
      filename: mobileAuthBannerFile.name,
      isPublic: true,
    });

    if (response.success) {
      const { path, url } = response.data;
      await fetch(url, { method: "put", body: mobileAuthBannerFile });
      input.mobileAuthBanner = path;
    } else {
      return { error: "Uploading mobile auth banner didn't work" };
    }
  }

  const bannerFile = secondHandCompany.bannerFile;
  if (bannerFile) {
    const response = await getMediaUploadPath({
      field: "banner",
      entity: "secondHandCompany/pictures",
      filename: bannerFile.name,
      isPublic: true,
    });

    if (response.success) {
      const { path, url } = response.data;
      await fetch(url, { method: "put", body: bannerFile });
      input.banner = path;
    } else {
      return { error: "Uploading banner didn't work" };
    }
  }

  const mobileBannerFile = secondHandCompany.mobileBannerFile;
  if (mobileBannerFile) {
    const response = await getMediaUploadPath({
      field: "mobileBanner",
      entity: "secondHandCompany/pictures",
      filename: mobileBannerFile.name,
      isPublic: true,
    });

    if (response.success) {
      const { path, url } = response.data;
      await fetch(url, { method: "put", body: mobileBannerFile });
      input.mobileBanner = path;
    } else {
      return {
        error: "Uploading mobile banner didn't work",
      };
    }
  }

  return { input };
};
