import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Conversation } from "~/types/data/Conversation.types";
import { Message } from "~/types/data/Message.types";
import {
  getUnreadConversationsCount,
  mapMessagesWithUnreadCount,
} from "~/util/functions/getUnreadConversationsCount";

import type { RootState } from "../store";

export interface ConversationsState {
  conversations: Conversation[];
  unreadConversationsCount: number;
}

const initialState: ConversationsState = {
  conversations: [],
  unreadConversationsCount: 0,
};

export const conversationsStateSlice = createSlice({
  name: "conversationsState",
  initialState,
  reducers: {
    initializeConversationState(
      state,
      action: PayloadAction<{
        conversations: Conversation[];
      }>
    ) {
      const { conversations } = action.payload;

      const modifiedConversations = mapMessagesWithUnreadCount(conversations);

      const unreadConversationsCount = getUnreadConversationsCount(
        modifiedConversations
      );
      return { conversations: modifiedConversations, unreadConversationsCount };
    },
    updateConversationsState(
      state,
      action: PayloadAction<{
        conversations: Conversation[];
      }>
    ) {
      const { conversations } = action.payload;

      const modifiedConversations = mapMessagesWithUnreadCount(conversations);

      const unreadConversationsCount = getUnreadConversationsCount(
        modifiedConversations
      );

      return {
        ...state,
        conversations: modifiedConversations,
        unreadConversationsCount,
      };
    },
    addSendingMessage(
      state,
      action: PayloadAction<{
        conversationId: number;
        message: Message;
      }>
    ) {
      const { conversationId, message } = action.payload;
      const conversations = state.conversations.map((conversation) =>
        conversation.id === conversationId
          ? { ...conversation, messages: [...conversation.messages, message] }
          : conversation
      );

      return { ...state, conversations };
    },
    removeSendingMessage(
      state,
      action: PayloadAction<{
        conversationId: number;
        addFailedMessageHandler: (message: Message) => void;
      }>
    ) {
      const { conversationId, addFailedMessageHandler } = action.payload;
      const conversations = state.conversations.map((conversation) => {
        if (conversation.id === conversationId) {
          const copyArray = [...conversation.messages];
          const lastItem = copyArray.pop();
          if (lastItem) {
            addFailedMessageHandler(lastItem);
          }
          return {
            ...conversation,
            messages: copyArray,
          };
        } else {
          return conversation;
        }
      });

      return { ...state, conversations };
    },
    markRead(
      state,
      action: PayloadAction<{
        conversationId: number;
      }>
    ) {
      const { conversationId } = action.payload;
      const conversations = state.conversations.map((conversation) =>
        conversation.id === conversationId
          ? {
              ...conversation,
              messages: conversation.messages.map((message) => ({
                ...message,
                read: true,
              })),
              unreadCount: 0,
            }
          : conversation
      );

      const unreadConversationsCount =
        getUnreadConversationsCount(conversations);

      return {
        ...state,
        conversations,
        unreadConversationsCount,
      };
    },
  },
});

export const {
  updateConversationsState,
  initializeConversationState,
  addSendingMessage,
  removeSendingMessage,
  markRead,
} = conversationsStateSlice.actions;

export const selectConversationsState = (state: RootState) => {
  return state.conversationsState;
};
export default conversationsStateSlice.reducer;
