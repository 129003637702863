import { gql } from "@apollo/client";

import {
  graphqlGetCollectionFields,
  graphqlGetProductFields,
} from "~/constants/graphql";
import { CollectionModel } from "~/types/data/Collection.type";

export type CollectionState = {
  id?: number;
  position?: number;
  name?: string;
  image?: string;
  description?: string;
  enabled?: boolean;
};

export interface UpsertCollectionInput {
  UpsertCollectionInput: CollectionState;
}

export interface UpsertCollectionResponse {
  upsertCollection: CollectionModel;
}

export interface GetAllCollectionsResponse {
  getAllCollections: CollectionModel[];
}

export interface DeleteCollectionInput {
  DeleteCollectionInput: {
    id: number;
  };
}

export interface DeleteCollectionResponse {
  deleteCollection: CollectionModel;
}

export interface CollectionProductPriorityInput {
  collectionId: number;
  productParentId: string;
  priority: number;
}
export interface UpdateCollectionProductsOrderObject {
  collectionId: number;
  priorities: CollectionProductPriorityInput[];
}
export interface UpdateCollectionProductsOrderInput {
  UpdateCollectionProductsOrderInput: UpdateCollectionProductsOrderObject;
}

export interface UpdateCollectionProductInput {
  UpdateCollectionProductInput: {
    collectionId: number;
    productId: number;
    secondHandPrice: number;
    discountedSecondHandPrice: number;
  };
}

const collectionFields = graphqlGetCollectionFields.join(" ");

export const UPSERT_COLLECTION = gql`
  mutation ($UpsertCollectionInput: UpsertCollectionInput!) {
    upsertCollection(UpsertCollectionInput: $UpsertCollectionInput) {
      ${collectionFields}
    }
  }
`;

export const DELETE_COLLECTION = gql`
  mutation ($DeleteCollectionInput: DeleteCollectionInput!) {
    deleteCollection(DeleteCollectionInput: $DeleteCollectionInput) {
      ${collectionFields}
    }
  }
`;

export const GET_ALL_COLLECTIONS = gql`
  query {
    getAllCollections {
      ${collectionFields}
      products {
         ${graphqlGetProductFields}
          image1FullPath 
          priorityInCollection  
      }
    }
  }
`;

export const UPDATE_COLLECTION_PRODUCT = gql`
  mutation ($UpdateCollectionProductInput: UpdateCollectionProductInput!) {
    updateCollectionProduct(
      UpdateCollectionProductInput: $UpdateCollectionProductInput
    ) {
      id
    }
  }
`;

export const UPDATE_COLLECTION_PRODUCTS_ORDER = gql`
  mutation (
    $UpdateCollectionProductsOrderInput: UpdateCollectionProductsOrderInput!
  ) {
    updateCollectionProductsOrder(
      UpdateCollectionProductsOrderInput: $UpdateCollectionProductsOrderInput
    ) {
      id
    }
  }
`;
