import { RentingPeriodEnum } from "~/types/data/Product.types";

export const mapRentingPeriod = (rentingPeriod: RentingPeriodEnum): string => {
  switch (rentingPeriod) {
    case RentingPeriodEnum.DAILY:
      return "Jour";
    case RentingPeriodEnum.WEEKLY:
      return "Semaine";
    case RentingPeriodEnum.MONTHLY:
      return "Mois";
    default:
      return "";
  }
};
