import { NavLink } from "react-router-dom";

import SVGContainer from "~/components/UI/SVGContainer";

import styles from "./index.module.scss";
type Props = {
  name: string;
  iconName: string;
  url: string;
};

export const NavigationSection = ({ name, iconName, url }: Props) => {
  return (
    <NavLink
      to={`/${url}`}
      className={({ isActive }) => (isActive ? styles.active : styles.link)}
    >
      <div className={styles.container}>
        <SVGContainer
          height="10px"
          width="1rem"
          imagePath={`/assets/${iconName}`}
        />
        {name}
      </div>
    </NavLink>
  );
};
