import { CarrierComponent } from "./CarrierComponent";

export const ListeTransporteur = () => {
  return (
    <>
      <CarrierComponent
        iconName="DHL-icon.svg"
        name="DHL"
        link="https://www.laposte.fr/outils/suivre-vos-envois?code=8R43765453759"
        bottomBorder={true}
      />
      <CarrierComponent
        iconName="UPS-icon.svg"
        name="UPS"
        link="https://www.laposte.fr/outils/suivre-vos-envois?code=8R43765453759"
        bottomBorder={true}
      />
      <CarrierComponent
        iconName="Colissimo-icon.svg"
        name="Colissimo"
        link="https://www.laposte.fr/outils/suivre-vos-envois?code=8R43765453759"
      />
    </>
  );
};
