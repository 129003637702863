import { ImportedFileStatusEnum } from "./ImportedFile.type";

export enum AdminImportTypeEnum {
  CATEGORIES = "CATEGORIES",
  ATTRIBUTE_VALUES = "ATTRIBUTE_VALUES",
}

export interface AdminImport {
  id: number;
  createdAt: string;
  finishedAt: string;
  filePath: string;
  type: AdminImportTypeEnum;
  status: ImportedFileStatusEnum;
  errorMessage: string;
  totalEntries: number;
  successfullEntries: number;
  associatedId?: number;
}
