import { gql } from "@apollo/client";

import { graphqlGetUBOFields } from "~/constants/graphql";
import {
  Company,
  UpdateCompanyUboDeclarationInput,
} from "~/types/data/Company.types";

export interface UpdateCompanyUboDeclarationApiResponse {
  updateCompanyUboDeclaration: Company;
}

export interface UpdateCompanyUboDeclarationApiInput {
  UpdateCompanyUboDeclarationInput: UpdateCompanyUboDeclarationInput;
}

const uboFields = graphqlGetUBOFields.join(" ");

export const UPDATE_COMPANY_UBO_DECLARATION = gql`
  mutation ($UpdateCompanyUboDeclarationInput: UpdateCompanyUboDeclarationInput!) {
    updateCompanyUboDeclaration(UpdateCompanyUboDeclarationInput: $UpdateCompanyUboDeclarationInput) {
      id
      uboDeclarationId
      uboDeclarationStatus
      ubos {
        ${uboFields}
      }
    }
  }
`;
