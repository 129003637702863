import { CSSProperties } from "@material-ui/core/styles/withStyles";

type Props = {
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
};

export const SizedBox = ({ width, height }: Props) => {
  return <div style={{ width, height }} />;
};
