import React, { useState } from "react";

import SVGContainer from "~/components/UI/SVGContainer";

import styles from "./index.module.scss";

interface Props {
  onChange: (imageFile: File | undefined) => void;
  value: string | undefined | null;
  id: string;
  disabled?: boolean;
  height?: string;
}

export const DragAndDropFilePicker = ({
  onChange,
  value,
  id,
  disabled,
  height,
}: Props) => {
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [hasError, setHasError] = useState(false);

  const dropHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDraggedOver(false);

    const file = event.dataTransfer.files[0];

    const extension = file.name.split(".").slice(-1)[0];

    if (!(extension === "xlsx" || extension === "csv")) {
      setHasError(true);
      return;
    }

    setHasError(false);

    onChange(file);
  };

  const filePickedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").slice(-1)[0];

      if (!(extension === "xlsx" || extension === "csv")) {
        setHasError(true);
        return;
      }

      setHasError(false);

      onChange(file);
    }
  };
  const dragOverHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDraggedOver(true);
  };

  const dragLeaveHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDraggedOver(false);
  };

  const removeFileHandler = () => {
    onChange(undefined);
  };

  return (
    <div className={`${styles.container}`}>
      {hasError && (
        <div className={`${styles.errorContainer}`}>
          <p>
            Your file must be either in XLSX format or in CSV format and encoded
            as UTF-8.
          </p>
        </div>
      )}
      <div
        className={`${styles.filePickerContainer} ${
          isDraggedOver ? styles.draggedOver : ""
        }`}
        id="drop-zone"
        onDrop={dropHandler}
        onDragOver={dragOverHandler}
        onDragLeave={dragLeaveHandler}
        style={{ height }}
      >
        <div className={`${styles.uploadControlsContainer}`}>
          <h3>Drag and drop a file</h3>
          <h4>Or</h4>
          <label htmlFor={id}>Select a file</label>
        </div>
      </div>

      {value && (
        <div className={`${styles.fileName}`}>
          <p>{value}</p>
          <SVGContainer
            height="10px"
            width="10px"
            imagePath="/assets/close-button.svg"
            onClick={removeFileHandler}
          />
        </div>
      )}
      <input
        type="file"
        id={id}
        onChange={filePickedHandler}
        disabled={disabled}
        onClick={(e) => {
          if (!disabled) e.currentTarget.value = "";
        }}
        hidden
      />
    </div>
  );
};
