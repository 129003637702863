import { OrderToProduct } from "./Order.types";

export enum MessagesSelectFilterEnum {
  READ = "READ",
  UNREAD = "UNREAD",
}

export enum MessageTypeEnum {
  TEXT = "TEXT",
  PICTURE = "PICTURE",
  FILE = "FILE",
  CUSTOM = "CUSTOM",
}

export enum CustomMessageTypeEnum {
  INCIDENT_OPEN = "INCIDENT_OPEN",
  INCIDENT_CLOSED_BY_SELLER = "INCIDENT_CLOSED_BY_SELLER",
  INCIDENT_CLOSED = "INCIDENT_CLOSED",
  INCIDENT_REOPENED = "INCIDENT_REOPENED",
}

export enum MessageStatusEnum {
  SENDING = "SENDING",
  RECIEVED = "RECIEVED",
  FAILED = "FAILED",
}

export interface Message {
  id?: number;
  createdAt: string;
  type: MessageTypeEnum;
  read?: boolean;
  text?: string;
  picturePath?: string;
  filePath?: string;
  pictureUrl?: string;
  fileUrl?: string;
  senderId: number;
  recipientId: number;
  customMessage?: CustomMessage;
  status?: MessageStatusEnum;
}

export interface CustomMessage {
  id: number;
  type: CustomMessageTypeEnum;
  reason: string;
  text: string;
  orderToProducts: OrderToProduct[];
}
