import InfiniteScroll from "react-infinite-scroll-component";

import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { HeadCell } from "~/types/common/headCell.type";
import { Product } from "~/types/data/Product.types";
import { VendorCategory } from "~/types/data/VendorCategory.types";

import { CataloguesTableBody } from "./CataloguesTableBody";
import { CataloguesTableHead } from "./CataloguesTableHead";
import styles from "./index.module.scss";

const HEADCELLS: HeadCell<Product>[] = [
  { id: 1, label: "", isCheckbox: true },
  { id: 2, label: "Photo" },
  { id: 3, label: "Produit", isSortable: true, associatedKey: "title" },
  { id: 4, label: "Identifiants", isSortable: true, associatedKey: "sku" },
  { id: 5, label: "Quantité", isSortable: true, associatedKey: "quantity" },
  { id: 6, label: "Prix", isSortable: true, associatedKey: "priceTTC" },
  {
    id: 7,
    label: "Date création",
    isSortable: true,
    associatedKey: "createdAt",
  },
  { id: 8, label: "Date MàJ", isSortable: true, associatedKey: "updatedAt" },
  { id: 9, label: "Statut", isSortable: true, associatedKey: "status" },
  { id: 10, label: "Etat", isSortable: true, associatedKey: "stateType" },
  { id: 11, label: "Company" },
  { id: 12, label: "" },
];
interface Props {
  productVariants?: Product[];
  selectedRows: Product[];
  onCheckboxChange: (product: Product) => void;
  onSelectAllChange: (selected: boolean) => void;
  isSelectedAll: boolean;
  fetchMoreData: () => void;
  hasMoreData: boolean;
  vendorCategories: VendorCategory[];
  onRowClick: (variantId: number) => void;
  showCompanyColumn: boolean;
}

export const CataloguesTable = ({
  productVariants,
  selectedRows,
  onCheckboxChange,
  onSelectAllChange,
  fetchMoreData,
  hasMoreData,
  vendorCategories,
  onRowClick,
  isSelectedAll,
  showCompanyColumn,
}: Props) => {
  const MODIFIED_HEADCELLS = showCompanyColumn
    ? HEADCELLS
    : HEADCELLS.filter(({ id }) => id !== HEADCELLS.length - 1);

  return (
    <>
      <InfiniteScroll
        dataLength={productVariants?.length ?? 0}
        next={fetchMoreData}
        hasMore={!!productVariants && hasMoreData}
        loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
      >
        <table className={`${styles.table}`}>
          <CataloguesTableHead
            headCells={MODIFIED_HEADCELLS}
            onSelectAllChange={onSelectAllChange}
            allSelected={isSelectedAll}
          />
          <CataloguesTableBody
            productVariants={productVariants || []}
            selectedRows={selectedRows}
            onCheckboxChange={onCheckboxChange}
            vendorCategories={vendorCategories}
            onRowClick={onRowClick}
            showCompanyColumn={showCompanyColumn}
          />
        </table>
      </InfiniteScroll>
      {!productVariants && (
        <div className={styles.loaderContainer}>
          <SimpleLoader size="size3" fill={colors.$primary} />
        </div>
      )}
    </>
  );
};
