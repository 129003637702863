const daysAgo = (date: Date) => {
  const today = new Date();
  const diff = Math.abs(today.getTime() - date.getTime());
  return Math.ceil(diff / (1000 * 3600 * 24));
};

export const mapDate = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const newDate = new Date();
  const sameMonthYear =
    newDate.getFullYear() === year && newDate.getMonth() + 1 === month;

  if (sameMonthYear && newDate.getDate() === day) {
    return "Aujourd'hui";
  } else if (sameMonthYear && newDate.getDate() - 1 === day) {
    return "Hier";
  } else if (daysAgo(date) <= 7) {
    return `il y a ${daysAgo(date)} jours`;
  }
  return `${day}/${month}/${year}`;
};
