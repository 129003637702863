export enum ShopTypes {
  Magento = "Magento",
  PrestaShop = "PrestaShop",
  WooCommerce = "WooCommerce",
  Shopify = "Shopify",
}

export type ForeignShop = {
  id: number;
  newShopId?: string;
  name: string;
  updateQuantity: boolean;
  updatePrice: boolean;
  updateDiscount: boolean;
  updateOrders: boolean;
  provider?: ShopTypes;
  companyId?: number;
};

export interface ForeignShopWithCount extends ForeignShop {
  _count: {
    foreignShopMapping: number;
    products?: number;
  };
}
