import { Rating } from "@mui/material";

import { colors } from "~/constants/styles";

import styles from "./index.module.scss";

interface Props {
  rating: number;
  precision?: 0.5 | 1;
  size?: "small" | "medium" | "large";
  color?: string;
  secondNumber?: number;
}

export const RatingStars = ({
  rating,
  color = colors.$primary,
  precision = 0.5,
  size = "large",
  secondNumber,
}: Props) => {
  return (
    <div className={`${styles.container}`}>
      <Rating
        readOnly
        value={rating}
        precision={precision}
        size={size}
        sx={{ fontSize: "35px", color: color }}
      />
      <p>{secondNumber}</p>
    </div>
  );
};
