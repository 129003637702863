import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Attribute } from "~/types/data/Attribute.types";
import { AttributeValue } from "~/types/data/AttributeValue.types";
import { AttributeValuesSheetValueType } from "~/types/data/FileImport.types";
import { Product } from "~/types/data/Product.types";
import {
  SellerMapping,
  SellerMappingTypeEnum,
} from "~/types/data/SellerMapping.types";
import { getAttributeValues } from "~/util/functions/getAttributeValues";
import { getKeys } from "~/util/functions/getKeys";
import { getBestMatch } from "~/util/functions/matching";

import type { RootState } from "../store";

export type MappedAttributeValues<K extends keyof Product> = {
  [key: string]: AttributeValue<Product[K]>;
};

export type MappedAttributeValuesReduxState = {
  [key in keyof Product]: MappedAttributeValues<key>;
};

const initialState: MappedAttributeValuesReduxState = {};

export const mappedAttributeValuesSlice = createSlice({
  name: "mappedAttributeValues",
  initialState,
  reducers: {
    initializeMappedAttributeValues(
      state,
      action: PayloadAction<{
        attributeValuesSheetValues: AttributeValuesSheetValueType;
        attributes: Attribute[];
        sellerMappings: SellerMapping[];
      }>
    ) {
      const { attributeValuesSheetValues, attributes, sellerMappings } =
        action.payload;

      let mappedAttributeValues: MappedAttributeValuesReduxState = {};

      const attributeKeys = getKeys(attributeValuesSheetValues);

      attributeKeys.forEach((attributeKey) => {
        const attribute = attributes.find((attr) => attr.name === attributeKey);
        const attributeValues = getAttributeValues(attribute);

        const attributeValuesNames = attributeValues
          .map(({ associatedValues }) => associatedValues as string[])
          .reduce((a, b) => a.concat(b), []);

        const sheetValues = attributeValuesSheetValues[attributeKey] || [];

        for (const originalSheetName of sheetValues) {
          const sellerMapping = sellerMappings.find(
            ({ originalValue, type, name }) =>
              type === SellerMappingTypeEnum.ATTRIBUTE_VALUE &&
              name === attributeKey &&
              originalValue.toLowerCase() === originalSheetName.toLowerCase()
          );

          const attrValue = attributeValues.find(
            ({ id }) => id.toString() === sellerMapping?.mappedValue
          );
          if (sellerMapping) {
            const attributeNameValueMap = {
              ...mappedAttributeValues[attributeKey],
              [originalSheetName]: attrValue,
            };
            mappedAttributeValues = {
              ...mappedAttributeValues,
              [attributeKey]: attributeNameValueMap,
            };
            continue;
          }

          const bestMatch = getBestMatch(
            originalSheetName,
            attributeValuesNames
          );

          if (bestMatch) {
            const mappedAttributeValue = attributeValues.find(
              ({ associatedValues }) => associatedValues.includes(bestMatch)
            );
            const attributeNameValueMap = {
              ...mappedAttributeValues[attributeKey],
              [originalSheetName]: mappedAttributeValue,
            };
            mappedAttributeValues = {
              ...mappedAttributeValues,
              [attributeKey]: attributeNameValueMap,
            };
          } else {
            const attributeNameValueMap = {
              ...mappedAttributeValues[attributeKey],
              [originalSheetName]: undefined,
            };
            mappedAttributeValues = {
              ...mappedAttributeValues,
              [attributeKey]: attributeNameValueMap,
            };
          }
        }
      });
      return mappedAttributeValues;
    },
    onMappingChange<K extends keyof Product>(
      state: MappedAttributeValuesReduxState,
      action: PayloadAction<{
        attributeValueName: string;
        attributeKey: K;
        mappedAttributeValue?: AttributeValue<Product[K]>;
      }>
    ) {
      const { attributeValueName, mappedAttributeValue, attributeKey } =
        action.payload;
      const attributeNameValueMap = {
        ...state[attributeKey],
        [attributeValueName]: mappedAttributeValue,
      };
      const newMappedAttributeValuesState = {
        ...state,
        [attributeKey]: attributeNameValueMap,
      };
      return newMappedAttributeValuesState;
    },
  },
});

export const { initializeMappedAttributeValues, onMappingChange } =
  mappedAttributeValuesSlice.actions;

export const selectMappedAttributeValuesState = (state: RootState) =>
  state.mappedAttributeValues;

export default mappedAttributeValuesSlice.reducer;
