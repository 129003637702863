import { DeliveryAddress } from "~/types/data/Order.types";
import { Profile } from "~/types/data/Profile.types";

import styles from "./index.module.scss";

interface Props {
  shippingAddress?: DeliveryAddress;
  buyer?: Profile;
}

export const ShippingAddressCard = ({ shippingAddress, buyer }: Props) => {
  const phoneNumber = shippingAddress?.phoneNumber || buyer?.phoneNumber || "";
  const email = shippingAddress?.email || buyer?.email || "";

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.titleContainer}`}>
        <p>Livraison</p>
      </div>

      <div className={`${styles.sectionContainer}`}>
        <p className={`${styles.sectionTitle}`}>Informations client :</p>
        {shippingAddress?.name && (
          <p className={`${styles.sectionData}`}>{shippingAddress?.name}</p>
        )}
        {phoneNumber && (
          <p className={`${styles.sectionData}`}>{phoneNumber}</p>
        )}
        {email && <p className={`${styles.sectionData}`}>{email}</p>}
      </div>
      <div className={`${styles.sectionContainer}`}>
        <p className={`${styles.sectionTitle}`}>Adresse de livraison :</p>
        <p className={`${styles.sectionData}`}>{shippingAddress?.address1}</p>
        {!!shippingAddress?.address2 && (
          <p className={`${styles.sectionData}`}>{shippingAddress?.address2}</p>
        )}
        <p className={`${styles.sectionData}`}>
          {shippingAddress?.city}, {shippingAddress?.country}
        </p>
        {shippingAddress?.zipCode && (
          <p className={`${styles.sectionData}`}>{shippingAddress?.zipCode}</p>
        )}
      </div>
    </div>
  );
};
