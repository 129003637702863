import { useState } from "react";

import deleteUser from "~/api/auth/deleteUser";
import CustomModal from "~/components/UI/CustomModal";
import SVGContainer from "~/components/UI/SVGContainer";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useUserContext } from "~/context/userContext";
import { PermissionLevelEnum } from "~/types/data/User.types";

import styles from "./index.module.scss";
import { UserForm } from "./UserForm";
import { UsersTable } from "./UsersTable";

export const Users = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [userId, setUserId] = useState<number>();
  const [deletingId, setDeletingId] = useState<number>();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { companyAccount, setCompanyAccount } = useCompanyAccountContext();
  const { userState } = useUserContext();

  const userEditHandler = (id?: number) => {
    setShowEditModal(true);
    setUserId(id);
  };
  const userDeleteHandler = async (id: number) => {
    setDeletingId(id);
    const res = await deleteUser({ id });
    if (res.success) {
      setSuccessMessage("User deleted successfully.");

      setCompanyAccount((prev) => {
        const updatedUsersArray = prev.users.filter((user) => {
          return user.id !== id;
        });
        return { ...prev, users: updatedUsersArray };
      });
    } else {
      setErrorMessage(res.errorMessage || "Unknown Error");
    }
    setDeletingId(undefined);
  };

  const hideEditModalHandler = () => {
    setShowEditModal(false);
  };

  const selectedUser = companyAccount.users.find((user) => user.id === userId);

  return (
    <>
      <CustomModal
        onCancel={hideEditModalHandler}
        show={showEditModal}
        disableScroll={true}
      >
        <UserForm user={selectedUser} />
      </CustomModal>
      <div className="myAccountFormSection">
        <div className={styles.titleWithButtonContainer}>
          <h3 className="title">Utilisateurs</h3>
          {userState?.connected &&
            userState.permissionLevel === PermissionLevelEnum.ADMIN && (
              <div
                className={styles.addButton}
                onClick={() => {
                  userEditHandler();
                }}
              >
                <SVGContainer
                  height="16px"
                  width="16px"
                  imagePath="/assets/add-button.svg"
                />
                <p>Ajouter</p>
              </div>
            )}
        </div>

        <UsersTable
          users={companyAccount.users}
          onUserEditClick={userEditHandler}
          onUserDeleteClick={(id: number) => {
            userDeleteHandler(id);
          }}
          deletingId={deletingId}
        />
        <div className="tableMessageContainer">
          {!!errorMessage && <p className="errorMessage">{errorMessage}</p>}
          {!!successMessage && <p>{successMessage}</p>}
        </div>
      </div>
    </>
  );
};
