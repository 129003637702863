import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import uuid4 from "uuid4";

import {
  GET_ADMIN_ATTRIBUTES,
  GetAdminAttributesResponse,
} from "~/api/graphql/attribute";
import {
  GET_ALL_LEVEL1_CATEGORIES,
  GetAllLevel1CategoriesResponse,
} from "~/api/graphql/category";
import {
  GET_ALL_VENDOR_CATEGORIES_WITH_MAPPINGS,
  GetAllVendorCategoriesResponse,
} from "~/api/graphql/vendorCategory";
import { OptionType } from "~/components/form/SelectInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import SVGContainer from "~/components/UI/SVGContainer";
import { colors } from "~/constants/styles";
import { ConfirmModal } from "~/pages/SynchronizationOfStreams/myConnectors/ConfirmModal";
import { Category } from "~/types/data/Category.type";
import { VendorCategory } from "~/types/data/VendorCategory.types";
import { getCategories3FromCategories1 } from "~/util/functions/category/getAllNestedCategories.util";

import styles from "./index.module.scss";
import { MemoizedVendorCategoriesTable } from "./VendorCategoriesTable";

export type DeleteModalProps = {
  open: boolean;
  deleteCategory?: () => Promise<void>;
};

export const VendorCategories = () => {
  const [vendorCategoriesData, setVendorCategoriesData] =
    useState<VendorCategory[]>();
  const [deleteModalState, setDeleteModalState] = useState<DeleteModalProps>({
    open: false,
  });

  const [level1Categories, setLevel1Categories] = useState<Category[]>([]);
  const [level3Categories, setLevel3Categories] = useState<Category[]>([]);
  const [attributes, setAttributes] = useState<OptionType[]>([]);
  const [fetchingErrorMessage, setFetchingErrorMessage] = useState<string>("");
  // const [errorMessage, setErrorMessage] = useState<string>("");

  const { data: getCategoriesData } = useQuery<GetAllLevel1CategoriesResponse>(
    GET_ALL_LEVEL1_CATEGORIES
  );

  const { data: attributesData } =
    useQuery<GetAdminAttributesResponse>(GET_ADMIN_ATTRIBUTES);

  const [getVendorCategoriesTrigger] =
    useLazyQuery<GetAllVendorCategoriesResponse>(
      GET_ALL_VENDOR_CATEGORIES_WITH_MAPPINGS
    );

  const fetchVendorCategoriesHandler = async () => {
    setFetchingErrorMessage("");
    const { data, error } = await getVendorCategoriesTrigger();

    if (error) {
      setFetchingErrorMessage("Error fetching vendor categories");
      return;
    }

    if (!data) {
      setFetchingErrorMessage("No data found");
      return;
    }

    setVendorCategoriesData(data.getAllVendorCategories);
  };

  useEffect(() => {
    setVendorCategoriesData(undefined);
    fetchVendorCategoriesHandler();
  }, []);

  useEffect(() => {
    if (!getCategoriesData) return;

    const categories3 = getCategories3FromCategories1(
      getCategoriesData.getAllLevel1Categories
    );

    setLevel3Categories(categories3);
    setLevel1Categories(getCategoriesData.getAllLevel1Categories);
  }, [getCategoriesData]);

  useEffect(() => {
    if (!attributesData) return;
    setAttributes(
      attributesData.getAdminAttributes.map((attribute) => {
        return {
          label: attribute.name,
          value: attribute.id.toString(),
        };
      })
    );
  }, [attributesData]);

  const addNewVendorCategoryHandler = () => {
    setVendorCategoriesData((prevState) => {
      return [
        {
          id: uuid4(),
          name: "",
          associatedNames: [],
          attributes: [],
          mappings: [
            {
              category1Id: null,
              category2Id: null,
              category3Id: null,
            },
          ],
        },
        ...(prevState ?? []),
      ];
    });
  };

  return (
    <div className={styles.container}>
      <ConfirmModal
        onCancel={() =>
          setDeleteModalState({
            open: false,
          })
        }
        show={deleteModalState.open}
        onConfirm={() => {
          deleteModalState.deleteCategory?.();
          setDeleteModalState({
            open: false,
          });
        }}
        text="Are you sure you want to delete this vendor category?"
      />

      {!!fetchingErrorMessage ? (
        <div className={styles.error}>
          <p>{fetchingErrorMessage}</p>
        </div>
      ) : !vendorCategoriesData ||
        !level1Categories.length ||
        !level3Categories.length ||
        !attributes.length ? (
        <div className={styles.loadingContainer}>
          <SimpleLoader size="size3" fill={colors.$primary} />
        </div>
      ) : (
        <>
          <div className={styles.headerContainer}>
            <h2 className={styles.header}>Taxonomie</h2>
            <SVGContainer
              onClick={addNewVendorCategoryHandler}
              imagePath="/assets/add-button.svg"
              width="32px"
              height="32px"
            />
          </div>
          <MemoizedVendorCategoriesTable
            vendorCategories={vendorCategoriesData}
            level1Categories={level1Categories}
            level3Categories={level3Categories}
            attributes={attributes}
            setDeleteModalState={setDeleteModalState}
          />
        </>
      )}
    </div>
  );
};
