import { Dispatch, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  deleteForeignShop,
  DeleteForeignShopInput,
  DeleteForeignShopResponse,
  updateForeignShop,
  UpdateForeignShopInput,
  UpdateForeignShopResponse,
} from "~/api/graphql/foreignShop";
import fetchShopifyProductsRequest from "~/api/rest/fetchShopifyProducts";
import synchronizeShopifyMappingsRequest from "~/api/rest/synchronizeShopifyMappings";
import updateShopifyProductsRequest from "~/api/rest/updateShopifyProducts";
import { CustomButton } from "~/components/form/CustomButton";
import SVGContainer from "~/components/UI/SVGContainer";
import { customRedButtonParams } from "~/constants/style";
import { colors } from "~/constants/styles";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import {
  ForeignShopWithCount,
  ShopTypes,
} from "~/types/data/ForeignShop.types";

import { UpdateShopOptions } from "..";
import { CheckboxContainer } from "../checkboxContainer";
import { ShopCard } from "../shopCard";
import { ConfirmModal } from "./ConfirmModal";
import { GeneralModal } from "./GeneralModal";
import styles from "./index.module.scss";
import { ShopifyConnect } from "./ShopifyConnect";

type Props = {
  shop?: ForeignShopWithCount;
  increaseCounter?: () => void;
  removeShop: (shopId: string | number, isNew: boolean) => void;
  newShopId?: string;
  setLoading: Dispatch<React.SetStateAction<boolean>>;
  updateShopCount: (shopId: number, newCount: number) => void;
  refetchShops: () => void;
};

export const MyConnectors = ({
  increaseCounter,
  removeShop,
  newShopId,
  shop,
  setLoading,
  refetchShops,
}: Props) => {
  console.log("newShopId", newShopId);
  const navigate = useNavigate();
  const [selectedShop, setSelectedShop] = useState<ShopTypes>(
    shop?.provider ?? ShopTypes.Shopify
  );
  const [checkedOptions, setCheckedOptions] = useState<Set<UpdateShopOptions>>(
    new Set<UpdateShopOptions>([
      ...(shop?.updateDiscount ? [UpdateShopOptions.Discounts] : []),
      ...(shop?.updateQuantity ? [UpdateShopOptions.Stocks] : []),
      ...(shop?.updatePrice ? [UpdateShopOptions.Prices] : []),
      ...(shop?.updateOrders ? [UpdateShopOptions.Orders] : []),
    ])
  );
  const [showShop, setShowShop] = useState(false);

  const [generalModalDetails, setGeneralModalDetails] = useState({
    show: false,
    text: "",
  });

  const [confirmModalDetails, setConfirmModalDetails] = useState({
    show: false,
    text: "",
  });

  const onUpdateForeignShopSuccess = () => {
    setGeneralModalDetails({
      show: true,
      text: "The shop has been updated successfully",
    });
  };

  const onUpdateForeignShopFail = () => {
    setGeneralModalDetails({
      show: true,
      text: "An error occurred while updating the shop",
    });
  };

  const onDeleteForeignShopSuccess = () => {
    setLoading(false);
    refetchShops();
  };

  const onDeleteForeignShopFail = () => {
    setLoading(false);
    setGeneralModalDetails({
      show: true,
      text: "An error occurred while deleting the shop",
    });
  };

  const {
    trigger: updateForeignShopTrigger,
    loading: updateForeignShopLoading,
  } = useMutationWithCallbacks<
    UpdateForeignShopResponse,
    UpdateForeignShopInput
  >(updateForeignShop, onUpdateForeignShopSuccess, onUpdateForeignShopFail);

  const { trigger: deleteForeignShopTrigger } = useMutationWithCallbacks<
    DeleteForeignShopResponse,
    DeleteForeignShopInput
  >(deleteForeignShop, onDeleteForeignShopSuccess, onDeleteForeignShopFail);

  const resetGeneralModalDetails = () => {
    setGeneralModalDetails({ show: false, text: "" });
  };

  const handleShowShops = () => {
    if (increaseCounter) increaseCounter();
    setShowShop(true);
  };
  const handleCheckBoxes = (type: UpdateShopOptions) => {
    if (checkedOptions.has(type)) {
      checkedOptions.delete(type);
    } else {
      checkedOptions.add(type);
    }
    setCheckedOptions(new Set<UpdateShopOptions>(checkedOptions));
  };
  const handleMappingNav = () => {
    if (shop) navigate(`/importer-par-boutique/${shop.id}`);
  };

  const fetchNewProducts = async (shopId: number) => {
    setLoading(true);
    const response = await fetchShopifyProductsRequest(shopId);
    setLoading(false);

    const text = response.error
      ? response.error
      : response.count
      ? `${response.count} products have been added successfully`
      : "No new products were found";

    setGeneralModalDetails({
      show: true,
      text,
    });
  };

  const syncMappings = async (shopId: number) => {
    setLoading(true);
    const response = await synchronizeShopifyMappingsRequest(shopId);
    setLoading(false);

    const text = response.error
      ? response.error
      : "Products have been synchronized successfully";

    setGeneralModalDetails({
      show: true,
      text,
    });
  };

  const updateProducts = async (shopId: number) => {
    setLoading(true);
    const response = await updateShopifyProductsRequest(shopId);
    setLoading(false);

    const text = response.error
      ? response.error
      : "Products have been updated successfully";

    setGeneralModalDetails({
      show: true,
      text,
    });
  };

  const updateStore = (shopId: number) => {
    updateForeignShopTrigger({
      variables: {
        UpdateForeignShopInput: {
          id: shopId,
          updateDiscount: checkedOptions.has(UpdateShopOptions.Discounts),
          updatePrice: checkedOptions.has(UpdateShopOptions.Prices),
          updateQuantity: checkedOptions.has(UpdateShopOptions.Stocks),
          updateOrders: checkedOptions.has(UpdateShopOptions.Orders),
        },
      },
    });
  };

  const deleteShop = (shopId: number) => {
    deleteForeignShopTrigger({
      variables: {
        DeleteForeignShopInput: {
          id: shopId,
        },
      },
    });
  };

  const removeShopTrigger = () => {
    if (newShopId) {
      removeShop(newShopId ?? "", true);
    } else {
      setConfirmModalDetails({
        show: true,
        text: "Are you sure you want to delete this shop?",
      });
    }
  };

  const deleteShopTrigger = () => {
    setLoading(true);
    setConfirmModalDetails({ show: false, text: "" });
    deleteShop(shop?.id ?? 0);
  };

  useEffect(() => {
    setLoading(updateForeignShopLoading);
  }, [updateForeignShopLoading]);

  return (
    <>
      <ConfirmModal
        {...confirmModalDetails}
        onConfirm={deleteShopTrigger}
        onCancel={() => {
          setConfirmModalDetails({ show: false, text: "" });
        }}
      />
      <GeneralModal
        show={generalModalDetails.show}
        onCancel={resetGeneralModalDetails}
        text={generalModalDetails.text}
      />
      {!showShop && !shop && (
        <CustomButton
          {...customRedButtonParams}
          backgroundColor={colors.$primary}
          color="white"
          onClick={handleShowShops}
        >
          <SVGContainer
            imagePath="assets/round_add.svg"
            width="1rem"
            height="1rem"
          />
          <p className={styles.gap}> Ajouter un connecteur</p>
        </CustomButton>
      )}
      {shop && <h4>{`#${shop.id} ${shop.name}`}</h4>}
      {(showShop || !!shop) && (
        <div className={styles.container}>
          <div className={styles.deleteButton} onClick={removeShopTrigger}>
            <SVGContainer
              imagePath="assets/delete-button.svg"
              height="18px"
              width="18px"
            />
          </div>

          <div className={styles.shops}>
            <ShopCard
              disabled={!!shop ? shop.provider !== ShopTypes.Shopify : false}
              onClick={setSelectedShop}
              path="assets/shopify.png"
              selected={selectedShop === ShopTypes.Shopify}
              shopType={ShopTypes.Shopify}
            />
            {/* <ShopCard
              disabled={!!shop ? shop.provider !== ShopTypes.PrestaShop : false}
              onClick={setSelectedShop}
              path="assets/presta-shop.png"
              selected={selectedShop === ShopTypes.PrestaShop}
              shopType={ShopTypes.PrestaShop}
            />
            <ShopCard
              disabled={
                !!shop ? shop.provider !== ShopTypes.WooCommerce : false
              }
              onClick={setSelectedShop}
              path="assets/woo-commerce.png"
              selected={selectedShop == ShopTypes.WooCommerce}
              shopType={ShopTypes.WooCommerce}
            />
            <ShopCard
              disabled={!!shop ? shop.provider !== ShopTypes.Magento : false}
              onClick={setSelectedShop}
              path="assets/magento.png"
              selected={selectedShop === ShopTypes.Magento}
              shopType={ShopTypes.Magento}
            /> */}
          </div>

          {!!shop && (
            <>
              <h3 className={styles.bold500}>Catalog managment</h3>
              <div className={styles.active_actions}>
                <CustomButton
                  {...customRedButtonParams}
                  fontSize="0.8rem"
                  borderRadius={"50px"}
                  onClick={() => {
                    fetchNewProducts(shop?.id);
                  }}
                >
                  <p className={styles.gap}>Import products</p>
                </CustomButton>
                <CustomButton
                  {...customRedButtonParams}
                  fontSize="0.8rem"
                  borderRadius={"50px"}
                  onClick={handleMappingNav}
                  disabled={shop?._count?.foreignShopMapping === 0 ?? false}
                >
                  <p className={styles.gap}>Catalog mapping</p>
                </CustomButton>
                <CustomButton
                  {...customRedButtonParams}
                  fontSize="0.8rem"
                  borderRadius={"50px"}
                  onClick={() => {
                    syncMappings(shop.id);
                  }}
                >
                  <p className={styles.gap}>Synchronize mappings</p>
                </CustomButton>
              </div>
            </>
          )}
          <h3 className={styles.bold500}>Enable automatic updates :</h3>

          <CheckboxContainer<UpdateShopOptions>
            text="Quantity"
            checked={checkedOptions.has(UpdateShopOptions.Stocks)}
            type={UpdateShopOptions.Stocks}
            onSwitch={handleCheckBoxes}
            haveIcon={true}
            iconText="Automatic product quantity updates in the 
            Reedoo>Shopify and Shopify>Reedoo direction."
          />
          <CheckboxContainer<UpdateShopOptions>
            text="Prices"
            checked={checkedOptions.has(UpdateShopOptions.Prices)}
            type={UpdateShopOptions.Prices}
            onSwitch={handleCheckBoxes}
            haveIcon={true}
            iconText="Automatic price updates. Price modifications on Shopify 
            will be automatically applied to your Reedoo catalogue."
          />
          <CheckboxContainer<UpdateShopOptions>
            text="Discounted prices"
            checked={checkedOptions.has(UpdateShopOptions.Discounts)}
            type={UpdateShopOptions.Discounts}
            onSwitch={handleCheckBoxes}
            haveIcon={true}
            iconText="Automatic update of discounts. 
            Discounts made on your Shopify shop will be applied on Reedoo."
          />
          <CheckboxContainer<UpdateShopOptions>
            text="Orders"
            checked={checkedOptions.has(UpdateShopOptions.Orders)}
            type={UpdateShopOptions.Orders}
            onSwitch={handleCheckBoxes}
            haveIcon={true}
            iconText="Receive and process orders from your Shopify 
            shop (shipping, refunds...)"
          />
          {!!shop ? (
            <div className={styles.buttonsHolder}>
              <CustomButton
                {...customRedButtonParams}
                onClick={() => {
                  updateStore(shop.id);
                }}
              >
                <p className={styles.gap}>Update Store Automation</p>
              </CustomButton>
              <CustomButton
                {...customRedButtonParams}
                onClick={() => {
                  updateProducts(shop.id);
                }}
              >
                <p className={styles.gap}>Update Products</p>
              </CustomButton>
            </div>
          ) : selectedShop === ShopTypes.Shopify ? (
            <ShopifyConnect
              setLoading={setLoading}
              refetchShops={refetchShops}
              updateData={checkedOptions}
            />
          ) : (
            <br />
          )}
        </div>
      )}
    </>
  );
};
