import { DateTime } from "luxon";
import { SingleValue } from "react-select";

import DatePicker from "~/components/form/DatePicker";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import SVGContainer from "~/components/UI/SVGContainer";
import { useAppDispatch, useAppSelector } from "~/redux/hooks";
import {
  onFilterArgsChange,
  selectAdminCompaniesFilterArgs,
} from "~/redux/slice/adminCompanies.slice";
import { KycLevelEnum } from "~/types/data/Company.types";

import styles from "./index.module.scss";

export const CompaniesFilterForm = () => {
  const filterArgsState = useAppSelector(selectAdminCompaniesFilterArgs);

  const dispatch = useAppDispatch();

  const clearAllFiltersHandler = () => {
    dispatch(
      onFilterArgsChange({
        filterArg: "clearAll",
      })
    );
  };

  const kycLevelOptions: OptionType[] = [
    { value: KycLevelEnum.LIGHT, label: "LIGHT" },
    { value: KycLevelEnum.REGULAR, label: "REGULAR" },
  ];

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.title}`}>
        <SVGContainer
          height="16px"
          width="17px"
          imagePath="/assets/filter-icon.svg"
        />
        <p>Filtrer les companies</p>
      </div>
      <div className={`${styles.formSection}`}>
        <p className={`${styles.label}`}>Date de création :</p>
        <div className={`${styles.inputsContainer}`}>
          <div className={`${styles.datePickerContainer}`}>
            <p>Du</p>
            <DatePicker
              fontSize="12px"
              icon="keyboardArrowDown"
              onChange={(value: DateTime | null) => {
                if (value?.isValid !== false) {
                  const modifiedDate = value?.set({ hour: 0, minute: 0 });
                  dispatch(
                    onFilterArgsChange({
                      filterArg: "createdAtFrom",
                      createdAtFrom: modifiedDate?.toISO(),
                    })
                  );
                }
              }}
              value={
                filterArgsState.createdAtFrom
                  ? DateTime.fromISO(filterArgsState.createdAtFrom)
                  : null
              }
              backgroundColor="white"
              borderRadius="5px"
            />
          </div>
          <div className={`${styles.datePickerContainer}`}>
            <p>au</p>
            <DatePicker
              fontSize="12px"
              icon="keyboardArrowDown"
              onChange={(value: DateTime | null) => {
                if (value?.isValid !== false) {
                  const modifiedDate = value?.set({ hour: 23, minute: 59 });
                  dispatch(
                    onFilterArgsChange({
                      filterArg: "createdAtTo",
                      createdAtTo: modifiedDate?.toISO(),
                    })
                  );
                }
              }}
              value={
                filterArgsState.createdAtTo
                  ? DateTime.fromISO(filterArgsState.createdAtTo)
                  : null
              }
              backgroundColor="white"
              borderRadius="5px"
            />
          </div>
        </div>
        <div className={`${styles.singleInput}`}>
          <p className={`${styles.label}`}>KYC level :</p>
          <SelectInput
            fontSize="12px"
            backgroundColor="white"
            options={kycLevelOptions}
            onChange={(option: SingleValue<OptionType>) => {
              dispatch(
                onFilterArgsChange({
                  filterArg: "kycLevel",
                  kycLevel: (option?.value as KycLevelEnum) || undefined,
                })
              );
            }}
            value={filterArgsState.kycLevel?.toString() || ""}
            hasDefault={true}
            defaultLabel="Not selected"
          />
        </div>
      </div>{" "}
      <div className={`${styles.clearAllButtonContainer}`}>
        <p className={`${styles.label}`} onClick={clearAllFiltersHandler}>
          Effacer tous les filtres
        </p>
      </div>
    </div>
  );
};
