import { NavLink } from "react-router-dom";

import { routesBuilder } from "~/navigation/routes";

import styles from "./index.module.scss";

interface Props {
  userId: number;
}

export const UserNavigation = ({ userId }: Props) => {
  const userDetailsToDetailsRoute = routesBuilder({
    routes: ["userDetailsRoute", "detailsRoute"],
    replace: [{ position: 1, variable: `${userId}` }],
  });

  const userDetailsToProfileRoute = routesBuilder({
    routes: ["userDetailsRoute", "profileRoute"],
    replace: [{ position: 1, variable: `${userId}` }],
  });

  const userDetailsToAddressesRoute = routesBuilder({
    routes: ["userDetailsRoute", "addressesRoute"],
    replace: [{ position: 1, variable: `${userId}` }],
  });

  return (
    <div className={`${styles.navigationContainer}`}>
      <div>
        <NavLink
          to={`/${userDetailsToDetailsRoute}`}
          className={({ isActive }) => (isActive ? styles.active : "")}
          replace={false}
        >
          Details
        </NavLink>
      </div>
      <div>
        <NavLink
          to={`/${userDetailsToProfileRoute}`}
          className={({ isActive }) => (isActive ? styles.active : "")}
          replace={false}
        >
          Profile
        </NavLink>
      </div>
      <div>
        <NavLink
          to={`/${userDetailsToAddressesRoute}`}
          className={({ isActive }) => (isActive ? styles.active : "")}
          replace={false}
        >
          Addresses
        </NavLink>
      </div>
    </div>
  );
};
