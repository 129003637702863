import { SquareImage } from "~/components/UI/SquareImage";
import { MessageStatusEnum } from "~/types/data/Message.types";

import styles from "./index.module.scss";

interface Props {
  fileUrl: string;
  fileName: string;
  status?: MessageStatusEnum;
}

export const FileMessageBody = ({ fileUrl, status, fileName }: Props) => {
  const splitName = fileName.split("-");
  const shortenedName = splitName[splitName.length - 1];
  return (
    <div className={`${styles.container}`}>
      {status === MessageStatusEnum.SENDING ? (
        <div className={styles.sending}>sending</div>
      ) : status === MessageStatusEnum.FAILED ? (
        <div className={styles.failed}>failed</div>
      ) : (
        <div className={styles.fileMessage}>
          <div className={styles.imageContainer}>
            <SquareImage url="/assets/file-thumbnail.png" borderRadius="1rem" />
          </div>

          <a href={fileUrl} target="_blank" rel="noreferrer">
            {shortenedName}
          </a>
        </div>
      )}
    </div>
  );
};
