import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  GET_PAGINATED_IMPORTED_FILES,
  GetPaginatedImportedFilesInput,
  GetPaginatedImportedFilesResponse,
} from "~/api/graphql/importedFile";
import FullPageLoader from "~/components/UI/FullPageLoader";
import { ImportedFile } from "~/types/data/ImportedFile.type";

import { ImportedFileCard } from "./ImportedFileCard";
import styles from "./index.module.scss";

export const MesImportations = () => {
  const [importedFiles, setImportedFiles] = useState<ImportedFile[]>();
  const [hasMoreImportedFiles, setHasMoreReviews] = useState(false);
  const [cursor, setCursor] = useState<number>();
  const TAKE = 10;

  const [getImportedFilesTrigger, { error }] = useLazyQuery<
    GetPaginatedImportedFilesResponse,
    GetPaginatedImportedFilesInput
  >(GET_PAGINATED_IMPORTED_FILES);

  const fetchImportedFiles = async () => {
    const { data } = await getImportedFilesTrigger({
      variables: {
        GetPaginatedImportedFilesInput: {
          cursor,
          take: TAKE,
        },
      },
    });

    if (data) {
      const { hasMoreData, nextCursor, importedFiles } =
        data.getPaginatedImportedFiles;
      setImportedFiles((prev) =>
        prev ? [...prev, ...importedFiles] : importedFiles
      );
      setHasMoreReviews(hasMoreData);
      setCursor(nextCursor);
    }
  };

  useEffect(() => {
    setImportedFiles(undefined);
    fetchImportedFiles();
  }, []);

  return (
    <div className={styles.container}>
      {error ? (
        <div>Error</div>
      ) : !importedFiles ? (
        <FullPageLoader />
      ) : (
        <>
          <h2>Mes Importations</h2>
          <InfiniteScroll
            dataLength={importedFiles.length}
            next={() => {
              fetchImportedFiles();
            }}
            hasMore={hasMoreImportedFiles}
            loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
          >
            <div className={styles.importsListContainer}>
              {importedFiles.map((importedFile, index) => (
                <div className={styles.cardContainer} key={index}>
                  <ImportedFileCard importedFile={importedFile} />
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </>
      )}
    </div>
  );
};
