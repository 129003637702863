import { ApolloProvider } from "@apollo/client";
import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { apolloClient } from "./api/clients/apollo";
import { getCompanyInfo } from "./api/graphql/company/getCompanyInfo";
import { getUserInfo } from "./api/graphql/user/getUserInfo";
import { ConversationsWrapper } from "./components/UI/ConversationsWrapper";
import {
  graphqlGetCompanyFields,
  graphqlGetUserFields,
} from "./constants/graphql";
import {
  initBankReference,
  initCompanyCarrierState,
  initMyAccountState,
  initReedooCarrierState,
  initUBOState,
} from "./constants/states";
import {
  CompanyAccountContext,
  CompanyContextData,
} from "./context/companyAccountContext";
import { UserContext, UserContextData } from "./context/userContext";
import AppRoutes from "./navigation/AppRoutes";
import { persistor, store } from "./redux/store";
import { UboStatusEnum } from "./types/data/UBO.types";

function App() {
  const [user, setUser] = useState<UserContextData>();
  const [companyAccount, setCompanyAccount] = useState<CompanyContextData>({
    ...initMyAccountState,
  });

  const initUserInfo = async () => {
    const response = await getUserInfo(graphqlGetUserFields);
    if (response.success) {
      setUser({ ...response.data, connected: true });
    } else {
      setUser({ connected: false });
    }
  };

  const initCompanyInfo = async () => {
    const res = await getCompanyInfo(graphqlGetCompanyFields);
    if (res.success) {
      const ubos =
        res.data.uboDeclarationStatus === UboStatusEnum.REFUSED
          ? res.data.ubos.map((ubo) => {
              return { ...ubo, mangopayId: "" };
            })
          : res.data.ubos;

      setCompanyAccount({
        ...res.data,
        carriers: res.data.carriers.length
          ? res.data.carriers
          : [initCompanyCarrierState],
        reedooCarriers: res.data.reedooCarriers.length
          ? res.data.reedooCarriers
          : [initReedooCarrierState],
        ubos: ubos.length ? ubos : [initUBOState],
        bankReference: res.data.bankReference || initBankReference,
        kycDocuments: res.data.kycDocuments || [],
        connected: true,
      });
    }
  };

  useEffect(() => {
    initUserInfo();
  }, []);

  useEffect(() => {
    if (user?.connected) {
      initCompanyInfo();
    } else if (user?.connected === false) {
      setCompanyAccount({ ...initMyAccountState, connected: false });
    }
  }, [user?.connected]);

  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <PersistGate persistor={persistor} loading={<>Loading...</>}>
          <UserContext.Provider
            value={{ userState: user, setUserState: setUser }}
          >
            <CompanyAccountContext.Provider
              value={{
                companyAccount: companyAccount,
                setCompanyAccount: setCompanyAccount,
              }}
            >
              <ConversationsWrapper>
                <AppRoutes />
              </ConversationsWrapper>
            </CompanyAccountContext.Provider>
          </UserContext.Provider>
        </PersistGate>
      </ApolloProvider>
    </Provider>
  );
}

export default App;
