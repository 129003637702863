import { DeliveryPackage } from "./Carrier.types";
import { Conversation } from "./Conversation.types";
import { OrderHisory } from "./OrderHistory.types";
import { Product } from "./Product.types";
import { Profile } from "./Profile.types";
import { Transportation } from "./Transportation.types";

export enum DeliveryOptionEnum {
  COLISSIMO = "COLISSIMO",
  UPS = "UPS",
  CHRONOPOST = "CHRONOPOST",
  HAND_DELIVERY = "HAND_DELIVERY",
  CUSTOM = "CUSTOM",
}

export enum PaymentMethodEnum {
  DIRECT_PAYIN = "DIRECT_PAYIN",
  PAYPAL = "PAYPAL",
  APPLE = "APPLE",
  GOOGLE = "GOOGLE",
}

export enum RefundStatusEnum {
  NONE = "NONE",
  PARTIAL = "PARTIAL",
  TOTAL = "TOTAL",
}

export enum OrderStatusEnum {
  OPEN = "OPEN",
  WAITING_FOR_CONFIRMATION = "WAITING_FOR_CONFIRMATION",
  WAITING_FOR_EXPEDITION = "WAITING_FOR_EXPEDITION",
  WAITING_FOR_RECEPTION = "WAITING_FOR_RECEPTION",
  WAITING_FOR_VALIDATION = "WAITING_FOR_VALIDATION",
  CANCELLED = "CANCELLED",
  CLOSED = "CLOSED",
}

export enum ProductOrderStatusEnum {
  ON_HOLD = "ON_HOLD",
  ACCEPTED = "ACCEPTED",
  REFUSED = "REFUSED",
}

export enum OrderToProductStatusEnum {
  WAITING_FOR_CONFIRMATION = "WAITING_FOR_CONFIRMATION",
  WAITING_FOR_EXPEDITION = "WAITING_FOR_EXPEDITION",
  WAITING_FOR_RECEPTION = "WAITING_FOR_RECEPTION",
  RECEIVED = "RECEIVED",
  CANCEL = "CANCEL",
}

export enum OrderDeliveryOptionEnum {
  DELIVERY = "DELIVERY",
  PICKUP = "PICKUP",
  BY_HAND = "BY_HAND",
}

export enum IncidentStatusEnum {
  NONE = "NONE",
  OPEN = "OPEN",
  CLOSED_BY_SELLER = "CLOSED_BY_SELLER",
  CLOSED = "CLOSED",
}

export interface DeliveryAddress {
  id: number;
  pickupId?: string;
  pickupName?: string;
  title?: string;
  name: string;
  address1: string;
  address2?: string;
  zipCode: string;
  city: string;
  country: string;
  phoneNumber?: string;
  email?: string;
}

export interface Payment {
  id: number;
  paymentMethod: PaymentMethodEnum;
  externalId?: string;
  totalPrice: number;
  paidByWallet: number;
  refundStatus: RefundStatusEnum;
  refundedAmount: number;
}

export interface Order {
  id: number;
  createdAt: string;
  shippingDate: string;
  status: OrderStatusEnum;
  incidentStatus: IncidentStatusEnum;
  incidentReason?: string;

  totalPrice: number;
  finalTotalPrice: number;
  shippingPrice: number;
  refundStatus: RefundStatusEnum;
  refundReason?: string;
  refundAmount: number;
  refundShippingAmount: number;
  commissionAmount: number;

  usedReedooCarrier: boolean;
  withdrawalPoint?: string;
  shippingAddress?: DeliveryAddress;
  billingAddress?: DeliveryAddress;

  orderToProducts: OrderToProduct[];

  deliveryOption?: DeliveryPackage;

  transportations: Transportation[];

  payment?: Payment;

  conversation?: Conversation;
  rating?: unknown;

  managedByReedoo: boolean;

  buyer?: Profile;

  orderHistory: OrderHisory[];

  storeName?: string;
  sellerName?: string;

  professionalSellerId?: number;
}

export interface OrderToProduct {
  id: number;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  productStatus: ProductOrderStatusEnum;
  status: OrderToProductStatusEnum;
  product: Product;
  refundAmount: number;
  refundReason: string;
  commissionPercentage: number;
}

export interface OrderProductRefundInput {
  id: number;
  quantity: number;
  unitAmount: number;
  reason: string;
}

export interface RefundPartialOrderInput {
  orderId: number;
  shippingAmount: number;
  refundProducts: OrderProductRefundInput[];
}
