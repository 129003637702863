import { VendorCategory } from "~/types/data/VendorCategory.types";

import { getBestMatch } from "./matching";

export const getBestCategoryMatch = (
  categories: VendorCategory[],
  valueToGuess: string
) => {
  const categoryNames = categories
    .map((category) => [category.name, ...category.associatedNames])
    .reduce((a, b) => a.concat(b), []);
  const bestMatch = getBestMatch(valueToGuess, categoryNames);
  return categories.find((category) => {
    if (
      category.name === bestMatch ||
      category.associatedNames.includes(bestMatch ?? "")
    ) {
      return true;
    }
  });
};
