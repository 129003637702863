import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  createMessage,
  CreateMessageInput,
  CreateMessageResponse,
  markMessagesAsRead,
  MarkMessagesAsReadInput,
} from "~/api/graphql/conversation";
import { getMediaUploadPath } from "~/api/graphql/media/getMediaUploadPath";
import CustomModal from "~/components/UI/CustomModal";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import { getProfileName } from "~/pages/MesCommandes/DetailsDuneCommande/util/getProfileName";
import { useAppSelector } from "~/redux/hooks";
import {
  addSendingMessage,
  markRead,
  removeSendingMessage,
  selectConversationsState,
  updateConversationsState,
} from "~/redux/slice/conversations.slice";
import { Conversation } from "~/types/data/Conversation.types";
import {
  Message,
  MessageStatusEnum,
  MessageTypeEnum,
} from "~/types/data/Message.types";
import { IncidentStatusEnum, Order } from "~/types/data/Order.types";

import { ChatPreviewModal } from "./ChatPreviewModal";
import { ConversationBody } from "./ConversationBody";
import { ConversationHeader } from "./ConversationHeader";
import styles from "./index.module.scss";
import { MessageBar } from "./MessageBar";

interface Props {
  order?: Order;
  conversation: Conversation;
  showCloseIncidentModalHandler?: () => void;
}

export type UploadMediaType = "picture" | "file";

export type UploadMediaModalType = {
  file?: File;
  type?: UploadMediaType;
  error?: string;
  isLoading?: boolean;
};

export const ConversationContainer = ({
  conversation,
  order,
  showCloseIncidentModalHandler,
}: Props) => {
  const [modalState, setModalState] = useState<UploadMediaModalType>({});
  const [failedMessages, setFailedMessages] = useState<Message[]>([]);

  const dispatch = useDispatch();

  const { conversations } = useAppSelector(selectConversationsState);

  const sortedMessages = [...conversation.messages, ...failedMessages].sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );

  const sortedConversation: Conversation = {
    ...conversation,
    messages: sortedMessages,
  };

  const sendingMessageHandler = (message: Message) => {
    dispatch(
      addSendingMessage({
        conversationId: sortedConversation.id,
        message: { ...message, status: MessageStatusEnum.SENDING },
      })
    );
  };

  const sendMessageSuccessHandler = (responseData: CreateMessageResponse) => {
    const { createMessage: conversationResponse } = responseData;
    const modifiedConversations = conversations.map((conversation) =>
      conversation.id === conversationResponse.id
        ? conversationResponse
        : conversation
    );
    modifiedConversations.sort(
      (a, b) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    );
    dispatch(
      updateConversationsState({
        conversations: modifiedConversations,
      })
    );
  };

  const addFailedMessageHandler = (message: Message) => {
    const failedMessage: Message = {
      ...message,
      status: MessageStatusEnum.FAILED,
    };

    setFailedMessages((prevFailedMessages) => [
      ...prevFailedMessages,
      failedMessage,
    ]);
  };

  const sendMessageErrorHandler = () => {
    dispatch(
      removeSendingMessage({
        conversationId: sortedConversation.id,
        addFailedMessageHandler,
      })
    );
  };

  const { trigger: sendMessageTrigger } = useMutationWithCallbacks<
    CreateMessageResponse,
    CreateMessageInput
  >(createMessage, sendMessageSuccessHandler, sendMessageErrorHandler);

  const sendMessageHandler = async (message: Message) => {
    sendingMessageHandler(message);

    const { type, filePath, picturePath, text } = message;

    await sendMessageTrigger({
      variables: {
        CreateMessageInput: {
          conversationId: sortedConversation.id,
          type,
          filePath,
          picturePath,
          text,
        },
      },
    });
  };

  const { trigger: markMessagesAsReadTrigger } = useMutationWithCallbacks<
    undefined,
    MarkMessagesAsReadInput
  >(
    markMessagesAsRead,
    () => {},
    () => {}
  );

  const sendTextMessageHandler = (textMessage: string) => {
    const newMessage: Message = {
      recipientId: sortedConversation.buyerId,
      senderId: sortedConversation.professionalSellerId,
      type: MessageTypeEnum.TEXT,
      text: textMessage,
      createdAt: new Date().toISOString(),
    };

    sendMessageHandler(newMessage);
  };

  const filePickerHandler = (file: File, type: UploadMediaType) => {
    setModalState({ file, type });
  };

  const fileSubmitHandler = async () => {
    setModalState((prev) => ({ ...prev, error: "", isLoading: true }));
    const acceptedFormats =
      modalState.type === "picture" ? ["png", "jpg", "jpeg"] : [];
    const extension = modalState.file?.name
      .split(".")
      .slice(-1)[0]
      .toLowerCase();
    const error =
      acceptedFormats.length && !acceptedFormats.includes(extension || "")
        ? "Images must be of types [png, jpg, jpeg]"
        : undefined;

    if (error) {
      setModalState((prev) => ({ ...prev, error, isLoading: false }));
      return;
    }

    if (modalState.file && modalState.type) {
      const response = await getMediaUploadPath({
        entity: "chat",
        field: modalState.type,
        filename: modalState.file.name,
        isPublic: false,
      });
      if (response.success) {
        const { url, path } = response.data;

        try {
          await fetch(url, { method: "put", body: modalState.file });
        } catch (err) {
          setModalState((prev) => ({
            ...prev,
            error: "Error in uploading file",
            isLoading: false,
          }));

          return;
        }

        const newMessage: Message = {
          recipientId: sortedConversation.buyerId,
          senderId: sortedConversation.professionalSellerId,
          type:
            modalState.type === "file"
              ? MessageTypeEnum.FILE
              : MessageTypeEnum.PICTURE,
          picturePath: modalState.type === "picture" ? path : undefined,
          filePath: modalState.type === "file" ? path : undefined,
          createdAt: new Date().toISOString(),
        };

        await sendMessageHandler(newMessage);
      } else {
        setModalState((prev) => ({
          ...prev,
          error: "Error in getMediaUploadPath",
          isLoading: false,
        }));
      }
    }

    setModalState({});
  };

  const hideModalHandler = () => {
    setModalState({});
  };

  const buyerName = getProfileName(
    order?.buyer?.firstName,
    order?.buyer?.lastName
  );

  useEffect(() => {
    if (
      conversation.messages[conversation.messages.length - 1]?.senderId !==
      conversation.professionalSellerId
    ) {
      dispatch(markRead({ conversationId: conversation.id }));
      markMessagesAsReadTrigger({
        variables: {
          MarkMessagesAsReadInput: { conversationId: conversation.id },
        },
      });
    }
  }, [conversation.messages.length]);

  return (
    <div className={styles.container}>
      <CustomModal
        show={!!modalState.file}
        onCancel={hideModalHandler}
        disableCancel={modalState.isLoading}
      >
        <ChatPreviewModal
          modalState={modalState}
          submitHandler={() => {
            fileSubmitHandler();
          }}
        />
      </CustomModal>
      <div className={styles.headerContainer}>
        <ConversationHeader
          buyerName={buyerName}
          incidentStatus={order?.incidentStatus ?? IncidentStatusEnum.NONE}
          orderId={order?.id ?? 0}
          showCloseIncidentModalHandler={showCloseIncidentModalHandler}
          profilePicUrl={order?.buyer?.profilePicUrl ?? ""}
        />
      </div>
      <div className={styles.bodyContainer}>
        <ConversationBody
          messages={sortedConversation.messages}
          professionalSellerId={sortedConversation.professionalSellerId}
        />
      </div>
      <div className={styles.barContainer}>
        <MessageBar
          sendTextMessageHandler={sendTextMessageHandler}
          filePickerHandler={(file: File, type: UploadMediaType) => {
            filePickerHandler(file, type);
          }}
        />
      </div>
    </div>
  );
};
