import { Dispatch } from "react";

/**
 * onInputChange is a helper function that is used to update a single field in a
 * form state object. It is useful for reducing the amount of boilerplate code
 * needed to update a form state object.
 *
 * @param fieldName - The name of the field to update in the form state object.
 * @param value - The new value to assign to the field.
 * @param setState - A function to set the form state.
 */

export const onInputChange = <T extends object, K extends keyof T = keyof T>(
  fieldName: K,
  value: T[K],
  setState: Dispatch<React.SetStateAction<T>>
) => {
  setState((prev) => ({ ...prev, [fieldName]: value }));
};
