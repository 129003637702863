import React from "react";

import styles from "./index.module.scss";
interface Props {
  width: string;
  height: string;
  imagePath: string;
  onClick?: (event: React.MouseEvent) => void;
}

const SVGContainer = ({ width, height, imagePath, onClick }: Props) => {
  return (
    <div
      className={`${styles.container}`}
      style={{ width: width, height: height }}
    >
      <img
        src={imagePath}
        onClick={onClick}
        className={onClick ? styles.clickable : ""}
      />
    </div>
  );
};

export default SVGContainer;
