import { RentOrder } from "~/types/data/RentOrder.type";
import { formatPrice } from "~/util/functions/formatDecimal";

import styles from "./index.module.scss";

interface Props {
  rentOrder: RentOrder;
}

const OrderTotalCard = ({ rentOrder }: Props) => {
  const {
    totalPaidRecurringAmount,
    totalPurchasedAmount,
    orderToProducts,
    totalPaidAmount,
  } = rentOrder;

  const totalDamageFees = orderToProducts?.reduce((acc, { singleProducts }) => {
    const damageFees = singleProducts?.reduce(
      (acc, { damageFees }) => acc + damageFees,
      0
    );

    return acc + damageFees;
  }, 0);

  const formattedTotalRecurringBilledAmount = formatPrice(
    totalPaidRecurringAmount
  );
  const formattedTotalPurchasedAmount = formatPrice(totalPurchasedAmount);
  const formattedDeliveryPrice = formatPrice(0);
  const formattedRefundAmount = formatPrice(0);
  const formattedTotalDamageFees = formatPrice(totalDamageFees);
  const formattedTotalPaidAmount = formatPrice(totalPaidAmount);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.leftColumn}`}></div>
      <div className={`${styles.rightColumn}`}>
        <div>
          <p className={`${styles.boldText}`}>Montant loué :</p>
          <p>{formattedTotalRecurringBilledAmount}</p>
        </div>
        <div>
          <p className={`${styles.boldText}`}>Montant acheté :</p>
          <p>{formattedTotalPurchasedAmount}</p>
        </div>
        <div>
          <p className={`${styles.boldText}`}>Montant frais de port :</p>
          <p>{formattedDeliveryPrice}</p>
        </div>
        <div>
          <p className={`${styles.boldText}`}>Montant remboursé :</p>
          <p>{formattedRefundAmount}</p>
        </div>
        <div>
          <p className={`${styles.boldText}`}>Montant prélevé :</p>
          <p>{formattedTotalDamageFees}</p>
        </div>
        <div>
          <p className={`${styles.boldText}`}>Montant total :</p>
          <p>{formattedTotalPaidAmount}</p>
        </div>
      </div>
    </div>
  );
};

export default OrderTotalCard;
