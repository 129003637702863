import { ApolloError, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getCarriers, GetCarriersResponse } from "~/api/graphql/carrier";
import {
  companyCloseIncident,
  CompanyCloseIncidentInput,
  CompanyCloseIncidentResponse,
  getOrderById,
  GetOrderByIdInput,
  GetOrderByIdResponse,
  refundPartialOrder,
  RefundPartialOrderApiInput,
  RefundPartialOrderResponse,
  refundTotalOrder,
  RefundTotalOrderInput,
  RefundTotalOrderResponse,
} from "~/api/graphql/order";
import {
  getSelectReasons,
  GetSelectReasonsResponse,
} from "~/api/graphql/selectReason";
import { CustomButton } from "~/components/form/CustomButton";
import { OptionType } from "~/components/form/SelectInput";
import CustomModal from "~/components/UI/CustomModal";
import FullPageLoader from "~/components/UI/FullPageLoader";
import SVGContainer from "~/components/UI/SVGContainer";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useUserContext } from "~/context/userContext";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import { selectConversationsState } from "~/redux/slice/conversations.slice";
import {
  Order,
  OrderStatusEnum,
  RefundPartialOrderInput,
} from "~/types/data/Order.types";
import { SelectReasonTypeEnum } from "~/types/data/SelectReason.types";
import { RoleEnum } from "~/types/data/User.types";
import { formatDate, formatHour } from "~/util/functions/formatDate";

import { CloseIncidentForm } from "./CloseIncidentForm";
import Details from "./Details";
import { Expidition } from "./Expidition";
import { Historiques } from "./Historiques";
import styles from "./index.module.scss";
import { Messages } from "./Messages";
import OrderPageNavigation from "./OrderPageNavigation";
import { RefundForm } from "./RefundForm";
import { validatePartialRefund } from "./util/validatePartialRefund";

export type RefundMode = "default" | "full" | "detailed";

interface Props {
  page: "details" | "delivery" | "messages" | "history";
}

export interface RefundModalState {
  show: boolean;
  mode: RefundMode;
  isLoading: boolean;
  errorMessage?: string;
}

export interface CloseIncidentModalState {
  show: boolean;
  isLoading: boolean;
  errorMessage?: string;
}

export const DetailsDuneCommande = ({ page }: Props) => {
  const [order, SetOrder] = useState<Order>();
  const [refundModalState, setRefundModalState] = useState<RefundModalState>({
    mode: "default",
    show: false,
    isLoading: false,
  });
  const [closeIncidentModalState, setCloseIncidentModalState] =
    useState<CloseIncidentModalState>({ isLoading: false, show: false });

  // hooks
  const { userState } = useUserContext();
  const { companyAccount } = useCompanyAccountContext();
  const { conversations } = useSelector(selectConversationsState);

  const conversation = conversations.find(
    ({ order: conversationOrder }) => conversationOrder?.id === order?.id
  );

  const { data: carriersData, error: carriersError } =
    useQuery<GetCarriersResponse>(getCarriers, {
      fetchPolicy: "cache-first",
    });

  const { data: selectReasons } = useQuery<GetSelectReasonsResponse>(
    getSelectReasons,
    {
      fetchPolicy: "cache-first",
    }
  );

  const params = useParams();
  const orderId = params.id || "";

  const readOnly =
    (!!order?.managedByReedoo &&
      !!userState?.connected &&
      userState?.role !== RoleEnum.ADMIN) ||
    (!order?.managedByReedoo &&
      !!userState?.connected &&
      userState?.role === RoleEnum.ADMIN &&
      order?.professionalSellerId !== companyAccount.id);

  const closeIncidentReasonOptions: OptionType[] = selectReasons
    ? selectReasons.getSelectReasons
        .filter(({ type }) => type === SelectReasonTypeEnum.CLOSE_INCIDENT)
        .map(({ reason }) => ({ label: reason, value: reason }))
    : [];

  const refundReasonOptions: OptionType[] = selectReasons
    ? selectReasons.getSelectReasons
        .filter(({ type }) => type === SelectReasonTypeEnum.REFUND)
        .map(({ reason }) => ({ label: reason, value: reason }))
    : [];

  const refundTotalOrderSuccessHandler = (
    responseData: RefundTotalOrderResponse
  ) => {
    SetOrder(responseData.refundTotalOrder);
    setRefundModalState({ isLoading: false, show: false, mode: "default" });
  };

  const refundTotalOrderErrorHandler = (error: ApolloError) => {
    const { message } = error;
    setRefundModalState((prev) => {
      return { ...prev, errorMessage: message, isLoading: false };
    });
  };

  const { trigger: refundTotalOrderTrigger } = useMutationWithCallbacks<
    RefundTotalOrderResponse,
    RefundTotalOrderInput
  >(
    refundTotalOrder,
    refundTotalOrderSuccessHandler,
    refundTotalOrderErrorHandler
  );

  const refundTotalOrderHandler = async (refundReason: string) => {
    if (!refundReason) {
      setRefundModalState((prev) => {
        return {
          ...prev,
          isLoading: false,
          errorMessage: "Invalid motif de remboursement",
        };
      });
      return;
    }
    setRefundModalState((prev) => {
      return { ...prev, isLoading: true, errorMessage: "" };
    });

    await refundTotalOrderTrigger({
      variables: {
        RefundTotalOrderInput: {
          orderId: +orderId,
          refundReason,
        },
      },
    });
  };

  const refundPartialOrderSuccessHandler = (
    responseData: RefundPartialOrderResponse
  ) => {
    SetOrder(responseData.refundPartialOrder);
    setRefundModalState({ isLoading: false, show: false, mode: "default" });
  };

  const refundPartialOrderErrorHandler = (error: ApolloError) => {
    const { message } = error;
    setRefundModalState((prev) => {
      return { ...prev, errorMessage: message, isLoading: false };
    });
  };

  const { trigger: refundPartialOrderTrigger } = useMutationWithCallbacks<
    RefundPartialOrderResponse,
    RefundPartialOrderApiInput
  >(
    refundPartialOrder,
    refundPartialOrderSuccessHandler,
    refundPartialOrderErrorHandler
  );

  const refundPartialOrderHandler = async (
    refundPartialOrderInput: RefundPartialOrderInput
  ) => {
    // validate
    const errorMessage = validatePartialRefund(refundPartialOrderInput, order);

    if (errorMessage) {
      setRefundModalState((prev) => {
        return {
          ...prev,
          isLoading: false,
          errorMessage,
        };
      });
      return;
    }

    setRefundModalState((prev) => {
      return { ...prev, isLoading: true, errorMessage: "" };
    });

    await refundPartialOrderTrigger({
      variables: {
        RefundPartialOrderInput: refundPartialOrderInput,
      },
    });
  };

  const {
    data: orderData,
    error: orderError,
    loading: orderDataLoading,
  } = useQuery<GetOrderByIdResponse, GetOrderByIdInput>(getOrderById, {
    variables: { GetOrderByIdInput: { id: +orderId } },
  });

  const companyCloseIncidentSuccessHandler = (
    responseData: CompanyCloseIncidentResponse
  ) => {
    SetOrder(responseData.companyCloseIncident);
    setCloseIncidentModalState({ isLoading: false, show: false });
  };

  const companyCloseIncidentErrorHandler = (error: ApolloError) => {
    setCloseIncidentModalState({
      isLoading: false,
      show: true,
      errorMessage: error.message,
    });
  };

  const { trigger: companyCloseIncidentTrigger } = useMutationWithCallbacks<
    CompanyCloseIncidentResponse,
    CompanyCloseIncidentInput
  >(
    companyCloseIncident,
    companyCloseIncidentSuccessHandler,
    companyCloseIncidentErrorHandler
  );

  const showRefundModalHandler = () => {
    setRefundModalState({ mode: "default", show: true, isLoading: false });
  };

  const hideRefundModalHandler = () => {
    setRefundModalState({ mode: "default", show: false, isLoading: false });
  };

  const changeModalModeHandler = (mode: RefundMode) => {
    setRefundModalState({ mode: mode, show: true, isLoading: false });
  };

  const showCloseIncidentModalHandler = () => {
    setCloseIncidentModalState({ isLoading: false, show: true });
  };

  const hideCloseIncidentModalHandler = () => {
    setCloseIncidentModalState({ isLoading: false, show: false });
  };

  const confirmCloseIncidentHandler = async (reason: string) => {
    setCloseIncidentModalState({ isLoading: true, show: true });
    await companyCloseIncidentTrigger({
      variables: { CompanyCloseIncidentInput: { orderId: +orderId, reason } },
    });
  };

  const dataNotFound = !orderDataLoading && !orderData?.getOrderById;

  const updateOrderHandler = (order: Order) => {
    SetOrder(order);
  };

  useEffect(() => {
    if (orderData) {
      SetOrder(orderData.getOrderById);
    }
  }, [orderData]);

  const hasMessages = !!conversation;
  const unreadMessagesCount =
    conversation?.messages.filter(
      ({ read, recipientId }) =>
        !read && conversation?.professionalSellerId === recipientId
    ).length || 0;

  return (
    <div className={`${styles.container}`}>
      {carriersError ? (
        <div>Carriers Error: {carriersError.message}</div>
      ) : dataNotFound ? (
        <div>Order of ID {orderId} does not exist in the database</div>
      ) : orderError ? (
        <div>Order Error: ${orderError.message}</div>
      ) : orderDataLoading || !carriersData ? (
        <FullPageLoader />
      ) : (
        !!order && (
          <>
            {!readOnly && (
              <>
                <CustomModal
                  show={refundModalState.show}
                  onCancel={hideRefundModalHandler}
                  disableScroll={true}
                  disableCancel={refundModalState.isLoading}
                >
                  <RefundForm
                    state={refundModalState}
                    onChangeMode={changeModalModeHandler}
                    refundReasonOptions={refundReasonOptions}
                    refundTotalOrderHandler={(refundReason: string) => {
                      refundTotalOrderHandler(refundReason);
                    }}
                    refundPartialOrderHandler={(
                      refundPartialOrderInput: RefundPartialOrderInput
                    ) => {
                      refundPartialOrderHandler(refundPartialOrderInput);
                    }}
                    order={order}
                  />
                </CustomModal>
                <CustomModal
                  onCancel={hideCloseIncidentModalHandler}
                  show={closeIncidentModalState.show}
                  disableScroll={true}
                  disableCancel={closeIncidentModalState.isLoading}
                >
                  <CloseIncidentForm
                    onConfirm={(reason: string) => {
                      confirmCloseIncidentHandler(reason);
                    }}
                    closeIncidentReasonOptions={closeIncidentReasonOptions}
                    state={closeIncidentModalState}
                  />
                </CustomModal>
              </>
            )}
            <div className={`${styles.header}`}>
              <div className={`${styles.leftColumn}`}>
                <h2 className={styles.title}>Commande N°{orderId}</h2>
                <p>{`${formatDate(order.createdAt)} à ${formatHour(
                  order.createdAt
                )}`}</p>
                {order.status !== OrderStatusEnum.CLOSED &&
                  order.status !== OrderStatusEnum.CANCELLED &&
                  order.status !== OrderStatusEnum.WAITING_FOR_CONFIRMATION &&
                  !readOnly && (
                    <CustomButton
                      width="fit-content"
                      backgroundColor="#CECECE"
                      height="fit-content"
                      padding="0.5rem"
                      borderRadius="5px"
                      onClick={showRefundModalHandler}
                    >
                      <div className={`${styles.buttonContent}`}>
                        <SVGContainer
                          height="14px"
                          width="14px"
                          imagePath="/assets/refresh-icon.svg"
                        />
                        Remboursement
                      </div>
                    </CustomButton>
                  )}
              </div>
              <div>
                <OrderPageNavigation
                  orderId={orderId}
                  hasMessages={hasMessages}
                  unreadMessagesCount={unreadMessagesCount}
                />
              </div>
            </div>
            {page === "details" ? (
              <Details
                order={order}
                updateOrderHandler={updateOrderHandler}
                showCloseIncidentModalHandler={showCloseIncidentModalHandler}
                readOnly={readOnly}
              />
            ) : page === "delivery" ? (
              <Expidition
                order={order}
                carriers={carriersData.getCarriers}
                updateOrderHandler={updateOrderHandler}
                readOnly={readOnly}
              />
            ) : page === "messages" && conversation ? (
              <Messages
                order={order}
                showCloseIncidentModalHandler={showCloseIncidentModalHandler}
              />
            ) : (
              <Historiques order={order} />
            )}
          </>
        )
      )}
    </div>
  );
};
