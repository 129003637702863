import { DownloadInput } from "../../../components/form/DownloadInput";
import { SizedBox } from "../../../components/UI/SizedBox";
import styles from "./index.module.scss";
export const Cgu = () => {
  return (
    <>
      <SizedBox height="0.8rem" />
      <div className={styles.mainContainer}>
        <DownloadInput
          header="CGU FR"
          text="Lorem ipsum dolor"
          width={"50%"}
          filePath={""}
        />
        <DownloadInput
          header="CGU EN"
          text="Lorem ipsum dolor"
          width={"50%"}
          filePath={""}
        />
      </div>
    </>
  );
};
