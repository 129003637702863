import { Product } from "~/types/data/Product.types";

export const fieldToNameMap: { [key in keyof Product]: string } = {
  title: "Titre",
  categoryId: "Catégories",
  status: "Statut",
  image1: "Photographies",
  video: "Vidéo",
  description: "Description",
  productCareInstructions: "Conseil d'entretien",
  sku: "SKU",
  ean: "EAN",
  vendorParentId: "Références produit",
  composition: "Composition",
  compositionLining: "Composition doublure",
  minimalAge: "Âge minimal",
  weight: "Poids",
  depth: "Profondeur",
  length: "Longueur",
  height: "Hauteur",
  volume: "Volume",
  diameter: "Diamètre",
  gender: "Genre",
  brand: "Marque",
  stateType: "Etat du produit",
  logisticClass: "Classe logistique",
  color: "Couleur",
  liningMaterial: "Matière principale doublure",
  mainMaterial: "Matière principale",
  size: "Taille",
  ecoResponsibleLabel: "Label eco-responsable",
  madeIn: "Pays de fabrication",
  quantity: "Quantité en stock",
  sendAlertOnQuantity: "Alerte quantité",
  priceTTC: "Prix de vente (TTC)",
  collectionName: "Nom de la collection",
  discountedPriceTTC: "Prix remisé",
  startingDiscountDate: "Remisé à partir de",
  endingDiscountDate: "Remisé jusqu'au",
  vatRate: "TVA",
  ecoTax: "Eco-Tax",
  sellingType: "Type de vente",
  depositPrice: "Montant du dépôt",
  dailyRentPrice: "Prix de location (Jour)",
  weeklyRentPrice: "Prix de location (Semaine)",
  monthlyRentPrice: "Prix de location Prix de location (Mois)",
  rentSecondHandPrice: "Prix d'achat",
  rentingPeriod: "Fréquence",
};
