import { MessageStatusEnum } from "~/types/data/Message.types";

import styles from "./index.module.scss";

interface Props {
  messageText: string;
  status?: MessageStatusEnum;
}

export const TextMessageBody = ({
  messageText,
  status = MessageStatusEnum.RECIEVED,
}: Props) => {
  return (
    <div
      className={`${styles.container} ${
        status === MessageStatusEnum.SENDING
          ? styles.sending
          : status === MessageStatusEnum.FAILED
          ? styles.failed
          : ""
      }`}
      dangerouslySetInnerHTML={{ __html: messageText.replace(/\n/g, "<br>") }}
    />
  );
};
