import AuthForm from "~/components/auth/AuthForm";
import Display from "~/components/UI/Display";

import styles from "./index.module.scss";

const Login = () => {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.authenticationForm}`}>
        <AuthForm mode="login" />
      </div>
      <div className={`${styles.display}`}>
        <Display />
      </div>
    </div>
  );
};

export default Login;
