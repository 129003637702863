export enum CollectionImportTypeEnum {
  COLLECTION_PRODUCTS = "COLLECTION_PRODUCTS",
}

export enum SubscriptionImportStatusEnum {
  IN_PROGRESS = "IN_PROGRESS",
  ERROR = "ERROR",
  DONE = "DONE",
}

export interface CollectionImport {
  id: number;
  createdAt: string;
  filePath: string;
  type: CollectionImportTypeEnum;
  status: SubscriptionImportStatusEnum;
  errorMessage: string;
  totalEntries: number;
  successfullEntries: number;
}
