import { BankReference } from "./BankReference.types";
import { BrandCompany } from "./BrandCompany.type";
import { CompanyCarrier, ReedooCarrier } from "./Carrier.types";
import { Conversation } from "./Conversation.types";
import { KYC } from "./KYC.types";
import { SecondHandCompany } from "./SecondHandCompany.type";
import { UBO, UboStatusEnum } from "./UBO.types";
import { User } from "./User.types";

export enum SocialReasonEnum {
  SAS = "SAS",
  SARL = "SARL",
  SA = "SA",
  SASU = "SASU",
  EURL = "EURL",
  SNC = "SNC",
  GIE = "GIE",
  GAEC = "GAEC",
  FREELANCE = "FREELANCE",
  EIRL = "EIRL",
  ASSOCIATION = "ASSOCIATION",
  BUSINESS = "BUSINESS",
  SOLETRADER = "SOLETRADER",
}

export enum KycLevelEnum {
  REGULAR = "REGULAR",
  LIGHT = "LIGHT",
}

export interface Company {
  id: number;
  createdAt?: string;
  mangopayId?: string;
  mangopayWalletId?: string;
  uboDeclarationId?: string;
  uboDeclarationStatus?: UboStatusEnum;
  storeName: string;
  companyDescription: string;
  logo: string;
  logoFile?: File;
  logoFullPath?: string;
  shippingCountry?: string;
  returnPolicyDescription: string;
  companyName: string;
  socialReason: SocialReasonEnum | undefined;
  siret: string;
  intercomTVA: string;
  companyAddress: string;
  companyZipCode: string;
  companyCity: string;
  companyPhoneNumber: string;
  companyPhoneNumber2: string;
  website: string;
  email: string;
  country: string;
  legalRepLastName: string;
  legalRepFirstName: string;
  legalRepEmail: string;
  legalRepAddress: string;
  legalRepZipCode: string;
  legalRepCity: string;
  legalRepCountry: string;
  legalRepBirthday: string;
  apiToken?: string;

  kycLevel?: KycLevelEnum;

  ubos: UBO[];
  kycDocuments: KYC[];
  bankReference: BankReference | null;
  carriers: CompanyCarrier[];
  reedooCarriers: ReedooCarrier[];
  users: User[];
  conversations: Conversation[];
  brandCompany?: BrandCompany;
  secondHandCompany?: SecondHandCompany;
  reviewsAverage?: number;
  productsCount?: number;
  ordersCount?: number;
  totalRevenue?: number;
}

export interface UpdateCompanyIdentificationInput {
  id: number;
  storeName: string;
  companyDescription: string;
  logo: string;
  shippingCountry: string;
  returnPolicyDescription: string;
}

export interface UpdateCompanyDetailsInput {
  id: number;
  companyName: string;
  mangopayId: string;
  uboDeclarationId: string;
  socialReason: SocialReasonEnum | undefined;
  siret: string;
  intercomTVA: string;
  companyAddress: string;
  companyZipCode: string;
  companyCity: string;
  companyPhoneNumber: string;
  companyPhoneNumber2: string;
  website: string;
  email: string;
  country: string;

  legalRepLastName: string;
  legalRepFirstName: string;
  legalRepEmail: string;
  legalRepAddress: string;
  legalRepZipCode: string;
  legalRepCity: string;
  legalRepCountry: string;
  legalRepBirthday: string;
}

export interface UpdateCompanyUboDeclarationInput {
  id: number;
  ubos: UBO[];
}

export interface UpdateCompanyKYCDocumentsInput {
  id: number;
  kycDocuments: KYC[];
}

export interface UpdateCompanyBankReferenceInput {
  id: number;
  bankReference: BankReference;
}

export interface UpdateCompanyDeliveryInput {
  companyId: number;
  companyCarriers: CompanyCarrier[];
}

export interface UpdateReedooDeliveryInput {
  companyId: number;
  reedooCarriers: ReedooCarrier[];
}

export interface CompanyFormState {
  id?: number;

  storeName?: string;
  companyName?: string;

  logo?: string;
  logoFile?: File;
  logoFullPath?: string;

  mangopayId?: string;
  mangopayWalletId?: string;

  companyAddress?: string;
  country?: string;
  companyZipCode?: string;
  companyCity?: string;
  companyPhoneNumber?: string;
  companyPhoneNumber2?: string;
  website?: string;
  email?: string;

  legalRepLastName?: string;
  legalRepFirstName?: string;
  legalRepEmail?: string;
  legalRepAddress?: string;
  legalRepZipCode?: string;
  legalRepCity?: string;
  legalRepCountry?: string;
  legalRepBirthday?: string;

  siret?: string;
  intercomTVA?: string;

  socialReason?: SocialReasonEnum;
  shippingCountry?: string;

  uboDeclarationId?: string;
  uboDeclarationStatus?: UboStatusEnum;
}
