import { useEffect, useState } from "react";
import { SingleValue } from "react-select";

import CustomCheckbox from "~/components/form/CustomCheckbox";
import SelectInput, { OptionType } from "~/components/form/SelectInput";
import SVGContainer from "~/components/UI/SVGContainer";
import { colors } from "~/constants/styles";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { Carrier } from "~/types/data/Carrier.types";
import { DeliveryMethodEnum, ReedooCarrier } from "~/types/data/Carrier.types";

import { DeliveryMethodSection } from "../DeliveryMethodSection";
import { deletedReedooCarrier } from "../util/deleteReedooCarrier";
import styles from "./index.module.scss";

interface Props {
  index: number;
  reedooCarriers: ReedooCarrier[];
  carriers: Carrier[];
}

interface DeliveryMethodsChecklist {
  delivery: boolean;
  pickup: boolean;
}

export const ReedooCarrierSection = ({
  index,
  reedooCarriers,
  carriers,
}: Props) => {
  const { companyAccount, setCompanyAccount } = useCompanyAccountContext();

  const reedooCarrier = companyAccount.reedooCarriers[index];

  const carriersOptions: OptionType[] = reedooCarriers
    .map(({ carrierId }) => {
      const carrierObject = carriers.find(({ id }) => id === carrierId);
      return {
        label: carrierObject?.name || "",
        value: carrierObject?.id.toString() || "",
      };
    })
    .filter(({ value }) => {
      {
        const index = companyAccount.reedooCarriers
          .map(({ carrierId }) => carrierId)
          ?.findIndex((listValue) => value === listValue.toString());
        return (
          index < 0 || (value && value === reedooCarrier.carrierId.toString())
        );
      }
    });

  const initDeliveryMethodsChecklist: DeliveryMethodsChecklist = {
    delivery: !!reedooCarrier.deliveryPackages.find((deliveryPackage) => {
      return deliveryPackage.deliveryMethod === DeliveryMethodEnum.DELIVERY;
    }),
    pickup: !!reedooCarrier.deliveryPackages.find((deliveryPackage) => {
      return deliveryPackage.deliveryMethod === DeliveryMethodEnum.PICKUP;
    }),
  };

  const [deliveryMethodsChecklist, setDeliveryMethodsChecklist] =
    useState<DeliveryMethodsChecklist>(initDeliveryMethodsChecklist);

  const selectChangeHandler = (change: number, inputIndex: number) => {
    setCompanyAccount((state) => {
      const modifiedReedooCarriers = [...state.reedooCarriers].map(
        (reedoCarrier, arrayIndex) => {
          if (arrayIndex === inputIndex) {
            const reedooCarrier = reedooCarriers.find(
              ({ carrierId }) => carrierId === change
            );
            const deliveryPackages = reedooCarrier?.deliveryPackages || [];
            return {
              ...reedoCarrier,
              carrierId: change,
              id: reedooCarrier?.id || 0,
              deliveryPackages: [...deliveryPackages],
            };
          } else {
            return { ...reedoCarrier };
          }
        }
      );

      return { ...state, reedooCarriers: modifiedReedooCarriers };
    });
  };

  const deleteCarrierHandler = () => {
    const { updatedReedooCarriers } = deletedReedooCarrier(
      companyAccount.reedooCarriers,
      index
    );

    setCompanyAccount((prev) => {
      return { ...prev, reedooCarriers: updatedReedooCarriers };
    });
  };

  useEffect(() => {
    const deliveryMethodsChecklist: DeliveryMethodsChecklist = {
      delivery: !!reedooCarrier.deliveryPackages.find((deliveryPackage) => {
        return deliveryPackage.deliveryMethod === DeliveryMethodEnum.DELIVERY;
      }),
      pickup: !!reedooCarrier.deliveryPackages.find((deliveryPackage) => {
        return deliveryPackage.deliveryMethod === DeliveryMethodEnum.PICKUP;
      }),
    };
    setDeliveryMethodsChecklist(deliveryMethodsChecklist);
  }, [companyAccount.reedooCarriers]);

  return (
    <div className={`${styles.container}`}>
      {index !== 0 && <hr />}
      <div>
        <div className={`${styles.carrierInfoSection}`}>
          <div className={`${styles.selectInputContainer}`}>
            <div className="doubleInputs">
              <SelectInput
                label="Choisir un transporteur :"
                fontSize="14px"
                backgroundColor={colors.$inputGray}
                noBorder={true}
                options={carriersOptions}
                onChange={(option: SingleValue<OptionType>) => {
                  selectChangeHandler(option ? +option.value : 0, index);
                }}
                value={reedooCarrier.carrierId.toString() || ""}
                defaultLabel="Not selected"
                hasDefault={true}
              />
              <div style={{ width: "100%" }} />
            </div>
          </div>
          {companyAccount.reedooCarriers.length > 1 && (
            <SVGContainer
              height="16px"
              width="16px"
              imagePath="/assets/circular-delete-button.svg"
              onClick={deleteCarrierHandler}
            />
          )}
        </div>

        {!!reedooCarrier.carrierId && (
          <div className={`${styles.deliveryMethodsContainer}`}>
            <CustomCheckbox
              label="Livraison à domicile"
              checked={deliveryMethodsChecklist.delivery}
              onChange={() => {}}
              disabled
            />
            {deliveryMethodsChecklist.delivery && (
              <DeliveryMethodSection
                method={DeliveryMethodEnum.DELIVERY}
                carrierIndex={index}
              />
            )}
            <CustomCheckbox
              label="Point retrait"
              checked={deliveryMethodsChecklist.pickup}
              onChange={() => {}}
              disabled
            />
            {deliveryMethodsChecklist.pickup && (
              <DeliveryMethodSection
                method={DeliveryMethodEnum.PICKUP}
                carrierIndex={index}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
