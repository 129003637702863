import { useUserContext } from "~/context/userContext";
import { HeadCell } from "~/types/common/headCell.type";

interface Props {
  headCells: HeadCell[];
}

export const UsersTableHead = ({ headCells }: Props) => {
  const { userState } = useUserContext();

  return (
    <thead>
      <tr>
        {headCells.map((headCell) => {
          const label = headCell.label;
          const id = headCell.id;
          const permissionLevel = headCell.permissionLevel;

          if (
            userState?.connected &&
            permissionLevel &&
            userState.permissionLevel !== permissionLevel
          )
            return;

          return <th key={id}>{label}</th>;
        })}
      </tr>
    </thead>
  );
};
