import { HeadCell } from "~/types/common/headCell.type";

import { TableRowData } from "..";
import styles from "./index.module.scss";
import { SmallTableBody } from "./SmallTableBody";
import { SmallTableHead } from "./SmallTableHead";

interface Props {
  headCells: HeadCell[];
  tableRowsData: TableRowData[];
}

export const SmallTable = ({ headCells, tableRowsData }: Props) => {
  return (
    <table className={`${styles.table}`}>
      <SmallTableHead headCells={headCells} />
      <SmallTableBody headCells={headCells} tableRowsData={tableRowsData} />
    </table>
  );
};
