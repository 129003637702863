export const routePaths = {
  loginRoute: "login",
  setNewPasswordRoute: "set-new-password",
  forgetPasswordRoute: "forgot-password",
  confirmForgotPasswordRoute: "confirm-forgot-password",
  dashboardRoute: "dashboard",
  mesCommandesRoute: "mes-commandes",
  venteRoute: "vente",
  locationRoute: "location",
  mesEvaluationsRoute: "mes-evaluations",
  detailsDuneCommandeRoute: "details-d'une-commande",
  detailsRoute: "details",
  expeditionRoute: "expedition",
  messagesRoute: "messages",
  historiquesRoute: "historiques",
  monCatalogueRoute: "mon-catalogue",
  maMessagerieRoute: "ma-messagerie",
  monCompteRoute: "mon-compte",
  identificationRoute: "identification",
  coordonneesSocieteRoute: "coordonnees-societe",
  uboDeclarationRoute: "ubo-declaration",
  importDesKycRoute: "import-des-kyc",
  referencesBancairesRoute: "references-bancaires",
  livraisonRoute: "livraison",
  reedooLivraisonRoute: "reedoo-livraison",
  utilisateursRoute: "utilisateurs",
  maComptabiliteRoute: "ma-comptabilite",
  facturesRoute: "factures",
  transactionsRoute: "transactions",
  ajouterUnProduitRoute: "ajouter-un-produit",
  importerParFichierRoute: "importer-par-fichier",
  importerParBoutiqueRoute: "importer-par-boutique/:shopId",
  mesImportationsRoute: "mes-importations",
  synchronizationOfStreamsRoute: "synchronisation-des-flux",
  errorRoute: "error",
  idDynamicRoute: ":id",
  shopifyConnectRoute: "shopify-connect",
  maMarqueRoute: "ma-marque",
  maMarqueDetailsRoute: "details",
  secondHandRoute: "second-hand",
  secondHandDetailsRoute: "details",
  abonnementRoute: "abonnement",
  commandesAbonnementRoute: "commandes-d'abonnement",
  adminUtilisateursRoute: "admin-utilisateurs",
  userDetailsRoute: "user-details",
  addressesRoute: "addresses",
  profileRoute: "profile",
  adminCompaniesRoute: "admin-companies",
  companyFormRoute: "company-form",
  platformDataRoute: "donnees-plateforme",
  adminPlatformDataRoute: "admin-donnees-plateforme",
  taxonomieRoute: "taxonomie",
  vendorCategoriesRoute: "vendor-categories",
  attributesRoute: "attributes",
  promoCodesRoute: "promo-codes",
};

export type RoutesType = keyof typeof routePaths;
export type RouteReplaceType = { variable: string; position: number };

export const routesBuilder = (input: {
  routes: RoutesType[];
  replace?: RouteReplaceType[];
}) => {
  const { routes, replace } = input;
  const paths = routes.map((route) => {
    return routePaths[route];
  });
  if (replace) {
    replace.forEach(({ position, variable }) => {
      paths.splice(position, 0, `${variable}`);
    });
  }
  return paths.join("/");
};
