import { useEffect, useState } from "react";

import CustomCheckbox from "~/components/form/CustomCheckbox";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import {
  DeliveryAreaEnum,
  DeliveryMethodEnum,
} from "~/types/data/Carrier.types";

import { DeliveryAreaSection } from "../DeliveryAreaSection";
import styles from "./index.module.scss";

interface Props {
  carrierIndex: number;
  method: DeliveryMethodEnum;
}

interface DeliveryAreasChecklist {
  france: boolean;
  corse: boolean;
  monaco: boolean;
}

export const DeliveryMethodSection = ({ method, carrierIndex }: Props) => {
  const { companyAccount } = useCompanyAccountContext();

  const reedooCarrier = companyAccount.reedooCarriers[carrierIndex];

  const initDeliveryAreasChecklist: DeliveryAreasChecklist = {
    france: !!reedooCarrier.deliveryPackages.find((deliveryPackage) => {
      return (
        deliveryPackage.deliveryMethod === method &&
        deliveryPackage.deliveryArea === DeliveryAreaEnum.FRANCE
      );
    }),
    corse: !!reedooCarrier.deliveryPackages.find((deliveryPackage) => {
      return (
        deliveryPackage.deliveryMethod === method &&
        deliveryPackage.deliveryArea === DeliveryAreaEnum.CORSE
      );
    }),
    monaco: !!reedooCarrier.deliveryPackages.find((deliveryPackage) => {
      return (
        deliveryPackage.deliveryMethod === method &&
        deliveryPackage.deliveryArea === DeliveryAreaEnum.MONACO
      );
    }),
  };

  const [deliveryAreasChecklist, setDeliveryAreasChecklist] =
    useState<DeliveryAreasChecklist>(initDeliveryAreasChecklist);

  useEffect(() => {
    const deliveryAreasChecklist: DeliveryAreasChecklist = {
      france: !!reedooCarrier.deliveryPackages.find((deliveryPackage) => {
        return (
          deliveryPackage.deliveryMethod === method &&
          deliveryPackage.deliveryArea === DeliveryAreaEnum.FRANCE
        );
      }),
      corse: !!reedooCarrier.deliveryPackages.find((deliveryPackage) => {
        return (
          deliveryPackage.deliveryMethod === method &&
          deliveryPackage.deliveryArea === DeliveryAreaEnum.CORSE
        );
      }),
      monaco: !!reedooCarrier.deliveryPackages.find((deliveryPackage) => {
        return (
          deliveryPackage.deliveryMethod === method &&
          deliveryPackage.deliveryArea === DeliveryAreaEnum.MONACO
        );
      }),
    };
    setDeliveryAreasChecklist(deliveryAreasChecklist);
  }, [companyAccount.reedooCarriers]);

  return (
    <div className={`${styles.deliveryAreasContainer}`}>
      <CustomCheckbox
        label="France"
        checked={deliveryAreasChecklist.france}
        onChange={() => {}}
        disabled
      />
      {deliveryAreasChecklist.france && (
        <DeliveryAreaSection
          method={DeliveryMethodEnum[method]}
          area={DeliveryAreaEnum.FRANCE}
          carrierIndex={carrierIndex}
        />
      )}

      <CustomCheckbox
        label="Corse"
        checked={deliveryAreasChecklist.corse}
        onChange={() => {}}
        disabled
      />
      {deliveryAreasChecklist.corse && (
        <DeliveryAreaSection
          method={DeliveryMethodEnum[method]}
          area={DeliveryAreaEnum.CORSE}
          carrierIndex={carrierIndex}
        />
      )}

      <CustomCheckbox
        label="Monaco"
        checked={deliveryAreasChecklist.monaco}
        onChange={() => {}}
        disabled
      />
      {deliveryAreasChecklist.monaco && (
        <DeliveryAreaSection
          method={DeliveryMethodEnum[method]}
          area={DeliveryAreaEnum.MONACO}
          carrierIndex={carrierIndex}
        />
      )}
    </div>
  );
};
