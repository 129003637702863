import SVGContainer from "../SVGContainer";
import styles from "./index.module.scss";

interface Props {
  ascendingActive: boolean;
  descendingActive: boolean;
  orderByDescendingHandler: () => void;
  orderByAscendingingHandler: () => void;
}

export const SortingButtons = ({
  ascendingActive,
  descendingActive,
  orderByDescendingHandler,
  orderByAscendingingHandler,
}: Props) => {
  return (
    <div className={`${styles.container}`}>
      <SVGContainer
        height="4px"
        width="5px"
        imagePath={
          descendingActive
            ? "/assets/selected-sort-desc-icon.svg"
            : "/assets/sort-desc-icon.svg"
        }
        onClick={!descendingActive ? orderByDescendingHandler : undefined}
      />
      <SVGContainer
        height="4px"
        width="5px"
        imagePath={
          ascendingActive
            ? "/assets/selected-sort-asc-icon.svg"
            : "/assets/sort-asc-icon.svg"
        }
        onClick={!ascendingActive ? orderByAscendingingHandler : undefined}
      />
    </div>
  );
};
