import { DownloadInput } from "../../../components/form/DownloadInput";
import { SizedBox } from "../../../components/UI/SizedBox";
import styles from "./index.module.scss";
export const GuidesUtilisateur = () => {
  const inputWidth = "45%";
  return (
    <>
      <SizedBox height="0.8rem" />
      <div className={styles.mainContainer}>
        <DownloadInput
          header="Guide création compte"
          text="Lorem ipsum dolor"
          width={inputWidth}
          filePath={""}
        />
        <DownloadInput
          header="Guide import catalogue"
          text="Lorem ipsum dolor"
          width={inputWidth}
          filePath={""}
        />
        <DownloadInput
          header="Guide d’une fiche produit"
          text="Lorem ipsum dolor"
          width={inputWidth}
          filePath={""}
        />
        <DownloadInput
          header="Guide technique API"
          text="Lorem ipsum dolor"
          width={inputWidth}
          filePath={""}
        />
      </div>
    </>
  );
};
