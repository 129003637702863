import { findBestMatch } from "string-similarity";

export const getBestMatch = (s: string, a: string[]) => {
  if (a.length < 1) {
    return undefined;
  }
  const bestMatch = findBestMatch(
    s.toLowerCase(),
    a.map((s) => s.toLowerCase())
  );

  const bestMatchString =
    bestMatch.bestMatch.rating > 0.3 ? a[bestMatch.bestMatchIndex] : undefined;

  return bestMatchString;
};

export const recursiveBestMatchs = (
  categoriesToGuess: string[],
  index: number,
  categoryNames: string[]
) => {
  let bestMatch: string | undefined;
  while (index !== -1 && categoriesToGuess.length > 0) {
    bestMatch = getBestMatch(categoriesToGuess[index], categoryNames);
    if (bestMatch) return bestMatch;
    index--;
  }
  // this or above can work
  // const bestMatch = getBestMatch(categoriesToGuess[index], categoryNames);
  // return bestMatch;
  return;
};
