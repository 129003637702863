import StatusLabel from "~/components/UI/StatusLabel";
import SVGContainer from "~/components/UI/SVGContainer";
import { IncidentStatusEnum, OrderStatusEnum } from "~/types/data/Order.types";

import styles from "./index.module.scss";

interface Props {
  clientName: string;
  orderId: number;
  status: OrderStatusEnum;
  incidentStatus: IncidentStatusEnum;
}

export const InformationBanner = ({
  clientName,
  orderId,
  status,
  incidentStatus,
}: Props) => {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.bannerItem}`}>
        <p className={`${styles.title}`}>Client :</p>
        <div className={`${styles.itemContent}`}>
          <p>{clientName}</p>
          <SVGContainer
            height="12px"
            width="12px"
            imagePath="/assets/message-icon.svg"
          />
        </div>
      </div>
      <div className={`${styles.bannerItem}`}>
        <p className={`${styles.title}`}>N° de commande :</p>
        <div className={`${styles.itemContent}`}>
          <p>{orderId}</p>
        </div>
      </div>

      <div className={`${styles.bannerItem}`}>
        <p className={`${styles.title}`}>Statut :</p>
        <div className={`${styles.statusItemContent}`}>
          <StatusLabel label={status} color="white" backgroundColor="#3DBD00" />
          {(incidentStatus === IncidentStatusEnum.OPEN ||
            incidentStatus === IncidentStatusEnum.CLOSED_BY_SELLER) && (
            <StatusLabel
              label="Incident"
              color="white"
              backgroundColor="#C22A23"
            />
          )}
        </div>
      </div>
    </div>
  );
};
