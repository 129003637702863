import SVGContainer from "../SVGContainer";
import styles from "./index.module.scss";

interface Props {
  title: string;
}

const DraggableCard = ({ title, children }: React.PropsWithChildren<Props>) => {
  return (
    <div className={`${styles.card}`}>
      <div className={`${styles.header}`}>
        <div className={`${styles.title}`}>{title}</div>
        <div className={`draggable ${styles.icon}`}>
          <SVGContainer
            height="28px"
            width="28px"
            imagePath="/assets/drag-icon.svg"
          />
        </div>
      </div>
      <div className={`${styles.content}`}>{children}</div>
    </div>
  );
};

export default DraggableCard;
