import { memo } from "react";
import { SingleValue } from "react-select";

import SelectInput, { OptionType } from "~/components/form/SelectInput";
import { colors } from "~/constants/styles";

type Props = {
  value?: number | null;
  options: OptionType[];
  onValueChange: (value: string) => void;
};

const CategorySelectInput = ({ value, options, onValueChange }: Props) => {
  return (
    <SelectInput
      onChange={(option?: SingleValue<OptionType>) => {
        onValueChange(option?.value ?? "");
      }}
      options={options}
      value={`${value}`}
      backgroundColor={colors.$inputGray}
      fontSize="12px"
      hasDefault={true}
      defaultLabel="Not selected"
      invalidColor="#870000"
    />
  );
};
export const MemoizedCategorySelectInput = memo(CategorySelectInput);
