import { ApolloError, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { getMediaUploadPath } from "~/api/graphql/media/getMediaUploadPath";
import {
  UPDATE_SECOND_HAND_CATEGORY,
  UpdateSecondHandCategoryInput,
  UpdateSecondHandCategoryResponse,
} from "~/api/graphql/secondHandCategory";
import {
  UPDATE_SECOND_HAND_COMPANY,
  UpdateSecondHandCompanyInput,
  UpdateSecondHandCompanyResponse,
} from "~/api/graphql/SecondHandCompany";
import { ColorPickerInput } from "~/components/form/ColorPickerInput";
import { CustomButton } from "~/components/form/CustomButton";
import { CustomInput } from "~/components/form/CustomInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useUserContext } from "~/context/userContext";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import { routesBuilder } from "~/navigation/routes";
import { SecondHandCompany } from "~/types/data/SecondHandCompany.type";
import { PermissionLevelEnum } from "~/types/data/User.types";

import { ImageEditorDisplay } from "../../../components/form/ImageEditorDisplay";
import styles from "./index.module.scss";
import { validateAndUploadSecondHandCompanyMedia } from "./utils/validateAndUploadSecondHandCompanyMedia.util";
import { validateUpdateSecondHandCompany } from "./utils/validateUpdateSecondHandCompany.util";

export const Details = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { userState } = useUserContext();
  const { companyAccount, setCompanyAccount } = useCompanyAccountContext();

  const [secondHandCompany, setSecondHandCompany] =
    useState<SecondHandCompany>();

  const updateSecondHandCompanySuccessHandler = (
    data: UpdateSecondHandCompanyResponse
  ) => {
    setCompanyAccount((state) => ({
      ...state,
      ...(state.secondHandCompany && {
        secondHandCompany: {
          ...state.secondHandCompany,
          ...data.updateSecondHandCompany,
        },
      }),
    }));
  };

  const updateSecondHandCompanyErrorHandler = (error: ApolloError) => {
    setErrorMessage(error.message);
  };

  const { trigger: updateSecondHandCompanyTrigger } = useMutationWithCallbacks<
    UpdateSecondHandCompanyResponse,
    UpdateSecondHandCompanyInput
  >(
    UPDATE_SECOND_HAND_COMPANY,
    updateSecondHandCompanySuccessHandler,
    updateSecondHandCompanyErrorHandler
  );

  const inputChangeHandler = <T extends keyof SecondHandCompany>(
    inputName: T,
    changes: SecondHandCompany[T]
  ) => {
    setSecondHandCompany((state) =>
      state ? { ...state, [inputName]: changes } : undefined
    );
  };

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!secondHandCompany) return;
    setSuccessMessage("");
    setErrorMessage("");
    setIsLoading(true);

    // validate input
    const validationError = validateUpdateSecondHandCompany(secondHandCompany);

    if (validationError) {
      setErrorMessage(validationError);
      setIsLoading(false);
      return;
    }

    // validate and upload media
    const { input: validatedInput, error: mediaError } =
      await validateAndUploadSecondHandCompanyMedia(secondHandCompany);

    if (!validatedInput) {
      setErrorMessage(mediaError);
      setIsLoading(false);
      return;
    }

    // send update request
    await updateSecondHandCompanyTrigger({
      variables: { UpdateSecondHandCompanyInput: validatedInput },
    });

    setIsLoading(false);
  };

  const uploadAuthBannerHandler = (file?: File) => {
    if (!file) return;

    const imageUrl = URL.createObjectURL(file);
    console.log(imageUrl);
    inputChangeHandler("authBanner", imageUrl);
    inputChangeHandler("authBannerFile", file);
  };

  const uploadMobileAuthBannerHandler = (file?: File) => {
    if (!file) return;

    const imageUrl = URL.createObjectURL(file);
    inputChangeHandler("mobileAuthBanner", imageUrl);
    inputChangeHandler("mobileAuthBannerFile", file);
  };

  const updloadLogoHandler = (file?: File) => {
    if (!file) return;

    const imageUrl = URL.createObjectURL(file);
    inputChangeHandler("logo", imageUrl);
    inputChangeHandler("logoFile", file);
  };

  const uploadBannerHandler = (file?: File) => {
    if (!file) return;

    const imageUrl = URL.createObjectURL(file);
    inputChangeHandler("banner", imageUrl);
    inputChangeHandler("bannerFile", file);
  };

  const uploadMobileBannerHandler = (file?: File) => {
    if (!file) return;

    const imageUrl = URL.createObjectURL(file);
    inputChangeHandler("mobileBanner", imageUrl);
    inputChangeHandler("mobileBannerFile", file);
  };

  const removeAuthBannerHandler = () => {
    inputChangeHandler("authBanner", "");
    inputChangeHandler("authBannerFile", undefined);
  };

  const removeMobileAuthBannerHandler = () => {
    inputChangeHandler("mobileAuthBanner", "");
    inputChangeHandler("mobileAuthBannerFile", undefined);
  };

  const removeLogoHandler = () => {
    inputChangeHandler("logo", "");
    inputChangeHandler("logoFile", undefined);
  };

  const removeBannerHandler = () => {
    inputChangeHandler("banner", "");
    inputChangeHandler("bannerFile", undefined);
  };

  const removeMobileBannerHandler = () => {
    inputChangeHandler("mobileBanner", "");
    inputChangeHandler("mobileBannerFile", undefined);
  };

  useEffect(() => {
    if (companyAccount?.secondHandCompany) {
      setSecondHandCompany(companyAccount.secondHandCompany);
    }
  }, []);

  type SecondHandCategoriesUploaded = {
    [key: number]: {
      imageUrl: string;
      file: File;
    };
  };

  const [updateSecondHandCategory] = useMutation<
    UpdateSecondHandCategoryResponse,
    UpdateSecondHandCategoryInput
  >(UPDATE_SECOND_HAND_CATEGORY);

  const [secondHandCategoriesUploaded, setSecondHandCategoriesUploaded] =
    useState<SecondHandCategoriesUploaded>([]);
  const onCategoriesUpload = async () => {
    for (const categoryId in secondHandCategoriesUploaded) {
      const file = secondHandCategoriesUploaded[parseInt(categoryId)].file;
      const response = await getMediaUploadPath({
        field: "logo",
        entity: "secondHandCompany/pictures",
        filename: file.name,
        isPublic: true,
      });
      if (response.success) {
        const { path, url } = response.data;
        await fetch(url, { method: "put", body: file }).then((res) =>
          console.log(res)
        );
        await updateSecondHandCategory({
          variables: {
            UpdateSecondHandCategoryInput: {
              secondHandCompanyId: secondHandCompany?.id!,
              categoryId: parseInt(categoryId),
              imagePath: path,
            },
          },
        });
      } else {
        return { error: "Uploading logo didn't work" };
      }
    }
  };

  const uploadSecondHandCategoryHandler = (categoryId: number) => {
    return (file: File) => {
      // inputChangeHandler(`${categoryId}`, file);
      const imageUrl = URL.createObjectURL(file);
      return setSecondHandCategoriesUploaded((state) => ({
        ...state,
        [categoryId]: { imageUrl, file: file },
      }));
    };
  };

  const removeSecondHandCategoryHandler = (categoryId: number) =>
    setSecondHandCategoriesUploaded((state) => {
      const newState = { ...state };
      delete newState[categoryId];
      return newState;
    });

  const disabled =
    userState?.connected &&
    userState.permissionLevel === PermissionLevelEnum.MEMBER;
  const secondHandCompanyPromoCodeRoute = routesBuilder({
    routes: ["secondHandRoute", "promoCodesRoute"],
  });
  return (
    <div className={styles.mainContainer}>
      <div className={styles.titleContainer}>
        <h2>Gérer ma marque d'occasion</h2>
        <NavLink to={"/" + secondHandCompanyPromoCodeRoute}>
          <CustomButton
            backgroundColor={colors.$primary}
            color="white"
            width="fit-content"
            borderRadius="8px"
            padding="1rem 1.5rem"
          >
            Promo Codes
          </CustomButton>
        </NavLink>
      </div>
      <div className={styles.formCard}>
        <h3 className="title">Second Hand</h3>
        <form
          className={styles.formContainer}
          onSubmit={(e) => {
            if (!disabled) submitHandler(e);
          }}
        >
          <div className={styles.formRow}>
            <ImageEditorDisplay
              imageUrl={secondHandCompany?.logo}
              id="secondHandCompany-logo"
              onUpload={updloadLogoHandler}
              onRemove={removeLogoHandler}
              height="5rem"
              disabled={disabled}
            />
            <CustomInput
              onChange={(value: string | number) => {
                inputChangeHandler("name", value.toString());
              }}
              value={secondHandCompany?.name}
              label="Name :"
              borderRadius="5px"
              noBorder={true}
              backgroundColor={colors.$inputGray}
              textColor={colors.$primaryDark}
              fontSize="14px"
              width="100%"
              disabled={disabled}
            />
          </div>
          <div className={styles.formRow}>
            <CustomInput
              onChange={(value: string | number) => {
                inputChangeHandler("websiteUrl", value.toString());
              }}
              value={secondHandCompany?.websiteUrl}
              label="Website url :"
              borderRadius="5px"
              noBorder={true}
              backgroundColor={colors.$inputGray}
              textColor={colors.$primaryDark}
              fontSize="14px"
              width="100%"
              disabled={disabled}
            />
          </div>
          <div className={styles.imageRow}>
            <div className={styles.leftSide}>
              <ImageEditorDisplay
                imageUrl={secondHandCompany?.banner}
                id="secondHandCompany-banner"
                label="Image principale"
                onUpload={uploadBannerHandler}
                onRemove={removeBannerHandler}
                disabled={disabled}
              />
            </div>
            <div className={styles.rightSide}>
              <ImageEditorDisplay
                imageUrl={secondHandCompany?.mobileBanner}
                id="secondHandCompany-mobileBanner"
                label="Image principale (version mobile)"
                onUpload={uploadMobileBannerHandler}
                onRemove={removeMobileBannerHandler}
                disabled={disabled}
              />
            </div>
          </div>
          <div className={styles.imageRow}>
            <div className={styles.leftSide}>
              <ImageEditorDisplay
                imageUrl={secondHandCompany?.authBanner}
                id="secondHandCompany-authBanner"
                label="Image de connexion"
                onUpload={uploadAuthBannerHandler}
                onRemove={removeAuthBannerHandler}
                disabled={disabled}
              />
            </div>
            <div className={styles.rightSide}>
              <ImageEditorDisplay
                imageUrl={secondHandCompany?.mobileAuthBanner}
                id="secondHandCompany-mobileAuthBanner"
                label="Image de connexion (version mobile)"
                onUpload={uploadMobileAuthBannerHandler}
                onRemove={removeMobileAuthBannerHandler}
                disabled={disabled}
              />
            </div>
          </div>

          <div className={styles.formRow}>
            <ColorPickerInput
              onChange={(value) => {
                inputChangeHandler("primaryColor", value);
              }}
              value={secondHandCompany?.primaryColor ?? ""}
              label="Primary color :"
              borderRadius="5px"
              noBorder={true}
              backgroundColor={colors.$inputGray}
              textColor={colors.$primaryDark}
              fontSize="14px"
              width="100%"
              disabled={disabled}
            />
            <ColorPickerInput
              onChange={(value) => {
                inputChangeHandler("secondaryColor", value);
              }}
              value={secondHandCompany?.secondaryColor ?? ""}
              label="Secondary color :"
              borderRadius="5px"
              noBorder={true}
              backgroundColor={colors.$inputGray}
              textColor={colors.$primaryDark}
              fontSize="14px"
              width="100%"
              disabled={disabled}
            />
          </div>
          {!disabled && (
            <>
              <div className="messageContainer">
                {!!errorMessage && (
                  <p className="errorMessage">{errorMessage}</p>
                )}
                {!!successMessage && <p>{successMessage}</p>}
              </div>

              <div className="buttonContainer">
                <CustomButton
                  backgroundColor={colors.$primary}
                  color="white"
                  width="fit-content"
                  borderRadius="8px"
                  padding="1rem 1.5rem"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? <SimpleLoader size="size2" /> : "Enregistrer"}
                </CustomButton>
              </div>
            </>
          )}
        </form>
      </div>
      <div className={styles.formCard}>
        <div className={styles.categoriesContainer}>
          {secondHandCompany?.secondHandCategories.map(
            (secondHandCategory) => (
              console.log(secondHandCategory.imagePath),
              console.log(secondHandCompany?.authBanner),
              (
                <ImageEditorDisplay
                  imageUrl={
                    secondHandCategoriesUploaded[secondHandCategory.categoryId]
                      ?.imageUrl || secondHandCategory?.imagePath
                  }
                  id={secondHandCategory.category.name + secondHandCategory.id}
                  label={secondHandCategory?.category.name}
                  onCategoryUpload={uploadSecondHandCategoryHandler(
                    secondHandCategory.categoryId
                  )}
                  onRemove={() =>
                    removeSecondHandCategoryHandler(
                      secondHandCategory.categoryId
                    )
                  }
                  disabled={disabled}
                  key={secondHandCategory.id}
                />
              )
            )
          )}
        </div>
        {!disabled && (
          <>
            <div className="messageContainer">
              {!!errorMessage && <p className="errorMessage">{errorMessage}</p>}
              {!!successMessage && <p>{successMessage}</p>}
            </div>

            <div className="buttonContainer">
              <CustomButton
                backgroundColor={colors.$primary}
                color="white"
                width="fit-content"
                borderRadius="8px"
                padding="1rem 1.5rem"
                type="submit"
                disabled={isLoading}
                onClick={onCategoriesUpload}
              >
                {isLoading ? <SimpleLoader size="size2" /> : "Enregistrer"}
              </CustomButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
