import { CustomButton } from "~/components/form/CustomButton";
import CustomModal from "~/components/UI/CustomModal";
import { colors } from "~/constants/styles";

import styles from "./index.module.scss";
type Props = {
  onCancel: () => void;
  show: boolean;
  text: string;
};
export const GeneralModal = ({ onCancel, show, text }: Props) => {
  return (
    <CustomModal onCancel={onCancel} show={show}>
      <div className={styles.modalContainer}>
        <h3>{text}</h3>
        <CustomButton
          type="button"
          backgroundColor={colors.$primary}
          color="white"
          width="13rem"
          borderRadius="8px"
          padding="1rem 1rem"
          onClick={onCancel}
        >
          fermé
        </CustomButton>
      </div>
    </CustomModal>
  );
};
