import { memo } from "react";

import { OptionType } from "~/components/form/SelectInput";
import { Category } from "~/types/data/Category.type";
import { VendorCategory } from "~/types/data/VendorCategory.types";

import { DeleteModalProps } from "..";
import styles from "./index.module.scss";
import { MemoizedVendorCategoriesRow } from "./VendorCategoriesRow";

type Props = {
  vendorCategories: VendorCategory[];
  level1Categories: Category[];
  level3Categories: Category[];
  attributes: OptionType[];
  setDeleteModalState: (data: DeleteModalProps) => void;
};
const VendorCategoriesTable = ({
  vendorCategories,
  level1Categories,
  level3Categories,
  attributes,
  setDeleteModalState,
}: Props) => {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.tableContainer}`}>
        <table>
          <thead>
            <tr>
              <th rowSpan={2}>#ID</th>
              <th rowSpan={2}>Vendor Category</th>
              <th colSpan={4}>Mappings</th>
              <th rowSpan={2}>Actions</th>
            </tr>
            <tr>
              <th>Category 1</th>
              <th>Category 2</th>
              <th>Category 3</th>
              <th>Attributes</th>
            </tr>
          </thead>
          <tbody>
            {vendorCategories.map((vendorCategory, index) => {
              return (
                <MemoizedVendorCategoriesRow
                  key={vendorCategory.id ?? new Date().getTime()}
                  index={index}
                  level1Categories={level1Categories}
                  level3Categories={level3Categories}
                  vendorCategory={vendorCategory}
                  attributes={attributes}
                  setDeleteModalState={setDeleteModalState}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const MemoizedVendorCategoriesTable = memo(
  VendorCategoriesTable,
  (prevProps, nextProps) => {
    if (prevProps.vendorCategories.length !== nextProps.vendorCategories.length)
      return false;

    return true;
  }
);
