import { useMemo, useState } from "react";

import { CustomButton } from "~/components/form/CustomButton";
import { colors } from "~/constants/styles";
import { useAppSelector } from "~/redux/hooks";
import { selectMappedAttributesState } from "~/redux/slice/mappedAttributes.slice";
import { selectMappedAttributeValuesState } from "~/redux/slice/mappedAttributesValues.slice";
import { Attribute } from "~/types/data/Attribute.types";
import { AttributeValuesSheetValueType } from "~/types/data/FileImport.types";
import { getKeys } from "~/util/functions/getKeys";

import { SectionIndexEnum } from "../SectionsNavigation";
import { MemoizedAttributeSelectRow } from "./AttributeSelectRow";
import styles from "./index.module.scss";

interface Props {
  attributeValuesSheetValues: AttributeValuesSheetValueType;
  isProcessing: boolean;
  onNext: () => void;
  onPrevious: (section: SectionIndexEnum) => void;
  onStateHasChanged: (state: number) => void;
  attributes: Attribute[];
}

export const AttributeValuesMappingPage = ({
  isProcessing,
  onNext,
  onPrevious,
  attributes,
  onStateHasChanged,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const mappedAttributes = useAppSelector(selectMappedAttributesState);
  const mappedAttributeValues = useAppSelector(
    selectMappedAttributeValuesState
  );
  const attributeKeys = getKeys(mappedAttributeValues);

  const disabled = isProcessing;

  const previousNavigationHandler = () => {
    onPrevious(SectionIndexEnum.categoriesMapping);
  };

  const nextNaviagtionHandler = () => {
    setErrorMessage("");

    // check if there are undefined attribute values mapped
    // for (const attributeKey of attributeKeys) {
    //   const attributeValues = mappedAttributeValues[attributeKey];

    //   if (attributeValues) {
    //     const attributeValuesKeys = Object.keys(attributeValues);

    //     for (let i = 0; i < attributeValuesKeys.length; i++) {
    //       const mappedAttributeValue = attributeValues[attributeValuesKeys[i]];

    //       if (!mappedAttributeValue) {
    //         const attribute = mappedAttributes.find(
    //           ({ mappedAttribute }) => mappedAttribute?.name === attributeKey
    //         );
    //         setErrorMessage(
    //           `Value in row ${i + 1} in table ${
    //             attribute?.originalName
    //           } is not selected`
    //         );
    //         return;
    //       }
    //     }
    //   }
    // }

    // if no problems call onNext()
    onNext();
  };

  return (
    <div className={`${styles.container}`}>
      {attributeKeys.map((attributeKey) => {
        const attribute = mappedAttributes.find(
          ({ mappedAttribute }) => mappedAttribute?.name === attributeKey
        );
        if (attribute) {
          const tableTitle = attribute.originalName;

          const attrubteMappedAttributeValues =
            mappedAttributeValues[attributeKey] || {};

          const attributeValuesSheetNames = Object.keys(
            attrubteMappedAttributeValues
          );
          return (
            <div
              className={`${styles.attributeSectionContainer}`}
              key={attributeKey}
            >
              <h3>{tableTitle}</h3>
              <div className={`${styles.tableContainer}`}>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>Suggested</th>
                      <th>Your choice</th>
                    </tr>
                  </thead>
                  <tbody>
                    {attributeValuesSheetNames.map((sheetName, index) => {
                      const mappedAttributeValue =
                        attrubteMappedAttributeValues[sheetName];
                      const attributeValue = useMemo(
                        () => mappedAttributeValue?.value || "",
                        [mappedAttributeValue]
                      );
                      const value = mappedAttributeValue
                        ? `${
                            attributeValue as string
                          }||${mappedAttributeValue.associatedValues.join(
                            "||"
                          )}`
                        : "";

                      const guessedValue = useMemo(
                        () => (mappedAttributeValue?.value as string) || "",
                        []
                      );
                      return (
                        <tr key={index}>
                          <td>#{index + 1}</td>
                          <td>{sheetName}</td>
                          <td>{guessedValue}</td>
                          <MemoizedAttributeSelectRow
                            attributeKey={attributeKey}
                            attributeValueKey={sheetName}
                            value={value}
                            attributes={attributes}
                            onStateHasChanged={onStateHasChanged}
                          />
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <hr />
            </div>
          );
        }
      })}

      {!!errorMessage && (
        <p className={`${styles.errorMessage}`}>{errorMessage}</p>
      )}
      <div className={`${styles.buttons}`}>
        <div className={`${styles.button}`}>
          <CustomButton
            backgroundColor={colors.$inputGray}
            borderColor="#DEE3E8"
            borderRadius="5px"
            onClick={!disabled ? previousNavigationHandler : undefined}
            disabled={disabled}
          >
            Previous
          </CustomButton>
        </div>
        <div className={`${styles.button}`}>
          <CustomButton
            backgroundColor={colors.$inputGray}
            borderColor="#DEE3E8"
            borderRadius="5px"
            onClick={!disabled ? nextNaviagtionHandler : undefined}
            disabled={disabled}
          >
            Next
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
