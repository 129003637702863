import { ApolloError, DocumentNode, useMutation } from "@apollo/client";

export const useMutationWithCallbacks = <T, K = unknown>(
  mutation: DocumentNode,
  onSuccess: (response: T) => void,
  onError: (error: ApolloError) => void
) => {
  const [trigger, { loading }] = useMutation<T, K>(mutation, {
    onCompleted(data) {
      onSuccess(data);
    },
    onError(error) {
      onError(error);
    },
    notifyOnNetworkStatusChange: true,
  });

  return { trigger, loading };
};
