import { CustomButton } from "~/components/form/CustomButton";
import { colors } from "~/constants/styles";

import { RefundMode } from "../..";
import styles from "./index.module.scss";

interface Props {
  onModeButtonClick: (mode: RefundMode) => void;
}

export const DefaultRefundForm = ({ onModeButtonClick }: Props) => {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.buttons}`}>
        <CustomButton
          color={colors.$primary}
          borderColor={colors.$primary}
          borderRadius="5px"
          height="fit-content"
          padding="0.2rem 0.5rem"
          width="fit-content"
          onClick={() => onModeButtonClick("full")}
        >
          Remboursement complet
        </CustomButton>
        <CustomButton
          color={colors.$primary}
          borderColor={colors.$primary}
          borderRadius="5px"
          height="fit-content"
          padding="0.2rem 0.5rem"
          width="fit-content"
          onClick={() => onModeButtonClick("detailed")}
        >
          Remboursement détaillé
        </CustomButton>
      </div>
    </div>
  );
};
