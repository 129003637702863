import Joi from "joi";
import passwordComplexity from "joi-password-complexity";

import { ChangePasswordInput } from "~/types/input/auth.types";

export const changePasswordalidationSchema = Joi.object<ChangePasswordInput>({
  username: Joi.string()
    .email({ tlds: false, allowUnicode: true })
    .required()
    .error(new Error("Email invalide.")),
  code: Joi.string()
    .required()
    .error(new Error("Le code de vérification ne peut pas être vide.")),
  password: passwordComplexity()
    .required()
    .error(
      new Error("Au moins une majuscule, un caractère spécial et un chiffre.")
    ),
});
