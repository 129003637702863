import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import Layout from "~/components/layout/Layout";
import { AdminCompanies } from "~/pages/AdminCompanies";
import {
  AdminDataPlatform,
  AdminDataPlatformPagesEnum,
} from "~/pages/AdminDataPlatform";
import { AdminUtilisateurs } from "~/pages/AdminUtilisateurs";
import AjouterUnProduit from "~/pages/AjouterUnProduit";
import { BrandDetails } from "~/pages/BrandDetails";
import { CommandesAbonnement } from "~/pages/CommandesAbonnement";
import { CommandesAbonnementDetails } from "~/pages/CommandesAbonnement/CommandesAbonnementDetails";
import { CompanyForm } from "~/pages/CompanyForm";
import ConfirmForgotPassword from "~/pages/ConfirmForgotPassword";
import Dashboard from "~/pages/Dashboard";
import { DataPlatform } from "~/pages/DataPlatform";
import { DynamicErrorPage } from "~/pages/DynamicErrorPage";
import ForgotPassword from "~/pages/ForgotPassword";
import { ImporterParBoutique } from "~/pages/ImporterParBoutique";
import { ImporterParFichier } from "~/pages/ImporterParFichier";
import Login from "~/pages/Login";
import { MaComptabilite } from "~/pages/MaComptabilite";
import { MaMessagerie } from "~/pages/MaMessagerie";
import MesCommandes from "~/pages/MesCommandes";
import { DetailsDuneCommande } from "~/pages/MesCommandes/DetailsDuneCommande";
import { MyReviews } from "~/pages/MesCommandes/MyReviews";
import { RentDetailsDuneCommande } from "~/pages/MesCommandes/RentDetailsDuneCommande";
import { MesImportations } from "~/pages/MesImportations";
import MonCatalogue from "~/pages/MonCatalogue";
import MonCompte from "~/pages/MonCompte";
import { SecondHandDetails } from "~/pages/SecondHandDetails";
import { SecondHandPromoCodeManagment } from "~/pages/SecondHandDetails/PromoCodes";
import SetNewPassword from "~/pages/SetNewPassword";
import { ShopifyConnect } from "~/pages/ShopifyConnect";
import { SubscriptionDetails } from "~/pages/SubscriptionDetails";
import { SynchronizationOfStreams } from "~/pages/SynchronizationOfStreams";
import { UserDetailsPage } from "~/pages/UserForm";
import { SellingTypeEnum } from "~/types/data/Product.types";

import { AdminRoute } from "./AdminRoute";
import { AuthenticatedRoute } from "./AuthenticatedRoute/";
import { BrandCompanyRoute } from "./BrandCompanyRoute";
import { NotAuthenticatedRoute } from "./NotAuthenticatedRoute";
import { routePaths, routesBuilder } from "./routes";
import { SecondHandRoute } from "./SecondHandRoute";

const AppRoutes = () => {
  const {
    loginRoute,
    setNewPasswordRoute,
    forgetPasswordRoute,
    confirmForgotPasswordRoute,
    dashboardRoute,
    mesCommandesRoute,
    venteRoute,
    locationRoute,
    detailsRoute,
    expeditionRoute,
    messagesRoute,
    historiquesRoute,
    mesEvaluationsRoute,
    monCatalogueRoute,
    ajouterUnProduitRoute,
    importerParFichierRoute,
    mesImportationsRoute,
    maMessagerieRoute,
    monCompteRoute,
    maComptabiliteRoute,
    facturesRoute,
    transactionsRoute,
    identificationRoute,
    coordonneesSocieteRoute,
    uboDeclarationRoute,
    importDesKycRoute,
    referencesBancairesRoute,
    livraisonRoute,
    reedooLivraisonRoute,
    utilisateursRoute,
    importerParBoutiqueRoute,
    synchronizationOfStreamsRoute,
    platformDataRoute,
    adminPlatformDataRoute,
    errorRoute,
    shopifyConnectRoute,
    maMarqueRoute,
    secondHandRoute,
    secondHandDetailsRoute,
    maMarqueDetailsRoute,
    commandesAbonnementRoute,
    idDynamicRoute,
    adminUtilisateursRoute,
    userDetailsRoute,
    profileRoute,
    addressesRoute,
    adminCompaniesRoute,
    companyFormRoute,
    taxonomieRoute,
    vendorCategoriesRoute,
    attributesRoute,
    promoCodesRoute,
  } = routePaths;

  const detailsDuneCommandeToIdRoute = routesBuilder({
    routes: ["detailsDuneCommandeRoute", "idDynamicRoute"],
  });

  const userDetailsToIdRoute = routesBuilder({
    routes: ["userDetailsRoute", "idDynamicRoute"],
  });

  const maMaqueToAbonnementToIdRoute = routesBuilder({
    routes: ["abonnementRoute", "idDynamicRoute"],
  });

  return (
    <Router>
      <Routes>
        <Route path="/" element={<AuthenticatedRoute />}>
          <Route element={<Navigate to={dashboardRoute} />} path="/" />
        </Route>
        <Route path={`/${loginRoute}`} element={<NotAuthenticatedRoute />}>
          <Route element={<Login />} path={`/${loginRoute}`} />
        </Route>
        <Route
          path={`/${setNewPasswordRoute}`}
          element={<NotAuthenticatedRoute />}
        >
          <Route
            element={<SetNewPassword />}
            path={`/${setNewPasswordRoute}`}
          />
        </Route>
        <Route
          path={`/${forgetPasswordRoute}`}
          element={<NotAuthenticatedRoute />}
        >
          <Route
            element={<ForgotPassword />}
            path={`/${forgetPasswordRoute}`}
          />
        </Route>
        <Route
          path={`/${confirmForgotPasswordRoute}`}
          element={<NotAuthenticatedRoute />}
        >
          <Route
            element={<ConfirmForgotPassword />}
            path={`/${confirmForgotPasswordRoute}`}
          />
        </Route>
        <Route element={<AdminRoute />}>
          <Route
            element={
              <Layout>
                <Outlet />
              </Layout>
            }
          >
            <Route element={<MaMessagerie />} path={maMessagerieRoute} />
            <Route element={<Outlet />} path={dashboardRoute}>
              <Route index element={<Navigate to={venteRoute} />} />
              <Route
                element={<Dashboard mode={SellingTypeEnum.NEW} />}
                path={venteRoute}
              />
              <Route
                element={<Dashboard mode={SellingTypeEnum.RENT} />}
                path={locationRoute}
              />
            </Route>
            <Route element={<Outlet />} path={mesCommandesRoute}>
              <Route index element={<Navigate to={venteRoute} />} />
              <Route element={<Outlet />} path={venteRoute}>
                <Route
                  index
                  element={<MesCommandes ordersMode={SellingTypeEnum.NEW} />}
                />
                <Route element={<MyReviews />} path={mesEvaluationsRoute} />
                <Route element={<Outlet />} path={detailsDuneCommandeToIdRoute}>
                  <Route index element={<Navigate to={detailsRoute} />} />
                  <Route
                    element={<DetailsDuneCommande page="details" />}
                    path={detailsRoute}
                  />
                  <Route
                    element={<DetailsDuneCommande page="delivery" />}
                    path={expeditionRoute}
                  />
                  <Route
                    element={<DetailsDuneCommande page="messages" />}
                    path={messagesRoute}
                  />
                  <Route
                    element={<DetailsDuneCommande page="history" />}
                    path={historiquesRoute}
                  />
                </Route>
              </Route>
              <Route element={<Outlet />} path={locationRoute}>
                <Route
                  index
                  element={<MesCommandes ordersMode={SellingTypeEnum.RENT} />}
                />
                <Route element={<Outlet />} path={userDetailsToIdRoute}>
                  <Route index element={<Navigate to={detailsRoute} />} />
                  <Route
                    element={<RentDetailsDuneCommande page="details" />}
                    path={detailsRoute}
                  />
                  <Route
                    element={<RentDetailsDuneCommande page="delivery" />}
                    path={expeditionRoute}
                  />
                  <Route
                    element={<RentDetailsDuneCommande page="history" />}
                    path={historiquesRoute}
                  />
                </Route>
              </Route>
            </Route>
            <Route element={<MonCatalogue />} path={monCatalogueRoute} />{" "}
            <Route
              element={<AjouterUnProduit />}
              path={ajouterUnProduitRoute}
            />
          </Route>
        </Route>
        <Route element={<AdminRoute adminOnlyAccess />}>
          <Route
            element={
              <Layout>
                <Outlet />
              </Layout>
            }
          >
            <Route
              element={<AdminUtilisateurs />}
              path={adminUtilisateursRoute}
            />
            <Route element={<Outlet />} path={userDetailsToIdRoute}>
              <Route index element={<Navigate to={detailsRoute} />} />
              <Route
                element={<UserDetailsPage page="details" />}
                path={detailsRoute}
              />
              <Route
                element={<UserDetailsPage page="profile" />}
                path={profileRoute}
              />
              <Route
                element={<UserDetailsPage page="addresses" />}
                path={addressesRoute}
              />
            </Route>
            <Route
              element={<UserDetailsPage page="create" />}
              path={userDetailsRoute}
            />
            <Route element={<AdminCompanies />} path={adminCompaniesRoute} />
            <Route element={<CompanyForm />} path={companyFormRoute} />
            <Route element={<Outlet />} path={adminPlatformDataRoute}>
              <Route index element={<Navigate to={taxonomieRoute} />} />
              <Route
                element={
                  <AdminDataPlatform
                    page={AdminDataPlatformPagesEnum.TAXONOMIE}
                  />
                }
                path={taxonomieRoute}
              />
              <Route
                element={
                  <AdminDataPlatform
                    page={AdminDataPlatformPagesEnum.VENDOR_CATEGORIES}
                  />
                }
                path={vendorCategoriesRoute}
              />
              <Route
                element={
                  <AdminDataPlatform
                    page={AdminDataPlatformPagesEnum.ATTRIBUTES}
                  />
                }
                path={attributesRoute}
              />
            </Route>
          </Route>
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route
            element={
              <Layout>
                <Outlet />
              </Layout>
            }
          >
            <Route element={<Outlet />} path={monCompteRoute}>
              <Route index element={<Navigate to={identificationRoute} />} />
              <Route
                element={<MonCompte page="identification" />}
                path={identificationRoute}
              />
              <Route
                element={<MonCompte page="companyDetails" />}
                path={coordonneesSocieteRoute}
              />
              <Route
                element={<MonCompte page="uboDeclaration" />}
                path={uboDeclarationRoute}
              />
              <Route
                element={<MonCompte page="kycImports" />}
                path={importDesKycRoute}
              />
              <Route
                element={<MonCompte page="bankReference" />}
                path={referencesBancairesRoute}
              />
              <Route
                element={<MonCompte page="delivery" />}
                path={livraisonRoute}
              />
              <Route
                element={<MonCompte page="reedooDelivery" />}
                path={reedooLivraisonRoute}
              />
              <Route
                element={<MonCompte page="users" />}
                path={utilisateursRoute}
              />
            </Route>
            <Route element={<Outlet />} path={maComptabiliteRoute}>
              <Route index element={<Navigate to={facturesRoute} />} />
              <Route
                element={<MaComptabilite mode="factures" />}
                path={facturesRoute}
              />
              <Route
                element={<MaComptabilite mode="transactions" />}
                path={transactionsRoute}
              />
            </Route>
            <Route element={<DataPlatform />} path={platformDataRoute} />
            <Route
              element={<AjouterUnProduit />}
              path={ajouterUnProduitRoute}
            />
            <Route
              element={<SynchronizationOfStreams />}
              path={synchronizationOfStreamsRoute}
            />
            <Route
              element={<ImporterParFichier />}
              path={importerParFichierRoute}
            />
            <Route
              element={<ImporterParBoutique />}
              path={importerParBoutiqueRoute}
            />
            <Route
              element={<MesImportations />}
              path={`${mesImportationsRoute}`}
            />
            <Route element={<DynamicErrorPage />} path={`${errorRoute}`} />
          </Route>
        </Route>
        <Route element={<BrandCompanyRoute />}>
          <Route
            element={
              <Layout>
                <Outlet />
              </Layout>
            }
          >
            <Route element={<Outlet />} path={maMarqueRoute}>
              <Route index element={<Navigate to={maMarqueDetailsRoute} />} />
              <Route element={<BrandDetails />} path={maMarqueDetailsRoute} />
              <Route
                element={<SubscriptionDetails />}
                path={maMaqueToAbonnementToIdRoute}
              />
            </Route>
          </Route>
        </Route>
        <Route element={<SecondHandRoute />}>
          <Route
            element={
              <Layout>
                <Outlet />
              </Layout>
            }
          >
            <Route element={<Outlet />} path={secondHandRoute}>
              <Route index element={<Navigate to={secondHandDetailsRoute} />} />
              <Route
                element={<SecondHandDetails />}
                path={secondHandDetailsRoute}
              />
              <Route
                element={<SecondHandPromoCodeManagment />}
                path={promoCodesRoute}
              />
            </Route>
          </Route>
        </Route>

        <Route element={<BrandCompanyRoute />}>
          <Route
            element={
              <Layout>
                <Outlet />
              </Layout>
            }
          >
            <Route element={<Outlet />} path={commandesAbonnementRoute}>
              <Route index element={<CommandesAbonnement />} />
              <Route
                element={<CommandesAbonnementDetails />}
                path={idDynamicRoute}
              />
            </Route>
          </Route>
        </Route>
        <Route element={<ShopifyConnect />} path={shopifyConnectRoute} />
        <Route
          path="/*"
          element={
            <div>
              <h1>404 page</h1>
            </div>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
