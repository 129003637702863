import { NavLink } from "react-router-dom";

import SVGContainer from "~/components/UI/SVGContainer";
import { routesBuilder } from "~/navigation/routes";

import styles from "./index.module.scss";

export const MyAccountNavigation = () => {
  const monCompteToIdentificationRoute = routesBuilder({
    routes: ["monCompteRoute", "identificationRoute"],
  });

  const monCompteToCoordonneesSocieteRoute = routesBuilder({
    routes: ["monCompteRoute", "coordonneesSocieteRoute"],
  });

  const monCompteToUboDeclarationRoute = routesBuilder({
    routes: ["monCompteRoute", "uboDeclarationRoute"],
  });
  const monCompteToImportDesKycRoute = routesBuilder({
    routes: ["monCompteRoute", "importDesKycRoute"],
  });
  const monCompteToReferencesBancairesRoute = routesBuilder({
    routes: ["monCompteRoute", "referencesBancairesRoute"],
  });
  const monCompteToLivraisonRoute = routesBuilder({
    routes: ["monCompteRoute", "livraisonRoute"],
  });
  const monCompteToReedooLivraisonRoute = routesBuilder({
    routes: ["monCompteRoute", "reedooLivraisonRoute"],
  });
  const monCompteToUtilisateursRoute = routesBuilder({
    routes: ["monCompteRoute", "utilisateursRoute"],
  });
  return (
    <div className={`${styles.navigationContainer}`}>
      <div>
        <SVGContainer
          height="10px"
          width="1rem"
          imagePath="/assets/identification-icon.svg"
        />
        <NavLink
          to={`/${monCompteToIdentificationRoute}`}
          className={({ isActive }) => (isActive ? styles.active : "")}
        >
          Identification
        </NavLink>
      </div>
      <div>
        <SVGContainer
          height="10px"
          width="1rem"
          imagePath="/assets/company-details-icon.svg"
        />
        <NavLink
          to={`/${monCompteToCoordonneesSocieteRoute}`}
          className={({ isActive }) => (isActive ? styles.active : "")}
        >
          Coordonnées société
        </NavLink>
      </div>
      <div>
        <SVGContainer
          height="10px"
          width="1rem"
          imagePath="/assets/company-details-icon.svg"
        />
        <NavLink
          to={`/${monCompteToUboDeclarationRoute}`}
          className={({ isActive }) => (isActive ? styles.active : "")}
        >
          UBO Declaration
        </NavLink>
      </div>
      <div>
        <SVGContainer
          height="10px"
          width="1rem"
          imagePath="/assets/kyc-imports-icon.svg"
        />
        <NavLink
          to={`/${monCompteToImportDesKycRoute}`}
          className={({ isActive }) => (isActive ? styles.active : "")}
        >
          Import des KYC
        </NavLink>
      </div>
      <div>
        <SVGContainer
          height="10px"
          width="1rem"
          imagePath="/assets/bank-references-icon.svg"
        />
        <NavLink
          to={`/${monCompteToReferencesBancairesRoute}`}
          className={({ isActive }) => (isActive ? styles.active : "")}
        >
          Références bancaires
        </NavLink>
      </div>
      <div>
        <SVGContainer
          height="10px"
          width="1rem"
          imagePath="/assets/delivery-icon.svg"
        />
        <NavLink
          to={`/${monCompteToLivraisonRoute}`}
          className={({ isActive }) => (isActive ? styles.active : "")}
        >
          Livraison
        </NavLink>
      </div>
      <div>
        <SVGContainer
          height="10px"
          width="1rem"
          imagePath="/assets/delivery-icon.svg"
        />
        <NavLink
          to={`/${monCompteToReedooLivraisonRoute}`}
          className={({ isActive }) => (isActive ? styles.active : "")}
        >
          Reedoo Livraison
        </NavLink>
      </div>
      <div>
        <SVGContainer
          height="10px"
          width="1rem"
          imagePath="/assets/users-icon.svg"
        />
        <NavLink
          to={`/${monCompteToUtilisateursRoute}`}
          className={({ isActive }) => (isActive ? styles.active : "")}
        >
          Utilisateurs
        </NavLink>
      </div>
    </div>
  );
};
