import { utils } from "xlsx";

import { ExcelSheetDataObject } from "~/types/common/generic.types";

export const jsonToWorkbook = (
  data: ExcelSheetDataObject[],
  sheetName: string
) => {
  // create file using xlsx
  const wb = utils.book_new();
  const ws = utils.json_to_sheet(data);

  utils.book_append_sheet(wb, ws, sheetName);

  return wb;
};
