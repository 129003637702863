import { gql } from "@apollo/client";

import { Order } from "~/types/data/Order.types";

import { fullOrderFields } from "../order";

// responses
export interface CreateReedooTransportationResponse {
  createReedooTransportation: Order;
}

export interface CreateCompanyTransportationsResponse {
  createCompanyTransportations: Order;
}

// inputs
export interface CreateReedooTransportationInput {
  CreateReedooTransportationInput: {
    orderId: number;
    carrierId: number;
    weight: number;
    depositDate: Date;
  };
}

export interface CreateCompanyTransportationsInput {
  CreateCompanyTransportationsInput: {
    orderId: number;
    transportations: {
      carrierId: number;
      trackingUrl: string;
      trackingNumber: string;
    }[];
  };
}

export const createReedooTransportation = gql`
  mutation ($CreateReedooTransportationInput: CreateReedooTransportationInput!) {
    createReedooTransportation(CreateReedooTransportationInput: $CreateReedooTransportationInput) {
      ${fullOrderFields}
    }
  }
`;

export const createCompanyTransportations = gql`
  mutation ($CreateCompanyTransportationsInput: CreateCompanyTransportationsInput!) {
    createCompanyTransportations(CreateCompanyTransportationsInput: $CreateCompanyTransportationsInput) {
     ${fullOrderFields}
    }
  }
`;
