import { getS3SignedURL } from "~/api/graphql/media/getS3SignedURL";
import { imageFields } from "~/constants/states";
import { ImageFile, Product } from "~/types/data/Product.types";

export const fieldsToImagesMap = async (
  imageFieldsValues: Partial<Product>
) => {
  const productImages: ImageFile[] = [];
  for (let i = 0; i < 7; i++) {
    const path = imageFieldsValues[imageFields[i]] as string;

    if (!path) {
      break;
    }

    const res = await getS3SignedURL({ path });
    if (res.success) {
      const image: ImageFile = {
        file: undefined,
        url: res.data.s3SignedURL,
        key: path,
        i: `${i}`,
        x: i,
        y: 0,
        w: 1,
        h: 1,
      };
      productImages.push(image);
    }
  }
  return productImages;
};
