import { User } from "~/types/data/User.types";

import { UsersTableRow } from "../UsersTableRow";
import styles from "./index.module.scss";

interface Props {
  users: User[];
  onUserEditClick: (id: number) => void;
  onUserDeleteClick: (id: number) => void;
  deletingId?: number;
}

export const UsersTableBody = ({
  users,
  onUserEditClick,
  onUserDeleteClick,
  deletingId,
}: Props) => {
  return (
    <tbody className={`${styles.tbody}`}>
      {users.map((user, index) => {
        return (
          <UsersTableRow
            key={index.toString()}
            user={user}
            onEdit={onUserEditClick}
            onDelete={onUserDeleteClick}
            deletingId={deletingId}
          />
        );
      })}
    </tbody>
  );
};
