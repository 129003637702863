import { PromoCode } from "~/types/data/PromoCode.type";

export const promoCodeFieldsMapping: Record<keyof PromoCode, string> = {
  id: "ID",
  code: "Code",
  discountType: "Type",
  amount: "Amount",
  active: "Active",
  threshold: "Seuil Minimum Requis",
  uses: "Nombre maximum d'utilisation",
  validUntil: "Date de validité",
  used: "Used",
  maximumUsesPerUser: "Nombre maximum d'utilisation par utilisateur",
};

export const requiredPromoCodeFields: Record<keyof PromoCode, boolean> = {
  active: false,
  amount: true,
  code: true,
  discountType: true,
  id: false,
  threshold: true,
  used: false,
  uses: true,
  validUntil: true,
  maximumUsesPerUser: false,
};
