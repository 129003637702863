import { ApolloError } from "@apollo/client";
import { useEffect, useState } from "react";

import {
  createCompanyTransportations,
  CreateCompanyTransportationsInput,
  CreateCompanyTransportationsResponse,
} from "~/api/graphql/transportation";
import { CustomButton } from "~/components/form/CustomButton";
import { OptionType } from "~/components/form/SelectInput";
import { ErrorPopup } from "~/components/UI/ErrorPopup";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import SVGContainer from "~/components/UI/SVGContainer";
import { colors } from "~/constants/styles";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useMutationWithCallbacks } from "~/hooks/mutationWithCallbacks";
import { Carrier } from "~/types/data/Carrier.types";
import { Order, OrderStatusEnum } from "~/types/data/Order.types";
import { Transportation } from "~/types/data/Transportation.types";

import { BillingAddressCard } from "../components/BillingAddressCard";
import { ShippingAddressCard } from "../components/ShippingAddressCard";
import { getBuyerName } from "../util/getProfileName";
import { CarrierDetailsCard } from "./CarrierDetailsCard";
import styles from "./index.module.scss";
import { InformationBanner } from "./InformationBanner";
import { validateTransportationInput } from "./util/validateTransportationInput";

interface Props {
  order: Order;
  updateOrderHandler: (order: Order) => void;
  carriers: Carrier[];
  readOnly: boolean;
}

export const Expidition = ({
  order,
  updateOrderHandler,
  carriers,
  readOnly,
}: Props) => {
  const initTransportationState: Transportation = order.usedReedooCarrier
    ? { carrier: order.deliveryOption?.carrier }
    : {};

  const [transportations, setTransportations] = useState<Transportation[]>(
    order.transportations.length
      ? order.transportations
      : [initTransportationState]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { companyAccount } = useCompanyAccountContext();
  const filteredCarriers = carriers.filter(
    ({ id }) =>
      !!companyAccount.carriers.find(({ carrierId }) => carrierId === id)
  );

  const carriersOptions: OptionType[] = filteredCarriers.map(
    ({ id, name }) => ({
      label: name,
      value: id.toString(),
    })
  );

  const addCarrierHandler = () => {
    setTransportations((prev) => [...prev, initTransportationState]);
  };

  const deleteCarrierHandler = (index: number) => {
    setTransportations((prev) => prev.filter((carrier, i) => index !== i));
  };

  const inputChangeHandler = <T extends keyof Transportation>(
    index: number,
    inputName: T,
    changes: Transportation[T]
  ) => {
    setTransportations((prev) =>
      prev.map((transportation, transportationIndex) =>
        index === transportationIndex
          ? { ...transportation, [inputName]: changes }
          : transportation
      )
    );
  };

  const createCompanyTransportationsErrorHandler = (error: ApolloError) => {
    setIsLoading(false);
    setErrorMessage(error.message);
  };

  const createCompanyTransportationsSuccessHandler = (
    response: CreateCompanyTransportationsResponse
  ) => {
    updateOrderHandler(response.createCompanyTransportations);
    setIsLoading(false);
  };

  const { trigger: createCompanyTransportationsTrigger } =
    useMutationWithCallbacks<
      CreateCompanyTransportationsResponse,
      CreateCompanyTransportationsInput
    >(
      createCompanyTransportations,
      createCompanyTransportationsSuccessHandler,
      createCompanyTransportationsErrorHandler
    );

  const createCompanyTransportationsHandler = () => {
    setIsLoading(true);
    const error = validateTransportationInput(transportations);
    if (error) {
      setErrorMessage(error);
      setIsLoading(false);
      return;
    }
    createCompanyTransportationsTrigger({
      variables: {
        CreateCompanyTransportationsInput: {
          orderId: order.id,
          transportations: transportations.map(
            ({ carrierId, trackingNumber }) => {
              const carrier = filteredCarriers.find(
                ({ id }) => id === carrierId
              );
              return {
                carrierId: carrierId ?? 0,
                trackingUrl:
                  trackingNumber && carrier && carrier.trackingUrl
                    ? carrier.trackingUrl.replace(
                        "{trackingId}",
                        trackingNumber
                      )
                    : "",
                trackingNumber: trackingNumber ?? "",
              };
            }
          ),
        },
      },
    });
  };

  const hideErrorPopupHandler = () => {
    setErrorMessage("");
  };

  const buyerName = getBuyerName(
    order.buyer?.firstName,
    order.buyer?.lastName,
    order.shippingAddress?.name
  );

  useEffect(() => {
    setTransportations(
      order.transportations.length
        ? order.transportations
        : [initTransportationState]
    );
  }, [order]);

  return (
    <div className={styles.container}>
      <ErrorPopup
        onCancel={hideErrorPopupHandler}
        show={!!errorMessage}
        message={errorMessage}
      />
      <div className={styles.banner}>
        <InformationBanner
          clientName={buyerName}
          orderId={order.id}
          orderStatus={order.status}
        />
      </div>
      <div className={styles.details}>
        <div className={styles.carriersContainer}>
          <div className={styles.carriersList}>
            {transportations.map((transportation, index) => {
              return (
                <div className={`${styles.carrierCard}`} key={index}>
                  <CarrierDetailsCard
                    index={index}
                    order={order}
                    readOnly={readOnly}
                    updateOrderHandler={updateOrderHandler}
                    transportation={transportation}
                    carriersOptions={carriersOptions}
                    inputChangeHandler={inputChangeHandler}
                    onDeleteCarrier={
                      transportations.length > 1
                        ? deleteCarrierHandler
                        : undefined
                    }
                  />
                </div>
              );
            })}
            {transportations.length < 3 &&
              (order.status === OrderStatusEnum.WAITING_FOR_EXPEDITION ||
                order.status === OrderStatusEnum.WAITING_FOR_RECEPTION) &&
              !readOnly && (
                <div className={`${styles.addButtonContainer}`}>
                  <CustomButton
                    color="#354957"
                    borderColor="#354957"
                    borderRadius="5px"
                    height="fit-content"
                    padding="0.2rem 0.5rem"
                    width="fit-content"
                    onClick={addCarrierHandler}
                  >
                    <div className={`${styles.buttonInterior}`}>
                      <SVGContainer
                        height="16px"
                        width="16px"
                        imagePath="/assets/black-circular-add-icon.svg"
                      />
                      <span className={styles.bold}>Ajouter un colis</span>
                    </div>
                  </CustomButton>
                </div>
              )}
          </div>
          {!order.usedReedooCarrier &&
            (order.status === OrderStatusEnum.WAITING_FOR_EXPEDITION ||
              order.status === OrderStatusEnum.WAITING_FOR_RECEPTION) &&
            !readOnly && (
              <div className={`${styles.confirmButtonContainer}`}>
                <CustomButton
                  backgroundColor={colors.$primary}
                  color="white"
                  borderRadius="8px"
                  padding="1rem 1.5rem"
                  width="15rem"
                  onClick={createCompanyTransportationsHandler}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <SimpleLoader size="size1" />
                  ) : (
                    "Confirmer la livraison"
                  )}
                </CustomButton>
              </div>
            )}
        </div>

        <div className={`${styles.deliverySection}`}>
          <div className={`${styles.orderDelivery}`}>
            <ShippingAddressCard
              shippingAddress={order.shippingAddress}
              deliveryOption={order.deliveryOption}
              shippingDate={order.shippingDate}
              usedReedooCarrier={order.usedReedooCarrier}
              buyer={order.buyer}
              carrier={order.deliveryOption?.carrier}
            />
          </div>
          <div className={`${styles.orderBilling}`}>
            <BillingAddressCard
              paymentMethod={order.payment?.paymentMethod}
              shippingAddress={order.billingAddress}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
