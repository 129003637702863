import { colors } from "~/constants/styles";
import { useCompanyAccountContext } from "~/context/companyAccountContext";
import { useUserContext } from "~/context/userContext";
import { routePaths, routesBuilder } from "~/navigation/routes";
import { useAppSelector } from "~/redux/hooks";
import { selectConversationsState } from "~/redux/slice/conversations.slice";
import { RoleEnum } from "~/types/data/User.types";

import { CustomHoverNavButton, HoverSubLink } from "../CustomHoverNavButton";
import CustomNavButton from "../CustomNavButton";
import styles from "./index.module.scss";

const HeaderNavigation = () => {
  const {
    dashboardRoute,
    mesCommandesRoute,
    monCatalogueRoute,
    ajouterUnProduitRoute,
    importerParFichierRoute,
    mesImportationsRoute,
    maMessagerieRoute,
    monCompteRoute,
    maComptabiliteRoute,
    maMarqueRoute,
    secondHandRoute,
    commandesAbonnementRoute,
    adminUtilisateursRoute,
    adminCompaniesRoute,
    promoCodesRoute,
  } = routePaths;

  const mesCommandesToMesEvaluations = routesBuilder({
    routes: ["mesCommandesRoute", "mesEvaluationsRoute"],
  });

  const monCompteToIdentificationRoute = routesBuilder({
    routes: ["monCompteRoute", "identificationRoute"],
  });

  const monCompteToCoordonneesSocieteRoute = routesBuilder({
    routes: ["monCompteRoute", "coordonneesSocieteRoute"],
  });

  const monCompteToUboDeclarationRoute = routesBuilder({
    routes: ["monCompteRoute", "uboDeclarationRoute"],
  });
  const monCompteToImportDesKycRoute = routesBuilder({
    routes: ["monCompteRoute", "importDesKycRoute"],
  });
  const monCompteToReferencesBancairesRoute = routesBuilder({
    routes: ["monCompteRoute", "referencesBancairesRoute"],
  });
  const monCompteToLivraisonRoute = routesBuilder({
    routes: ["monCompteRoute", "livraisonRoute"],
  });
  const monCompteToReedooLivraisonRoute = routesBuilder({
    routes: ["monCompteRoute", "reedooLivraisonRoute"],
  });
  const monCompteToUtilisateursRoute = routesBuilder({
    routes: ["monCompteRoute", "utilisateursRoute"],
  });

  const maComptabiliteToFacturesRoute = routesBuilder({
    routes: ["maComptabiliteRoute", "facturesRoute"],
  });

  const maComptabiliteToTransactionsRoute = routesBuilder({
    routes: ["maComptabiliteRoute", "transactionsRoute"],
  });
  const secondHandCompanyPromoCodeRoute = routesBuilder({
    routes: ["secondHandRoute", "promoCodesRoute"],
  });

  const mesCommandesSubLinks: HoverSubLink[] = [
    { label: "Toutes les commandes", to: mesCommandesRoute },
    { label: "Commandes à traiter", to: `${mesCommandesRoute}?pending` },
    { label: "Commandes en cours", to: mesCommandesRoute },
    { label: "Commandes incidents en cours", to: mesCommandesRoute },
    { label: "Commandes incidents clos", to: mesCommandesRoute },
    { label: "Commandes fermées", to: mesCommandesRoute },
    { label: "Evaluation", to: mesCommandesToMesEvaluations },
  ];

  const monCatalogueSubLinks: HoverSubLink[] = [
    { label: "Gestion du catalogue", to: monCatalogueRoute },
    {
      label: "Ajouter un produit",
      to: ajouterUnProduitRoute,
    },
    { label: "Importer par fichier", to: importerParFichierRoute },
    { label: "Mes importations", to: mesImportationsRoute },
  ];

  const monCompteSubLinks: HoverSubLink[] = [
    { label: "Identification", to: monCompteToIdentificationRoute },
    {
      label: "Coordonnées société",
      to: monCompteToCoordonneesSocieteRoute,
    },
    { label: "UBO Declaration", to: monCompteToUboDeclarationRoute },
    { label: "Import des KYC", to: monCompteToImportDesKycRoute },
    {
      label: "Références bancaires",
      to: monCompteToReferencesBancairesRoute,
    },
    { label: "Livraison", to: monCompteToLivraisonRoute },
    { label: "Reedoo Livrasion", to: monCompteToReedooLivraisonRoute },
    { label: "Utilisateurs", to: monCompteToUtilisateursRoute },
  ];

  const maComptabiliteSubLinks: HoverSubLink[] = [
    { label: "Mes factures", to: maComptabiliteToFacturesRoute },
    {
      label: "Mes transactions",
      to: maComptabiliteToTransactionsRoute,
    },
  ];

  const maMarqueSubLinks: HoverSubLink[] = [
    { label: "Gérer ma marque", to: maMarqueRoute },
    { label: "Commandes d'abonnement", to: commandesAbonnementRoute },
  ];

  const secondHandLinks: HoverSubLink[] = [
    { label: "Gérer ma marque d'occasion", to: secondHandRoute },
    { label: "Promo Codes", to: secondHandCompanyPromoCodeRoute },
  ];

  const { unreadConversationsCount } = useAppSelector(selectConversationsState);

  const { userState } = useUserContext();
  const { companyAccount } = useCompanyAccountContext();
  const hasBrandCompany = !!companyAccount?.brandCompany;
  const hasSecondHandCompany = !!companyAccount?.secondHandCompany;

  const isAdmin = userState?.connected && userState.role === RoleEnum.ADMIN;

  return (
    <nav className={`${styles.nav} ${!isAdmin && styles.center}`}>
      <CustomNavButton
        label="Dashboard"
        to={dashboardRoute}
        padding="19px 16px"
        backgroundColor="rgba(225, 139, 117, 0.1)"
        activeBackgroundColor={colors.$primary}
        activeTextColor="white"
        borderRadius="8px"
        textColor={colors.$primary}
        margin="0 0.5rem"
      />
      {!isAdmin ? (
        <>
          <CustomHoverNavButton
            label="Mes commandes"
            to={mesCommandesRoute}
            padding="19px 16px"
            backgroundColor="rgba(225, 139, 117, 0.1)"
            activeBackgroundColor={colors.$primary}
            activeTextColor="white"
            borderRadius="8px"
            textColor={colors.$primary}
            margin="0 0.5rem"
            subLinks={mesCommandesSubLinks}
          />
          <CustomHoverNavButton
            label="Mon catalogue"
            to={monCatalogueRoute}
            padding="19px 16px"
            backgroundColor="rgba(225, 139, 117, 0.1)"
            activeBackgroundColor={colors.$primary}
            activeTextColor="white"
            borderRadius="8px"
            textColor={colors.$primary}
            margin="0 0.5rem"
            subLinks={monCatalogueSubLinks}
          />
        </>
      ) : (
        <>
          <CustomNavButton
            label="Mes commandes"
            to={mesCommandesRoute}
            padding="19px 16px"
            backgroundColor="rgba(225, 139, 117, 0.1)"
            activeBackgroundColor={colors.$primary}
            activeTextColor="white"
            borderRadius="8px"
            textColor={colors.$primary}
            margin="0 0.5rem"
          />
          <CustomNavButton
            label="Mon catalogue"
            to={monCatalogueRoute}
            padding="19px 16px"
            backgroundColor="rgba(225, 139, 117, 0.1)"
            activeBackgroundColor={colors.$primary}
            activeTextColor="white"
            borderRadius="8px"
            textColor={colors.$primary}
            margin="0 0.5rem"
          />
          <CustomNavButton
            label="Utilisateurs"
            to={adminUtilisateursRoute}
            padding="19px 16px"
            backgroundColor="rgba(225, 139, 117, 0.1)"
            activeBackgroundColor={colors.$primary}
            activeTextColor="white"
            borderRadius="8px"
            textColor={colors.$primary}
            margin="0 0.5rem"
          />
          <CustomNavButton
            label="Companies"
            to={adminCompaniesRoute}
            padding="19px 16px"
            backgroundColor="rgba(225, 139, 117, 0.1)"
            activeBackgroundColor={colors.$primary}
            activeTextColor="white"
            borderRadius="8px"
            textColor={colors.$primary}
            margin="0 0.5rem"
          />
        </>
      )}
      <CustomNavButton
        label={`Ma messagerie ${
          unreadConversationsCount ? `(${unreadConversationsCount})` : ""
        }`}
        to={maMessagerieRoute}
        padding="19px 16px"
        backgroundColor="rgba(225, 139, 117, 0.1)"
        activeBackgroundColor={colors.$primary}
        activeTextColor="white"
        borderRadius="8px"
        textColor={colors.$primary}
        margin="0 0.5rem"
      />
      {!isAdmin && (
        <CustomHoverNavButton
          label="Mon compte"
          to={monCompteRoute}
          padding="19px 16px"
          backgroundColor="rgba(225, 139, 117, 0.1)"
          activeBackgroundColor={colors.$primary}
          activeTextColor="white"
          borderRadius="8px"
          textColor={colors.$primary}
          margin="0 0.5rem"
          subLinks={monCompteSubLinks}
        />
      )}
      {!isAdmin && (
        <CustomHoverNavButton
          label="Ma comptabilité"
          to={maComptabiliteRoute}
          padding="19px 16px"
          backgroundColor="rgba(225, 139, 117, 0.1)"
          activeBackgroundColor={colors.$primary}
          activeTextColor="white"
          borderRadius="8px"
          textColor={colors.$primary}
          margin="0 0.5rem"
          subLinks={maComptabiliteSubLinks}
        />
      )}
      {!isAdmin && !!hasBrandCompany && (
        <CustomHoverNavButton
          label="Ma marque"
          to={maMarqueRoute}
          padding="19px 16px"
          backgroundColor="rgba(225, 139, 117, 0.1)"
          activeBackgroundColor={colors.$primary}
          activeTextColor="white"
          borderRadius="8px"
          textColor={colors.$primary}
          margin="0 0.5rem"
          subLinks={maMarqueSubLinks}
        />
      )}
      {!isAdmin && !!hasSecondHandCompany && (
        <CustomHoverNavButton
          label="Second Hand"
          to={secondHandRoute}
          padding="19px 16px"
          backgroundColor="rgba(225, 139, 117, 0.1)"
          activeBackgroundColor={colors.$primary}
          activeTextColor="white"
          borderRadius="8px"
          textColor={colors.$primary}
          margin="0 0.5rem"
          subLinks={secondHandLinks}
        />
      )}
    </nav>
  );
};

export default HeaderNavigation;
