export enum UboStatusEnum {
  DRAFT = "DRAFT",
  INCOMPLETE = "INCOMPLETE",
  CREATED = "CREATED",
  VALIDATION_ASKED = "VALIDATION_ASKED",
  VALIDATED = "VALIDATED",
  REFUSED = "REFUSED",
}

export interface UBO {
  id?: number;
  mangopayId?: string;
  firstName: string;
  lastName: string;
  address: string;
  zipCode: string;
  city: string;
  region: string;
  country: string;
  birthDate: string;
  birthPlace: string;
  birthCountry: string;
  nationality: string;
  active: boolean;
}
