import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  SellerMapping,
  SellerMappingTypeEnum,
} from "~/types/data/SellerMapping.types";
import { VendorCategory } from "~/types/data/VendorCategory.types";
import { getBestMatch } from "~/util/functions/matching";

import type { RootState } from "../store";

export interface MappedCategoryState {
  [key: string]: number | undefined;
}

const initialState: MappedCategoryState = {};

export const importMappedCategoriesSlice = createSlice({
  name: "importMappedCategories",
  initialState,
  reducers: {
    initializeImportMappedCategories(
      state,
      action: PayloadAction<{
        vendorCategorySheetValues: string[];
        sellerMappings: SellerMapping[];
        vendorCategories: VendorCategory[];
      }>
    ) {
      const { vendorCategorySheetValues, sellerMappings, vendorCategories } =
        action.payload;
      const vendorCategoryNames = vendorCategories
        .map(({ name, associatedNames }) => [name, ...associatedNames])
        .reduce((a, b) => a.concat(b), []);

      for (const sheetValue of vendorCategorySheetValues) {
        // find if there's an already mapped value
        const sellerMapping = sellerMappings.find(
          ({ originalValue, type }) =>
            type === SellerMappingTypeEnum.CATEGORY &&
            originalValue.toLowerCase() === sheetValue.toLowerCase()
        );
        if (sellerMapping) {
          state[sheetValue] = +sellerMapping.mappedValue;
          continue;
        }
        const bestMatch = getBestMatch(sheetValue, vendorCategoryNames);
        if (bestMatch) {
          const mappedCategory = vendorCategories.find(
            ({ name, associatedNames }) =>
              [name, ...associatedNames].includes(bestMatch)
          );
          state[sheetValue] =
            typeof mappedCategory?.id == "number" ? mappedCategory?.id : 0;
          continue;
        }
        state[sheetValue] = undefined;
      }
    },
    onMappingChange(
      state,
      action: PayloadAction<{
        key: string;
        value: string;
      }>
    ) {
      const { key, value } = action.payload;
      const mappedValue = value.split("||").length ? value.split("||")[0] : "";
      state[key] = mappedValue ? +mappedValue : undefined;
    },
  },
});

export const { initializeImportMappedCategories, onMappingChange } =
  importMappedCategoriesSlice.actions;

export const selectMappedCategoriesState = (state: RootState) =>
  state.importMappedCategories;

export default importMappedCategoriesSlice.reducer;
