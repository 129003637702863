import { HeadCell } from "~/types/common/headCell.type";

import styles from "./index.module.scss";
import { SmallTable } from "./SmallTable";

export interface TableRowData {
  text: string;
  value1: number;
  value2?: number;
}

export interface TableData {
  headCells: HeadCell[];
  data: TableRowData[];
}
interface Props {
  table1Data: TableData;
  table2Data?: TableData;
}

export const TableCard = ({ table1Data, table2Data }: Props) => {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.leftTable}`}>
        <SmallTable
          tableRowsData={table1Data.data}
          headCells={table1Data.headCells}
        />
      </div>
      {!!table2Data && (
        <div className={`${styles.rightTable}`}>
          <SmallTable
            tableRowsData={table2Data.data}
            headCells={table2Data.headCells}
          />
        </div>
      )}
    </div>
  );
};
