import InfiniteScroll from "react-infinite-scroll-component";

import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { HeadCell } from "~/types/common/headCell.type";
import { User } from "~/types/data/User.types";

import styles from "./index.module.scss";
import { UsersTableBody } from "./UsersTableBody";
import { UsersTableHead } from "./UsersTableHead";

const HEADCELLS: HeadCell<User>[] = [
  { id: 1, label: "", isCheckbox: true },
  { id: 2, label: "Created at", isSortable: true, associatedKey: "createdAt" },
  { id: 3, label: "ID", isSortable: true, associatedKey: "id" },
  { id: 4, label: "Email", isSortable: true, associatedKey: "email" },
  { id: 5, label: "Username", isSortable: true, associatedKey: "username" },
  { id: 6, label: "Company" },
  { id: 7, label: "Role", isSortable: true, associatedKey: "role" },
  { id: 8, label: "Status", isSortable: true, associatedKey: "status" },
  {
    id: 9,
    label: "Phone number",
    isSortable: true,
    associatedKey: "phoneNumber",
  },
];
interface Props {
  users?: User[];
  selectedRows: User[];
  onCheckboxChange: (user: User) => void;
  onSelectAllChange: (selected: boolean) => void;
  isSelectedAll: boolean;
  fetchMoreData: () => void;
  hasMoreData: boolean;
  onRowClick: (variantId: number) => void;
}

export const UsersTable = ({
  users,
  selectedRows,
  onCheckboxChange,
  onSelectAllChange,
  fetchMoreData,
  hasMoreData,
  onRowClick,
  isSelectedAll,
}: Props) => {
  return (
    <>
      <InfiniteScroll
        dataLength={users?.length ?? 0}
        next={fetchMoreData}
        hasMore={!!users && hasMoreData}
        loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
      >
        <table className={`${styles.table}`}>
          <UsersTableHead
            headCells={HEADCELLS}
            onSelectAllChange={onSelectAllChange}
            allSelected={isSelectedAll}
          />
          <UsersTableBody
            users={users || []}
            selectedRows={selectedRows}
            onCheckboxChange={onCheckboxChange}
            onRowClick={onRowClick}
          />
        </table>
      </InfiniteScroll>
      {!users && (
        <div className={styles.loaderContainer}>
          <SimpleLoader size="size3" fill={colors.$primary} />
        </div>
      )}
    </>
  );
};
