// import { GraphQLGetResponse } from "~/types/common/graphql.types";
import {
  graphGetCustomMessageFields,
  graphqlGetBankReferenceFields,
  graphqlGetBrandCompanyFields,
  graphqlGetCompanyCarriersFields,
  graphqlGetConversationFields,
  graphqlGetDeliveryPackagesFields,
  graphqlGetKYCDocumentFields,
  graphqlGetMessageFields,
  graphqlGetOrderToProductFields,
  graphqlGetProductFields,
  graphqlGetReedooCarrierFields,
  graphqlGetSecondHandCompanyFields,
  graphqlGetSubscriptionFields,
  graphqlGetUBOFields,
  graphqlGetUserFields,
} from "~/constants/graphql";
import { ApiGetResponse } from "~/types/common/auth.types";
import { Company } from "~/types/data/Company.types";

import { gql, graphqlQuery } from "../../clients/apollo";

interface ApiGetCompanyResponse {
  getCompany: Company | null;
}

export async function getCompanyInfo(
  fields: (keyof Company)[]
): Promise<ApiGetResponse<Company>> {
  const graphqlField = fields.join(" ");
  const getSubscriptionSelectFields = graphqlGetSubscriptionFields.join(" ");
  const res = await graphqlQuery<ApiGetCompanyResponse, never>(
    gql`
      query {
        getCompany {
          ${graphqlField}

          brandCompany {
            ${graphqlGetBrandCompanyFields}

            subscriptions {
              ${getSubscriptionSelectFields}
            }
          }
          secondHandCompany {
            ${graphqlGetSecondHandCompanyFields}
            secondHandCategories {
            id
            imagePath
            categoryId
            category {
            id
            name
            }
            }
          }

          ubos {
            ${graphqlGetUBOFields}
          }

          bankReference {
            ${graphqlGetBankReferenceFields}
          }

          kycDocuments {
            fullPath
            ${graphqlGetKYCDocumentFields}
          }

          users {
            ${graphqlGetUserFields}
          }

          carriers{
            ${graphqlGetCompanyCarriersFields}

            deliveryPackages {
              ${graphqlGetDeliveryPackagesFields}
            }
          }

          reedooCarriers{
            ${graphqlGetReedooCarrierFields}

            deliveryPackages {
              ${graphqlGetDeliveryPackagesFields}
            }
          }

          logoFullPath

          conversations{
            ${graphqlGetConversationFields}

            messages { 
              ${graphqlGetMessageFields}
            
              pictureUrl
              fileUrl

              customMessage {
                ${graphGetCustomMessageFields}

                orderToProducts {
                  ${graphqlGetOrderToProductFields}

                  product{
                    ${graphqlGetProductFields}
                  }
                }
              }
            }
          }
        }    
      }
    `
  );
  if (res?.data && res.data.getCompany) {
    return {
      success: true,
      data: res.data.getCompany,
    };
  }
  return { success: false };
}
