import { Profile } from "./Profile.types";

export enum UserStatusEnum {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  DISABLED = "DISABLED",
}

export enum ProviderEnum {
  COGNITO = "COGNITO",
  FACEBOOK = "FACEBOOK",
  GOOGLE = "GOOGLE",
  APPLE = "APPLE",
}

export enum RoleEnum {
  CUSTOMER = "CUSTOMER",
  SELLER = "SELLER",
  ADMIN = "ADMIN",
}

export enum PermissionLevelEnum {
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
}

export interface User {
  id: number;
  sub: string;
  deactivated: boolean;
  username: string;
  resolveFieldUsername: string;
  preferredUsername: string;
  createdAt: string;
  updatedAt: string;
  email?: string;
  phoneNumber: string;
  status: UserStatusEnum;
  provider: ProviderEnum;
  role: RoleEnum;
  companyId: number;
  permissionLevel: PermissionLevelEnum;
  companyName: string;
  profile: Profile;
}

export interface UserFormState {
  id?: number;
  createdAt?: string;
  deactivated: boolean;
  resolveFieldUsername: string;
  preferredUsername: string;
  email?: string;
  phoneNumber: string;
  status?: UserStatusEnum;
  provider?: ProviderEnum;
  role?: RoleEnum;
  permissionLevel?: PermissionLevelEnum;
  companyId?: number;
  profileId?: number;
}

export interface CreateUserInput {
  email: string;
  username: string;
  permissionLevel: PermissionLevelEnum;
  role: RoleEnum;
  companyId: number;
}

export interface UpdateUserInput {
  id: number;
  permissionLevel: PermissionLevelEnum;
}

export interface DeleteUserInput {
  id: number;
}
