import { gql } from "@apollo/client";

import {
  graphqlGetAttributeFields,
  graphqlGetVendorCategoryFields,
} from "~/constants/graphql";
import {
  CategoryMapping,
  VendorCategory,
} from "~/types/data/VendorCategory.types";

export interface GetVendorCategoriesResponse {
  getVendorCategories: VendorCategory[];
}

export interface GetAllVendorCategoriesResponse {
  getAllVendorCategories: VendorCategory[];
}

export interface CreateVendorCategoriesResponse {
  createVendorCategory: VendorCategory;
}

export interface UpdateVendorCategoriesResponse {
  updateVendorCategory: VendorCategory;
}

export interface DeleteVendorCategoryResponse {
  deleteVendorCategory: VendorCategory;
}

export interface GetVendorCategoriesInput {
  GetVendorCategoriesInput?: { companiesIds: number[] };
}

export interface CreateVendorCategoryInput {
  CreateVendorCategoryInput: {
    name?: string;
    associatedNames?: string[];
    categoryMappings?: CategoryMapping[];
    attributes: number[];
  };
}

export interface UpdateVendorCategoryInput {
  UpdateVendorCategoryInput: {
    id: number;
    name?: string;
    associatedNames?: string[];
    categoryMappings?: CategoryMapping[];
    attributes: number[];
  };
}

export interface DeleteVendorCategoryInput {
  DeleteVendorCategoryInput: {
    id: number;
  };
}

const categoriesFields = graphqlGetVendorCategoryFields.join(" ");
const attributeFields = graphqlGetAttributeFields.join(" ");

export const ADMIN_CREATE_VENDOR_CATEGORIES = gql`
  mutation ($CreateVendorCategoryInput: CreateVendorCategoryInput!) {
    createVendorCategory(
      CreateVendorCategoryInput: $CreateVendorCategoryInput
    ) {
      ${graphqlGetVendorCategoryFields}
    }
  }
`;

export const ADMIN_UPDATE_VENDOR_CATEGORIES = gql`
  mutation ($UpdateVendorCategoryInput: UpdateVendorCategoryInput!) {
    updateVendorCategory(
      UpdateVendorCategoryInput: $UpdateVendorCategoryInput
    ) {
      ${graphqlGetVendorCategoryFields}
    }
  }
`;

export const ADMIN_DELETE_VENDOR_CATEGORIES = gql`
  mutation ($DeleteVendorCategoryInput: DeleteVendorCategoryInput!) {
    deleteVendorCategory(
      DeleteVendorCategoryInput: $DeleteVendorCategoryInput
    ) {
      ${graphqlGetVendorCategoryFields}
    }
  }
`;

export const GET_USED_VENDOR_CATEGORIES = gql`
  query ($GetVendorCategoriesInput: GetVendorCategoriesInput) {
    getVendorCategories (GetVendorCategoriesInput: $GetVendorCategoriesInput) {
      ${categoriesFields}
      
      attributes {
        ${attributeFields}
      }
    }
  }
`;

export const GET_ALL_VENDOR_CATEGORIES = gql`
query ($GetVendorCategoriesInput: GetVendorCategoriesInput) {
  getAllVendorCategories (GetVendorCategoriesInput: $GetVendorCategoriesInput) {
    ${categoriesFields}
    
    attributes {
      ${attributeFields}
    }
  }
}
`;

export const GET_ALL_VENDOR_CATEGORIES_WITH_MAPPINGS = gql`
  query ($GetVendorCategoriesInput: GetVendorCategoriesInput) {
    getAllVendorCategories (GetVendorCategoriesInput: $GetVendorCategoriesInput) {
      ${categoriesFields}

      mappings {
        id
        category1Id
        category2Id
        category3Id
        vendorCategoryId
      }
      
      attributes {
        id
      }
    }
  }
`;
