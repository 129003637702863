import { gql } from "@apollo/client";

import {
  graphqlGetProfileFields,
  graphqlGetReviewFields,
} from "~/constants/graphql";
import { ReviewsFilterArgs } from "~/redux/slice/reviewsFilter.slice";
import { OrderBy } from "~/types/common/filter.types";
import { Review } from "~/types/data/Review.types";

// responses
export interface GetFilteredReviewsByIdResponse {
  getFilteredReviewsById: {
    reviews: Review[];
    nextCursor: number;
    hasMoreData: boolean;
  };
}

// responses
export interface UpdateReviewResponseApiResponse {
  updateReviewResponse: Review;
}

export interface GetReviewsAggregateDataResponse {
  getReviewsAggregateData: { totalReviews: number; averageRating: number };
}

// input
export interface GetFilteredReviewsByIdInput {
  GetFilteredReviewsByIdInput: {
    reviewedCompanyId: number;
    cursor?: number;
    take?: number;
    orderBy: OrderBy<ReviewsFilterArgs>;
    rating?: number;
    searchBox?: string;
  };
}

export interface UpdateReviewResponseInput {
  UpdateReviewResponseInput: { id: number; response: string };
}

export interface GetReivewsAggregateDataInput {
  GetReivewsAggregateDataInput: { companyId: number };
}

// select fields
const reviewFields = graphqlGetReviewFields.join(" ");
const profileFields = graphqlGetProfileFields.join(" ");

export const GET_FILTERED_REVIEWS_BY_ID = gql`
  query($GetFilteredReviewsByIdInput: GetFilteredReviewsByIdInput!) {
    getFilteredReviewsById(GetFilteredReviewsByIdInput: $GetFilteredReviewsByIdInput) {
      reviews {
        ${reviewFields}
        reviewerProfile {
          ${profileFields}
          profilePicUrl
        }
      }
      nextCursor
      hasMoreData
    }
  }
`;

export const UPDATE_REVIEWS_RESPONSE = gql`
  mutation($UpdateReviewResponseInput: UpdateReviewResponseInput!) {
    updateReviewResponse(UpdateReviewResponseInput: $UpdateReviewResponseInput) {
      ${reviewFields}
      reviewerProfile {
        ${profileFields}
        profilePicUrl
      }
    }
  }
`;

export const GET_REVIEWS_AGGREGATE_DATA = gql`
  query ($GetReivewsAggregateDataInput: GetReivewsAggregateDataInput!) {
    getReviewsAggregateData(
      GetReivewsAggregateDataInput: $GetReivewsAggregateDataInput
    ) {
      totalReviews
      averageRating
    }
  }
`;
