import { HeadCell } from "~/types/common/headCell.type";
import {
  OrderHisory,
  OrderHistoryFilterState,
} from "~/types/data/OrderHistory.types";

import { HistoryTableBody } from "./HistoryTableBody";
import { HistoryTableHead } from "./HistoryTableHead";
import styles from "./index.module.scss";

interface Props {
  historyData: OrderHisory[];
  filterState: OrderHistoryFilterState;
  filterChangeHandler: <T extends keyof OrderHistoryFilterState>(
    inputName: T,
    changes: OrderHistoryFilterState[T]
  ) => void;
}

const HEADCELLS: HeadCell[] = [
  { id: 0, label: "Date", isSortable: true },
  { id: 1, label: "Déclencheur" },
  { id: 2, label: "Description" },
  { id: 3, label: "Notification" },
  { id: 4, label: "Nouveau statut" },
];

export const HistoryTable = ({
  historyData,
  filterChangeHandler,
  filterState,
}: Props) => {
  return (
    <table className={`${styles.table}`}>
      <HistoryTableHead
        headCells={HEADCELLS}
        filterState={filterState}
        filterChangeHandler={filterChangeHandler}
      />
      <HistoryTableBody historyData={historyData} />
    </table>
  );
};
