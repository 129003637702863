import { CustomButton } from "~/components/form/CustomButton";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { Order, ProductOrderStatusEnum } from "~/types/data/Order.types";
import { formatPrice } from "~/util/functions/formatDecimal";

import { ConfirmModalState } from "..";
import styles from "./index.module.scss";

interface Props {
  state: ConfirmModalState;
  order: Order;
  onConfirmAllProducts: () => void;
  onConfirmProduct: () => void;
}

export const ConfirmOrderModal = ({
  state,
  order,
  onConfirmAllProducts,
  onConfirmProduct,
}: Props) => {
  const { mode, orderProductId, isLoading, errorMessage } = state;

  const { orderToProducts, shippingPrice } = order;

  const orderProduct = orderProductId
    ? orderToProducts.find(({ id }) => id === orderProductId)
    : undefined;

  const title =
    mode === "accept" && orderProduct
      ? `Confirm Accept ${orderProduct.product.title}`
      : mode === "refuse" && orderProduct
      ? `Confirm Refuse ${orderProduct.product.title}`
      : mode === "acceptAll"
      ? "Accept All"
      : "Refuse All";
  const onHoldOrderProducts = orderToProducts.filter(
    (orderToProduct) =>
      orderToProduct.productStatus === ProductOrderStatusEnum.ON_HOLD
  );
  const totalProductsPrice = onHoldOrderProducts.reduce(
    (a, b) => a + b.totalPrice,
    0
  );

  const totalQuantity = onHoldOrderProducts.reduce((a, b) => a + b.quantity, 0);

  const formattedUnitPrice = formatPrice(orderProduct?.unitPrice);
  const formattedTotalProductPrice = formatPrice(orderProduct?.totalPrice);
  const formattedTotalPrice = formatPrice(totalProductsPrice);
  const formattedShippingPrice = formatPrice(shippingPrice);
  const formattedTotalAmountPrice = formatPrice(
    totalProductsPrice + shippingPrice
  );

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h2>{title}</h2>
      </div>
      {(mode === "accept" || mode === "refuse") && orderProduct ? (
        <div className={styles.detailsContainer}>
          <div className={styles.informationContainer}>
            <p className={styles.boldText}>Quantity: </p>
            <p>{orderProduct.quantity}</p>
          </div>
          <div className={styles.informationContainer}>
            <p className={styles.boldText}>Unit price: </p>
            <p>{formattedUnitPrice}</p>
          </div>
          <div className={styles.informationContainer}>
            <p className={styles.boldText}>Total price: </p>
            <p>{formattedTotalProductPrice}</p>
          </div>
        </div>
      ) : (
        <div className={styles.detailsContainer}>
          <div className={styles.informationContainer}>
            <p className={styles.boldText}>Total items: </p>
            <p>{totalQuantity}</p>
          </div>
          <div className={styles.informationContainer}>
            <p className={styles.boldText}>Products total price: </p>
            <p>{formattedTotalPrice}</p>
          </div>
          <div className={styles.informationContainer}>
            <p className={styles.boldText}>Shipping price: </p>
            <p>{formattedShippingPrice}</p>
          </div>
          <div className={styles.informationContainer}>
            <p className={styles.boldText}>Montant total: </p>
            <p>{formattedTotalAmountPrice}</p>
          </div>
        </div>
      )}
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}

      <div className={styles.buttonsContainer}>
        <CustomButton
          width="max-content"
          padding="0.7rem 1rem"
          backgroundColor={colors.$primary}
          color="white"
          borderRadius="7px"
          disabled={isLoading}
          onClick={() => {
            if (mode === "acceptAll") {
              onConfirmAllProducts();
            } else if (mode === "refuseAll") {
              onConfirmAllProducts();
            } else if (mode === "accept") {
              onConfirmProduct();
            } else if (mode === "refuse") {
              onConfirmProduct();
            }
          }}
        >
          {isLoading ? <SimpleLoader size="size2" /> : "confirmer"}
        </CustomButton>
      </div>
    </div>
  );
};
