import { CustomButton } from "~/components/form/CustomButton";
import { SquareImage } from "~/components/UI/SquareImage";
import StatusLabel from "~/components/UI/StatusLabel";
import { colors } from "~/constants/styles";
import { useUserContext } from "~/context/userContext";
import {
  OrderToProduct,
  ProductOrderStatusEnum,
} from "~/types/data/Order.types";
import { RoleEnum } from "~/types/data/User.types";
import { formatPrice } from "~/util/functions/formatDecimal";

import { ConfirmModalState } from "..";
import styles from "./index.module.scss";

interface Props {
  onShowModal: (modalState: ConfirmModalState) => void;
  orderProduct: OrderToProduct;
  readOnly: boolean;
}

const OrderDetailsCard = ({ onShowModal, orderProduct, readOnly }: Props) => {
  const { id, unitPrice, product, productStatus, quantity } = orderProduct;

  const formattedPrice = formatPrice(unitPrice);

  const showModalHandler = (modalState: ConfirmModalState) => {
    onShowModal(modalState);
  };

  const statusColor =
    productStatus == ProductOrderStatusEnum.ACCEPTED ? "green" : "red";

  const { userState } = useUserContext();

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.productDetailsContainer}`}>
        <div className={`${styles.imageContainer}`}>
          <SquareImage
            url={orderProduct.product.image1FullPath || ""}
            borderRadius="8px"
          />
        </div>
        <div className={`${styles.informationContainer}`}>
          <div className={`${styles.header}`}>
            <p className={`${styles.productTitle}`}>{product.title}</p>
            {!!orderProduct.refundAmount && (
              <p className={`${styles.refundStatus}`}>remboursé</p>
            )}
          </div>

          <div className={`${styles.attributesContainer}`}>
            <p>
              <span className={`${styles.emphasizedText}`}>SKU Produit : </span>
              {product.sku}
            </p>
          </div>
          <div className={`${styles.attributesContainer}`}>
            <p>
              <span className={`${styles.emphasizedText}`}>Prix : </span>
              {formattedPrice}
            </p>
            <p>
              <span className={`${styles.emphasizedText}`}>Taille : </span>
              {product.size || "N/A"}
            </p>
            <p>
              <span className={`${styles.emphasizedText}`}>Couleur : </span>
              {product.color || "N/A"}
            </p>
            <p>
              <span className={`${styles.emphasizedText}`}>Quantité : </span>
              {quantity}
            </p>
            {userState?.connected && userState.role == RoleEnum.ADMIN && (
              <p>
                <span className={`${styles.emphasizedText}`}>State Type: </span>
                {product.stateType || "N/A"}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={`${styles.buttonsContainer}`}>
        {productStatus === ProductOrderStatusEnum.ON_HOLD && !readOnly ? (
          <>
            <CustomButton
              color={colors.$primaryDark}
              borderColor={colors.$primaryDark}
              borderRadius="5px"
              height="fit-content"
              padding="0.2rem 0.5rem"
              width="fit-content"
              onClick={() => {
                showModalHandler({
                  mode: "refuse",
                  orderProductId: id,
                  isLoading: false,
                  show: true,
                });
              }}
            >
              Refuser
            </CustomButton>
            <CustomButton
              color={colors.$primaryDark}
              borderColor={colors.$primaryDark}
              borderRadius="5px"
              height="fit-content"
              padding="0.2rem 0.5rem"
              width="fit-content"
              onClick={() => {
                showModalHandler({
                  mode: "accept",
                  orderProductId: id,
                  isLoading: false,
                  show: true,
                });
              }}
            >
              Accepter
            </CustomButton>
          </>
        ) : (
          <StatusLabel
            backgroundColor={statusColor}
            color="white"
            label={productStatus}
          />
        )}
      </div>
    </div>
  );
};

export default OrderDetailsCard;
