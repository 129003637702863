import { SquareImage } from "~/components/UI/SquareImage";
import { MessageStatusEnum } from "~/types/data/Message.types";

import styles from "./index.module.scss";

interface Props {
  pictureUrl: string;
  status?: MessageStatusEnum;
}

export const PictureMessageBody = ({ pictureUrl, status }: Props) => {
  return (
    <div className={`${styles.container}`}>
      {status === MessageStatusEnum.SENDING ? (
        <div className={styles.sending}>sending</div>
      ) : status === MessageStatusEnum.FAILED ? (
        <div className={styles.failed}>failed</div>
      ) : (
        <div className={styles.imageContainer}>
          <SquareImage url={pictureUrl} />
        </div>
      )}
    </div>
  );
};
