import { useNavigate } from "react-router-dom";

import { CustomButton } from "~/components/form/CustomButton";
import { colors } from "~/constants/styles";

import styles from "./index.module.scss";

interface Props {
  hideFinalPageModal: () => void;
}

export const FinalPageModal = ({ hideFinalPageModal }: Props) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.modalHeader}>
        <h3 className={styles.modalTitle}>
          Your products have been successfully submitted
        </h3>
        <div className={styles.buttonsHolder}>
          <CustomButton
            backgroundColor={colors.$primary}
            color="white"
            width="13rem"
            borderRadius="8px"
            padding="1rem 1.5rem"
            onClick={() => {
              navigate("/mes-importations", { replace: true });
              hideFinalPageModal();
            }}
          >
            Mes importations
          </CustomButton>
          <CustomButton
            backgroundColor={colors.$primary}
            color="white"
            width="13rem"
            borderRadius="8px"
            padding="1rem 1.5rem"
            onClick={() => {
              navigate("/mon-catalogue", { replace: true });
              hideFinalPageModal();
            }}
          >
            Mon catalogue
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
