import { useState } from "react";

import Card from "~/components/UI/Card";
import CustomModal from "~/components/UI/CustomModal";
import { useUserContext } from "~/context/userContext";
import {
  GroupedRentOrderSingleProduct,
  RentOrder,
} from "~/types/data/RentOrder.type";
import { RoleEnum } from "~/types/data/User.types";

import { BillingAddressCard } from "../components/BillingAddressCard";
import { ShippingAddressCard } from "../components/ShippingAddressCard";
import { ConfirmOrderModal } from "./ConfirmOrderModal";
import styles from "./index.module.scss";
import { InformationBanner } from "./InformationBanner";
import OrderTotalCard from "./OrderTotalCard";
import { RentOrderProductsList } from "./RentOrderProductsList";

export interface ConfirmModalState {
  groupedProduct?: GroupedRentOrderSingleProduct;
  mode?: "updatePending" | "review";
  errorMessage?: string;
  isLoading: boolean;
  show: boolean;
}
interface Props {
  rentOrder: RentOrder;
  updateOrderHandler: (order: RentOrder) => void;
}

const Details = ({ rentOrder, updateOrderHandler }: Props) => {
  const { orderToProducts, shippingAddress, billingAddress, buyer } = rentOrder;

  const [confirmModalState, setConfirmModalState] = useState<ConfirmModalState>(
    { isLoading: false, show: false }
  );

  const { userState } = useUserContext();
  const isAdmin = !!(userState?.connected && userState.role === RoleEnum.ADMIN);

  const showConfirmOrderModalHandler = (modalState: ConfirmModalState) => {
    setConfirmModalState(modalState);
  };

  const hideConfirmOrderModalHandler = () => {
    setConfirmModalState({ isLoading: false, show: false });
  };

  const setModalState = (modalState: ConfirmModalState) => {
    setConfirmModalState(modalState);
  };

  return (
    <div className={`${styles.container}`}>
      <CustomModal
        onCancel={
          !confirmModalState.isLoading ? hideConfirmOrderModalHandler : () => {}
        }
        show={confirmModalState.show}
        disableCancel={confirmModalState.isLoading}
      >
        <ConfirmOrderModal
          state={confirmModalState}
          rentOrder={rentOrder}
          setModalState={setModalState}
          updateOrderHandler={updateOrderHandler}
        />
      </CustomModal>
      <InformationBanner
        rentOrder={rentOrder}
        setOrderHandler={updateOrderHandler}
      />

      <div className={`${styles.detailsRow}`}>
        <div className={`${styles.orderSection}`}>
          <RentOrderProductsList
            orderToProducts={orderToProducts}
            onShowModal={showConfirmOrderModalHandler}
            isAdmin={isAdmin}
          />

          <div className={`${styles.orderTotal}`}>
            <Card>
              <OrderTotalCard rentOrder={rentOrder} />
            </Card>
          </div>
        </div>
        <div className={`${styles.deliverySection}`}>
          <div className={`${styles.orderDelivery}`}>
            <ShippingAddressCard
              shippingAddress={shippingAddress}
              buyer={buyer}
            />
          </div>
          <div className={`${styles.orderBilling}`}>
            <BillingAddressCard shippingAddress={billingAddress} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
