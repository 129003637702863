import { gql } from "@apollo/client";

import {
  graphqlGetAttributeFields,
  graphqlGetAttributeValuesFields,
} from "~/constants/graphql";
import { Attribute } from "~/types/data/Attribute.types";
import { Product } from "~/types/data/Product.types";

export interface GetAttributeResponse {
  getAttribute: Attribute;
}

export interface GetAttributesResponse {
  getAttributes: Attribute[];
}

export interface GetAdminAttributesResponse {
  getAdminAttributes: Attribute[];
}

const attributeFields = graphqlGetAttributeFields.join(" ");
const attributeValuesFields = graphqlGetAttributeValuesFields.join(" ");

export interface GetAttributeInput {
  GetAttributeInput: {
    name: keyof Product;
    companiesIds?: number[];
  };
}

export const getAttribute = gql`
      query ($GetAttributeInput: GetAttributeInput!) {
        getAttribute(
          GetAttributeInput: $GetAttributeInput
        ) {
          id
          companiesIds
          values{
            ${attributeValuesFields}
          }
        }
      }
    `;

export const GET_ATTRIBUTES = gql`
      query {
        getAttributes {
          ${attributeFields}
          values{
            ${attributeValuesFields}
          }
        }
      }
    `;

export const GET_ADMIN_ATTRIBUTES = gql`
  query {
    getAdminAttributes {
      ${attributeFields}
      values{
        ${attributeValuesFields}
      }
    }
  }
`;
