import { useQuery } from "@apollo/client";
import { DateTime } from "luxon";
import { useState } from "react";

import {
  GET_BRAND_COMPANIES,
  GetBrandCompaniesResponse,
} from "~/api/graphql/BrandCompany";
import DatePicker from "~/components/form/DatePicker";
import CustomNavButton from "~/components/navigation/CustomNavButton";
import CustomModal from "~/components/UI/CustomModal";
import SVGContainer from "~/components/UI/SVGContainer";
import { colors } from "~/constants/styles";
import { useUserContext } from "~/context/userContext";
import { routesBuilder } from "~/navigation/routes";
import { SellingTypeEnum } from "~/types/data/Product.types";

import { GenerateStatistiscsReportModal } from "./GenerateStatistiscsReportModal";
import styles from "./index.module.scss";
import { RentPage } from "./RentPage/RentPage";
import { SalePage } from "./SalePage";
interface Props {
  mode: SellingTypeEnum;
}

const Dashboard = ({ mode }: Props) => {
  const [from, setFrom] = useState<string>();
  const [to, setTo] = useState<string>();
  const [showStatisticsReportModal, setShowStatisticsReportModal] =
    useState<boolean>(false);

  const { userState } = useUserContext();

  const dashboardToVenteRoute = routesBuilder({
    routes: ["dashboardRoute", "venteRoute"],
  });

  const dashboardToLocationRoute = routesBuilder({
    routes: ["dashboardRoute", "locationRoute"],
  });

  const openStatisticsReportModal = () => {
    setShowStatisticsReportModal(true);
  };

  const { data, error } =
    useQuery<GetBrandCompaniesResponse>(GET_BRAND_COMPANIES);

  const isAdmin = userState?.connected && userState?.role === "ADMIN";

  return (
    <>
      <CustomModal
        show={showStatisticsReportModal}
        onCancel={() => {
          setShowStatisticsReportModal(false);
        }}
        disableScroll
      >
        <GenerateStatistiscsReportModal
          hideModalTrigger={() => {
            setShowStatisticsReportModal(false);
          }}
          brandCompanies={data?.getBrandCompanies}
          fetchBrandCompaniesError={error?.message}
        />
      </CustomModal>
      {isAdmin && (
        <div className={`${styles.dashboardButtons}`}>
          <p
            className={styles.downloadButton}
            onClick={() => {
              openStatisticsReportModal();
            }}
          >
            <SVGContainer
              height="1.1rem"
              width="1rem"
              imagePath="/assets/days-calendar-icon.svg"
            />
            <span>Generate statistics report</span>
          </p>
        </div>
      )}
      <div className={`${styles.header}`}>
        <div className={`${styles.leftSide}`}>
          <p className={`${styles.welcomeText}`}>
            Bonjour{" "}
            <span>{userState?.connected ? userState.username : "Error"}</span>
          </p>
          <div className={`${styles.inputsContainer}`}>
            <div className={`${styles.rangeInput}`}>
              <p>Du</p>
              <DatePicker
                fontSize="12px"
                icon="keyboardArrowDown"
                onChange={(value: DateTime | null) => {
                  if (value?.isValid !== false) {
                    const modifiedDate = value?.set({ hour: 0, minute: 0 });
                    setFrom(modifiedDate?.toISO());
                  }
                }}
                value={from ? DateTime.fromISO(from) : null}
                backgroundColor="white"
                borderRadius="5px"
              />
            </div>
            <div className={`${styles.rangeInput}`}>
              <p>au</p>
              <DatePicker
                fontSize="12px"
                icon="keyboardArrowDown"
                onChange={(value: DateTime | null) => {
                  if (value?.isValid !== false) {
                    const modifiedDate = value?.set({ hour: 23, minute: 59 });
                    setTo(modifiedDate?.toISO());
                  }
                }}
                value={to ? DateTime.fromISO(to) : null}
                backgroundColor="white"
                borderRadius="5px"
              />
            </div>
          </div>
        </div>
        {isAdmin && (
          <div className={`${styles.rightSide}`}>
            <div className={styles.navs}>
              <CustomNavButton
                label="Vente"
                to={`/${dashboardToVenteRoute}`}
                padding="0.5rem 1rem"
                backgroundColor="rgba(225, 139, 117, 0)"
                activeBackgroundColor={colors.$primary}
                activeTextColor="white"
                borderRadius="5px"
                borderColor={colors.$primary}
                textColor={colors.$primary}
              />
              <CustomNavButton
                label="Location"
                to={`/${dashboardToLocationRoute}`}
                padding="0.5rem 1rem"
                backgroundColor="rgba(225, 139, 117, 0)"
                activeBackgroundColor={colors.$primary}
                activeTextColor="white"
                borderRadius="5px"
                borderColor={colors.$primary}
                textColor={colors.$primary}
              />
            </div>
          </div>
        )}
      </div>
      {mode === SellingTypeEnum.NEW ? (
        <SalePage from={from} to={to} />
      ) : (
        <RentPage from={from} to={to} />
      )}
    </>
  );
};

export default Dashboard;
