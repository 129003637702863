import { AttributeValue } from "./AttributeValue.types";
import { Product } from "./Product.types";

export enum AttributeTypeEnum {
  STRING = "STRING",
  NUMBER = "NUMBER",
  BOOLEAN = "BOOLEAN",
  DATE = "DATE",
  MEDIA = "MEDIA",
}
export interface Attribute {
  id: number;
  name: keyof Product;
  associatedNames: string[];
  isGuessable: boolean;
  forProducts: boolean;
  type: AttributeTypeEnum;
  values?: AttributeValue[];
}
