import StatusLabel from "~/components/UI/StatusLabel";
import { IncidentStatusEnum, OrderToProduct } from "~/types/data/Order.types";
import { formatPrice } from "~/util/functions/formatDecimal";

import styles from "./index.module.scss";

interface Props {
  title: string;
  date: string;
  email: string;
  phoneNumber: string;
  products: OrderToProduct[];
  finalShippingPrice: number;
  finalTotalPrice: number;
  incidentStatus: IncidentStatusEnum;
  incidentReason?: string;
}

const InformationCard = ({
  title,
  date,
  email,
  phoneNumber,
  finalShippingPrice,
  products,
  incidentStatus,
  incidentReason,
  finalTotalPrice,
}: Props) => {
  const formattedFinalShippingPrice = formatPrice(finalShippingPrice);
  const formattedFinalTotalPrice = formatPrice(finalTotalPrice);
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.titleContainer}`}>
        <p>{title}</p>
      </div>
      <div className={`${styles.informationSection}`}>
        <p className={`${styles.informationTitle}`}>Date de la commande</p>
        <p className={`${styles.informationText}`}>{date}</p>
      </div>
      <div className={`${styles.informationSection}`}>
        <p className={`${styles.informationTitle}`}>Adresse e-mail</p>
        <p className={`${styles.informationText}`}>{email}</p>
      </div>
      <div className={`${styles.informationSection}`}>
        <p className={`${styles.informationTitle}`}>Téléphone</p>
        <p className={`${styles.informationText}`}>{phoneNumber}</p>
      </div>
      <div className={`${styles.productsSection}`}>
        <p className={`${styles.informationTitle}`}>Produits commandés</p>
        <div className={`${styles.productsText}`}>
          {products.map(({ product, totalPrice, refundAmount }, index) => {
            const finalPrice = totalPrice - refundAmount;
            const formattedUnitPrice = formatPrice(finalPrice);
            return (
              <div className={`${styles.productInfoContainer}`} key={index}>
                <p>{`Produit ${index + 1} ${product.title}`}</p>
                <p>{formattedUnitPrice}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={`${styles.informationSection}`}>
        <p className={`${styles.informationTitle}`}>Prix total final</p>
        <p className={`${styles.informationText}`}>
          {formattedFinalTotalPrice}
        </p>
      </div>
      <div className={`${styles.informationSection}`}>
        <p className={`${styles.informationTitle}`}>Port</p>
        <p className={`${styles.informationText}`}>
          {formattedFinalShippingPrice}
        </p>
      </div>
      {incidentStatus === IncidentStatusEnum.OPEN && (
        <div className={`${styles.informationSection}`}>
          <StatusLabel
            label={incidentStatus}
            color="white"
            backgroundColor="#C22A23"
          />
          <p className={`${styles.informationText} ${styles.boldText}`}>
            {incidentReason}
          </p>
        </div>
      )}
    </div>
  );
};

export default InformationCard;
