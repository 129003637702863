import { CustomButton } from "~/components/form/CustomButton";
import { NameCard } from "~/components/UI/NameCard";
import { RatingStars } from "~/components/UI/RatingStars";
import { colors } from "~/constants/styles";
import { Review } from "~/types/data/Review.types";
import { formatDate } from "~/util/functions/formatDate";

import { getProfileName } from "../../DetailsDuneCommande/util/getProfileName";
import styles from "./index.module.scss";

interface Props {
  review: Review;
  index: number;
  onOpenResponseModal: (index: number) => void;
}
export const ReviewCard = ({ review, index, onOpenResponseModal }: Props) => {
  const {
    createdAt,
    description,
    reviewedOrderId,
    response,
    rating,
    reviewerProfile,
  } = review;

  const formattedDate = formatDate(createdAt);
  const reviewerName = getProfileName(
    reviewerProfile.firstName,
    reviewerProfile.lastName
  );

  const buttonText = response ? "Voir la réponse" : "Répondre";

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.rowNumberContainer}`}>{index + 1}</div>
      <div className={`${styles.reviewContainer}`}>
        <div className={`${styles.detailsContainer}`}>
          <div>{formattedDate}</div>
          <div>Commande N°{reviewedOrderId}</div>
          <div>
            <NameCard
              name={reviewerName}
              imageLink={reviewerProfile.profilePicUrl ?? ""}
            />
          </div>
          <div>
            <RatingStars rating={rating} />
          </div>
        </div>
        <div className={`${styles.reviewContainer}`}>{description}</div>
      </div>
      <div className={`${styles.buttonContainer}`}>
        <CustomButton
          backgroundColor={response ? "white" : colors.$primary}
          borderRadius="8px"
          width="10rem"
          color={response ? colors.$primary : "white"}
          borderColor={response ? colors.$primary : ""}
          onClick={() => {
            onOpenResponseModal(index);
          }}
        >
          {buttonText}
        </CustomButton>
      </div>
    </div>
  );
};
